import React, { useEffect, useState } from "react";
import { Layout, Card } from "antd";
import ProposalHeader from "../../components/proposal/ProposalHeader";
import JobSiteHeader from "../../components/proposal/JobSiteHeader";
import ProjectSummary from "../../components/proposal/ProjectSummary";
import PriceSummary from "../../components/proposal/PriceSummary";
import ProjectDetails from "../../containers/proposal/ProjectDetails";
import Signature from "../../components/proposal/Signature";
import "../../components/proposal/Proposal.less";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import ResourceNotCreated from "../../components/other/ResourceNotCreated";
import ContractorSteps from "./ContractorSteps";
import TermsConditions from "../../containers/proposal/TermsConditions";
import PriceSummaryC from "../../components/proposal/PriceSummaryC";

const { Content } = Layout;

const ContractorProposal: React.FC = () => {
  const history = useHistory();
  const [project, setProject] = useState<any>();
  const [optionPjcc, setOptionPjcc] = useState<any>();
  const [options, setOptions] = useState<any>();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<any>();
  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  useEffect(() => {
    setOptions(
      items[currentProject]?.options
        .filter((option: any) => {
          return (
            option?.pjcc?.find((item: any) => item.name == "Total Labor")
              ?.cost > 0 && option.optionInfo.included === "included"
          );
        })
        ?.map((item: any) => item.optionInfo)
    );
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, [dispatch, items]);

  return (
    <Content>
      <div>
        <ContractorSteps current={0} />
      </div>

      <div className="steps-content">
        {items[currentProject]?.projectInfo?.proposal ? (
          <div>
            <Card style={{ overflowX: "scroll" }}>
              <ProposalHeader
                jobNumber={items[currentProject]?.projectInfo.jobNumber}
                date={items[currentProject]?.projectInfo.proposal.createdAt}
                companyInfo={adminDefaults?.companyInfo}
              />
              <hr
                style={{
                  border: "1px solid #E8E8E8",
                  margin: 0,
                  borderBottom: "0px",
                }}
              />
              <JobSiteHeader
                jobName={items[currentProject]?.projectInfo.jobName}
                address={items[currentProject]?.projectInfo.streetAddressOne}
                telephone={
                  items[currentProject]?.projectInfo.primaryContactTelephone
                }
                email={items[currentProject]?.projectInfo.primaryContactEmail}
                associatePicture={
                  items[currentProject]?.projectInfo.salesAssociate?.picture
                }
                associateName={
                  items[currentProject]?.projectInfo.salesAssociate?.fullName
                }
                associateCell="3176266674"
                associateEmail={
                  items[currentProject]?.projectInfo.salesAssociate?.email
                }
              />
              <PriceSummaryC options={options} />

              <ProjectSummary
                summary={
                  items[currentProject]?.projectInfo.proposal.projectSummary
                }
              />
              <ProjectDetails />
              <hr
                style={{ border: "1px solid #E8E8E8", borderBottom: "0px" }}
              />

              <Signature />
            </Card>
          </div>
        ) : (
          <ResourceNotCreated message="Proposal not created" url="/" />
        )}
      </div>
    </Content>
  );
};

export default ContractorProposal;
