import React, { useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Card, Row, Checkbox, Col, message } from "antd";
import NotesCard from "../../components/notes/NotesCard";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import NotesInput from "../../components/notes/NotesInput";

import { addNotes } from "../../redux/project/action";
import { getAuthUser } from "../../utils/authToken";
import EmailModal from "../email-template-modal/EmailModal";

var crypto = require("crypto");

const Notes: React.FC = () => {
  const dispatch = useDispatch<any>();
  const [sendEmail, setSendEmail] = useState(false);

  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const [showModal, setShowModal] = useState<any>(false);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailBody, setEmailBody] = useState<string>("");
  const [emailRecipients, setEmailRecipients] = useState<string>("");

  const [emailNotes, setEmailNotes] = useState<string>("");

  const user = getAuthUser();

  const handleSubmit = (note: string) => {
    if (/\S/.test(note)) {
      if (sendEmail) {
        setEmailNotes(note);
        const receiverEmails = [];
        switch (user.roles[0]) {
          case "estimator":
            {
              if (items[currentProject]?.projectInfo?.salesAssociate) {
                receiverEmails.push(
                  items[currentProject]?.projectInfo?.salesAssociate.email
                );
              }
              if (items[currentProject]?.projectInfo?.productionAssociate) {
                receiverEmails.push(
                  items[currentProject]?.projectInfo?.productionAssociate.email
                );
              }
            }
            break;
          case "sales_associate":
            {
              if (items[currentProject]?.projectInfo?.estimator) {
                receiverEmails.push(
                  items[currentProject]?.projectInfo?.estimator.email
                );
              }
              if (items[currentProject]?.projectInfo?.productionAssociate) {
                receiverEmails.push(
                  items[currentProject]?.projectInfo?.productionAssociate.email
                );
              }
            }
            break;
          case "production_associate":
            {
              if (items[currentProject]?.projectInfo?.salesAssociate) {
                receiverEmails.push(
                  items[currentProject]?.projectInfo?.salesAssociate.email
                );
              }
              if (items[currentProject]?.projectInfo?.estimator) {
                receiverEmails.push(
                  items[currentProject]?.projectInfo?.estimator.email
                );
              }
            }
            break;
          case "manager":
            {
              if (items[currentProject]?.projectInfo?.salesAssociate) {
                receiverEmails.push(
                  items[currentProject]?.projectInfo?.salesAssociate.email
                );
              }
              if (items[currentProject]?.projectInfo?.estimator) {
                receiverEmails.push(
                  items[currentProject]?.projectInfo?.estimator.email
                );
              }
              if (items[currentProject]?.projectInfo?.productionAssociate) {
                receiverEmails.push(
                  items[currentProject]?.projectInfo?.productionAssociate.email
                );
              }
            }
            break;
          case "admin":
            {
              if (items[currentProject]?.projectInfo?.salesAssociate) {
                receiverEmails.push(
                  items[currentProject]?.projectInfo?.salesAssociate.email
                );
              }
              if (items[currentProject]?.projectInfo?.estimator) {
                receiverEmails.push(
                  items[currentProject]?.projectInfo?.estimator.email
                );
              }
              if (items[currentProject]?.projectInfo?.productionAssociate) {
                receiverEmails.push(
                  items[currentProject]?.projectInfo?.productionAssociate.email
                );
              }
            }
            break;
        }
        setEmailSubject(
          `Notes - ${items[currentProject]?.projectInfo?.jobName}`
        );
        setEmailBody(`Hello, \n ${note} \n\nThank you,\n${user.fullName}`);

        setEmailRecipients(`${receiverEmails.join("; ")}`);
        setShowModal(true);
      } else {
        let _id = crypto.randomBytes(12).toString("hex");
        const payload = {
          _id,
          project: items[currentProject]?.projectId,
          text: note,
          sendEmail,
          user: {
            email: user.email,
            fullName: user.fullName,
            id: user._id,
            roles: user.roles,
            picture: user.picture,
          },
        };

        dispatch(addNotes(payload));
      }
    }
  };

  const handleSendEmail = (body: any) => {
    let _id = crypto.randomBytes(12).toString("hex");
    const payload = {
      ...body,
      _id,
      project: items[currentProject]?.projectId,
      text: emailNotes,
      sendEmail,
      user: {
        email: user.email,
        fullName: user.fullName,
        id: user._id,
        roles: user.roles,
        picture: user.picture,
      },
    };

    dispatch(addNotes(payload));
    message.success("Email Sent");
    setShowModal(false);
  };

  const handleChange = (e: CheckboxChangeEvent) => {
    setSendEmail(e.target.checked);
  };

  return (
    <div style={{ boxShadow: "0px 0px 13px 2px rgba(5, 21, 63, 0.04) " }}>
      <EmailModal
        showModal={showModal}
        setShowModal={setShowModal}
        emailSubject={emailSubject}
        emailBody={emailBody}
        emailRecipients={emailRecipients}
        sendEmail={handleSendEmail}
      />
      <Card
        style={{
          paddingRight: "10px",
        }}
        headStyle={{
          fontWeight: "bold",
          fontSize: "22px",
          lineHeight: "26px",
          background: "white",
        }}
        title="Notes"
      >
        <Row justify="end">
          <Col>
            <Checkbox onChange={handleChange} checked={sendEmail}>
              <h1
                style={{
                  fontSize: 16,
                  lineHeight: "20px",
                  color: "#1F1F1F",
                }}
              >
                Send Email
              </h1>
            </Checkbox>
          </Col>
        </Row>
        {items[currentProject] && (
          <>
            {items[currentProject]?.notes?.map((note: any) => (
              <NotesCard key={note._id} data={note} />
            ))}

            <NotesInput handleSubmit={handleSubmit} />
          </>
        )}
      </Card>
    </div>
  );
};

export default Notes;
