import { useState, useEffect, MouseEvent, useRef } from "react";
import {
  Button,
  Input,
  Menu,
  Card,
  Row,
  Col,
  Checkbox,
  Dropdown,
  Space,
  Form,
  message,
  Upload,
} from "antd";
import { HideArrowIcon, SearchIcon, ShowArrowIcon } from "../../utils/icons";
import "./OptionDetails.less";
import NotesGroup from "../notes-group/NotesGroup";
import ViewPjcc from "../../components/PjccCard/ViewPjcc";
import GroupNotesCard from "../../components/notes/GroupNotesCard";
import { Link, useHistory, useParams } from "react-router-dom";
import Painting from "../../components/option-details/Painting";
import Carpentry from "../../components/option-details/Carpentry";
import Equipment from "../../components/option-details/Equipment";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import Wash from "../../components/option-details/Wash";
import Miscellaneous from "../../components/option-details/Miscellaneous";
import Travel from "../../components/option-details/Travel";
import {
  customToFixed,
  getArrayClone,
  getUpdatedOptionDetails,
} from "../../utils/helpers";
import AddSurface from "../../components/pop-ups/AddSurface";
import { PlusOutlined } from "@ant-design/icons";
import _, { add, isNull, over } from "lodash";
import { getAuthUser, getSelectedTenant } from "../../utils/authToken";
import api from "../../utils/api";
import {
  updateOptionDetails,
  updateProjectOnNewOption,
  addServiceToProject,
  addRatesToOptions,
  updateProjectStateById,
  cloneOptionToProject,
  removeRatesFromOptionsWithIds,
  addPlusUpdateModifiedProjectRateAndOptionRate,
  removeServiceFromProject,
  removeServiceFromProjectOnly,
  undoDeleteOnProjectRates,
  handleAddCaption,
} from "../../redux/project/action";
import moment from "moment";
import OptionNotesSummary from "../../components/option-details/OptionNotesSummary";
import OptionDetailsInput from "../../components/option-details/OptionDetailsInput";
import CustomExpandIcon from "../../components/option-details/CustomExpandIcon";
import CloneOption from "../../components/pop-ups/CloneOption";
import axios from "axios";
import { API_BASE } from "../../constant";
import AddSurfaceInOption from "../../components/pop-ups/AddSurfaceInOption";
import AddDefaultModifiedSurface from "../../components/pop-ups/AddDefaultModifiedSurface";
import { useMediaQuery } from "react-responsive";
import EditMedia from "../option-media/editOptionMedia";
import AddMedia from "../option-media/addOptionMedia";
import Camera from "../option-media/camera";

var crypto = require("crypto");
const { TextArea } = Input;

let paintClone: any;
let carpentryClone: any;
let equipmentClone: any;
let miscClone: any;
let travelClone: any;

type Props = {
  path?: string;
};

const OptionDetailsC = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:900px)" });

  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const dispatch = useDispatch<any>();

  const [form] = Form.useForm();

  const [surface, setSurface] = useState("");
  const [overRideSqft, setOverRideSqft] = useState<boolean>();
  const [showAddSurface, setShowAddSurface] = useState(false);
  const [paintRates, setPaintRates] = useState<any>([]);
  const [carpentryRates, setCarpentryRates] = useState<any>([]);
  const [equipmentRates, setEquipmentRates] = useState<any>([]);
  const [miscellaneousRates, setMiscellaneousRates] = useState<any>([]);
  const [travelRates, setTravelRates] = useState<any>([]);
  const [washRates, setWashRates] = useState<any>({});
  const [washDetails, setWashDetails] = useState<any>({});
  const [washChecked, setWashChecked] = useState<boolean>(false);
  const [showSider, setShowSider] = useState(false);
  const [pjccData, setPjccData] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>();
  const [buildingSearched, setBuildingSearched] = useState("");
  const [setupValue, setSetupValue] = useState<any>(0);
  let [fileList, setFileList] = useState<any>([]);
  const [captionText, setCaptionText] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const carpentryLaborRateConst = 35;
  const user: any = getAuthUser();
  const tenantId = getSelectedTenant();
  const [gm, setGm] = useState<any>([]);
  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const [options, setOptions] = useState(
    items[currentProject]?.options?.map((item: any) => item.optionInfo)
  );

  const [showNotes, setShowNotes] = useState<{
    estimateNotes: boolean;
    workOrderPaintingNotes: boolean;
    workOrderCarpentryNotes: boolean;
  }>({
    estimateNotes: false,
    workOrderPaintingNotes: false,
    workOrderCarpentryNotes: false,
  });

  const [showModalForEstimate, setShowModalForEstimate] = useState(false);

  //Edited Rates Ids
  const [editedPaintRatesIds, setEditedPaintRatesIds] = useState([]);
  const [editedCarpentryRatesIds, setEditedCarpentryRatesIds] = useState([]);
  const [editedEquipmentRatesIds, setEditedEquipmentRatesIds] = useState([]);
  const [editedMiscRatesIds, setEditedMiscRatesIds] = useState([]);
  const [editedTravelRatesIds, setEditedTravelRatesIds] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
    if (online) {
      setLoading(true);
      axios
        .get(
          `${API_BASE}upload-files/option-media/${items[currentProject].projectId}/${id}`,
          {
            headers: {
              Authorization: "Bearer " + user.accessToken,
              "tenant-id": !isNull(tenantId) ? tenantId : "",
            },
          }
        )
        .then((resp) => {
          if (resp?.data?.length > 0) {
            setFileList(resp.data);
          } else {
            setFileList([]);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    const unListen = history.listen(() => {
      saveOption();
    });

    return () => {
      unListen();
    };
  }, [
    selectedOption,
    paintRates,
    carpentryRates,
    miscellaneousRates,
    equipmentRates,
    travelRates,
    washDetails,
    washChecked,
    gm,
    history,
  ]);

  useEffect(() => {
    getOptionRates();
    getPjcc();
  }, [id, items[currentProject].options]);

  useEffect(() => {
    calculatePjCC();
  }, [
    selectedOption?.buildingQuantity,
    selectedOption?.roundGallons,
    selectedOption?.spotPrimeMaterial,
    selectedOption?.squareFeet,
    selectedOption?.washIncluded,
    selectedOption?.overRideSetupHours,
    paintRates,
    carpentryRates,
    miscellaneousRates,
    equipmentRates,
    travelRates,
    washDetails,
    washChecked,
    gm,
  ]);

  useEffect(() => {
    calculateSqFt();
  }, [paintRates]);

  const getPjcc = () => {
    const optionSelected = items[currentProject].options.find(
      (item: any) => item.optionInfo._id === id
    );

    if (optionSelected?.pjcc.length > 0) {
      setPjccData(optionSelected?.pjcc);
      setGm(optionSelected.optionInfo.overRideGrossMargin);
      // items[currentProject].projectInfo.overRideGrossMargin
      //   ? setGm(items[currentProject].projectInfo.overRideGrossMargin)
      //   : setGm(optionSelected.optionInfo.overRideGrossMargin);
    }
  };

  console.log("GM", gm);
  const getOptionRates = () => {
    const optionFind = items[currentProject].options.find(
      (item: any) => item.optionInfo._id === id
    );
    const optionSelected = _.cloneDeep(optionFind);

    const option = {
      ...optionSelected.optionInfo,
      buildingQuantity: optionSelected.optionInfo.buildingQuantity
        ? optionSelected.optionInfo.buildingQuantity
        : 1,
      squareFeet: optionSelected.optionInfo.squareFeet
        ? optionSelected.optionInfo.squareFeet
        : 1,
      roundGallons: optionSelected.optionInfo.roundGallons
        ? optionSelected.optionInfo.roundGallons
        : false,
      spotPrimeMaterial: optionSelected.optionInfo.spotPrimeMaterial
        ? optionSelected.optionInfo.spotPrimeMaterial
        : 0,
    };
    setSetupValue(option.overRideSetupHours ? option.overRideSetupHours : 0);

    setShowNotes({
      estimateNotes: option?.estimatorNotes?.length > 0 ? true : false,
      workOrderPaintingNotes:
        option?.workOrderPaintNotes?.length > 0 ? true : false,
      workOrderCarpentryNotes:
        option?.workOrderCarpentryNotes?.length > 0 ? true : false,
    });
    setSelectedOption(option);
    // dispatch(setCurrentOption(option));

    setTravelRates(optionSelected?.rates?.travelRates);
    travelClone = getArrayClone(optionSelected?.rates?.travelRates);

    setMiscellaneousRates(optionSelected?.rates?.miscellaneousRates);
    miscClone = getArrayClone(optionSelected?.rates?.miscellaneousRates);

    setEquipmentRates(optionSelected?.rates?.equipmentRates);
    equipmentClone = getArrayClone(optionSelected?.rates?.equipmentRates);

    setCarpentryRates(optionSelected?.rates?.carpentryRates);
    carpentryClone = getArrayClone(optionSelected?.rates?.carpentryRates);

    let newPaintRates = getArrayClone(optionSelected?.rates?.paintRates);
    setPaintRates(newPaintRates.filter((item: any) => !item.isDeleted));
    // setPaintRates(optionSelected?.rates?.paintRates);
    paintClone = getArrayClone(optionSelected?.rates?.paintRates);

    setWashRates(adminDefaults?.defaultRates?.washRates[0]); //admin
    setWashChecked(optionSelected?.optionInfo.washIncluded);
    let squareFeet = 0;
    if (optionSelected?.rates?.paintRates.length > 0) {
      for (const item of optionSelected?.rates?.paintRates) {
        squareFeet += item.count;
      }
      if (
        optionSelected?.optionInfo.washIncluded === true &&
        squareFeet !==
        parseFloat(optionSelected?.rates?.washRates[0]?.squareFeet)
      ) {
        setOverRideSqft(true);
      }
    }
    setWashDetails(optionSelected?.rates?.washRates[0]);
  };

  const setPaintState = (data: any) => {
    setPaintRates(data);
  };

  const setCarpentryState = (data: any) => {
    setCarpentryRates(data);
  };
  const setEquipmentState = (data: any) => {
    setEquipmentRates(data);
  };
  const setMiscellaneousState = (data: any) => {
    setMiscellaneousRates(data);
  };

  const setTravelState = (data: any) => {
    setTravelRates(data);
  };

  const handleNext = (e: MouseEvent) => {
    if (captionText.length > 0) {
      dispatch(handleAddCaption(captionText));
    }

    const filteredOptions = options.filter(
      (item: any) => item.included === "included"
    );

    let index = filteredOptions.findIndex((x: any) => x._id === id);

    if (index === filteredOptions.length - 1) {
      index = 0;
    } else {
      index++;
    }

    let nextOption: any = filteredOptions[index];
    if (e.currentTarget.id === "next-pg") {
      if (props.path) {
        history.push(`/${props.path}/${items[currentProject].projectId}`);
      } else {
        history.push(`/estimate-checklists/${items[currentProject].projectId}`);
      }
    } else {
      history.push(
        `/${props.path ? "manager-" : ""}option-details/${nextOption._id}`
      );
    }
  };

  const onRoundChange = (e: any) => {
    setSelectedOption({ ...selectedOption, roundGallons: e.target.checked });
  };

  const onChange = (e: any) => {
    const newData = { ...selectedOption };
    newData.washIncluded = e.target.checked;
    setSelectedOption(newData);
    if (e.target.checked) {
      setWashChecked(true);
    } else {
      setWashChecked(false);
    }
  };

  const handleWashDetails = (data: any) => {
    setWashDetails(data);
  };

  const setOverRideWashSqft = () => {
    setOverRideSqft(true);
  };

  const calculateSqFt = () => {
    let squareFeet = 0;
    if (paintRates !== undefined && Object.keys(washDetails).length !== 0) {
      for (const item of paintRates) {
        squareFeet += item.count;
      }
      if (overRideSqft) {
        squareFeet = washDetails?.squareFeet;
      }
      let totalHours = squareFeet / washDetails?.rate;
      setWashDetails({
        ...washDetails,
        squareFeet: squareFeet,
        totalHours: totalHours.toFixed(2),
      });
    }
  };
  const findBuilding = options?.filter((option: any) => {
    if (!buildingSearched) {
      return true;
    }
    return option.title.toLowerCase().includes(buildingSearched.toLowerCase());
  });

  type IdsListType = {
    paintRatesId: string[];
    carpentryRatesId: string[];
    equipmentRatesId: string[];
    miscRatesId: string[];
    travelRatesId: string[];
  };

  const deleteMultipleSurfaces = (data: any, project: any) => {
    let idsList: IdsListType = {
      paintRatesId: [],
      carpentryRatesId: [],
      equipmentRatesId: [],
      miscRatesId: [],
      travelRatesId: [],
    };
    for (const surface of data.defaults) {
      switch (surface.category) {
        case "paint":
          surface.ratesId.forEach((key: any) => {
            const currentOptionIndex = project.options.findIndex(
              (item: any) => item.optionInfo._id === id
            );
            const index = project.options[
              currentOptionIndex
            ].rates.paintRates.findIndex((item: any) => {
              if (typeof item.defaultRate === "string") {
                return (
                  item.isDeleted === false &&
                  !(item.isCloned || item.modified) &&
                  item.defaultRate === key
                );
              } else {
                return (
                  item.isDeleted === false &&
                  !(item.isCloned || item.modified) &&
                  item.defaultRate._id === key
                );
              }
            });
            if (index !== -1) {
              project.options[currentOptionIndex].rates.paintRates[
                index
              ].isDeleted = true;
              let id =
                project.options[currentOptionIndex].rates.paintRates[index]._id;
              idsList.paintRatesId.push(id);
            }
          });
          break;

        case "carpentry":
          surface.ratesId.forEach((key: any) => {
            const currentOptionIndex = project.options.findIndex(
              (item: any) => item.optionInfo._id === id
            );
            const index = project.options[
              currentOptionIndex
            ].rates.carpentryRates.findIndex((item: any) => {
              if (typeof item.defaultRate === "string") {
                return (
                  item.isDeleted === false &&
                  !(item.isCloned || item.modified) &&
                  item.defaultRate === key
                );
              } else {
                return (
                  item.isDeleted === false &&
                  !(item.isCloned || item.modified) &&
                  item.defaultRate._id === key
                );
              }
            });
            if (index !== -1) {
              project.options[currentOptionIndex].rates.carpentryRates[
                index
              ].isDeleted = true;
              let id =
                project.options[currentOptionIndex].rates.carpentryRates[index]
                  ._id;
              idsList.carpentryRatesId.push(id);
            }
          });
          break;

        case "equipment":
          surface.ratesId.forEach((key: any) => {
            const currentOptionIndex = project.options.findIndex(
              (item: any) => item.optionInfo._id === id
            );
            const index = project.options[
              currentOptionIndex
            ].rates.equipmentRates.findIndex((item: any) => {
              if (typeof item.defaultRate === "string") {
                return (
                  item.isDeleted === false &&
                  !(item.isCloned || item.modified) &&
                  item.defaultRate === key
                );
              } else {
                return (
                  item.isDeleted === false &&
                  !(item.isCloned || item.modified) &&
                  item.defaultRate._id === key
                );
              }
            });
            if (index !== -1) {
              project.options[currentOptionIndex].rates.equipmentRates[
                index
              ].isDeleted = true;
              let id =
                project.options[currentOptionIndex].rates.equipmentRates[index]
                  ._id;
              idsList.equipmentRatesId.push(id);
            }
          });
          break;

        case "miscellaneous":
          surface.ratesId.forEach((key: any) => {
            const currentOptionIndex = project.options.findIndex(
              (item: any) => item.optionInfo._id === id
            );
            const index = project.options[
              currentOptionIndex
            ].rates.miscellaneousRates.findIndex((item: any) => {
              if (typeof item.defaultRate === "string") {
                return (
                  item.isDeleted === false &&
                  !(item.isCloned || item.modified) &&
                  item.defaultRate === key
                );
              } else {
                return (
                  item.isDeleted === false &&
                  !(item.isCloned || item.modified) &&
                  item.defaultRate._id === key
                );
              }
            });
            if (index !== -1) {
              console.log(
                project.options[currentOptionIndex].rates.miscellaneousRates[
                index
                ]
              );
              project.options[currentOptionIndex].rates.miscellaneousRates[
                index
              ].isDeleted = true;
              let id =
                project.options[currentOptionIndex].rates.miscellaneousRates[
                  index
                ]._id;
              idsList.miscRatesId.push(id);
            }
          });
          break;

        case "travel":
          surface.ratesId.forEach((key: any) => {
            const currentOptionIndex = project.options.findIndex(
              (item: any) => item.optionInfo._id === id
            );
            const index = project.options[
              currentOptionIndex
            ].rates.travelRates.findIndex((item: any) => {
              if (typeof item.defaultRate === "string") {
                return (
                  item.isDeleted === false &&
                  !(item.isCloned || item.modified) &&
                  item.defaultRate === key
                );
              } else {
                return (
                  item.isDeleted === false &&
                  !(item.isCloned || item.modified) &&
                  item.defaultRate._id === key
                );
              }
            });
            if (index !== -1) {
              project.options[currentOptionIndex].rates.travelRates[
                index
              ].isDeleted = true;
              let id =
                project.options[currentOptionIndex].rates.travelRates[index]
                  ._id;
              idsList.travelRatesId.push(id);
            }
          });
          break;
      }
    }

    for (const surface of data.modified) {
      switch (surface.category) {
        case "paint":
          surface.ratesId.forEach((key: any) => {
            const currentOptionIndex = project.options.findIndex(
              (item: any) => item.optionInfo._id === id
            );
            const index = project.options[
              currentOptionIndex
            ].rates.paintRates.findIndex(
              (item: any) => item.isDeleted === false && item._id === key
            );
            if (index !== -1) {
              project.options[currentOptionIndex].rates.paintRates[
                index
              ].isDeleted = true;
              let id =
                project.options[currentOptionIndex].rates.paintRates[index]._id;
              idsList.paintRatesId.push(id);
            }
          });
          break;

        case "carpentry":
          surface.ratesId.forEach((key: any) => {
            const currentOptionIndex = project.options.findIndex(
              (item: any) => item.optionInfo._id === id
            );
            const index = project.options[
              currentOptionIndex
            ].rates.carpentryRates.findIndex(
              (item: any) =>
                item.isDeleted === false && item.projectLaborRate === key
            );
            if (index !== -1) {
              project.options[currentOptionIndex].rates.carpentryRates[
                index
              ].isDeleted = true;
              let id =
                project.options[currentOptionIndex].rates.carpentryRates[index]
                  ._id;
              idsList.carpentryRatesId.push(id);
            }
          });
          break;

        case "equipment":
          surface.ratesId.forEach((key: any) => {
            const currentOptionIndex = project.options.findIndex(
              (item: any) => item.optionInfo._id === id
            );
            const index = project.options[
              currentOptionIndex
            ].rates.equipmentRates.findIndex(
              (item: any) =>
                item.isDeleted === false && item.projectLaborRate === key
            );
            if (index !== -1) {
              project.options[currentOptionIndex].rates.equipmentRates[
                index
              ].isDeleted = true;
              let id =
                project.options[currentOptionIndex].rates.equipmentRates[index]
                  ._id;
              idsList.equipmentRatesId.push(id);
            }
          });
          break;

        case "miscellaneous":
          surface.ratesId.forEach((key: any) => {
            const currentOptionIndex = project.options.findIndex(
              (item: any) => item.optionInfo._id === id
            );
            const index = project.options[
              currentOptionIndex
            ].rates.miscellaneousRates.findIndex(
              (item: any) =>
                item.isDeleted === false && item.projectLaborRate === key
            );
            if (index !== -1) {
              project.options[currentOptionIndex].rates.miscellaneousRates[
                index
              ].isDeleted = true;
              let id =
                project.options[currentOptionIndex].rates.miscellaneousRates[
                  index
                ]._id;
              idsList.miscRatesId.push(id);
            }
          });
          break;

        case "travel":
          surface.ratesId.forEach((key: any) => {
            const currentOptionIndex = project.options.findIndex(
              (item: any) => item.optionInfo._id === id
            );
            const index = project.options[
              currentOptionIndex
            ].rates.travelRates.findIndex(
              (item: any) =>
                item.isDeleted === false && item.projectLaborRate === key
            );

            if (index !== -1) {
              project.options[currentOptionIndex].rates.travelRates[
                index
              ].isDeleted = true;
              let id =
                project.options[currentOptionIndex].rates.travelRates[index]
                  ._id;
              idsList.travelRatesId.push(id);
            }
          });
          break;
      }
    }

    dispatch(removeRatesFromOptionsWithIds(idsList, project.projectId));

    return project;
  };

  const addSurfaceToOption = (surfaces: any, deletedArray: any) => {
    let paintRate: any = [];
    let carpentryRate: any = [];
    let equipmentRate: any = [];
    let miscRate: any = [];
    let travelRate: any = [];
    //Convert default rate object to option rate format
    for (const surface of surfaces.defaults) {
      switch (surface.category) {
        case "paint":
          paintRate = surface.rate
            .map((item: any) => {
              const defaultRateId = item._id;
              // delete item._id;
              return {
                ...item,
                option: selectedOption._id,
                type: item.type,
                project: items[currentProject].projectId,
                defaultRate: item,
                count: 0,
                coats: 1,
                primeSelect: "spot",
                paintPricePerGallon: 20,
                primerPricePerGallon: 10,
                paintLaborRate: item.paintLaborMedium,
                primeLaborRate: item.primeLaborMedium,
                prepLaborRate: item.prepLaborMedium,
                prepHours: 0,
                primeHours: 0,
                paintHours: 0,
                primerGallons: 0,
                paintGallons: 0,
                totalPaintHours: 0,
                paintMaterialCost: 0,
                color: "TBD",
                prepLevel: 2,
                paintLevel: 2,
                primeLevel: 2,
                // showLaborRates: item.showLaborRates
              };
            })
            .map((item: any) => {
              delete item.paintLaborEasy;
              delete item.paintLaborHard;
              delete item.paintLaborMedium;
              delete item.prepLaborEasy;
              delete item.prepLaborHard;
              delete item.prepLaborMedium;
              delete item.primeLaborEasy;
              delete item.primeLaborHard;
              delete item.primeLaborMedium;
              delete item.paintPricePerGallon;
              delete item.primerPricePerGallon;
              delete item.checked;
              delete item.createdAt;
              delete item.updatedAt;
              return {
                ...item,
              };
            });
          break;

        case "carpentry":
          carpentryRate = surface.rate
            .map((item: any) => {
              const defaultRateId = item._id;
              // delete item._id;
              return {
                ...item,
                project: items[currentProject].projectId,
                defaultRate: item,
                option: selectedOption._id,
                count: 0,
              };
            })
            .map((item: any) => {
              delete item.checked;
              delete item.createdAt;
              delete item.updatedAt;
              return {
                ...item,
                carpentryMaterial: item.count * item.materialRate,
                carpentryHours:
                  (1.08 * item.count * item.laborRate) /
                  carpentryLaborRateConst,
              };
            });
          break;
        case "equipment":
          equipmentRate = surface.rate
            .map((item: any) => {
              const defaultRateId = item._id;
              // delete item._id;
              return {
                ...item,
                project: items[currentProject].projectId,
                defaultRate: item,
                option: selectedOption._id,
                cost: item.dailyCost,
                type: "daily",
                count: 0,
                periods: 1,
              };
            })
            .map((item: any) => {
              return {
                ...item,
                totalCost:
                  item.count *
                  item.periods *
                  (item.cost + item.puAndDelivery + item.surcharges) *
                  (1 + item.tax / 100),
              };
            });
          break;
        case "travel":
          travelRate = surface.rate
            .map((item: any) => {
              const defaultRateId = item._id;
              // delete item._id;
              return {
                ...item,
                project: items[currentProject].projectId,
                defaultRate: item,
                option: selectedOption._id,
                rfmCount: 0,
                dnCount: 0,
              };
            })
            .map((item: any) => {
              delete item.checked;
              delete item.createdAt;
              delete item.updatedAt;
              return {
                ...item,
                totalCost: item.cost * item.rfmCount * item.dnCount,
              };
            });
          break;
        case "miscellaneous":
          miscRate = surface.rate
            .map((item: any) => {
              const defaultRateIdnewRates = item._id;
              // delete item._id;
              return {
                ...item,
                project: items[currentProject].projectId,
                defaultRate: item,
                option: selectedOption._id,
                count: 0,
              };
            })
            .map((item: any) => {
              delete item.checked;
              delete item.createdAt;
              delete item.updatedAt;
              return {
                ...item,
                totalCost: item.cost * item.count,
              };
            });
          break;
        default:
          break;
      }
    }

    let addedDefaultRatesByCategory = [
      {
        category: "paint",
        rate: paintRate,
      },
      { category: "carpentry", rate: carpentryRate },
      { category: "equipment", rate: equipmentRate },
      { category: "travel", rate: travelRate },
      { category: "miscellaneous", rate: miscRate },
    ];

    let newProject = _.cloneDeep(items[currentProject]);
    let selectedOptionIndex = newProject.options.findIndex(
      (option: any) => option.optionInfo._id.toString() === id
    );

    newProject.options[selectedOptionIndex].optionInfo = {
      ...selectedOption,
    };

    newProject.options[selectedOptionIndex].rates = {
      ...newProject.options[selectedOptionIndex].rates,
      paintRates,
      carpentryRates,
      equipmentRates,
      travelRates,
      miscellaneousRates,
    };

    let newPaintRates = [];
    let newPaintProjectRates = [];
    let newCarpentryRates = [];
    let newProjectCarpentryRates = [];
    let newEquipmentRates = [];
    let newProjectEquipmentRates = [];
    let newMiscellaneousRates = [];
    let newProjectMiscellaneousRates = [];
    let newTravelRates = [];
    let newProjectTravelRates = [];

    // Add default rate to option and project
    for (const defaultRateByCategory of addedDefaultRatesByCategory) {
      switch (defaultRateByCategory.category) {
        case "paint":
          for (const item of defaultRateByCategory.rate) {
            let projectRate = newProject.projectRates.paintRates.find(
              (currentProjectRate: any) =>
                currentProjectRate.defaultRate?._id === item.defaultRate?._id &&
                !(currentProjectRate.modified || currentProjectRate.isCloned)
            );

            if (!projectRate) {
              let _id = crypto.randomBytes(12).toString("hex");
              let projectPaintRate = {
                ...item,
                type: item.type,
                _id,
              };
              newProject.projectRates.paintRates.push(projectPaintRate);
              newPaintProjectRates.push(projectPaintRate);

              for (const singleOption of newProject.options) {
                if (singleOption.optionInfo._id === selectedOption._id) {
                  let optionRateId = crypto.randomBytes(12).toString("hex");
                  singleOption.rates.paintRates = paintRates;
                  singleOption.rates.paintRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: _id,
                  });
                  newPaintRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: _id,
                  });
                  break;
                }
              }
            } else {
              //TODO: edit the project rate to set isDeleted flag to false
              for (const singleOption of newProject.options) {
                if (singleOption.optionInfo._id === selectedOption._id) {
                  let optionRateId = crypto.randomBytes(12).toString("hex");
                  singleOption.rates.paintRates = paintRates;
                  singleOption.rates.paintRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: projectRate._id,
                  });
                  newPaintRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: projectRate._id,
                  });
                  break;
                }
              }
            }
          }
          let oldPaintRates = _.cloneDeep(paintRates);
          const mergedPaintRates = [...oldPaintRates, ...newPaintRates];

          const uniquePaintRates = mergedPaintRates.filter(
            (rate, index, self) =>
              index === self.findIndex((r) => r.id === rate.id)
          );

          setPaintRates(uniquePaintRates);
          toggleAddSurfaceModal(false);

          break;
        case "carpentry":
          for (const item of defaultRateByCategory.rate) {
            let projectRate = newProject.projectRates.carpentryRates.find(
              (entity: any) => entity.defaultRate?._id === item.defaultRate?._id
            );

            if (!projectRate) {
              let _id = crypto.randomBytes(12).toString("hex");
              let projectCarpentryRate = {
                defaultRate: item.defaultRate,
                item: item.item,
                laborRate: item.laborRate,
                materialRate: item.materialRate,
                project: item.project,
                type: item.type,
                _id,
              };
              newProject.projectRates.carpentryRates.push(projectCarpentryRate);
              newProjectCarpentryRates.push(projectCarpentryRate);
              for (const singleOption of newProject.options) {
                if (singleOption.optionInfo._id === selectedOption._id) {
                  let optionRateId = crypto.randomBytes(12).toString("hex");
                  singleOption.rates.carpentryRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: _id,
                  });
                  newCarpentryRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: _id,
                  });
                }
              }
            } else {
              for (const singleOption of newProject.options) {
                if (singleOption.optionInfo._id === selectedOption._id) {
                  let optionRateId = crypto.randomBytes(12).toString("hex");
                  singleOption.rates.carpentryRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: projectRate._id,
                  });
                  newCarpentryRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: projectRate._id,
                  });
                }
              }
            }
          }

          let oldCarpentryRates = _.cloneDeep(carpentryRates);
          const mergedCarpentaryRates = [
            ...oldCarpentryRates,
            ...newCarpentryRates,
          ];

          const uniqueCarpentaryRates = mergedCarpentaryRates.filter(
            (rate, index, self) =>
              index === self.findIndex((r) => r.id === rate.id)
          );

          setCarpentryRates(uniqueCarpentaryRates);
          toggleAddSurfaceModal(false);
          break;
        case "equipment":
          for (const item of defaultRateByCategory.rate) {
            let projectRate = newProject.projectRates.equipmentRates.find(
              (entity: any) => entity.defaultRate?._id === item.defaultRate?._id
            );

            if (!projectRate) {
              let _id = crypto.randomBytes(12).toString("hex");
              let projectEquipmentRate = {
                cost: item.cost,
                defaultRate: item.defaultRate,
                item: item.item,
                project: item.project,
                tax: item.tax,
                type: item.type,
                surcharges: item.surcharges,
                puAndDelivery: item.puAndDelivery,
                _id,
              };
              newProject.projectRates.equipmentRates.push(projectEquipmentRate);
              newProjectEquipmentRates.push(projectEquipmentRate);
              for (const singleOption of newProject.options) {
                if (singleOption.optionInfo._id === selectedOption._id) {
                  let optionRateId = crypto.randomBytes(12).toString("hex");
                  singleOption.rates.equipmentRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: _id,
                  });
                  newEquipmentRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: _id,
                  });
                }
              }
            } else {
              for (const singleOption of newProject.options) {
                if (singleOption.optionInfo._id === selectedOption._id) {
                  let optionRateId = crypto.randomBytes(12).toString("hex");
                  singleOption.rates.equipmentRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: projectRate._id,
                  });
                  newEquipmentRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: projectRate._id,
                  });
                }
              }
            }
          }

          let oldEquipmentRates = _.cloneDeep(equipmentRates);
          const mergedEquipmentRates = [
            ...oldEquipmentRates,
            ...newEquipmentRates,
          ];

          const uniqueEquipmentRates = mergedEquipmentRates.filter(
            (rate, index, self) =>
              index === self.findIndex((r) => r.id === rate.id)
          );

          setEquipmentRates(uniqueEquipmentRates);
          toggleAddSurfaceModal(false);
          break;
        case "miscellaneous":
          for (const item of defaultRateByCategory.rate) {
            let projectRate = newProject.projectRates.miscellaneousRates.find(
              (entity: any) => entity?.defaultRate?._id === item.defaultRate._id
            );

            if (!projectRate) {
              let _id = crypto.randomBytes(12).toString("hex");
              let projectMiscellaneousRate = {
                cost: item.cost,
                defaultRate: item.defaultRate,
                item: item.item,
                project: item.project,
                _id,
              };
              newProject.projectRates.miscellaneousRates.push(
                projectMiscellaneousRate
              );
              newProjectMiscellaneousRates.push(projectMiscellaneousRate);
              for (const singleOption of newProject.options) {
                if (singleOption.optionInfo._id === selectedOption._id) {
                  let optionRateId = crypto.randomBytes(12).toString("hex");
                  singleOption.rates.miscellaneousRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: _id,
                  });
                  newMiscellaneousRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: _id,
                  });
                }
              }
            } else {
              for (const singleOption of newProject.options) {
                if (singleOption.optionInfo._id === selectedOption._id) {
                  let optionRateId = crypto.randomBytes(12).toString("hex");
                  singleOption.rates.miscellaneousRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: projectRate._id,
                  });
                  newMiscellaneousRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: projectRate._id,
                  });
                }
              }
            }
          }

          let oldMiscellaneousRates = _.cloneDeep(miscellaneousRates);
          const mergedMiscellaneousRates = [
            ...oldMiscellaneousRates,
            ...newMiscellaneousRates,
          ];

          const uniqueMiscellaneousRates = mergedMiscellaneousRates.filter(
            (rate, index, self) =>
              index === self.findIndex((r) => r.id === rate.id)
          );
          setMiscellaneousRates(uniqueMiscellaneousRates);
          toggleAddSurfaceModal(false);
          break;
        case "travel":
          for (const item of defaultRateByCategory.rate) {
            let projectRate = newProject.projectRates.travelRates.find(
              (entity: any) => entity.defaultRate?._id === item.defaultRate?._id
            );

            if (!projectRate) {
              let _id = crypto.randomBytes(12).toString("hex");
              let projectTravelRate = {
                cost: item.cost,
                defaultRate: item.defaultRate,
                item: item.item,
                project: item.project,
                _id,
              };
              newProject.projectRates.travelRates.push(projectTravelRate);
              newProjectTravelRates.push(projectTravelRate);
              for (const singleOption of newProject.options) {
                if (singleOption.optionInfo._id === selectedOption._id) {
                  let optionRateId = crypto.randomBytes(12).toString("hex");
                  singleOption.rates.travelRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: _id,
                  });
                  newTravelRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: _id,
                  });
                }
              }
            } else {
              for (const singleOption of newProject.options) {
                if (singleOption.optionInfo._id === selectedOption._id) {
                  let optionRateId = crypto.randomBytes(12).toString("hex");
                  singleOption.rates.travelRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: projectRate._id,
                  });
                  newTravelRates.push({
                    ...item,
                    _id: optionRateId,
                    projectLaborRate: projectRate._id,
                  });
                }
              }
            }
          }

          let oldTravelRates = _.cloneDeep(travelRates);
          const mergedTravelRates = [...oldTravelRates, ...newTravelRates];

          const uniqueTravelRates = mergedTravelRates.filter(
            (rate, index, self) =>
              index === self.findIndex((r) => r.id === rate.id)
          );
          setTravelRates(uniqueTravelRates);
          toggleAddSurfaceModal(false);
          break;
        default:
          break;
      }
    }

    let modifiedPaint = [];
    let modifiedCarpentry = [];
    let modifiedEquipment = [];
    let modifiedMisc = [];
    let modifiedTravel = [];

    let modifiedSurfacePaint: any[] = [];
    let modifiedSurfaceCarpentry: any[] = [];
    let modifiedSurfaceEquipment: any[] = [];
    let modifiedSurfaceMisc: any[] = [];
    let modifiedSurfaceTravel: any[] = [];

    for (const modifiedSurface of surfaces.modified) {
      switch (modifiedSurface.category) {
        case "paint":
          //Edit Project rate if it has isDeleted: true
          modifiedSurface.rate.forEach((item: any) => {
            let index = newProject.projectRates.paintRates.findIndex(
              (e: any) => e._id === item._id
            );
            if (index !== -1) {
              if (newProject.projectRates.paintRates[index].isDeleted) {
                newProject.projectRates.paintRates[index].isDeleted = false;
              }
              modifiedSurfacePaint.push(
                newProject.projectRates.paintRates[index]
              );
            }
          });
          for (const option of newProject.options) {
            if (option.optionInfo._id === id) {
              let reduxObj = [];
              for (const selectedRate of modifiedSurface.rate) {
                let optionPaintRateIndex = option.rates.paintRates.findIndex(
                  (optionPaintRate: any) =>
                    optionPaintRate.projectLaborRate == selectedRate._id
                );
                if (optionPaintRateIndex > 0) {
                  // Rate already added. Set isDeleted to false
                  if (option.rates.paintRates[optionPaintRateIndex].isDeleted) {
                    option.rates.paintRates[optionPaintRateIndex].isDeleted =
                      false;
                  }
                } else {
                  let count = 0;
                  let coats = 1;
                  let color = "TBD";
                  let prepHours = count / selectedRate.prepLaborRate;
                  let primeHours = selectedRate.primeHours
                    ? selectedRate.primeHours
                    : 0;
                  let paintHours =
                    (count * coats) / selectedRate.paintLaborRate;
                  let primerGallons = selectedRate.primerGallons
                    ? selectedRate.primerGallons
                    : 0;
                  let primeSelect = selectedRate.primeSelect
                    ? selectedRate.primeSelect
                    : "spot";
                  let paintGallons =
                    (count * coats) / selectedRate.paintSpreadRate;
                  const projectLaborRate = selectedRate._id;
                  let _id = crypto.randomBytes(12).toString("hex");
                  let newObj = {
                    ...selectedRate,
                    _id,
                    option: option.optionInfo._id,
                    projectLaborRate: projectLaborRate,
                    count,
                    coats,
                    color,
                    prepHours,
                    primeHours,
                    paintHours,
                    primerGallons,
                    primeSelect,
                    paintGallons,
                    totalPaintHours: prepHours + primeHours + paintHours,
                    paintMaterialCost:
                      selectedRate.paintMaterial?.priceAfterTax * paintGallons +
                      selectedRate.primerMaterial?.priceAfterTax *
                      primerGallons,
                  };
                  modifiedPaint.push(newObj);
                  reduxObj.push(newObj);
                }
              }
              option.rates.paintRates.push(...reduxObj);
              break;
            }
          }
          break;

        case "carpentry":
          modifiedSurface.rate.forEach((item: any) => {
            let index = newProject.projectRates.carpentryRates.findIndex(
              (e: any) => e._id === item._id
            );
            if (index !== -1) {
              if (newProject.projectRates.carpentryRates[index].isDeleted) {
                newProject.projectRates.carpentryRates[index].isDeleted = false;
              }
              modifiedSurfaceCarpentry.push(
                newProject.projectRates.carpentryRates[index]
              );
            }
          });
          for (const item of newProject.options) {
            if (item.optionInfo._id === id) {
              let reduxObj = [];
              for (const element of modifiedSurface.rate) {
                let carpentryLaborRateDefaultConst =
                  adminDefaults.wageDefaults.find((item: any) => {
                    return item.title === "Carpentry Wage Rate";
                  });
                let condition = item.rates.carpentryRates.find(
                  (entity: any) => entity.projectLaborRate == element._id
                );
                if (condition) {
                  let optionIndex = item.rates.carpentryRates.findIndex(
                    (entity: any) => entity.projectLaborRate == element._id
                  );
                  if (item.rates.carpentryRates[optionIndex].isDeleted) {
                    item.rates.carpentryRates[optionIndex].isDeleted = false;
                  }
                } else {
                  let count = 0;
                  let carpentryMaterial = count * element.materialRate;
                  let carpentryHours =
                    (1.08 * count * element.laborRate) /
                    carpentryLaborRateDefaultConst.wageValue;
                  const projectLaborRate = element._id;
                  let _id = crypto.randomBytes(12).toString("hex");
                  let newObj = {
                    ...element,
                    _id,
                    option: item.optionInfo._id,
                    projectLaborRate: projectLaborRate,
                    count,
                    carpentryMaterial,
                    carpentryHours,
                  };
                  modifiedCarpentry.push(newObj);
                  reduxObj.push(newObj);
                }
              }
              item.rates.paintRates.push(...reduxObj);
            }
          }
          break;

        case "equipment":
          modifiedSurface.rate.forEach((item: any) => {
            let index = newProject.projectRates.equipmentRates.findIndex(
              (e: any) => e._id === item._id
            );
            if (index !== -1) {
              if (newProject.projectRates.equipmentRates[index].isDeleted) {
                newProject.projectRates.equipmentRates[index].isDeleted = false;
              }
              modifiedSurfaceEquipment.push(
                newProject.projectRates.equipmentRates[index]
              );
            }
          });
          for (const item of newProject.options) {
            if (item.optionInfo._id === id) {
              let reduxObj = [];
              for (const element of modifiedSurface.rate) {
                let condition = item.rates.equipmentRates.find(
                  (entity: any) => entity.projectLaborRate == element._id
                );
                if (condition) {
                  let optionIndex = item.rates.equipmentRates.findIndex(
                    (entity: any) => entity.projectLaborRate == element._id
                  );
                  if (item.rates.equipmentRates[optionIndex].isDeleted) {
                    item.rates.equipmentRates[optionIndex].isDeleted = false;
                  }
                } else {
                  let count = 0;
                  let periods = 1;
                  let item = element.item;
                  let tax = element.tax;
                  let project = element.project;
                  let defaultRate = element.defaultRate;
                  let type = "daily";
                  let cost = element.dailyCost;
                  let option = item.optionInfo._id;
                  let totalCost =
                    count *
                    periods *
                    (cost + element.surcharges + element.puAndDelivery) *
                    (1 + tax / 100);
                  const projectLaborRate = element._id;
                  let _id = crypto.randomBytes(12).toString("hex");
                  let newObj = {
                    _id,
                    projectLaborRate: projectLaborRate,
                    count,
                    periods,
                    item,
                    surcharges: element.surcharges,
                    puAndDelivery: element.puAndDelivery,
                    tax,
                    project,
                    defaultRate,
                    type,
                    cost,
                    option,
                    totalCost,
                  };
                  modifiedEquipment.push(newObj);
                  reduxObj.push(newObj);
                }
              }
              item.rates.paintRates.push(...reduxObj);
            }
          }
          break;

        case "travel":
          modifiedSurface.rate.forEach((item: any) => {
            let index = newProject.projectRates.travelRates.findIndex(
              (e: any) => e._id === item._id
            );
            if (index !== -1) {
              if (newProject.projectRates.travelRates[index].isDeleted) {
                newProject.projectRates.travelRates[index].isDeleted = false;
              }
              modifiedSurfaceTravel.push(
                newProject.projectRates.travelRates[index]
              );
            }
          });
          for (const item of newProject.options) {
            if (item.optionInfo._id === id) {
              let reduxObj = [];
              for (const element of modifiedSurface.rate) {
                let condition = item.rates.travelRates.find(
                  (entity: any) => entity.projectLaborRate == element._id
                );
                if (condition) {
                  let optionIndex = item.rates.travelRates.findIndex(
                    (entity: any) => entity.projectLaborRate == element._id
                  );
                  if (item.rates.travelRates[optionIndex].isDeleted) {
                    item.rates.travelRates[optionIndex].isDeleted = false;
                  }
                } else {
                  let rfmCount = 0;
                  let dnCount = 0;
                  let cost = element.cost;
                  let totalCost = cost * rfmCount * dnCount;
                  const projectLaborRate = element._id;
                  let _id = crypto.randomBytes(12).toString("hex");
                  let newObj = {
                    _id,
                    projectLaborRate: projectLaborRate,
                    rfmCount,
                    dnCount,
                    item: element.item,
                    cost,
                    project: element.project,
                    defaultRate: element.defaultRate,
                    option: item.optionInfo._id,
                    totalCost,
                  };
                  modifiedTravel.push(newObj);
                  reduxObj.push(newObj);
                }
              }
              item.rates.paintRates.push(...reduxObj);
            }
          }
          break;

        case "miscellaneous":
          modifiedSurface.rate.forEach((item: any) => {
            let index = newProject.projectRates.miscellaneousRates.findIndex(
              (e: any) => e._id === item._id
            );
            if (index !== -1) {
              if (newProject.projectRates.miscellaneousRates[index].isDeleted) {
                newProject.projectRates.miscellaneousRates[index].isDeleted =
                  false;
              }
              modifiedSurfaceMisc.push(
                newProject.projectRates.miscellaneousRates[index]
              );
            }
          });
          for (const item of newProject.options) {
            if (item.optionInfo._id === id) {
              let reduxObj = [];
              for (const element of modifiedSurface.rate) {
                let condition = item.rates.miscellaneousRates.find(
                  (entity: any) => entity.projectLaborRate == element._id
                );
                if (condition) {
                  let optionIndex = item.rates.miscellaneousRates.findIndex(
                    (entity: any) => entity.projectLaborRate == element._id
                  );
                  if (item.rates.miscellaneousRates[optionIndex].isDeleted) {
                    item.rates.miscellaneousRates[optionIndex].isDeleted =
                      false;
                  }
                } else {
                  let rfmCount = 0;
                  let dnCount = 0;
                  let cost = element.cost;
                  let totalCost = cost * rfmCount * dnCount;
                  const projectLaborRate = element._id;
                  let _id = crypto.randomBytes(12).toString("hex");
                  let newObj = {
                    _id,
                    projectLaborRate: projectLaborRate,
                    rfmCount,
                    dnCount,
                    item: element.item,
                    cost,
                    project: element.project,
                    defaultRate: element.defaultRate,
                    option: item.optionInfo._id,
                    totalCost,
                  };
                  modifiedMisc.push(newObj);
                  reduxObj.push(newObj);
                }
              }
              item.rates.paintRates.push(...reduxObj);
            }
          }
          break;
      }
    }

    let updatedProject = deleteMultipleSurfaces(deletedArray, newProject);

    dispatch(
      addPlusUpdateModifiedProjectRateAndOptionRate(
        items[currentProject].projectId,
        {
          selectedOptionId: id,
          surface: [
            { category: "paint", rate: modifiedSurfacePaint },
            { category: "carpentry", rate: modifiedSurfaceCarpentry },
            { category: "equipment", rate: modifiedSurfaceEquipment },
            { category: "travel", rate: modifiedSurfaceTravel },
            { category: "miscellaneous", rate: modifiedSurfaceMisc },
          ],
          optionRates: {
            paint: modifiedPaint,
            carpentry: modifiedCarpentry,
            equipment: modifiedEquipment,
            miscellaneous: modifiedMisc,
            travel: modifiedTravel,
          },
        }
      )
    );

    let newProductionPjcc = updatedProject.projectInfo.productionPjcc?.map(
      (item: any) => {
        return {
          ...item,
          productionTargets: null,
        };
      }
    );

    updatedProject.projectInfo.productionPjcc = newProductionPjcc
      ? newProductionPjcc
      : [];

    let newBodyForProjectRates = [
      {
        category: "paint",
        rate: newPaintProjectRates,
      },
      {
        category: "carpentry",
        rate: newProjectCarpentryRates,
      },
      {
        category: "equipment",
        rate: newProjectEquipmentRates,
      },
      {
        category: "travel",
        rate: newProjectTravelRates,
      },
      {
        category: "miscellaneous",
        rate: newProjectMiscellaneousRates,
      },
    ];
    dispatch(
      addServiceToProject(
        items[currentProject].projectId,
        newBodyForProjectRates
      )
    );

    let newBodyForOptionRates = [
      {
        category: "paint",
        rate: newPaintRates,
      },
      {
        category: "carpentry",
        rate: newCarpentryRates,
      },
      {
        category: "equipment",
        rate: newEquipmentRates,
      },
      {
        category: "travel",
        rate: newTravelRates,
      },
      {
        category: "miscellaneous",
        rate: newMiscellaneousRates,
      },
    ];
    dispatch(
      addRatesToOptions(newBodyForOptionRates, newProject, adminDefaults)
    );
  };

  const deepEqualPaintDefault = (
    obj1: any,
    obj2: any,
    depth: number = 0
  ): boolean => {
    // If both are direct values
    if (obj1 === obj2) {
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1).filter(
      (key) =>
        ![
          "customerSuppliedPaint",
          "customerSuppliedPrimer",
          "modified",
          "_id",
        ].includes(key)
    );
    const keys2 = Object.keys(obj2).filter(
      (key) =>
        ![
          "customerSuppliedPaint",
          "customerSuppliedPrimer",
          "modified",
          "_id",
        ].includes(key)
    );

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (
        depth === 1 &&
        (key === "primerMaterial" || key === "paintMaterial")
      ) {
        if (obj1[key]._id !== obj2[key]._id) {
          return false;
        }
        continue;
      }

      if (
        !(
          key === "defaultRate" ||
          key === "primerMaterial" ||
          key === "paintMaterial"
        )
      ) {
        if (
          !keys2.includes(key) ||
          !deepEqual(obj1[key], obj2[key], depth + 1)
        ) {
          return false;
        }
      }
    }

    return true;
  };

  const deepEqualPaint = (obj1: any, obj2: any, depth: number = 0): boolean => {
    // If both are direct values
    if (obj1 === obj2) {
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1).filter(
      (key) =>
        ![
          "customerSuppliedPaint",
          "customerSuppliedPrimer",
          "modified",
        ].includes(key)
    );
    const keys2 = Object.keys(obj2).filter(
      (key) =>
        ![
          "customerSuppliedPaint",
          "customerSuppliedPrimer",
          "modified",
        ].includes(key)
    );

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (
        depth === 1 &&
        (key === "primerMaterial" || key === "paintMaterial")
      ) {
        if (obj1[key]._id !== obj2[key]._id) {
          return false;
        }
        continue;
      }

      if (
        !(
          key === "defaultRate" ||
          key === "primerMaterial" ||
          key === "paintMaterial"
        )
      ) {
        if (
          !keys2.includes(key) ||
          !deepEqual(obj1[key], obj2[key], depth + 1)
        ) {
          return false;
        }
      }
    }

    return true;
  };

  const deepEqualDefault = (
    obj1: any,
    obj2: any,
    depth: number = 0
  ): boolean => {
    // If both are direct values
    if (obj1 == obj2) {
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1).filter(
      (key) => !["modified", "_id"].includes(key)
    );
    const keys2 = Object.keys(obj2).filter(
      (key) => !["modified", "_id"].includes(key)
    );

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (depth === 1) {
        if (obj1[key]._id !== obj2[key]._id) {
          return false;
        }
        continue;
      }

      if (!(key === "defaultRate")) {
        if (
          !keys2.includes(key) ||
          !deepEqual(obj1[key], obj2[key], depth + 1)
        ) {
          return false;
        }
      }
    }

    return true;
  };

  const deepEqual = (obj1: any, obj2: any, depth: number = 0): boolean => {
    // If both are direct values
    if (obj1 == obj2) {
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1).filter(
      (key) => !["modified"].includes(key)
    );
    const keys2 = Object.keys(obj2).filter(
      (key) => !["modified"].includes(key)
    );

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (depth === 1) {
        if (obj1[key]._id !== obj2[key]._id) {
          return false;
        }
        continue;
      }

      if (!(key === "defaultRate")) {
        if (
          !keys2.includes(key) ||
          !deepEqual(obj1[key], obj2[key], depth + 1)
        ) {
          return false;
        }
      }
    }

    return true;
  };

  const deepEqualPaintProjectRate = (
    obj1: any,
    obj2: any,
    depth: number = 0
  ): boolean => {
    // If both are direct values
    if (obj1 === obj2) {
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1).filter(
      (key) =>
        ![
          "_id",
          "customerSuppliedPaint",
          "customerSuppliedPrimer",
          "modified",
          "isDeleted",
        ].includes(key)
    );
    const keys2 = Object.keys(obj2).filter(
      (key) =>
        ![
          "_id",
          "customerSuppliedPaint",
          "customerSuppliedPrimer",
          "modified",
          "isDeleted",
        ].includes(key)
    );

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (depth === 1 && key === "primerMaterial" && obj2[key]?._id) {
        if (obj1[key] !== obj2[key]._id) {
          return false;
        }
        continue;
      }

      if (
        depth === 1 &&
        key === "defaultRate" &&
        obj1[key]?._id &&
        obj2[key]?._id
      ) {
        if (obj1[key]._id !== obj2[key]._id) {
          return false;
        }
        continue;
      }
      if (!(key === "defaultRate" || key === "primerMaterial")) {
        if (
          !keys2.includes(key) ||
          !deepEqualPaintProjectRate(obj1[key], obj2[key], depth + 1)
        ) {
          return false;
        }
      }
    }

    return true;
  };

  const deepEqualProjectRate = (
    obj1: any,
    obj2: any,
    depth: number = 0
  ): boolean => {
    // If both are direct values
    if (obj1 === obj2) {
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1).filter(
      (key) => !["_id", "modified", "isDeleted"].includes(key)
    );
    const keys2 = Object.keys(obj2).filter(
      (key) => !["_id", "modified", "isDeleted"].includes(key)
    );

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (depth === 1) {
        if (obj1[key] !== obj2[key]._id) {
          return false;
        }
        continue;
      }

      if (
        depth === 1 &&
        key === "defaultRate" &&
        obj1[key]?._id &&
        obj2[key]?._id
      ) {
        if (obj1[key]._id !== obj2[key]._id) {
          return false;
        }
        continue;
      }
      if (!(key === "defaultRate")) {
        if (
          !keys2.includes(key) ||
          !deepEqualProjectRate(obj1[key], obj2[key], depth + 1)
        ) {
          return false;
        }
      }
    }

    return true;
  };

  const handleNewPaintLaborRateComparisonWithAllPaintLaborRates = (
    rate: any,
    projectRatesArray: any
  ) => {
    for (let oldObject of projectRatesArray) {
      const updatedOldObject = {
        ...oldObject,
        paintMaterial: oldObject.paintMaterial
          ? oldObject.paintMaterial
          : null,
        primerMaterial: oldObject.primerMaterial
          ? oldObject.primerMaterial
          : null,
      };
      const tempRate = {
        ...rate,
        paintMaterial: rate.paintMaterial ? rate.paintMaterial : null,
        primerMaterial: rate.primerMaterial ? rate.primerMaterial : null,
      };
      if (deepEqualPaintProjectRate(tempRate, updatedOldObject)) {
        return {
          rate: updatedOldObject,
          result: true,
        };
        // return true;
      }
    }
    return {
      rate: rate,
      result: false,
    };
  };

  const handleNewLaborRateComparisonWithAllLaborRates = (
    rate: any,
    projectRatesArray: any
  ) => {
    for (let oldObject of projectRatesArray) {
      const updatedOldObject = {
        ...oldObject,
      };
      const tempRate = {
        ...rate,
      };
      if (deepEqualProjectRate(tempRate, updatedOldObject)) {
        return {
          rate: updatedOldObject,
          result: true,
        };
        // return true;
      }
    }
    return {
      rate: rate,
      result: false,
    };
  };

  const checkIfLaborRateExitsInOtherOptions = (
    rateId: any,
    allOptions: any,
    category: string
  ) => {
    switch (category) {
      case "paint":
        let listOfOptionsForPaint = [];
        for (const singleOption of allOptions) {
          if (singleOption.optionInfo._id !== id) {
            const isFound = singleOption.rates.paintRates.some(
              (item: any) => item.projectLaborRate === rateId._id
            );
            if (isFound) {
              listOfOptionsForPaint.push(singleOption.optionInfo.title);
            }
          }
        }
        return listOfOptionsForPaint;

      case "carpentry":
        let listOfOptionsForCarpentry = [];
        for (const singleOption of allOptions) {
          if (singleOption.optionInfo._id !== id) {
            const isFound = singleOption.rates.carpentryRates.some(
              (item: any) => item.projectLaborRate === rateId._id
            );
            if (isFound) {
              listOfOptionsForCarpentry.push(singleOption.optionInfo.title);
            }
          }
        }
        return listOfOptionsForCarpentry;
      case "equipment":
        let listOfOptionsEquipment = [];
        for (const singleOption of allOptions) {
          if (singleOption.optionInfo._id !== id) {
            const isFound = singleOption.rates.equipmentRates.some(
              (item: any) => item.projectLaborRate === rateId._id
            );
            if (isFound) {
              listOfOptionsEquipment.push(singleOption.optionInfo.title);
            }
          }
        }
        return listOfOptionsEquipment;
      case "travel":
        let listOfOptionsForTravel = [];
        for (const singleOption of allOptions) {
          if (singleOption.optionInfo._id !== id) {
            const isFound = singleOption.rates.travelRates.some(
              (item: any) => item.projectLaborRate === rateId._id
            );
            if (isFound) {
              listOfOptionsForTravel.push(singleOption.optionInfo.title);
            }
          }
        }
        return listOfOptionsForTravel;
      case "miscellaneous":
        let listOfOptionsForMisc = [];
        for (const singleOption of allOptions) {
          if (singleOption.optionInfo._id !== id) {
            const isFound = singleOption.rates.miscellaneousRates.some(
              (item: any) => item.projectLaborRate === rateId._id
            );
            if (isFound) {
              listOfOptionsForMisc.push(singleOption.optionInfo.title);
            }
          }
        }
        return listOfOptionsForMisc;
      default:
        return [];
    }
  };

  const deleteLaborRateFromProject = (
    projectRateId: string,
    category: string
  ) => {
    let project: any = _.cloneDeep(items[currentProject]);
    switch (category) {
      case "paint":
        const indexPaint = project.projectRates.paintRates.findIndex(
          (item: any) => !item.isDeleted && item._id === projectRateId
        );
        if (indexPaint !== -1) {
          project.projectRates.paintRates[indexPaint].isDeleted = true;
          dispatch(
            removeServiceFromProjectOnly(
              projectRateId,
              "paint",
              project,
              adminDefaults
            )
          );
        }
        break;

      case "carpentry":
        const indexCarpentry = project.projectRates.carpentryRates.findIndex(
          (item: any) => !item.isDeleted && item._id === projectRateId
        );
        if (indexCarpentry !== -1) {
          project.projectRates.carpentryRates[indexCarpentry].isDeleted = true;
          dispatch(
            removeServiceFromProjectOnly(
              projectRateId,
              "carpentry",
              project,
              adminDefaults
            )
          );
        }
        break;

      case "equipment":
        const indexEquipment = project.projectRates.equipmentRates.findIndex(
          (item: any) => !item.isDeleted && item._id === projectRateId
        );
        if (indexEquipment !== -1) {
          project.projectRates.equipmentRates[indexEquipment].isDeleted = true;
          dispatch(
            removeServiceFromProjectOnly(
              projectRateId,
              "equipment",
              project,
              adminDefaults
            )
          );
        }
        break;

      case "miscellaneous":
        const indexMiscellaneous =
          project.projectRates.miscellaneousRates.findIndex(
            (item: any) => !item.isDeleted && item._id === projectRateId
          );
        if (indexMiscellaneous !== -1) {
          project.projectRates.miscellaneousRates[
            indexMiscellaneous
          ].isDeleted = true;
          dispatch(
            removeServiceFromProjectOnly(
              projectRateId,
              "miscellaneous",
              project,
              adminDefaults
            )
          );
        }
        break;

      case "travel":
        const indexTravel = project.projectRates.travelRates.findIndex(
          (item: any) => !item.isDeleted && item._id === projectRateId
        );
        if (indexTravel !== -1) {
          project.projectRates.travelRates[indexTravel].isDeleted = true;
          dispatch(
            removeServiceFromProjectOnly(
              projectRateId,
              "travel",
              project,
              adminDefaults
            )
          );
        }
        break;
    }
  };

  const saveOption = () => {
    let reduxProject = _.cloneDeep(items[currentProject]);
    let newPaintProjectRates: any = [];
    let newCarpentryProjectRates: any = [];
    let newEquipmentProjectRates: any = [];
    let newMiscProjectRates: any = [];
    let newTravelProjectRates: any = [];

    //Undo Delete Project Rates List
    let undoDeleteOnPaintProjectRates: any = [];
    let undoDeleteOnCarpentryProjectRates: any = [];
    let undoDeleteOnEquipmentProjectRates: any = [];
    let undoDeleteOnMiscProjectRates: any = [];
    let undoDeleteOnTravelProjectRates: any = [];

    const selectedOptionInfoIndex = reduxProject.options.findIndex(
      (item: any) => item.optionInfo._id === id
    );
    const selectedReduxOptionInfo =
      reduxProject.options[selectedOptionInfoIndex];

    const updateTravelForRedux = travelRates.map((item: any) => {
      return {
        _id: item._id,
        option: selectedOption._id,
        project: items[currentProject].projectId,
        defaultRate: item.defaultRate,
        isCloned: item.isCloned,
        isDeleted: item.isDeleted,
        modified: item.modified,
        projectLaborRate: item.projectLaborRate,
        item: item.item,
        rfmCount: item.rfmCount,
        dnCount: item.dnCount,
        totalCost: item.totalCost,
        cost: item.cost,
        createdBy: user.id,
      };
    });

    const updateTravel = travelRates.map((item: any) => {
      const rateIdExits = editedTravelRatesIds.findIndex(
        (key: any) => key === item._id
      );
      if (rateIdExits !== -1) {
        const reduxRateIndex =
          selectedReduxOptionInfo.rates.travelRates.findIndex(
            (element: any) => element._id === item._id
          );

        const result = deepEqual(
          item,
          selectedReduxOptionInfo.rates.travelRates[reduxRateIndex]
        );
        if (!result) {
          const projectTravelRateId = crypto.randomBytes(12).toString("hex");
          const newProjectTravelRate = {
            project: items[currentProject].projectId,
            defaultRate: item.defaultRate,
            item: item.item,
            cost: +item.cost,
            modified: item.modified,
            isCloned: item.isCloned,
            isDeleted: item.isDeleted,
            __v: item.__v,
            _id: projectTravelRateId,
          };
          const newLaborRateExitsInProjectRates =
            handleNewLaborRateComparisonWithAllLaborRates(
              newProjectTravelRate,
              reduxProject.projectRates.travelRates
            );
          if (newLaborRateExitsInProjectRates.result) {
            if (
              newLaborRateExitsInProjectRates.rate.modified ||
              newLaborRateExitsInProjectRates.rate.isCloned
            ) {
              undoDeleteOnTravelProjectRates.push(
                newLaborRateExitsInProjectRates.rate._id
              );
              return {
                _id: item._id,
                option: selectedOption._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                projectLaborRate: newLaborRateExitsInProjectRates.rate._id,
                item: item.item,
                rfmCount: item.rfmCount,
                dnCount: item.dnCount,
                totalCost: item.totalCost,
                cost: item.cost,
                createdBy: user._id,
                modified: newLaborRateExitsInProjectRates.rate.modified,
                isCloned: newLaborRateExitsInProjectRates.rate.isCloned,
                isDeleted: item.isDeleted,
              };
            } else {
              const previousLaborRateIndex =
                reduxProject.projectRates.travelRates.findIndex(
                  (element: any) => element._id === item.projectLaborRate
                );
              const listOfOptionsInWhichRateExits =
                checkIfLaborRateExitsInOtherOptions(
                  reduxProject.projectRates.travelRates[previousLaborRateIndex],
                  reduxProject.options,
                  "travel"
                );
              if (listOfOptionsInWhichRateExits.length === 0) {
                deleteLaborRateFromProject(
                  reduxProject.projectRates.travelRates[previousLaborRateIndex]
                    ._id,
                  "travel"
                );
              }
              return {
                _id: item._id,
                option: selectedOption._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                projectLaborRate: newLaborRateExitsInProjectRates.rate._id,
                item: item.item,
                rfmCount: item.rfmCount,
                dnCount: item.dnCount,
                totalCost: item.totalCost,
                cost: item.cost,
                createdBy: user._id,
                modified: newLaborRateExitsInProjectRates.rate.modified,
                isCloned: newLaborRateExitsInProjectRates.rate.isCloned,
                isDeleted: item.isDeleted,
              };
            }
          } else {
            const defaultRateOfEditedRate = {
              project: items[currentProject].projectId,
              defaultRate: item.defaultRate,
              item: item.defaultRate.item,
              cost: +item.defaultRate.cost,
              modified: false,
              isCloned: false,
              isDeleted: false,
              __v: item.__v,
              _id: projectTravelRateId,
            };

            const compareDefaultOfThisRate = deepEqualDefault(
              newProjectTravelRate,
              defaultRateOfEditedRate
            );

            if (compareDefaultOfThisRate) {
              newTravelProjectRates.push({
                ...newProjectTravelRate,
                modified: false,
              });

              const previousLaborRateIndex =
                reduxProject.projectRates.travelRates.findIndex(
                  (element: any) => element._id === item.projectLaborRate
                );
              const listOfOptionsInWhichRateExits =
                checkIfLaborRateExitsInOtherOptions(
                  reduxProject.projectRates.travelRates[previousLaborRateIndex],
                  reduxProject.options,
                  "travel"
                );
              if (listOfOptionsInWhichRateExits.length === 0) {
                deleteLaborRateFromProject(
                  reduxProject.projectRates.travelRates[previousLaborRateIndex]
                    ._id,
                  "travel"
                );
              }

              return {
                _id: item._id,
                option: selectedOption._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                projectLaborRate: newProjectTravelRate._id,
                item: item.item,
                rfmCount: item.rfmCount,
                dnCount: item.dnCount,
                totalCost: item.totalCost,
                cost: item.cost,
                createdBy: user._id,
                modified: false,
                isCloned: false,
                isDeleted: item.isDeleted,
              };
            } else {
              newTravelProjectRates.push(newProjectTravelRate);
              return {
                _id: item._id,
                option: selectedOption._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                projectLaborRate: newProjectTravelRate._id,
                item: item.item,
                rfmCount: item.rfmCount,
                dnCount: item.dnCount,
                totalCost: item.totalCost,
                cost: item.cost,
                createdBy: user._id,
                modified: item.modified,
                isCloned: item.isCloned,
                isDeleted: item.isDeleted,
              };
            }
          }
        }
      }
      return {
        _id: item._id,
        option: selectedOption._id,
        project: items[currentProject].projectId,
        defaultRate: item.defaultRate,
        projectLaborRate: item.projectLaborRate,
        item: item.item,
        rfmCount: item.rfmCount,
        dnCount: item.dnCount,
        totalCost: item.totalCost,
        cost: item.cost,
        createdBy: user._id,
        isCloned: item.isCloned,
        isDeleted: item.isDeleted,
        modified: item.modified,
      };
    });
    const updateMiscForRedux = miscellaneousRates.map((item: any) => {
      return {
        _id: item._id,
        option: selectedOption._id,
        project: items[currentProject].projectId,
        defaultRate: item.defaultRate,
        projectLaborRate: item.projectLaborRate,
        createdBy: user._id,
        count: item.count,
        cost: item.cost,
        item: item.item,
        totalCost: item.totalCost,
        isCloned: item.isCloned,
        isDeleted: item.isDeleted,
        modified: item.modified,
      };
    });

    const updateMisc = miscellaneousRates.map((item: any) => {
      const rateIdExits = editedMiscRatesIds.findIndex(
        (key: any) => key === item._id
      );
      if (rateIdExits !== -1) {
        const reduxRateIndex =
          selectedReduxOptionInfo.rates.miscellaneousRates.findIndex(
            (element: any) => element._id === item._id
          );

        const result = deepEqual(
          item,
          selectedReduxOptionInfo.rates.miscellaneousRates[reduxRateIndex]
        );
        if (!result) {
          const projectMiscRateId = crypto.randomBytes(12).toString("hex");
          const newProjectMiscRate = {
            cost: +item.cost,
            item: item.item,
            project: items[currentProject].projectId,
            defaultRate: item.defaultRate,
            modified: item.modified,
            isCloned: item.isCloned,
            isDeleted: item.isDeleted,
            __v: item.__v,
            _id: projectMiscRateId,
          };
          const newLaborRateExitsInProjectRates =
            handleNewLaborRateComparisonWithAllLaborRates(
              newProjectMiscRate,
              reduxProject.projectRates.miscellaneousRates
            );
          if (newLaborRateExitsInProjectRates.result) {
            if (
              newLaborRateExitsInProjectRates.rate.modified ||
              newLaborRateExitsInProjectRates.rate.isCloned
            ) {
              undoDeleteOnMiscProjectRates.push(
                newLaborRateExitsInProjectRates.rate._id
              );
              return {
                _id: item._id,
                option: selectedOption._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                projectLaborRate: newLaborRateExitsInProjectRates.rate._id,
                createdBy: user._id,
                count: item.count,
                cost: item.cost,
                item: item.item,
                totalCost: item.totalCost,
                modified: newLaborRateExitsInProjectRates.rate.modified,
                isCloned: newLaborRateExitsInProjectRates.rate.isCloned,
                isDeleted: item.isDeleted,
              };
            } else {
              const previousLaborRateIndex =
                reduxProject.projectRates.miscellaneousRates.findIndex(
                  (element: any) => element._id === item.projectLaborRate
                );
              const listOfOptionsInWhichRateExits =
                checkIfLaborRateExitsInOtherOptions(
                  reduxProject.projectRates.miscellaneousRates[
                  previousLaborRateIndex
                  ],
                  reduxProject.options,
                  "miscellaneous"
                );
              if (listOfOptionsInWhichRateExits.length === 0) {
                deleteLaborRateFromProject(
                  reduxProject.projectRates.miscellaneousRates[
                    previousLaborRateIndex
                  ]._id,
                  "miscellaneous"
                );
              }
              return {
                _id: item._id,
                option: selectedOption._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                projectLaborRate: newLaborRateExitsInProjectRates.rate._id,
                createdBy: user._id,
                count: item.count,
                cost: item.cost,
                item: item.item,
                totalCost: item.totalCost,
                modified: newLaborRateExitsInProjectRates.rate.modified,
                isCloned: newLaborRateExitsInProjectRates.rate.isCloned,
                isDeleted: item.isDeleted,
              };
            }
          } else {
            const defaultRateOfEditedRate = {
              cost: +item.defaultRate.cost,
              item: item.defaultRate.item,
              project: items[currentProject].projectId,
              defaultRate: item.defaultRate,
              modified: false,
              isCloned: false,
              isDeleted: false,
              __v: item.__v,
              _id: projectMiscRateId,
            };

            const compareDefaultOfThisRate = deepEqualDefault(
              newProjectMiscRate,
              defaultRateOfEditedRate
            );

            if (compareDefaultOfThisRate) {
              newMiscProjectRates.push({
                ...newProjectMiscRate,
                modified: false,
              });
              const previousLaborRateIndex =
                reduxProject.projectRates.miscellaneousRates.findIndex(
                  (element: any) => element._id === item.projectLaborRate
                );
              const listOfOptionsInWhichRateExits =
                checkIfLaborRateExitsInOtherOptions(
                  reduxProject.projectRates.miscellaneousRates[
                  previousLaborRateIndex
                  ],
                  reduxProject.options,
                  "miscellaneous"
                );
              if (listOfOptionsInWhichRateExits.length === 0) {
                deleteLaborRateFromProject(
                  reduxProject.projectRates.miscellaneousRates[
                    previousLaborRateIndex
                  ]._id,
                  "miscellaneous"
                );
              }
              return {
                _id: item._id,
                option: selectedOption._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                projectLaborRate: newProjectMiscRate._id,
                createdBy: user._id,
                count: item.count,
                cost: item.cost,
                item: item.item,
                totalCost: item.totalCost,
                modified: false,
                isCloned: false,
                isDeleted: item.isDeleted,
              };
            } else {
              newMiscProjectRates.push(newProjectMiscRate);
              return {
                _id: item._id,
                option: selectedOption._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                projectLaborRate: newProjectMiscRate._id,
                createdBy: user._id,
                count: item.count,
                cost: item.cost,
                item: item.item,
                totalCost: item.totalCost,
                modified: item.modified,
                isCloned: item.isCloned,
                isDeleted: item.isDeleted,
              };
            }
          }
        }
      }
      return {
        _id: item._id,
        option: selectedOption._id,
        project: items[currentProject].projectId,
        defaultRate: item.defaultRate,
        projectLaborRate: item.projectLaborRate,
        createdBy: user._id,
        count: item.count,
        cost: item.cost,
        item: item.item,
        totalCost: item.totalCost,
        isCloned: item.isCloned,
        isDeleted: item.isDeleted,
        modified: item.modified,
      };
    });

    const updateEquipmentForRedux = equipmentRates.map((item: any) => {
      return {
        _id: item._id,
        option: selectedOption._id,
        project: items[currentProject].projectId,
        defaultRate: item.defaultRate,
        projectLaborRate: item.projectLaborRate,
        item: item.item,
        type: item.type,
        count: item.count,
        surcharges: item.surcharges,
        puAndDelivery: item.puAndDelivery,
        periods: item.periods,
        cost: item.cost,
        totalCost: item.totalCost,
        tax: item.tax,
        createdBy: user._id,
        isCloned: item.isCloned,
        isDeleted: item.isDeleted,
        modified: item.modified,
      };
    });
    const updateEquipment = equipmentRates.map((item: any) => {
      const rateIdExits = editedEquipmentRatesIds.findIndex(
        (key: any) => key === item._id
      );
      if (rateIdExits !== -1) {
        const reduxRateIndex =
          selectedReduxOptionInfo.rates.equipmentRates.findIndex(
            (element: any) => element._id === item._id
          );

        const result = deepEqual(
          item,
          selectedReduxOptionInfo.rates.equipmentRates[reduxRateIndex]
        );
        if (!result) {
          const projectEquipmentRateId = crypto.randomBytes(12).toString("hex");
          const newProjectEquipmentRate = {
            surcharges: +item.surcharges,
            puAndDelivery: +item.puAndDelivery,
            cost: +item.cost,
            tax: +item.tax,
            type: item.type,
            item: item.item,
            project: items[currentProject].projectId,
            defaultRate: item.defaultRate,
            modified: item.modified,
            isCloned: item.isCloned,
            isDeleted: item.isDeleted,
            __v: item.__v,
            _id: projectEquipmentRateId,
          };
          const newLaborRateExitsInProjectRates =
            handleNewLaborRateComparisonWithAllLaborRates(
              newProjectEquipmentRate,
              reduxProject.projectRates.equipmentRates
            );
          if (newLaborRateExitsInProjectRates.result) {
            if (
              newLaborRateExitsInProjectRates.rate.modified ||
              newLaborRateExitsInProjectRates.rate.isCloned
            ) {
              undoDeleteOnEquipmentProjectRates.push(
                newLaborRateExitsInProjectRates.rate._id
              );
              return {
                _id: item._id,
                option: selectedOption._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                projectLaborRate: newLaborRateExitsInProjectRates.rate._id,
                item: item.item,
                type: item.type,
                count: item.count,
                surcharges: item.surcharges,
                puAndDelivery: item.puAndDelivery,
                periods: item.periods,
                cost: item.cost,
                totalCost: item.totalCost,
                tax: item.tax,
                createdBy: user._id,
                modified: newLaborRateExitsInProjectRates.rate.modified,
                isCloned: newLaborRateExitsInProjectRates.rate.isCloned,
                isDeleted: item.isDeleted,
              };
            } else {
              const previousLaborRateIndex =
                reduxProject.projectRates.equipmentRates.findIndex(
                  (element: any) => element._id === item.projectLaborRate
                );
              const listOfOptionsInWhichRateExits =
                checkIfLaborRateExitsInOtherOptions(
                  reduxProject.projectRates.equipmentRates[
                  previousLaborRateIndex
                  ],
                  reduxProject.options,
                  "equipment"
                );
              if (listOfOptionsInWhichRateExits.length === 0) {
                deleteLaborRateFromProject(
                  reduxProject.projectRates.equipmentRates[
                    previousLaborRateIndex
                  ]._id,
                  "equipment"
                );
              }
              return {
                _id: item._id,
                option: selectedOption._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                projectLaborRate: newLaborRateExitsInProjectRates.rate._id,
                item: item.item,
                type: item.type,
                count: item.count,
                surcharges: item.surcharges,
                puAndDelivery: item.puAndDelivery,
                periods: item.periods,
                cost: item.cost,
                totalCost: item.totalCost,
                tax: item.tax,
                createdBy: user._id,
                modified: newLaborRateExitsInProjectRates.rate.modified,
                isCloned: newLaborRateExitsInProjectRates.rate.isCloned,
                isDeleted: item.isDeleted,
              };
            }
          } else {
            const defaultRateOfEditedRate = {
              surcharges: +item.defaultRate.surcharges,
              puAndDelivery: +item.defaultRate.puAndDelivery,
              cost: +item.defaultRate.cost,
              tax: +item.defaultRate.tax,
              type: item.defaultRate.type,
              item: item.defaultRate.item,
              project: items[currentProject].projectId,
              defaultRate: item.defaultRate,
              modified: false,
              isCloned: false,
              isDeleted: false,
              __v: item.__v,
              _id: projectEquipmentRateId,
            };

            const compareDefaultOfThisRate = deepEqualDefault(
              newProjectEquipmentRate,
              defaultRateOfEditedRate
            );

            if (compareDefaultOfThisRate) {
              newEquipmentProjectRates.push({
                ...newProjectEquipmentRate,
                modified: false,
              });
              const previousLaborRateIndex =
                reduxProject.projectRates.equipmentRates.findIndex(
                  (element: any) => element._id === item.projectLaborRate
                );
              const listOfOptionsInWhichRateExits =
                checkIfLaborRateExitsInOtherOptions(
                  reduxProject.projectRates.equipmentRates[
                  previousLaborRateIndex
                  ],
                  reduxProject.options,
                  "equipment"
                );
              if (listOfOptionsInWhichRateExits.length === 0) {
                deleteLaborRateFromProject(
                  reduxProject.projectRates.equipmentRates[
                    previousLaborRateIndex
                  ]._id,
                  "equipment"
                );
              }

              return {
                _id: item._id,
                option: selectedOption._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                projectLaborRate: newProjectEquipmentRate._id,
                item: item.item,
                type: item.type,
                count: item.count,
                surcharges: item.surcharges,
                puAndDelivery: item.puAndDelivery,
                periods: item.periods,
                cost: item.cost,
                totalCost: item.totalCost,
                tax: item.tax,
                createdBy: user._id,
                modified: false,
                isCloned: false,
                isDeleted: item.isDeleted,
              };
            } else {
              newEquipmentProjectRates.push(newProjectEquipmentRate);
              return {
                _id: item._id,
                option: selectedOption._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                projectLaborRate: newProjectEquipmentRate._id,
                item: item.item,
                type: item.type,
                count: item.count,
                surcharges: item.surcharges,
                puAndDelivery: item.puAndDelivery,
                periods: item.periods,
                cost: item.cost,
                totalCost: item.totalCost,
                tax: item.tax,
                createdBy: user._id,
                modified: item.modified,
                isCloned: item.isCloned,
                isDeleted: item.isDeleted,
              };
            }
          }
        }
      }
      return {
        _id: item._id,
        option: selectedOption._id,
        project: items[currentProject].projectId,
        defaultRate: item.defaultRate,
        projectLaborRate: item.projectLaborRate,
        item: item.item,
        type: item.type,
        count: item.count,
        surcharges: item.surcharges,
        puAndDelivery: item.puAndDelivery,
        periods: item.periods,
        cost: item.cost,
        totalCost: item.totalCost,
        tax: item.tax,
        createdBy: user._id,
        isCloned: item.isCloned,
        isDeleted: item.isDeleted,
        modified: item.modified,
      };
    });
    const updateCarpentryForRedux = carpentryRates.map((item: any) => {
      return {
        _id: item._id,
        project: items[currentProject].projectId,
        defaultRate: item.defaultRate,
        option: selectedOption._id,
        item: item.item,
        projectLaborRate: item.projectLaborRate,
        count: item.count,
        carpentryHours: item.carpentryHours,
        laborRate: item.laborRate,
        materialRate: item.materialRate,
        carpentryMaterial: item.carpentryMaterial,
        createdBy: user.id,
        isCloned: item.isCloned,
        isDeleted: item.isDeleted,
        modified: item.modified,
      };
    });

    const updateCarpentry = carpentryRates.map((item: any) => {
      const rateIdExits = editedCarpentryRatesIds.findIndex(
        (key: any) => key === item._id
      );
      if (rateIdExits !== -1) {
        const reduxRateIndex =
          selectedReduxOptionInfo.rates.carpentryRates.findIndex(
            (element: any) => element._id === item._id
          );

        const result = deepEqual(
          item,
          selectedReduxOptionInfo.rates.carpentryRates[reduxRateIndex]
        );
        if (!result) {
          const projectCarpentryRateId = crypto.randomBytes(12).toString("hex");
          const newProjectCarpentryRate = {
            laborRate: +item.laborRate,
            materialRate: +item.materialRate,
            type: item.type,

            item: item.item,
            project: items[currentProject].projectId,
            defaultRate: item.defaultRate,
            modified: item.modified,
            isCloned: item.isCloned,
            isDeleted: item.isDeleted,
            __v: item.__v,
            _id: projectCarpentryRateId,
          };
          const newLaborRateExitsInProjectRates =
            handleNewLaborRateComparisonWithAllLaborRates(
              newProjectCarpentryRate,
              reduxProject.projectRates.carpentryRates
            );
          if (newLaborRateExitsInProjectRates.result) {
            if (
              newLaborRateExitsInProjectRates.rate.modified ||
              newLaborRateExitsInProjectRates.rate.isCloned
            ) {
              undoDeleteOnCarpentryProjectRates.push(
                newLaborRateExitsInProjectRates.rate._id
              );
              return {
                _id: item._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                option: selectedOption._id,
                item: item.item,
                type: item.type,
                projectLaborRate: newLaborRateExitsInProjectRates.rate._id,
                count: item.count,
                carpentryHours: item.carpentryHours,
                laborRate: item.laborRate,
                materialRate: item.materialRate,
                carpentryMaterial: item.carpentryMaterial,
                createdBy: user._id,
                modified: newLaborRateExitsInProjectRates.rate.modified,
                isCloned: newLaborRateExitsInProjectRates.rate.isCloned,
                isDeleted: item.isDeleted,
              };
            } else {
              const previousLaborRateIndex =
                reduxProject.projectRates.carpentryRates.findIndex(
                  (element: any) => element._id === item.projectLaborRate
                );
              const listOfOptionsInWhichRateExits =
                checkIfLaborRateExitsInOtherOptions(
                  reduxProject.projectRates.carpentryRates[
                  previousLaborRateIndex
                  ],
                  reduxProject.options,
                  "carpentry"
                );
              if (listOfOptionsInWhichRateExits.length === 0) {
                deleteLaborRateFromProject(
                  reduxProject.projectRates.carpentryRates[
                    previousLaborRateIndex
                  ]._id,
                  "carpentry"
                );
              }
              return {
                _id: item._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                option: selectedOption._id,
                item: item.item,
                type: item.type,
                projectLaborRate: newLaborRateExitsInProjectRates.rate._id,
                count: item.count,
                carpentryHours: item.carpentryHours,
                laborRate: item.laborRate,
                materialRate: item.materialRate,
                carpentryMaterial: item.carpentryMaterial,
                createdBy: user._id,
                modified: newLaborRateExitsInProjectRates.rate.modified,
                isCloned: newLaborRateExitsInProjectRates.rate.isCloned,
                isDeleted: item.isDeleted,
              };
            }
          } else {
            const defaultRateOfEditedRate = {
              laborRate: +item.defaultRate.laborRate,
              materialRate: +item.defaultRate.materialRate,
              type: item.defaultRate.type,
              item: item.defaultRate.item,
              project: items[currentProject].projectId,
              defaultRate: item.defaultRate,
              modified: false,
              isCloned: false,
              isDeleted: false,
              __v: item.__v,
              _id: projectCarpentryRateId,
            };

            const compareDefaultOfThisRate = deepEqualDefault(
              newProjectCarpentryRate,
              defaultRateOfEditedRate
            );
            if (compareDefaultOfThisRate) {
              newCarpentryProjectRates.push({
                ...newProjectCarpentryRate,
                modified: false,
              });

              const previousLaborRateIndex =
                reduxProject.projectRates.carpentryRates.findIndex(
                  (element: any) => element._id === item.projectLaborRate
                );
              const listOfOptionsInWhichRateExits =
                checkIfLaborRateExitsInOtherOptions(
                  reduxProject.projectRates.carpentryRates[
                  previousLaborRateIndex
                  ],
                  reduxProject.options,
                  "carpentry"
                );
              if (listOfOptionsInWhichRateExits.length === 0) {
                deleteLaborRateFromProject(
                  reduxProject.projectRates.carpentryRates[
                    previousLaborRateIndex
                  ]._id,
                  "carpentry"
                );
              }

              return {
                _id: item._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                option: selectedOption._id,
                item: item.item,
                type: item.type,
                projectLaborRate: newProjectCarpentryRate._id,
                count: item.count,
                carpentryHours: item.carpentryHours,
                laborRate: item.laborRate,
                materialRate: item.materialRate,
                carpentryMaterial: item.carpentryMaterial,
                createdBy: user._id,
                modified: false,
                isCloned: false,
                isDeleted: item.isDeleted,
              };
            } else {
              newCarpentryProjectRates.push(newProjectCarpentryRate);
              return {
                _id: item._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                option: selectedOption._id,
                item: item.item,
                type: item.type,
                projectLaborRate: newProjectCarpentryRate._id,
                count: item.count,
                carpentryHours: item.carpentryHours,
                laborRate: item.laborRate,
                materialRate: item.materialRate,
                carpentryMaterial: item.carpentryMaterial,
                createdBy: user._id,
                modified: item.modified,
                isCloned: item.isCloned,
                isDeleted: item.isDeleted,
              };
            }
          }
        }
      }
      return {
        _id: item._id,
        project: items[currentProject].projectId,
        defaultRate: item.defaultRate,
        option: selectedOption._id,
        item: item.item,
        projectLaborRate: item.projectLaborRate,
        count: item.count,
        carpentryHours: item.carpentryHours,
        laborRate: item.laborRate,
        materialRate: item.materialRate,
        carpentryMaterial: item.carpentryMaterial,
        createdBy: user._id,
        isCloned: item.isCloned,
        isDeleted: item.isDeleted,
        modified: item.modified,
      };
    });
    const updatePaintForRedux = paintRates.map((item: any) => {
      return {
        _id: item._id,
        project: items[currentProject].projectId,
        defaultRate: item.defaultRate,
        option: selectedOption._id,
        projectLaborRate: item.projectLaborRate,
        item: item.item,
        showLaborRates: item.showLaborRates,
        count: item.count,
        primeSelect: item.primeSelect,
        coats: item.coats,
        paintMaterial: item.paintMaterial,
        color: item.color,
        primerMaterial: item.primerMaterial,
        prepHours: item.prepHours,
        primeHours: item.primeHours,
        paintHours: item.paintHours,
        totalPaintHours: item.totalPaintHours,
        primerGallons: item.primerGallons,
        paintGallons: item.paintGallons,
        paintMaterialCost: item.paintMaterialCost,
        primeLaborRate: item.primeLaborRate,
        paintLaborRate: item.paintLaborRate,
        prepLaborRate: item.prepLaborRate,
        paintSpreadRate: item.paintSpreadRate,
        primerSpreadRate: item.primerSpreadRate,
        prepLevel: item.prepLevel,
        paintLevel: item.paintLevel,
        primeLevel: item.primeLevel,
        paintPricePerGallon: item.paintPricePerGallon,
        primerPricePerGallon: item.primerPricePerGallon,
        customerSuppliedPaint: item.customerSuppliedPaint,
        customerSuppliedPrimer: item.customerSuppliedPrimer,
        createdBy: user.id,
        isCloned: item.isCloned,
        isDeleted: item.isDeleted,
        modified: item.modified,
      };
    });

    const updatePaint = paintRates.map((item: any) => {
      const rateIdExits = editedPaintRatesIds.findIndex(
        (key: any) => key === item._id
      );

      // Edits found
      if (rateIdExits !== -1) {
        const reduxRateIndex =
          selectedReduxOptionInfo.rates.paintRates.findIndex(
            (element: any) => element._id === item._id
          );

        const result = deepEqualPaint(
          item,
          selectedReduxOptionInfo.rates.paintRates[reduxRateIndex]
        );
        if (!result) {
          const projectPaintRateId = crypto.randomBytes(12).toString("hex");
          const newProjectPaintRate = {
            customerSuppliedPaint: item.customerSuppliedPaint,
            customerSuppliedPrimer: item.customerSuppliedPrimer,
            defaultRate: item.defaultRate,
            isCloned: item.isCloned,
            isDeleted: item.isDeleted,
            item: item.item,
            modified: item.modified,
            paintLaborRate: item.paintLaborRate,
            paintLevel: item.paintLevel,
            paintMaterial: item.paintMaterial,
            paintSpreadRate: item.paintSpreadRate,
            prepLaborRate: item.prepLaborRate,
            prepLevel: item.prepLevel,
            primeLaborRate: item.primeLaborRate,
            primeLevel: item.primeLevel,
            primerMaterial: item.primerMaterial,
            primerSpreadRate: item.primerSpreadRate,
            showLaborRates: item.showLaborRates,
            project: item.project,
            type: item.type,
            __v: item.__v,
            _id: projectPaintRateId,
          };
          const newLaborRateExitsInProjectRates =
            handleNewPaintLaborRateComparisonWithAllPaintLaborRates(
              newProjectPaintRate,
              reduxProject.projectRates.paintRates
            );
          if (newLaborRateExitsInProjectRates.result) {
            if (
              newLaborRateExitsInProjectRates.rate.modified ||
              newLaborRateExitsInProjectRates.rate.isCloned
            ) {
              undoDeleteOnPaintProjectRates.push(
                newLaborRateExitsInProjectRates.rate._id
              );
              return {
                _id: item._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                option: selectedOption._id,
                projectLaborRate: newLaborRateExitsInProjectRates.rate._id,
                item: item.item,
                showLaborRates: item.showLaborRates,
                count: item.count,
                primeSelect: item.primeSelect,
                coats: item.coats,
                paintMaterial: item.paintMaterial,
                color: item.color,
                primerMaterial: item.primerMaterial,
                prepHours: item.prepHours,
                primeHours: item.primeHours,
                paintHours: item.paintHours,
                totalPaintHours: item.totalPaintHours,
                primerGallons: item.primerGallons,
                paintGallons: item.paintGallons,
                paintMaterialCost: item.paintMaterialCost,
                primeLaborRate: item.primeLaborRate,
                paintLaborRate: item.paintLaborRate,
                prepLaborRate: item.prepLaborRate,
                paintSpreadRate: item.paintSpreadRate,
                primerSpreadRate: item.primerSpreadRate,
                prepLevel: item.prepLevel,
                paintLevel: item.paintLevel,
                primeLevel: item.primeLevel,
                paintPricePerGallon: item.paintPricePerGallon,
                primerPricePerGallon: item.primerPricePerGallon,
                customerSuppliedPaint: item.customerSuppliedPaint,
                customerSuppliedPrimer: item.customerSuppliedPrimer,
                createdBy: user.id,
                modified: newLaborRateExitsInProjectRates.rate.modified,
                isCloned: newLaborRateExitsInProjectRates.rate.isCloned,
                isDeleted: item.isDeleted,
              };
            } else {
              const previousLaborRateIndex =
                reduxProject.projectRates.paintRates.findIndex(
                  (element: any) => element._id === item.projectLaborRate
                );
              const listOfOptionsInWhichRateExits =
                checkIfLaborRateExitsInOtherOptions(
                  reduxProject.projectRates.paintRates[previousLaborRateIndex],
                  reduxProject.options,
                  "paint"
                );
              if (listOfOptionsInWhichRateExits.length === 0) {
                deleteLaborRateFromProject(
                  reduxProject.projectRates.paintRates[previousLaborRateIndex]
                    ._id,
                  "paint"
                );
              }
              return {
                _id: item._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                option: selectedOption._id,
                projectLaborRate: newLaborRateExitsInProjectRates.rate._id,
                item: item.item,
                showLaborRates: item.showLaborRates,
                count: item.count,
                primeSelect: item.primeSelect,
                coats: item.coats,
                paintMaterial: item.paintMaterial,
                color: item.color,
                primerMaterial: item.primerMaterial,
                prepHours: item.prepHours,
                primeHours: item.primeHours,
                paintHours: item.paintHours,
                totalPaintHours: item.totalPaintHours,
                primerGallons: item.primerGallons,
                paintGallons: item.paintGallons,
                paintMaterialCost: item.paintMaterialCost,
                primeLaborRate: item.primeLaborRate,
                paintLaborRate: item.paintLaborRate,
                prepLaborRate: item.prepLaborRate,
                paintSpreadRate: item.paintSpreadRate,
                primerSpreadRate: item.primerSpreadRate,
                prepLevel: item.prepLevel,
                paintLevel: item.paintLevel,
                primeLevel: item.primeLevel,
                paintPricePerGallon: item.paintPricePerGallon,
                primerPricePerGallon: item.primerPricePerGallon,
                customerSuppliedPaint: item.customerSuppliedPaint,
                customerSuppliedPrimer: item.customerSuppliedPrimer,
                createdBy: user.id,
                modified: newLaborRateExitsInProjectRates.rate.modified,
                isCloned: newLaborRateExitsInProjectRates.rate.isCloned,
                isDeleted: item.isDeleted,
              };
            }
          } else {
            const defaultRateOfEditedRate = {
              customerSuppliedPaint: false,
              customerSuppliedPrimer: false,
              defaultRate: item.defaultRate,
              isCloned: false,
              isDeleted: false,
              item: item.defaultRate.item,
              modified: false,
              paintMaterial: item.defaultRate.paintMaterial,
              paintSpreadRate: item.defaultRate.paintSpreadRate,
              primerMaterial: item.defaultRate.primerMaterial,
              primerSpreadRate: item.defaultRate.primerSpreadRate,
              paintLaborRate: item.defaultRate.paintLaborMedium,
              primeLaborRate: item.defaultRate.primeLaborMedium,
              prepLaborRate: item.defaultRate.prepLaborMedium,
              prepLevel: 2,
              paintLevel: 2,
              primeLevel: 2,
              project: item.project,
              type: item.defaultRate.type,
              __v: item.__v,
              _id: projectPaintRateId,
            };

            const compareDefaultOfThisRate = deepEqualPaintDefault(
              newProjectPaintRate,
              defaultRateOfEditedRate
            );
            if (compareDefaultOfThisRate) {
              newPaintProjectRates.push({
                ...newProjectPaintRate,
                modified: false,
              });
              const previousLaborRateIndex =
                reduxProject.projectRates.paintRates.findIndex(
                  (element: any) => element._id === item.projectLaborRate
                );
              const listOfOptionsInWhichRateExits =
                checkIfLaborRateExitsInOtherOptions(
                  reduxProject.projectRates.paintRates[previousLaborRateIndex],
                  reduxProject.options,
                  "paint"
                );
              if (listOfOptionsInWhichRateExits.length === 0) {
                deleteLaborRateFromProject(
                  reduxProject.projectRates.paintRates[previousLaborRateIndex]
                    ._id,
                  "paint"
                );
              }
              return {
                _id: item._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                option: selectedOption._id,
                projectLaborRate: newProjectPaintRate._id,
                item: item.item,
                showLaborRates: item.showLaborRates,
                count: item.count,
                primeSelect: item.primeSelect,
                coats: item.coats,
                paintMaterial: item.paintMaterial,
                color: item.color,
                primerMaterial: item.primerMaterial,
                prepHours: item.prepHours,
                primeHours: item.primeHours,
                paintHours: item.paintHours,
                totalPaintHours: item.totalPaintHours,
                primerGallons: item.primerGallons,
                paintGallons: item.paintGallons,
                paintMaterialCost: item.paintMaterialCost,
                primeLaborRate: item.primeLaborRate,
                paintLaborRate: item.paintLaborRate,
                prepLaborRate: item.prepLaborRate,
                paintSpreadRate: item.paintSpreadRate,
                primerSpreadRate: item.primerSpreadRate,
                prepLevel: item.prepLevel,
                paintLevel: item.paintLevel,
                primeLevel: item.primeLevel,
                // paintPricePerGallon: item.paintPricePerGallon,
                // primerPricePerGallon: item.primerPricePerGallon,
                customerSuppliedPaint: item.customerSuppliedPaint,
                customerSuppliedPrimer: item.customerSuppliedPrimer,
                // createdBy: user.id,
                modified: false,
                isCloned: false,
                isDeleted: item.isDeleted,
              };
            } else {
              newPaintProjectRates.push(newProjectPaintRate);
              return {
                _id: item._id,
                project: items[currentProject].projectId,
                defaultRate: item.defaultRate,
                option: selectedOption._id,
                projectLaborRate: newProjectPaintRate._id,
                item: item.item,
                showLaborRates: item.showLaborRates,
                count: item.count,
                primeSelect: item.primeSelect,
                coats: item.coats,
                paintMaterial: item.paintMaterial,
                color: item.color,
                primerMaterial: item.primerMaterial,
                prepHours: item.prepHours,
                primeHours: item.primeHours,
                paintHours: item.paintHours,
                totalPaintHours: item.totalPaintHours,
                primerGallons: item.primerGallons,
                paintGallons: item.paintGallons,
                paintMaterialCost: item.paintMaterialCost,
                primeLaborRate: item.primeLaborRate,
                paintLaborRate: item.paintLaborRate,
                prepLaborRate: item.prepLaborRate,
                paintSpreadRate: item.paintSpreadRate,
                primerSpreadRate: item.primerSpreadRate,
                prepLevel: item.prepLevel,
                paintLevel: item.paintLevel,
                primeLevel: item.primeLevel,
                // paintPricePerGallon: item.paintPricePerGallon,
                // primerPricePerGallon: item.primerPricePerGallon,
                customerSuppliedPaint: item.customerSuppliedPaint,
                customerSuppliedPrimer: item.customerSuppliedPrimer,
                // createdBy: user.id,
                modified: item.modified,
                isCloned: item.isCloned,
                isDeleted: item.isDeleted,
              };
            }
          }
        } else {
          console.log("exits");
        }
      }
      return {
        _id: item._id,
        project: items[currentProject].projectId,
        defaultRate: item.defaultRate,
        option: selectedOption._id,
        projectLaborRate: item.projectLaborRate,
        item: item.item,
        showLaborRates: item.showLaborRates,
        count: item.count,
        primeSelect: item.primeSelect,
        coats: item.coats,
        paintMaterial: item.paintMaterial,
        color: item.color,
        primerMaterial: item.primerMaterial,
        prepHours: item.prepHours,
        primeHours: item.primeHours,
        paintHours: item.paintHours,
        totalPaintHours: item.totalPaintHours,
        primerGallons: item.primerGallons,
        paintGallons: item.paintGallons,
        paintMaterialCost: item.paintMaterialCost,
        primeLaborRate: item.primeLaborRate,
        paintLaborRate: item.paintLaborRate,
        prepLaborRate: item.prepLaborRate,
        paintSpreadRate: item.paintSpreadRate,
        primerSpreadRate: item.primerSpreadRate,
        prepLevel: item.prepLevel,
        paintLevel: item.paintLevel,
        primeLevel: item.primeLevel,
        // paintPricePerGallon: item.paintPricePerGallon,
        // primerPricePerGallon: item.primerPricePerGallon,
        customerSuppliedPaint: item.customerSuppliedPaint,
        customerSuppliedPrimer: item.customerSuppliedPrimer,
        // createdBy: user.id,
        modified: item.modified,
        isCloned: item.isCloned,
        isDeleted: item.isDeleted,
      };
    });

    let newBodyForProjectRates = [
      {
        category: "paint",
        rate: newPaintProjectRates,
      },
      {
        category: "carpentry",
        rate: newCarpentryProjectRates,
      },
      {
        category: "equipment",
        rate: newEquipmentProjectRates,
      },
      {
        category: "travel",
        rate: newTravelProjectRates,
      },
      {
        category: "miscellaneous",
        rate: newMiscProjectRates,
      },
    ];

    dispatch(
      addServiceToProject(
        items[currentProject].projectId,
        newBodyForProjectRates
      )
    );

    let wash: any = [];
    if (washChecked) {
      wash.push({ ...washDetails });
    }
    const serviceRatesForRedux = {
      paintRates: updatePaint,
      carpentryRates: updateCarpentry,
      equipmentRates: updateEquipment,
      travelRates: updateTravel,
      miscellaneousRates: updateMisc,
      // washRates: washChecked ? [washDetails] : [],
      washRates: [washDetails],
    };
    const serviceRates = {
      paintRates: updatePaint.map((item: any) => {
        return {
          ...item,
          paintMaterial: item.paintMaterial,
          primerMaterial: item.primerMaterial,
        };
      }),
      carpentryRates: updateCarpentry,
      equipmentRates: updateEquipment,
      travelRates: updateTravel,
      miscellaneousRates: updateMisc,
      washRates: washChecked ? [washDetails] : [],
    };

    const bodyForRedux = {
      ...selectedOption,
      _id: selectedOption._id,
      project: items[currentProject].projectId,
      title: selectedOption.title,
      roundGallons: selectedOption.roundGallons,
      spotPrimeMaterial: selectedOption.spotPrimeMaterial,
      address: selectedOption.address,
      buildingQuantity: selectedOption.buildingQuantity,
      workOrderCarpentryNotes: selectedOption.workOrderCarpentryNotes,
      estimatorNotes: selectedOption.estimatorNotes,
      proposalGeneralDescription: selectedOption.proposalGeneralDescription,
      workOrderPaintNotes: selectedOption.workOrderPaintNotes,
      washIncluded: selectedOption.washIncluded,
      // overRideGrossMargin: newGm,
      squareFeet: selectedOption.squareFeet,
      traversed: true,
    };

    const body = {
      ...selectedOption,
      _id: selectedOption._id,
      project: items[currentProject].projectId,
      title: selectedOption.title,
      roundGallons: selectedOption.roundGallons,
      spotPrimeMaterial: selectedOption.spotPrimeMaterial,
      address: selectedOption.address,
      buildingQuantity: selectedOption.buildingQuantity,
      workOrderCarpentryNotes: selectedOption.workOrderCarpentryNotes,
      estimatorNotes: selectedOption.estimatorNotes,
      proposalGeneralDescription: selectedOption.proposalGeneralDescription,
      workOrderPaintNotes: selectedOption.workOrderPaintNotes,
      washIncluded: selectedOption.washIncluded,
      // overRideGrossMargin: newGm,
      serviceRates: serviceRates,
      squareFeet: selectedOption.squareFeet,
      traversed: true,
    };
    let project = _.cloneDeep(items[currentProject]);
    project.projectInfo.overRideGrossMargin = [];
    let newProductionPjccData = project.projectInfo.productionPjcc?.map(
      (item: any) => {
        return {
          ...item,
          productionTargets: null,
        };
      }
    );
    project.projectInfo.productionPjcc = newProductionPjccData;

    let undoDeleteProjectRates = [
      {
        category: "paint",
        rate: undoDeleteOnPaintProjectRates,
      },
      {
        category: "carpentry",
        rate: undoDeleteOnCarpentryProjectRates,
      },
      {
        category: "equipment",
        rate: undoDeleteOnEquipmentProjectRates,
      },
      {
        category: "travel",
        rate: undoDeleteOnTravelProjectRates,
      },
      {
        category: "miscellaneous",
        rate: undoDeleteOnMiscProjectRates,
      },
    ];

    dispatch(
      undoDeleteOnProjectRates(
        items[currentProject].projectId,
        undoDeleteProjectRates
      )
    );
    const projectState = _.cloneDeep(items[currentProject]);
    const optionInfo = bodyForRedux;
    const updatedOptions = projectState.options.map((item: any) => {
      if (item.optionInfo._id === bodyForRedux._id) {
        return {
          optionInfo: optionInfo,
          pjcc: item.pjcc,
          rates: serviceRatesForRedux,
        };
      }
      return item;
    });
    projectState.options = updatedOptions;
    if (newPaintProjectRates.length > 0) {
      projectState.projectRates.paintRates = [
        ...projectState.projectRates.paintRates,
        ...newPaintProjectRates,
      ];
    }
    if (undoDeleteOnPaintProjectRates.length > 0) {
      undoDeleteOnPaintProjectRates.forEach((rateId: any) => {
        const projectRateIndex = projectState.projectRates.paintRates.findIndex(
          (element: any) => element._id === rateId
        );
        projectState.projectRates.paintRates[projectRateIndex].isDeleted =
          false;
      });
    }
    if (newTravelProjectRates.length > 0) {
      projectState.projectRates.travelRates = [
        ...projectState.projectRates.travelRates,
        ...newTravelProjectRates,
      ];
    }
    if (undoDeleteOnTravelProjectRates.length > 0) {
      undoDeleteOnTravelProjectRates.forEach((rateId: any) => {
        const projectRateIndex =
          projectState.projectRates.travelRates.findIndex(
            (element: any) => element._id === rateId
          );
        projectState.projectRates.travelRates[projectRateIndex].isDeleted =
          false;
      });
    }
    if (newMiscProjectRates.length > 0) {
      projectState.projectRates.miscellaneousRates = [
        ...projectState.projectRates.miscellaneousRates,
        ...newMiscProjectRates,
      ];
    }
    if (undoDeleteOnMiscProjectRates.length > 0) {
      undoDeleteOnMiscProjectRates.forEach((rateId: any) => {
        const projectRateIndex =
          projectState.projectRates.miscellaneousRates.findIndex(
            (element: any) => element._id === rateId
          );
        projectState.projectRates.miscellaneousRates[
          projectRateIndex
        ].isDeleted = false;
      });
    }
    if (newEquipmentProjectRates.length > 0) {
      projectState.projectRates.equipmentRates = [
        ...projectState.projectRates.equipmentRates,
        ...newEquipmentProjectRates,
      ];
    }
    if (undoDeleteOnEquipmentProjectRates.length > 0) {
      undoDeleteOnEquipmentProjectRates.forEach((rateId: any) => {
        const projectRateIndex =
          projectState.projectRates.equipmentRates.findIndex(
            (element: any) => element._id === rateId
          );
        projectState.projectRates.equipmentRates[projectRateIndex].isDeleted =
          false;
      });
    }
    if (newCarpentryProjectRates.length > 0) {
      projectState.projectRates.carpentryRates = [
        ...projectState.projectRates.carpentryRates,
        ...newCarpentryProjectRates,
      ];
    }
    if (undoDeleteOnCarpentryProjectRates.length > 0) {
      undoDeleteOnCarpentryProjectRates.forEach((rateId: any) => {
        const projectRateIndex =
          projectState.projectRates.carpentryRates.findIndex(
            (element: any) => element._id === rateId
          );
        projectState.projectRates.carpentryRates[projectRateIndex].isDeleted =
          false;
      });
    }

    const removedMargins: { name: string; margin: number; _id?: string }[] = [];
    projectState.projectInfo.overRideGrossMargin =
      projectState.projectInfo.overRideGrossMargin.filter(
        (marginEntity: { name: string; margin: number; _id: string }) => {
          // Find if the current marginEntity exists in the gm array
          const gmEntity = gm.find(
            (gmItem: { name: string; margin: number; _id: string }) =>
              gmItem.name === marginEntity.name
          );

          if (gmEntity) {
            // Case 1: If name and margin are equal, keep the marginEntity as is
            if (gmEntity.margin === marginEntity.margin) {
              return true; // Keep this marginEntity in the array
            }

            // Case 2: If name is equal but margin is different, remove the marginEntity
            if (gmEntity.margin !== marginEntity.margin) {
              removedMargins.push(marginEntity); // Store the removed marginEntity
              return false; // Remove this marginEntity from the array
            }
          }

          // Case 3: If the object exists in gm but not in overRideGrossMargin, do nothing
          // No action needed in this case as filter only iterates over existing projectInfo.overRideGrossMargin

          // Default case: Keep the marginEntity as is if no other conditions are met
          return true;
        }
      );

    console.log("project gm", projectState.projectInfo.overRideGrossMargin);
    console.log("Removed objects:", removedMargins);

    if (removedMargins.length !== 0) {
      api.patch(
        `projects/remove-only-project-override-gm/${projectState.projectInfo._id}`,
        removedMargins
      );
    }
    // projectState.projectInfo.overRideGrossMargin = [];
    // let newProductionPjcc = projectState.projectInfo.productionPjcc?.map(
    //   (item: any) => {
    //     return {
    //       ...item,
    //       productionTargets: null,
    //     };
    //   }
    // );
    // projectState.projectInfo.productionPjcc = newProductionPjcc;
    dispatch(updateOptionDetails(body, projectState));
    // dispatch(updateProjectOnNewOption(updatedProjectBody));

    const option = items[currentProject].options.find(
      (item: any) => item.optionInfo._id === id
    );

    const oldEstimatedPrice = option.pjcc.find(
      (item: any) => item.name === "Estimated Price"
    );

    const estimatedPrice = pjccData.find(
      (item: any) => item.name === "Estimated Price"
    );
    if (
      items[currentProject].options.length === 1 &&
      oldEstimatedPrice.totalAmount === 0 &&
      estimatedPrice.totalAmount !== "0.00"
    ) {
      let body = {
        _id: items[currentProject].projectId,
        projectState: 3,
      };
      dispatch(updateProjectStateById(body));
    }
  };

  const handleCheckModifiedPaintOnEdit = (editedRate: any) => {
    let tempEditedPaintRatesIdsArray: any = _.cloneDeep(editedPaintRatesIds);
    const doesRateIdExitsInArrayState = tempEditedPaintRatesIdsArray.findIndex(
      (key: any) => key === editedRate._id
    );
    if (doesRateIdExitsInArrayState === -1) {
      tempEditedPaintRatesIdsArray.push(editedRate._id);
      setEditedPaintRatesIds(tempEditedPaintRatesIdsArray);
    }
  };

  const handleCheckModifiedCarpentryOnEdit = (editedRate: any) => {
    let tempEditedCarpentryRatesIdsArray: any = _.cloneDeep(
      editedCarpentryRatesIds
    );
    const doesRateIdExitsInArrayState =
      tempEditedCarpentryRatesIdsArray.findIndex(
        (key: any) => key === editedRate._id
      );
    if (doesRateIdExitsInArrayState === -1) {
      tempEditedCarpentryRatesIdsArray.push(editedRate._id);
      setEditedCarpentryRatesIds(tempEditedCarpentryRatesIdsArray);
    }
  };

  const handleCheckModifiedEquipmentOnEdit = (editedRate: any) => {
    let tempEditedEquipmentRatesIdsArray: any = _.cloneDeep(
      editedEquipmentRatesIds
    );
    const doesRateIdExitsInArrayState =
      tempEditedEquipmentRatesIdsArray.findIndex(
        (key: any) => key === editedRate._id
      );
    if (doesRateIdExitsInArrayState === -1) {
      tempEditedEquipmentRatesIdsArray.push(editedRate._id);
      setEditedEquipmentRatesIds(tempEditedEquipmentRatesIdsArray);
    }
  };

  const handleCheckModifiedMiscOnEdit = (editedRate: any) => {
    let tempEditedMiscRatesIdsArray: any = _.cloneDeep(editedMiscRatesIds);
    const doesRateIdExitsInArrayState = tempEditedMiscRatesIdsArray.findIndex(
      (key: any) => key === editedRate._id
    );
    if (doesRateIdExitsInArrayState === -1) {
      tempEditedMiscRatesIdsArray.push(editedRate._id);
      setEditedMiscRatesIds(tempEditedMiscRatesIdsArray);
    }
  };

  const handleCheckModifiedTravelOnEdit = (editedRate: any) => {
    let tempEditedTravelRatesIdsArray: any = _.cloneDeep(editedTravelRatesIds);
    const doesRateIdExitsInArrayState = tempEditedTravelRatesIdsArray.findIndex(
      (key: any) => key === editedRate._id
    );
    if (doesRateIdExitsInArrayState === -1) {
      tempEditedTravelRatesIdsArray.push(editedRate._id);
      setEditedTravelRatesIds(tempEditedTravelRatesIdsArray);
    }
  };
  const menu = (
    <div className="buildings-menu buildings-scroll-bar">
      <div>
        <div className="option-input-container">
          <Input
            className="buildings-search"
            placeholder="Search"
            onChange={(e) => {
              setBuildingSearched(e.target.value);
            }}
            prefix={<SearchIcon />}
          />
        </div>
        <Menu>
          {findBuilding
            ?.filter((item: any) => item.included === "included")
            ?.map((option: any) => (
              <Menu.Item style={{ height: 45 }} key={option._id}>
                <Link to={`/manager-option-details/${option._id}`}>
                  <p
                    style={{
                      whiteSpace: "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      lineHeight: "1.2em",
                      maxHeight: "2.4em",
                    }}
                  >
                    {option.title}
                  </p>
                </Link>
              </Menu.Item>
            ))}
        </Menu>
      </div>
    </div>
  );

  const togglePjccBtn = () => {
    showSider ? setShowSider(false) : setShowSider(true);
  };

  const handleOnKeyDown = (e: any) => {
    if (e.key === "+" || e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
  };

  const handleSetupHoursChange = (e: any) => {
    const newData = { ...selectedOption };
    newData.overRideSetupHours = +e.target.value;
    setSetupValue(e.target.value);
    setSelectedOption(newData);
  };

  const handleQuantityChange = (e: any) => {
    const newData = { ...selectedOption };
    newData.buildingQuantity = e.target.value;
    setSelectedOption(newData);
  };

  const handleSpotMaterialChange = (e: any) => {
    const newData = { ...selectedOption };
    newData.spotPrimeMaterial = +e.target.value;
    setSelectedOption(newData);
  };
  const handleWorkOrderCarpentryNotes = (input: string) => {
    const newData = { ...selectedOption };
    newData.workOrderCarpentryNotes = input;
    setSelectedOption(newData);
  };

  const handleWorkOrderCarpentryNotesLinks = (input: [string]) => {
    const newData = { ...selectedOption };
    newData.workOrderCarpentryNotesLinks = input;
    setSelectedOption(newData);
  };

  const handleEstimatorNotes = (input: string) => {
    const newData = { ...selectedOption };
    newData.estimatorNotes = input;
    setSelectedOption(newData);
  };

  const handleEstimatorNotesLinks = (input: [string]) => {
    const newData = { ...selectedOption };
    newData.estimatorNotesLinks = input;
    setSelectedOption(newData);
  };

  const handleShowEstimatorNotes = (e: any) => {
    setShowNotes({ ...showNotes, estimateNotes: !showNotes.estimateNotes });
  };

  const handleShowWorkOrderPaintingNotes = (e: any) => {
    setShowNotes({
      ...showNotes,
      workOrderPaintingNotes: !showNotes.workOrderPaintingNotes,
    });
  };

  const handleShowWorkOrderCarpentryNotes = (e: any) => {
    setShowNotes({
      ...showNotes,
      workOrderCarpentryNotes: !showNotes.workOrderCarpentryNotes,
    });
  };

  const handleWorkOrderPaintNotes = (input: string) => {
    const newData = { ...selectedOption };
    newData.workOrderPaintNotes = input;
    setSelectedOption(newData);
  };

  const handleWorkOrderPaintNotesLinks = (input: [string]) => {
    const newData = { ...selectedOption };
    newData.workOrderPaintNotesLinks = input;
    setSelectedOption(newData);
  };

  const handleProposalGeneralNotes = (input: string) => {
    const newData = { ...selectedOption };
    newData.proposalGeneralDescription = input;
    setSelectedOption(newData);
  };

  const handleProposalGeneralNotesLinks = (input: [string]) => {
    const newData = { ...selectedOption };
    newData.proposalGeneralDescriptionLinks = input;
    setSelectedOption(newData);
  };

  const toggleAddSurfaceModal = (_: boolean) => {
    !showAddSurface ? setShowAddSurface(true) : setShowAddSurface(false);
  };
  const handleAddSurface = (surfaceType: string) => {
    setSurface(surfaceType);
    !showAddSurface
      ? toggleAddSurfaceModal(true)
      : toggleAddSurfaceModal(false);
  };

  const handleChangeName = (value: string) => {
    setSelectedOption({ ...selectedOption, title: value });
  };

  const handleChangeAddress = (value: string) => {
    setSelectedOption({ ...selectedOption, address: value });
  };

  const setRatesValue = (data: any) => { };

  const totalCostGrossMarginEstimation = (marginDefaults: any, cost: any) => {
    let grossMargin = marginDefaults.filter((item: any) => {
      return cost >= item.cost;
    });

    let smallestValue = grossMargin[0]?.margin;
    let smallestIndex = 0;
    for (let i = 1; i < grossMargin.length; i++) {
      if (grossMargin[i]?.margin < smallestValue) {
        smallestValue = grossMargin[i]?.margin;
        smallestIndex = i;
      }
    }
    let index = smallestIndex;

    let costGm = cost / (1 - grossMargin[index]?.margin / 100);
    return { costGm, grossMargin, index };
  };

  const grossMarginEstimation = (
    marginDefaults: any,
    cost: any,
    singleOption: any,
    name: string,
    totalCostGrossMargin: any
  ) => {
    if (singleOption.overRideGrossMargin) {
      let overRideMargin = singleOption.overRideGrossMargin.filter(
        (item: any) => {
          return item.name === name;
        }
      );
      if (overRideMargin.length === 0) {
        let grossMargin = totalCostGrossMargin.grossMargin;
        let index = totalCostGrossMargin.index;
        // let grossMargin = marginDefaults.filter((item: any) => {
        //   return cost >= item.cost;
        // });
        // let index = grossMargin.length - 1;

        let costGm = cost / (1 - grossMargin[index]?.margin / 100);
        return { costGm, grossMargin, index };
      } else {
        const costGm = cost / (1 - overRideMargin[0].margin / 100);
        let index = 0;
        let grossMargin = overRideMargin;
        return { costGm, grossMargin, index };
      }
    } else {
      let grossMargin = totalCostGrossMargin.grossMargin;
      let index = totalCostGrossMargin.index;
      // let grossMargin = marginDefaults.filter((item: any) => {
      //   return cost >= item.cost;
      // });
      // let index = grossMargin.length - 1;

      let costGm =
        cost /
        (1 -
          totalCostGrossMargin.grossMargin[totalCostGrossMargin.index].margin /
          100);
      return { costGm, grossMargin, index };
    }
  };
  const applyToFixed = (num: number) =>
    num % 1 !== 0 ? parseFloat(num?.toFixed(2)) : num;
  //// Modify-
  const optionPjccObject = (
    projectId: string,
    singleOption: any,
    cost: any,
    costGm: any,
    name: string
  ) => {
    if (
      name === "Total Material Cost" ||
      // name === "Paint Hours" ||
      // name === "Carpentry Hours" ||
      // name === "Total Hours" ||
      name === "Total Labor" ||
      name === "Average Wage" ||
      name === "Total Cost"
    ) {
      return {
        project: projectId,
        option: singleOption._id,
        name: name,
        cost: applyToFixed(cost),
        amount: applyToFixed(costGm),
        totalAmount: applyToFixed(
          costGm *
          (singleOption.buildingQuantity ? singleOption.buildingQuantity : 1)
        ),
        grossMargin: +(isNaN(100 * (1 - cost / costGm))
          ? 0
          : 100 * (1 - cost / costGm)),
      };
    }
    if (
      name === "Paint Hours" ||
      name === "Carpentry Hours" ||
      name === "Total Hours"
    ) {
      return {
        project: projectId,
        option: singleOption._id,
        name: name,
        cost: applyToFixed(cost),
        amount: applyToFixed(costGm),
        totalAmount: applyToFixed(
          costGm *
          (singleOption.buildingQuantity ? singleOption.buildingQuantity : 1)
        ),
        grossMargin: +(isNaN(100 * (1 - cost / costGm))
          ? 0
          : 100 * (1 - cost / costGm)),
      };
    }

    if (name === "Estimated Price" || name === "Gross Margin") {
      return {
        project: projectId,
        option: singleOption._id,
        name: name,
        cost: applyToFixed(cost),
        amount: applyToFixed(costGm),
        totalAmount: applyToFixed(
          costGm *
          (singleOption.buildingQuantity ? singleOption.buildingQuantity : 1)
        ),
        grossMargin: 0,
      };
    } else {
      return {
        project: projectId,
        option: singleOption._id,
        name: name,
        cost: applyToFixed(cost),
        amount: applyToFixed(costGm.costGm),
        totalAmount: applyToFixed(
          costGm.costGm *
          (singleOption.buildingQuantity ? singleOption.buildingQuantity : 1)
        ),
        grossMargin: costGm.grossMargin[costGm.index]?.margin,
      };
    }
  };

  const calculatePjCC = () => {
    if (
      paintRates &&
      carpentryRates &&
      equipmentRates &&
      miscellaneousRates &&
      travelRates &&
      selectedOption &&
      washDetails
    ) {
      let sumOfAllPaintMaterialCost = 0;
      let sumOfAllPaintRateHours = 0;
      for (const item of paintRates) {
        if (!item.isDeleted) {
          sumOfAllPaintMaterialCost =
            sumOfAllPaintMaterialCost +
            (isNaN(item.paintMaterialCost) ? 0 : item.paintMaterialCost);
          sumOfAllPaintRateHours =
            sumOfAllPaintRateHours +
            ((item.paintHours ? item.paintHours : 0) +
              (item.primeHours ? item.primeHours : 0) +
              (item.prepHours ? item.prepHours : 0));
        }
      }

      let sumOfAllCarpentryRateHours = 0;
      let sumOfAllCarpentryMaterial = 0;
      for (const item of carpentryRates) {
        if (!item.isDeleted) {
          sumOfAllCarpentryRateHours =
            parseFloat(sumOfAllCarpentryRateHours.toFixed(2)) +
            (item.carpentryHours
              ? parseFloat(item.carpentryHours.toFixed(2))
              : 0);
          sumOfAllCarpentryMaterial =
            parseFloat(sumOfAllCarpentryMaterial.toFixed(2)) +
            (item.carpentryMaterial
              ? parseFloat(item.carpentryMaterial.toFixed(2))
              : 0);
        }
      }

      let sumOfAllEquipmentTotalCost = 0;
      for (const item of equipmentRates) {
        if (!item.isDeleted) {
          sumOfAllEquipmentTotalCost =
            sumOfAllEquipmentTotalCost + (item.totalCost ? item.totalCost : 0);
        }
      }

      let sumOfAllMiscTotalCost = 0;
      for (const item of miscellaneousRates) {
        if (!item.isDeleted) {
          sumOfAllMiscTotalCost =
            sumOfAllMiscTotalCost + (item.totalCost ? item.totalCost : 0);
        }
      }

      let sumOfAllTravelTotalCost = 0;
      for (const item of travelRates) {
        if (!item.isDeleted) {
          sumOfAllTravelTotalCost =
            sumOfAllTravelTotalCost + (item.totalCost ? item.totalCost : 0);
        }
      }

      //OptionPjcc CALCULATIONS
      let washHours = 0;
      if (washDetails && selectedOption.washIncluded === true) {
        washHours = washDetails ? washDetails.squareFeet / washDetails.rate : 0;
      }
      let setup = 0;
      if (selectedOption?.overRideSetupHours) {
        setup = selectedOption?.overRideSetupHours;
      } else {
        setup =
          items[currentProject].projectInfo.setupConst *
          (parseFloat(sumOfAllPaintRateHours.toFixed(2)) + washHours);
        setSetupValue(setup);
      }

      let totalPaintHours = parseFloat(
        (
          parseFloat(sumOfAllPaintRateHours.toFixed(2)) +
          washHours +
          setup
        ).toFixed(2)
      );

      let carpentryWageRate: any = adminDefaults.wageDefaults.find(
        (entity: any) => entity.title === "Carpentry Wage Rate"
      );

      let totalCarpentryHours = 1.08 * sumOfAllCarpentryRateHours;
      // let totalCarpentryHours = parseFloat(
      //   (1.08 * sumOfAllCarpentryRateHours).toFixed(2)
      // );
      let totalPaintAndCarpentryHours = totalPaintHours + totalCarpentryHours;
      // let totalPaintAndCarpentryHours = parseFloat(
      //   (totalPaintHours + totalCarpentryHours).toFixed(2)
      // );

      let ssWageRate: any = adminDefaults.wageDefaults.find(
        (entity: any) => entity.title === "SS"
      );

      let paintLaborRate: any = adminDefaults.wageDefaults.find(
        (entity: any) => entity.title === "Painting Wage Rate"
      );

      //PaintCost
      let paintCost = Math.round(
        (isNaN(selectedOption.spotPrimeMaterial)
          ? 0
          : selectedOption.spotPrimeMaterial) + sumOfAllPaintMaterialCost
      );

      //SsCost
      let ssCost = Math.round(
        Math.round(totalPaintAndCarpentryHours) *
        items[currentProject].projectInfo.ssConst
      );

      //CarpentryMaterial
      let carpentryMaterial = Math.round(sumOfAllCarpentryMaterial);
      //TotalMaterialCost
      let totalMaterialCost = Math.round(
        paintCost + ssCost + carpentryMaterial
      );
      //EquipmentCost
      let equipmentCost = Math.round(sumOfAllEquipmentTotalCost);
      //MiscCost
      let miscCost = Math.round(sumOfAllMiscTotalCost);
      //TravelCost
      let travelCost = Math.round(sumOfAllTravelTotalCost);
      //PaintHours
      let paintHours = customToFixed(totalPaintHours, 2);
      //CarpentryHours
      let carpentryHours = customToFixed(totalCarpentryHours, 2);
      //TotalHours
      let totalHours = customToFixed(totalPaintAndCarpentryHours, 2);
      //PaintLabor
      let paintLabor = Math.round(
        totalPaintHours * items[currentProject].projectInfo.paintWageRate
      );
      //CarpentryLabor
      let carpentryLabor = Math.round(
        totalCarpentryHours *
        items[currentProject].projectInfo.carpentryWageRate
      );
      //TotalLabor
      let totalLabor = Math.round(paintLabor + carpentryLabor);
      //TotalCost
      let totalCost = Math.round(
        totalMaterialCost + totalLabor + miscCost + travelCost + equipmentCost
      );

      let totalCostGrossMargin = totalCostGrossMarginEstimation(
        items[currentProject].projectInfo.tieredMargins,
        totalCost
      );

      let paintCostGm = grossMarginEstimation(
        items[currentProject].projectInfo.tieredMargins,
        paintCost,
        selectedOption,
        "Paint Cost",
        totalCostGrossMargin
      );

      let ssCostGm = grossMarginEstimation(
        items[currentProject].projectInfo.tieredMargins,
        ssCost,
        selectedOption,
        "SS Cost",
        totalCostGrossMargin
      );

      let carpentryMaterialGm = grossMarginEstimation(
        items[currentProject].projectInfo.tieredMargins,
        carpentryMaterial,
        selectedOption,
        "Carpentry Material",
        totalCostGrossMargin
      );

      let totalMaterialCostGm =
        paintCostGm.costGm + ssCostGm.costGm + carpentryMaterialGm.costGm;

      let equipmentCostGm = grossMarginEstimation(
        items[currentProject].projectInfo.tieredMargins,
        equipmentCost,
        selectedOption,
        "Equipment Cost",
        totalCostGrossMargin
      );

      let miscCostGm = grossMarginEstimation(
        items[currentProject].projectInfo.tieredMargins,
        miscCost,
        selectedOption,
        "Miscellaneous Cost",
        totalCostGrossMargin
      );

      let travelCostGm = grossMarginEstimation(
        items[currentProject].projectInfo.tieredMargins,
        travelCost,
        selectedOption,
        "Travel Cost",
        totalCostGrossMargin
      );

      let paintLaborGm = grossMarginEstimation(
        items[currentProject].projectInfo.tieredMargins,
        paintLabor,
        selectedOption,
        "Paint Labor",
        totalCostGrossMargin
      );

      let carpentryLaborGm = grossMarginEstimation(
        items[currentProject].projectInfo.tieredMargins,
        carpentryLabor,
        selectedOption,
        "Carpentry Labor",
        totalCostGrossMargin
      );

      let totalLaborGm = paintLaborGm.costGm + carpentryLaborGm.costGm;

      let averageWage = isNaN(totalLabor / totalHours)
        ? 0
        : Math.round(totalLabor / totalHours);
      let averageWageGm = isNaN(totalLaborGm / totalHours)
        ? 0
        : Math.round(totalLaborGm / totalHours);

      let totalCostGm =
        totalMaterialCostGm +
        totalLaborGm +
        miscCostGm.costGm +
        travelCostGm.costGm +
        equipmentCostGm.costGm;

      let paintCostObject = optionPjccObject(
        items[currentProject].projectId,
        selectedOption,
        paintCost,
        paintCostGm,
        "Paint Cost"
      );

      let ssCostObject = optionPjccObject(
        items[currentProject].projectId,
        selectedOption,
        ssCost,
        ssCostGm,
        "SS Cost"
      );
      let carpentryMaterialObject = optionPjccObject(
        items[currentProject].projectId,
        selectedOption,
        carpentryMaterial,
        carpentryMaterialGm,
        "Carpentry Material"
      );
      let totalMaterialCostObject = optionPjccObject(
        items[currentProject].projectId,
        selectedOption,
        totalMaterialCost,
        totalMaterialCostGm,
        "Total Material Cost"
      );
      let equipmentCostObject = optionPjccObject(
        items[currentProject].projectId,
        selectedOption,
        equipmentCost,
        equipmentCostGm,
        "Equipment Cost"
      );
      let miscCostObject = optionPjccObject(
        items[currentProject].projectId,
        selectedOption,
        miscCost,
        miscCostGm,
        "Miscellaneous Cost"
      );
      let travelCostObject = optionPjccObject(
        items[currentProject].projectId,
        selectedOption,
        travelCost,
        travelCostGm,
        "Travel Cost"
      );
      let paintHoursObject = optionPjccObject(
        items[currentProject].projectId,
        selectedOption,
        paintHours,
        paintHours,
        "Paint Hours"
      );
      let carpentryHoursObject = optionPjccObject(
        items[currentProject].projectId,
        selectedOption,
        carpentryHours,
        carpentryHours,
        "Carpentry Hours"
      );
      let totalHoursObject = optionPjccObject(
        items[currentProject].projectId,
        selectedOption,
        totalHours,
        totalHours,
        "Total Hours"
      );
      let paintLaborObject = optionPjccObject(
        items[currentProject].projectId,
        selectedOption,
        paintLabor,
        paintLaborGm,
        "Paint Labor"
      );
      let carpentryLaborObject = optionPjccObject(
        items[currentProject].projectId,
        selectedOption,
        carpentryLabor,
        carpentryLaborGm,
        "Carpentry Labor"
      );
      let totalLaborObject = optionPjccObject(
        items[currentProject].projectId,
        selectedOption,
        totalLabor,
        totalLaborGm,
        "Total Labor"
      );
      let averageWageObject = optionPjccObject(
        items[currentProject].projectId,
        selectedOption,
        averageWage,
        averageWageGm,
        "Average Wage"
      );

      let totalCostObject = optionPjccObject(
        items[currentProject].projectId,
        selectedOption,
        totalCost,
        totalCostGm,
        "Total Cost"
      );
      let estimatedPriceObject = optionPjccObject(
        items[currentProject].projectId,
        selectedOption,
        totalCost,
        totalCostGm,
        "Estimated Price"
      );
      let grossMarginObject = {
        project: items[currentProject].projectId,
        option: selectedOption._id,
        name: "Gross Margin",
        cost: null,
        amount: parseFloat(
          (estimatedPriceObject.amount - totalCostObject.cost).toFixed(2)
        ),
        totalAmount: parseFloat(
          (
            estimatedPriceObject.totalAmount -
            totalCostObject.cost * selectedOption.buildingQuantity
          ).toFixed(2)
        ),
      };

      const pjcc = [
        {
          ...estimatedPriceObject,
          key: 1,
        },
        {
          ...paintCostObject,
          key: 2,
        },
        {
          ...ssCostObject,
          key: 3,
        },
        {
          ...carpentryMaterialObject,
          key: 4,
        },
        {
          ...totalMaterialCostObject,
          key: 5,
        },
        {
          ...equipmentCostObject,
          key: 6,
        },
        {
          ...miscCostObject,
          key: 7,
        },
        {
          ...travelCostObject,
          key: 8,
        },
        {
          ...paintHoursObject,
          key: 9,
        },
        {
          ...carpentryHoursObject,
          key: 10,
        },
        {
          ...totalHoursObject,
          key: 11,
        },
        {
          ...paintLaborObject,
          key: 12,
        },
        {
          ...carpentryLaborObject,
          key: 13,
        },
        {
          ...totalLaborObject,
          key: 14,
        },
        {
          ...averageWageObject,
          key: 15,
        },
        {
          ...totalCostObject,
          key: 16,
        },
        {
          ...grossMarginObject,
          key: 17,
        },
      ];

      setPjccData(pjcc);
    }
  };

  const applyGrossMargin = (e: any, title: any, index: number) => {
    const newPjcc = [...pjccData];
    let overRideGrossMargin = _.cloneDeep(gm);
    if (gm && overRideGrossMargin.length === 0) {
      overRideGrossMargin = [
        {
          name: title,
          margin: +e.target.value,
        },
      ];
    } else {
      let index = overRideGrossMargin.findIndex(
        (item: any) => item.name === title
      );
      if (index > -1) {
        overRideGrossMargin[index].margin = +e.target.value;
      } else {
        overRideGrossMargin.push({
          name: title,
          margin: +e.target.value,
        });
      }
    }
    setSelectedOption({ ...selectedOption, overRideGrossMargin });

    newPjcc[index].grossMargin = +e.target.value;

    setPjccData(newPjcc);
    setGm(overRideGrossMargin);
  };

  const handleAddClone = () => {
    !showModalForEstimate ? toggleModal(true) : toggleModal(false);
  };

  const generateId = () => crypto.randomBytes(12).toString("hex");

  const updateItem = (
    item: any,
    optionId: string,
    includeDateFields: boolean = false,
    extraFields: any = {},
    addCount: boolean
  ) => {
    const _id = generateId();
    const now = new Date().toISOString();
    if (addCount) {
      return {
        ...item,
        _id,
        option: optionId,
        ...(includeDateFields && {
          createdAt: now,
          updatedAt: now,
        }),
      };
    } else {
      return {
        ...item,
        ...extraFields,
        _id,
        option: optionId,
        ...(includeDateFields && {
          createdAt: now,
          updatedAt: now,
        }),
      };
    }
  };

  const updateRates = (rates: any, optionId: string, addCount: boolean) => {
    const rateTypes = [
      "carpentryRates",
      "equipmentRates",
      "miscellaneousRates",
      "paintRates",
      "travelRates",
      "washRates",
    ];

    rateTypes.forEach((type) => {
      let extraFields = {};
      if (
        type === "carpentryRates" ||
        type === "equipmentRates" ||
        type === "miscellaneousRates" ||
        type === "paintRates"
      ) {
        extraFields = { count: 0 };
      } else if (type === "travelRates") {
        extraFields = { rfmCount: 0, dnCount: 0 };
      } else if (type === "washRates") {
        extraFields = { squareFeet: 0, totalHours: 0 };
      }

      if (rates[type]) {
        rates[type] = rates[type].map((item: any) =>
          updateItem(
            item,
            optionId,
            type === "washRates",
            extraFields,
            addCount
          )
        );
      }
    });
  };

  const createOptionClone = (option: any, values: any, user: any) => {
    const now = new Date().toISOString();
    const updatedOption = _.cloneDeep(option);
    delete updatedOption.pjcc;

    let optionId = generateId();
    updatedOption.optionInfo = {
      ...updatedOption.optionInfo,
      title: values.title,
      address: values.address,
      spotPrimeMaterial: values.addCount
        ? updatedOption.optionInfo.spotPrimeMaterial
        : 0,
      overRideSetupHours: values.addCount
        ? updatedOption.optionInfo.overRideSetupHours
        : 0,
      createdAt: now,
      updatedAt: now,
      createdBy: user._id,
      _id: optionId,
    };

    updateRates(updatedOption.rates, optionId, values.addCount);

    return updatedOption;
  };

  const handleOptionClone = (values: any) => {
    const optionFind = items[currentProject].options.find(
      (item: any) => item.optionInfo._id === id
    );

    const updatedOption = createOptionClone(optionFind, values, user);

    let newProject: any = _.cloneDeep(items[currentProject]);

    newProject.options.push({ ...updatedOption, pjcc: [] });

    dispatch(cloneOptionToProject(updatedOption, newProject, adminDefaults));
    setOptions(newProject?.options?.map((item: any) => item.optionInfo));
    setShowModalForEstimate(false);
  };

  const toggleModal = (_: boolean) => {
    !showModalForEstimate
      ? setShowModalForEstimate(true)
      : setShowModalForEstimate(false);
  };

  return (
    <>
      <div className="option-expandable-comp">
        <CloneOption
          handleOptionClone={handleOptionClone}
          showModalForEstimate={showModalForEstimate}
          toggleModal={toggleModal}
          form={form}
        />
        <Card>
          <div className="option-details-header">
            <Row justify="space-between">
              <Col>
                <h1 className="option-details-title">Option Details</h1>
              </Col>
              <Col>
                <Row gutter={10}>
                  <Col>
                    <Button
                      style={{
                        backgroundColor: " #fdb913",
                        borderRadius: "5px",
                        border: "1px solid #fdb913",
                        color: "#1f1f1f ",
                        fontSize: "15px",
                        fontWeight: "bold",
                        lineHeight: "18px",
                        width: "175px",
                        paddingRight: "40px ",
                        paddingLeft: "40px ",
                      }}
                      onClick={handleAddClone}
                    >
                      Clone Option
                    </Button>
                  </Col>
                  <Col>
                    <Dropdown overlay={menu} trigger={["click"]}>
                      <Button className="jump-option-btn">
                        Jump to Option
                      </Button>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <hr className="options-divider" />

          <div className="details-container">
            <div className="details-sub-container">
              <h1 className="option-details-sub">Details</h1>
              {selectedOption && (
                <div className="od-margin-top">
                  <Row align="top">
                    <Col lg={15} sm={18}>
                      <Row gutter={60}>
                        <Col>
                          <h2 className="od-margin-bottom">Building Name</h2>
                          <h2
                            style={{ paddingTop: 15 }}
                            className="od-margin-bottom"
                          >
                            Address
                          </h2>
                          <h2 style={{ paddingTop: 20 }}>Building Quantity</h2>
                          <h2 style={{ paddingTop: 20 }}>
                            Spot Prime Material
                          </h2>
                          <h2 style={{ paddingTop: 20 }}>Setup</h2>
                        </Col>
                        <Col>
                          <p className="od-margin-bottom">
                            <OptionDetailsInput
                              text={selectedOption?.title}
                              onInputChange={handleChangeName}
                            />
                          </p>
                          <p className="od-margin-bottom">
                            <OptionDetailsInput
                              text={selectedOption?.address}
                              onInputChange={handleChangeAddress}
                            />
                          </p>
                          <p className="od-margin-bottom">
                            <Input
                              type="number"
                              min={0}
                              style={{
                                border: "1px solid #E8E8E8",
                                borderRadius: "5px",
                                width: "80px",
                              }}
                              onChange={handleQuantityChange}
                              onKeyDown={handleOnKeyDown}
                              value={selectedOption?.buildingQuantity}
                              placeholder="Enter"
                            />
                          </p>
                          <p className="od-margin-bottom">
                            <Input
                              type="number"
                              style={{
                                border: "1px solid #E8E8E8",
                                borderRadius: "5px",
                                width: "80px",
                              }}
                              onChange={handleSpotMaterialChange}
                              value={selectedOption?.spotPrimeMaterial}
                              placeholder="Enter"
                            />
                          </p>
                          <p className="od-margin-bottom">
                            <Input
                              type="number"
                              min={0}
                              step="0.01"
                              style={{
                                border: "1px solid #E8E8E8",
                                borderRadius: "5px",
                                width: "80px",
                              }}
                              onChange={handleSetupHoursChange}
                              onKeyDown={handleOnKeyDown}
                              value={setupValue}
                              placeholder="Enter"
                            />
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={9} sm={6}>
                      <Row>
                        <Checkbox checked={washChecked} onChange={onChange}>
                          <h4 className="wash-cb">Wash</h4>
                        </Checkbox>
                      </Row>
                      <Row style={{ marginTop: 20 }}>
                        <Checkbox
                          checked={selectedOption?.roundGallons}
                          onChange={onRoundChange}
                        >
                          <h4 className="wash-cb">Round Gallons</h4>
                        </Checkbox>
                      </Row>
                    </Col>
                  </Row>
                  <div style={{ position: "fixed", right: 0, zIndex: 9999 }}>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        className="invert-pjcc-btns"
                      >
                        {!showSider && (
                          <Button
                            type="primary"
                            className="view-pjcc-btn"
                            onClick={togglePjccBtn}
                          >
                            {!showSider ? (
                              <HideArrowIcon className="pjcc-show-icon" />
                            ) : (
                              <ShowArrowIcon className="pjcc-show-icon" />
                            )}
                            View PJCC
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <OptionNotesSummary
                    title="Proposal General Description"
                    onNotesChange={handleProposalGeneralNotes}
                    text={selectedOption?.proposalGeneralDescription}
                  />
                  <OptionNotesSummary
                    title={"Estimator Notes"}
                    expanded={showNotes.estimateNotes}
                    onExpand={handleShowEstimatorNotes}
                    onNotesChange={handleEstimatorNotes}
                    text={selectedOption?.estimatorNotes}
                  />

                  <OptionNotesSummary
                    title="Work Order Painting Notes"
                    expanded={showNotes.workOrderPaintingNotes}
                    onExpand={handleShowWorkOrderPaintingNotes}
                    onNotesChange={handleWorkOrderPaintNotes}
                    text={selectedOption?.workOrderPaintNotes}
                  />

                  <OptionNotesSummary
                    title="Work Order Carpentry Notes"
                    expanded={showNotes.workOrderCarpentryNotes}
                    onExpand={handleShowWorkOrderCarpentryNotes}
                    onNotesChange={handleWorkOrderCarpentryNotes}
                    text={selectedOption?.workOrderCarpentryNotes}
                  />
                </div>
              )}
            </div>
          </div>

          <div
            style={{ position: "fixed", right: 0, zIndex: 9999, top: "11%" }}
            className="show-view-pjcc"
          >
            <div>
              {showSider && (
                <ViewPjcc
                  applyGrossMargin={applyGrossMargin}
                  pjccData={pjccData}
                  showSider={showSider}
                  togglePjccBtn={togglePjccBtn}
                />
              )}
            </div>
          </div>
        </Card>
        {/* <AddSurface
          surface={surface}
          showModalForEstimate={showAddSurface}
          toggleModal={toggleAddSurfaceModal}
          rates={rates}
          addSurface={addSurfaceToOption}
        /> */}
        <AddSurfaceInOption
          surface={surface}
          showModalForEstimate={showAddSurface}
          toggleModal={toggleAddSurfaceModal}
          rates={{
            paintRates,
            carpentryRates,
            equipmentRates,
            miscellaneousRates,
            travelRates,
          }}
          addSurface={addSurfaceToOption}
        />
        <Card>
          <div style={{ paddingBottom: 0 }}>
            <div className="surface-table" style={{ marginTop: 45 }}></div>

            {washChecked && (
              <div style={{ marginTop: 36 }}>
                <div className="labor-rates-container">
                  <h1 className="labor-rates-title">Wash</h1>
                </div>
                <Wash
                  handleWashDetails={handleWashDetails}
                  washDetails={washDetails}
                  washRates={washRates}
                  setOverRideWashSqft={setOverRideWashSqft}
                  washIncluded={washChecked}
                />
                <hr
                  style={{
                    border: "1px solid #E8E8E8",
                    borderBottom: "none",
                    marginTop: 30,
                  }}
                />
              </div>
            )}

            <div style={{ marginTop: 30 }}>
              <div className="labor-rates-container">
                <Row justify="space-between" align="middle">
                  <Col>
                    <h1 className="labor-rates-title">Painting</h1>
                  </Col>
                  <Col style={{ marginRight: "20px" }}>
                    <Button
                      type="primary"
                      style={{ color: "black" }}
                      icon={<PlusOutlined />}
                      onClick={() => handleAddSurface("paint")}
                    />
                  </Col>
                </Row>
              </div>
              <Painting
                roundGallons={selectedOption?.roundGallons}
                calculateSqFt={calculateSqFt}
                setPaintState={setPaintState}
                paintRates={paintRates}
                setRatesValue={setRatesValue}
                handleCheckModifiedOnEdit={handleCheckModifiedPaintOnEdit}
                selectedOption={selectedOption}
              />
            </div>
            <hr
              style={{
                border: "1px solid #E8E8E8",
                borderBottom: "none",
                // marginTop: 30,
              }}
            />
            <div style={{ marginTop: 30 }}>
              <div className="labor-rates-container">
                <Row justify="space-between" align="middle">
                  <Col>
                    <h1 className="labor-rates-title">Carpentry</h1>
                  </Col>
                  <Col style={{ marginRight: "20px" }}>
                    <Button
                      type="primary"
                      style={{ color: "black" }}
                      icon={<PlusOutlined />}
                      onClick={() => handleAddSurface("carpentry")}
                    />
                  </Col>
                </Row>
              </div>
              <Carpentry
                carpentryRates={carpentryRates}
                setCarpentryState={setCarpentryState}
                setRatesValue={setRatesValue}
                handleCheckModifiedOnEdit={handleCheckModifiedCarpentryOnEdit}
              />
            </div>
            <hr
              style={{
                border: "1px solid #E8E8E8",
                borderBottom: "none",
                // marginTop: 30,
              }}
            />
            <div style={{ marginTop: 30 }}>
              <div className="labor-rates-container">
                <Row justify="space-between" align="middle">
                  <Col>
                    <h1 className="labor-rates-title">Equipment</h1>
                  </Col>
                  <Col style={{ marginRight: "20px" }}>
                    <Button
                      type="primary"
                      style={{ color: "black" }}
                      icon={<PlusOutlined />}
                      onClick={() => handleAddSurface("equipment")}
                    />
                  </Col>
                </Row>
              </div>
              <Equipment
                equipmentRates={equipmentRates}
                setEquipmentState={setEquipmentState}
                setRatesValue={setRatesValue}
                handleCheckModifiedOnEdit={handleCheckModifiedEquipmentOnEdit}
              />
            </div>
            <hr
              style={{
                border: "1px solid #E8E8E8",
                borderBottom: "none",
                // marginTop: 30,
              }}
            />
            <div style={{ marginTop: 30 }}>
              <div className="labor-rates-container">
                <Row justify="space-between">
                  <Col>
                    <h1 className="labor-rates-title">Miscellaneous</h1>
                  </Col>
                  <Col style={{ marginRight: "20px" }}>
                    <Button
                      type="primary"
                      style={{ color: "black" }}
                      icon={<PlusOutlined />}
                      onClick={() => handleAddSurface("miscellaneous")}
                    />
                  </Col>
                </Row>
              </div>
              <Miscellaneous
                miscellaneousRates={miscellaneousRates}
                setMiscellaneousState={setMiscellaneousState}
                setRatesValue={setRatesValue}
                handleCheckModifiedOnEdit={handleCheckModifiedMiscOnEdit}
              />
            </div>
            <hr
              style={{
                border: "1px solid #E8E8E8",
                borderBottom: "none",
                // marginTop: 30,
              }}
            />
            <div style={{ marginTop: 30 }}>
              <div className="labor-rates-container">
                <Row justify="space-between">
                  <Col>
                    <h1 className="labor-rates-title">Travel</h1>
                  </Col>
                  <Col style={{ marginRight: "20px" }}>
                    <Button
                      type="primary"
                      style={{ color: "black" }}
                      icon={<PlusOutlined />}
                      onClick={() => handleAddSurface("travel")}
                    />
                  </Col>
                </Row>
              </div>
              <Travel
                travelRates={travelRates}
                setTravelState={setTravelState}
                setRatesValue={setRatesValue}
                handleCheckModifiedOnEdit={handleCheckModifiedTravelOnEdit}
              />
            </div>
          </div>
        </Card>
      </div>
      <div>
        <Card className="root-container">
          <div className="job-container form-container ">
            <div className="profile-content">
              <Row justify="space-between">
                <Col>
                  <h1 className="labor-rates-title">Media</h1>
                </Col>
                <Col>
                  <Camera fileList={fileList} setFileList={setFileList} />
                  <AddMedia fileList={fileList} setFileList={setFileList} />
                </Col>
              </Row>
            </div>
            <EditMedia
              fileList={fileList}
              setFileList={setFileList}
              captionText={captionText}
              setCaptionText={setCaptionText}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </Card>
      </div>
      {isTabletOrMobile ? (
        <div className="onsite-btn-container">
          <Row justify="center" style={{ marginBottom: "20px" }}>
            <Space direction="horizontal" size={"large"}>
              <Col>
                <Button
                  id="next-opt"
                  name="nextOption"
                  onClick={handleNext}
                  type="primary"
                  className="save-button"
                >
                  Next Option
                </Button>
              </Col>
              <Col>
                <Button
                  id="next-pg"
                  name="nextPage"
                  onClick={handleNext}
                  type="primary"
                  className="save-button"
                >
                  PJCC
                </Button>
              </Col>
            </Space>
          </Row>
        </div>
      ) : (
        <div className="onsite-btn-container">
          <Row justify="center">
            <Space direction="horizontal" size={"large"}>
              <Col>
                <Button
                  id="next-opt"
                  name="nextOption"
                  onClick={handleNext}
                  type="primary"
                  className="save-button"
                >
                  Next Option
                </Button>
              </Col>
              <Col>
                <Button
                  id="next-pg"
                  name="nextPage"
                  onClick={handleNext}
                  type="primary"
                  className="save-button"
                >
                  PJCC
                </Button>
              </Col>
            </Space>
          </Row>
        </div>
      )}
    </>
  );
};

export default OptionDetailsC;
