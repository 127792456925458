import { Button, Col, Row, Switch, Select, Radio, message } from "antd";
import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ClientContact from "../../components/proposalSetup/ClientContact";
import JobsiteInfo from "../../components/proposalSetup/JobsiteInfo";
import ProposalSummary from "../../components/proposalSetup/ProposalSummary";
import SetupCheckboxes from "../../components/proposalSetup/SetupCheckboxes";
import SurfaceCheckboxes from "../../components/proposalSetup/SurfaceCheckboxes";
import TermsAndConditionsCheckboxes from "../../components/proposalSetup/TermsAndConditionsCheckboxes";
import {
  setSetupDefaults,
  setSurfaceDefaults,
} from "../../redux/proposal-defaults/action";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import "./ProposalSetupC.less";
import {
  createProposal,
  updateProposal,
  updateProjectStateById,
  setProjectDefaultsUpdated,
  updateProjectDefaults,
} from "../../redux/project/action";
import _ from "lodash";
import api from "../../utils/api";
var crypto = require("crypto");

type Proposal = {
  primary: boolean;
  onSite: boolean;
  maintenance: boolean;
  setup: string[];
  surface: string[];
};

type Props = {
  edit?: boolean;
  handleEdit?: any;
  path?: any;
};

const ProposalSetupC = (props: Props) => {
  const history = useHistory();

  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const dispatch = useDispatch<any>();
  const [proposal, setProposal] = useState<any>(
    items[currentProject]?.projectInfo?.proposal
      ? items[currentProject].projectInfo.proposal
      : {}
  );
  const [termsAndConditions, setTermsAndConditions] = useState<any>();

  const [projectDefaultsProposal, setProjectDefaultsProposal] = useState<any>(
    items[currentProject]?.projectDefaults
  );
  const [updatesForProjectDefaults, setUpdatesForProjectDefaults] = useState<
    any[]
  >([]);

  const [optionsList, setOptionsList] = useState<any>([]);
  const [imagesPerRow, setImagesPerRow] = useState(1);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    let jobType = "";
    let termsAndConditions = [];
    if (items[currentProject]?.projectInfo?.projectSubtype === "Interior") {
      jobType = "interior";
    } else {
      jobType = "exterior";
    }
    const setupDefaultsWithJobType = adminDefaults.proposalSetup.filter(
      (item: any) => item.jobType === jobType
    );
    const surfaceDefaultsWithJobType = adminDefaults.proposalSurface.filter(
      (item: any) => item.jobType === jobType
    );
    dispatch(setSetupDefaults(setupDefaultsWithJobType));
    dispatch(setSurfaceDefaults(surfaceDefaultsWithJobType));
    if (items[currentProject]?.projectInfo?.projectType === "Commercial") {
      termsAndConditions = adminDefaults?.termsAndCondition?.filter(
        (item: any) => item.type === "Commercial"
      );
    } else {
      termsAndConditions = adminDefaults?.termsAndCondition?.filter(
        (item: any) => item.type === "Residential"
      );
    }
    setTermsAndConditions(termsAndConditions);
    const includedOptions: any[] = [];
    items[currentProject].options.map((option: any) => {
      if (option.optionInfo.included === "included") {
        includedOptions.push(option.optionInfo.title);
      }
    });
    let optionsArray = items[currentProject].options.map((item: any) => {
      if (item.optionInfo.included === "included") {
        return item.optionInfo.title;
      }
    });
    setOptionsList(optionsArray);
  }, []);

  const handleFinalize = () => {
    if (updatesForProjectDefaults.length > 0) {
      dispatch(
        setProjectDefaultsUpdated({
          projectId: id,
          updates: projectDefaultsProposal,
        })
      ); //setting project defaults updated state
      dispatch(updateProjectDefaults(updatesForProjectDefaults)); //patch call to update project defaults
    }
    if (props.edit) {
      let oldProposal = _.cloneDeep(items[currentProject].projectInfo.proposal);
      dispatch(updateProposal(id, proposal, oldProposal));
      props.handleEdit();
    } else {
      if (items[currentProject]?.projectInfo?.proposal) {
        let oldProposal = _.cloneDeep(
          items[currentProject].projectInfo.proposal
        );
        dispatch(updateProposal(id, proposal, oldProposal));
        if (props.path) {
          history.push(`/${props.path}/${id}`);
        } else {
          history.push(`/estimator-proposal/${id}`);
        }
      }
    }
  };

  const handleSwitch = (checked: boolean, title: string) => {
    switch (title) {
      case "Primary":
        setProposal({ ...proposal, primary: checked });
        break;
      case "On-site":
        setProposal({ ...proposal, onSite: checked });
        break;
      case "Maintenance":
        setProposal({ ...proposal, maintenance: checked });
        break;
      default:
        break;
    }
  };

  const handleWarrantySwitch = (checked: boolean) => {
    setProposal({ ...proposal, addWarranty: checked });
  };

  const handleOptionMediaSwitch = (checked: boolean) => {
    setProposal({ ...proposal, addOptionMedia: checked });
  };

  const handleTermsAndConditions = (e: CheckboxChangeEvent, item: any) => {
    let newData = [];
    if (proposal && proposal.termsAndConditions) {
      newData = proposal.termsAndConditions?.map((item: any) => {
        return { ...item };
      });
    }
    if (e.target.checked) {
      newData.push(item);
    } else {
      const index = newData.findIndex((obj: any) => obj._id === item._id);
      newData.splice(index, 1);
    }
    setProposal({ ...proposal, termsAndConditions: newData });
  };

  const handleSurface = (e: CheckboxChangeEvent, item: any) => {
    let newData = [];
    if (proposal && proposal.surface) {
      newData = proposal.surface?.map((item: any) => {
        return { ...item };
      });
    }
    if (e.target.checked) {
      newData.push(item);
    } else {
      const index = newData.findIndex((obj: any) => obj._id === item._id);
      newData.splice(index, 1);
    }
    setProposal({ ...proposal, surface: newData });
  };

  const handleSetup = (e: CheckboxChangeEvent, item: any) => {
    let newData = [];
    if (proposal && proposal.setup) {
      newData = proposal.setup?.map((item: any) => {
        return { ...item };
      });
    }
    if (e.target.checked) {
      newData.push(item);
    } else {
      const index = newData.findIndex((obj: any) => obj._id === item._id);
      newData.splice(index, 1);
    }
    setProposal({ ...proposal, setup: newData });
  };

  const getItemCategory = (category: string) => {
    switch (category) {
      case "Setup":
        return "setup";
      case "Surface":
        return "surface";
      case "TermsAndConditions":
        return "termsAndConditions";
      default:
        return "";
    }
  };

  const setProjectsDefaultsByCategory = (category: string, updates: any) => {
    switch (category) {
      case "Setup":
        setProjectDefaultsProposal({
          ...projectDefaultsProposal,
          setup: updates,
        });
        break;
      case "Surface":
        setProjectDefaultsProposal({
          ...projectDefaultsProposal,
          surface: updates,
        });
        break;
      case "TermsAndConditions":
        setProjectDefaultsProposal({
          ...projectDefaultsProposal,
          termsAndConditions: updates,
        });
        break;
      default:
        return;
    }
  };

  const handleProjectDefaults = (
    e: CheckboxChangeEvent,
    item: any,
    category: string
  ) => {
    const updatedItem = { ...item, checked: e.target.checked };
    const projectDefaultCategory = getItemCategory(category);

    let updatedProjectDefaultByCategory = projectDefaultsProposal[
      projectDefaultCategory
    ]?.map((innerItem: any) =>
      innerItem._id === item._id
        ? { ...innerItem, checked: e.target.checked }
        : innerItem
    );
    setUpdatesForProjectDefaults([...updatesForProjectDefaults, updatedItem]);
    setProjectsDefaultsByCategory(category, updatedProjectDefaultByCategory);
  };

  const handleSummary = (e: any) => {
    setProposal({ ...proposal, projectSummary: e });
  };

  const handleImagesPerRow = (value: number) => {
    if (value) {
      if (value === 4) {
        message.warning(
          "if caption length is greater than 130 characters it will overlap"
        );
      }
      setProposal({ ...proposal, optionImagesPerRow: value });
    }
    setImagesPerRow(value);
  };

  return (
    <div>
      <div className="ps-container">
        <h1 className="ps-title">
          Proposal Setup ({items[currentProject]?.projectInfo.projectType})
        </h1>

        <hr className="ps-divider" />
        <div className="ps-sub-container">
          <JobsiteInfo
            name={items[currentProject]?.projectInfo.jobName}
            address={items[currentProject]?.projectInfo.streetAddressOne}
            telephone={
              items[currentProject]?.projectInfo.primaryContactTelephone
            }
            email={items[currentProject]?.projectInfo.primaryContactEmail}
          />
          <div className="ps-client-container">
            <h1>Client's contact information</h1>

            <ClientContact
              title="Primary"
              name={items[currentProject]?.projectInfo.primaryContactName}
              telephone={
                items[currentProject]?.projectInfo.primaryContactTelephone
              }
              address={items[currentProject]?.projectInfo.primaryContactAddress}
              email={items[currentProject]?.projectInfo.primaryContactEmail}
              cell={items[currentProject]?.projectInfo.primaryContactCell}
              addToProposal={proposal?.primary}
              onSwitchChange={handleSwitch}
              showSwitch={true}
            />
            {items[currentProject]?.projectInfo.hasOnSiteContact && (
              <ClientContact
                title="On-site"
                name={items[currentProject]?.projectInfo.onSiteContactName}
                telephone={
                  items[currentProject]?.projectInfo.onSiteContactTelephone
                }
                address={
                  items[currentProject]?.projectInfo.onSiteContactAddress
                }
                email={items[currentProject]?.projectInfo.onSiteContactEmail}
                cell={items[currentProject]?.projectInfo.onSiteContactCell}
                addToProposal={proposal?.onSite}
                onSwitchChange={handleSwitch}
                showSwitch={true}
              />
            )}
            {items[currentProject]?.projectInfo.hasMaintenanceContact && (
              <ClientContact
                title="Maintenance"
                name={items[currentProject]?.projectInfo.maintenanceContactName}
                telephone={
                  items[currentProject]?.projectInfo.maintenanceContactTelephone
                }
                address={
                  items[currentProject]?.projectInfo.maintenanceContactAddress
                }
                email={
                  items[currentProject]?.projectInfo.maintenanceContactEmail
                }
                cell={items[currentProject]?.projectInfo.maintenanceContactCell}
                addToProposal={proposal?.maintenance}
                onSwitchChange={handleSwitch}
                showSwitch={true}
              />
            )}
          </div>
          <ProposalSummary
            handleSummary={handleSummary}
            summary={proposal?.projectSummary}
            optionsList={optionsList}
          />
          <SetupCheckboxes
            handleSetupChange={handleSetup}
            checkedSetup={proposal?.setup}
            checkedSetupProjectDefaults={
              projectDefaultsProposal?.setup?.filter(
                (item: any) => !item.isDeleted
              ) || []
            }
            handleSetupChangeProjectDefaults={handleProjectDefaults}
          />
          <SurfaceCheckboxes
            handleSurfaceChange={handleSurface}
            checkedSurfaces={proposal?.surface}
            checkedProjectDefaultsSurfaces={
              projectDefaultsProposal?.surface?.filter(
                (item: any) => !item.isDeleted
              ) || []
            }
            handleProjectDefaultsSurfaceChange={handleProjectDefaults}
          />
          <TermsAndConditionsCheckboxes
            handleTermsChange={handleTermsAndConditions}
            checkedTermsAndConditions={proposal?.termsAndConditions}
            termsAndConditionsArray={termsAndConditions}
            checkedProjectDefaultsTermsAndConditions={
              projectDefaultsProposal?.termsAndConditions?.filter(
                (item: any) => !item.isDeleted
              ) || []
            }
            handleProjectDefaultsTermsChange={handleProjectDefaults}
          />
          <div className="surface-ch-container">
            <Row justify="space-between">
              <h2>Options Media Images</h2>
              <Row gutter={15}>
                <Radio.Group
                  defaultValue={
                    !proposal.optionImagesPerRow
                      ? 3
                      : proposal.optionImagesPerRow
                  }
                  onChange={(e) => {
                    if (!e.target.value) {
                      handleImagesPerRow(3);
                    } else {
                      handleImagesPerRow(e.target.value);
                    }
                  }}
                  buttonStyle="solid"
                >
                  <Radio.Button value={2}>2 per row</Radio.Button>
                  <Radio.Button value={3}>3 per row</Radio.Button>
                  <Radio.Button value={4}>4 per row</Radio.Button>
                </Radio.Group>
                <Col>
                  <Switch
                    checked={proposal?.addOptionMedia}
                    onChange={(checked) => handleOptionMediaSwitch(checked)}
                    style={{ marginTop: 5 }}
                  />
                </Col>
                <Col style={{ marginTop: 5 }}>
                  <p>Add to proposal</p>
                </Col>
              </Row>
            </Row>
            <Row justify="space-between">
              <h2>Limited Two Year Warranty</h2>
              <Row gutter={15} style={{ marginTop: 5 }}>
                <Col>
                  <Switch
                    checked={proposal.addWarranty}
                    onChange={(checked) => handleWarrantySwitch(checked)}
                  />
                </Col>
                <Col>
                  <p>Add to proposal</p>
                </Col>
              </Row>
            </Row>
          </div>
        </div>
      </div>
      <Row justify="center" style={{ marginBottom: "20px" }}>
        <Col>
          <Button
            type="primary"
            onClick={handleFinalize}
            className="ps-finalize-btn"
          >
            Finalize Proposal
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ProposalSetupC;
