import { getAuthUser } from "./../../utils/authToken";
import axios from "axios";
import { API_BASE } from "../../constant";
import api from "../../utils/api";

export const setPjcc = (payload: any) => ({
  type: "SET_PJCC",
  payload,
});

export const updateGm = (payload: any) => ({
  type: "UPDATE_GM",
  payload,
});

export const applyDiscount = (payload: any) => ({
  type: "APPLY_DISCOUNT",
  payload,
});
export const removeDiscount = (payload: any) => ({
  type: "REMOVE_DISCOUNT",
  payload,
});

export const setDiscount = (payload: any) => ({
  type: "SET_DISCOUNT",
  payload,
});
export const setAppliedDiscount = (payload: any) => ({
  type: "SET_APPLIED_DISCOUNT",
  payload,
});

export const fetchPjcc = (id: string) => (dispatch: any) => {
  api
    .get(`pjcc/project/${id}`)
    .then((response: any) => {
      dispatch(setPjcc(response));
    })
    .catch((err) => {
      console.error(err);
    });
};
