const initialState = {
  paints: [],
  primers: [],
  loading: true,
};

const materialDefaults = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_DEFAULTS":
      return {
        ...state,
        paints: action.payload.paints,
        primers: action.payload.primers,
      };
    case "ADD_PAINT":
      return {
        ...state,
        paints: [...state.paints, action.payload],
      };
    case "ADD_PRIMER":
      return {
        ...state,
        primers: [...state.primers, action.payload],
      };
    case "REMOVE_PAINT":
      return {
        ...state,
        paints: state.paints.filter((item: any) => item._id !== action.payload),
      };
    case "REMOVE_PRIMER":
      return {
        ...state,
        primers: state.primers.filter(
          (item: any) => item._id !== action.payload
        ),
      };
    case "UPDATE_PAINT":
      return {
        ...state,
        paints: state.paints.map((item: any) =>
          item._id === action.payload._id ? action.payload : item
        ),
      };
    case "UPDATE_PRIMER":
      return {
        ...state,
        primers: state.primers.map((item: any) =>
          item._id === action.payload._id ? action.payload : item
        ),
      };
    case "BULK_ADD_DEFAULT_MATERIAL":
      if (action.payload[0].material === "paint") {
        return {
          ...state,
          paints: action.payload,
        };
      } else {
        return {
          ...state,
          primers: action.payload,
        };
      }
    case "SHOW_LOADER":
      return {
        ...state,
        loading: true,
      };
    case "HIDE_LOADER":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default materialDefaults;
