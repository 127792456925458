import React from "react";
import { Row, Col, Image } from "antd";
import { BulletIcon, EquipmentIcon } from "../../utils/icons";

type Props = {
  data: {
    _id: string;
    svgString: string;
    title: string;
    description: string;
    position: number;
    addToProjects: boolean;
    isDefault: boolean;
  };
  interpolatedDescription: string;
  equipments: any;
};

const Equipment = ({ data, interpolatedDescription, equipments }: Props) => {
  return (
    <div className="equipment-root">
      <Row gutter={20} align="top">
        <Col>
          <Image
            src={data.svgString}
            style={{
              paddingTop: "0px",
            }}
            preview={false}
          />
        </Col>
        <Col>
          <h1>Equipment</h1>
        </Col>
      </Row>

      <pre
        className="contract-defaults-description"
        style={{ whiteSpace: "pre-wrap" }}
        dangerouslySetInnerHTML={{
          __html: interpolatedDescription,
        }}
      />

      <div className="equipment-bullets e-margin-tp-30  ">
        <Row>
          {equipments?.map((equipment: any, index: number) => {
            return (
              <Col key={index} span={12}>
                <Row>
                  <Col>
                    <BulletIcon />
                  </Col>
                  <Col>
                    <p>{equipment.item}</p>
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default Equipment;
