import React from "react";
import { Row, Col } from "antd";
import  "./UserContact.less";
import {
  MailIcon,
  MapPinIcon,
  MapPinRedIcon,
  PhoneIcon,
} from "../../utils/icons";
import { useMediaQuery } from "react-responsive";

type Props = {
  contact: string;
  email: string;
  address: string;
};

const UserContact = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:800px)" });

  return (
    <>
      {!isTabletOrMobile ? (
        <div className="user-contact-root">
          <h1>Contact Details</h1>
          <div style={{ marginTop: "30px" }}>
            <Row gutter={16}>
              <Col>
                <PhoneIcon />
              </Col>
              <Col>
                <p>{props.contact}</p>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }} gutter={16}>
              <Col>
                <MailIcon />
              </Col>
              <Col>
                <p>{props.email}</p>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }} gutter={16}>
              <Col>
                <MapPinRedIcon />
              </Col>
              <Col>
                <p>{props.address}</p>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="user-contact-root" style={{ width: "350px" }}>
          <h1>Contact Details</h1>
          <div style={{ marginTop: "30px" }}>
            <Row gutter={16}>
              <Col>
                <PhoneIcon />
              </Col>
              <Col>
                <p>{props.contact}</p>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }} gutter={16}>
              <Col>
                <MailIcon />
              </Col>
              <Col>
                <p>{props.email}</p>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }} gutter={16}>
              <Col>
                <MapPinRedIcon />
              </Col>
              <Col>
                <p>{props.address}</p>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default UserContact;
