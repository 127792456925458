export const columns = [
  {
    title: "Draw Number",
    dataIndex: "draw_number",
    key: "draw_number",
    render: (data: number) => {
      return <p>{data}</p>;
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (data: string) => {
      return <p>{data}</p>;
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: () => {
      return <p>Amount</p>;
    },
  },
  {
    title: "Retainer",
    dataIndex: "retainer",
    key: "retainer",
    render: (data: number) => {
      return <p>{data}</p>;
    },
  },
  {
    title: "Deposit Amount",
    dataIndex: "deposit_amount",
    key: "deposit_amount",
    render: (data: number) => {
      return <p>{data}</p>;
    },
  },
  {
    title: "Balance Due",
    dataIndex: "balance_due",
    key: "balance_due",
    render: (data: string) => {
      return <h1>{data}</h1>;
    },
  },
];
