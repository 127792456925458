import React, { useState } from "react";
import { Card, Form, Input, Button, Row, Col, Select, Modal } from "antd";
import "./EditDeliveryAddressModal.less";

import { RootStateOrAny, useSelector } from "react-redux";

import { usStates, canadaStates } from "../..//constant/index";

const { Option } = Select;

type Props = {
  showModal: boolean;
  onModalCancel: any;
  onFinish?: any;
};

const EditDeliveryAddressModal = (props: Props) => {
  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const [form] = Form.useForm();
  const [states, setStates] = useState<any>([...usStates, ...canadaStates]);

  const onFinish = (values: any) => {
    const data = {
      clientName: values.clientName,
      streetAddressOne: values.streetAddressOne,
      streetAddressTwo: values.streetAddressTwo,
      city: values.city,
      state: values.state,
      country: values.country,
      zip: values.zip,
    };

    props.onFinish(data);
  };

  const onCancel = () => {
    props.onModalCancel();

    form.resetFields();
  };

  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "+" || e.key === "-" || e.key === "." || e.key === "e") {
      e.preventDefault();
    }
  };

  const handleCountry = (value: any) => {
    if (usStates.includes(value)) {
      form.setFieldsValue({
        country: "United States",
      });
    }
    if (canadaStates.includes(value)) {
      form.setFieldsValue({
        country: "Canada",
      });
    }
  };

  return (
    <div>
      <Modal
        title={
          <h2 style={{ marginTop: 10, fontWeight: "bold" }}>
            Add Custom Address
          </h2>
        }
        visible={props.showModal}
        footer={false}
        onCancel={onCancel}
        width={"50%"}
      >
        <div className="job-container form-container ">
          <Form
            onFinish={onFinish}
            form={form}
            scrollToFirstError
            autoComplete="off"
            layout="vertical"
          >
            {/* title */}
            <div>
              <h1 className="form-subtitle">Delivery Address information</h1>
            </div>
            {/* form */}

            <div className="job-form" style={{ marginTop: "23px" }}>
              <Row gutter={17}>
                {/* <Col span={12}>
                  <div>
                    <Form.Item
                      initialValue={items[currentProject]?.projectInfo?.jobName}
                      hasFeedback
                      name="jobName"
                      label={<h1 className="form-text">Job Name</h1>}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Job Name!",
                        },
                        checkEmpty,
                      ]}
                    >
                      <Input className="form-input" />
                    </Form.Item>
                  </div>
                </Col> */}
                <Col span={12}>
                  <div>
                    <Form.Item
                      hasFeedback
                      name="clientName"
                      label={<h1 className="form-text">Client's Name</h1>}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Client Name!",
                        },
                        checkEmpty,
                      ]}
                    >
                      <Input className="form-input" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={17}>
                <Col span={12}>
                  <div>
                    <Form.Item
                      //   initialValue={
                      //     items[currentProject]?.projectInfo?.streetAddressOne
                      //   }
                      hasFeedback
                      name="streetAddressOne"
                      label={<h1 className="form-text">Street Address 1</h1>}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Street Address!",
                        },
                        checkEmpty,
                      ]}
                    >
                      <Input className="form-input" />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Form.Item
                      //   initialValue={
                      //     items[currentProject]?.projectInfo?.streetAddressTwo
                      //   }
                      name="streetAddressTwo"
                      label={
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <h1 className="form-text">Street Address 2</h1>
                          <p
                            style={{
                              color: "#CACACA",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            (optional)
                          </p>
                        </div>
                      }
                      rules={[checkEmpty]}
                    >
                      <Input className="form-input" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={17}>
                <Col span={12}>
                  <div>
                    <Form.Item
                      //   initialValue={items[currentProject]?.projectInfo?.city}
                      hasFeedback
                      name="city"
                      label={<h1 className="form-text">City</h1>}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the City!",
                        },
                        checkEmpty,
                      ]}
                    >
                      <Input className="form-input" />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="EditJobsite--state--dropdown">
                    <Form.Item
                      //   initialValue={items[currentProject]?.projectInfo?.state}
                      hasFeedback
                      name="state"
                      label={<h1 className="form-text">State</h1>}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the State!",
                        },
                        checkEmpty,
                      ]}
                    >
                      <Select
                        showSearch
                        onChange={handleCountry}
                        className="select-field"
                        placeholder="State"
                        size="large"
                        filterOption={(input: any, option: any) =>
                          (option?.children?.toString() ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {states?.sort().map((item: any, index: number) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </Select>
                      {/* <Input className="form-input" /> */}
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={17}>
                <Col span={12}>
                  <div>
                    <Form.Item
                      hasFeedback
                      name="zip"
                      label={<h1 className="form-text">Zip</h1>}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Zip code!",
                        },
                        {
                          max: 5,
                          message: "Code cannot exceed 5 digits",
                        },
                        checkEmpty,
                      ]}
                    >
                      <Input
                        type="number"
                        min={0}
                        className="form-input"
                        onKeyDown={handleKeyDown}
                        onPaste={(e: any) => {
                          const pastedText = e.clipboardData.getData("text");
                          const parsedText = parseFloat(pastedText);
                          if (pastedText < 0) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Form.Item
                      hasFeedback
                      name="country"
                      label={<h1 className="form-text">Country</h1>}
                    >
                      {/* <Select
                          onChange={handleCountry}
                          className="select-field"
                          placeholder="Country"
                          size="large"
                        >
                          <Option value={"Canada"}>Canada</Option>
                          <Option value={"United States"}>United States</Option>
                        </Select> */}
                      <Input className="form-input" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>

            {/* Button */}
            <div className="btn-container">
              <div>
                <Button
                  onClick={onCancel}
                  style={{
                    border: "none",
                    color: "#FDB913",
                    width: "94px",
                    height: "40px",
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  style={{
                    border: "none",
                    color: "#1F1F1F",
                    width: "94px",
                    height: "40px",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default EditDeliveryAddressModal;
