import { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import MaterialDefaultC from "../../containers/material-defaults/MaterialDefaultC";
import { fetchMaterialDefaults } from "../../redux/material-defaults/action";

const MaterialDefaults = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMaterialDefaults());
  }, []);
  return <MaterialDefaultC />;
};

export default MaterialDefaults;
