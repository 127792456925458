import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Layout, Steps, Row, Col, Button } from "antd";
import ProposalSetupC from "../../containers/ProposalSetup/ProposalSetupC";
import ManagerSteps from "./ManagerSteps";
import useActiveProject from "../../utils/useActiveProject";
import useSyncProjectData from "../../utils/useSyncProjectData";
import { useParams } from "react-router-dom";
import { Spin } from "antd";

const { Content } = Layout;
const { Step } = Steps;
const ManagerProposalSetup: React.FC = () => {
  useActiveProject();
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);
  const { id } = useParams<any>();
  const [isLoading] = useSyncProjectData(id);
  return (
    <>
      {isLoading === true ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <Content>
          <ManagerSteps current={6} />
          <div className="steps-content">
            <Row>
              <Col className="gutter-row" span={24}>
                <ProposalSetupC path="manager-proposal" />
              </Col>
            </Row>
          </div>
        </Content>
      )}
    </>
  );
};

export default ManagerProposalSetup;
