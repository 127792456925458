import React from "react";
import VendorsDefault from "../../containers/admin-vendors/VendorsDefault";

type Props = {};

const Vendors = (props: Props) => {
  return (
    <div>
      <VendorsDefault />
    </div>
  );
};

export default Vendors;
