import React, { useState } from "react";
import "./CommercialPopUps.less";
import { Modal, Button, Row, Col, Select, Input, Form, Radio } from "antd";
const { Option } = Select;
type Props = {
  handleSetupAdd?: any;
  title?: string;
  showSetupInteriorModal: boolean;
  toggleSetupInteriorModal: (item: any) => void;
};

const SetupInteriorModal = (props: Props) => {
  const [currentSelect, setCurrentSelect] = useState("customerTasks");
  const [form] = Form.useForm();
  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };

  const onFinish = (values: any) => {
    props.handleSetupAdd(values);
    form.resetFields();
  };

  const handleChange = (e: string) => {
    setCurrentSelect(e);
  };

  return (
    <Modal
      className="job-type-comm-modal"
      centered={true}
      visible={props.showSetupInteriorModal}
      onOk={props.toggleSetupInteriorModal}
      onCancel={props.toggleSetupInteriorModal}
      footer={false}
      title={<h1 style={{ marginTop: 10 }}>Setup ({props.title})</h1>}
    >
      <div>
        <Form
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            hasFeedback
            name="title"
            label={<h2>Select task category</h2>}
            rules={[
              {
                required: true,
                message: "Please select the task!",
              },
              checkEmpty,
            ]}
          >
            <Select value={currentSelect} onChange={handleChange}>
              <Option value="customer tasks">Customer Tasks</Option>
              <Option value="certapro tasks">Certapro Tasks</Option>
            </Select>
          </Form.Item>

          {currentSelect === "certapro tasks" && (
            <Form.Item
              hasFeedback
              name="category"
              label={<h3>Certapro will</h3>}
              rules={[
                {
                  required: true,
                  message: "Please select the category!",
                },
              ]}
            >
              <Radio.Group>
                <Radio style={{ marginRight: 40 }} value="Cover and protect">
                  Cover and protect
                </Radio>
                <Radio value="will do">will do</Radio>
              </Radio.Group>
            </Form.Item>
          )}

          <Form.Item
            hasFeedback
            name="task"
            label={<h2>Add Task</h2>}
            rules={[
              {
                required: true,
                message: "Please enter the task!",
              },
              checkEmpty,
            ]}
          >
            <Input
              placeholder="Type here"
              className="default-material-form-input"
            />
          </Form.Item>

          <Row style={{ marginTop: 40 }} gutter={10} justify="end">
            <Col>
              <Button
                onClick={props.toggleSetupInteriorModal}
                className="job-type-add-comm-cancel"
              >
                Cancel
              </Button>
            </Col>

            <Col>
              <Button
                htmlType="submit"
                type="primary"
                className="job-type-add-comm-btn"
              >
                Add
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default SetupInteriorModal;
