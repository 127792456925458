import { message } from "antd";
const initialState: any = {
  projectPjcc: [],
  discountsApplied: [],
  message: "",
};

const projectPjcc = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_PJCC":
      return { ...state, projectPjcc: action.payload };
    case "UPDATE_GM": {
      const newData: any = state.projectPjcc.map((item: any) => {
        return { ...item };
      });
      if (action.payload.value >= 0 && action.payload.value < 100) {
        newData[action.payload.index].grossMargin = action.payload.value;
        newData[action.payload.index].totalAmount =
          newData[action.payload.index].cost /
          (1 - newData[action.payload.index].grossMargin / 100);
        if (
          action.payload.index === 1 ||
          action.payload.index === 2 ||
          action.payload.index === 3
        ) {
          const addValueToMaterial =
            newData[action.payload.index].totalAmount -
            state.projectPjcc[action.payload.index].totalAmount;

          //calc totalmaterialcost
          newData[4].totalAmount += addValueToMaterial;

          //calc estimated price
          const estimatedPrice =
            newData[4].totalAmount - state.projectPjcc[4].totalAmount;
          newData[20].totalAmount += estimatedPrice;
          newData[0].totalAmount += estimatedPrice;
        } else if (
          action.payload.index === 5 ||
          action.payload.index === 6 ||
          action.payload.index === 7
        ) {
          //calc estimated price
          const estimatedPrice =
            newData[action.payload.index].totalAmount -
            state.projectPjcc[action.payload.index].totalAmount;
          newData[20].totalAmount += estimatedPrice;
          newData[0].totalAmount += estimatedPrice;
        } else if (action.payload.index === 11 || action.payload.index === 12) {
          const addToLabor =
            newData[action.payload.index].totalAmount -
            state.projectPjcc[action.payload.index].totalAmount;
          //calc total labor
          newData[14].totalAmount += addToLabor;
          //calc estimated price
          const estimatedPrice =
            newData[14].totalAmount - state.projectPjcc[14].totalAmount;
          newData[20].totalAmount += estimatedPrice;
          newData[0].totalAmount += estimatedPrice;
        }
        // calc gross Margin
        newData[16].totalAmount = newData[0].totalAmount - newData[15].cost;
        newData[17].totalAmount =
          newData[16].totalAmount / newData[10].totalAmount;
        newData[19].totalAmount =
          (1 - newData[15].cost / newData[0].totalAmount) * 100;
      }

      return {
        ...state,
        projectPjcc: newData,
      };
    }
    case "SET_APPLIED_DISCOUNT": {
      return { ...state, discountsApplied: action.payload };
    }
    case "SET_DISCOUNT": {
      console.log(action.payload);
      let newData = state.projectPjcc.map((item: any) => ({ ...item }));

      const discount = state.discountsApplied
        ? [...state.discountsApplied]
        : [];

      if (action.payload.checked) {
        discount.push(action.payload);
      } else {
        const discountIndex = discount.findIndex(
          (item: any) => item._id === action.payload._id
        );
        discount.splice(discountIndex, 1);
      }
      let priceToClient = newData[0].totalAmount;
      for (let item of discount) {
        if (item.amountType === "percentage") {
          priceToClient -= priceToClient * (item.amount / 100);
        } else {
          priceToClient -= item.amount;
        }
      }

      newData[20].totalAmount = priceToClient;
      const pjccProjectFullBidCosts = newData.find(
        (item: any) => item.name === "Total Cost"
      );
      const pjccProjectPriceToClient = newData.find(
        (item: any) => item.name === "Price to Client"
      );

      // gross margin update
      newData[16].totalAmount = newData[20].totalAmount - newData[15].cost;
      // project margin update
      newData[19].totalAmount =
        (1 -
          pjccProjectFullBidCosts.cost / pjccProjectPriceToClient.totalAmount) *
        100;
      // gross profit p/hr update
      newData[17].totalAmount =
        newData[16].totalAmount / newData[10].totalAmount;

      newData[17].totalAmount = isFinite(newData[17].totalAmount)
        ? newData[17].totalAmount
        : 0;

      return {
        ...state,
        projectPjcc: newData,
        discountsApplied: discount,
      };
    }
    default:
      return state;
  }
};

export default projectPjcc;
