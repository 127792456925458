import React from 'react';
import { Row, Col } from 'antd';
import UserProfileC from '../../containers/user-profile/UserProfileC';

const UserProfile: React.FC = () => {
  return (
    <div>
      <Row>
        <Col span={15}>
          <UserProfileC />
        </Col>
      </Row>
    </div>
  );
};

export default UserProfile;
