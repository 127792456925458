import React from 'react';
import { Row, Col } from 'antd';
import './LaborRatesDefaultsC.less';
import PaintSurfacesDefaults from './paint-surfaces/PaintSurfacesDefaults';
import CarpentrySurfacesDefaults from './carpentry-surfaces/CarpentrySurfacesDefaults';
import EquipmentDefaults from './equipment/EquipmentDefaults';
import MiscDefaults from './misc-defaults/MiscDefaults';
import TravelDefaults from './travel-defaults/TravelDefaults';

type Props = {};

const LaborRatesDefaultsC = (props: Props) => {
  return (
    <div className="labor-rates-defaults-root">
      <h1 className="lr-defaults-text">Labor Rates Defaults</h1>
      <hr style={{ border: '1px solid #E8E8E8' }} />
      <PaintSurfacesDefaults />
      <CarpentrySurfacesDefaults />
      <EquipmentDefaults />
      <MiscDefaults />
      <TravelDefaults />
    </div>
  );
};

export default LaborRatesDefaultsC;
