import React from "react";
import ContractDefaultContainer from "../../containers/contract-default-container/ContractDefaultContainer";

function ContractDefault() {
  return (
    <div>
      <ContractDefaultContainer />
    </div>
  );
}

export default ContractDefault;
