import { useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Input,
  Form,
  DatePicker,
  message,
} from "antd";
import "./AddItemDefaultModal.less";
import { useDispatch } from "react-redux";
import {
  addContractorDefaults,
  updateContractorDefaults,
} from "../../../redux/contractor-data/action";
import moment from "moment";

type Props = {
  showContractorDataModal: boolean;
  toggleContractorDataModal: () => void;
  item: any;
  edit: boolean;
};

const AddItemContractor = (props: Props) => {
  const dispatch = useDispatch<any>();
  const [form] = Form.useForm();
  useEffect(() => {
    if (Object.keys(props.item).length > 0) {
      form.setFieldsValue({ ...props.item, date: moment(props.item.date) });
    } else {
      form.resetFields();
    }
  }, [props.item, props.showContractorDataModal]);

  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };
  const onFinish = (values: any) => {
    if (!props.edit) {
      dispatch(addContractorDefaults(values));
    } else {
      dispatch(updateContractorDefaults({ ...values, _id: props.item._id }));
    }
    props.toggleContractorDataModal();
  };
  return (
    <Modal
      className="default-modal-rt"
      centered={true}
      visible={props.showContractorDataModal}
      onOk={props.toggleContractorDataModal}
      onCancel={props.toggleContractorDataModal}
      footer={false}
      title={
        <h1 style={{ marginTop: 10 }} className="default-modal-add-item">
          {!props.edit ? "Add item" : "Edit item"}
        </h1>
      }
    >
      <div className="default-material-form">
        <Form
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="fullName"
                label={<h2>Add Contractor name</h2>}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  checkEmpty,
                ]}
              >
                <Input className="default-material-form-input" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="date"
                label={<h2>Date</h2>}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker className="contractor-date-picker" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="email"
                label={<h2>Email Address</h2>}
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Enter valid email",
                  },
                  checkEmpty,
                ]}
              >
                <Input
                  disabled={props.edit}
                  className="default-material-form-input"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="phone"
                label={<h2>Contact Number</h2>}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  checkEmpty,
                ]}
              >
                <Input type="number" className="default-material-form-input" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10} align="middle" justify="end">
            <Col>
              <Button
                onClick={props.toggleContractorDataModal}
                className="default-material-cancel-btn"
              >
                Cancel
              </Button>
            </Col>

            <Col>
              <Button
                htmlType="submit"
                type="primary"
                className="default-material-add-btn"
              >
                {!props.edit ? "Add Contractor" : "Update Contractor"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default AddItemContractor;
