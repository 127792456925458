import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import ContractCompensation from "../../components/contract-setup/ContractCompensation";
import ContractDate from "../../components/contract-setup/ContractDate";
import ContractDescription from "../../components/contract-setup/ContractDescription";
import ContractDocuments from "../../components/contract-setup/ContractDocuments";
import ContractFooter from "../../components/contract-setup/ContractFooter";
import ContractHeader from "../../components/contract-setup/ContractHeader";
import CorrectionPeriod from "../../components/contract-setup/CorrectionPeriod";
import Equipment from "../../components/contract-setup/Equipment";
import api from "../../utils/api";
import "./JobJacketContract.less";
import { Col, Image, Row } from "antd";
import ContractProject from "../../components/contract-setup/ContractProject";

type Props = {};

interface ContractDefaultDTO {
  _id: string;
  svgString: string;
  title: string;
  description: string;
  startAndCompletionDate?: {
    startDateFirmString: string;
    startDateFlexibleString: string;
    completionDateFirmString: string;
    completionDateFlexibleString: string;
  };
  position: number;
  addToProjects: boolean;
  isDefault: boolean;
  isEditable: boolean;
}

const JobJacketContract = (props: Props) => {
  const [totalSum, setTotalSum] = useState(0);
  const [equipment, setEquipment] = useState<any>([]);
  const [compensation, setCompensation] = useState([]);
  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const values = {
    startDate: moment(
      items[currentProject].prePresentationChecklist?.projectStartDate
    ).format("DD MMM,YYYY"),
    completionDate: moment(
      items[currentProject].prePresentationChecklist?.jobCompleteDate
    ).format("DD MMM,YYYY"),

    contractor:
      items[currentProject].prePresentationChecklist?.crew?.fullName ??
      "_______________",
    companyName: adminDefaults?.companyInfo.companyName,
    jobName: items[currentProject].projectInfo?.jobName,
  };

  useEffect(() => {
    const productionItems = items[
      currentProject
    ].projectInfo?.productionPjcc?.filter((item: any) => {
      return item.appearOnWorkOrder;
    });
    setCompensation(productionItems);

    setTotalSum(
      productionItems?.reduce((initial: any, curValue: any) => {
        return (
          initial +
          parseFloat(
            curValue.productionTargets ? curValue?.productionTargets : 0
          )
        );
      }, 0)
    );
    const equipmentRates = items[currentProject]?.options
      ?.map((item: any) => item.rates?.equipmentRates)
      ?.reduce((acc: any, val: any) => acc.concat(val), []);
    const uniqueEquipmentRates = [
      ...new Map(equipmentRates.map((rate: any) => [rate.item, rate])).values(),
    ];

    setEquipment(uniqueEquipmentRates);
  }, []);

  const interpolateDescription = (description: string, values: any) => {
    return description.replace(
      /\{(.*?)\}/g,
      (match, key) => values[key.trim()] || match
    );
  };

  return (
    <div className="jjc-root">
      <ContractHeader companyInfo={adminDefaults?.companyInfo} />
      <hr style={{ border: "1px solid #E8E8E8" }} />
      {[...adminDefaults.contractDefaults]
        .sort((a, b) => a.position - b.position)
        .map((item: ContractDefaultDTO, index: number) => {
          if (item.addToProjects) {
            const interpolatedDescription = interpolateDescription(
              item.description,
              values
            );
            if (item.title === "Project") {
              return (
                <div className="contract-defaults-root" key={index}>
                  <ContractProject
                    data={item}
                    jobName={items[currentProject].projectInfo?.jobName}
                  />
                </div>
              );
            }
            if (item.title === "Compensation") {
              return (
                <ContractCompensation
                  data={item}
                  totalAmount={parseFloat(totalSum?.toFixed(2))}
                  amount={compensation}
                  compensation={
                    items[currentProject].prePresentationChecklist
                      ?.paymentTermsException
                  }
                />
              );
            }
            if (
              item.title === "Equipment" &&
              items[currentProject]?.prePresentationChecklist?.showEquipment
            ) {
              return (
                <Equipment
                  data={item}
                  interpolatedDescription={interpolatedDescription}
                  equipments={equipment}
                />
              );
            }
            if (item.title === "Correction Period") {
              return (
                <CorrectionPeriod
                  data={item}
                  correction={
                    items[currentProject].prePresentationChecklist
                      ?.correctionPeriod
                  }
                />
              );
            }
            if (
              item.title === "Start/Completion Date" &&
              item.startAndCompletionDate
            ) {
              return (
                <ContractDate
                  data={item.startAndCompletionDate}
                  svgString={item.svgString}
                  startDate={
                    items[currentProject].prePresentationChecklist
                      ?.projectStartDate
                  }
                  completionDate={
                    items[currentProject].prePresentationChecklist
                      ?.jobCompleteDate
                  }
                  startDateFirmOrFlexible={
                    items[currentProject].prePresentationChecklist
                      ?.startDateFirmOrFlexible
                  }
                  completionDateFirmOrFlexible={
                    items[currentProject].prePresentationChecklist
                      ?.completionDateFirmOrFlexible
                  }
                />
              );
            } else
              return (
                <div className="contract-defaults-root" key={index}>
                  <Row>
                    {item.svgString && (
                      <Col>
                        <Image
                          src={item.svgString}
                          style={{
                            paddingTop: "0px",
                            paddingRight: "18px",
                          }}
                          preview={false}
                        />
                      </Col>
                    )}
                    <Col>
                      <h1>{item.title}</h1>
                    </Col>
                  </Row>
                  <pre
                    className="contract-defaults-description"
                    style={{ whiteSpace: "pre-wrap" }}
                    dangerouslySetInnerHTML={{
                      __html: interpolatedDescription,
                    }}
                  />
                </div>
              );
          }
        })}
      <hr style={{ border: "1px solid #E8E8E8" }} />
      <ContractFooter companyInfo={adminDefaults?.companyInfo} />
    </div>
  );
};

export default JobJacketContract;
