import { useEffect, useState } from "react";
import { Button, Col, Dropdown, Menu, Row, Spin, Table } from "antd";
import api from "../../utils/api";
import { MoreActionIcon } from "../../utils/icons";
import AddSegmentModal from "../../components/pop-ups/AddSegmentModal";

interface Segment {
  _id?: string;
  segmentName: string;
  segmentIcon: string;
  subSegments: string[];
  iconName: string;
}
const SegmentDefaultsContainer = () => {
  const [loading, setLoading] = useState(false);
  const [marketSegments, setMarketSegments] = useState<Segment[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingSegment, setEditingSegment] = useState<Segment | null>(null);

  useEffect(() => {
    api
      .get("market-segments")
      .then((resp: any) => {
        console.log(resp);
        setMarketSegments(resp);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const showModal = (segment: Segment | null = null) => {
    setEditingSegment(segment);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingSegment(null);
  };

  const handleOk = (updatedSegment: Segment) => {
    if (editingSegment) {
      // Handle update logic here
      console.log("Updated Segment:", updatedSegment);
      api
        .patch(`market-segments/${updatedSegment._id}`, updatedSegment)
        .then((resp: any) => {
          console.log("Segment Updated:", resp);
          // Update the segment in the state
          setMarketSegments((prevSegments) =>
            prevSegments.map((segment) =>
              segment._id === updatedSegment._id ? resp : segment
            )
          );
        })
        .catch((error: any) => {
          console.error("Error updating segment:", error);
        });
    } else {
      // Handle add logic here
      console.log("New Segment:", updatedSegment);
      api
        .post("market-segments/create", updatedSegment)
        .then((resp: any) => {
          console.log("Segment Created:", resp);
          setMarketSegments((prevSegments) => [...prevSegments, resp]);
        })
        .catch((error: any) => {
          console.error("Error creating segment:", error);
        });
    }
    setIsModalVisible(false);
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "segmentName",
      key: "segmentName",
      sorter: (a: Segment, b: Segment) =>
        a.segmentName.localeCompare(b.segmentName),
    },
    {
      title: "Icon",
      dataIndex: "segmentIcon",
      key: "segmentIcon",
      render: (icon: string) => {
        // Check if the icon is a data URL
        if (icon.startsWith("data:image/svg+xml,")) {
          return (
            <img
              src={icon}
              alt="Segment Icon"
              style={{ display: "inline-block", width: 35, height: 35 }}
            />
          );
        }

        // Otherwise, assume it's an inline SVG string
        return (
          <span
            dangerouslySetInnerHTML={{ __html: icon }}
            style={{ display: "inline-block", width: 35, height: 35 }}
          />
        );
      },
    },
    {
      title: "Sub Segments",
      dataIndex: "subSegments",
      key: "subSegments",
      render: (subSegments: string[]) => subSegments.join(", "),
    },
    {
      title: "Action",
      width: 100,
      render: (segment: Segment) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="edit"
                  style={{ fontWeight: "bold" }}
                  onClick={() => showModal(segment)}
                >
                  Edit
                </Menu.Item>
                {/* <Menu.Item
                  key="delete"
                  style={{ fontWeight: "bold" }}
                  onClick={() => handleDelete(data._id)}
                >
                  Delete
                </Menu.Item> */}
              </Menu>
            }
            trigger={["click"]}
          >
            <div style={{ cursor: "pointer" }}>
              <MoreActionIcon />
            </div>
          </Dropdown>
        );
      },
    },
  ];
  return (
    <>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <AddSegmentModal
            visible={isModalVisible}
            onCancel={handleCancel}
            onOk={handleOk}
            segmentData={editingSegment || undefined}
          />
          <div>
            <Row
              className="contract-default-header"
              align="middle"
              justify="space-between"
            >
              <Col>
                <h1 style={{ paddingTop: "8px" }}>Segment Defaults</h1>
              </Col>
              <Col>
                <Button
                  className="contract-add"
                  type="primary"
                  onClick={() => showModal()}
                >
                  + Add
                </Button>
              </Col>
            </Row>
            {marketSegments.length > 0 && (
              <Table
                scroll={{ x: "900px" }}
                rowKey="_id"
                columns={tableColumns}
                dataSource={marketSegments}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SegmentDefaultsContainer;
