import { Divider } from "antd";
import { BulletIconBlack } from "../../utils/icons";
import "../../components/proposal/Proposal.less";
import { useMediaQuery } from "react-responsive";

type Props = {
  setup?: any;
  surface?: any;
  termsAndConditions?: any;
};

const TermsConditions = (props: Props) => {




  const isTabletOrMobile = useMediaQuery({ query: "(max-width:850px)" });

  const termsConditions = [
    {
      title: "PAYMENT TERMS",
      detail:
        "Net 30 unless otherwise specified. Checks should be made out to CPP Indianapolis.",
    },

    {
      title: "PAYMENT TERMS",
      detail:
        "The number of colors selected and the placement of each color will impact the labor required to complete your painting project.Please communicate your desired color placement (match existing, one new color, two-tone, multi-tone, etc.) so that your Sales Associate canaccurately estimate your project. If number of colors or placement of colors changes after the original quote, updates to the quote may be required to reflect appropriate labor requirements.",
    },
    {
      title: "PAYMENT TERMS",
      detail:
        "Large surface areas are typically sprayed, and smaller surfaces such as trim, windows and door frames are painted by brush and roller, unless otherwise specified.",
    },
    {
      title: "PAYMENT TERMS",
      detail:
        "Net 30 unless otherwise specified. Checks should be made out to CPP Indianapolis.",
    },
    {
      title: "PAYMENT TERMS",
      detail:
        "Net 30 unless otherwise specified. Checks should be made out to CPP Indianapolis.",
    },
    {
      title: "PAYMENT TERMS",
      detail:
        "Net 30 unless otherwise specified. Checks should be made out to CPP Indianapolis.",
    },
    {
      title: "PAYMENT TERMS",
      detail:
        "Net 30 unless otherwise specified. Checks should be made out to CPP Indianapolis.",
    },
  ];
  if (props?.termsAndConditions == undefined || props?.termsAndConditions.length == 0) { return null }
  return (
    <>
      {isTabletOrMobile ? (
        <div
          className="terms-container"
          style={{
            marginTop: 30,
            marginBottom: 50,
            marginLeft: 30,
            marginRight: 25,
            width: "800px",
          }}
        >
          <h2 className="content-title">TERMS & CONDITIONS</h2>
          <Divider
            type="horizontal"
            style={{
              marginTop: 0,
              marginRight: 50,
              borderColor: "#FDB913",
              borderWidth: 5,
            }}
          ></Divider>
          <div style={{ marginTop: "25px" }}>
            {props?.termsAndConditions?.map((item: any, index: any) => {
              return (
                <div
                  className="tasks-wrapper"
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div>
                    <BulletIconBlack />
                  </div>

                  <p
                    className="terms-text"
                    style={{
                      fontWeight: "normal",
                      marginLeft: "10px",
                      marginRight: "5px",
                    }}
                  >
                    <strong>{item.title}:</strong>
                    {item.detail}
                  </p>
                </div>
              );
            })}
          </div>
          <Divider
            type="horizontal"
            style={{
              marginTop: 20,
              marginRight: 50,
              borderColor: "#000000",
              borderWidth: 5,
            }}
          ></Divider>
        </div>
      ) : (
        <div
          className="terms-container"
          style={{
            marginTop: 30,
            marginBottom: 50,
            marginLeft: 30,
            marginRight: 25,
          }}
        >
          <h2 className="content-title">TERMS & CONDITIONS</h2>
          <Divider
            type="horizontal"
            style={{
              marginTop: 0,
              marginRight: 50,
              borderColor: "#FDB913",
              borderWidth: 5,
            }}
          ></Divider>
          <div style={{ marginTop: "25px" }}>
            {props?.termsAndConditions?.map((item: any, index: any) => {
              return (
                <div
                  className="tasks-wrapper"
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div>
                    <BulletIconBlack />
                  </div>

                  <p
                    className="terms-text"
                    style={{
                      fontWeight: "normal",
                      marginLeft: "10px",
                      marginRight: "5px",
                    }}
                  >
                    <strong>{item.title}:</strong>
                    {item.detail}
                  </p>
                </div>
              );
            })}
          </div>
          <Divider
            type="horizontal"
            style={{
              marginTop: 20,
              marginRight: 50,
              borderColor: "#000000",
              borderWidth: 5,
            }}
          ></Divider>
        </div>
      )}
    </>
  );
};

export default TermsConditions;
