import React from 'react';
import AvailableJobsC from '../../containers/available-jobs/AvailableJobsC';

type Props = {};

const AvailableJobs = (props: Props) => {
  return <AvailableJobsC />;
};

export default AvailableJobs;
