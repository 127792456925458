import { Col, Row } from "antd";

export const columns: any = [
  {
    title: "Surface",
    dataIndex: "item",
    render: (data: string) => {
      return <p>{data}</p>;
    },
  },
  {
    title: "Product",
    render: (data: any) => {
      return (
        <>
          {data?.primerCoats === "spot" ? (
            <p>{data?.paintMaterial}</p>
          ) : (
            <>
              <p>{data?.primerMaterial}</p>
              <p>{data?.paintMaterial}</p>
            </>
          )}
          {/* <p>{data?.paintMaterial}</p>
          <p>{data?.primerMaterial}</p> */}
        </>
      );
    },
  },
  {
    title: "Coats",
    dataIndex: "coats",
    render: (data: number) => {
      return <p>{data}</p>;
    },
  },
  {
    title: "Coats",
    render: (data: any) => {
      return (
        <>
          {data?.primerCoats === "spot" ? (
            <p>{data?.coats}</p>
          ) : (
            <>
              <p>{data?.primerCoats === "full" ? "Full Prime" : "Spot"}</p>
              <p>{data?.coats}</p>
            </>
          )}
          {/* <p>{data?.primerCoats === "full" ? "Full Prime" : "Spot"}</p>
          <p>{data?.coats}</p> */}
        </>
      );
    },
  },
  {
    title: "Gallons",
    // dataIndex: "paintGallons",
    render: (data: any) => {
      return (
        <>
          {data?.primerCoats === "spot" ? (
            <p>{data?.paintGallons.toFixed(2)}</p>
          ) : (
            <>
              <p>{data?.primerGallons.toFixed(2)}</p>
              <p>{data?.paintGallons.toFixed(2)}</p>
            </>
          )}
          {/* <p>{data?.primerGallons.toFixed(2)}</p>
          <p>{data?.paintGallons.toFixed(2)}</p> */}
        </>
      );
    },
  },

  {
    title: "Primer",
    render: (data: any) => {
      return <h1>{data.primerMaterial}</h1>;
    },
  },
  {
    title: "Primer Gallons",
    dataIndex: "primerGallons",
    render: (data: number) => {
      return <p>{data.toFixed(2)}</p>;
    },
  },
];
