import { Row, Col, Button, Table, Dropdown, Menu, Spin, Input } from "antd";
import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  addPayProcessingDefaults,
  deletePayProcessingDefaults,
} from "../../redux/pay-proccesing/action";
import { TrashIcon } from "../../utils/icons";
import { columns } from "./config";
import "./PayProcessing.less";
import ContractPaymentDefault from "../contract-payment-default/ContractPaymentDefault";

const PayProcessing = () => {
  const dispatch = useDispatch();
  const { associates, payProcessing, loading } = useSelector(
    (state: RootStateOrAny) => state.payProcessingDefaults
  );
  const [associateList, setAssociateList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    setAssociateList(
      associates.filter(
        (associate: any) =>
          !payProcessing.find((item: any) => {
            return item._id === associate._id;
          })
      )
    );
  }, [payProcessing, associates]);

  useEffect(() => {
    const filteredList = associates.filter((associate: any) =>
      associate.fullName.toLowerCase().includes(searchValue.toLowerCase())
    );
    setAssociateList(filteredList);
  }, [searchValue, associates]);

  const sortedPayProcessing = [...payProcessing].sort((a: any, b: any) =>
    a.fullName.localeCompare(b.fullName)
  );

  const handleMenuClick = (item: any) => {
    dispatch(addPayProcessingDefaults(item));
  };

  const handleDelete = (id: string) => {
    dispatch(deletePayProcessingDefaults(id));
  };

  const tableColumns = [
    ...columns,

    {
      title: "Action",
      render: (data: any) => {
        return (
          <TrashIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(data._id)}
          />
        );
      },
    },
  ];

  const menu = (
    <Menu
      className="pp-associates-menu pp-associate-scroll-bar"
      style={{ maxHeight: "180px", overflowY: "auto" }}
    >
      <Input.Search
        placeholder="Search item name"
        onChange={(e) => setSearchValue(e.target.value)}
      />
      {associateList.map((item: any) => (
        <Menu.Item onClick={() => handleMenuClick(item)} key={item._id}>
          <p className="pp-associates-text">{item.fullName}</p>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="pay-processing-root">
      <Row className="pp-default-header" align="middle" justify="space-between">
        <Col>
          <h1>Pay Processing Defaults</h1>
        </Col>
        <Col style={{ marginRight: 40 }}>
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button className="pp-add-associates" type="primary">
              Add Associates
            </Button>
          </Dropdown>
        </Col>
      </Row>

      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <hr style={{ border: "1px solid #E8E8E8" }} />
          <Row>
            <ContractPaymentDefault />
          </Row>

          <div className="pp-default-table">
            {sortedPayProcessing?.length > 0 && (
              <Table
                scroll={{ x: "900px" }}
                columns={tableColumns}
                dataSource={sortedPayProcessing}
                loading={!sortedPayProcessing.length}
                rowKey="_id"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PayProcessing;
