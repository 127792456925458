import React, { PropsWithChildren } from "react";

import JobPostingBoardC from "../../containers/job-posting-board/JobPostingBoardC";
import JobPostingCard from "../../containers/job-posting-card/JobPostingCard";

const JobPostingBoard: React.FC<PropsWithChildren<{}>> = ({}) => {
  return (
    <div>
      <JobPostingBoardC />
    </div>
  );
};

export default JobPostingBoard;
