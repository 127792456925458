import React, { useEffect } from "react";
import { Layout, Row, Col, Button } from "antd";
import WoodOrderC from "../../containers/work-order/wood-order/WoodOrderC";
import { useHistory, useParams } from "react-router-dom";
import JobJacketSteps from "./JobJacketSteps";
import useSyncProjectData from "../../utils/useSyncProjectData";
import { Spin } from "antd";

const { Content } = Layout;

const WoodOrder: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const handleConfirm = () => {
    history.push(`/job-jacket-contract/${id}`);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);
  const [isLoading] = useSyncProjectData(id);

  return (
    <>
      {isLoading === true ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <Content>
          <JobJacketSteps current={4} />
          <div className="steps-content">
            <Row gutter={24}>
              <Col className="gutter-row" span={24}>
                <WoodOrderC />
                <Row justify="center" style={{ marginTop: "50px" }}>
                  <Col>
                    <Row style={{ marginBottom: 20 }} justify="center">
                      <Button
                        className="initial-product-btn"
                        onClick={handleConfirm}
                        type="primary"
                      >
                        Next
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Content>
      )}
    </>
  );
};

export default WoodOrder;
