export const columns = [
  {
    title: "Name",
    dataIndex: "fullName",
    sorter: (a: any, b: any) => a.fullName.localeCompare(b.fullName),
  },
  {
    title: "Title",
    dataIndex: "jobTitle",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Phone",
    dataIndex: "phone",
  },
];
