import { Row, Col, Table, Spin, Button, message } from "antd";
import { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import api from "../../utils/api";
import { columns } from "./config";
import CreateAccountC from "../login-signup/CreateAccountC";
import "../pay-processing-defaults/PayProcessing.less";
import { register, updateUser } from "../../redux/user/action";
import { useDispatch } from "react-redux";
import { Switch } from "antd";
import { getAuthUser, storeUser } from "../../utils/authToken";
import { getAssociatePeople } from "../../redux/associatePeople/action";

const Users = () => {
  const dispatch = useDispatch<any>();
  const [invalidEmail, setInvalidEmail] = useState("");
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState<any>({});
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const user = getAuthUser();

  useEffect(() => {
    setLoading(true);
    api
      .get("user/users")
      .then((response: any) => {
        setLoading(false);
        setUsers(response);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const handleAddUser = () => {
    setInvalidEmail("");
    setEdit({});
    setShowAddUserModal(true);
  };

  const toggleShowAddUserModal = (collapse: boolean) => {
    !showAddUserModal ? setShowAddUserModal(true) : setShowAddUserModal(false);
  };

  const onFinish = (values: any) => {
    if (Object.keys(edit).length > 0) {
      let contact;
      if (values.phone === "(___) ___-____") {
        contact = "";
      } else {
        contact = values.phone;
      }
      const updatedBody = {
        ...edit,
        fullName: values.fullName,
        jobTitle: values.jobTitle,
        phone: contact,
        roles: values.roles,
      };

      api.put("user", updatedBody).then((response: any) => {
        if (updatedBody._id === user._id) {
          const userData = {
            ...response,
            accessToken: user.accessToken,
          };

          dispatch(updateUser(userData));
          storeUser(userData);
        }
        setUsers(
          users.map((item: any) => {
            if (item._id === updatedBody._id) {
              return response;
            }
            return item;
          })
        );
        message.success("User successfully updated");
        dispatch(getAssociatePeople());
      });
    } else {
      dispatch(register(values))
        .then((resp: any) => {
          setUsers([...users, resp]);
          message.success(`User created with email: ${resp.email}`);
        })
        .catch((error: string) => {
          setInvalidEmail(error);
          message.error(error);
          dispatch(getAssociatePeople());
        });
    }
    setInvalidEmail("");
    setShowAddUserModal(false);
  };

  const handleActive = (checked: boolean, id: string) => {
    api
      .patch(`user/update-status/${id}`, { active: checked })
      .then((response: any) => {
        dispatch(getAssociatePeople());
        setUsers(
          users.map((item: any) => {
            if (item._id === id) {
              return response;
            }
            return item;
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEdit = (row: any) => {
    setInvalidEmail("");
    setEdit(row);
    setShowAddUserModal(true);
  };

  const updatedColumns = [
    ...columns,
    {
      title: "Active",
      render: (data: any) => {
        return (
          <Switch
            disabled={data._id === user?._id}
            onChange={(checked: boolean) => handleActive(checked, data._id)}
            checked={data.active}
          />
        );
      },
    },
    {
      title: "Edit",
      render: (data: any) => {
        return (
          <EditOutlined
            onClick={() => handleEdit(data)}
            style={{ color: "#FDB913", cursor: "pointer", fontSize: "20px" }}
          />
        );
      },
    },
  ];

  return (
    <div className="pay-processing-root">
      <CreateAccountC
        row={edit}
        showAddUserModal={showAddUserModal}
        toggleShowAddUserModal={toggleShowAddUserModal}
        handleAddUser={onFinish}
        invalidEmail={invalidEmail}
      />
      <Row className="pp-default-header" align="middle" justify="space-between">
        <Col>
          <h1>Users</h1>
        </Col>
        <Col>
          <Button
            className="pp-add-associates"
            type="primary"
            onClick={handleAddUser}
          >
            Add User
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <hr style={{ border: "1px solid #E8E8E8" }} />
          <div className="pp-default-table">
            {users?.length > 0 && (
              <Table
                scroll={{ x: "900px" }}
                columns={updatedColumns}
                dataSource={users}
                rowKey="_id"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Users;
