import React, { useState } from "react";
import { Table, Row, Col, Button, message, Spin } from "antd";
import { paintOrderColumns, primerOrderColumns } from "./columns";
import "../WorkOrderPaintC.less";
import { InvalidMediaIcon } from "../../../utils/icons";
import Description from "./Description";
import SelectDeliveryDate from "../../../components/data-time-card/SelectDeliveryDate";
import OrderAmountCheck from "./OrderAmountCheck";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import api from "../../../utils/api";
import moment from "moment";
import { RootStateOrAny, useSelector } from "react-redux";
import axios from "axios";

type Props = {};

const PaintOrderCom = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const history = useHistory();
  const [paint, setPaint] = useState<any>([]);
  const [primer, setPrimer] = useState<any>([]);
  const [toggleComponent, setToggleComponent] = useState(true);
  const [loading, setLoading] = useState(false);
  const [orderNo, setOrderNo] = useState(0);

  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const [storeLocations, setStoreLocations] = useState([
    ...adminDefaults?.storeLocations,
  ]);
  // console.log(storeLocations);

  const [closestStore, setClosestStore] = useState<any>({});

  const [jobSiteLocation, setJobSiteLocation] = useState<any>([]);
  const productionAssociateEmail = items[currentProject].projectInfo?.productionAssociate?.email
  useEffect(() => {
    setLoading(true);
    let storeLocationsArray = [...storeLocations];
    getPaintMaterials();
    axios
      .get(
        `https://script.google.com/macros/s/AKfycbzIjxF2qlxsKT-Spz0BClilOuaOI19gMAvSva9nt6yfRQWZJpQGjmwYKtP_RMIGfOJk/exec?address=${items[currentProject].paintOrder?.jobSiteAddress}`
      )
      .then((resp: any) => {
        console.log(resp);
        if (resp?.data?.length > 0) {
          setJobSiteLocation(resp.data);
          let paintOrderAccount =
            items[currentProject].paintOrder?.accountNumber.split(":");
          console.log(paintOrderAccount);
          const vendorSelectedIndex = adminDefaults.vendorDefaults.findIndex(
            (item: any) => item.accountNumber === paintOrderAccount[0]
          );
          // let filteredArray = storeLocationsArray.filter(
          //   (item: any) => item.store === vendorSelected.prefix
          // );
          console.log(storeLocationsArray);
          // console.log(vendorSelected);
          const vendorSelected =
            adminDefaults.vendorDefaults[vendorSelectedIndex];
          // console.log(vendorSelected.prefix[0]);
          let filteredArray = storeLocationsArray.filter((item) =>
            vendorSelected.prefix.includes(item.store)
          );
          // let filteredArray = storeLocationsArray.filter(
          //   (item) => item.store === vendorSelected[0].prefix[0]
          // );

          console.log(filteredArray);
          let updatedArray = filteredArray.map((item: any) => {
            let distanceFromJobSite = getClosestStore(
              resp.data[0],
              resp.data[1],
              item.latitude,
              item.longitude
            );
            let mapLink = `https://www.google.com/maps/dir/?api=1&origin=${items[
              currentProject
            ].paintOrder?.jobSiteAddress
              ?.split(" ")
              .join("+")}&destination=${item.address
                ?.split(" ")
                .join("+")}&travelmode=driving`;
            return {
              ...item,
              mapLink,
              distanceFromJobSite,
            };
          });
          console.log("USE EFFECT", updatedArray);

          if (updatedArray.length > 0) {
            const smallestObj = updatedArray.reduce((min, obj) =>
              obj.distanceFromJobSite < min.distanceFromJobSite ? obj : min
            );
            console.log("USE EFFECT", smallestObj);
            setClosestStore(smallestObj);
          } else {
            setClosestStore({});
          }
          setStoreLocations(updatedArray);
          setLoading(false);
        }
      })
      .catch((err: any) => {
        setLoading(false);
      });
  }, []);

  const getPaintMaterials = () => {
    api.get(`place-order/${id}`).then((resp: any) => {
      updateGallons(resp);
    });
  };

  const updateGallons = (resp: any) => {
    let updatedPaint = [];
    let updatedPrimer = [];
    if (resp.orderNo) {
      setOrderNo(resp.orderNo);
    }
    if (resp?.paintInfo?.length > 0) {
      updatedPaint = items[currentProject].paintOrder?.paintInfo?.map(
        (item: any) => {
          const material = resp.paintInfo.find(
            (product: any) => product.product === item.product
          );
          return material
            ? { ...item, orderedGallons: material.gallons }
            : { ...item };
        }
      );
    } else {
      updatedPaint = items[currentProject].paintOrder?.paintInfo?.map(
        (item: any) => {
          return { ...item, orderedGallons: 0 };
        }
      );
    }
    if (resp?.primerInfo?.length > 0) {
      updatedPrimer = items[currentProject].paintOrder?.primerInfo?.map(
        (item: any) => {
          const material = resp.primerInfo.find(
            (product: any) => product.primer === item.primer
          );
          return material
            ? { ...item, orderedGallons: material.gallons }
            : { ...item };
        }
      );
    } else {
      updatedPrimer = items[currentProject].paintOrder?.primerInfo?.map(
        (item: any) => {
          return { ...item, orderedGallons: 0 };
        }
      );
    }
    setPaint(updatedPaint);
    setPrimer(updatedPrimer);
  };
  const getClosestStore = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) => {
    let radiusOfEarth = 6371000; //In Meters
    let degreeLat = ((lat2 - lat1) * Math.PI) / 180;
    let degreeLon = ((lon2 - lon1) * Math.PI) / 180;
    let a =
      Math.sin(degreeLat / 2) * Math.sin(degreeLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(degreeLon / 2) *
      Math.sin(degreeLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = radiusOfEarth * c;

    return Math.round((d / 1000) * 0.621371 * 100) / 100;
  };

  const newColumn = [
    paintOrderColumns[0],
    {
      title: (
        <>
          <Row>
            <Col>
              <div
                style={{
                  height: 20,
                  width: 20,
                  // marginTop:8,
                  marginRight: 20,
                }}
              />
            </Col>
            <Col>Color</Col>
          </Row>
        </>
      ),
      dataIndex: "color",
      width: "40%",
      render: (data: string) => {
        let response = adminDefaults.colors.find(
          (item: any) => item.name === data
        );
        return (
          <>
            <Row>
              <Col>
                <div
                  style={{
                    background: response?.hexCode,
                    height: 20,
                    width: 20,
                    marginRight: 20,
                  }}
                />
              </Col>
              <Col>
                <p>{data}</p>
              </Col>
            </Row>
          </>
        );
      },
    },
    paintOrderColumns[2],
    paintOrderColumns[3],
  ];

  const handleProductOrder = () => {
    setToggleComponent(false);
  };

  const handleNext = () => {
    history.push(`/wood-order/${id}`);
  };
  const handleToggle = () => {
    setToggleComponent(true);
  };
  const handleUpdateState = (data: any) => {
    updateGallons(data);
  };

  return (
    <>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <div>
          {toggleComponent ? (
            <>
              <div className="wo-paint-root ">
                <h1 className="wop-title wop-header">Paint Order</h1>
                <hr style={{ border: "1px solid #E8E8E8" }} />
                <Row style={{ padding: "30px 15px" }}>
                  <Col span={24}>
                    <Description
                      title="paintOrder"
                      paintOrder={{
                        ...items[currentProject].paintOrder,
                        ...items[currentProject].prePresentationChecklist,
                      }}
                      selectedStore={closestStore}
                    />
                  </Col>
                </Row>
                <div className="wo-paint-table">
                  <div>
                    <Table
                      rowKey="_id"
                      pagination={false}
                      rowClassName={(record, index) =>
                        index % 2 === 0
                          ? "table_row table-row-light"
                          : "table_row table-row-dark"
                      }
                      columns={primerOrderColumns}
                      dataSource={primer?.filter(
                        (item: any) => item.primeGallons
                      )}
                    />
                  </div>
                  <div>
                    <Table
                      rowKey="_id"
                      pagination={false}
                      rowClassName={(record, index) =>
                        index % 2 === 0
                          ? "table_row table-row-light"
                          : "table_row table-row-dark"
                      }
                      columns={newColumn}
                      dataSource={paint?.filter(
                        (item: any) => item.paintGallons
                      )}
                    />
                  </div>
                </div>
              </div>
              <Row justify="center" style={{ marginTop: "50px" }}>
                <Col>
                  <Row
                    gutter={20}
                    style={{ marginBottom: 20 }}
                    justify="center"
                  >
                    <Col>
                      <Button
                        onClick={handleProductOrder}
                        className="initial-product-btn"
                        type="primary"
                        disabled={!online}
                      >
                        Initiate Product Order
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={handleNext}
                        type="primary"
                        className="next-btn-po"
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <OrderAmountCheck
              paintOrder={{
                ...items[currentProject].paintOrder,
                ...items[currentProject].prePresentationChecklist,
              }}
              orderNo={orderNo}
              paintMaterials={paint}
              primerMaterials={primer}
              handleToggle={handleToggle}
              closestStore={closestStore}
              jobSiteLocation={jobSiteLocation}
              handleUpdateState={handleUpdateState}
              productionAssociateEmail={productionAssociateEmail}
            />
          )}
        </div>
      )}
    </>
  );
};

export default PaintOrderCom;
