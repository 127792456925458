const initialState = {
  setup: [],
  surface: [],
  termsAndConditions: [],
};

const proposalDefaultsComRes = (state = initialState, action: any) => {
  switch (action.type) {
    case "PUSH_SETUP_DEFAULTS":
      return {
        ...state,
        setup: [...state.setup, action.payload],
      };
    case "PUSH_SURFACE_DEFAULTS":
      return {
        ...state,
        surface: [...state.surface, action.payload],
      };
    case "PUSH_TERMS_AND_CONDITIONS_DEFAULTS":
      return {
        ...state,
        termsAndConditions: [...state.termsAndConditions, action.payload],
      };

    case "ADD_SETUP_DEFAULTS":
      return {
        ...state,
        setup: action.payload,
      };
    case "REMOVE_SETUP_DEFAULTS":
      return {
        ...state,
        setup: state.setup.filter((item: any) => item._id !== action.payload),
      };
    case "REMOVE_SURFACE_DEFAULTS":
      return {
        ...state,
        surface: state.surface.filter(
          (item: any) => item._id !== action.payload
        ),
      };

    case "REMOVE_TERMS_AND_CONDITIONS_DEFAULTS":
      return {
        ...state,
        termsAndConditions: state.termsAndConditions.filter(
          (item: any) => item._id !== action.payload
        ),
      };
    case "ADD_SURFACE_DEFAULTS":
      return {
        ...state,
        surface: action.payload,
      };

    case "ADD_TERMS_AND_CONDITIONS_DEFAULTS":
      return {
        ...state,
        termsAndConditions: action.payload,
      };

    case "UPDATE_SURFACE_DEFAULTS": {
      const newData = state.surface.map((item: any) => item);
      const index = state.surface.findIndex(
        (item: any) => item._id === action.payload._id
      );

      newData[index].title = action.payload.title;
      newData[index].detail = action.payload.detail;

      return {
        ...state,
        surface: newData,
      };
    }

    case "UPDATE_TERMS_AND_CONDITIONS_DEFAULTS": {
      const newData = state.termsAndConditions.map((item: any) => item);
      const index = state.termsAndConditions.findIndex(
        (item: any) => item._id === action.payload._id
      );

      newData[index].title = action.payload.title;
      newData[index].detail = action.payload.detail;

      return {
        ...state,
        termsAndConditions: newData,
      };
    }

    case "UPDATE_SETUP_DEFAULTS":
      const newData = state.setup.map((item: any) => item);
      const index = state.setup.findIndex(
        (item: any) => item._id === action.payload._id
      );
      newData[index].task = action.payload.task;

      return {
        ...state,
        setup: newData,
      };

    default:
      return state;
  }
};

export default proposalDefaultsComRes;
