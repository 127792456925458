import React from 'react';
import CreateAccountC from '../../containers/login-signup/CreateAccountC';

const CreateAccount: React.FC = () => {
  return (
    <div>
      {/* <CreateAccountC /> */}
    </div>
  );
};

export default CreateAccount;
