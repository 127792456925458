import { Col, Input, Row, Spin, Card, Button, Modal, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { BulletIcon, SearchIcon } from "../../utils/icons";
import "./Media.less";
import { useParams } from "react-router-dom";
import api from "../../utils/api";
import { useSelector, RootStateOrAny } from "react-redux";
import { FilePdfFilled } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";

type Props = {};

const options = {
  density: 100,
  saveFilename: "untitled",
  savePath: "./images",
  format: "png",
  width: 600,
  height: 600,
};

const DocumentCardContractor = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { id } = useParams<{ id: string }>();
  const [documents, setDocuments] = useState<any>([]);
  const [testImage, setTestImage] = useState<any>();
  const [activeDocs, setActiveDOcs] = useState<any>([]);
  const [archivedDocs, setArchivedDOcs] = useState<any>([]);
  const [paintOrdersArr, setPaintOrdersArr] = useState<any>([]);
  const [checkRequestArr, setCheckRequestArr] = useState<any>([]);
  const [uploadedDocsArr, setUploadedDocsArr] = useState<any>([]);
  const [inputSearch, setInputSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const isMobile = useMediaQuery({ query: "(max-width:400px)" });

  useEffect(() => {
    setLoading(true);
    if (online) {
      api
        .get(`upload-files/document/${id}`)
        .then((resp: any) => {
          const dateArr: any[] = [];
          const jobNameArr: any[] = [];
          const uploadedDocs: any[] = [];

          resp.forEach((item: any) => {
            const nameParts = item.name.split(" : ");
            if (
              nameParts.length === 2 &&
              nameParts[1].trim().match(/^\d{4}-\d{2}-\d{2}/)
            ) {
              dateArr.push(item);
            } else {
              jobNameArr.push(item);
            }
          });

          const checkRequests = jobNameArr.filter((item: any) =>
            item.name.startsWith("Check Request")
          );
          const paintOrders = jobNameArr.filter((item: any) =>
            item.name.startsWith("Paint Order")
          );
          const uploadFiles = jobNameArr.filter(
            (item: any) =>
              !item.name.startsWith("Paint Order") &&
              !item.name.startsWith("Check Request") &&
              !item.name.startsWith("Proposal")
          );
          setPaintOrdersArr(paintOrders);
          setCheckRequestArr(checkRequests);
          setUploadedDocsArr(uploadFiles);

          let activeDocuments: any = [];
          let archivedDocuments: any = [];

          dateArr.forEach((item: any) => {
            if (item?.active === true) {
              activeDocuments.push(item);
            }
            if (item?.active === false) {
              archivedDocuments.push(item);
            }
          });

          setActiveDOcs(
            activeDocuments.filter((item: any) => item.addToProject === true)
          );
          setArchivedDOcs(archivedDocuments);

          setDocuments(resp);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [online]);

  const foundActive = activeDocs?.filter((item: any) => {
    if (!inputSearch) {
      return true;
    }
    return item.name.toLowerCase().includes(inputSearch.toLowerCase());
  });

  function blobToUint8Array(blob: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function () {
        const arrayBuffer = this.result;
        if (arrayBuffer instanceof ArrayBuffer) {
          const uint8Array = new Uint8Array(arrayBuffer);
          resolve(uint8Array);
        } else {
          reject(new Error("FileReader did not return an ArrayBuffer."));
        }
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsArrayBuffer(blob);
    });
  }
  function base64ToBlob(base64: any, mimeType: any) {
    const byteCharacters = atob(base64.split(",")[1]);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: mimeType });
  }

  useEffect(() => {
    const allItemNames = [
      ...(foundActive?.map((item: any) => item?.name.split(":")[0]) || []),
      ...(uploadedDocsArr?.map((item: any) => item?.name) || []),
      ...(paintOrdersArr?.map((item: any) => item?.name) || []),
      ...(checkRequestArr?.map((item: any) => item?.name) || []),
    ];

    setSelectedItems(allItemNames);
  }, [isModalOpen]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (itemName: string) => {
    setSelectedItems((prevSelectedItems) => {
      const isSelected = prevSelectedItems.includes(itemName);

      if (isSelected) {
        return prevSelectedItems.filter((item) => item !== itemName);
      } else {
        return [...prevSelectedItems, itemName];
      }
    });
  };

  const handleDownloadButtonClick = () => {
    selectedItems.forEach((itemName) => {
      const item = findItemByName(itemName);
      if (item) {
        const link = document.createElement("a");
        link.href = item?.thumbUrl;
        link.download = item?.name;
        link.click();
      }
    });
  };

  const findItemByName = (itemName: any) => {
    const foundItem =
      foundActive.find((item: any) => item.name.split(":")[0] === itemName) ||
      uploadedDocsArr.find((item: any) => item.name === itemName) ||
      paintOrdersArr.find((item: any) => item.name === itemName) ||
      checkRequestArr.find((item: any) => item.name === itemName);

    return foundItem || null;
  };

  const containerStyle: React.CSSProperties = {
    maxHeight: "300px",
    overflowY: "auto",
    marginTop: "20px",
    marginBottom: "20px",
  };

  const openPdfInNewTab = (pdfUrl: string, itemName: string) => {
    if (/iPhone|iPad|iPod|Macintosh|Mac OS X/i.test(navigator.userAgent)) {
      const a = document.createElement("a");
      a.href = pdfUrl;
      a.target = "_blank";
      a.click();
    } else {
      fetch(pdfUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const objectUrl = URL.createObjectURL(blob);
          const win = window.open(objectUrl, "_blank");
          if (!win) {
            console.error(
              "Unable to open a new tab. Please check your browser settings."
            );
          } else {
            win.document.title = itemName;
          }
        })
        .catch((error) => {
          console.error("Error loading PDF:", error);
        });
    }
  };

  return (
    <div className="media-background">
      {isMobile ? (
        <Row>
          <Col>
            <h1>Document</h1>
          </Col>

          <Col>
            <Input
              className="media-search"
              placeholder="Search Documents"
              prefix={<SearchIcon />}
              value={inputSearch}
              onChange={(e: any) => setInputSearch(e.target.value)}
            />
          </Col>
          <Col>
            <Button
              style={{
                color: "black",
                cursor: "pointer",
                borderRadius: "5px",
                marginTop: "5px",
                height: "40px",
              }}
              type="primary"
              onClick={showModal}
            >
              Download Job Jacket
            </Button>
            <Modal
              title="Job Jacket"
              open={isModalOpen}
              onCancel={handleCancel}
              footer={
                <Button
                  style={{
                    color: "black",
                    cursor: "pointer",
                    borderRadius: "5px",
                    width: "200px",
                  }}
                  type="primary"
                  onClick={handleDownloadButtonClick}
                >
                  Download Job Jacket
                </Button>
              }
            >
              <div style={containerStyle}>
                {foundActive?.map((item: any, index: number) => (
                  <Col key={item?.thumbUrl}>
                    <Checkbox
                      checked={selectedItems.includes(item?.name.split(":")[0])}
                      onChange={() =>
                        handleCheckboxChange(item?.name.split(":")[0])
                      }
                    >
                      <a style={{ color: "black" }}>
                        {item?.name.split(":")[0]}
                      </a>
                    </Checkbox>
                  </Col>
                ))}

                {uploadedDocsArr?.map((item: any, index: number) => (
                  <Col key={item?.thumbUrl}>
                    <Checkbox
                      checked={selectedItems.includes(item?.name)}
                      onChange={() => handleCheckboxChange(item?.name)}
                    >
                      <a style={{ color: "black" }}>{item?.name}</a>
                    </Checkbox>
                  </Col>
                ))}
                {paintOrdersArr?.map((item: any, index: number) => (
                  <Col key={item?.thumbUrl}>
                    <Checkbox
                      checked={selectedItems.includes(item?.name)}
                      onChange={() => handleCheckboxChange(item?.name)}
                    >
                      <a style={{ color: "black" }}>{item?.name}</a>
                    </Checkbox>
                  </Col>
                ))}
                {checkRequestArr?.map((item: any, index: number) => (
                  <Col key={item?.thumbUrl}>
                    <Checkbox
                      checked={selectedItems.includes(item?.name)}
                      onChange={() => handleCheckboxChange(item?.name)}
                    >
                      <a style={{ color: "black" }}>{item?.name}</a>
                    </Checkbox>
                  </Col>
                ))}
              </div>
            </Modal>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={14}>
            <h1>Document</h1>
          </Col>

          <Col style={{ marginRight: "5px" }}>
            <Input
              className="media-search"
              placeholder="Search Documents"
              prefix={<SearchIcon />}
              value={inputSearch}
              onChange={(e: any) => setInputSearch(e.target.value)}
            />
          </Col>
          <Col>
            <Button
              style={{
                color: "black",
                cursor: "pointer",
                borderRadius: "5px",
                marginLeft: "10px",
                height: "40px",
              }}
              type="primary"
              onClick={showModal}
            >
              Download Job Jacket
            </Button>
            <Modal
              title="Job Jacket"
              open={isModalOpen}
              onCancel={handleCancel}
              footer={
                <Button
                  style={{
                    color: "black",
                    cursor: "pointer",
                    borderRadius: "5px",
                    width: "200px",
                  }}
                  type="primary"
                  onClick={handleDownloadButtonClick}
                >
                  Download Job Jacket
                </Button>
              }
            >
              <div style={containerStyle}>
                {foundActive?.map((item: any, index: number) => (
                  <Col key={item?.thumbUrl}>
                    <Checkbox
                      checked={selectedItems.includes(item?.name.split(":")[0])}
                      onChange={() =>
                        handleCheckboxChange(item?.name.split(":")[0])
                      }
                    >
                      <a style={{ color: "black" }}>
                        {item?.name.split(":")[0]}
                      </a>
                    </Checkbox>
                  </Col>
                ))}

                {uploadedDocsArr?.map((item: any, index: number) => (
                  <Col key={item?.thumbUrl}>
                    <Checkbox
                      checked={selectedItems.includes(item?.name)}
                      onChange={() => handleCheckboxChange(item?.name)}
                    >
                      <a style={{ color: "black" }}>{item?.name}</a>
                    </Checkbox>
                  </Col>
                ))}
                {paintOrdersArr?.map((item: any, index: number) => (
                  <Col key={item?.thumbUrl}>
                    <Checkbox
                      checked={selectedItems.includes(item?.name)}
                      onChange={() => handleCheckboxChange(item?.name)}
                    >
                      <a style={{ color: "black" }}>{item?.name}</a>
                    </Checkbox>
                  </Col>
                ))}
                {checkRequestArr?.map((item: any, index: number) => (
                  <Col key={item?.thumbUrl}>
                    <Checkbox
                      checked={selectedItems.includes(item?.name)}
                      onChange={() => handleCheckboxChange(item?.name)}
                    >
                      <a style={{ color: "black" }}>{item?.name}</a>
                    </Checkbox>
                  </Col>
                ))}
              </div>
            </Modal>
          </Col>
        </Row>
      )}

      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <Col>
            <h2>Job Jacket:</h2>

            <Row>
              {foundActive?.map((item: any, index: number) => (
                <Col key={item?.thumbUrl}>
                  <a
                    href={item?.thumbUrl}
                    title={item?.name}
                    style={{ textDecoration: "none", cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      openPdfInNewTab(item?.thumbUrl, item?.name);
                    }}
                  >
                    <Card
                      style={{
                        minHeight: "400px",
                        width: 300,
                        marginRight: "20px",
                        border: "1px solid #D3D3D3",
                      }}
                      cover={
                        <img
                          style={{
                            width: "270px",
                            marginLeft: "5%",
                            marginTop: "10px",
                            border: "0.5px solid #D3D3D3",
                          }}
                          alt="pfd"
                          src={`data:image/png;base64,${item?.thumbnailBuf}`}
                        />
                      }
                    >
                      <Row
                        style={{
                          borderTop: "1px solid #D3D3D3",
                          minHeight: "70px",
                        }}
                      >
                        <Col
                          span={4}
                          style={{ marginTop: "5px", marginLeft: "20px" }}
                        >
                          <FilePdfFilled
                            style={{ color: "#AA0000", fontSize: "40px" }}
                          />
                        </Col>
                        <Col span={16} style={{ marginTop: "15px" }}>
                          <React.Fragment key={item?.thumbUrl}>
                            {item?.name.split(":")[0]}
                          </React.Fragment>
                        </Col>
                      </Row>
                    </Card>
                  </a>
                </Col>
              ))}
            </Row>
          </Col>
          <h2>Uploaded Docs:</h2>
          <Row gutter={0}>
            {uploadedDocsArr?.map((item: any, index: number) => (
              <Col key={item?.thumbUrl}>
                <a
                  href={item?.thumbUrl}
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    openPdfInNewTab(item?.thumbUrl, item.name);
                  }}
                >
                  <Card
                    style={{
                      width: 300,
                      marginRight: "20px",
                      borderRadius: "20px",
                      border: "1px solid #D3D3D3",
                    }}
                    cover={
                      <img
                        style={{
                          width: "270px",
                          marginLeft: "5%",
                          marginTop: "10px",
                          border: "0.5px solid #D3D3D3",
                        }}
                        alt="pdf"
                        src={`data:image/png;base64,${item?.thumbnailBuf}`}
                      />
                    }
                  >
                    <Row
                      style={{
                        borderTop: "1px solid #D3D3D3",
                        minHeight: "70px",
                      }}
                    >
                      <Col
                        span={4}
                        style={{ marginTop: "5px", marginLeft: "20px" }}
                      >
                        <FilePdfFilled
                          style={{ color: "#AA0000", fontSize: "40px" }}
                        />
                      </Col>
                      <Col span={16} style={{ marginTop: "15px" }}>
                        <React.Fragment key={item?.thumbUrl}>
                          <a
                            download={item?.name}
                            href={item?.thumbUrl}
                            style={{
                              textDecoration: "none",
                              color: "#000000",
                            }}
                          >
                            {item?.name}
                          </a>
                        </React.Fragment>
                      </Col>
                    </Row>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>{" "}
          <h2>Paint Order:</h2>
          <Row gutter={16}>
            {paintOrdersArr?.map((item: any, index: number) => (
              <Col key={item?.thumbUrl}>
                <a
                  href={item?.thumbUrl}
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    openPdfInNewTab(item?.thumbUrl, item.name);
                  }}
                >
                  <Card
                    style={{
                      width: 300,
                      marginRight: "20px",
                      borderRadius: "20px",
                      border: "1px solid #D3D3D3",
                    }}
                    cover={
                      <img
                        style={{
                          width: "270px",
                          marginLeft: "5%",
                          marginTop: "10px",
                          border: "0.5px solid #D3D3D3",
                        }}
                        alt="pdf"
                        src={`data:image/png;base64,${item?.thumbnailBuf}`}
                      />
                    }
                  >
                    <Row
                      style={{
                        borderTop: "1px solid #D3D3D3",
                        minHeight: "70px",
                      }}
                    >
                      <Col
                        span={4}
                        style={{ marginTop: "5px", marginLeft: "20px" }}
                      >
                        <FilePdfFilled
                          style={{ color: "#AA0000", fontSize: "40px" }}
                        />
                      </Col>
                      <Col span={16} style={{ marginTop: "15px" }}>
                        <React.Fragment key={item?.thumbUrl}>
                          <a
                            download={item?.name}
                            href={item?.thumbUrl}
                            style={{
                              textDecoration: "none",
                              color: "#000000",
                            }}
                          >
                            {item?.name}
                          </a>
                        </React.Fragment>
                      </Col>
                    </Row>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>{" "}
          <h2>Check Request:</h2>
          <Row gutter={16}>
            {checkRequestArr?.map((item: any, index: number) => (
              <Col key={item?.thumbUrl}>
                <a
                  href={item?.thumbUrl}
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    openPdfInNewTab(item?.thumbUrl, item.name);
                  }}
                >
                  <Card
                    style={{
                      width: 300,
                      marginRight: "20px",
                      borderRadius: "20px",
                      border: "1px solid #D3D3D3",
                    }}
                    cover={
                      <img
                        style={{
                          width: "270px",
                          marginLeft: "5%",
                          marginTop: "10px",
                          border: "0.5px solid #D3D3D3",
                        }}
                        alt="pdf"
                        src={`data:image/png;base64,${item?.thumbnailBuf}`}
                      />
                    }
                  >
                    <Row
                      style={{
                        borderTop: "1px solid #D3D3D3",
                        minHeight: "70px",
                      }}
                    >
                      <Col
                        span={4}
                        style={{ marginTop: "5px", marginLeft: "20px" }}
                      >
                        <FilePdfFilled
                          style={{ color: "#AA0000", fontSize: "40px" }}
                        />
                      </Col>
                      <Col span={16} style={{ marginTop: "15px" }}>
                        <React.Fragment key={item?.thumbUrl}>
                          <a
                            style={{ color: "black" }}
                            download={item?.name}
                            href={item?.thumbUrl}
                          >
                            {item?.name}
                          </a>
                        </React.Fragment>
                      </Col>
                    </Row>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>{" "}
        </>
      )}
    </div>
  );
};

export default DocumentCardContractor;
