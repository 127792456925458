import { Form, Input, InputNumber, Select } from "antd";

const { Option } = Select;
interface MarginEditableProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: any;
  index: number;
  form: typeof Form;
  children: React.ReactNode;
  data: any;
  setDataRates: any;
}

const MarginEditable: React.FC<MarginEditableProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  form,
  record,
  index,
  children,
  data,
  setDataRates,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? <InputNumber type="number" /> : <Input />;

  return (
    <>
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    </>
  );
};

export default MarginEditable;
