import { UpCircleOutlined, DownCircleOutlined } from "@ant-design/icons";
type Props = {
  onExpand?: any;
  record?: any;
  expanded?: any;
};
const CustomExpandIcon = (props: Props) => {
  return (
    <a
      onClick={(e) => {
        props.record ? props.onExpand(props.record, e) : props.onExpand(e);
      }}
    >
      {props?.expanded ? (
        <UpCircleOutlined style={{ fontSize: 28 }} />
      ) : (
        <DownCircleOutlined style={{ fontSize: 28 }} />
      )}
    </a>
  );
};

export default CustomExpandIcon;
