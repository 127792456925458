import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Popover,
  Row,
} from "antd";

import { useState } from "react";
import { MoreActionIcon } from "../../../utils/icons";

type Props = {
  data: any;
  index: number;
  category: string;
  handleEdit?: any;
  handleDelete?: any;
  handleClone?: any;
};

const ActionDropDown = (props: Props) => {
  const [popOverVisible, setPopOverVisible] = useState<boolean[]>([]);
  const [surfaceName, setSurfaceName] = useState<any>([""]);

  const handleVisibleChange = (_: boolean, index: number) => {
    const prev = [...popOverVisible];
    prev[index] = true;
    setPopOverVisible(prev);
  };

  const handleSurfaceName = (
    e: React.FormEvent<HTMLInputElement>,
    index: number
  ) => {
    const prev = [...surfaceName];
    prev[index] = e.currentTarget.value;
    setSurfaceName(prev);
  };

  const handleAddClone = (data: any, index: number) => {
    if (surfaceName[index]) {
      props.handleClone(data, index, surfaceName);
      const prev = [...popOverVisible];
      prev[index] = false;
      setPopOverVisible(prev);
    } else {
      message.warn("Please enter title!");
    }
  };

  const handlePopOverCancel = (index: number) => {
    const prev = [...popOverVisible];
    prev[index] = false;
    setPopOverVisible(prev);
  };
  const edit = () => {
    props.handleEdit(props.data);
  };

  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item
            key={1}
            style={{ fontWeight: "bold" }}
            onClick={() => props.handleDelete(props.data._id)}
          >
            Delete
          </Menu.Item>
          <Popover
            visible={popOverVisible[props.index]}
            onVisibleChange={(visible: boolean) =>
              handleVisibleChange(visible, props.index)
            }
            content={
              <div>
                <h3 style={{ fontWeight: "bold" }}>Enter Title:</h3>
                <Input
                  style={{ marginBottom: "8px" }}
                  value={surfaceName[props.index]}
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    handleSurfaceName(e, props.index)
                  }
                />
                <Row justify="space-between" gutter={3}>
                  <Col>
                    <Button
                      type="primary"
                      onClick={() => handleAddClone(props.data, props.index)}
                    >
                      Add
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => handlePopOverCancel(props.index)}
                      type="primary"
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </div>
            }
            trigger="click"
          >
            <Menu.Item key={2} style={{ fontWeight: "bold" }}>
              Clone
            </Menu.Item>
          </Popover>
          <Menu.Item key={3} onClick={edit} style={{ fontWeight: "bold" }}>
            Edit
          </Menu.Item>
        </Menu>
      }
      trigger={["click"]}
    >
      <button className="action-button">
        <MoreActionIcon />
      </button>
    </Dropdown>
  );
};

export default ActionDropDown;
