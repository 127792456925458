import moment from "moment";

export const columns = [
  {
    title: "Draw Number",
    dataIndex: "drawNumber",
  },
  {
    title: "Date",
    dataIndex: "date",
    render: (data: any) => {
      return <div>{moment(data).format("MMMM DD, YYYY")}</div>;
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (data: any) => {
      return <div>${data}</div>;
    },
  },
  {
    title: "Retainer",
    dataIndex: "retainer",
    render: (data: any) => {
      return <div>${data}</div>;
    },
  },
  {
    title: "Deposit Amount",
    dataIndex: "depositAmount",
    render: (data: any) => {
      return <div>${data}</div>;
    },
  },
  {
    title: "Balance Due",
    dataIndex: "balanceDue",
    render: (data: any) => {
      return <div>${data}</div>;
    },
  },
];
