import { Col, Modal, Radio, Row, Tooltip } from "antd";
import "./ContractDefaultCard.less";
import { useState } from "react";
import {
  MoneyIcon,
  CalendarIcon,
  ProjectIcon,
  ScopeIcon,
  CorrectionIcon,
  EquipmentIcon,
} from "../../utils/icons";
import { renderToStaticMarkup } from "react-dom/server";

type Props = {
  openModal: boolean;
  setModalState: (value: boolean) => void;
  handleSelectIconOk: (svgIconString: any) => void;
};

function SelectSvgIconModal({
  openModal,
  setModalState,
  handleSelectIconOk,
}: Props) {
  const [modalSelectedIcon, setModalSelectedIcon] = useState(null);

  const convertReactComponentSvgToString = (
    SVG: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  ) => {
    const svgString = encodeURIComponent(renderToStaticMarkup(<SVG />));
    return `data:image/svg+xml,${svgString}`;
  };

  const iconsConstants = [
    {
      SvgIcon: ProjectIcon,
      iconName: "Project Icon",
      svgString: convertReactComponentSvgToString(ProjectIcon),
    },
    {
      SvgIcon: MoneyIcon,
      iconName: "Money Icon",
      svgString: convertReactComponentSvgToString(MoneyIcon),
    },
    {
      SvgIcon: ScopeIcon,
      iconName: "Scope Icon",
      svgString: convertReactComponentSvgToString(ScopeIcon),
    },
    {
      SvgIcon: EquipmentIcon,
      iconName: "Equipment Icon",
      svgString: convertReactComponentSvgToString(EquipmentIcon),
    },
    {
      SvgIcon: CorrectionIcon,
      iconName: "Correction Icon",
      svgString: convertReactComponentSvgToString(CorrectionIcon),
    },
    {
      SvgIcon: CalendarIcon,
      iconName: "Calendar Icon",
      svgString: convertReactComponentSvgToString(CalendarIcon),
    },
  ];

  return (
    <div>
      <Modal
        open={openModal}
        title={<div style={{ fontWeight: "bold" }}>Select Icon</div>}
        onCancel={() => setModalState(false)}
        onOk={() => handleSelectIconOk(modalSelectedIcon)}
      >
        <div>
          <Radio.Group
            style={{ width: "105%" }}
            onChange={(e) => setModalSelectedIcon(e.target.value)}
          >
            <Row align={"middle"} justify={"space-between"}>
              {iconsConstants.map(({ SvgIcon, iconName, svgString }, index) => (
                <Col span={4} key={index}>
                  <Tooltip title={iconName}>
                    <Radio.Button
                      value={svgString}
                      className="custom-radio-button"
                    >
                      <SvgIcon />
                    </Radio.Button>
                  </Tooltip>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </div>
      </Modal>
    </div>
  );
}

export default SelectSvgIconModal;
