import React from 'react';
import { Row, Col } from 'antd';
import SettingC from '../../containers/settings/SettingC';

const Setting: React.FC = () => {
  return (
    <div>
      <Row>
        <Col span={24}>
          <SettingC />
        </Col>
      </Row>
    </div>
  );
};

export default Setting;
