import { Menu, Steps } from "antd";
import { Col, Row } from "antd";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";
import RoleNavigationSteps from "../../components/role-steps/RoleNavigationSteps";
import useActiveProject from "../../utils/useActiveProject";
import "./ManagerSteps.less";
import { useRef } from "react";
import { Overlay } from "antd/lib/popconfirm/PurePanel";
import { useMediaQuery } from "react-responsive";

const { Step } = Steps;
type Props = {
  current: number;
};

const steps = [
  {
    title: "Profile",
  },
  {
    title: "On-site checklist",
  },
  {
    title: "Labor rates",
  },
  {
    title: "Options",
  },
  {
    title: "PJCC",
  },
  {
    title: "Estimate checklist",
  },

  {
    title: "Proposal setup",
  },
  {
    title: "Proposal",
  },
];

const ManagerSteps = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:1200px)" });
  const history = useHistory();
  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const navigationData = {
    projectId: items[currentProject]?.projectId,
    current: 0,
  };
  const handleStep = (select: number) => {
    switch (select) {
      case 0:
        history.push(
          `/manager-preview-profile/${items[currentProject]?.projectId}`
        );
        break;
      case 1:
        history.push(
          `/manager-on-site-checklists/${items[currentProject]?.projectId}`
        );
        break;
      case 2:
        history.push(
          `/manager-labor-rates/${items[currentProject]?.projectId}`
        );
        break;
      case 3:
        history.push(
          `/manager-options-setup/${items[currentProject]?.projectId}`
        );
        break;
      case 4:
        history.push(`/manager-pjcc/${items[currentProject]?.projectId}`);
        break;
      case 5:
        history.push(
          `/manager-estimate-checklist/${items[currentProject]?.projectId}`
        );
        break;
      case 6:
        history.push(
          `/manager-proposal-setup/${items[currentProject]?.projectId}`
        );
        break;
      case 7:
        history.push(`/manager-proposal/${items[currentProject]?.projectId}`);
        break;
    }
  };

  const menuItems: any = (
    <Menu>
      {steps.map((item: any, index: number) => {
        return (
          <Menu.Item onClick={() => handleStep(index)}>{item.title}</Menu.Item>
        );
      })}
    </Menu>
  );
  const handlePrev = () => {
    const prev = props.current - 1;
    if (prev >= 0) {
      handleStep(prev);
    }
  };
  const handleNext = () => {
    const next = props.current + 1;
    if (next < steps.length) {
      handleStep(next);
    }
  };

  return (
    <>
      <div className="main--steps--holder">
        <RoleNavigationSteps data={navigationData} />
      </div>

      {!isTabletOrMobile ? (
        <div className="main--tabs--holder">
          <Steps
            type="navigation"
            current={props.current}
            className="site-navigation-steps"
          >
            {steps.map((item, index: number) => (
              <Step
                className="step"
                key={item.title}
                title={item.title}
                onClick={() => handleStep(index)}
              />
            ))}
          </Steps>
        </div>
      ) : (
        <div className="navigation-text-container">
          <Row align={"middle"}>
            <Col span={6} offset={4}>
              <p onClick={handlePrev}>
                <LeftOutlined /> Prev
              </p>
            </Col>
            <Col span={6} offset={0}>
              <Dropdown
                overlay={menuItems}
                trigger={["click"]}
                placement="bottom"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    {steps[props.current]?.title} <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </Col>
            <Col span={6} offset={2}>
              <p onClick={handleNext}>
                Next <RightOutlined />
              </p>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ManagerSteps;
