import { API_BASE } from "../../constant";
import axios from "axios";
import { getAuthUser } from "../../utils/authToken";
import api from "../../utils/api";

const fetchingAssociatePeopleSuccess = (payload: any) => ({
  type: "FETCHING_ASSOCIATE_PEOPLE_SUCCESS",
  payload,
});

export const setSelectedSalesAssociate = (payload: any) => ({
  type: "SET_SELECTED_SALES_ASSOCIATE",
  payload,
});
export const setSelectedEstimator = (payload: any) => ({
  type: "SET_SELECTED_ESTIMATOR",
  payload,
});

export const setSelectedProductionAssociate = (payload: any) => ({
  type: "SET_SELECTED_PRODUCTION_ASSOCIATE",
  payload,
});

export const setSelectedAssociates = (payload: any) => ({
  type: "SET_SELECTED_ASSOCIATES",
  payload,
});

export const getAssociatePeople = () => (dispatch: any) => {
  api
    .get("user/users-association")
    .then((response: any) => {
      dispatch(fetchingAssociatePeopleSuccess(response));
    })
    .catch((err) => {
      console.log(err);
    });
};
