import {
  Row,
  Col,
  Table,
  Spin,
  Dropdown,
  Button,
  message,
  Menu,
  Input,
} from "antd";
import { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import api from "../../utils/api";
import CreateVendor from "../create-vendor/CreateVendor";
import "../pay-processing-defaults/PayProcessing.less";
import { useDispatch } from "react-redux";
import { MoreActionIcon } from "../../utils/icons";
import { CSVLink } from "react-csv";
import DefaultBulkEntry from "../../components/pop-ups/default-modal/MaterialDefaultBulkEntry";

const VendorsDefault = () => {
  const dispatch = useDispatch<any>();
  const [vendors, setVendors] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState<any>({});
  const [showAddVendorModal, setShowAddVendorModal] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const [vendorSearched, setVendorSearched] = useState("");

  useEffect(() => {
    setLoading(true);
    api
      .get("defaults/vendor")
      .then((response: any) => {
        setLoading(false);
        const sortedVendors = response.sort((a: any, b: any) =>
          a.vendorName.localeCompare(b.vendorName)
        );
        setVendors(sortedVendors);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const handleAddVendor = () => {
    setEdit({});
    setShowAddVendorModal(true);
  };

  const toggleShowAddVendorModal = (collapse: boolean) => {
    !showAddVendorModal
      ? setShowAddVendorModal(true)
      : setShowAddVendorModal(false);
  };

  const onFinish = (values: any) => {
    if (Object.keys(edit).length > 0) {
      api.put(`defaults/vendor/${edit._id}`, values).then((resp: any) => {
        let newData = [...vendors];
        let rowIndex = newData.findIndex((item: any) => item._id === edit._id);
        newData[rowIndex] = resp;
        setVendors(newData);
      });
    } else {
      api
        .post("defaults/vendor", values)
        .then((resp: any) => {
          let newData = [...vendors];
          newData.push(resp);
          setVendors(newData);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    setShowAddVendorModal(false);
  };

  const handleDelete = (id: string) => {
    api
      .delete(`defaults/vendor/${id}`)
      .then((resp: any) => {
        let newData = [...vendors];
        let updatedBody = newData.filter((item: any) => item._id !== resp._id);
        setVendors(updatedBody);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const found = vendors?.filter((item: any) => {
    if (!vendorSearched) {
      return true;
    }
    return item.vendorName.toLowerCase().includes(vendorSearched.toLowerCase());
  });

  const handleEdit = (row: any) => {
    setEdit(row);
    setShowAddVendorModal(true);
  };

  const handleBulkBtn = () => {
    !showBulkModal ? setShowBulkModal(true) : setShowBulkModal(false);
  };

  const toggleBulkModal = () => {
    !showBulkModal ? setShowBulkModal(true) : setShowBulkModal(false);
  };

  const handleAddCsv = (info: any) => {
    setButtonDisable(true);
    if (info.file.status === "done") {
      setButtonDisable(false);
      message.success("Successfully Uploaded");
      const resp = info.file.response.data;
      setVendors(resp);
      toggleBulkModal();
    } else if (info.file.status === "error") {
      setButtonDisable(false);
      message.error("Uploading Failed!");
    }
  };

  const columns = [
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      sorter: (a: any, b: any) => a.vendorName.localeCompare(b.vendorName),
    },
    {
      title: "Rep Name",
      dataIndex: "repName",
    },
    {
      title: "Rep Contact",
      dataIndex: "repContact",
    },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Prefixes",
      dataIndex: "prefix",
      render: (data: any) => {
        let arrayOfString = data.join(", ");

        return <p>{arrayOfString}</p>;
      },
    },
    {
      title: "Action",
      render: (data: any) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  className="project-menu-items"
                  key={1}
                  onClick={() => {
                    handleDelete(data._id);
                  }}
                >
                  <span className="nav-text">
                    <h1>Delete</h1>
                  </span>
                </Menu.Item>
                <Menu.Item
                  className="project-menu-items"
                  key={2}
                  onClick={() => {
                    handleEdit(data);
                  }}
                >
                  <span className="nav-text">
                    <h1>Edit</h1>
                  </span>
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <Button className="action-button">
              <MoreActionIcon />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const vendorHeader = [
    {
      label: "vendorName",
      key: "vendorName",
    },
    {
      label: "repName",
      key: "repName",
    },
    {
      label: "repContact",
      key: "repContact",
    },
    {
      label: "accountNumber",
      key: "accountNumber",
    },
    {
      label: "category",
      key: "category",
    },
    {
      label: "_id",
      key: "_id",
    },
  ];

  return (
    <div className="pay-processing-root">
      <DefaultBulkEntry
        buttonDisable={buttonDisable}
        showBulkModal={showBulkModal}
        toggleBulkModal={toggleBulkModal}
        url="defaults/vendor/upload"
        handleAddCsv={handleAddCsv}
      />
      <CreateVendor
        row={edit}
        showAddVendorModal={showAddVendorModal}
        toggleShowAddVendorModal={toggleShowAddVendorModal}
        handleAddVendor={onFinish}
      />
      <Row className="admin-ec-header" justify="space-between">
        <Col>
          <h1>Vendors</h1>
        </Col>
        <Row gutter={12}>
          <Col>
            <Input
              className="search-manufacture-input"
              prefix={
                <SearchOutlined className="contractor-data-search-icon" />
              }
              placeholder="Search Vendor"
              onChange={(e) => {
                setVendorSearched(e.target.value);
              }}
            />
          </Col>
          <div className="contractor-data-vertical-line " />
          <Col>
            <Button
              className="pp-add-associates"
              type="primary"
              onClick={handleAddVendor}
            >
              Add Vendor
            </Button>
          </Col>
          <Col>
            <Button
              className="admin-ec-add-btn"
              type="primary"
              onClick={handleBulkBtn}
            >
              Bulk Entry
            </Button>
          </Col>
        </Row>
      </Row>

      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <hr style={{ border: "1px solid #E8E8E8", marginBottom: 30 }} />
          <Button
            className="default-bulk-btn"
            style={{ margin: "0px 20px 10px" }}
            type="primary"
          >
            <CSVLink
              data={vendors}
              filename={"vendor-defaults"}
              headers={vendorHeader}
              enclosingCharacter={""}
            >
              Download CSV
            </CSVLink>
          </Button>
          <div className="pp-default-table">
            {vendors?.length > 0 && (
              <Table
                scroll={{ x: "900px" }}
                columns={columns}
                dataSource={found}
                rowKey="_id"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default VendorsDefault;
