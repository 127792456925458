const deepEqualForClone = (obj1: any, obj2: any): boolean => {
  // If both are direct values
  console.log("OBJECT 1", obj1, "OBJECT 2", obj2);
  if (obj1 === obj2) {
    console.log("equal");
    return true;
  }

  // If either of them isn't an object or is null
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  // Get the keys of both objects excluding the "_id"
  const keys1 = Object.keys(obj1).filter((key) => key !== "_id");
  const keys2 = Object.keys(obj2).filter((key) => key !== "_id");

  // If number of keys is different between the two objects
  if (keys1.length !== keys2.length) {
    console.log("keys length Not equal");
    return false;
  }

  // Check if every key-value pair in obj1 matches that in obj2
  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqualForClone(obj1[key], obj2[key])) {
      console.log("Check if every key-value pair in obj1 matches that in obj2");
      return false;
    }
  }

  return true;
};

export const deepEqualCheckForClone = (
  editedObject: any,
  oldObjectArray: any
) => {
  console.log(editedObject, oldObjectArray);
  for (let oldObject of oldObjectArray) {
    const updatedOldObject = {
      ...oldObject,
    };
    if (deepEqualForClone(editedObject, updatedOldObject)) {
      return {
        rate: updatedOldObject,
        result: true,
      };
    }
  }
  return false;
};

const deepEqual = (obj1: any, obj2: any, depth: number = 0): boolean => {
  // If both are direct values
  console.log("OBJECT 1", obj1, "OBJECT 2", obj2);
  if (obj1 === obj2) {
    console.log("equal");
    return true;
  }

  // If either of them isn't an object or is null
  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1).filter(
    (key) => !["_id", "modified"].includes(key)
  );
  const keys2 = Object.keys(obj2).filter(
    (key) => !["_id", "modified"].includes(key)
  );

  // If number of keys is different between the two objects
  if (keys1.length !== keys2.length) {
    console.log("keys length Not equal");
    return false;
  }

  // Check if every key-value pair in obj1 matches that in obj2
  for (let key of keys1) {
    if (depth === 1 && key === "primerMaterial" && obj2[key]?._id) {
      if (obj1[key] !== obj2[key]._id) {
        return false;
      }
      continue;
    }

    if (
      depth === 1 &&
      key === "defaultRate" &&
      obj1[key]?._id &&
      obj2[key]?._id
    ) {
      if (obj1[key]._id !== obj2[key]._id) {
        return false;
      }
      continue;
    }
    if (!(key === "defaultRate" || key === "primerMaterial")) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key], depth + 1)) {
        console.log(
          "Check if every key-value pair in obj1 matches that in obj2"
        );
        return false;
      }
    }
  }

  return true;
};

export const deepEqualCheck = (editedObject: any, oldObjectArray: any) => {
  console.log(editedObject, oldObjectArray);
  for (let oldObject of oldObjectArray) {
    const updatedOldObject = {
      ...oldObject,
    };
    if (deepEqual(editedObject, updatedOldObject)) {
      return {
        rate: updatedOldObject,
        result: true,
      };
      // return true;
    }
  }
  return false;
};
