import { combineReducers } from "redux";
import offlineData from "./project/reducer";
import auth from "./user/reducer";
import notes from "./notes/reducer";
import associatePeople from "./associatePeople/reducer";
import options from "./options/reducer";
import discount from "./discounts/reducer";
import projectPjcc from "./project-pjcc/reducer";
import proposalDefaults from "./proposal-defaults/reducer";
import materialDefaults from "./material-defaults/reducer";
import contractorData from "./contractor-data/reducer";
import closestStoreData from "./closestStoreData/reducer";

import proposalDefaultsComRes from "./proposal-defaults-com-res/reducer";
import payProcessingDefaults from "./pay-proccesing/reducer";
export default combineReducers({
  offlineData,
  auth,
  notes,
  associatePeople,
  options,
  projectPjcc,
  discount,
  proposalDefaults,
  materialDefaults,
  contractorData,
  payProcessingDefaults,
  proposalDefaultsComRes,
  closestStoreData,
});

// export const rootReducer = combineReducers({
//     projectReducer: projects,
//   });

//   export type RootState = ReturnType<typeof rootReducer>
