import React, { useEffect, useState } from "react";
import { Card, Row, Col, Table, Input, Popconfirm, Button } from "antd";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import {
  removeSingleEntityOfProjectOverRideGrossMargin,
  updateProjectGrossMargin,
  updateSingleEntityOfProjectGrossMargin,
} from "../../redux/project/action";
import _ from "lodash";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { calculateSums } from "../../utils/helpers";
const XlsxPopulate = require("xlsx-populate");

type Props = {
  pjccData?: any;
  setPjccData?: any;
};

const PjccListing = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const [tableData, setTableData] = useState<any>([]);
  const [optionalData, setOptionalData] = useState<any>([]);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [resetIndex, setResetIndex] = useState<number | null>(null);
  const [allTempValues, setAllTempValues] = useState<number[]>([]);
  const [optionGallons, setOptionGallons] = useState<any>([]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newTempValue = parseFloat(e.target.value);
    const updatedTempValues = [...allTempValues];
    updatedTempValues[index] = newTempValue;
    setAllTempValues(updatedTempValues);
  };

  useEffect(() => {
    const optionalPjcc = items[currentProject]?.options.filter(
      (el: any) => el.optionInfo.included === "optional"
    );
    if (optionalPjcc.length) {
      setOptionalData(calculateSums(optionalPjcc));
    } else {
      setOptionalData(optionalPjcc);
    }

    let totalPrimerGallons = 0;
    let totalPaintGallons = 0;

    optionalPjcc.forEach((item: any) => {
      item.rates.paintRates.forEach((rate: any) => {
        totalPrimerGallons += Math.ceil(rate.primerGallons);
        totalPaintGallons += Math.ceil(rate.paintGallons);
      });
    });
    setOptionGallons(totalPrimerGallons + totalPaintGallons);
  }, []);

  useEffect(() => {
    let newData = [...props?.pjccData];
    newData = newData.map((item: any) => {
      if (
        item.name === "Paint Hours" ||
        item.name === "Carpentry Hours" ||
        item.name === "Total Hours"
      ) {
        return {
          ...item,
          cost: item.cost,
          totalAmount: item.totalAmount,
        };
      } else if (item.name === "Project Margin") {
        return {
          ...item,
        };
      } else {
        return {
          ...item,
          cost: item.cost,
          totalAmount: item.totalAmount,
        };
      }
    });
    // newData[16].totalAmount = (1 - newData[15].cost / newData[0].amount) * 100;
    const cost = parseFloat(newData[15]?.cost);
    const amount =
      cost && newData[0]?.amount
        ? (1 - cost / newData[0]?.totalAmount) * 100
        : 0;
    if (newData[22]?.name === "Gross Margin %") {
      newData[22] = {
        project: newData[16]?.project,
        name: "Gross Margin %",
        amount: amount.toFixed(2),
        totalAmount: amount.toFixed(2),
        // option: newData[16]?.option,
      };
    } else {
      newData.push({
        project: newData[16]?.project,
        name: "Gross Margin %",
        amount: amount.toFixed(2),
        totalAmount: amount.toFixed(2),
        option: newData[16]?.option,
      });
    }

    setAllTempValues(
      (props?.pjccData || []).map((item: any) => item.grossMargin)
    );
    setTableData(newData);
  }, [props.pjccData]);

  const handleEditedData = (EditedtableData: any) => {
    console.log(EditedtableData);
    allTempValues.map((entity: any, index: any) => {
      EditedtableData[index].grossMargin = entity;
    });

    const updatedGm = EditedtableData.filter((item: any) => {
      if (
        item.index === 1 ||
        item.index === 2 ||
        item.index === 3 ||
        item.index === 5 ||
        item.index === 6 ||
        item.index === 7 ||
        item.index === 11 ||
        item.index === 12
      ) {
        return item;
      }
    }).map((item: any) => {
      return {
        name: item.name,
        margin: +item.grossMargin,
      };
    });
    let project = _.cloneDeep(items[currentProject]);
    project.projectInfo.overRideGrossMargin = updatedGm;
    let newBody = {
      overRideGrossMargin: updatedGm.filter((item: any) => {
        if (item.margin === 0) {
          item.margin = null;
        }
        return item;
      }),
    };
    console.log(newBody, id, project);
    // dispatch(updateProjectGrossMargin(newBody, id, project, adminDefaults));
  };

  const updatedHandleOkForSingleEntity = (index: number) => {
    if (allTempValues !== null) {
      const item = {
        name: tableData[index].name,
        margin: allTempValues[index],
      };

      let project = _.cloneDeep(items[currentProject]);

      let marginUpdatedInProjectInfo = false;
      project.projectInfo.overRideGrossMargin = project.projectInfo.overRideGrossMargin.map(
        (grossMarginEntity: { name: string; margin: number }) => {
          if (grossMarginEntity.name === item.name) {
            marginUpdatedInProjectInfo = true;
            return {
              ...grossMarginEntity,
              margin: item.margin,
            };
          }
          return grossMarginEntity;
        }
      );

      if (!marginUpdatedInProjectInfo) {
        project.projectInfo.overRideGrossMargin.push(item);
      }

      let updatedOptions = project.options.map((option: any) => {
        let marginUpdatedInOptions = false;
        let updatedOptionInfo = {
          ...option.optionInfo,
          overRideGrossMargin: option.optionInfo.overRideGrossMargin.map(
            (grossMarginEntity: { name: string; margin: number }) => {
              if (grossMarginEntity.name === item.name) {
                marginUpdatedInOptions = true;
                return {
                  ...grossMarginEntity,
                  margin: item.margin,
                };
              }
              return grossMarginEntity;
            }
          ),
        };

        if (!marginUpdatedInOptions) {
          updatedOptionInfo.overRideGrossMargin.push(item);
        }

        return {
          ...option,
          optionInfo: updatedOptionInfo,
        };
      });

      let updatedProject = {
        ...project,
        options: updatedOptions,
      };

      dispatch(updateSingleEntityOfProjectGrossMargin(item, id, updatedProject, adminDefaults));
    }

    setEditingIndex(null);
  };

  const handleResetForSingleEntity = (index: number) => {
    if (allTempValues !== null) {
      const item = {
        name: tableData[index].name,
      };
      let project = _.cloneDeep(items[currentProject]);

      project.projectInfo.overRideGrossMargin = project.projectInfo.overRideGrossMargin.filter(
        (grossMarginEntity: { name: string; margin: number }) =>
          grossMarginEntity.name !== item.name
      );

      let updatedOptions = project.options.map((option: any) => {
        let updatedOptionInfo = {
          ...option.optionInfo,
          overRideGrossMargin: option.optionInfo.overRideGrossMargin.filter(
            (grossMarginEntity: { name: string; margin: number }) =>
              grossMarginEntity.name !== item.name
          ),
        };

        return {
          ...option,
          optionInfo: updatedOptionInfo,
        };
      });

      let updatedProject = {
        ...project,
        options: updatedOptions,
      };

      dispatch(
        removeSingleEntityOfProjectOverRideGrossMargin(
          item,
          id,
          updatedProject,
          adminDefaults
        )
      );
    }
    setResetIndex(null);
  };

  const columns = [
    {
      title: "Items",
      dataIndex: "name",
      key: "items",
    },
    {
      title: "GM",
      dataIndex: "grossMargin",
      key: "gm",
      render: (data: any, _: any, index: number) => {
        const isEditing = editingIndex === index;
        return (
          <>
            {index == 0 ||
              index == 4 ||
              index == 8 ||
              index == 9 ||
              index == 10 ||
              index == 13 ||
              index == 14 ||
              index == 15 ||
              index == 16 ||
              index == 17 ||
              index == 18 ? null : (
              <>
                {index === editingIndex ? (
                  <>
                    <Input
                      style={{ width: 100 }}
                      type="number"
                      min={0}
                      onChange={(e) => handleInputChange(e, index)}
                      value={
                        allTempValues[index] !== undefined
                          ? allTempValues[index]
                          : data
                      }
                      addonAfter="%"
                    />
                    <Popconfirm
                      title="Are you Sure you want to override this margin as it will also override the margin in all the options?"
                      onConfirm={() => updatedHandleOkForSingleEntity(index)}
                      // onConfirm={() => handleOk()}
                      onCancel={() => setEditingIndex(null)}
                    >
                      <a>
                        <CheckOutlined
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            color: "#FDB913",
                            cursor: "pointer",
                            marginTop: 10,
                          }}
                        />
                      </a>
                    </Popconfirm>
                    <CloseOutlined
                      onClick={() => setEditingIndex(null)}
                      style={{
                        color: "#FDB913",
                        cursor: "pointer",
                        marginTop: 10,
                      }}
                    />
                  </>
                ) : index === resetIndex ? (
                  <>
                    <Input
                      style={{ width: 100 }}
                      type="number"
                      min={0}
                      value={data}
                      disabled={true}
                      addonAfter="%"
                    />
                    <Popconfirm
                      title="Are you Sure you want to reset this margin to tired margin?"
                      onConfirm={() => handleResetForSingleEntity(index)}
                      onCancel={() => setResetIndex(null)}
                    >
                      <a>
                        <CheckOutlined
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            color: "#FDB913",
                            cursor: "pointer",
                            marginTop: 10,
                          }}
                        />
                      </a>
                    </Popconfirm>
                    <CloseOutlined
                      onClick={() => setResetIndex(null)}
                      style={{
                        color: "#FDB913",
                        cursor: "pointer",
                        marginTop: 10,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Input
                      style={{ width: 100 }}
                      type="number"
                      min={0}
                      value={data}
                      disabled={true}
                      addonAfter="%"
                    />
                    <EditOutlined
                      onClick={() => setEditingIndex(index)}
                      style={{
                        marginLeft: 10,
                        color: "#FDB913",
                        cursor: "pointer",
                      }}
                    />
                    <UndoOutlined
                      onClick={() => setResetIndex(index)}
                      style={{
                        marginLeft: 10,
                        color: "#FDB913",
                        cursor: "pointer",
                      }}
                    />
                  </>
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (data: any, _: any, index: number) => {
        return (
          <>
            <div>
              {index != 0 && index != 17 && index != 16 && index != 18 && (
                <h1>
                  {index !== 8 && index !== 9 && index !== 10 && "$"}
                  {data}
                  {/* {parseFloat(data)?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} */}
                </h1>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (data: any, _: any, index: number) => {
        return (
          <>
            {index === 18 ? (
              <div>
                <h1>
                  {data}
                  {/* {data?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} */}
                  %
                </h1>
              </div>
            ) : (
              <div>
                {index != 15 && (
                  <h1>
                    {index !== 8 && index !== 9 && index !== 10 && "$"}
                    {data}
                    {/* {parseFloat(data)?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} */}
                  </h1>
                )}
              </div>
            )}
          </>
        );
      },
    },
  ];

  const generateExcelFile = async () => {
    const findItemByName = (arr: any[], name: string) =>
      arr.find((item: any) => item.name === name);
    const safeDivide = (num1: number, num2: number) =>
      num2 !== 0 ? num1 / num2 : null;
    let grossProfit = safeDivide(
      findItemByName(optionalData, "Gross Margin")?.totalAmount || 0,
      findItemByName(optionalData, "Total Hours")?.totalAmount || 0
    );
    const averageWageCost =
      findItemByName(tableData, "Average Wage")?.cost || null;
    const averageWageAmount =
      findItemByName(tableData, "Average Wage")?.totalAmount || null;
    const totalHours =
      findItemByName(tableData, "Total Hours")?.totalAmount || 0;
    const totalMaterialCost =
      tableData
        .filter((item: any) =>
          [
            "Total Material Cost",
            "Equipment Cost",
            "Miscellaneous Cost",
            "Travel Cost",
          ].includes(item.name)
        )
        .reduce((acc: any, item: any) => acc + item.cost, 0) || null;
    const totalLaborCost =
      findItemByName(tableData, "Total Labor")?.cost || null;
    const totalCOGS = findItemByName(tableData, "Total Cost")?.cost || null;
    const grossMarginPercent =
      findItemByName(tableData, "Gross Margin %")?.amount || null;
    const grossProfitPerHour =
      findItemByName(tableData, "Gross Profit $ p/hr")?.totalAmount || null;
    const grossMarginAmount =
      findItemByName(tableData, "Gross Margin")?.amount || 0;
    const estimatedPrice = findItemByName(tableData, "Price to Client")?.totalAmount || null;

    const totalGallons =
      (items[currentProject].paintOrder?.primerInfo?.reduce(
        (acc: any, item: any) => acc + item.primeGallons,
        0
      ) || 0) +
      (items[currentProject].paintOrder?.paintInfo?.reduce(
        (acc: any, item: any) => acc + item.paintGallons,
        0
      ) || 0) || 0;

    const optionHours =
      findItemByName(optionalData, "Total Hours")?.totalAmount || 0;
    const optionMaterialCost =
      optionalData
        .filter((item: any) =>
          [
            "Total Material Cost",
            "Equipment Cost",
            "Miscellaneous Cost",
            "Travel Cost",
          ].includes(item.name)
        )
        .reduce((acc: any, item: any) => acc + item.totalAmount, 0) || null;
    const optionLaborCost =
      findItemByName(optionalData, "Total Labor")?.totalAmount || null;
    // const optionMarginPercent = (1 - parseFloat(findItemByName(optionalData, "Total Cost").cost) / findItemByName(optionalData, "Estimated Price").totalAmount) * 100;
    const optionMarginPercent = (() => {
      const totalCostItem = findItemByName(optionalData, "Total Cost");
      const estimatedPriceItem = findItemByName(
        optionalData,
        "Estimated Price"
      );

      if (
        totalCostItem &&
        estimatedPriceItem &&
        totalCostItem.cost &&
        estimatedPriceItem.totalAmount
      ) {
        const totalCost = parseFloat(totalCostItem.cost);
        const estimatedPrice = parseFloat(estimatedPriceItem.totalAmount);

        if (
          !isNaN(totalCost) &&
          !isNaN(estimatedPrice) &&
          estimatedPrice !== 0
        ) {
          return (1 - totalCost / estimatedPrice) * 100;
        }
      }
      return null;
    })();
    const optionProfitPerHour = grossProfit?.toFixed(2) || null;
    // const optionMarginAmount = findItemByName(optionalData, "Gross Margin")?.amount.toFixed(2) || null;
    const optionMarginAmount =
      (
        findItemByName(optionalData, "Estimated Price")?.amount -
        findItemByName(optionalData, "Total Cost")?.cost
      ).toFixed(2) || null;
    const optionPrice =
      findItemByName(optionalData, "Estimated Price")?.totalAmount || null;

    // Create the data for the Excel sheet
    const data = [
      ["Labor Rate", averageWageCost],
      ["Charge Rate", averageWageAmount],
      ["Hours", totalHours],
      ["Gallons", totalGallons],
      ["Total Material Cost", totalMaterialCost],
      ["Total Labor Cost", totalLaborCost],
      ["Total COGS", totalCOGS],
      ["GM%", grossMarginPercent],
      ["GM$/Hr", grossProfitPerHour],
      ["GM$", grossMarginAmount],
      ["Total Price", estimatedPrice],
      ["Option Hours", optionHours],
      ["Option Gallons", optionGallons],
      ["Option Material Cost", optionMaterialCost],
      ["Option Labor Cost", optionLaborCost],
      ["Option GM%", optionMarginPercent],
      ["Option GM$/Hr", optionProfitPerHour],
      ["Option GM$", optionMarginAmount],
      ["Option Price", optionPrice],
    ];

    // Generate worksheet and workbook
    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();

    // Apply formats
    const accountingFormat =
      '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)';
    const percentageFormat = "0.00%";
    const nullAccountingFormat =
      '_("$-"* #,##0.00_);_("$-"* (#,##0.00);_("$-"* "-"??_);_(@_)';
    const nullPercentageFormat = '0.00%;0.00%;"-$"';

    const accountingCells = [
      "B1",
      "B2",
      "B5",
      "B6",
      "B7",
      "B9",
      "B10",
      "B11",
      "B14",
      "B15",
      "B17",
      "B18",
      "B19",
    ];
    const percentageCells = ["B8", "B16"];

    accountingCells.forEach((cell) => {
      if (!worksheet[cell]) worksheet[cell] = { v: null };
      if (worksheet[cell].v === null) {
        worksheet[cell].v = "-";
        worksheet[cell].z = nullAccountingFormat;
      } else {
        worksheet[cell].z = accountingFormat;
      }
    });

    percentageCells.forEach((cell) => {
      if (!worksheet[cell]) worksheet[cell] = { v: null };
      if (worksheet[cell].v === null) {
        worksheet[cell].v = "-";
        worksheet[cell].z = nullPercentageFormat;
      } else {
        worksheet[cell].v = worksheet[cell].v / 100;
        worksheet[cell].z = percentageFormat;
      }
    });

    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Estimator App (DO NOT DELETE)"
    );

    // Convert workbook to Blob
    const workbookBlob = workbook2blob(workbook);

    // Style the workbook and download
    const url = await addStyle(workbookBlob);
    downloadFile(url, "EstimatorApp.xlsx");
  };

  const workbook2blob = (workbook: XLSX.WorkBook) => {
    const wopts: XLSX.WritingOptions = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };
    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    return blob;
  };

  const s2ab = (s: string) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i);
    return buf;
  };

  const addStyle = async (workbookBlob: Blob) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(
      (workbook: {
        sheet: (arg0: number) => any;
        outputAsync: () => Promise<any>;
      }) => {
        const sheet = workbook.sheet(0);
        const length = sheet._rows.length - 1;

        sheet.column("A").width(16);
        sheet.column("B").width(12);

        sheet.range("A1:A" + length).style({
          fontColor: "#231F20",
          fontFamily: "Calibri",
          fontSize: 11,
        });

        sheet.range("B1:B" + length).style({
          fontColor: "#231F20",
          fontFamily: "Calibri",
          fontSize: 11,
        });

        return workbook
          .outputAsync()
          .then((workbookBlob) => URL.createObjectURL(workbookBlob));
      }
    );
  };

  const downloadFile = (url: string, filename: string) => {
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", url);
    downloadAnchorNode.setAttribute("download", filename);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  return (
    <>
      <Card className="root-container">
        <div
          className="title-container"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: "20px",
          }}
        >
          <h1 className="form-title">PJCC</h1>
          <Button
            type="primary"
            className="add-surface"
            style={{
              fontWeight: "bold",
              fontSize: "15px",
              lineHeight: "18px",
              letterSpacing: "0.055em",
              borderRadius: "5px",
              height: "40px",
              color: "#1f1f1f",
            }}
            onClick={generateExcelFile}
          >
            Generate Excel
          </Button>
        </div>
        <hr style={{ border: "1px solid #E8E8E8", borderBottom: "none" }} />

        <div style={{ margin: "0px 20px" }}>
          <Row gutter={10}>
            <Col span={6}>
              <div className="pjcc--box">
                {tableData[17]?.totalAmount?.toFixed(2).length > 6 ? (
                  <>
                    <h3>${(tableData[17]?.totalAmount / 1000)?.toFixed(2)}k</h3>
                  </>
                ) : (
                  <>
                    <h3>${tableData[17]?.totalAmount?.toFixed(2)}</h3>
                  </>
                )}

                <p>Gross Profit $p/hr</p>
              </div>
            </Col>
            <Col span={6}>
              <div className="pjcc--box">
                {tableData[18]?.cost?.toFixed(2)?.length > 6 ? (
                  <>
                    <h3>${(tableData[18]?.cost / 1000).toFixed(2)}k</h3>
                  </>
                ) : (
                  <>
                    <h3>${tableData[18]?.cost?.toFixed(2)}</h3>
                  </>
                )}
                <p>Project Cost</p>
              </div>
            </Col>
            <Col span={6}>
              <div className="pjcc--box">
                {tableData[20]?.totalAmount?.toFixed(2).length > 6 ? (
                  <>
                    <h3>${(tableData[20]?.totalAmount / 1000).toFixed(2)}k</h3>
                  </>
                ) : (
                  <>
                    <h3>${tableData[20]?.totalAmount?.toFixed(2)}</h3>
                  </>
                )}
                <p>Price To Client</p>
              </div>
            </Col>
            <Col span={6}>
              <div className="pjcc--box">
                {tableData[19]?.totalAmount?.toFixed(2).length > 6 ? (
                  <>
                    <h3>${(tableData[19]?.totalAmount / 1000).toFixed(2)}k</h3>
                  </>
                ) : (
                  <>
                    <h3>{tableData[19]?.totalAmount?.toFixed(2)}%</h3>
                  </>
                )}
                <p>Project Margin</p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="pjcc--listing">
          <div
            className="site-layout-background  table-title"
            style={{ padding: "20px 0px 40px 0px" }}
          >
            <Table
              scroll={{ x: 900 }}
              rowKey="index"
              rowClassName={(record, index) =>
                index % 2 === 0
                  ? "table_row table-row-light"
                  : "table_row table-row-dark"
              }
              columns={columns}
              dataSource={tableData.filter(
                (item: any) =>
                  item.index != 20 &&
                  item.index != 19 &&
                  item.index != 18 &&
                  item.index != 17 &&
                  item.index != 21
              )}
              pagination={false}
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default PjccListing;
