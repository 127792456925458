import React, { useEffect } from "react";
import { Layout, Button, Row, Col } from "antd";
import { useHistory, useParams } from "react-router-dom";
import PjccListing from "../../containers/pjcc/PjccListing";
import Notes from "../../containers/notes/Notes";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  fetchPjcc,
  setAppliedDiscount,
  setPjcc,
} from "../../redux/project-pjcc/action";
import { fetchDiscounts } from "../../redux/discounts/action";
import ProductionSteps from "./ProductionSteps";
import ProductionPjcc from "../../containers/pjcc/ProductionPjcc";
import useSyncProjectData from "../../utils/useSyncProjectData";
import { Spin } from "antd";

const { Content } = Layout;

const PJCC: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
    dispatch(
      setPjcc(items[currentProject]?.pjcc ? items[currentProject]?.pjcc : [])
    );
  }, []);
  const [isLoading] = useSyncProjectData(id);
  return (
    <>
      {isLoading === true ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <Content>
          <ProductionSteps current={0} />
          <div className="steps-content">
            <ProductionPjcc />
          </div>
        </Content>
      )}
    </>
  );
};

export default PJCC;
