import React from 'react';
import OnSiteDefault from '../../containers/onsite-defaults/OnSiteDefault';

type Props = {};

const OnSiteDefaults = (props: Props) => {
  return (
    <div>
      <OnSiteDefault />
    </div>
  );
};

export default OnSiteDefaults;
