import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Users from "../../containers/Users/Users";

const PayProcessingDefaults = () => {
  //   const dispatch = useDispatch();
  //   useEffect(() => {
  //     dispatch(fetchAssociates());
  //     dispatch(fetchPayProcessing());
  //   }, []);
  return (
    <div>
      <Users />
    </div>
  );
};

export default PayProcessingDefaults;
