import React from "react";
import { Row, Col, Divider, Table } from "antd";
import "./Proposal.less";

const AdditionalOptions = ({ tableData, priceWithOptionalOptions }: any) => {
  const columns = [
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p>Item & Description</p>
        </div>
      ),
      dataIndex: "title",
      width: "45%",
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p>Price</p>
        </div>
      ),
      // dataIndex: "totalPrice",
      width: "15%",
      render: (data: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: " #1F1F1F",
              }}
            >
              $
              {Math.round(data.totalPrice)?.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
        );
      },
    },
    {
      title: "ACCEPTANCE OF ADDITIONAL CHARGES",
      children: [
        {
          title: (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p>CertaPro Initials</p>
            </div>
          ),
          width: "20%",
        },
        {
          title: (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p>Client Initials</p>
            </div>
          ),
          width: "20%",
        },
      ],
    },
  ];
  const finalPriceColumns = [
    {
      title: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h3>FINAL NEW PROJECT PRICE WITH OPTIONS</h3>
        </div>
      ),
      width: "60%",
      render: (data: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: " #1F1F1F",
              }}
            >
              $
              {data.finalPrice?.toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </p>
          </div>
        );
      },
    },
    {
      title: "ACCEPTANCE OF ADDITIONAL CHARGES",
      children: [
        {
          title: (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p>CertaPro Initials</p>
            </div>
          ),
          width: "20%",
        },
        {
          title: (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p>Client Initials</p>
            </div>
          ),
          width: "20%",
        },
      ],
    },
  ];
  return (
    <div
      className="terms-container"
      style={{
        marginTop: 30,
        marginBottom: 50,
        marginLeft: 30,
        marginRight: 30,
      }}
    >
      <div className="tasks-wrapper" style={{ marginTop: "50px" }}>
        <h2 className="content-title">ADDITIONAL OPTIONS</h2>
        <Divider
          type="horizontal"
          style={{
            marginTop: 0,
            marginRight: 50,
            borderColor: "#FDB913",
            borderWidth: 5,
          }}
        ></Divider>
        <div className="option-table">
          <h3 style={{ color: "red" }}>
            NOTE: NOT INCLUDED IN THE ABOVE PRICE
          </h3>
          <Table
            rowKey="_id"
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
          <Table
            rowKey="_id"
            columns={finalPriceColumns}
            dataSource={[
              {
                finalPrice:
                  tableData.reduce(
                    (acc: any, item: any) => acc + Math.round(item.totalPrice),
                    0
                  ) + priceWithOptionalOptions,
              },
            ]}
            pagination={false}
            style={{ marginTop: 25 }}
          />
        </div>
      </div>
    </div>
  );
};

export default AdditionalOptions;
