import { Modal, Button, Row, Col, Input, Form } from "antd";
import "./CommercialPopUps.less";
type Props = {
  title: string;
  showDefaultsInteriorModal: boolean;
  toggleDefaultIntModal: (item: any) => void;
};

const DefaultsInteriorModal = (props: Props) => {
  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };

  const onFinish = (values: any) => {
    console.log(values);
  };
  return (
    <Modal
      className="job-type-comm-modal"
      centered={true}
      visible={props.showDefaultsInteriorModal}
      onOk={props.toggleDefaultIntModal}
      onCancel={props.toggleDefaultIntModal}
      footer={false}
      title={<h1 style={{ marginTop: 10 }}>Default ({props.title})</h1>}
    >
      <Form
        onFinish={onFinish}
        scrollToFirstError
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          hasFeedback
          name="addTitle"
          label={<h2>Add Title</h2>}
          rules={[
            {
              required: true,
              message: "Please enter the title!",
            },
            checkEmpty,
          ]}
        >
          <Input
            placeholder="Type here"
            className="default-material-form-input"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="addDetail"
          label={<h2>Add Detail</h2>}
          rules={[
            {
              required: true,
              message: "Please enter the Details!",
            },
            checkEmpty,
          ]}
        >
          <Input
            placeholder="Type here"
            className="default-material-form-input"
          />
        </Form.Item>

        <Row style={{ marginTop: 70 }} gutter={10} justify="end">
          <Col>
            <Button
              className="job-type-add-comm-cancel"
              onClick={props.toggleDefaultIntModal}
            >
              Cancel
            </Button>
          </Col>

          <Col>
            <Button
              className="job-type-add-comm-btn"
              htmlType="submit"
              type="primary"
            >
              Add
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default DefaultsInteriorModal;
