import React from "react";
import { Row, Col } from "antd";
import logo from "../../assets/images/Pro-Driven_low.png";
import "./ContractSetup.less";
import { CertaproIcon } from "../../utils/icons";

type Props = {
  companyInfo?: any;
};

const ContractHeader = (props: Props) => {
  return (
    <div className="ch-root">
      <Row justify="space-between">
        <Col span={14}>
          {/* <CertaproIcon />
          <p>CertaPro Painters® of Indianapolis</p> */}
          <img
            style={{
              width: 200,

              // marginLeft: -20,
              marginTop: 15,
            }}
            src={props?.companyInfo?.companyLogo}
            alt="logo"
          />
          <h1>CONTRACT</h1>
        </Col>
        <Col span={10}
          style={{
            marginTop: 30,
          }}
        >
          <h2>{props?.companyInfo?.companyName}</h2>
          <h2>
            Address:{" "}
            {`${props?.companyInfo?.companyStreetAddressOne}, ${props?.companyInfo?.companyStreetAddressTwo
              ? props?.companyInfo?.companyStreetAddressTwo + ", "
              : ""
              }`}
          </h2>
          <h2>{`${props?.companyInfo?.companyCity}, ${props?.companyInfo?.companyState}, ${props?.companyInfo?.companyZip}`}</h2>
          <h2>Telephone: {props?.companyInfo?.companyTelephone}</h2>
          <h2>Website: {props?.companyInfo?.companyWebsite}</h2>
        </Col>
      </Row>
    </div>
  );
};

export default ContractHeader;
