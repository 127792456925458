import React, { useState } from "react";
import { Row, Col, Input, Button, Modal, Popconfirm } from "antd";
import { SaveOutlined, EditOutlined } from "@ant-design/icons";

type Props = {
  bonusPayment: number;
  handleBonusPayment: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSaveOutlinedClick: () => void;
  showModal: boolean;
  handleModalCancel: () => void;
  handleOk: (notes: string) => void;
  crewName: string;
  projectName: string;
  contractedPrice: number;
  handleSave: () => void;
  jobNumber: any;
  handleNotesChange: (notes: string) => void;
  checkRequestRetainerPercent: number;
  handleRetainerPercentageChange: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleRetainerPercentageSave: () => void;
  handleResetOnRetainerPercentage: () => void;
};

const CheckRequestDescription: React.FC<Props> = ({
  bonusPayment,
  handleBonusPayment,
  handleSaveOutlinedClick,
  showModal,
  handleModalCancel,
  handleOk,
  crewName,
  projectName,
  contractedPrice,
  handleSave,
  jobNumber,
  handleNotesChange,
  checkRequestRetainerPercent,
  handleRetainerPercentageChange,
  handleRetainerPercentageSave,
  handleResetOnRetainerPercentage,
}) => {
  const [isModalConfirmed, setIsModalConfirmed] = useState(false);
  const [notes, setNotes] = useState("");

  const handleModalConfirm = () => {
    setIsModalConfirmed(true);
    handleOk(notes);
  };

  const handleKeyDown = (e: any) => {
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="desc-card-root">
      <Row className="cr-desc-card">
        <Col span={4}>
          <h1>Project</h1>
        </Col>
        <Col span={8} style={{ wordBreak: "break-word" }}>
          <p>{projectName}</p>
        </Col>
        <Col span={4}>
          <h1>Contracted Price</h1>
        </Col>
        <Col span={8}>
          <p>
            $
            {contractedPrice?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
        </Col>
        <Col span={4}>
          <h1>Job Number</h1>
        </Col>
        <Col span={8}>
          <p>{jobNumber}</p>
        </Col>
        <Col span={4}>
          <h1>Misc Payment</h1>
        </Col>
        <Col span={4}>
          <Row align="middle" gutter={10}>
            <Col span={13}>
              <Input
                onClick={handleSave}
                value={isModalConfirmed ? 0 : bonusPayment}
                onChange={handleBonusPayment}
                type="number"
                prefix="$"
              />
            </Col>
            <Col>
              <SaveOutlined
                onClick={handleSaveOutlinedClick}
                style={{
                  color: "#FDB913",
                  cursor: "pointer",
                  fontSize: "20px",
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={1}></Col>
        <Col span={4}>
          <h1>Crew</h1>
        </Col>
        <Col span={8}>
          <p>{crewName}</p>
        </Col>
        <Col span={4}>
          <h1>Retainer Percentage</h1>
        </Col>
        <Col span={4}>
          <Row align="middle" gutter={10}>
            <Col span={13}>
              <Input
                value={checkRequestRetainerPercent}
                onChange={handleRetainerPercentageChange}
                type="number"
                prefix="%"
              />
            </Col>
            <Col>
              <Popconfirm
                title="Are you Sure you want to save this percentage?"
                onConfirm={handleRetainerPercentageSave}
                onCancel={handleResetOnRetainerPercentage}
              >
                <a>
                  <SaveOutlined
                    // onClick={handleRetainerPercentageSave}
                    style={{
                      color: "#FDB913",
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                  />
                </a>
              </Popconfirm>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        centered={true}
        title="Misc Payment"
        visible={showModal}
        onCancel={handleModalCancel}
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleModalConfirm}
            style={{ color: "black" }}
          >
            Confirm
          </Button>,
        ]}
      >
        <div>
          <p>Are you sure you want to add a Miscellaneous payment?</p>
          <Input
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Add a Description"
          />
        </div>
      </Modal>
    </div>
  );
};

export default CheckRequestDescription;
