import React from "react";
import { Button, Card, Col, Row, Select } from "antd";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import "./AssociatePeopleForm.less";
import {
  setSelectedEstimator,
  setSelectedProductionAssociate,
  setSelectedSalesAssociate,
} from "../../redux/associatePeople/action";
const { Option } = Select;

type Props = {
  handleUpdate?: any;
  handleCancel?: any;
};

const AssociatePeopleForm = (props: Props) => {
  const dispatch = useDispatch();

  const associatePeople = useSelector(
    (state: RootStateOrAny) => state.associatePeople
  );

  const {
    selectedSalesAssociate,
    selectedEstimators,
    selectedProductionAssociate,
    associates,
  } = associatePeople;

  const OnSalesChange = (e: string, item: any) => {
    const found = associates.find((element: any) => element._id === item.key);
    dispatch(setSelectedSalesAssociate(found));
  };

  const OnEstChange = (e: string, item: any) => {
    const found = associates.find((element: any) => element._id === item.key);
    dispatch(setSelectedEstimator(found));
  };

  const OnProdChange = (e: string, item: any) => {
    const found = associates.find((element: any) => element._id === item.key);
    dispatch(setSelectedProductionAssociate(found));
  };

  return (
    <div className="cardFormContainer">
      <Card
        title="Associate People"
        bordered={false}
        className="cardBox"
        headStyle={{
          fontSize: "22px",
          fontWeight: "bold",
          paddingTop: "20px",
        }}
      >
        <div className="insideContainer">
          <p className="titleContent">Sales Associate</p>

          <Select
            size="large"
            className="select-field"
            placeholder="Please select"
            value={selectedSalesAssociate?.fullName}
            onSelect={OnSalesChange}
          >
            {associates.map((item: any) => (
              <Option value={item.fullName} key={item._id}>
                {item.fullName}
              </Option>
            ))}
          </Select>
        </div>
        <div className="insideContainer">
          <p className="titleContent">Estimator</p>

          <Select
            size="large"
            className="select-field"
            placeholder="Please select"
            value={selectedEstimators?.fullName}
            onSelect={OnEstChange}
          >
            {associates.map((item: any) => (
              <Option value={item.fullName} key={item._id}>
                {item.fullName}
              </Option>
            ))}
          </Select>
        </div>
        <div className="insideContainer">
          <p className="titleContent">Production Associate</p>

          <Select
            size="large"
            className="select-field"
            placeholder="Please select"
            value={selectedProductionAssociate?.fullName}
            onSelect={OnProdChange}
          >
            {associates.map((item: any) => (
              <Option value={item.fullName} key={item._id}>
                {item.fullName}
              </Option>
            ))}
          </Select>
        </div>
        {props.handleUpdate && (
          <Row gutter={10}>
            <Col>
              <Button
                style={{
                  border: "none",
                  color: "#FDB913",
                  width: "94px",
                  height: "40px",
                }}
                onClick={() => props.handleCancel()}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  border: "none",
                  color: "#1F1F1F",
                  width: "94px",
                  height: "40px",
                }}
                type="primary"
                onClick={() => props.handleUpdate()}
              >
                Save
              </Button>
            </Col>
          </Row>
        )}
      </Card>
    </div>
  );
};

export default AssociatePeopleForm;
