import { useEffect } from "react";
import SetupDefaults from "./setup/SetupDefaults";
import "./ProposalDefaultCom.less";
import SurfacePreparationsDefault from "./surface-preparations/SurfacePreparationsDefault";
import TermsAndConditionsDefault from "./terms-and-conditions/TermsAndConditionsDefault";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  fetchSetupDefaults,
  fetchSurfaceDefaults,
  fetchTermsAndConditionsDefaults,
} from "../../../redux/proposal-defaults-com-res/action";

const ProposalDefaultCom = () => {
  const dispatch = useDispatch();
  const { setup, surface, termsAndConditions } = useSelector(
    (state: RootStateOrAny) => state.proposalDefaultsComRes
  );
  useEffect(() => {
    dispatch(fetchSetupDefaults("commercial"));
    dispatch(fetchSurfaceDefaults("commercial"));
    dispatch(fetchTermsAndConditionsDefaults("Commercial"));
  }, []);

  return (
    <div>
      <h1 className="pd-commercial-title"></h1>
      <SetupDefaults
        tasks={setup?.filter((item: any) => item.jobType === "interior")}
        type="commercial"
        jobType="interior"
      />
      <SetupDefaults
        tasks={setup?.filter((item: any) => item.jobType === "exterior")}
        type="commercial"
        jobType="exterior"
      />
      <SurfacePreparationsDefault
        surfaces={surface?.filter((item: any) => item.jobType === "interior")}
        type="commercial"
        jobType="interior"
      />
      <SurfacePreparationsDefault
        surfaces={surface?.filter((item: any) => item.jobType === "exterior")}
        type="commercial"
        jobType="exterior"
      />
      <TermsAndConditionsDefault terms={termsAndConditions} type="Commercial" />
    </div>
  );
};

export default ProposalDefaultCom;
