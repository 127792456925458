import { Input } from "antd";
import { useEffect, useState } from "react";

type Props = {
  text: string;
  onInputChange: any;
};

const OptionDetailsInput = (props: Props) => {
  const [input, setInput] = useState<string>("");
  useEffect(() => {
    setInput(props?.text);
  }, [props.text]);
  const submitNotes = () => {
    props.onInputChange(input);
  };
  return (
    <Input
      style={{
        border: "1px solid #E8E8E8",
        borderRadius: "5px",
      }}
      onChange={(e) => setInput(e.target.value)}
      value={input}
      onBlur={submitNotes}
    />
  );
};

export default OptionDetailsInput;
