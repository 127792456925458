import React, { PropsWithChildren, useState, useEffect } from 'react';
import { data } from '../../containers/available-jobs/data';
import { Layout, Table, Input, Tag, Menu, Avatar, Dropdown } from 'antd';
import moment from 'moment';
import { MoreActionIcon } from '../../utils/icons';
import { SearchOutlined } from '@ant-design/icons';
import EmptyScreen from '../../containers/available-jobs/EmptyScreen';
import PaymentTabC from '../../containers/payment-tab/PaymentTabC';

const { Content } = Layout;

const PaymentTab: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [userSearched, setUserSearched] = useState('');
  const [showEmpty, setShowEmpty] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = 'manual';
    if (!data) {
      setShowEmpty(true);
    }
  }, []);

  const HandleMenuClick = (e: any) => {};

  return (
    <div>
      <div className="content--container">
        <Content style={{ margin: '24px 0px 0' }}>
          <PaymentTabC />
        </Content>
      </div>
    </div>
  );
};

export default PaymentTab;
