import { Row, Col, Spin, Input, message } from "antd";
import { useEffect, useState } from "react";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import "./ContractPaymentDefault.less";
import api from "../../utils/api";

interface ContractPayment {
  _id?: string;
  retainerPercentage: number;
  _v?: number;
}

const ContractPaymentDefault = () => {
  const [loading, setLoading] = useState(true);
  const [contractPayment, setContractPayment] = useState<ContractPayment>({
    retainerPercentage: 0,
  });
  const [editEnabled, setEditEnabled] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    api
      .get("defaults/contract-payment")
      .then((response: any) => {
        if (response.length > 0) {
          setContractPayment(response[0]);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error("Failed to fetch data");
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleKeyDown = (e: any) => {
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleInputChange = (e: any) => {
    const newValue = parseFloat(e.target.value);
    setContractPayment((prevState) => {
      const updatedState = { ...prevState, retainerPercentage: newValue };
      setIsChanged(
        prevState.retainerPercentage !== updatedState.retainerPercentage
      );
      return updatedState;
    });
  };

  const handleSave = () => {
    if (!isChanged) {
      setEditEnabled(false);
      return;
    }
    const { retainerPercentage } = contractPayment;
    if (
      retainerPercentage === null ||
      retainerPercentage === undefined ||
      isNaN(retainerPercentage) ||
      typeof retainerPercentage !== "number" ||
      retainerPercentage < 0 ||
      retainerPercentage > 100
    ) {
      message.error("Retainer Percentage must be a number between 0 and 100");
      return;
    }
    const action = contractPayment._id ? "put" : "post";
    const baseUrl = "defaults/contract-payment";
    const url = contractPayment._id
      ? `${baseUrl}/${contractPayment._id}`
      : baseUrl;

    api[action](url, contractPayment)
      .then((response: any) => {
        console.log(response);
        setContractPayment(response);
        setEditEnabled(false);
        setIsChanged(false);
        message.success("Data saved successfully");
      })
      .catch((err) => {
        console.log(err);
        message.error("Failed to save data");
      });
  };

  return (
    <div className="pay-processing-root" style={{ boxShadow: "none" }}>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <div>
          <div style={{ marginBottom: "20px", marginLeft: "25px" }}>
            <Row>
              <Col style={{ marginLeft: 0, paddingTop: "25px" }}>
                <h1 style={{ margin: 0 }}>Contractor Payment Default</h1>
              </Col>
            </Row>
          </div>
          <div style={{ marginLeft: "25px", paddingBottom: "25px" }}>
            <Row gutter={10}>
              <Col>
                <h3>Retainer Percentage: </h3>
              </Col>
              <Col>
                <Input
                  style={{
                    border: "1px solid gray",
                    borderRadius: "5px",
                    width: "150px",
                    height: "25px",
                  }}
                  type="number"
                  max={100}
                  min={0}
                  onKeyDown={handleKeyDown}
                  value={contractPayment.retainerPercentage}
                  onChange={handleInputChange}
                  disabled={!editEnabled}
                />
              </Col>
              <Col>
                {editEnabled ? (
                  <SaveOutlined
                    style={{
                      color: "#FDB913",
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                    onClick={handleSave}
                    // disabled={!isChanged}
                  />
                ) : (
                  <EditOutlined
                    style={{
                      color: "#FDB913",
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                    onClick={() => setEditEnabled(true)}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractPaymentDefault;
