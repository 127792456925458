import React, { useEffect } from "react";
import { Layout, Button, Row, Col } from "antd";
import { useHistory, useParams } from "react-router-dom";
import SkillsList from "../../components/skills-card/SkillsList";
import PrePresentation from "../../containers/pre-presentation-checklist/PrePresentation";
import ProductionSteps from "./ProductionSteps";
import useSyncProjectData from "../../utils/useSyncProjectData";
import { Spin } from "antd";

const { Content } = Layout;

const PreChecklist: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);
  const { id } = useParams<any>();
  const [isLoading] = useSyncProjectData(id);
  return (
    <>
      {isLoading === true ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <Content>
          <ProductionSteps current={1} />
          <div className="steps-content">
            <Row gutter={24}>
              <Col className="gutter-row">
                <PrePresentation />
              </Col>
            </Row>
          </div>
        </Content>
      )}
    </>
  );
};

export default PreChecklist;
