import { Modal, Button, Upload, Row, Col, message } from "antd";
import { API_BASE } from "../../constant";
import { getAuthUser, getSelectedTenant } from "../../utils/authToken";
import { isNull } from "lodash";

const { Dragger } = Upload;
type Props = {
  showAgreementModal: boolean;
  toggleAgreementModal: () => void;
  url?: string;
  handleAddAgreement?: (info: any) => void;
  contractDisable?: any;
};
const fileSizeLimit = 5 * 1024 * 1024; // 5 MB
const UploadAgreement = (props: Props) => {
  const user = getAuthUser();
  const tenantId = getSelectedTenant();

  const handleBeforeUpload = (file: File) => {
    const isTooLarge = file.size > fileSizeLimit;
    const isPDF = file.type === "application/pdf";
    if (isTooLarge) {
      message.error("Maximum file size is 5mb!");
      return Upload.LIST_IGNORE;
    }
    if (!isPDF) {
      message.error("You can only upload PDF file!");
      return Upload.LIST_IGNORE;
    }
    return isPDF;
  };

  return (
    <div>
      <Modal
        className="default-modal-rt"
        centered={true}
        visible={props.showAgreementModal}
        onOk={props.toggleAgreementModal}
        onCancel={props.toggleAgreementModal}
        footer={[
          <div className="send-btn-container">
            <Button
              key="back"
              onClick={props.toggleAgreementModal}
              className="default-material-cancel-btn"
            >
              Cancel
            </Button>
          </div>,
        ]}
        title={
          <h1 style={{ marginTop: 10 }} className="default-modal-add-item">
            Add item
          </h1>
        }
      >
        <div className="bulk-default-dragger">
          <Dragger
            action={`${API_BASE}${props.url}`}
            headers={{
              Authorization: "Bearer " + user.accessToken,
              "tenant-id": !isNull(tenantId) ? tenantId : "",
            }}
            onChange={props.handleAddAgreement}
            beforeUpload={handleBeforeUpload}
            showUploadList={false}
            method="PATCH"
            disabled={props.contractDisable}
          >
            <div style={{ padding: 20 }}>
              <Row justify="center">
                <Col>
                  <Button
                    disabled={props.contractDisable}
                    className="default-bulk-btn-upload"
                    type="primary"
                  >
                    Upload PDF
                  </Button>
                </Col>
              </Row>
              <Row justify="center">
                <Col>
                  <p>.pdf accepted</p>
                </Col>
              </Row>
              <Row justify="center">
                <Col>
                  <h3>
                    You can upload any .pdf file if it has the format of
                    _______. You will be able to remove or cleanup any data
                    before the report is finalized.
                  </h3>
                </Col>
              </Row>
            </div>
          </Dragger>
        </div>
      </Modal>
    </div>
  );
};

export default UploadAgreement;
