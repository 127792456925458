export const wageColumns = [
  {
    title: "Wage Defaults",
    dataIndex: "title",
    width: "50%",
    // editable: true,
  },
  {
    title: "Cost",
    width: "50%",
    dataIndex: "wageValue",
    editable: true,
    render: (data: any, row: any) => {
      return (
        <div>
          {(row.title === "Painting Wage Rate" ||
            row.title === "Carpentry Wage Rate") && <>$</>}
          {data}
          {row.title === "Additional Coats Percentage" && <>%</>}
          {/* {row.title !== "Default Margin Target" && <>$</>}
          {row.title === "Default Margin Target" && <>%</>} */}
        </div>
      );
    },
  },
];

export const costColumns = [
  {
    title: "Cost",
    dataIndex: "cost",
    editable: true,
    width: "50%",
    render: (data: any) => {
      return <div>${data}</div>;
    },
  },
  {
    title: "Tiered Margin",
    dataIndex: "margin",
    editable: true,
    width: "50%",
    render: (data: any) => {
      return <div>{data}%</div>;
    },
  },
];
