import SelectOptionForm from "../../components/pre-presentation-checklist/SelectOptionForm";
import "./PrePresentation.less";

type Props = {};

const PrePresentation = (props: Props) => {
  return <SelectOptionForm />;
};

export default PrePresentation;
