import React, { useEffect, useState } from "react";
import JobCard from "../../components/job-card/JobCard";
import { Row, Col, Spin, Button } from "antd";
import "./JobCards.less";
import api from "../../utils/api";

import {
  clearOfflineData,
  setAdminDefaults,
  setCurrentProject,
} from "../../redux/project/action";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { pricingCalculation } from "../../utils/priceCalculation";
import axios from "axios";
import { API_BASE } from "../../constant";
import { logout } from "../../redux/user/action";
import { useHistory } from "react-router-dom";

type Props = {};

const JobCards = (props: Props) => {
  const [jobs, setJobs] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const { adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const history = useHistory();
  const { tenant } = useSelector((state: RootStateOrAny) => state.auth);

  if (tenant === null) {
    localStorage.clear();
    dispatch(logout());
    dispatch(clearOfflineData());
    history.push("/login");
  }
  const [adminDefaultsValues, setAdminDefaultsValues] = useState();
  const [pageNo, setPageNo] = useState<number>(1);
  const [myJobsCount, setMyJobsCount] = useState<number>(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    dispatch(setCurrentProject(-1));
    setLoading(true);
    if (online) {
      // api.get("projects/all-defaults").then((resp: any) => {
      //   console.log(resp);
      //   setAdminDefaultsValues(resp);
      //   dispatch(setAdminDefaults(resp));
      //   setLoading(false);
      // });
      api.get(`jobs/contractor/my-jobs/${pageNo}`).then((resp: any) => {
        if (resp.myJobs.length < 12) {
          setShowButton(false);
        } else {
          setShowButton(true);
        }
        const calculatedProjects = resp.myJobs.map((item: any) => {
          return pricingCalculation(item, adminDefaults);
        });
        setJobs(calculatedProjects);
        setMyJobsCount(resp.myJobsCount);
        setPageNo(2);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [online]);

  const handleLoadMore = () => {
    setButtonLoading(true);
    api
      .get(`projects/contractor/my-jobs/${pageNo}`)
      .then((resp: any) => {
        if (resp.myJobs.length < 12) {
          setShowButton(false);
        }
        // if (resp.myJobsCount < 3) {
        //   setShowButton(false);
        // } else {
        //   setShowButton(true);
        // }
        const calculatedProjects = resp.myJobs.map((item: any) => {
          return pricingCalculation(item, adminDefaults);
        });
        setJobs([...jobs, ...calculatedProjects]);
        setMyJobsCount(resp.myJobsCount);
        setPageNo(pageNo + 1);
        setButtonLoading(false);
      })
      .catch((err) => {
        setButtonLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <div>
          <h1 className="job-cards-title">My Jobs</h1>
          <Row style={{ marginTop: "25px" }} gutter={[40, 40]}>
            {jobs?.map((item: any) => {
              return (
                <Col key={item._id} sm={24} md={12} lg={12} xl={8}>
                  <JobCard data={item} />
                </Col>
              );
            })}
          </Row>
          {showButton ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handleLoadMore}
                loading={buttonLoading}
                style={{
                  color: "#1F1F1F",
                  borderRadius: 5,
                  marginTop: 60,
                  marginBottom: 20,
                  height: 50,
                  width: 300,
                }}
                type="primary"
              >
                Load More
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default JobCards;
