const initialState = {
  associates: [],
  payProcessing: [],
  loading: true,
};

const payProcessingDefaults = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_ASSOCIATES":
      return {
        ...state,
        associates: action.payload,
      };
    case "SET_PAY_PROCESSING":
      return {
        ...state,
        payProcessing: action.payload,
      };
    case "ADD_PAY_PROCESSING":
      return {
        ...state,
        payProcessing: [...state.payProcessing, action.payload],
      };
    case "DELETE_PAY_PROCESSING":
      return {
        ...state,
        payProcessing: state.payProcessing.filter(
          (item: any) => item._id !== action.payload
        ),
      };
    case "HIDE_LOADER":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default payProcessingDefaults;
