import {
  Row,
  Col,
  Table,
  Spin,
  Button,
  message,
  Form,
  Modal,
  Input,
  Select,
  Card,
  List,
  Checkbox,
} from "antd";
import { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import api from "../../utils/api";
import { columns } from "./config";
import { useParams } from "react-router-dom";
import { SearchIcon } from "../../utils/icons";
import React from "react";

const Users = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<any>([]);
  const [tenants, setTenants] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editUser, setEditUser] = useState<any>(null);
  const [checkedTenants, setCheckedTenants] = useState<any>([]);
  const [checkedTenantNames, setCheckedTenantNames] = useState<string[]>([]);
  const [checkboxSearched, setCheckboxSearched] = useState<string>("");

  const [isAddUserMode, setIsAddUserMode] = useState(true);

  console.log("TENANTS", tenants, checkboxSearched);

  useEffect(() => {
    setLoading(true);
    api
      .get(`user/users`)
      .then((response: any) => {
        console.log(response);
        setLoading(false);
        setUsers(
          response.filter(
            (item: any) =>
              !item.roles.some((role: string) => role === "super_admin")
          )
        );
      })
      .catch((err) => {
        setLoading(false);
      });
    api
      .get("tenants")
      .then((response: any) => {
        setTenants(response);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const uniqueRoles = [
    "admin",
    "sales_associate",
    "production_associate",
    "estimator",
    "contractor",
    "manager",
  ];

  const handleAddUser = () => {
    form.resetFields();
    setIsModalVisible(true);
    setEditUser(null);
    setIsAddUserMode(true);
  };

  const handleEditUser = (user: any) => {
    setIsModalVisible(true);
    setEditUser(user);
    form.resetFields();
    form.setFieldsValue({
      fullName: user.fullName,
      jobTitle: user.jobTitle,
      role: user.roles,
      email: user.email,
      phone: user.phone,
      tenant: user.tenants.map((tenant: any) => tenant.tenantName).join(", "),
    });

    const userTenantIds = user.tenants.map((tenant: any) => tenant._id);
    setCheckedTenants(userTenantIds);

    setIsAddUserMode(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditUser(null);
    setIsAddUserMode(true);
    setCheckedTenants([]);
    setCheckedTenantNames([]);
    form.resetFields();
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const body = {
          ...values,
          roles: [values.role],
          tenants: checkedTenants,
        };
        delete body.role;
        delete body.tenant;
        setIsModalVisible(false);
        form.resetFields();

        if (isAddUserMode) {
          api
            .post("user", body)
            .then((response: any) => {
              console.log("POST Res", response);
              const newUser = { ...response };
              setUsers([...users, newUser]);
              message.success(`User created with email: ${newUser.email}`);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          const editBody = {
            ...editUser,
            fullName: values.fullName,
            jobTitle: values.jobTitle,
            roles: values.role,
            phone: values.phone,
            tenants: checkedTenants,
          };
          api
            .put(`user/${editUser._id}`, editBody)
            .then((response: any) => {
              console.log("PUT Res", response);
              const updatedUser = { ...response };
              const updatedUsers = users.map((user: any) =>
                user._id === updatedUser._id ? updatedUser : user
              );
              setUsers(updatedUsers);
              message.success(`User updated with email: ${updatedUser.email}`);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        setCheckedTenants([]);
        setCheckedTenantNames([]);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });

    setCheckboxSearched("");
  };
  const getTenantDisplayValue = (tenants: any[]) => {
    return tenants.map((tenant: any) => tenant.tenantName).join(", ");
  };

  const updatedColumns = [
    ...columns,
    {
      title: "Tenant",
      dataIndex: "tenants",
      render: (tenants: any) => {
        if (tenants.length > 0) {
          const tenantsNames = tenants
            .map((tenant: any) => {
              return tenant.tenantName;
            })
            .join(", ");
          return <>{tenantsNames}</>;
        } else {
          return <></>;
        }
      },
    },
    {
      title: "Edit",
      render: (data: any) => {
        return (
          <EditOutlined
            onClick={() => handleEditUser(data)}
            style={{ color: "#FDB913", cursor: "pointer", fontSize: "20px" }}
          />
        );
      },
    },
  ];
  const handleCheckbox = (e: any, tenantId: string) => {
    const isChecked = e.target.checked;
    const tenant = tenants.find((t: any) => t._id === tenantId);

    if (isChecked) {
      setCheckedTenants([...checkedTenants, tenantId]);
      setCheckedTenantNames([...checkedTenantNames, tenant?.tenantName || ""]);
    } else {
      setCheckedTenants(checkedTenants.filter((id: string) => id !== tenantId));
      setCheckedTenantNames(
        checkedTenantNames.filter((name) => name !== (tenant?.tenantName || ""))
      );
    }
  };
  return (
    <>
      <Row className="pp-default-header" align="middle" justify="space-between">
        <Col>
          <h1 style={{ fontWeight: "bold", fontSize: "30px" }}>Create Users</h1>
        </Col>
        <Col>
          <Button
            className="pp-add-associates"
            type="primary"
            onClick={handleAddUser}
          >
            Create User
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <hr style={{ border: "1px solid #E8E8E8" }} />
          <div className="pp-default-table">
            <Table columns={updatedColumns} dataSource={users} rowKey="email" />
            <Modal
              title={
                <span style={{ fontWeight: "bold" }}>
                  {isAddUserMode ? "Add User" : "Edit User"}
                </span>
              }
              style={{ fontWeight: "bold" }}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <Form form={form} layout="vertical" autoComplete="false">
                <Form.Item label="Name" name="fullName">
                  <Input />
                </Form.Item>
                <Form.Item label="Job Title" name="jobTitle">
                  <Input />
                </Form.Item>
                <Form.Item label="Role" name="role">
                  <Select>
                    {uniqueRoles
                      .filter((role) => role !== "super_admin")
                      .map((role: string) => (
                        <Option key={role} value={role}>
                          {role}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Email" name="email">
                  <Input disabled={editUser} />
                </Form.Item>
                <Form.Item label="Phone" name="phone">
                  <Input />
                </Form.Item>
                <Form.Item label="Tenant" name="tenant">
                  <>
                    <Card className="checkbox-card">
                      <Input
                        className="search-surface-input"
                        prefix={<SearchIcon />}
                        placeholder="Search Tenant"
                        onChange={(e) => {
                          setCheckboxSearched(e.target.value);
                        }}
                      />
                      <div className="checkbox-scroll-bar">
                        <List
                          bordered
                          style={{ height: 120 }}
                          dataSource={tenants.filter((tenant: any) =>
                            tenant.tenantName
                              .toLowerCase()
                              .includes(checkboxSearched.toLowerCase())
                          )}
                          rowKey="_id"
                          renderItem={(item: any, index: number) => (
                            <React.Fragment key={item._id}>
                              <List.Item className="surface-list-container">
                                <Checkbox
                                  checked={checkedTenants.includes(item._id)}
                                  onChange={(e) => handleCheckbox(e, item._id)}
                                >
                                  {item.tenantName}
                                </Checkbox>
                              </List.Item>
                            </React.Fragment>
                          )}
                        />
                      </div>
                    </Card>
                    <div style={{ marginTop: "10px" }}>
                      <ul>
                        {checkedTenantNames.map((name, index) => (
                          <li key={index}>{name}</li>
                        ))}
                      </ul>
                    </div>
                  </>
                </Form.Item>

                {isAddUserMode && (
                  <>
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        { required: true, message: "Please enter a password" },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      label="Confirm Password"
                      name="confirmPassword"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("The two passwords do not match")
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </>
                )}
              </Form>
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default Users;
