import {
  Button,
  Col,
  Form,
  Input,
  List,
  Modal,
  Radio,
  Row,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  MoneyIcon,
  CalendarIcon,
  ProjectIcon,
  ScopeIcon,
  CorrectionIcon,
  EquipmentIcon,
  SchoolSegmentIcon,
  CondoSegmentIcon,
  ApartmentSegmentIcon,
  OfficeSegmentIcon,
  WorshipSegmentIcon,
  RetailSegmentIcon,
  LightIndustrialSegmentIcon,
  SeniorLivingSegmentIcon,
  HospitalSegmentIcon,
} from "../../utils/icons";
import { renderToStaticMarkup } from "react-dom/server";
import "./AddSegmentModal.less";

const { Text } = Typography;

const convertReactComponentSvgToString = (
  SVG: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
) => {
  const svgString = encodeURIComponent(renderToStaticMarkup(<SVG />));
  return `data:image/svg+xml,${svgString}`;
};

const iconsConstants = [
  {
    SvgIcon: SchoolSegmentIcon,
    iconName: "school",
    svgString: convertReactComponentSvgToString(SchoolSegmentIcon),
  },
  {
    SvgIcon: CondoSegmentIcon,
    iconName: "condo",
    svgString: convertReactComponentSvgToString(CondoSegmentIcon),
  },
  {
    SvgIcon: ApartmentSegmentIcon,
    iconName: "apartment",
    svgString: convertReactComponentSvgToString(ApartmentSegmentIcon),
  },
  {
    SvgIcon: OfficeSegmentIcon,
    iconName: "office",
    svgString: convertReactComponentSvgToString(OfficeSegmentIcon),
  },
  {
    SvgIcon: WorshipSegmentIcon,
    iconName: "worship",
    svgString: convertReactComponentSvgToString(WorshipSegmentIcon),
  },
  {
    SvgIcon: RetailSegmentIcon,
    iconName: "retail",
    svgString: convertReactComponentSvgToString(RetailSegmentIcon),
  },
  {
    SvgIcon: LightIndustrialSegmentIcon,
    iconName: "industrial",
    svgString: convertReactComponentSvgToString(LightIndustrialSegmentIcon),
  },
  {
    SvgIcon: SeniorLivingSegmentIcon,
    iconName: "old home",
    svgString: convertReactComponentSvgToString(SeniorLivingSegmentIcon),
  },
  {
    SvgIcon: HospitalSegmentIcon,
    iconName: "hospital",
    svgString: convertReactComponentSvgToString(HospitalSegmentIcon),
  },
];

interface Segment {
  _id?: string;
  segmentName: string;
  segmentIcon: string;
  subSegments: string[];
  iconName: string;
}

interface AddSegmentModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (segment: Segment) => void;
  segmentData?: Segment;
}

const AddSegmentModal = ({
  visible,
  onCancel,
  onOk,
  segmentData,
}: AddSegmentModalProps) => {
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [subSegment, setSubSegment] = useState("");
  const [subSegmentsList, setSubSegmentsList] = useState<string[]>([]);
  const [selectedIcon, setSelectedIcon] = useState<string>("");
  const [subSegmentError, setSubSegmentError] = useState<string | null>(null);

  useEffect(() => {
    if (segmentData) {
      form.setFieldsValue({
        name: segmentData.segmentName,
        icon: segmentData.iconName,
      });
      setName(segmentData.segmentName);
      setSubSegmentsList(segmentData.subSegments || []);
      setSelectedIcon(segmentData.iconName);
    } else {
      setName("");
      setSubSegmentsList([]);
      setSelectedIcon("");
    }
  }, [segmentData, form]);

  const handleAddSubSegment = () => {
    if (subSegment.trim()) {
      setSubSegmentsList([...subSegmentsList, subSegment]);
      setSubSegment(""); // Clear the input field after adding
    }
  };

  const handleRemoveSubSegment = (index: number) => {
    const updatedList = subSegmentsList.filter((_, i) => i !== index);
    setSubSegmentsList(updatedList);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then(() => {
        if (subSegmentsList.length === 0) {
          setSubSegmentError("Please add at least one sub-segment.");
          return;
        }
        const selectedIconObject = iconsConstants.find(
          (icon) => icon.iconName === selectedIcon
        );

        const updatedSegment = {
          ...(segmentData && { _id: segmentData._id }),
          segmentName: name,
          subSegments: subSegmentsList,
          segmentIcon: selectedIconObject ? selectedIconObject.svgString : "",
          iconName: selectedIcon,
        };
        onOk(updatedSegment);
        form.resetFields();
        setName("");
        setSubSegment("");
        setSubSegmentsList([]);
        setSelectedIcon("");
        setSubSegmentError(null);
      })
      .catch((info) => {
        console.error("Validation Failed:", info);
      });
  };

  return (
    <Modal
      title={segmentData ? "Edit Segment" : "Add Segment"}
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
      okButtonProps={{ style: { color: "black" } }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Please enter the segment name" },
            {
              max: 50,
              message: "Segment name must be less than 50 characters",
            },
          ]}
        >
          <Input
            placeholder="Enter segment name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Icon"
          name="icon"
          rules={[{ required: true, message: "Please select an icon" }]}
        >
          <Radio.Group
            style={{ width: "105%" }}
            value={selectedIcon}
            onChange={(e) => setSelectedIcon(e.target.value)}
          >
            <Row>
              {iconsConstants.map(({ SvgIcon, iconName, svgString }, index) => (
                <Col span={4} key={index} style={{ paddingBottom: "15px" }}>
                  <Tooltip title={iconName}>
                    <Radio.Button
                      value={iconName}
                      className="custom-icon-radio-button"
                    >
                      <SvgIcon />
                    </Radio.Button>
                  </Tooltip>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Sub Segment" className="sub-segment-item">
          <Input
            className="sub-segment-input"
            placeholder="Enter sub segment"
            value={subSegment}
            onChange={(e) => setSubSegment(e.target.value)}
            onPressEnter={handleAddSubSegment}
            addonAfter={
              <Button
                className="sub-segment-add-button"
                onClick={handleAddSubSegment}
              >
                +
              </Button>
            }
          />
        </Form.Item>

        {subSegmentsList.length > 0 && (
          <List
            size="small"
            bordered
            dataSource={subSegmentsList}
            renderItem={(item, index) => (
              <List.Item
                className="sub-segment-list-item"
                actions={[
                  <Button
                    className="sub-segment-remove-button"
                    type="link"
                    danger
                    onClick={() => handleRemoveSubSegment(index)}
                  >
                    Remove
                  </Button>,
                ]}
              >
                {item}
              </List.Item>
            )}
          />
        )}
        {subSegmentError && <Text type="danger">{subSegmentError}</Text>}
      </Form>
    </Modal>
  );
};

export default AddSegmentModal;
