import Item from "antd/lib/list/Item";

const initialState = {
  contractorData: [],
  loading: true,
};

const contractorData = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_CONTRACTOR_DATA":
      return {
        ...state,
        contractorData: action.payload,
      };
    case "ADD_CONTRACTOR":
      return {
        ...state,
        contractorData: [...state.contractorData, action.payload],
      };

    case "UPDATE_CONTRACTOR_DATA":
      return {
        ...state,
        contractorData: state.contractorData.map((item: any) =>
          item._id === action.payload._id ? action.payload : item
        ),
      };

    case "BULK_ADD_CONTRACTOR_DATA":
      return {
        ...state,
        contractorData: [...state.contractorData, ...action.payload],
      };

    case "UPDATE_CONTRACTOR_STATUS":
      return {
        ...state,
        contractorData: state.contractorData.map((item: any) => {
          if (item._id === action.payload._id) {
            return {
              ...item,
              active: action.payload.active,
            };
          }
          return item;
        }),
      };
    case "HIDE_LOADER":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default contractorData;
