import React, { useEffect, useState } from "react";
import { Row, Col, Table, Image } from "antd";
import { BulletIcon, MoneyIcon } from "../../utils/icons";
import "./ContractSetup.less";

type Props = {
  data: {
    _id: string;
    svgString: string;
    title: string;
    description: string;
    position: number;
    addToProjects: boolean;
    isDefault: boolean;
  };
  amount?: any;
  totalAmount?: any;
  compensation?: string;
  onTextChange?: (e: any) => void;
};
const compensationColumns = [
  {
    title: "Description",
    dataIndex: "name",
    width: "100px",
  },
  {
    title: "Amount",
    dataIndex: "productionTargets",
    render: (data: any) => {
      return (
        <>
          {data && (
            <div>
              $
              {data?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          )}
        </>
      );
    },
    width: "100px",
  },
];

const ContractCompensation = ({ data, ...props }: Props) => {
  const [tableData, setTableData] = useState<any>([]);
  useEffect(() => {
    if (props.amount) {
      setTableData([
        ...props.amount,
        {},
        {},
        {
          name: "Total Contract Price",
          productionTargets: props.totalAmount,
        },
      ]);
    }
  }, [props.amount, props.totalAmount]);

  return (
    <div>
      <div className="contract-comp-root-v2">
        <Row className="cd-margin-btm ">
          <Col>
            <Image
            src={data.svgString}
            style={{
              paddingTop: "0px",
              paddingRight: "18px",
            }}
            preview={false}
          />
          </Col>
          <Col>
            <h1>Compensation</h1>
          </Col>
        </Row>
        <p>
          Subcontractor shall be paid for satisfactory performance of the Work
          the total lump sum amount shown below.
        </p>
      </div>
      <div
        style={{ padding: "0px 25px 20px 30px" }}
        className="compensation-table"
      >
        <Table
          rowKey={(_, index: any) => index.toString()}
          rowClassName={(_, index) =>
            index % 2 === 0
              ? "table_row table-row-light"
              : "table_row table-row-dark"
          }
          pagination={false}
          columns={compensationColumns}
          dataSource={tableData}
        />
      </div>
      <div className="contract-comp-root e-margin-tp-30">
        <Row>
          <Col span={24}>
            <pre
              className="contract-defaults-description"
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
            {props.compensation && (
              <Row gutter={10}>
                <Col span={0.1}>
                  <BulletIcon />
                </Col>
                <Col span={23}>
                  <p>{props.compensation}</p>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ContractCompensation;
