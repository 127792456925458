import React, { RefObject, useRef, useState } from "react";
import { Button, Checkbox, Input, InputRef, List, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";

interface FilterItem {
  text: string;
  value: string;
  checked: boolean;
}

interface FilterColumnProps {
  dataIndex: string;
  selectedKeys: string[];
  setSelectedKeys: (keys: string[]) => void;
  //   setFilteredLists: (keys: any) => void;
  filters: { [key: string]: FilterItem[] };
  filteredLists: { [key: string]: FilterItem[] };
  handleSearch: (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: string,
    filteredLists: { [key: string]: FilterItem[] }
  ) => void;
  handleReset: (
    clearFilters: () => void,
    dataIndex: string,
    filters: { [key: string]: FilterItem[] }
  ) => void;
  clearFilters?: () => void;
  //   handleCheckboxChange: (dataIndex: string, index: number) => void;
  confirm: () => void;
}

const FilterColumn: React.FC<FilterColumnProps> = ({
  dataIndex,
  selectedKeys,
  setSelectedKeys,
  //   setFilteredLists,
  filters,
  filteredLists,
  handleSearch,
  handleReset,
  clearFilters,
  //   handleCheckboxChange,
  confirm,
}) => {
  const searchInput = useRef<InputRef>(null);
  const [columnFilteredLists, setColumnFilteredLists] = useState<{
    [key: string]: FilterItem[];
  }>(filteredLists);

  const handleCheckboxChange = (dataIndex: string, index: number) => {
    setColumnFilteredLists((prevFilteredLists) => {
      const updatedList = [...prevFilteredLists[dataIndex]];
      updatedList[index].checked = !updatedList[index].checked;
      return {
        ...prevFilteredLists,
        [dataIndex]: updatedList,
      };
    });
  };

  const handleFilterReset = (clearFilters: () => void, dataIndex: string) => {
    setSelectedKeys([]);
    setColumnFilteredLists((prevFilteredLists) => {
      const updatedList = prevFilteredLists[dataIndex].map((item) => ({
        ...item,
        checked: false,
      }));

      return {
        ...prevFilteredLists,
        [dataIndex]: updatedList,
      };
    });

    clearFilters && handleReset(clearFilters, dataIndex, filters);
  };

  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => {
          const value = e.target.value;
          setSelectedKeys(value ? [value] : []);
          setColumnFilteredLists((prev: any) => ({
            ...prev,
            [dataIndex]: filters[dataIndex].filter((item) =>
              item.text.toLowerCase().includes(value.toLowerCase())
            ),
          }));
        }}
        // onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
        style={{ marginBottom: 8, display: "block" }}
      />
      <div style={{ height: 300, overflowY: "scroll", paddingRight: 16 }}>
        <List
          itemLayout="horizontal"
          dataSource={columnFilteredLists[dataIndex]}
          renderItem={(item, index) => (
            <List.Item>
              <Checkbox
                checked={item.checked}
                onChange={() => handleCheckboxChange(dataIndex, index)}
              >
                {item.text}
              </Checkbox>
            </List.Item>
          )}
        />
      </div>
      <Space>
        <Button
          onClick={() =>
            clearFilters && handleFilterReset(clearFilters, dataIndex)
          }
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button
          type="primary"
          onClick={() =>
            handleSearch(
              selectedKeys as string[],
              confirm,
              dataIndex,
              columnFilteredLists
            )
          }
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
      </Space>
    </div>
  );
};

export default FilterColumn;
