import React, { useState } from "react";
import { Row, Col, Button, Table } from "antd";
import "./Pricing.less";
import { pricingColumns } from "./config";
import { pricingData } from "./data";
import { HideArrowIcon, ShowArrowIcon } from "../../utils/icons";
import ViewPjcc from "../../components/PjccCard/ViewPjcc";

type Props = {};

const PricingC = (props: Props) => {
  const [showSider, setShowSider] = useState(false);
  const [itemsSelected, setItemsSelected] = useState(0);

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setItemsSelected(selectedRows.length);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const togglePjccBtn = () => {
    showSider ? setShowSider(false) : setShowSider(true);
  };
  return (
    <div>
      <div className="pricing-container">
        <div className="pricing-header">
          <h1 className="pricing-title">Pricing</h1>
        </div>
        <hr style={{ border: "1px solid #E8E8E8" }} />

        <div className="items-selected-container">
          <Row justify="space-between">
            <Row>
              <Col>
                {itemsSelected > 0 && (
                  <h1 className="items-selected-text">
                    {itemsSelected} items Selected
                  </h1>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Button className="pricing-btn">Optional</Button>
              </Col>
              <Col>
                <Button className="pricing-btn">Exclude</Button>
              </Col>
            </Row>
          </Row>
        </div>
        <div style={{ position: "fixed", right: 0, zIndex: 9999 }}>
          <div style={{ display: "flex" }}>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="invert-pjcc-btns"
            >
              <Button
                type="primary"
                className="view-pjcc-btn"
                onClick={togglePjccBtn}
              >
                {!showSider ? (
                  <HideArrowIcon className="pjcc-show-icon" />
                ) : (
                  <ShowArrowIcon className="pjcc-show-icon" />
                )}
                View PJCC
              </Button>
            </div>
          </div>
        </div>

        <div
          style={{ position: "fixed", right: 0, zIndex: 9999, top: "11%" }}
          className="show-view-pjcc"
        >
          <div>
            {showSider && (
              <ViewPjcc showSider={showSider} togglePjccBtn={togglePjccBtn} />
            )}
          </div>
        </div>
        <Table
          rowSelection={{
            ...rowSelection,
          }}
          pagination={false}
          columns={pricingColumns}
          dataSource={pricingData}
        />
      </div>
      <Row justify="center">
        <Col>
          <Button className="pricing-request-btn">
            Request Paint Vendor Job Quote
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default PricingC;
