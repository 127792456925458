import React, { useEffect, useState } from "react";
import { Button, Col, Input, Popover, Row, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import CustomExpandIcon from "./CustomExpandIcon";
import { LinkIcon } from "../../utils/icons";
const { TextArea } = Input;
type Props = {
  title: string;
  text: string;
  // links: [string];
  // onLinksChange: any;
  onNotesChange: any;
  expanded?: boolean;
  onExpand?: any;
};

const OptionNotesSummary = (props: Props) => {
  const [input, setInput] = useState<string>("");
  const [open, setOpen] = useState(false);
  // const [urlList, setUrlList] = useState<string[]>([]);
  const [urlValue, setUrlValue] = useState<string>("");

  useEffect(() => {
    setInput(props.text);
  }, [props.text]);

  const submitNotes = () => {
    props.onNotesChange(input);
  };
  const handleAddLink = () => {
    const newValue = `${input} [${urlValue}] `;
    setInput(newValue);
    setOpen(false);
    setUrlValue("");
    submitNotesWithLink(newValue);
  };

  const submitNotesWithLink = (value: string) => {
    props.onNotesChange(value);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleUrlChange = (e: any) => {
    setUrlValue(e.target.value);
  };

  return (
    <>
      {props.title === "Proposal General Description" ? (
        <Row>
          <h2 style={{ paddingTop: 20 }}>{props.title}</h2>
          <Popover
            content={
              <>
                <Row>
                  <Col>
                    <Input value={urlValue} onChange={handleUrlChange} />
                  </Col>
                  <Col>
                    <Button onClick={handleAddLink}>Apply</Button>
                  </Col>
                </Row>
              </>
            }
            title="Add Link"
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <Tooltip placement="right" title={"Add a Link"}>
              <LinkIcon
                style={{
                  height: 22,
                  width: 22,
                  cursor: "pointer",
                  marginTop: 20,
                  marginLeft: 10,
                }}
              />
            </Tooltip>
          </Popover>
        </Row>
      ) : (
        <>
          <Row>
            <Col style={{ marginRight: 12, marginTop: 16 }}>
              <CustomExpandIcon
                expanded={props.expanded}
                onExpand={props.onExpand}
              />
            </Col>
            <Col>
              <h2 style={{ paddingTop: 20 }}>{props.title}</h2>
            </Col>
            <Col>
              <Popover
                content={
                  <>
                    <Row>
                      <Col>
                        <Input value={urlValue} onChange={handleUrlChange} />
                      </Col>
                      <Col>
                        <Button onClick={handleAddLink}>Apply</Button>
                      </Col>
                    </Row>
                  </>
                }
                title="Add Link"
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
              >
                <Tooltip placement="right" title={"Add a Link"}>
                  <LinkIcon
                    style={{
                      height: 22,
                      width: 22,
                      cursor: "pointer",
                      marginTop: 20,
                      marginLeft: 10,
                    }}
                  />
                </Tooltip>
              </Popover>
            </Col>
          </Row>
        </>
      )}
      {props.title === "Proposal General Description" ? (
        <>
          <TextArea
            autoSize={{ minRows: 4, maxRows: 4 }}
            style={{
              marginTop: 15,
              border: "1px solid #E8E8E8",

              borderRadius: "5px",
            }}
            onChange={(e) => setInput(e.target.value)}
            value={input}
            rows={5}
            placeholder="Add Notes"
            onBlur={submitNotes}
          />
        </>
      ) : (
        <>
          {props.expanded && (
            <>
              <TextArea
                autoSize={{ minRows: 4, maxRows: 4 }}
                style={{
                  marginTop: 15,
                  border: "1px solid #E8E8E8",

                  borderRadius: "5px",
                }}
                onChange={(e) => setInput(e.target.value)}
                value={input}
                rows={5}
                placeholder="Add Notes"
                onBlur={submitNotes}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default OptionNotesSummary;
