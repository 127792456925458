export const columns = [
  {
    title: "Name",
    dataIndex: "fullName",
    sorter: (a: any, b: any) => a.fullName.localeCompare(b.fullName),
  },
  {
    title: "Role",
    dataIndex: "roles",
    render: (role: any) => {
      return <>{role[0]}</>;
    },
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Phone",
    dataIndex: "phone",
  },
];
