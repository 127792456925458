export const API_BASE = process.env.REACT_APP_BACKEND_URL;
export const token =
  "6f9a06abf507bdcb999906d99a86ca78ff67374b4ed70bb72c9c2325590c0eae0c9d162aec02c6c959d7fd93d29bee2efc2458eaea4614c9c46d052a774ae174de61502352c6f1ae8180234622f93d6fe6a035aca31fb2bad03b2606902452ed53c03d9f1bc901d89894b14d7987ecff4fe5fe96b05fb1bc1954eced4cfb4e62bce93f6703dad07cc631901e6a116c3c15eda7bd6fc1f5fe29af0fd55499af5190371b9fd48c66e28645fd15781deabd8cef51ad572ee1134eff5f4198561774";

export const canadaStates = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon",
];

export const usStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
