import { useEffect, useState } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  Dropdown,
  Menu,
  message,
  Switch,
  Spin,
} from "antd";
import { columns, contractorHeader } from "./config";
import { SearchOutlined } from "@ant-design/icons";
import "./ContractorDataC.less";
import AddItemContractor from "../../components/pop-ups/default-modal/AddItemContractor";
import DefaultBulkEntry from "../../components/pop-ups/default-modal/MaterialDefaultBulkEntry";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { MoreActionIcon } from "../../utils/icons";
import {
  bulkAddContractorData,
  updateContractorActive,
  updateContractorData,
} from "../../redux/contractor-data/action";
import { CSVLink } from "react-csv";
import UploadAgreement from "./UploadAgreement";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

const ContractorDataC = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:800px)" });

  const [ContractorSearched, setContractorSearched] = useState("");
  const [limit, setLimit] = useState<any>([]);
  const dispatch = useDispatch();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [contractDisable, setContractDisable] = useState(false);
  const [showContractorDataModal, setShowContractorDataModal] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const [item, setItem] = useState({});
  const [edit, setEdit] = useState(false);
  const [contractor, setContractor] = useState<any>({});
  const [downloadCsv, setDownloadCsv] = useState<any>([]);

  const { contractorData, loading } = useSelector(
    (state: RootStateOrAny) => state.contractorData
  );

  useEffect(() => {
    let newArray = contractorData.map((item: any) => {
      return {
        ...item,
        date: moment(item?.data).format("MMMM YYYY"),
      };
    });
    setDownloadCsv(newArray);
  }, [contractorData]);

  const found = contractorData.length ? contractorData.filter((item: any) => {
    if (!ContractorSearched) {
      return true;
    }
    return item?.fullName
      ?.toLowerCase()
      .includes(ContractorSearched.toLowerCase());
  }) : [];

  const toggleContractorDataModal = () => {
    !showContractorDataModal
      ? setShowContractorDataModal(true)
      : setShowContractorDataModal(false);
  };

  const toggleBulkModal = () => {
    !showBulkModal ? setShowBulkModal(true) : setShowBulkModal(false);
  };

  const toggleAgreementModal = () => {
    !showAgreementModal
      ? setShowAgreementModal(true)
      : setShowAgreementModal(false);
  };

  const handleSingleItem = () => {
    setEdit(false);
    setItem({});
    toggleContractorDataModal();
  };

  const handleAddPdf = (info: any) => {
    setContractDisable(true);
    if (info.file.status === "done") {
      setContractDisable(false);
      dispatch(updateContractorData(info.file.response));
      message.success("Successfully Uploaded");
      toggleAgreementModal();
    } else if (info.file.status === "error") {
      setContractDisable(false);
      message.error("Uploading Failed!");
    }
  };

  const handleAddCsv = (info: any) => {
    setButtonDisable(true);
    if (info.file.status === "done") {
      setButtonDisable(false);
      dispatch(bulkAddContractorData(info.file.response));
      message.success("Successfully Uploaded");
      toggleBulkModal();
    } else if (info.file.status === "error") {
      if (info.file?.response?.statusCode === 422) {
        message.error(info.file.response.message);
      } else {
        message.error("Uploading Failed!");
      }
      setButtonDisable(false);
    }
  };

  const handleBulkBtn = () => {
    !showBulkModal ? setShowBulkModal(true) : setShowBulkModal(false);
  };

  const handleUpload = (data: any) => {
    setContractor(data);
    toggleAgreementModal();
  };

  const handleEdit = (data: any) => {
    setItem(data);
    setEdit(true);
    toggleContractorDataModal();
  };

  const handleActive = (checked: boolean, data: any) => {
    dispatch(updateContractorActive({ _id: data._id, active: checked }));
  };

  const handleLimit = (index: number) => {
    const newArr = [...limit];
    newArr[index] = true;
    setLimit(newArr);
  };

  const tableColumns = [
    ...columns,
    {
      title: "Active",
      render: (data: any) => {
        return (
          <Switch
            checked={data?.active}
            onChange={(checked: boolean) => handleActive(checked, data)}
          />
        );
      },
    },
    {
      title: "Action",
      render: (data: any) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="edit"
                  style={{ fontWeight: "bold" }}
                  onClick={() => handleEdit(data)}
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  key="upload"
                  style={{ fontWeight: "bold" }}
                  onClick={() => handleUpload(data)}
                >
                  Upload Agreement
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <div>
              <MoreActionIcon />
            </div>
          </Dropdown>
        );
      },
    },
    // {
    //   title: "Skills",
    //   dataIndex: "skills",
    //   key: "skills",
    //   render: (data: any, _: any, index: number) => {
    //     return (
    //       <div style={{ display: "flex", flexDirection: "row" }}>
    //         {data
    //           .split(",")
    //           .slice(0, !limit[index] ? 3 : data.split(",").length)
    //           .map((item: any) => (
    //             <p>{item},</p>
    //           ))}

    //         {!limit[index] && data.split(",").length > 3 && (
    //           <div onClick={() => handleLimit(index)}>
    //             <p className="cd-skills-more">
    //               +{data.split(",").length - 3} more
    //             </p>
    //           </div>
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <div className="contractor-data-default-root">
      <AddItemContractor
        toggleContractorDataModal={toggleContractorDataModal}
        showContractorDataModal={showContractorDataModal}
        item={item}
        edit={edit}
      />
      <DefaultBulkEntry
        buttonDisable={buttonDisable}
        showBulkModal={showBulkModal}
        toggleBulkModal={toggleBulkModal}
        url="user/upload-contractors"
        handleAddCsv={handleAddCsv}
      />
      <UploadAgreement
        showAgreementModal={showAgreementModal}
        toggleAgreementModal={toggleAgreementModal}
        url={`user/upload-agreement/${contractor._id}`}
        handleAddAgreement={handleAddPdf}
        contractDisable={contractDisable}
      />
      <Row
        justify="space-between"
        className="contractor-data-default-header"
        align="middle"
      >
        {isTabletOrMobile ? (
          <Col>
            <h1 style={{ marginBottom: "15px" }}>Contractor data</h1>
          </Col>
        ) : (
          <Col>
            <h1>Contractor data</h1>
          </Col>
        )}

        <Col>
          <Row gutter={12}>
            <Col>
              <Input
                className="search-manufacture-input"
                prefix={
                  <SearchOutlined className="contractor-data-search-icon" />
                }
                placeholder="Search Contractor"
                onChange={(e) => {
                  setContractorSearched(e.target.value);
                }}
              />
            </Col>
            <div className="contractor-data-vertical-line " />
            <Col>
              <Button
                className="contractor-data-add-btn"
                onClick={handleSingleItem}
              >
                Add Contractor
              </Button>
            </Col>
            <Col>
              <Button
                onClick={handleBulkBtn}
                className="contractor-data-bulk-btn "
                type="primary"
              >
                Bulk Entry
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <hr style={{ border: "1px solid #E8E8E8", margin: "25px 0px" }} />
          <div>
            <Button
              className="default-bulk-btn"
              style={{ marginBottom: 10 }}
              type="primary"
            >
              <CSVLink
                data={downloadCsv}
                filename={"contractors"}
                headers={contractorHeader}
                enclosingCharacter={""}
              >
                Download CSV
              </CSVLink>
            </Button>
            {contractorData.length > 0 && (
              <Table
                rowClassName={(_, index) =>
                  index % 2 === 0
                    ? "table_row table-row-light"
                    : "table_row table-row-dark"
                }
                scroll={{ x: "900px" }}
                rowKey="_id"
                dataSource={found}
                columns={tableColumns}
                loading={!contractorData.length}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ContractorDataC;
