import { useState } from "react";
import { Button, Col, Input, message, Popover, Row, Table } from "antd";
import "../../containers/OptionDetails/OptionDetails.less";
import ActionMenu from "./ActionMenu";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getAuthUser } from "../../utils/authToken";
import api from "../../utils/api";
import {
  removeRatesFromOptions,
  addServiceToProject,
  addRatesToOptions,
} from "../../redux/project/action";
import _ from "lodash";
import React from "react";
import { customToFixed } from "../../utils/helpers";
var crypto = require("crypto");

type Props = {
  carpentryRates: any;
  setCarpentryState: any;
  setRatesValue: any;
  handleCheckModifiedOnEdit?: any;
};

const Carpentry = (props: Props) => {
  const [countIndex, setCountIndex] = useState<any>();
  const [plusVisible, setPlusVisible] = useState<any>([]);
  const [minusVisible, setMinusVisible] = useState<any>([]);
  const user = getAuthUser();
  const [count, setCount] = useState<any>();
  const dispatch = useDispatch<any>();
  const carpentryLaborRateConst = 35;
  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const handlePlusVisibleChange = (visibleState: any, index: any) => {
    const prev = [...plusVisible];
    prev[index] = visibleState;
    setPlusVisible(prev);
  };
  const handleMinusVisibleChange = (visibleState: any, index: any) => {
    const prev = [...minusVisible];
    prev[index] = visibleState;
    setMinusVisible(prev);
  };
  const findCount = (rate: any) => {
    if (rate) {
      let count = 0;
      switch (rate.type) {
        case "EA":
          count = 1;
          break;
        case "LF":
          count = 8;
          break;
        case "SQFT":
          count = 10;
          break;
        default:
          break;
      }
      return count;
    } else {
      return 8;
    }
  };

  const handleAdd = (index: number) => {
    const newData = [...props.carpentryRates];

    if (count) {
      newData[countIndex].count =
        parseInt(newData[countIndex].count) + parseInt(count);
      props.setCarpentryState(newData);
      calculateCarpentryHours(countIndex, newData);
      calculateCarpentryMaterial(countIndex, newData);
      const prev = [...plusVisible];
      prev[index] = false;
      setPlusVisible(prev);
      setCount("");
    }
  };

  function onCountChange(e: any, index: any, rowId: any) {
    if (parseInt(e.target.value) >= 0 || e.target.value === "") {
      const newData = [...props.carpentryRates];
      const rowIndex = newData.findIndex((item: any) => item._id === rowId);

      if (e.target.value === "") {
        newData[rowIndex].count = 0;
      } else {
        newData[rowIndex].count = parseInt(e.target.value);
      }

      calculateCarpentryHours(rowIndex, newData);
      calculateCarpentryMaterial(rowIndex, newData);
    }
  }

  const handleMinus = (index: number) => {
    if (props.carpentryRates[countIndex].count - parseInt(count) >= 0) {
      const newData = [...props.carpentryRates];

      newData[countIndex].count = newData[countIndex].count - parseInt(count);
      props.setCarpentryState(newData);
      calculateCarpentryHours(countIndex, newData);
      calculateCarpentryMaterial(countIndex, newData);
    }
    setCount("");
    const prev = [...minusVisible];
    prev[index] = false;
    setMinusVisible(prev);
  };
  const handleMinusCancel = (index: number) => {
    const prev = [...minusVisible];
    prev[index] = false;
    setMinusVisible(prev);
  };

  const handlePlusCancel = (index: number) => {
    const prev = [...plusVisible];
    prev[index] = false;
    setPlusVisible(prev);
  };

  const handleAddValue = (index: number, rowId: string) => {
    const newData = [...props.carpentryRates];
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    const count = findCount(props.carpentryRates[rowIndex]?.defaultRate);
    newData[rowIndex].count = newData[rowIndex].count + count;
    // props.setCarpentryState(newData);

    calculateCarpentryHours(rowIndex, newData);
    calculateCarpentryMaterial(rowIndex, newData);
  };
  const handleSubtractValue = (index: number, rowId: string) => {
    const newData = [...props.carpentryRates];
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    const count = findCount(props.carpentryRates[rowIndex]?.defaultRate);
    if (props.carpentryRates[rowIndex].count >= count) {
      newData[rowIndex].count = newData[rowIndex].count - count;
      // props.setCarpentryState(newData);
      calculateCarpentryHours(rowIndex, newData);
      calculateCarpentryMaterial(rowIndex, newData);
    }
  };

  const handleCountChange = (e: any) => {
    setCount(e.target.value);
  };
  const handleAddKeyDown = (e: any, index: number) => {
    if (e.key === "Enter") {
      handleAdd(index);
    }
  };

  const handleMinusKeyDown = (e: any, index: number) => {
    if (e.key === "Enter") {
      handleMinus(index);
    }
  };

  const content = (index: number) => (
    <div style={{ width: 150 }}>
      <Input
        type="number"
        onKeyDown={(e) => handleAddKeyDown(e, index)}
        value={count}
        onChange={handleCountChange}
      />
      <Row justify="space-between">
        <Col>
          <Button
            style={{ marginTop: 10 }}
            type="primary"
            onClick={() => handleAdd(index)}
          >
            Ok
          </Button>
        </Col>
        <Col>
          <Button
            style={{ marginTop: 10 }}
            onClick={() => handlePlusCancel(index)}
          >
            Close
          </Button>
        </Col>
      </Row>
    </div>
  );

  const minusContent = (index: number) => (
    <div style={{ width: 150 }}>
      <Input
        type="number"
        onKeyDown={(e) => handleMinusKeyDown(e, index)}
        value={count}
        onChange={handleCountChange}
      />
      <Row justify="space-between">
        <Col>
          <Button
            style={{ marginTop: 10 }}
            type="primary"
            onClick={() => handleMinus(index)}
          >
            Ok
          </Button>
        </Col>
        <Col>
          <Button
            style={{ marginTop: 10 }}
            onClick={() => handleMinusCancel(index)}
          >
            Close
          </Button>
        </Col>
      </Row>
    </div>
  );

  const handlePlusIndexState = (e: any, index: any, rowId: string) => {
    const newData = [...props.carpentryRates];
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    setCountIndex(rowIndex);
    const prev = [...plusVisible];
    prev[rowIndex] = true;
    setPlusVisible(prev);
  };
  const handleMinusIndexState = (e: any, index: any, rowId: string) => {
    const newData = [...props.carpentryRates];
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    setCountIndex(rowIndex);
    const prev = [...minusVisible];
    prev[rowIndex] = true;
    setMinusVisible(prev);
  };

  const calculateCarpentryMaterial = (index: number, newData: any) => {
    // const newData = [...props.carpentryRates];
    newData[index].carpentryMaterial =
      newData[index].count * newData[index].materialRate;

    newData[index].carpentryMaterial = customToFixed(
      newData[index].carpentryMaterial,
      2
    );
    props.setCarpentryState(newData);
  };

  const calculateCarpentryHours = (index: number, newData: any) => {
    // const newData = [...props.carpentryRates];
    newData[index].carpentryHours =
      (newData[index].count * newData[index].laborRate) /
      carpentryLaborRateConst;

    newData[index].carpentryHours = customToFixed(
      newData[index].carpentryHours,
      2
    );
    props.setCarpentryState(newData);
  };

  const onActiveChange = (e: any, index: any) => {
    const newData = [...props.carpentryRates];
    newData[index].status = e.target.checked;
    props.setCarpentryState(newData);
  };

  const handleMaterialRate = (e: any, index: number, rowId: string) => {
    const newData = _.cloneDeep(props.carpentryRates);
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    newData[rowIndex].materialRate = e.target.value;
    handleModifiedRate(rowIndex);
    newData[rowIndex].modified = true;
    // props.setCarpentryState(newData);
    calculateCarpentryMaterial(rowIndex, newData);
  };

  const handleLaborRate = (e: any, index: number, rowId: string) => {
    const newData = [...props.carpentryRates];
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    newData[rowIndex].laborRate = e.target.value;
    handleModifiedRate(rowIndex);
    newData[rowIndex].modified = true;
    // props.setCarpentryState(newData);
    calculateCarpentryHours(rowIndex, newData);
  };

  const handleDelete = (id: string) => {
    let project = _.cloneDeep(items[currentProject]);
    const newData = [...props.carpentryRates];
    const optionRate = newData.find((item: any) => item._id === id);
    let optionIndex = project.options.findIndex((option: any) => optionRate.option === option.optionInfo._id)
    project.options[optionIndex].rates.carpentryRates = _.cloneDeep(props.carpentryRates);
    for (const singleOption of project.options) {
      if (singleOption.optionInfo._id === optionRate.option) {
        const optionRateIndex = singleOption.rates.carpentryRates.findIndex(
          (item: any) => item._id === id
        );
        singleOption.rates.carpentryRates[optionRateIndex].isDeleted = true;
        // singleOption.rates.carpentryRates.splice(optionRateIndex, 1);
      }
    }
    let body = {
      category: "carpentry",
      id: id,
    };
    dispatch(removeRatesFromOptions(body, project, adminDefaults));
    const index = newData.findIndex((item: any) => item._id === id);
    newData[index].isDeleted = true;
    // newData.splice(index, 1);
    props.setRatesValue({ carpentryRates: newData });
    props.setCarpentryState(newData);
  };

  const deepEqualForClone = (obj1: any, obj2: any): boolean => {
    // If both are direct values
    console.log("OBJECT 1", obj1, "OBJECT 2", obj2);
    if (obj1 === obj2) {
      console.log("equal");
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects excluding the "_id"
    const keys1 = Object.keys(obj1).filter(
      (key) => !["_id", "projectLaborRate"].includes(key)
    );
    const keys2 = Object.keys(obj2).filter(
      (key) => !["_id", "projectLaborRate"].includes(key)
    );

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      console.log("keys length Not equal");
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqualForClone(obj1[key], obj2[key])) {
        console.log(
          "Check if every key-value pair in obj1 matches that in obj2"
        );
        return false;
      }
    }

    return true;
  };

  const deepEqualCheckForClone = (editedObject: any, oldObjectArray: any) => {
    for (let oldObject of oldObjectArray) {
      const updatedOldObject = {
        ...oldObject,
      };
      if (deepEqualForClone(editedObject, updatedOldObject)) {
        return true;
      }
    }
    return false;
  };

  const deepEqualForCloneInProjectRates = (obj1: any, obj2: any): boolean => {
    // If both are direct values
    console.log("OBJECT 1", obj1, "OBJECT 2", obj2);
    if (obj1 === obj2) {
      console.log("equal");
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects excluding the "_id"
    const keys1 = Object.keys(obj1).filter((key) => !["_id"].includes(key));
    const keys2 = Object.keys(obj2).filter((key) => !["_id"].includes(key));

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      console.log("keys length Not equal");
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqualForClone(obj1[key], obj2[key])) {
        console.log(
          "Check if every key-value pair in obj1 matches that in obj2"
        );
        return false;
      }
    }

    return true;
  };

  const deepEqualCheckForCloneInProjectRates = (
    editedObject: any,
    oldObjectArray: any
  ) => {
    for (let oldObject of oldObjectArray) {
      const updatedOldObject = {
        ...oldObject,
      };
      if (deepEqualForCloneInProjectRates(editedObject, updatedOldObject)) {
        return {
          rate: updatedOldObject,
          result: true,
        };
      }
    }
    return {
      rate: editedObject,
      result: false,
    };
  };

  const handleCarpentryClone = (
    data: any,
    index: number,
    surfaceName: string
  ) => {
    const result = props.carpentryRates.some((obj: any) => {
      let name: string = obj.item;
      if (name.replaceAll(" ", "") === surfaceName[index].replaceAll(" ", "")) {
        return true;
      }
    });
    if (result === true) {
      message.error("Name already taken");
    } else {
      let projectLaborRateId = crypto.randomBytes(12).toString("hex");
      const newRates = {
        ...data,
        item: surfaceName[index],
        project: items[currentProject].projectId,
        projectLaborRate: projectLaborRateId,
        _id: crypto.randomBytes(12).toString("hex"),
        isCloned: true,
      };

      const notDeletedRates = props.carpentryRates.filter(
        (item: any) => item.isDeleted === false
      );

      const isClonedArray = notDeletedRates.filter(
        (item: any) => item.isCloned === true
      );

      const rateAlreadyExists = deepEqualCheckForClone(newRates, isClonedArray);
      if (rateAlreadyExists) {
        message.error("Rate Already Exists");
      } else {
        let project = _.cloneDeep(items[currentProject]);
        let selectedOptionIndex = project.options.findIndex(
          (item: any) => item.optionInfo._id === newRates.option
        );
        project.options[selectedOptionIndex].rates.carpentryRates = props.carpentryRates;
        let newProjectCarpentryRates = [];

        let projectCarpentryRate = {
          item: newRates.item,
          laborRate: newRates.laborRate,
          materialRate: newRates.materialRate,
          project: newRates.project,
          type: newRates.type,
          _id: projectLaborRateId,
          isCloned: true,
          isDeleted: newRates.isDeleted,
          modified: newRates.modified,
          defaultRate: newRates.defaultRate
        };

        const notDeletedProjectRates =
          project.projectRates.carpentryRates.filter(
            (item: any) => item.isDeleted === false
          );

        const isClonedProjectRatesArray = notDeletedProjectRates.filter(
          (item: any) => item.isCloned === true
        );

        const rateAlreadyExistsInProject = deepEqualCheckForCloneInProjectRates(
          newRates,
          isClonedProjectRatesArray
        );

        if (rateAlreadyExistsInProject.result) {
          const foundedRate = rateAlreadyExistsInProject.rate;
          newRates.projectLaborRate = foundedRate._id;
        } else {
          project.projectRates.carpentryRates.push(projectCarpentryRate);
          newProjectCarpentryRates.push(projectCarpentryRate);
        }

        let optionIndex = project.options.findIndex(
          (item: any) => item.optionInfo._id === newRates.option
        );
        project.options[optionIndex].rates.carpentryRates.push(newRates);

        const body = [
          {
            category: "carpentry",
            rate: [newRates],
          },
        ];

        const newData = [...props.carpentryRates];
        newData.push({ ...newRates });
        props.setCarpentryState(newData);

        if (newProjectCarpentryRates.length !== 0) {
          let newBodyForProjectRates = [
            {
              category: "carpentry",
              rate: newProjectCarpentryRates,
            },
          ];
          dispatch(
            addServiceToProject(
              items[currentProject].projectId,
              newBodyForProjectRates
            )
          );
        }
        dispatch(addRatesToOptions(body, project, adminDefaults));
      }
    }
  };

  const handleModifiedRate = (index: number) => {
    const newData = [...props.carpentryRates];
    props.handleCheckModifiedOnEdit(newData[index]);
  };

  const optionCarpentryColumns = [
    {
      title: "Item / Count",
      key: "item",
      width: "150px",
      render: (data: any, record: any, index: number) => {
        return (
          <div
            className="table--editor--holder"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div className="text--with--border">{data.item}</div>
            <Popover
              visible={minusVisible[index]}
              onVisibleChange={(visible: any) =>
                handleMinusVisibleChange(visible, index)
              }
              content={minusContent(index)}
              trigger="click"
            >
              <div
                onClick={(e: any) => handleMinusIndexState(e, index, data._id)}
                className="minus--icon--holder"
              >
                <span>-</span>
              </div>
            </Popover>

            <div
              onClick={() => handleSubtractValue(index, data._id)}
              className="qty--icon--holder"
            >
              <span>{findCount(data?.defaultRate)}</span>
            </div>

            <div className="qty--text--field">
              <Input
                onChange={(e) => {
                  onCountChange(e, index, data._id);
                }}
                value={data.count}
              />
            </div>

            <div
              onClick={() => handleAddValue(index, data._id)}
              className="qty--icon--holder"
            >
              <span>{findCount(data?.defaultRate)}</span>
            </div>
            <Popover
              visible={plusVisible[index]}
              onVisibleChange={(visible: any) =>
                handlePlusVisibleChange(visible, index)
              }
              content={content(index)}
              trigger="click"
            >
              <div
                onClick={(e: any) => handlePlusIndexState(e, index, data._id)}
                className="minus--icon--holder"
              >
                <span>+</span>
              </div>
            </Popover>
          </div>
        );
      },
    },

    {
      title: "Material Rate",
      dataIndex: "materialRate",
      key: "materialRate",
      width: "120px",
      render: (materialRate: any, _: any, index: number) => {
        return (
          <div>
            <Input
              type="number"
              onChange={(e: any) => handleMaterialRate(e, index, _._id)}
              value={materialRate}
              defaultValue={materialRate}
            />
          </div>
        );
      },
    },
    {
      title: "Labor Rate",
      dataIndex: "laborRate",
      key: "laborRate",
      width: "120px",
      render: (laborRate: any, _: any, index: number) => {
        return (
          <div>
            <Input
              type="number"
              onChange={(e: any) => handleLaborRate(e, index, _._id)}
              value={laborRate}
              defaultValue={laborRate}
            />
          </div>
        );
      },
    },
    {
      title: "Carpentry Hours",
      dataIndex: "carpentryHours",
      key: "carpentryHours",
      width: "100px",
      render: (carpentryHours: any) => {
        return (
          <div style={{ marginLeft: 10, marginTop: 0 }}>{carpentryHours}</div>
        );
      },
    },
    {
      title: "Carpentry Material",
      dataIndex: "carpentryMaterial",
      key: "carpentryMaterial",
      width: "100px",
      render: (carpentryMaterial: any) => {
        return (
          <div style={{ marginLeft: 10, marginTop: 0 }}>
            {carpentryMaterial}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "80px",

      render: (data: any, _: any, index: number) => {
        return (
          <div
            className="table--editor--holder"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <ActionMenu
              handleClone={handleCarpentryClone}
              handleDelete={handleDelete}
              data={data}
              index={index}
              category="carpentry"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      {props.carpentryRates?.length > 0 && (
        <Table
          scroll={{ x: 900 }}
          rowKey="_id"
          rowClassName={(_, index) =>
            index % 2 === 0
              ? "table_row table-row-light"
              : "table_row table-row-dark"
          }
          pagination={false}
          columns={optionCarpentryColumns}
          dataSource={props.carpentryRates
            ?.filter((item: any) => !item.isDeleted)
            .sort((a: any, b: any) => a.item.localeCompare(b.item))}
        />
      )}
    </>

  );
};

export default React.memo(Carpentry);
