import React from 'react';
import { Row, Col, Button, Table } from 'antd';
import { columns } from './config';
import '../PaintSurfacesDefaults.less';

type Props = {};

const CarpentrySurfacesDefaults = (props: Props) => {
  const data = new Array(6).fill({
    carpentryItem: 'WR: Deck - 1x2 AC2',
    type: 'EA',
    laborUnit: '$1.41',
    materialUnit: '$0.17',
    action: '',
  });
  return (
    <div style={{ marginTop: 75 }}>
      <Row className="paint-surface-defaults-container" justify="space-between">
        <Col>
          <h1>Carpentry surfaces</h1>
        </Col>
        <Col>
          <Button className="labor-rates-defaults-buttons">
            Add Carpentry Surface
          </Button>
        </Col>
      </Row>
      <Table dataSource={data} columns={columns} pagination={false} />
    </div>
  );
};

export default CarpentrySurfacesDefaults;
