import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Form, Input, Modal, Row, Table } from "antd";
import { useHistory } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";
import axios from "axios";
import { usStates, canadaStates } from "../../constant/index";
import { API_BASE } from "../../constant";
import { EditOutlined } from "@ant-design/icons";

import _ from "lodash";
var crypto = require("crypto");

type Props = {};

const Tenant = (props: Props) => {
  const history = useHistory();
  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  const { online } = useSelector((state: RootStateOrAny) => state.offline);

  const [form] = Form.useForm();
  const [isEdit, isEditSet] = useState(false);
  const [loading, setLoading] = useState(false);

  const [companyInfo, setCompanyInfo] = useState<any>([]);
  const [companyLogo, setCompanyLogo] = useState("");
  const [states, setStates] = useState<any>([...usStates, ...canadaStates]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingCompany, setEditingCompany] = useState<any>(null);

  useEffect(() => {
    axios
      .get(`${API_BASE}tenants`, {
        headers: { Authorization: "Bearer " + user?.accessToken },
      })
      .then((response: any) => {
        console.log(response);
        setCompanyInfo(response.data);
        if (response.data.length !== 0) {
          setCompanyLogo(response.data[0].companyLogo);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const handleAddUser = () => {
    isEditSet(false);
    setIsModalVisible(true);
  };

  const handleViewUsers = (data: any) => {
    history.push({
      pathname: `/super_admin/tenantUsers/${data._id}`,
      state: { companyId: data._id, companyName: data.companyName },
    });
  };
  const createOnCellConfig =
    (onClickHandler: (record: any) => void) => (record: any) => ({
      onClick: () => onClickHandler(record),
      style: { cursor: "pointer" },
    });

  const handleEditUser = (data: any) => {
    setEditingCompany(data);
    isEditSet(true);
    setIsModalVisible(true);
    form.setFieldsValue({
      addDefaults: data.addDefaults,
      tenantName: data.tenantName,
      companyName: data.companyName,
      officePhone: data.officePhone,
      faxPhone: data.faxPhone,
      companyEmail: data.companyEmail,
      companyStreetAddressOne: data.companyStreetAddressOne,
      companyStreetAddressTwo: data.companyStreetAddressTwo,
      companyCity: data.companyCity,
      companyStateProvince: data.companyStateProvince,
      companyPostalCode: data.companyPostalCode,
      companyCountry: data.companyCountry,
      proposalNumber: data.proposalNumber,
      franchiseNumber: data.franchiseNumber,
      contractorLicense: data.contractorLicense,
      stateRegistration: data.stateRegistration,
      workersComp: data.workersComp,
      insurance: data.insurance,
      companyWebsiteUrl: data.companyWebsiteUrl,
      sourceId: data.sourceId,
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values: any) => {
        console.log({
          ...values,
          tenantName: values.companyName
            .trim()
            .toLowerCase()
            .replace(/\s+/g, "-"),
        });
        const requestMethod = isEdit ? axios.put : axios.post;
        const requestUrl = isEdit
          ? `${API_BASE}tenants/${editingCompany._id}`
          : `${API_BASE}tenants`;
        const requestBody = isEdit
          ? {
            _id: editingCompany._id,
            ...values,
          }
          : {
            _id: crypto.randomBytes(12).toString("hex"),
            ...values,
            tenantName: `CPP-${values.franchiseNumber}`,
            // tenantName: values.companyName
            //   .trim()
            //   .toLowerCase()
            //   .replace(/\s+/g, "-"),
          };

        requestMethod(requestUrl, requestBody, {
          headers: { Authorization: "Bearer " + user.accessToken },
        })
          .then((response: any) => {
            console.log(response);
            const prevData = _.cloneDeep(companyInfo);
            const updatedData = isEdit
              ? prevData.map((item: any) =>
                item._id === editingCompany._id ? response.data : item
              )
              : [response.data.response, ...prevData];
            setCompanyInfo(updatedData);
            setCompanyLogo(updatedData[0].companyLogo);
            setIsModalVisible(false);
            form.resetFields();
            setEditingCompany(null);
          })
          .catch((err: any) => {
            console.log(err);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const columns = [
    // {
    //   title: "Company Logo",
    //   dataIndex: "companyLogo",
    //   key: "companyLogo",
    //   width: 150,
    //   onCell: createOnCellConfig(handleViewUsers),

    //   render: (text: any, record: any) => (
    //     <img
    //       src={record.companyLogo}
    //       alt="Company Logo"
    //       style={{ width: "100px", height: "auto" }}
    //     />
    //   ),
    // },
    {
      title: "Tenant Name",
      dataIndex: "tenantName",
      key: "tenantName",
      width: 150,
      onCell: createOnCellConfig(handleViewUsers),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      width: 150,
      onCell: createOnCellConfig(handleViewUsers),
    },
    {
      title: "Street Address 1",
      dataIndex: "companyStreetAddressOne",
      key: "companyStreetAddressOne",
      width: 170,
      onCell: createOnCellConfig(handleViewUsers),
    },
    {
      title: "Street Address 2",
      dataIndex: "companyStreetAddressTwo",
      key: "companyStreetAddressTwo",
      width: 170,
      onCell: createOnCellConfig(handleViewUsers),
    },
    {
      title: "City",
      dataIndex: "companyCity",
      key: "companyCity",
      width: 150,
      onCell: createOnCellConfig(handleViewUsers),
    },
    {
      title: "State",
      dataIndex: "companyStateProvince",
      key: "companyStateProvince",
      width: 150,
      onCell: createOnCellConfig(handleViewUsers),
    },
    {
      title: "Postal Code",
      dataIndex: "companyPostalCode",
      key: "companyPostalCode",
      width: 150,
      onCell: createOnCellConfig(handleViewUsers),
    },
    {
      title: "Country",
      dataIndex: "companyCountry",
      key: "companyCountry",
      width: 150,
      onCell: createOnCellConfig(handleViewUsers),
    },
    {
      title: "Office Phone",
      dataIndex: "officePhone",
      key: "officePhone",
      width: 150,
      onCell: createOnCellConfig(handleViewUsers),
    },
    {
      title: "Website Url",
      dataIndex: "companyWebsiteUrl",
      key: "companyWebsiteUrl",
      width: 150,
      onCell: createOnCellConfig(handleViewUsers),
    },
    {
      title: "Edit",
      width: 150,
      render: (data: any) => (
        <EditOutlined
          onClick={() => handleEditUser(data)}
          style={{ color: "#FDB913", cursor: "pointer", fontSize: "20px" }}
        />
      ),
    },
  ];
  return (
    <>
      <div className="company-root">
        <Row
          className="pp-default-header"
          align="middle"
          justify="space-between"
        >
          <Col>
            <h1>Tenant</h1>
          </Col>
          <Col>
            <Button
              className="pp-add-associates"
              type="primary"
              onClick={handleAddUser}
            >
              Add
            </Button>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #E8E8E8", padding: 0, margin: 0 }} />
        <Table
          columns={columns}
          dataSource={companyInfo}
          rowKey="companyName"
          bordered
        />
        <Modal
          title={
            <span style={{ fontWeight: "bold" }}>
              {isEdit ? "Edit" : "Add"} Tenant
            </span>
          }
          style={{ fontWeight: "bold" }}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={800}
        >
          <Form form={form} layout="vertical">
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Company Name"
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the company name",
                    },
                  ]}
                >
                  <Input disabled={isEdit} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="companyEmail"
                  rules={[
                    { required: true, message: "Please enter the email" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Office Phone"
                  name="officePhone"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the office phone",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Fax Phone"
                  name="faxPhone"
                  rules={[
                    { required: true, message: "Please enter the fax phone" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Street Address 1"
                  name="companyStreetAddressOne"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the street address",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Street Address 2 (Optional)"
                  name="companyStreetAddressTwo"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="City"
                  name="companyCity"
                  rules={[{ required: true, message: "Please enter the city" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="State/Province"
                  name="companyStateProvince"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the state or province",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Postal Code"
                  name="companyPostalCode"
                  rules={[
                    { required: true, message: "Please enter the postal code" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Country"
                  name="companyCountry"
                  rules={[
                    { required: true, message: "Please enter the country" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Proposal Number"
                  name="proposalNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the proposal number",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Franchise Number"
                  name="franchiseNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the franchise number",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Contractor License"
                  name="contractorLicense"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the contractor license",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="State Registration"
                  name="stateRegistration"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the state registration",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Workers Comp"
                  name="workersComp"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the workers comp",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Insurance"
                  name="insurance"
                  rules={[
                    { required: true, message: "Please enter the insurance" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Website Url"
                  name="companyWebsiteUrl"
                  rules={[
                    { required: true, message: "Please enter the website URL" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Source Id"
                  name="sourceId"
                  rules={[
                    { required: true, message: "Please enter the source ID" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="addDefaults" valuePropName="checked">
              <Checkbox disabled={isEdit}>
                Also Add Defaults in this Tenant
              </Checkbox>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default Tenant;
