import React, { useState, useEffect, MouseEvent } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Select,
  Checkbox,
  Card,
  List,
  Input,
  message,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { EyeIcon, RedCrossIcon, SearchIcon } from "../../utils/icons";
import "./SendRequest.less";

import api from "../../utils/api";
import { RootStateOrAny, useSelector } from "react-redux";
import Item from "antd/lib/list/Item";
import { useParams } from "react-router-dom";
import TabPane from "antd/lib/tabs/TabPane";
import _ from "lodash";
const { Option } = Select;

type Props = {
  showModalForEstimate: boolean;
  toggleModal: (item: any) => void;
  surface: string;
  rates?: any;
  addSurface: (
    surfaces: any,
    deletedArray: any,
    collapse: MouseEvent,
    category: string
  ) => void;
  handleDelete?: any;
};

const AddDefaultModifiedSurface = (props: Props) => {
  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const [limit, setLimit] = useState(false);
  const [Category, setCategory] = useState<string>();
  const [modifiedCategory, setModifiedCategory] = useState<string>();

  const [checkboxSearched, setCheckboxSearched] = useState("");
  const [checkboxModifiedSearched, setCheckboxModifiedSearched] = useState("");

  const [checkedList, setCheckedList] = useState<any>([]);

  const [checkedPaint, setCheckedPaint] = useState<any>([]);
  const [checkedCarpentry, setCheckedCarpentry] = useState<any>([]);
  const [checkedEquipment, setCheckedEquipment] = useState<any>([]);
  const [checkedMisc, setCheckedMisc] = useState<any>([]);
  const [checkedTravel, setCheckedTravel] = useState<any>([]);

  const [checkedSurfaces, setCheckedSurfaces] = useState<any>({
    paint: [],
    carpentry: [],
    equipment: [],
    miscellaneous: [],
    travel: [],
  });

  const [checkedModifiedSurfaces, setCheckedModifiedSurfaces] = useState<any>({
    paint: [],
    carpentry: [],
    equipment: [],
    miscellaneous: [],
    travel: [],
  });
  const [surfaceData, setSurfaceData] = useState<any>([]);
  const [modifiedSurfaceData, setModifiedSurfaceData] = useState<any>([]);
  const [deletedRatesArray, setDeletedRatesArray] = useState<any>({
    paint: [],
    carpentry: [],
    equipment: [],
    miscellaneous: [],
    travel: [],
  });
  const [deletedModifiedRatesArray, setDeletedModifiedRatesArray] =
    useState<any>({
      paint: [],
      carpentry: [],
      equipment: [],
      miscellaneous: [],
      travel: [],
    });
  const { id } = useParams<{ id: string }>();

  const filterRates = (rates: any, isModified: boolean) => {
    return rates?.filter(
      (item: any) => (item.modified || item.isCloned) === isModified
    );
  };

  const prepareRates = (rates: any, defaults: any) => {
    delete rates.washRates;
    return Object.keys(rates).reduce((acc: any, key) => {
      const filteredRates = rates[key].filter((item: any) => !item.isDeleted);
      const unmodifiedRates = filterRates(filteredRates, false);

      const modifiedRates = filterRates(rates[key], true);

      // const modifiedRates = filteredRates.filter(
      //   (item: any) => item.isModified
      // );

      const checkedRates = defaults[key].map((defaultItem: any) => {
        const isChecked = unmodifiedRates.some((rate: any) => {
          return rate.defaultRate?._id === defaultItem._id;
        });
        return { ...defaultItem, checked: isChecked };
      });

      const checkedModifiedRates = modifiedRates.map((item: any) => ({
        ...item,
        checked: !item.isDeleted,
      }));

      const modifiedProjectRates = items[currentProject].projectRates[
        key
      ].filter((item: any) => item.modified);
      const updatedCheckedModifiedRates = modifiedProjectRates.map(
        (item: any) => {
          const currentRateIndex = modifiedRates.findIndex(
            (element: any) => element.projectLaborRate === item._id
          );
          if (currentRateIndex === -1) {
            return {
              ...item,
              checked: false,
            };
          } else {
            return {
              ...item,
              checked: !modifiedRates[currentRateIndex].isDeleted,
            };
          }
        }
      );

      acc[key] = {
        original: checkedRates,
        modified: updatedCheckedModifiedRates,
      };

      return acc;
    }, {});
  };

  useEffect(() => {
    if (props.rates) {
      const { rates } = props;
      const preparedRates = prepareRates(rates, adminDefaults?.defaultRates);
      console.log("preparedRates", preparedRates)

      // Set the state for each category
      setCheckedSurfaces({
        paint: preparedRates.paintRates.original,
        carpentry: preparedRates.carpentryRates.original,
        equipment: preparedRates.equipmentRates.original,
        miscellaneous: preparedRates.miscellaneousRates.original,
        travel: preparedRates.travelRates.original,
      });

      setCheckedModifiedSurfaces({
        paint: preparedRates.paintRates.modified,
        carpentry: preparedRates.carpentryRates.modified,
        equipment: preparedRates.equipmentRates.modified,
        miscellaneous: preparedRates.miscellaneousRates.modified,
        travel: preparedRates.travelRates.modified,
      });
    }
  }, [id, props.showModalForEstimate, props.rates]);

  useEffect(() => {
    setCheckedPaint(adminDefaults?.defaultRates?.paintRates);
    setCheckedCarpentry(adminDefaults?.defaultRates.carpentryRates);
    setCheckedEquipment(adminDefaults?.defaultRates?.equipmentRates);
    setCheckedMisc(adminDefaults?.defaultRates?.miscellaneousRates);
    setCheckedTravel(adminDefaults?.defaultRates?.travelRates);
  }, [id, props.showModalForEstimate]);

  useEffect(() => {
    const aggregateCheckedItems = (surfaces: any) => {
      return Object.values(surfaces)
        .flat()
        .filter((item: any) => item.checked);
    };

    setCheckedList([
      ...aggregateCheckedItems(checkedSurfaces),
      ...aggregateCheckedItems(checkedModifiedSurfaces),
    ]);
  }, [checkedSurfaces, checkedModifiedSurfaces, props.showModalForEstimate]);

  //   useEffect(() => {
  //     setCheckedList(
  //       [
  //         ...checkedPaint,
  //         ...checkedCarpentry,
  //         ...checkedEquipment,
  //         ...checkedMisc,
  //         ...checkedTravel,
  //       ].filter((item: any) => item.checked)
  //     );
  //   }, [
  //     checkedPaint,
  //     checkedCarpentry,
  //     checkedEquipment,
  //     checkedMisc,
  //     checkedTravel,
  //     props.showModalForEstimate,
  //   ]);

  useEffect(() => {
    handleCategory(props.surface);
    handleModifiedCategory(props.surface);
  }, [
    props.surface,
    props.rates,
    props.showModalForEstimate,
    // handleBulkDelete(),
  ]);

  const found = surfaceData
    .filter((item: any) => {
      if (!checkboxSearched) {
        return true;
      }
      return item.item.toLowerCase().includes(checkboxSearched.toLowerCase());
    })
    .sort((a: any, b: any) => {
      if (a.checked !== b.checked) {
        return a.checked ? -1 : 1;
      }
      const itemA = a.item.toLowerCase();
      const itemB = b.item.toLowerCase();
      if (itemA < itemB) {
        return -1;
      }
      if (itemA > itemB) {
        return 1;
      }
      return 0;
    });
  const foundModified = modifiedSurfaceData
    .filter((item: any) => {
      if (!checkboxModifiedSearched) {
        return true;
      }
      return item.item
        .toLowerCase()
        .includes(checkboxModifiedSearched.toLowerCase());
    })
    .sort((a: any, b: any) => {
      if (a.checked !== b.checked) {
        return a.checked ? -1 : 1;
      }
      const itemA = a.item.toLowerCase();
      const itemB = b.item.toLowerCase();
      if (itemA < itemB) {
        return -1;
      }
      if (itemA > itemB) {
        return 1;
      }
      return 0;
    });

  const checkIsDeleted = (rates: any, category: string, key: number) => {
    return rates[`${category}Rates`]?.some((e: any) => {
      return e.defaultRate?._id
        ? e.defaultRate?._id === key
        : e.defaultRate === key;
    });
  };

  const handleCategoryChange = (
    category: string,
    e: any,
    key: number,
    rates: any,
    deletedRatesArray: any,
    setDeletedRatesArray: any,
    setCategoryState: any
  ) => {
    // Map the new state based on the checked status
    const newState = surfaceData.map((obj: any) => {
      return obj._id === key
        ? { ...obj, checked: e.target.checked, category }
        : obj;
    });

    // Set the state for the specific category
    setCategoryState((prev: any) => ({
      ...prev,
      [category]: newState,
    }));

    // Check if the item is marked as 'deleted'
    const isDeleted = checkIsDeleted(rates, category, key);

    if (isDeleted) {
      let temp = _.cloneDeep(deletedRatesArray);

      if (!e.target.checked) {
        temp[category].push(key);
        setDeletedRatesArray(temp);
      } else {
        const filteredArr = temp[category].filter((item: any) => item !== key);
        setDeletedRatesArray((prev: any) => ({
          ...prev,
          [category]: filteredArr,
        }));
      }
    }
  };

  const handleCheckbox = (e: CheckboxChangeEvent, key: number) => {
    const newState = surfaceData.map((obj: any) => {
      return obj._id === key
        ? { ...obj, checked: e.target.checked, category: Category }
        : obj;
    });
    switch (Category) {
      case "paint":
        handleCategoryChange(
          "paint",
          e,
          key,
          props.rates,
          deletedRatesArray,
          setDeletedRatesArray,
          setCheckedSurfaces
        );
        // setCheckedSurfaces((prev: any) => ({
        //   ...prev,
        //   paint: newState,
        // }));
        break;
      case "carpentry":
        handleCategoryChange(
          "carpentry",
          e,
          key,
          props.rates,
          deletedRatesArray,
          setDeletedRatesArray,
          setCheckedSurfaces
        );
        // setCheckedCarpentry(newState);
        break;
      case "equipment":
        handleCategoryChange(
          "equipment",
          e,
          key,
          props.rates,
          deletedRatesArray,
          setDeletedRatesArray,
          setCheckedSurfaces
        );
        // setCheckedEquipment(newState);
        break;
      case "miscellaneous":
        handleCategoryChange(
          "miscellaneous",
          e,
          key,
          props.rates,
          deletedRatesArray,
          setDeletedRatesArray,
          setCheckedSurfaces
        );
        // setCheckedMisc(newState);
        break;
      case "travel":
        handleCategoryChange(
          "travel",
          e,
          key,
          props.rates,
          deletedRatesArray,
          setDeletedRatesArray,
          setCheckedSurfaces
        );
        // setCheckedTravel(newState);
        break;
    }
    setSurfaceData(newState);
  };

  const checkModifiedIsDeleted = (
    rates: any,
    category: string,
    key: number
  ) => {
    return rates[`${category}Rates`]?.some((e: any) => {
      return e._id === key;
    });
  };

  const handleModifiedCategoryChange = (
    category: string,
    e: any,
    key: number,
    rates: any,
    deletedRatesArray: any,
    setDeletedRatesArray: any,
    setCategoryState: any
  ) => {
    // Map the new state based on the checked status
    const newState = modifiedSurfaceData.map((obj: any) => {
      return obj._id === key
        ? { ...obj, checked: e.target.checked, category }
        : obj;
    });

    // Set the state for the specific category
    setCategoryState((prev: any) => ({
      ...prev,
      [category]: newState,
    }));

    // Check if the item is marked as 'deleted'
    const isDeleted = checkModifiedIsDeleted(rates, category, key);

    if (isDeleted) {
      let temp = _.cloneDeep(deletedRatesArray);

      if (!e.target.checked) {
        temp[category].push(key);
        setDeletedRatesArray(temp);
      } else {
        const filteredArr = temp[category].filter((item: any) => item !== key);
        setDeletedRatesArray((prev: any) => ({
          ...prev,
          [category]: filteredArr,
        }));
      }
    }
  };

  const handleModifiedCheckbox = (e: CheckboxChangeEvent, key: number) => {
    let newState: any;
    if (e.target.checked) {
      newState = modifiedSurfaceData.map((obj: any) => {
        return obj._id === key
          ? { ...obj, checked: e.target.checked, category: Category }
          : obj;
      });
    } else {
      newState = modifiedSurfaceData.map((obj: any) => {
        return obj._id === key
          ? {
            ...obj,
            checked: e.target.checked,
            category: Category,
            isDeleted: true,
          }
          : obj;
      });
      // temp.push(key);
      // setDeletedModifiedRatesArray(temp);
      // props.handleDelete(key, "paint", true);
    }
    // newState = modifiedSurfaceData.map((obj: any) => {
    //   return obj._id === key
    //     ? { ...obj, checked: e.target.checked, category: Category }
    //     : obj;
    // });
    switch (Category) {
      case "paint":
        handleModifiedCategoryChange(
          "paint",
          e,
          key,
          props.rates,
          deletedModifiedRatesArray,
          setDeletedModifiedRatesArray,
          setCheckedModifiedSurfaces
        );
        // setCheckedModifiedSurfaces((prev: any) => ({
        //   ...prev,
        //   paint: newState,
        // }));
        break;
      case "carpentry":
        handleModifiedCategoryChange(
          "carpentry",
          e,
          key,
          props.rates,
          deletedModifiedRatesArray,
          setDeletedModifiedRatesArray,
          setCheckedModifiedSurfaces
        );
        // setCheckedCarpentry(newState);
        break;
      case "equipment":
        handleModifiedCategoryChange(
          "equipment",
          e,
          key,
          props.rates,
          deletedModifiedRatesArray,
          setDeletedModifiedRatesArray,
          setCheckedModifiedSurfaces
        );
        // setCheckedEquipment(newState);
        break;
      case "miscellaneous":
        handleModifiedCategoryChange(
          "miscellaneous",
          e,
          key,
          props.rates,
          deletedModifiedRatesArray,
          setDeletedModifiedRatesArray,
          setCheckedModifiedSurfaces
        );
        // setCheckedMisc(newState);
        break;
      case "travel":
        handleModifiedCategoryChange(
          "travel",
          e,
          key,
          props.rates,
          deletedModifiedRatesArray,
          setDeletedModifiedRatesArray,
          setCheckedModifiedSurfaces
        );
        // setCheckedTravel(newState);
        break;
    }
    setModifiedSurfaceData(newState);
  };

  const handleDelete = (_: MouseEvent, key: number) => {
    const checked: any = [
      ...checkedPaint,
      ...checkedCarpentry,
      ...checkedEquipment,
      ...checkedMisc,
      ...checkedTravel,
    ];
    const findCheckedObject = checked.find((item: any) => item._id === key);

    const newState = surfaceData.map((obj: any) => {
      return obj._id === key ? { ...obj, checked: false } : obj;
    });

    switch (findCheckedObject?.category) {
      case "paint": {
        const uncheck = checkedPaint.map((obj: any) => {
          return obj._id === key ? { ...obj, checked: false } : obj;
        });
        setCheckedPaint(uncheck);
        break;
      }
      case "carpentry": {
        const uncheck = checkedCarpentry.map((obj: any) => {
          return obj._id === key ? { ...obj, checked: false } : obj;
        });
        setCheckedCarpentry(uncheck);
        break;
      }
      case "equipment": {
        const uncheck = checkedEquipment.map((obj: any) => {
          return obj._id === key ? { ...obj, checked: false } : obj;
        });
        setCheckedEquipment(uncheck);
        break;
      }
      case "miscellaneous": {
        const uncheck = checkedMisc.map((obj: any) => {
          return obj._id === key ? { ...obj, checked: false } : obj;
        });
        setCheckedMisc(uncheck);
        break;
      }
      case "travel": {
        const uncheck = checkedTravel.map((obj: any) => {
          return obj._id === key ? { ...obj, checked: false } : obj;
        });
        setCheckedTravel(uncheck);
        break;
      }
    }
    setLimit(false);
    setSurfaceData(newState);
  };

  const handleSeeMore = () => {
    setLimit(true);
  };

  const checkOldRatesWithAddedSurfaces = (
    type: string,
    checkedSurface: any
  ) => {
    let tempArray = props.rates[type].filter((item: any) => !item.isDeleted);
    const tempRates = tempArray.filter(
      (item: any) => !(item.modified || item.isCloned || item.isDeleted)
    );
    // const tempRates = props.rates[type].filter(
    //   (item: any) => !(item.modified || item.isCloned || item.isDeleted)
    // );
    const newCheckedSurfaces = checkedSurface
      .map((item: any) => {
        let isExists = tempRates?.some((e: any) => {
          return e.defaultRate._id === item._id;
        });
        if (!isExists) {
          return item;
        }
      })
      .filter(Boolean);
    return newCheckedSurfaces;
  };

  const checkOldRatesWithAddedModifiedSurfaces = (
    type: string,
    checkedSurface: any
  ) => {
    const temp = props.rates[type].filter((item: any) => !item.isDeleted);
    const tempRates = temp.filter(
      (item: any) => item.modified || item.isCloned
    );
    const newCheckedSurfaces = checkedSurface
      .map((item: any) => {
        let isExists = tempRates?.some(
          (e: any) => e.projectLaborRate === item._id
        );
        if (!isExists) {
          return item;
        }
      })
      .filter(Boolean);
    return newCheckedSurfaces;
  };

  const handleAddSurface = (collapse: MouseEvent) => {
    const surfaces = surfaceData.filter((item: any) => item.checked);
    const modifiedSurfaces = modifiedSurfaceData.filter(
      (item: any) => item.checked
    );
    if (!Category || (surfaces.length < 1 && modifiedSurfaces.length < 1)) {
      message.warn("Please Select");
    } else {
      const payload = [
        {
          category: "paint",
          rate: checkOldRatesWithAddedSurfaces(
            "paintRates",
            checkedSurfaces?.paint.filter((item: any) => item.checked)
          ),
        },
        {
          category: "carpentry",
          rate: checkOldRatesWithAddedSurfaces(
            "carpentryRates",
            checkedSurfaces?.carpentry.filter((item: any) => item.checked)
          ),
        },
        {
          category: "equipment",
          rate: checkOldRatesWithAddedSurfaces(
            "equipmentRates",
            checkedSurfaces?.equipment.filter((item: any) => item.checked)
          ),
        },
        {
          category: "travel",
          rate: checkOldRatesWithAddedSurfaces(
            "travelRates",
            checkedSurfaces?.travel.filter((item: any) => item.checked)
          ),
        },
        {
          category: "miscellaneous",
          rate: checkOldRatesWithAddedSurfaces(
            "miscellaneousRates",
            checkedSurfaces?.miscellaneous.filter((item: any) => item.checked)
          ),
        },
      ];

      const payloadModified = [
        {
          category: "paint",
          rate: checkOldRatesWithAddedModifiedSurfaces(
            "paintRates",
            checkedModifiedSurfaces?.paint.filter((item: any) => item.checked)
          ),
        },
        {
          category: "carpentry",
          rate: checkOldRatesWithAddedModifiedSurfaces(
            "carpentryRates",
            checkedModifiedSurfaces?.carpentry.filter(
              (item: any) => item.checked
            )
          ),
        },
        {
          category: "equipment",
          rate: checkOldRatesWithAddedModifiedSurfaces(
            "equipmentRates",
            checkedModifiedSurfaces?.equipment.filter(
              (item: any) => item.checked
            )
          ),
        },
        {
          category: "travel",
          rate: checkOldRatesWithAddedModifiedSurfaces(
            "travelRates",
            checkedModifiedSurfaces?.travel.filter((item: any) => item.checked)
          ),
        },
        {
          category: "miscellaneous",
          rate: checkOldRatesWithAddedModifiedSurfaces(
            "miscellaneousRates",
            checkedModifiedSurfaces?.miscellaneous.filter(
              (item: any) => item.checked
            )
          ),
        },
      ];

      let body = {
        defaults: payload,
        modified: payloadModified,
      };

      const deletePayload = [
        {
          category: "paint",
          ratesId: deletedRatesArray.paint,
        },
        {
          category: "carpentry",
          ratesId: deletedRatesArray.carpentry,
        },
        {
          category: "equipment",
          ratesId: deletedRatesArray.equipment,
        },
        {
          category: "travel",
          ratesId: deletedRatesArray.travel,
        },
        {
          category: "miscellaneous",
          ratesId: deletedRatesArray.miscellaneous,
        },
      ];

      const deletePayloadModified = [
        {
          category: "paint",
          ratesId: deletedModifiedRatesArray.paint,
        },
        {
          category: "carpentry",
          ratesId: deletedModifiedRatesArray.carpentry,
        },
        {
          category: "equipment",
          ratesId: deletedModifiedRatesArray.equipment,
        },
        {
          category: "travel",
          ratesId: deletedModifiedRatesArray.travel,
        },
        {
          category: "miscellaneous",
          ratesId: deletedModifiedRatesArray.miscellaneous,
        },
      ];

      let deleteBody = {
        defaults: deletePayload,
        modified: deletePayloadModified,
      };

      props.addSurface(body, deleteBody, collapse, Category);

      setCheckedPaint(removeChecked(checkedPaint));
      setCheckedEquipment(removeChecked(checkedEquipment));
      setCheckedCarpentry(removeChecked(checkedCarpentry));
      setCheckedMisc(removeChecked(checkedMisc));
      setCheckedTravel(removeChecked(checkedTravel));
      setCheckedList([]);
    }
  };

  const removeChecked = (items: any) => {
    return items.map((item: any) => {
      const { checked, ...rest } = item;
      return rest;
    });
  };

  const addChecked = (data: any, type: string) => {
    let temp: any = [];
    if (data) {
      temp = data.filter((item: any) => {
        return !props.rates[type]?.find((e: any) => {
          return e.defaultRate?._id === item._id;
        });
      });
    }

    return temp;
  };

  const handleCategory = (value: string) => {
    setCategory(value);
    switch (value) {
      case "paint":
        setSurfaceData(checkedSurfaces.paint);
        break;
      case "carpentry":
        setSurfaceData(checkedSurfaces.carpentry);
        break;
      case "equipment":
        setSurfaceData(checkedSurfaces.equipment);
        break;
      case "travel":
        setSurfaceData(checkedSurfaces.travel);
        break;
      case "miscellaneous":
        setSurfaceData(checkedSurfaces.miscellaneous);
        break;
      default:
        break;
    }
  };

  const handleModifiedCategory = (value: string) => {
    setModifiedCategory(value);
    switch (value) {
      case "paint":
        setModifiedSurfaceData(checkedModifiedSurfaces.paint);
        break;
      case "carpentry":
        setModifiedSurfaceData(checkedModifiedSurfaces.carpentry);
        break;
      case "equipment":
        setModifiedSurfaceData(checkedModifiedSurfaces.equipment);
        break;
      case "travel":
        setModifiedSurfaceData(checkedModifiedSurfaces.travel);
        break;
      case "miscellaneous":
        setModifiedSurfaceData(checkedModifiedSurfaces.miscellaneous);
        break;
      default:
        break;
    }
  };

  const handleBulkDelete = () => {
    // setCheckedSurfaces(checkedSurfaces.map((item: any)=> {return {...item, checked: false}}))
    setCheckedSurfaces(
      Object.fromEntries(
        Object.entries(checkedSurfaces).map(([key, value]) => [
          key,
          (value as Array<any>).map((item: any) => ({
            ...item,
            checked: false,
          })),
        ])
      )
    );
    setCheckedModifiedSurfaces(
      Object.fromEntries(
        Object.entries(checkedModifiedSurfaces).map(([key, value]) => [
          key,
          (value as Array<any>).map((item: any) => ({
            ...item,
            checked: false,
          })),
        ])
      )
    );
    handleCategory(props.surface);
    handleModifiedCategory(props.surface);
  };

  const exitsInOptions = (record: any) => {
    const options = items[currentProject].options;
    let listOfOptions = [];
    for (const singleOption of options) {
      switch (Category) {
        case "paint":
          const isFoundPaint = singleOption.rates.paintRates.some(
            (item: any) =>
              item.projectLaborRate === record._id && !item.isDeleted
          );
          if (isFoundPaint) {
            listOfOptions.push(singleOption.optionInfo.title);
          }
          break;
        case "carpentry":
          const isFoundCarpentry = singleOption.rates.carpentryRates.some(
            (item: any) =>
              item.projectLaborRate === record._id && !item.isDeleted
          );
          if (isFoundCarpentry) {
            listOfOptions.push(singleOption.optionInfo.title);
          }
          break;
        case "equipment":
          const isFoundEquipment = singleOption.rates.equipmentRates.some(
            (item: any) =>
              item.projectLaborRate === record._id && !item.isDeleted
          );
          if (isFoundEquipment) {
            listOfOptions.push(singleOption.optionInfo.title);
          }
          break;
        case "miscellaneous":
          const isFoundMiscellaneous =
            singleOption.rates.miscellaneousRates.some(
              (item: any) =>
                item.projectLaborRate === record._id && !item.isDeleted
            );
          if (isFoundMiscellaneous) {
            listOfOptions.push(singleOption.optionInfo.title);
          }
          break;
        case "travel":
          const isFoundTravel = singleOption.rates.travelRates.some(
            (item: any) =>
              item.projectLaborRate === record._id && !item.isDeleted
          );
          if (isFoundTravel) {
            listOfOptions.push(singleOption.optionInfo.title);
          }
          break;
      }
    }
    return listOfOptions;
  };

  const exitsInOptionsForDefaultRates = (record: any) => {
    const options = items[currentProject].options;
    let listOfOptions = [];
    for (const singleOption of options) {
      switch (Category) {
        case "paint":
          const isFoundPaint = singleOption.rates.paintRates.some(
            (item: any) => item._id === record._id && !item.isDeleted
          );
          if (isFoundPaint) {
            listOfOptions.push(singleOption.optionInfo.title);
          }
          break;
        case "carpentry":
          const isFoundCarpentry = singleOption.rates.carpentryRates.some(
            (item: any) => item._id === record._id && !item.isDeleted
          );
          if (isFoundCarpentry) {
            listOfOptions.push(singleOption.optionInfo.title);
          }
          break;
        case "equipment":
          const isFoundEquipment = singleOption.rates.equipmentRates.some(
            (item: any) => item._id === record._id && !item.isDeleted
          );
          if (isFoundEquipment) {
            listOfOptions.push(singleOption.optionInfo.title);
          }
          break;
        case "miscellaneous":
          const isFoundMiscellaneous =
            singleOption.rates.miscellaneousRates.some(
              (item: any) => item._id === record._id && !item.isDeleted
            );
          if (isFoundMiscellaneous) {
            listOfOptions.push(singleOption.optionInfo.title);
          }
          break;
        case "travel":
          const isFoundTravel = singleOption.rates.travelRates.some(
            (item: any) => item._id === record._id && !item.isDeleted
          );
          if (isFoundTravel) {
            listOfOptions.push(singleOption.optionInfo.title);
          }
          break;
      }
    }
    return listOfOptions;
  };

  const handleModifiedPaintRateToolTip = (rate: any) => {
    const listOfOptions = exitsInOptions(rate);
    return (
      <>
        <table>
          <tr>
            <td style={{ padding: 10 }}>Type</td>
            <td style={{ padding: 10 }}>Paint Labor</td>
            <td style={{ padding: 10 }}>Prep Labor</td>
            <td style={{ padding: 10 }}>Prime Labor</td>
            <td style={{ padding: 10 }}>Paint</td>
            <td style={{ padding: 10 }}>Prime</td>
            <td style={{ padding: 10 }}>Paint Material</td>
            <td style={{ padding: 10 }}>Primer Material</td>
          </tr>
          <tr>
            <td style={{ padding: 10 }}>{rate.type}</td>
            <td style={{ padding: 10 }}>{rate.paintLaborRate}</td>
            <td style={{ padding: 10 }}>{rate.prepLaborRate}</td>
            <td style={{ padding: 10 }}>{rate.primeLaborRate}</td>
            <td style={{ padding: 10 }}>{rate.paintSpreadRate}</td>
            <td style={{ padding: 10 }}>{rate.primerSpreadRate}</td>
            <td style={{ padding: 10 }}>
              {rate.paintMaterial?.manufacturer}:{rate.paintMaterial?.product}
            </td>
            <td style={{ padding: 10 }}>
              {rate.primerMaterial?.manufacturer}:{rate.primerMaterial?.product}
            </td>
          </tr>
        </table>
        <p>{listOfOptions.join(", ")}</p>
      </>
    );
  };

  const handleModifiedCarpentryRateToolTip = (rate: any) => {
    const listOfOptions = exitsInOptions(rate);
    return (
      <>
        <table>
          <tr>
            <td style={{ padding: 10 }}>Type</td>
            <td style={{ padding: 10 }}>Labor/Unit</td>
            <td style={{ padding: 10 }}>Material/Unit</td>
          </tr>
          <tr>
            <td style={{ padding: 10 }}>{rate.type}</td>
            <td style={{ padding: 10 }}>{rate.laborRate}</td>
            <td style={{ padding: 10 }}>{rate.materialRate}</td>
          </tr>
        </table>
        <p>{listOfOptions.join(", ")}</p>
      </>
    );
  };

  const handleModifiedEquipmentRateToolTip = (rate: any) => {
    const listOfOptions = exitsInOptions(rate);
    return (
      <>
        <table>
          <tr>
            <td style={{ padding: 10 }}>Type</td>
            <td style={{ padding: 10 }}>Cost</td>
            <td style={{ padding: 10 }}>Delivery</td>
            <td style={{ padding: 10 }}>Surcharges</td>
            <td style={{ padding: 10 }}>Tax %</td>
          </tr>
          <tr>
            <td style={{ padding: 10 }}>
              {rate.hasIntervals ? "daily" : "other"}
            </td>
            <td style={{ padding: 10 }}>{rate.dailyCost}</td>
            <td style={{ padding: 10 }}>{rate.surcharges}</td>
            <td style={{ padding: 10 }}>{rate.puAndDelivery}</td>
            <td style={{ padding: 10 }}>{rate.tax}</td>
          </tr>
        </table>
        <p>{listOfOptions.join(", ")}</p>
      </>
    );
  };

  const handleModifiedMiscRateToolTip = (rate: any) => {
    const listOfOptions = exitsInOptions(rate);
    return (
      <>
        <table>
          <tr>
            <td style={{ padding: 10 }}>Cost/Unit</td>
          </tr>
          <tr>
            <td style={{ padding: 10 }}>{rate.cost}</td>
          </tr>
        </table>
        <p>{listOfOptions.join(", ")}</p>
      </>
    );
  };

  const handleModifiedTravelRateToolTip = (rate: any) => {
    const listOfOptions = exitsInOptions(rate);
    return (
      <>
        <table>
          <tr>
            <td style={{ padding: 10 }}>Cost Per</td>
          </tr>
          <tr>
            <td style={{ padding: 10 }}>{rate.cost}</td>
          </tr>
        </table>
        <p>{listOfOptions.join(", ")}</p>
      </>
    );
  };

  const handleModifiedToolTipString = (rate: any) => {
    switch (Category) {
      case "paint":
        return handleModifiedPaintRateToolTip(rate);
      case "carpentry":
        return handleModifiedCarpentryRateToolTip(rate);
      case "equipment":
        return handleModifiedEquipmentRateToolTip(rate);
      case "travel":
        return handleModifiedTravelRateToolTip(rate);
      case "miscellaneous":
        return handleModifiedMiscRateToolTip(rate);
      default:
        break;
    }
  };

  const handlePaintRateToolTip = (rate: any) => {
    let notDeletedRates = props.rates.paintRates.filter(
      (item: any) => !item.isDeleted
    );
    if (rate.checked) {
      let existingRate = notDeletedRates.find((item: any) => {
        if (typeof item.defaultRate === "string") {
          return item.defaultRate === rate._id;
        } else {
          return item.defaultRate && item.defaultRate?._id === rate._id;
        }
      });
      if (existingRate) {
        const listOfOptions = exitsInOptionsForDefaultRates(existingRate);
        return (
          <>
            <table>
              <tr>
                <td style={{ padding: 10 }}>Type</td>
                <td style={{ padding: 10 }}>Paint Labor</td>
                <td style={{ padding: 10 }}>Prep Labor</td>
                <td style={{ padding: 10 }}>Prime Labor</td>
                <td style={{ padding: 10 }}>Paint</td>
                <td style={{ padding: 10 }}>Prime</td>
                <td style={{ padding: 10 }}>Paint Material</td>
                <td style={{ padding: 10 }}>Primer Material</td>
              </tr>
              <tr>
                <td style={{ padding: 10 }}>{existingRate.type}</td>
                <td style={{ padding: 10 }}>{existingRate.paintLaborRate}</td>
                <td style={{ padding: 10 }}>{existingRate.prepLaborRate}</td>
                <td style={{ padding: 10 }}>{existingRate.primeLaborRate}</td>
                <td style={{ padding: 10 }}>{existingRate.paintSpreadRate}</td>
                <td style={{ padding: 10 }}>{existingRate.primerSpreadRate}</td>
                <td style={{ padding: 10 }}>
                  {existingRate.paintMaterial?.manufacturer}:
                  {existingRate.paintMaterial?.product}
                </td>
                <td style={{ padding: 10 }}>
                  {existingRate.primerMaterial?.manufacturer}:
                  {existingRate.primerMaterial?.product}
                </td>
              </tr>
            </table>

            <p>{listOfOptions.join(", ")}</p>
          </>
        );
      }
    }
    return (
      <>
        <table>
          <tr>
            <td style={{ padding: 10 }}>Type</td>
            <td style={{ padding: 10 }}>Paint Labor</td>
            <td style={{ padding: 10 }}>Prep Labor</td>
            <td style={{ padding: 10 }}>Prime Labor</td>
            <td style={{ padding: 10 }}>Paint</td>
            <td style={{ padding: 10 }}>Prime</td>
            <td style={{ padding: 10 }}>Paint Material</td>
            <td style={{ padding: 10 }}>Primer Material</td>
          </tr>
          <tr>
            <td style={{ padding: 10 }}>{rate.type}</td>
            <td style={{ padding: 10 }}>{rate.paintLaborMedium}</td>
            <td style={{ padding: 10 }}>{rate.prepLaborMedium}</td>
            <td style={{ padding: 10 }}>{rate.primeLaborMedium}</td>
            <td style={{ padding: 10 }}>{rate.paintSpreadRate}</td>
            <td style={{ padding: 10 }}>{rate.primerSpreadRate}</td>
            <td style={{ padding: 10 }}>
              {rate.paintMaterial?.manufacturer}:{rate.paintMaterial?.product}
            </td>
            <td style={{ padding: 10 }}>
              {rate.primerMaterial?.manufacturer}:{rate.primerMaterial?.product}
            </td>
          </tr>
        </table>

        {/* <p>{listOfOptions.join(", ")}</p> */}
      </>
    );
  };

  const handleCarpentryRateToolTip = (rate: any) => {
    let notDeletedRates = props.rates.carpentryRates.filter(
      (item: any) => !item.isDeleted
    );
    if (rate.checked) {
      let existingRate = notDeletedRates.find((item: any) => {
        if (typeof item.defaultRate === "string") {
          return item.defaultRate === rate._id;
        } else {
          return item.defaultRate && item.defaultRate?._id === rate._id;
        }
      });
      if (existingRate) {
        const listOfOptions = exitsInOptionsForDefaultRates(existingRate);
        return (
          <>
            <table>
              <tr>
                <td style={{ padding: 10 }}>Type</td>
                <td style={{ padding: 10 }}>Labor/Unit</td>
                <td style={{ padding: 10 }}>Material/Unit</td>
              </tr>
              <tr>
                <td style={{ padding: 10 }}>{existingRate.type}</td>
                <td style={{ padding: 10 }}>{existingRate.laborRate}</td>
                <td style={{ padding: 10 }}>{existingRate.materialRate}</td>
              </tr>
            </table>

            <p>{listOfOptions.join(", ")}</p>
          </>
        );
      }
    }
    return (
      <>
        <table>
          <tr>
            <td style={{ padding: 10 }}>Type</td>
            <td style={{ padding: 10 }}>Labor/Unit</td>
            <td style={{ padding: 10 }}>Material/Unit</td>
          </tr>
          <tr>
            <td style={{ padding: 10 }}>{rate.type}</td>
            <td style={{ padding: 10 }}>{rate.laborRate}</td>
            <td style={{ padding: 10 }}>{rate.materialRate}</td>
          </tr>
        </table>

        {/* <p>{listOfOptions.join(", ")}</p> */}
      </>
    );
  };

  const handleEquipmentRateToolTip = (rate: any) => {
    let notDeletedRates = props.rates.equipmentRates.filter(
      (item: any) => !item.isDeleted
    );
    if (rate.checked) {
      let existingRate = notDeletedRates.find((item: any) => {
        if (typeof item.defaultRate === "string") {
          return item.defaultRate === rate._id;
        } else {
          return item.defaultRate && item.defaultRate?._id === rate._id;
        }
      });
      if (existingRate) {
        const listOfOptions = exitsInOptionsForDefaultRates(existingRate);
        return (
          <>
            <table>
              <tr>
                <td style={{ padding: 10 }}>Type</td>
                <td style={{ padding: 10 }}>Cost</td>
                <td style={{ padding: 10 }}>Delivery</td>
                <td style={{ padding: 10 }}>Surcharges</td>
                <td style={{ padding: 10 }}>Tax %</td>
              </tr>
              <tr>
                <td style={{ padding: 10 }}>
                  {existingRate.hasIntervals ? "daily" : "other"}
                </td>
                <td style={{ padding: 10 }}>{existingRate.dailyCost}</td>
                <td style={{ padding: 10 }}>{existingRate.surcharges}</td>
                <td style={{ padding: 10 }}>{existingRate.puAndDelivery}</td>
                <td style={{ padding: 10 }}>{existingRate.tax}</td>
              </tr>
            </table>

            <p>{listOfOptions.join(", ")}</p>
          </>
        );
      }
    }
    return (
      <>
        <table>
          <tr>
            <td style={{ padding: 10 }}>Type</td>
            <td style={{ padding: 10 }}>Cost</td>
            <td style={{ padding: 10 }}>Delivery</td>
            <td style={{ padding: 10 }}>Surcharges</td>
            <td style={{ padding: 10 }}>Tax %</td>
          </tr>
          <tr>
            <td style={{ padding: 10 }}>
              {rate.hasIntervals ? "daily" : "other"}
            </td>
            <td style={{ padding: 10 }}>{rate.dailyCost}</td>
            <td style={{ padding: 10 }}>{rate.surcharges}</td>
            <td style={{ padding: 10 }}>{rate.puAndDelivery}</td>
            <td style={{ padding: 10 }}>{rate.tax}</td>
          </tr>
        </table>

        {/* <p>{listOfOptions.join(", ")}</p> */}
      </>
    );
  };

  const handleTravelRateToolTip = (rate: any) => {
    let notDeletedRates = props.rates.travelRates.filter(
      (item: any) => !item.isDeleted
    );
    if (rate.checked) {
      let existingRate = notDeletedRates.find((item: any) => {
        if (typeof item.defaultRate === "string") {
          return item.defaultRate === rate._id;
        } else {
          return item.defaultRate && item.defaultRate?._id === rate._id;
        }
      });
      if (existingRate) {
        const listOfOptions = exitsInOptionsForDefaultRates(existingRate);
        return (
          <>
            <table>
              <tr>
                <td style={{ padding: 10 }}>Cost Per</td>
              </tr>
              <tr>
                <td style={{ padding: 10 }}>{existingRate.cost}</td>
              </tr>
            </table>

            <p>{listOfOptions.join(", ")}</p>
          </>
        );
      }
    }
    return (
      <>
        <table>
          <tr>
            <td style={{ padding: 10 }}>Cost Per</td>
          </tr>
          <tr>
            <td style={{ padding: 10 }}>{rate.cost}</td>
          </tr>
        </table>

        {/* <p>{listOfOptions.join(", ")}</p> */}
      </>
    );
  };

  const handleMiscRateToolTip = (rate: any) => {
    let notDeletedRates = props.rates.miscellaneousRates.filter(
      (item: any) => !item.isDeleted
    );
    if (rate.checked) {
      let existingRate = notDeletedRates.find((item: any) => {
        if (typeof item.defaultRate === "string") {
          return item.defaultRate === rate._id;
        } else {
          return item.defaultRate && item.defaultRate?._id === rate._id;
        }
      });
      if (existingRate) {
        const listOfOptions = exitsInOptionsForDefaultRates(existingRate);
        return (
          <>
            <table>
              <tr>
                <td style={{ padding: 10 }}>Cost/Unit</td>
              </tr>
              <tr>
                <td style={{ padding: 10 }}>{existingRate.cost}</td>
              </tr>
            </table>

            <p>{listOfOptions.join(", ")}</p>
          </>
        );
      }
    }
    return (
      <>
        <table>
          <tr>
            <td style={{ padding: 10 }}>Cost/Unit</td>
          </tr>
          <tr>
            <td style={{ padding: 10 }}>{rate.cost}</td>
          </tr>
        </table>

        {/* <p>{listOfOptions.join(", ")}</p> */}
      </>
    );
  };

  const handleToolTipString = (rate: any) => {
    switch (Category) {
      case "paint":
        return handlePaintRateToolTip(rate);

      case "carpentry":
        return handleCarpentryRateToolTip(rate);

      case "equipment":
        return handleEquipmentRateToolTip(rate);

      case "travel":
        return handleTravelRateToolTip(rate);

      case "miscellaneous":
        return handleMiscRateToolTip(rate);

      default:
        break;
    }
  };

  return (
    <Modal
      className="surface-modal"
      centered={true}
      visible={props.showModalForEstimate}
      onOk={props.toggleModal}
      onCancel={props.toggleModal}
      title={
        <h1 style={{ marginTop: 10 }} className="send-title-text">
          Required Items / services
        </h1>
      }
      footer={[
        <div key="footer" className="send-btn-container">
          <Button onClick={props.toggleModal} className="cancel-btn">
            Cancel
          </Button>

          <Button
            type="primary"
            className="send-btn"
            onClick={handleAddSurface}
          >
            Apply
          </Button>
        </div>,
      ]}
    >
      <Row justify="center">
        <Col span={18}>
          <h3 className="send-req-input-title">Select Category</h3>
          <div className="labor-rates-select-height">
            <Select
              placeholder="Select"
              style={{ width: "100%", marginBottom: 20 }}
              onChange={(e) => {
                handleCategory(e);
                handleModifiedCategory(e);
              }}
              value={Category}
            >
              <Option value="paint">Paint</Option>
              <Option value="carpentry">Carpentry</Option>
              <Option value="equipment">Equipment</Option>
              <Option value="miscellaneous">Misc</Option>
              <Option value="travel">Travel</Option>
            </Select>
          </div>

          <div className="surface-container">
            <h3 className="send-req-input-title">Select Surface</h3>

            <Tabs defaultActiveKey="Default" tabBarStyle={{ marginBottom: 0 }}>
              <TabPane tab="Default" key="Default">
                <Card className="checkbox-card">
                  <Input
                    className="search-surface-input"
                    prefix={<SearchIcon />}
                    placeholder="Search surfaces"
                    onChange={(e) => {
                      setCheckboxSearched(e.target.value);
                    }}
                  />

                  <div className="checkbox-scroll-bar">
                    <List
                      bordered
                      style={{ height: 120 }}
                      dataSource={found}
                      rowKey="_id"
                      renderItem={(item: any, index: number) => (
                        <React.Fragment key={item._id}>
                          <List.Item className="surface-list-container">
                            <Checkbox
                              checked={item.checked}
                              onChange={(e) => {
                                handleCheckbox(e, item._id);
                              }}
                            >
                              <h1 className="surface-checkbox-text ">
                                {item.item}
                              </h1>
                            </Checkbox>
                            <Tooltip
                              placement="top"
                              title={() => handleToolTipString(item)}
                              overlayStyle={{
                                maxWidth: "80%",
                              }}
                              showArrow={false}
                            >
                              <EyeIcon
                                style={{
                                  height: 20,
                                  width: 25,
                                  cursor: "pointer",
                                  marginTop: -5,
                                  marginRight: 10,
                                }}
                              />
                            </Tooltip>
                          </List.Item>
                        </React.Fragment>
                      )}
                    />
                  </div>
                </Card>
              </TabPane>
              <TabPane tab="Modified" key="Modified">
                <Card className="checkbox-card">
                  <Input
                    className="search-surface-input"
                    prefix={<SearchIcon />}
                    placeholder="Search surfaces"
                    onChange={(e) => {
                      setCheckboxModifiedSearched(e.target.value);
                    }}
                  />

                  <div className="checkbox-scroll-bar">
                    <List
                      bordered
                      style={{ height: 120 }}
                      dataSource={foundModified}
                      rowKey="_id"
                      renderItem={(item: any, index: number) => (
                        <React.Fragment key={item._id}>
                          <List.Item className="surface-list-container">
                            <Checkbox
                              checked={item.checked}
                              onChange={(e) => {
                                handleModifiedCheckbox(e, item._id);
                              }}
                            >
                              <h1 className="surface-checkbox-text ">
                                {item.item}
                              </h1>
                            </Checkbox>
                            <Tooltip
                              placement="top"
                              title={() => handleModifiedToolTipString(item)}
                              overlayStyle={{
                                maxWidth: "80%",
                              }}
                              showArrow={false}
                            >
                              <EyeIcon
                                style={{
                                  height: 20,
                                  width: 25,
                                  cursor: "pointer",
                                  marginTop: -5,
                                  marginRight: 10,
                                }}
                              />
                            </Tooltip>
                          </List.Item>
                        </React.Fragment>
                      )}
                    />
                  </div>
                </Card>
              </TabPane>
            </Tabs>

            {/* <Card className="checkbox-card">
              <Input
                className="search-surface-input"
                prefix={<SearchIcon />}
                placeholder="Search surfaces"
                onChange={(e) => {
                  setCheckboxSearched(e.target.value);
                }}
              />

              <div className="checkbox-scroll-bar">
                <List
                  bordered
                  style={{ height: 120 }}
                  dataSource={found}
                  rowKey="_id"
                  renderItem={(item: any, index: number) => (
                    <React.Fragment key={item._id}>
                      <List.Item className="surface-list-container">
                        <Checkbox
                          checked={item.checked}
                          onChange={(e) => {
                            handleCheckbox(e, item._id);
                          }}
                        >
                          <h1 className="surface-checkbox-text ">
                            {item.item}
                          </h1>
                        </Checkbox>
                      </List.Item>
                    </React.Fragment>
                  )}
                />
              </div>
            </Card> */}
            <Row gutter={20}>
              {checkedList
                .slice(0, !limit ? 5 : checkedList.length)
                .map((data: any) => {
                  return (
                    <Col key={data._id} className="selected-checkbox">
                      <h2 className="checkbox-tag-text">{data.item}</h2>
                      {/* <RedCrossIcon
                        style={{ cursor: "pointer" }}
                        onClick={(e: MouseEvent) => {
                          handleDelete(e, data._id);
                        }}
                      /> */}
                    </Col>
                  );
                })}
              {!limit && checkedList.length > 5 && (
                <div onClick={handleSeeMore} className="see-more-container">
                  <p className="show-more-text">
                    +{checkedList.length - 5} more
                  </p>
                </div>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default AddDefaultModifiedSurface;
