export const columns = [
  {
    title: "Carpentry Item",
    dataIndex: "item",
    render: (data: string) => {
      return <p>{data}</p>;
    },
  },
  {
    title: "Quantity",
    dataIndex: "count",
    render: (data: string) => {
      return <p>{data}</p>;
    },
  },
];
