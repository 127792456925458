import { Col, Row, Button } from 'antd';
import React, { useState } from 'react';
import DefaultsInteriorModal from '../../commercial/pop-ups/DefaultsInteriorModal';
import './DefaultsInterior.less';

type Props = {};

const DefaultsInterior = (props: Props) => {
  const [showDefaultsInteriorModal, setShowDefaultsInteriorModal] =
    useState(false);
  const handleAdd = () => {
    !showDefaultsInteriorModal
      ? setShowDefaultsInteriorModal(true)
      : setShowDefaultsInteriorModal(false);
  };
  const toggleDefaultIntModal = (collapse: boolean) => {
    !showDefaultsInteriorModal
      ? setShowDefaultsInteriorModal(true)
      : setShowDefaultsInteriorModal(false);
  };
  return (
    <div className="defaults-interior-root">
      <DefaultsInteriorModal
        title="interior"
        toggleDefaultIntModal={toggleDefaultIntModal}
        showDefaultsInteriorModal={showDefaultsInteriorModal}
      />
      <Row justify="space-between">
        <Col>
          <h1>Defaults (interior)</h1>
        </Col>
        <Col>
          <Button
            onClick={handleAdd}
            className="defaults-interior-add-btn"
            type="link"
          >
            + Add
          </Button>
        </Col>
      </Row>
      <div style={{ marginTop: 25 }}>
        <h2>2nd Coat Option</h2>
        <p>
          If an additional coat is necessary to ensure proper coverage, or if a
          color or sheen change is desired, the surfaces listed under “Include”
          at the top of the proposal will receive a second finish coat.
        </p>
      </div>
      <div style={{ marginTop: 25 }}>
        <h2>Current Emerald Promo</h2>
        <p>
          "Revolutionary technology with best-in-class overall performance.
          Sherwin Williams’ finest exterior paint, Emerald Exterior Acrylic
          Latex Paint is self-priming with a smooth beautiful finish and
          provides exceptional hide. Drying nearly twice as thick as standard
          paints, Emerald offers exceptional durability, a mildew-resistant
          coating and delivers extreme resistance to blistering, peeling, and
          color fade. Cost to Upgrade = $51.2823341509435 **Extends CertaPro’s
          warranty from 2-years to 3-years**"
        </p>
      </div>
      <div style={{ marginTop: 25 }}>
        <h2>PPG Permanizer Paint Upgrade</h2>
        <p>
          Manor Hall Timeless offers the most exciting technological
          advancements available in the Super Premium Exterior Paint category.
          The crosslinking 100% acrylic resin provides a durable bond in one
          amazing coat. Self-priming, high-film build, bridging of cracks, low
          temperature application and providing a mildew/algae-resistant paint
          film are just the beginning of this product’s exciting features.
        </p>
      </div>
      <div style={{ marginTop: 25 }}>
        <h2>Free Resilience Upgrade</h2>
        <p>
          CertaPro will use Sherwin Williams Resilience on this project in place
          of Super Paint. Resilience is a 100% Acrylic Coating and delivers
          outstanding hide and durability, and is also formulated with
          MoistureGuard technology making it ideal for early spring and late
          fall painting.
        </p>
      </div>
      <div style={{ marginTop: 25 }}>
        <h2>Deck Cleaning</h2>
        <p>
          "(A standard rinse is included in the stain price)
          <br /> For stain to properly penetrate surfaces, giving best results,
          a proper cleaning should be performed to kill and/or remove dirt,
          mold, mildew, algae, old stain, mill-glaze (on previously uncoated
          wood), and other growth/debris. We will apply a mixture of TSP (4oz),
          Bleach (1qt), and Water (3qt) to the stained surfaces then pressure
          wash.
          <br />
          <br /> ***CertaPro does not warranty horizontal surfaces***"
        </p>
      </div>
    </div>
  );
};

export default DefaultsInterior;
