import { Button, Col, message } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../utils/api";
import { generateProposalPdf } from "../../utils/proposalPdf";
import EmailModal from "../email-template-modal/EmailModal";

type Props = {
  options: any;
  optionPjcc: any;
};

const SalesAssociateButton = (props: Props) => {
  const { id } = useParams<any>();
  const { user } = useSelector((state: RootStateOrAny) => state.auth);

  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const [profilePhoto, setProfilePhoto] = useState<any>();
  const [showModal, setShowModal] = useState<any>(false);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailBody, setEmailBody] = useState<string>("");
  const [emailUrl, setEmailUrl] = useState<string>("");
  const [emailRecipients, setEmailRecipients] = useState<string>("");

  const [proposalBuffer, setProposalBuffer] = useState<any>();
  const { online } = useSelector((state: RootStateOrAny) => state.offline);

  useEffect(() => {
    if (online) {
      api
        .get(
          `user/photo/${items[currentProject].projectInfo?.salesAssociate._id}`
        )
        .then((resp: any) => {
          setProfilePhoto(resp);
        });
    }
  }, [online]);
  const handleSendProposal = async () => {
    setEmailUrl(`projects/send-proposal/${id}`);
    setEmailSubject(
      `Proposal - ${items[currentProject]?.projectInfo?.jobName}`
    );
    setEmailBody(
      `Hello ${items[currentProject]?.projectInfo?.clientName},\n\nHere is the proposal for job ${items[currentProject]?.projectInfo?.jobName}. \n \nThanks.`
    );
    setEmailRecipients(
      `${items[currentProject]?.projectInfo?.primaryContactEmail}`
    );
    setShowModal(true);

    let project = _.cloneDeep(items[currentProject]);
    let defaults = _.cloneDeep(adminDefaults);
    let companyInfo = _.cloneDeep(adminDefaults.companyInfo);
    let pdfBuffer: any = await generateProposalPdf(
      project,
      defaults,
      companyInfo,
      "email",
      profilePhoto
    );
    setProposalBuffer(pdfBuffer);
  };

  const notifyProductionAssociate = () => {
    setEmailUrl(`user/notify-production-associate/${id}`);
    setEmailSubject(
      `Production Ready- ${items[currentProject]?.projectInfo?.jobName}`
    );
    setEmailBody(
      `Hello ${items[currentProject]?.projectInfo?.productionAssociate.fullName},\n\nThe Project for ${items[currentProject]?.projectInfo?.jobName} is now production ready. Please contact with ${items[currentProject]?.projectInfo?.salesAssociate.fullName} to complete proeject hand off. \n\nfor more information please visit https://cppindy.com/manager-preview-profile/${items[currentProject]?.projectInfo?._id}.\n\nRegards,\n${user.fullName}`
    );

    setEmailRecipients(
      `${items[currentProject]?.projectInfo?.productionAssociate.email}`
    );
    setShowModal(true);
    setProposalBuffer("");
  };

  const handleSendEmail = (body: any) => {
    let emailBody = {
      ...body,
      proposalBuffer,
    };
    api
      .post(emailUrl, emailBody)
      .then(() => {
        message.success("Email Sent");
      })
      .catch((err) => {
        console.error(err);
      });
    setShowModal(false);
  };

  const notifyManager = () => {
    // console.log(items)
    setEmailSubject(
      `Project Review Request! - ${items[currentProject]?.projectInfo?.jobName}`
    );
    setEmailBody(
      `Project Estimation is completed for ${items[currentProject]?.projectInfo?.jobName} job! Please review the job. \n\n\
      For more information please visit https://cppindy.com/manager-preview-profile/${items[currentProject]?.projectInfo?._id}\n\n Thanks.\n${user?.fullName}`
    );
    setShowModal(true);
  };

  return (
    <>
      <EmailModal
        showModal={showModal}
        setShowModal={setShowModal}
        emailSubject={emailSubject}
        emailBody={emailBody}
        emailRecipients={emailRecipients}
        sendEmail={handleSendEmail}
      />
      {/* <Col>
        <Button
          type="primary"
          style={{
            color: "#1f1f1f",
          }}
          className="notify-btn"
          onClick={handleSendProposal}
        >
          Send Proposal
        </Button>
      </Col> */}
      <Col>
        <Button
          type="primary"
          style={{
            color: "#1f1f1f",
          }}
          onClick={notifyManager}
          className="notify-btn"
        >
          Request Approval
        </Button>
        <Button
          type="primary"
          style={{
            color: "#1f1f1f",
          }}
          className="notify-btn"
          onClick={notifyProductionAssociate}
        >
          Notify Production Associate
        </Button>
      </Col>
    </>
  );
};

export default SalesAssociateButton;
