import ProposalDefaultCom from "../../containers/proposal-defaults/commercial/ProposalDefaultCom";

type Props = {};

const ProposalDefaultsCom = (props: Props) => {
  return (
    <div>
      <ProposalDefaultCom />
    </div>
  );
};

export default ProposalDefaultsCom;
