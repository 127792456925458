import { MoreActionIcon } from "../../utils/icons";

export const columns = [
  {
    title: "Manufacturer",
    dataIndex: "manufacturer",
    sorter: (a: any, b: any) => a.manufacturer.localeCompare(b.manufacturer),
  },
  {
    title: "Product",
    dataIndex: "product",

    sorter: (a: any, b: any) => a.product.localeCompare(b.product),
  },
  {
    title: "Sheen",
    dataIndex: "sheen",
  },
  {
    title: "Type",
    dataIndex: "itemType",
  },
  {
    title: "Price",
    dataIndex: "priceAfterTax",
    render: (data: any) => {
      return <h1>${data}</h1>;
    },
  },
  // {
  //   title: "Price after taxes",
  //   dataIndex: "priceAfterTax",
  //   render: (data: any) => {
  //     return <h1>${data}</h1>;
  //   },
  // },
];

export const paintPrimerHeaders = [
  {
    label: "manufacturer",
    key: "manufacturer",
  },
  {
    label: "product",
    key: "product",
  },
  {
    label: "sheen",
    key: "sheen",
  },
  {
    label: "itemType",
    key: "itemType",
  },
  // {
  //   label: "priceBeforeTax",
  //   key: "priceBeforeTax",
  // },
  {
    label: "priceAfterTax",
    key: "priceAfterTax",
  },
  {
    label: "material",
    key: "material",
  },
  {
    label: "_id",
    key: "_id",
  },
];
