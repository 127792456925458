import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Upload,
  Button,
  Checkbox,
  Select,
  Radio,
  Form,
  message,
  Spin,
  Modal,
} from "antd";
import "./OnsiteChecklist.less";
import type { RadioChangeEvent } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import { useHistory, useParams } from "react-router-dom";
import { API_BASE } from "../../constant";
import axios from "axios";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  addOnsiteChecklistOtherAnswers,
  addOnsiteChecklistToProject,
  setCurrentProject,
  updateOnsiteChecklistToProject,
  updateProjectById,
  updateProjectStateById,
} from "../../redux/project/action";
import { getAuthUser, getSelectedTenant } from "../../utils/authToken";
import api from "../../utils/api";
import moment from "moment";
import { EditOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import _, { isNull } from "lodash";
import Notes from "../notes/Notes";
import { useMediaQuery } from "react-responsive";
var crypto = require("crypto");
const { Option } = Select;

type Props = {
  path?: string;
  waterSourceLocationPhotoList: any[];
  setWaterSourceLocationPhotoList?: any;
  electricalSourceLocationPhotoList: any[];
  setElectricalSourceLocationPhotoList?: any;
  loading?: boolean;
};

const OnsiteChecklist = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:1150px)" });
  const isTabletAir = useMediaQuery({ query: "(max-width:1200px)" });

  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const user = getAuthUser();
  const tenantId = getSelectedTenant();

  const [form] = Form.useForm();
  const [electricalSourceLocationPhoto, setElectricalSourceLocationPhoto] =
    useState("");
  const [waterSourceLocationPhoto, setWaterSourceLocationPhoto] = useState("");
  const [radioValue, setRadioValue] = useState<{ [key: number]: number }>({});
  const [preview, setPreview] = useState(true);
  const [imageSource, setImageSource] = useState("");

  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const [inspectionCompleted, setInspectionCompleted] = useState(
    items[currentProject]?.onsiteChecklistAnswers?.failureInspectionCompleted
      ? items[currentProject]?.onsiteChecklistAnswers
        ?.failureInspectionCompleted
      : false
  );
  const [savedAnswers, setSavedAnswers] = useState<any>(
    items[currentProject]?.onsiteChecklistAnswers
      ? items[currentProject]?.onsiteChecklistAnswers
      : {}
  );

  const [paintWageRateEdit, setPaintWageRateEdit] = useState(false);
  const [paintWageRateValue, setPaintWageRateValue] = useState(
    items[currentProject]?.projectInfo?.paintWageRate
      ? items[currentProject]?.projectInfo?.paintWageRate
      : 0
  );

  const [carpentryWageRateEdit, setCarpentryWageRateEdit] = useState(false);
  const [carpentryWageRateValue, setCarpentryWageRateValue] = useState(
    items[currentProject]?.projectInfo?.carpentryWageRate
      ? items[currentProject]?.projectInfo?.carpentryWageRate
      : 0
  );

  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [previewLoading, setPreviewLoading] = useState(false);

  const handleImageLoad = (event: any) => {
    const { naturalWidth, naturalHeight } = event.target;
    setImageDimensions({ width: naturalWidth, height: naturalHeight });
  };

  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };

  const onRadioChange = (e: RadioChangeEvent, index: number) => {
    setRadioValue({ ...radioValue, [index]: e.target.value });
  };

  const onFormSubmit = (values: any) => {
    let otherChecklistAnswers = [];
    // extract other checklist answers from onsite
    for (const key in values) {
      const onsiteAnsId = crypto.randomBytes(12).toString("hex");
      if (key.startsWith("otherChecklist")) {
        otherChecklistAnswers.push({
          _id: onsiteAnsId,
          project: id,
          question: key.substring(14),
          answer: values[key],
          createdBy: user._id,
        });
      }
    }

    // if onsite is already created then update it

    if (Object.keys(savedAnswers).length > 0) {
      let ans = otherChecklistAnswers.map((item: any, index: number) => {
        return {
          ...item,
          _id: savedAnswers?.otherChecklistAnswers[index]
            ? savedAnswers?.otherChecklistAnswers[index]?._id
            : undefined,
        };
      });

      let update = [];
      let create = [];
      for (const item of ans) {
        item._id ? update.push(item) : create.push(item);
      }

      const newQuestions = create.map((item) => {
        const ansId = crypto.randomBytes(12).toString("hex");
        return {
          ...item,
          _id: ansId,
        };
      });

      // if onsite is already created and admin adds more questions
      if (create.length > 0) {
        const updatedOtherChecklistAnswers = newQuestions.map((item: any) => {
          const populatedQuestion =
            adminDefaults.estimatorChecklistQuestionsService.find(
              (serviceItem: any) => serviceItem._id === item.question
            );
          return {
            ...item,
            question: populatedQuestion,
          };
        });
        const payload = {
          project: id,
          otherChecklistAnswers: updatedOtherChecklistAnswers,
        };
        dispatch(addOnsiteChecklistOtherAnswers(payload));
        if (props.path) {
          history.push(`/${props.path}/${id}`);
        } else {
          history.push(`/labor-rates/${id}`);
        }
      } else {
        const updatedOtherChecklistAnswers = update.map((item: any) => {
          const populatedQuestion =
            adminDefaults.estimatorChecklistQuestionsService.find(
              (serviceItem: any) => serviceItem._id === item.question
            );
          return {
            ...item,
            question: populatedQuestion,
          };
        });
        const payload = {
          ...values,
          updatedAt: moment(),
          _id: savedAnswers._id,
          project: id,
          otherChecklistAnswers: updatedOtherChecklistAnswers,
          electricalSourceLocationPhoto,
          waterSourceLocationPhoto,
        };

        dispatch(updateOnsiteChecklistToProject(payload));
        if (props.path) {
          history.push(`/${props.path}/${id}`);
        } else {
          history.push(`/labor-rates/${id}`);
        }
      }
      // create onsite checklist
    } else {
      const onsiteId = crypto.randomBytes(12).toString("hex");
      const updatedOtherChecklistAnswers = otherChecklistAnswers.map(
        (item: any) => {
          const populatedQuestion =
            adminDefaults.estimatorChecklistQuestionsService.find(
              (serviceItem: any) => serviceItem._id === item.question
            );
          return {
            ...item,
            question: populatedQuestion,
          };
        }
      );
      const payload = {
        ...values,
        _id: onsiteId,
        project: id,
        updatedAt: moment(),
        otherChecklistAnswers: updatedOtherChecklistAnswers,
        electricalSourceLocationPhoto,
        waterSourceLocationPhoto,
      };
      dispatch(addOnsiteChecklistToProject(payload));
      if (props.path) {
        history.push(`/${props.path}/${id}`);
      } else {
        let body = {
          _id: id,
          projectState: 2,
        };
        dispatch(updateProjectStateById(body));
        history.push(`/labor-rates/${id}`);
      }
    }
  };

  const onInspectionChange = (e: CheckboxChangeEvent) => {
    setInspectionCompleted(e.target.checked);
  };

  const handleBeforeUpload = (file: File) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (isJpgOrPng) {
      return true;
    } else {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    // if (!isJpgOrPng) {
    //   message.error("You can only upload JPG/PNG file!");
    // }
    // return isJpgOrPng;
  };

  const handleWaterLocationDelete = (file: any) => {
    if (file.response) {
      axios
        .delete(`${API_BASE}upload-files/${file.response._id}`, {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
        })
        .then(() => {
          props.setWaterSourceLocationPhotoList(
            props.waterSourceLocationPhotoList.filter(
              (item: any) => item.uid !== file.response._id
            )
          );
          // updateFileList(file.uid);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .delete(`${API_BASE}upload-files/${file.uid}`, {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
        })
        .then(() => {
          props.setWaterSourceLocationPhotoList(
            props.waterSourceLocationPhotoList.filter(
              (item: any) => item.uid !== file.uid
            )
          );
          // updateFileList(file.uid);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleElectricalLocationDelete = (file: any) => {
    if (file.response) {
      axios
        .delete(`${API_BASE}upload-files/${file.response._id}`, {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
        })
        .then(() => {
          props.setElectricalSourceLocationPhotoList(
            props.electricalSourceLocationPhotoList.filter(
              (item: any) => item.uid !== file.response._id
            )
          );
          // updateFileList(file.uid);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .delete(`${API_BASE}upload-files/${file.uid}`, {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
        })
        .then(() => {
          props.setElectricalSourceLocationPhotoList(
            props.electricalSourceLocationPhotoList.filter(
              (item: any) => item.uid !== file.uid
            )
          );
          // updateFileList(file.uid);
        })
        .catch((err) => console.log(err));
    }
  };

  const onWaterLocationUpload = (info: any) => {
    if (info.file.status === "done") {
      setWaterSourceLocationPhoto(info.file.response._id);
    }
    const { status, originFileObj } = info.file;
    if (status === "done") {
      const reader = new FileReader();
      reader.readAsDataURL(originFileObj as RcFile);
      reader.onloadend = () => {
        const url = reader.result;
        // do something with the thumbnail URL
        props.setWaterSourceLocationPhotoList([
          ...props.waterSourceLocationPhotoList,
          {
            name: info.file.name,
            url,
            uid: info.file.response._id,
            addToProject: info.file.response.addToProject,
          },
        ]);
      };
    }
  };

  const onElectricalLocationUpload = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === "done") {
      setElectricalSourceLocationPhoto(info.file.response._id);
    }
    const { status, originFileObj } = info.file;
    if (status === "done") {
      const reader = new FileReader();
      reader.readAsDataURL(originFileObj as RcFile);
      reader.onloadend = () => {
        const url = reader.result;
        // do something with the thumbnail URL
        props.setElectricalSourceLocationPhotoList([
          ...props.electricalSourceLocationPhotoList,
          {
            name: info.file.name,
            url,
            uid: info.file.response._id,
            addToProject: info.file.response.addToProject,
          },
        ]);
      };
    }
  };

  const base64ToBlob = (base64String: string, contentType: string) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const onPreview = async (file: any) => {
    setPreviewLoading(true);
    let src = file.url as string;
    let blob: Blob;

    if (file.thumbUrl && !file.OrgImageURl) {
      const res: any = await api.get(
        `upload-files/preview-image/${file.key || file.response.key}`
      );
      const OrgImageURl = `data:${file.mimeType};base64,${Buffer.from(res.Body).toString("base64")}`;
      file = { ...file, OrgImageURl };
      if (file.waterSourcePic) {
        const index = props.waterSourceLocationPhotoList.findIndex(
          (item) => item.uid === file.uid
        );
        const updatedList = [...props.waterSourceLocationPhotoList];
        updatedList[index] = { ...file, OrgImageURl };
        props.setWaterSourceLocationPhotoList(updatedList);
      } else if (file.electricSourcePic) {
        const index = props.electricalSourceLocationPhotoList.findIndex(
          (item) => item.uid === file.uid
        );
        const updatedList = [...props.electricalSourceLocationPhotoList];
        updatedList[index] = { ...file, OrgImageURl };
        props.setElectricalSourceLocationPhotoList(updatedList);
      }
      const base64Data = file.OrgImageURl.split(",");
      const mimeType = base64Data[0].substring(
        base64Data[0].indexOf(":") + 1,
        base64Data[0].indexOf(";")
      );
      blob = base64ToBlob(base64Data[1], mimeType);
    }
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj ? file.originFileObj : blob);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    setImageSource(src);
    setPreview(false);
    setPreviewLoading(false);
  };

  const handleCancel = () => setPreview(true);

  const handlePaintWageRateUpdate = () => {
    const tempProject = _.cloneDeep(items[currentProject]?.projectInfo);
    tempProject.paintWageRate = paintWageRateValue;
    dispatch(updateProjectById(tempProject));
    setPaintWageRateEdit(false);
  };

  const handleCarpentryWageRateUpdate = () => {
    const tempProject = _.cloneDeep(items[currentProject]?.projectInfo);
    tempProject.carpentryWageRate = carpentryWageRateValue;
    dispatch(updateProjectById(tempProject));
    setCarpentryWageRateEdit(false);
  };

  const resetPaintWageRateValue = () => {
    const defaultValue = items[currentProject]?.projectInfo?.paintWageRate || 0;
    setPaintWageRateValue(defaultValue);
    setPaintWageRateEdit(false);
  };

  const resetCarpentryWageRateValue = () => {
    const defaultValue =
      items[currentProject]?.projectInfo?.carpentryWageRate || 0;
    setCarpentryWageRateValue(defaultValue);
    setCarpentryWageRateEdit(false);
  };

  return (
    <Form
      form={form}
      onFinish={onFormSubmit}
      layout="vertical"
      scrollToFirstError
      autoComplete="off"
    >
      {isTabletOrMobile ? (
        <Row gutter={24}>
          <Col lg={24} md={24}>
            <div className="onsite-container">
              <div className="checklist-title-container">
                <h1 className="checklist-title">On- site Checklists </h1>
              </div>
              <hr className="checklist-divider" />

              <div className="source-container" style={{ marginTop: 20 }}>
                <Row justify="space-between">
                  <Col>
                    <h1 className="checklist-subtitle">Wage Rates</h1>
                  </Col>
                </Row>
                <div style={{ marginTop: "20px" }}>
                  <Row gutter={9}>
                    <Col span={12}>
                      <h3 style={{ marginBottom: 5 }}>Painting Wage Rate: </h3>
                      <Row>
                        <Col span={18}>
                          <Input
                            className="location-input"
                            // style={{
                            //   width: "60px",
                            //   height: "30px",
                            //   background: "#ffffff",
                            // }}
                            type="number"
                            value={paintWageRateValue}
                            onChange={(e) =>
                              setPaintWageRateValue(e.target.value)
                            }
                            disabled={!paintWageRateEdit}
                          />
                        </Col>
                        <Col span={6}>
                          {paintWageRateEdit ? (
                            <div style={{ marginTop: 14, marginLeft: 5 }}>
                              <CheckOutlined
                                onClick={handlePaintWageRateUpdate}
                                style={{
                                  color: "#FDB913",
                                  cursor: "pointer",
                                  marginRight: 8,
                                  fontSize: "22px",
                                }}
                              />

                              <CloseOutlined
                                onClick={resetPaintWageRateValue}
                                style={{
                                  color: "#FDB913",
                                  cursor: "pointer",
                                  fontSize: "22px",
                                }}
                              />
                            </div>
                          ) : (
                            <EditOutlined
                              onClick={() => setPaintWageRateEdit(true)}
                              style={{
                                color: "#FDB913",
                                cursor: "pointer",
                                fontSize: "26px",
                                marginTop: 10,
                                marginLeft: 8,
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <h3 style={{ marginBottom: 5 }}>Carpentry Wage Rate: </h3>
                      <Row>
                        <Col span={18}>
                          <Input
                            className="location-input"
                            // style={{
                            //   width: "60px",
                            //   height: "30px",
                            //   background: "#ffffff",
                            // }}
                            type="number"
                            value={carpentryWageRateValue}
                            onChange={(e) =>
                              setCarpentryWageRateValue(e.target.value)
                            }
                            disabled={!carpentryWageRateEdit}
                          />
                        </Col>
                        <Col span={6}>
                          {carpentryWageRateEdit ? (
                            <div style={{ marginTop: 14, marginLeft: 5 }}>
                              <CheckOutlined
                                onClick={handleCarpentryWageRateUpdate}
                                style={{
                                  color: "#FDB913",
                                  cursor: "pointer",
                                  marginRight: 8,
                                  fontSize: "22px",
                                }}
                              />

                              <CloseOutlined
                                onClick={resetCarpentryWageRateValue}
                                style={{
                                  color: "#FDB913",
                                  cursor: "pointer",
                                  fontSize: "22px",
                                }}
                              />
                            </div>
                          ) : (
                            <EditOutlined
                              onClick={() => setCarpentryWageRateEdit(true)}
                              size={60}
                              style={{
                                color: "#FDB913",
                                cursor: "pointer",
                                fontSize: "26px",
                                marginTop: 10,
                                marginLeft: 8,
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="source-container">
                <h1 className="checklist-subtitle">Source Location</h1>
                <div style={{ marginTop: "20px " }}>
                  <Row gutter={15}>
                    <Col span={15}>
                      <Form.Item
                        style={{ margin: 0 }}
                        name="waterSourceLocation"
                        initialValue={savedAnswers?.waterSourceLocation}
                        label={
                          <h3 style={{ margin: 0 }}>
                            Water Source Location (take photo/validate
                            operation){" "}
                          </h3>
                        }
                      >
                        <Input
                          className="location-input"
                          placeholder="Location"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {online && (
                    <Row>
                      {props.loading ? (
                        <Row justify="start">
                          <Spin
                            tip="Loading..."
                            size="large"
                            style={{ marginTop: 20 }}
                          />
                        </Row>
                      ) : (
                        <Form.Item
                          label={
                            <h3
                              style={{
                                fontWeight: "bold",
                                marginTop: 16,
                                marginBottom: 0,
                              }}
                            >
                              Upload photo
                            </h3>
                          }
                        >
                          <Row
                            justify="start"
                            align="middle"
                            className="upload-photo-container"
                          >
                            <Upload
                              accept="image/png, image/jpeg"
                              defaultFileList={[
                                ...props.waterSourceLocationPhotoList,
                              ]}
                              onPreview={onPreview}
                              onChange={onWaterLocationUpload}
                              onRemove={handleWaterLocationDelete}
                              beforeUpload={handleBeforeUpload}
                              maxCount={1}
                              action={`${API_BASE}upload-files/onsite-water-media/${items[currentProject]?.projectId}`}
                              headers={{
                                Authorization: "Bearer " + user.accessToken,
                                "tenant-id": !isNull(tenantId) ? tenantId : "",
                              }}
                              listType="picture-card"
                              disabled={previewLoading}
                            >
                              {props.waterSourceLocationPhotoList.length >=
                                1 ? null : (
                                <div>+ Upload</div>
                              )}
                            </Upload>
                            {previewLoading && (
                              <div
                                id={`waterSourceDiv-${props.waterSourceLocationPhotoList.length}`}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                                }}
                              >
                                <Spin />
                              </div>
                            )}
                            <Modal
                              open={!preview}
                              width={imageDimensions.width}
                              title={"Preview Image"}
                              footer={null}
                              onCancel={handleCancel}
                              bodyStyle={{ background: "transparent" }}
                            >
                              <img
                                alt="example"
                                style={{ width: "100%" }}
                                src={imageSource}
                                onLoad={handleImageLoad}
                              />
                            </Modal>
                          </Row>
                        </Form.Item>
                      )}
                    </Row>
                  )}

                  <Row gutter={15} style={{ marginTop: "20px" }}>
                    <Col span={15}>
                      <Form.Item
                        initialValue={savedAnswers?.electricalSourceLocation}
                        name="electricalSourceLocation"
                        label={
                          <h3 style={{ margin: 0 }}>
                            Electrical Source Location (take photo/validate
                            operation)
                          </h3>
                        }
                      >
                        <Input
                          className="location-input"
                          placeholder="Location"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {online && (
                    <Row>
                      {props.loading ? (
                        <Row justify="start">
                          <Spin
                            tip="Loading..."
                            size="large"
                            style={{ marginTop: 0 }}
                          />
                        </Row>
                      ) : (
                        <Form.Item
                          label={
                            <h3
                              style={{
                                fontWeight: "bold",
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              Upload photo
                            </h3>
                          }
                        >
                          <Row
                            justify="start"
                            align="middle"
                            className="upload-photo-container"
                          >
                            <Upload
                              accept="image/png, image/jpeg"
                              defaultFileList={[
                                ...props.electricalSourceLocationPhotoList,
                              ]}
                              onPreview={onPreview}
                              onChange={onElectricalLocationUpload}
                              onRemove={handleElectricalLocationDelete}
                              beforeUpload={handleBeforeUpload}
                              maxCount={1}
                              action={`${API_BASE}upload-files/onsite-electric-media/${items[currentProject]?.projectId}`}
                              headers={{
                                Authorization: "Bearer " + user.accessToken,
                                "tenant-id": !isNull(tenantId) ? tenantId : "",
                              }}
                              listType="picture-card"
                              disabled={previewLoading}
                            >
                              {props.electricalSourceLocationPhotoList.length >=
                                1 ? null : (
                                <div>+ Upload</div>
                              )}
                            </Upload>
                            {previewLoading && (
                              <div
                                id={`electricalSourceDiv-${props.electricalSourceLocationPhotoList.length}`}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                                }}
                              >
                                <Spin />
                              </div>
                            )}
                            <Modal
                              open={!preview}
                              width={imageDimensions.width}
                              title={"Preview Image"}
                              footer={null}
                              onCancel={handleCancel}
                              bodyStyle={{ background: "transparent" }}
                            >
                              <img
                                alt="example"
                                style={{ width: "100%" }}
                                src={imageSource}
                                onLoad={handleImageLoad}
                              />
                            </Modal>
                          </Row>
                        </Form.Item>
                      )}
                    </Row>
                  )}
                </div>

                <div
                  className="customize-select-height"
                  style={{ marginTop: 20 }}
                >
                  <Row justify="space-between">
                    <Col>
                      <h1 className="checklist-subtitle">
                        Inspection of failure
                      </h1>
                    </Col>
                    <Col>
                      <Form.Item
                        initialValue={savedAnswers?.failureInspectionCompleted}
                        valuePropName="checked"
                        name="failureInspectionCompleted"
                      >
                        <Checkbox
                          value={inspectionCompleted}
                          onChange={onInspectionChange}
                        >
                          <h3>Inspection for Failures Completed? </h3>
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <div style={{ marginTop: "25px" }}>
                    <Row gutter={9}>
                      <Col span={12}>
                        <Form.Item
                          initialValue={savedAnswers?.failureExtent}
                          name="failureExtent"
                          rules={[
                            {
                              required: inspectionCompleted,
                              message: "Please select an option",
                            },
                          ]}
                          label={
                            <h3 style={{ margin: 0 }}>
                              To what extent is the failure?{" "}
                            </h3>
                          }
                        >
                          <Select
                            size="large"
                            placeholder="Select"
                            style={{ width: "100%" }}
                          >
                            <Option value="0%">0%</Option>
                            <Option value="10%">10%</Option>
                            <Option value="20%">20%</Option>
                            <Option value="30%">30%</Option>
                            <Option value="40%">40%</Option>
                            <Option value="50%">50%</Option>
                            <Option value="60%">60%</Option>
                            <Option value="70%">70%</Option>
                            <Option value="80%">80%</Option>
                            <Option value="90%">90%</Option>
                            <Option value="100%">100%</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="failureLocation"
                          initialValue={savedAnswers?.failureLocation}
                          rules={[
                            {
                              required: inspectionCompleted,
                              message: "Please enter the building!",
                            },
                            checkEmpty,
                          ]}
                          label={
                            <h3 style={{ margin: 0 }}>
                              Where on the building is the failure?{" "}
                            </h3>
                          }
                        >
                          <Input
                            className="location-input"
                            placeholder="Building#"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "20px" }}>
                      <Col span={12}>
                        <Form.Item
                          initialValue={savedAnswers?.failureCause}
                          name="failureCause"
                          label={
                            <h3 style={{ margin: 0 }}>
                              What is the cause of the Failure?
                            </h3>
                          }
                          rules={[
                            {
                              required: inspectionCompleted,
                              message: "Please enter the cause of failure!",
                            },
                            checkEmpty,
                          ]}
                        >
                          <Input
                            className="location-input"
                            placeholder="Enter here"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div style={{ marginTop: 55 }}>
                  <Row justify="space-between">
                    <Col>
                      <h1 className="checklist-subtitle">Adhesion Test</h1>
                    </Col>
                    <Col>
                      <Form.Item
                        initialValue={savedAnswers?.adhesionTestCompleted}
                        valuePropName="checked"
                        name="adhesionTestCompleted"
                      >
                        <Checkbox>
                          <h3>Test Completed </h3>
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <div style={{ marginTop: 25 }}>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          initialValue={savedAnswers?.adhesionTestResult}
                          name="adhesionTestResult"
                          className="customize-select-height"
                          label={<h3 style={{ margin: 0 }}>Test Result</h3>}
                        >
                          <Select
                            size="large"
                            placeholder="Select"
                            style={{ width: "100%" }}
                          >
                            <Option value="Pass">Pass</Option>
                            <Option value="Fail">Fail</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div style={{ marginTop: 50 }}>
                  <h1
                    className="checklist-subtitle"
                    style={{ marginBottom: 40 }}
                  >
                    Other checklists
                  </h1>
                  <Row gutter={10}>
                    {[
                      ...adminDefaults?.estimatorChecklistQuestionsService,
                      ...(items[currentProject]?.projectDefaults
                        ?.estimateChecklistQuestions || []),
                    ]?.map((item: any, index: number) => {
                      let spn;
                      if (item.question.length > 50) {
                        spn = 24;
                      } else {
                        spn = 24;
                      }
                      if (item.type === "radio") {
                        return (
                          <Col key={item._id} span={spn}>
                            <Form.Item
                              style={{ marginBottom: 30 }}
                              initialValue={
                                savedAnswers?.otherChecklistAnswers.find(
                                  (answer: any) => answer.question === item._id
                                ).answer
                              }
                              name={`otherChecklist${item._id}`}
                              label={
                                <h3 style={{ margin: 0 }}>{item.question}</h3>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: "Please select!",
                                },
                              ]}
                            >
                              <Radio.Group
                                key={index}
                                onChange={(e: RadioChangeEvent) => {
                                  onRadioChange(e, index);
                                }}
                                value={radioValue[index]}
                              >
                                {item.answers.map(
                                  (answer: string, index: string) => {
                                    return (
                                      <Radio
                                        style={{ marginRight: 40 }}
                                        key={index}
                                        value={answer}
                                      >
                                        {answer}
                                      </Radio>
                                    );
                                  }
                                )}
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        );
                      } else if (item.type === "checkbox") {
                        return (
                          <Col key={item._id} span={spn}>
                            <Form.Item
                              valuePropName="checked"
                              initialValue={
                                savedAnswers?.otherChecklistAnswers.find(
                                  (answer: any) => answer.question === item._id
                                ).answer
                              }
                              style={{ marginBottom: 30 }}
                              name={`otherChecklist${item._id}`}
                              label={
                                <h3 style={{ margin: 0 }}>{item.question}</h3>
                              }
                              rules={[
                                {
                                  required: true,
                                  message: "Please select!",
                                },
                              ]}
                            >
                              {item.answers.map(
                                (answer: string, index: string) => {
                                  return (
                                    <Checkbox key={index}>{answer}</Checkbox>
                                  );
                                }
                              )}
                            </Form.Item>
                          </Col>
                        );
                      } else if (item.type === "text") {
                        return (
                          <Col key={item._id} span={spn}>
                            <Form.Item
                              style={{ marginBottom: 30 }}
                              name={`otherChecklist${item._id}`}
                              initialValue={
                                savedAnswers?.otherChecklistAnswers?.find(
                                  (answer: any) =>
                                    answer?.question === item._id ||
                                    answer?.question?.question === item.question
                                )?.answer
                              }
                              label={
                                <h3 style={{ margin: 0 }}>{item.question}</h3>
                              }
                              rules={[
                                {
                                  required: savedAnswers?.otherChecklistAnswers
                                    .length
                                    ? false
                                    : true,
                                  message: "Please enter!",
                                },
                              ]}
                            >
                              <Input
                                className="location-input"
                                placeholder="Enter"
                              />
                            </Form.Item>
                          </Col>
                        );
                      }
                    })}
                  </Row>
                </div>
              </div>
            </div>
          </Col>
          <Col className="gutter-row" lg={24} sm={24}>
            <Notes />
          </Col>
        </Row>
      ) : (
        <>
          {isTabletAir ? (
            <Row gutter={24}>
              <Col lg={24} md={24}>
                <div className="onsite-container">
                  <div className="checklist-title-container">
                    <h1 className="checklist-title">On- site Checklists </h1>
                  </div>
                  <hr className="checklist-divider" />

                  <div className="source-container" style={{ marginTop: 20 }}>
                    <Row justify="space-between">
                      <Col>
                        <h1 className="checklist-subtitle">Wage Rates</h1>
                      </Col>
                    </Row>
                    <div style={{ marginTop: "20px" }}>
                      <Row gutter={9}>
                        <Col span={12}>
                          <h3 style={{ marginBottom: 5 }}>
                            Painting Wage Rate:{" "}
                          </h3>
                          <Row>
                            <Col span={18}>
                              <Input
                                className="location-input"
                                // style={{
                                //   width: "60px",
                                //   height: "30px",
                                //   background: "#ffffff",
                                // }}
                                type="number"
                                value={paintWageRateValue}
                                onChange={(e) =>
                                  setPaintWageRateValue(e.target.value)
                                }
                                disabled={!paintWageRateEdit}
                              />
                            </Col>
                            <Col span={6}>
                              {paintWageRateEdit ? (
                                <div style={{ marginTop: 14, marginLeft: 5 }}>
                                  <CheckOutlined
                                    onClick={handlePaintWageRateUpdate}
                                    style={{
                                      color: "#FDB913",
                                      cursor: "pointer",
                                      marginRight: 8,
                                      fontSize: "22px",
                                    }}
                                  />

                                  <CloseOutlined
                                    onClick={resetPaintWageRateValue}
                                    style={{
                                      color: "#FDB913",
                                      cursor: "pointer",
                                      fontSize: "22px",
                                    }}
                                  />
                                </div>
                              ) : (
                                <EditOutlined
                                  onClick={() => setPaintWageRateEdit(true)}
                                  style={{
                                    color: "#FDB913",
                                    cursor: "pointer",
                                    fontSize: "26px",
                                    marginTop: 10,
                                    marginLeft: 8,
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <h3 style={{ marginBottom: 5 }}>
                            Carpentry Wage Rate:{" "}
                          </h3>
                          <Row>
                            <Col span={18}>
                              <Input
                                className="location-input"
                                // style={{
                                //   width: "60px",
                                //   height: "30px",
                                //   background: "#ffffff",
                                // }}
                                type="number"
                                value={carpentryWageRateValue}
                                onChange={(e) =>
                                  setCarpentryWageRateValue(e.target.value)
                                }
                                disabled={!carpentryWageRateEdit}
                              />
                            </Col>
                            <Col span={6}>
                              {carpentryWageRateEdit ? (
                                <div style={{ marginTop: 14, marginLeft: 5 }}>
                                  <CheckOutlined
                                    onClick={handleCarpentryWageRateUpdate}
                                    style={{
                                      color: "#FDB913",
                                      cursor: "pointer",
                                      marginRight: 8,
                                      fontSize: "22px",
                                    }}
                                  />

                                  <CloseOutlined
                                    onClick={resetCarpentryWageRateValue}
                                    style={{
                                      color: "#FDB913",
                                      cursor: "pointer",
                                      fontSize: "22px",
                                    }}
                                  />
                                </div>
                              ) : (
                                <EditOutlined
                                  onClick={() => setCarpentryWageRateEdit(true)}
                                  size={60}
                                  style={{
                                    color: "#FDB913",
                                    cursor: "pointer",
                                    fontSize: "26px",
                                    marginTop: 10,
                                    marginLeft: 8,
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="source-container">
                    <h1 className="checklist-subtitle">Source Location</h1>
                    <div style={{ marginTop: "20px " }}>
                      <Row gutter={15}>
                        <Col span={15}>
                          <Form.Item
                            style={{ margin: 0 }}
                            name="waterSourceLocation"
                            initialValue={savedAnswers?.waterSourceLocation}
                            label={
                              <h3 style={{ margin: 0 }}>
                                Water Source Location (take photo/validate
                                operation){" "}
                              </h3>
                            }
                          >
                            <Input
                              className="location-input"
                              placeholder="Location"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      {online && (
                        <Row>
                          {props.loading ? (
                            <Row justify="start">
                              <Spin
                                tip="Loading..."
                                size="large"
                                style={{ marginTop: 20 }}
                              />
                            </Row>
                          ) : (
                            <Form.Item
                              label={
                                <h3
                                  style={{
                                    fontWeight: "bold",
                                    marginTop: 16,
                                    marginBottom: 0,
                                  }}
                                >
                                  Upload photo
                                </h3>
                              }
                            >
                              <Row
                                justify="start"
                                align="middle"
                                className="upload-photo-container"
                              >
                                <Upload
                                  accept="image/png, image/jpeg"
                                  defaultFileList={[
                                    ...props.waterSourceLocationPhotoList,
                                  ]}
                                  onPreview={onPreview}
                                  onChange={onWaterLocationUpload}
                                  onRemove={handleWaterLocationDelete}
                                  beforeUpload={handleBeforeUpload}
                                  maxCount={1}
                                  action={`${API_BASE}upload-files/onsite-water-media/${items[currentProject]?.projectId}`}
                                  headers={{
                                    Authorization: "Bearer " + user.accessToken,
                                    "tenant-id": !isNull(tenantId)
                                      ? tenantId
                                      : "",
                                  }}
                                  listType="picture-card"
                                  disabled={previewLoading}
                                >
                                  {props.waterSourceLocationPhotoList.length >=
                                    1 ? null : (
                                    <div>+ Upload</div>
                                  )}
                                </Upload>
                                {previewLoading && (
                                  <div
                                    id={`waterSourceDiv-${props.waterSourceLocationPhotoList.length}`}
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.5)",
                                    }}
                                  >
                                    <Spin />
                                  </div>
                                )}
                                <Modal
                                  open={!preview}
                                  width={imageDimensions.width}
                                  title={"Preview Image"}
                                  footer={null}
                                  onCancel={handleCancel}
                                  bodyStyle={{ background: "transparent" }}
                                >
                                  <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={imageSource}
                                    onLoad={handleImageLoad}
                                  />
                                </Modal>
                              </Row>
                            </Form.Item>
                          )}
                        </Row>
                      )}

                      <Row gutter={15} style={{ marginTop: "20px" }}>
                        <Col span={15}>
                          <Form.Item
                            initialValue={
                              savedAnswers?.electricalSourceLocation
                            }
                            name="electricalSourceLocation"
                            label={
                              <h3 style={{ margin: 0 }}>
                                Electrical Source Location (take photo/validate
                                operation)
                              </h3>
                            }
                          >
                            <Input
                              className="location-input"
                              placeholder="Location"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      {online && (
                        <Row>
                          {props.loading ? (
                            <Row justify="start">
                              <Spin
                                tip="Loading..."
                                size="large"
                                style={{ marginTop: 0 }}
                              />
                            </Row>
                          ) : (
                            <Form.Item
                              label={
                                <h3
                                  style={{
                                    fontWeight: "bold",
                                    marginTop: 0,
                                    marginBottom: 0,
                                  }}
                                >
                                  Upload photo
                                </h3>
                              }
                            >
                              <Row
                                justify="start"
                                align="middle"
                                className="upload-photo-container"
                              >
                                <Upload
                                  accept="image/png, image/jpeg"
                                  defaultFileList={[
                                    ...props.electricalSourceLocationPhotoList,
                                  ]}
                                  onPreview={onPreview}
                                  onChange={onElectricalLocationUpload}
                                  onRemove={handleElectricalLocationDelete}
                                  beforeUpload={handleBeforeUpload}
                                  maxCount={1}
                                  action={`${API_BASE}upload-files/onsite-electric-media/${items[currentProject]?.projectId}`}
                                  headers={{
                                    Authorization: "Bearer " + user.accessToken,
                                    "tenant-id": !isNull(tenantId)
                                      ? tenantId
                                      : "",
                                  }}
                                  listType="picture-card"
                                  disabled={previewLoading}
                                >
                                  {props.electricalSourceLocationPhotoList
                                    .length >= 1 ? null : (
                                    <div>+ Upload</div>
                                  )}
                                </Upload>
                                {previewLoading && (
                                  <div
                                    id={`electricalSourceDiv-${props.electricalSourceLocationPhotoList.length}`}
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.5)",
                                    }}
                                  >
                                    <Spin />
                                  </div>
                                )}
                                <Modal
                                  open={!preview}
                                  width={imageDimensions.width}
                                  title={"Preview Image"}
                                  footer={null}
                                  onCancel={handleCancel}
                                  bodyStyle={{ background: "transparent" }}
                                >
                                  <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={imageSource}
                                    onLoad={handleImageLoad}
                                  />
                                </Modal>
                              </Row>
                            </Form.Item>
                          )}
                        </Row>
                      )}
                    </div>

                    <div
                      className="customize-select-height"
                      style={{ marginTop: 20 }}
                    >
                      <Row justify="space-between">
                        <Col>
                          <h1 className="checklist-subtitle">
                            Inspection of failure
                          </h1>
                        </Col>
                        <Col>
                          <Form.Item
                            initialValue={
                              savedAnswers?.failureInspectionCompleted
                            }
                            valuePropName="checked"
                            name="failureInspectionCompleted"
                          >
                            <Checkbox
                              value={inspectionCompleted}
                              onChange={onInspectionChange}
                            >
                              <h3>Inspection for Failures Completed? </h3>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <div style={{ marginTop: "25px" }}>
                        <Row gutter={9}>
                          <Col span={24}>
                            <Form.Item
                              initialValue={savedAnswers?.failureExtent}
                              name="failureExtent"
                              rules={[
                                {
                                  required: inspectionCompleted,
                                  message: "Please select an option",
                                },
                              ]}
                              label={
                                <h3 style={{ margin: 0 }}>
                                  To what extent is the failure?{" "}
                                </h3>
                              }
                            >
                              <Select
                                size="large"
                                placeholder="Select"
                                style={{ width: "100%" }}
                              >
                                <Option value="0%">0%</Option>
                                <Option value="10%">10%</Option>
                                <Option value="20%">20%</Option>
                                <Option value="30%">30%</Option>
                                <Option value="40%">40%</Option>
                                <Option value="50%">50%</Option>
                                <Option value="60%">60%</Option>
                                <Option value="70%">70%</Option>
                                <Option value="80%">80%</Option>
                                <Option value="90%">90%</Option>
                                <Option value="100%">100%</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              name="failureLocation"
                              initialValue={savedAnswers?.failureLocation}
                              rules={[
                                {
                                  required: inspectionCompleted,
                                  message: "Please enter the building!",
                                },
                                checkEmpty,
                              ]}
                              label={
                                <h3 style={{ margin: 0 }}>
                                  Where on the building is the failure?{" "}
                                </h3>
                              }
                            >
                              <Input
                                className="location-input"
                                placeholder="Building#"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "20px" }}>
                          <Col span={24}>
                            <Form.Item
                              initialValue={savedAnswers?.failureCause}
                              name="failureCause"
                              label={
                                <h3 style={{ margin: 0 }}>
                                  What is the cause of the Failure?
                                </h3>
                              }
                              rules={[
                                {
                                  required: inspectionCompleted,
                                  message: "Please enter the cause of failure!",
                                },
                                checkEmpty,
                              ]}
                            >
                              <Input
                                className="location-input"
                                placeholder="Enter here"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div style={{ marginTop: 55 }}>
                      <Row justify="space-between">
                        <Col>
                          <h1 className="checklist-subtitle">Adhesion Test</h1>
                        </Col>
                        <Col>
                          <Form.Item
                            initialValue={savedAnswers?.adhesionTestCompleted}
                            valuePropName="checked"
                            name="adhesionTestCompleted"
                          >
                            <Checkbox>
                              <h3>Test Completed </h3>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <div style={{ marginTop: 25 }}>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              initialValue={savedAnswers?.adhesionTestResult}
                              name="adhesionTestResult"
                              className="customize-select-height"
                              label={<h3 style={{ margin: 0 }}>Test Result</h3>}
                            >
                              <Select
                                size="large"
                                placeholder="Select"
                                style={{ width: "100%" }}
                              >
                                <Option value="Pass">Pass</Option>
                                <Option value="Fail">Fail</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div style={{ marginTop: 50 }}>
                      <h1
                        className="checklist-subtitle"
                        style={{ marginBottom: 40 }}
                      >
                        Other checklists
                      </h1>
                      <Row gutter={10}>
                        {[
                          ...adminDefaults?.estimatorChecklistQuestionsService,
                          ...(items[currentProject]?.projectDefaults
                            ?.estimateChecklistQuestions || []),
                        ]?.map((item: any, index: number) => {
                          let spn;
                          if (item.question.length > 50) {
                            spn = 24;
                          } else {
                            spn = 24;
                          }
                          if (item.type === "radio") {
                            return (
                              <Col key={item._id} span={spn}>
                                <Form.Item
                                  style={{ marginBottom: 30 }}
                                  initialValue={
                                    savedAnswers?.otherChecklistAnswers.find(
                                      (answer: any) =>
                                        answer.question === item._id
                                    ).answer
                                  }
                                  name={`otherChecklist${item._id}`}
                                  label={
                                    <h3 style={{ margin: 0 }}>
                                      {item.question}
                                    </h3>
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select!",
                                    },
                                  ]}
                                >
                                  <Radio.Group
                                    key={index}
                                    onChange={(e: RadioChangeEvent) => {
                                      onRadioChange(e, index);
                                    }}
                                    value={radioValue[index]}
                                  >
                                    {item.answers.map(
                                      (answer: string, index: string) => {
                                        return (
                                          <Radio
                                            style={{ marginRight: 40 }}
                                            key={index}
                                            value={answer}
                                          >
                                            {answer}
                                          </Radio>
                                        );
                                      }
                                    )}
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                            );
                          } else if (item.type === "checkbox") {
                            return (
                              <Col key={item._id} span={spn}>
                                <Form.Item
                                  valuePropName="checked"
                                  initialValue={
                                    savedAnswers?.otherChecklistAnswers.find(
                                      (answer: any) =>
                                        answer.question === item._id
                                    ).answer
                                  }
                                  style={{ marginBottom: 30 }}
                                  name={`otherChecklist${item._id}`}
                                  label={
                                    <h3 style={{ margin: 0 }}>
                                      {item.question}
                                    </h3>
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select!",
                                    },
                                  ]}
                                >
                                  {item.answers.map(
                                    (answer: string, index: string) => {
                                      return (
                                        <Checkbox key={index}>
                                          {answer}
                                        </Checkbox>
                                      );
                                    }
                                  )}
                                </Form.Item>
                              </Col>
                            );
                          } else if (item.type === "text") {
                            return (
                              <Col key={item._id} span={spn}>
                                <Form.Item
                                  style={{ marginBottom: 30 }}
                                  name={`otherChecklist${item._id}`}
                                  initialValue={
                                    savedAnswers?.otherChecklistAnswers?.find(
                                      (answer: any) =>
                                        answer?.question === item._id ||
                                        answer?.question?.question ===
                                        item.question
                                    )?.answer
                                  }
                                  label={
                                    <h3 style={{ margin: 0 }}>
                                      {item.question}
                                    </h3>
                                  }
                                  rules={[
                                    {
                                      required: savedAnswers
                                        ?.otherChecklistAnswers.length
                                        ? false
                                        : true,
                                      message: "Please enter!",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="location-input"
                                    placeholder="Enter"
                                  />
                                </Form.Item>
                              </Col>
                            );
                          }
                        })}
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="gutter-row" lg={24} sm={24}>
                <Notes />
              </Col>
            </Row>
          ) : (
            <Row gutter={24}>
              <Col lg={15} md={24}>
                <div className="onsite-container">
                  <div className="checklist-title-container">
                    <h1 className="checklist-title">On- site Checklists </h1>
                  </div>
                  <hr className="checklist-divider" />

                  <div className="source-container" style={{ marginTop: 20 }}>
                    <Row justify="space-between">
                      <Col>
                        <h1 className="checklist-subtitle">Wage Rates</h1>
                      </Col>
                    </Row>
                    <div style={{ marginTop: "20px" }}>
                      <Row gutter={9}>
                        <Col span={12}>
                          <h3 style={{ marginBottom: 5 }}>
                            Painting Wage Rate:{" "}
                          </h3>
                          <Row>
                            <Col span={18}>
                              <Input
                                className="location-input"
                                // style={{
                                //   width: "60px",
                                //   height: "30px",
                                //   background: "#ffffff",
                                // }}
                                type="number"
                                value={paintWageRateValue}
                                onChange={(e) =>
                                  setPaintWageRateValue(e.target.value)
                                }
                                disabled={!paintWageRateEdit}
                              />
                            </Col>
                            <Col span={6}>
                              {paintWageRateEdit ? (
                                <div style={{ marginTop: 14, marginLeft: 5 }}>
                                  <CheckOutlined
                                    onClick={handlePaintWageRateUpdate}
                                    style={{
                                      color: "#FDB913",
                                      cursor: "pointer",
                                      marginRight: 8,
                                      fontSize: "22px",
                                    }}
                                  />

                                  <CloseOutlined
                                    onClick={resetPaintWageRateValue}
                                    style={{
                                      color: "#FDB913",
                                      cursor: "pointer",
                                      fontSize: "22px",
                                    }}
                                  />
                                </div>
                              ) : (
                                <EditOutlined
                                  onClick={() => setPaintWageRateEdit(true)}
                                  style={{
                                    color: "#FDB913",
                                    cursor: "pointer",
                                    fontSize: "26px",
                                    marginTop: 10,
                                    marginLeft: 8,
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <h3 style={{ marginBottom: 5 }}>
                            Carpentry Wage Rate:{" "}
                          </h3>
                          <Row>
                            <Col span={18}>
                              <Input
                                className="location-input"
                                // style={{
                                //   width: "60px",
                                //   height: "30px",
                                //   background: "#ffffff",
                                // }}
                                type="number"
                                value={carpentryWageRateValue}
                                onChange={(e) =>
                                  setCarpentryWageRateValue(e.target.value)
                                }
                                disabled={!carpentryWageRateEdit}
                              />
                            </Col>
                            <Col span={6}>
                              {carpentryWageRateEdit ? (
                                <div style={{ marginTop: 14, marginLeft: 5 }}>
                                  <CheckOutlined
                                    onClick={handleCarpentryWageRateUpdate}
                                    style={{
                                      color: "#FDB913",
                                      cursor: "pointer",
                                      marginRight: 8,
                                      fontSize: "22px",
                                    }}
                                  />

                                  <CloseOutlined
                                    onClick={resetCarpentryWageRateValue}
                                    style={{
                                      color: "#FDB913",
                                      cursor: "pointer",
                                      fontSize: "22px",
                                    }}
                                  />
                                </div>
                              ) : (
                                <EditOutlined
                                  onClick={() => setCarpentryWageRateEdit(true)}
                                  size={60}
                                  style={{
                                    color: "#FDB913",
                                    cursor: "pointer",
                                    fontSize: "26px",
                                    marginTop: 10,
                                    marginLeft: 8,
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="source-container">
                    <h1 className="checklist-subtitle">Source Location</h1>
                    <div style={{ marginTop: "20px " }}>
                      <Row gutter={15}>
                        <Col span={15}>
                          <Form.Item
                            style={{ margin: 0 }}
                            name="waterSourceLocation"
                            initialValue={savedAnswers?.waterSourceLocation}
                            label={
                              <h3 style={{ margin: 0 }}>
                                Water Source Location (take photo/validate
                                operation){" "}
                              </h3>
                            }
                          >
                            <Input
                              className="location-input"
                              placeholder="Location"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      {online && (
                        <Row>
                          {props.loading ? (
                            <Row justify="start">
                              <Spin
                                tip="Loading..."
                                size="large"
                                style={{ marginTop: 20 }}
                              />
                            </Row>
                          ) : (
                            <Form.Item
                              label={
                                <h3
                                  style={{
                                    fontWeight: "bold",
                                    marginTop: 16,
                                    marginBottom: 0,
                                  }}
                                >
                                  Upload photo
                                </h3>
                              }
                            >
                              <Row
                                justify="start"
                                align="middle"
                                className="upload-photo-container"
                              >
                                <Upload
                                  accept="image/png, image/jpeg"
                                  defaultFileList={[
                                    ...props.waterSourceLocationPhotoList,
                                  ]}
                                  onPreview={onPreview}
                                  onChange={onWaterLocationUpload}
                                  onRemove={handleWaterLocationDelete}
                                  beforeUpload={handleBeforeUpload}
                                  maxCount={1}
                                  action={`${API_BASE}upload-files/onsite-water-media/${items[currentProject]?.projectId}`}
                                  headers={{
                                    Authorization: "Bearer " + user.accessToken,
                                    "tenant-id": !isNull(tenantId)
                                      ? tenantId
                                      : "",
                                  }}
                                  listType="picture-card"
                                  disabled={previewLoading}
                                >
                                  {props.waterSourceLocationPhotoList.length >=
                                    1 ? null : (
                                    <div>+ Upload</div>
                                  )}
                                </Upload>
                                {previewLoading && (
                                  <div
                                    id={`waterSourceDiv-${props.waterSourceLocationPhotoList.length}`}
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.5)",
                                    }}
                                  >
                                    <Spin />
                                  </div>
                                )}
                                <Modal
                                  open={!preview}
                                  width={imageDimensions.width}
                                  title={"Preview Image"}
                                  footer={null}
                                  onCancel={handleCancel}
                                  bodyStyle={{ background: "transparent" }}
                                >
                                  <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={imageSource}
                                    onLoad={handleImageLoad}
                                  />
                                </Modal>
                              </Row>
                            </Form.Item>
                          )}
                        </Row>
                      )}

                      <Row gutter={15} style={{ marginTop: "20px" }}>
                        <Col span={15}>
                          <Form.Item
                            initialValue={
                              savedAnswers?.electricalSourceLocation
                            }
                            name="electricalSourceLocation"
                            label={
                              <h3 style={{ margin: 0 }}>
                                Electrical Source Location (take photo/validate
                                operation)
                              </h3>
                            }
                          >
                            <Input
                              className="location-input"
                              placeholder="Location"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      {online && (
                        <Row>
                          {props.loading ? (
                            <Row justify="start">
                              <Spin
                                tip="Loading..."
                                size="large"
                                style={{ marginTop: 0 }}
                              />
                            </Row>
                          ) : (
                            <Form.Item
                              label={
                                <h3
                                  style={{
                                    fontWeight: "bold",
                                    marginTop: 0,
                                    marginBottom: 0,
                                  }}
                                >
                                  Upload photo
                                </h3>
                              }
                            >
                              <Row
                                justify="start"
                                align="middle"
                                className="upload-photo-container"
                              >
                                <Upload
                                  accept="image/png, image/jpeg"
                                  defaultFileList={[
                                    ...props.electricalSourceLocationPhotoList,
                                  ]}
                                  onPreview={onPreview}
                                  onChange={onElectricalLocationUpload}
                                  onRemove={handleElectricalLocationDelete}
                                  beforeUpload={handleBeforeUpload}
                                  maxCount={1}
                                  action={`${API_BASE}upload-files/onsite-electric-media/${items[currentProject]?.projectId}`}
                                  headers={{
                                    Authorization: "Bearer " + user.accessToken,
                                    "tenant-id": !isNull(tenantId)
                                      ? tenantId
                                      : "",
                                  }}
                                  listType="picture-card"
                                  disabled={previewLoading}
                                >
                                  {props.electricalSourceLocationPhotoList
                                    .length >= 1 ? null : (
                                    <div>+ Upload</div>
                                  )}
                                </Upload>
                                {previewLoading && (
                                  <div
                                    id={`electricalSourceDiv-${props.electricalSourceLocationPhotoList.length}`}
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.5)",
                                    }}
                                  >
                                    <Spin />
                                  </div>
                                )}
                                <Modal
                                  open={!preview}
                                  width={imageDimensions.width}
                                  title={"Preview Image"}
                                  footer={null}
                                  onCancel={handleCancel}
                                  bodyStyle={{ background: "transparent" }}
                                >
                                  <img
                                    alt="example"
                                    style={{ width: "100%" }}
                                    src={imageSource}
                                    onLoad={handleImageLoad}
                                  />
                                </Modal>
                              </Row>
                            </Form.Item>
                          )}
                        </Row>
                      )}
                    </div>

                    <div
                      className="customize-select-height"
                      style={{ marginTop: 20 }}
                    >
                      <Row justify="space-between">
                        <Col>
                          <h1 className="checklist-subtitle">
                            Inspection of failure
                          </h1>
                        </Col>
                        <Col>
                          <Form.Item
                            initialValue={
                              savedAnswers?.failureInspectionCompleted
                            }
                            valuePropName="checked"
                            name="failureInspectionCompleted"
                          >
                            <Checkbox
                              value={inspectionCompleted}
                              onChange={onInspectionChange}
                            >
                              <h3>Inspection for Failures Completed? </h3>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <div style={{ marginTop: "25px" }}>
                        <Row gutter={9}>
                          <Col span={12}>
                            <Form.Item
                              initialValue={savedAnswers?.failureExtent}
                              name="failureExtent"
                              rules={[
                                {
                                  required: inspectionCompleted,
                                  message: "Please select an option",
                                },
                              ]}
                              label={
                                <h3 style={{ margin: 0 }}>
                                  To what extent is the failure?{" "}
                                </h3>
                              }
                            >
                              <Select
                                size="large"
                                placeholder="Select"
                                style={{ width: "100%" }}
                              >
                                <Option value="0%">0%</Option>
                                <Option value="10%">10%</Option>
                                <Option value="20%">20%</Option>
                                <Option value="30%">30%</Option>
                                <Option value="40%">40%</Option>
                                <Option value="50%">50%</Option>
                                <Option value="60%">60%</Option>
                                <Option value="70%">70%</Option>
                                <Option value="80%">80%</Option>
                                <Option value="90%">90%</Option>
                                <Option value="100%">100%</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="failureLocation"
                              initialValue={savedAnswers?.failureLocation}
                              rules={[
                                {
                                  required: inspectionCompleted,
                                  message: "Please enter the building!",
                                },
                                checkEmpty,
                              ]}
                              label={
                                <h3 style={{ margin: 0 }}>
                                  Where on the building is the failure?{" "}
                                </h3>
                              }
                            >
                              <Input
                                className="location-input"
                                placeholder="Building#"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "20px" }}>
                          <Col span={12}>
                            <Form.Item
                              initialValue={savedAnswers?.failureCause}
                              name="failureCause"
                              label={
                                <h3 style={{ margin: 0 }}>
                                  What is the cause of the Failure?
                                </h3>
                              }
                              rules={[
                                {
                                  required: inspectionCompleted,
                                  message: "Please enter the cause of failure!",
                                },
                                checkEmpty,
                              ]}
                            >
                              <Input
                                className="location-input"
                                placeholder="Enter here"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div style={{ marginTop: 55 }}>
                      <Row justify="space-between">
                        <Col>
                          <h1 className="checklist-subtitle">Adhesion Test</h1>
                        </Col>
                        <Col>
                          <Form.Item
                            initialValue={savedAnswers?.adhesionTestCompleted}
                            valuePropName="checked"
                            name="adhesionTestCompleted"
                          >
                            <Checkbox>
                              <h3>Test Completed </h3>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                      <div style={{ marginTop: 25 }}>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              initialValue={savedAnswers?.adhesionTestResult}
                              name="adhesionTestResult"
                              className="customize-select-height"
                              label={<h3 style={{ margin: 0 }}>Test Result</h3>}
                            >
                              <Select
                                size="large"
                                placeholder="Select"
                                style={{ width: "100%" }}
                              >
                                <Option value="Pass">Pass</Option>
                                <Option value="Fail">Fail</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div style={{ marginTop: 50 }}>
                      <h1
                        className="checklist-subtitle"
                        style={{ marginBottom: 40 }}
                      >
                        Other checklists
                      </h1>
                      <Row gutter={10}>
                        {[
                          ...adminDefaults?.estimatorChecklistQuestionsService,
                          ...(items[currentProject]?.projectDefaults
                            ?.estimateChecklistQuestions || []),
                        ]?.map((item: any, index: number) => {
                          let spn;
                          if (item.question.length > 50) {
                            spn = 24;
                          } else {
                            spn = 24;
                          }
                          if (item.type === "radio") {
                            return (
                              <Col key={item._id} span={spn}>
                                <Form.Item
                                  style={{ marginBottom: 30 }}
                                  initialValue={
                                    savedAnswers?.otherChecklistAnswers.find(
                                      (answer: any) =>
                                        answer.question === item._id
                                    ).answer
                                  }
                                  name={`otherChecklist${item._id}`}
                                  label={
                                    <h3 style={{ margin: 0 }}>
                                      {item.question}
                                    </h3>
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select!",
                                    },
                                  ]}
                                >
                                  <Radio.Group
                                    key={index}
                                    onChange={(e: RadioChangeEvent) => {
                                      onRadioChange(e, index);
                                    }}
                                    value={radioValue[index]}
                                  >
                                    {item.answers.map(
                                      (answer: string, index: string) => {
                                        return (
                                          <Radio
                                            style={{ marginRight: 40 }}
                                            key={index}
                                            value={answer}
                                          >
                                            {answer}
                                          </Radio>
                                        );
                                      }
                                    )}
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                            );
                          } else if (item.type === "checkbox") {
                            return (
                              <Col key={item._id} span={spn}>
                                <Form.Item
                                  valuePropName="checked"
                                  initialValue={
                                    savedAnswers?.otherChecklistAnswers.find(
                                      (answer: any) =>
                                        answer.question === item._id
                                    ).answer
                                  }
                                  style={{ marginBottom: 30 }}
                                  name={`otherChecklist${item._id}`}
                                  label={
                                    <h3 style={{ margin: 0 }}>
                                      {item.question}
                                    </h3>
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select!",
                                    },
                                  ]}
                                >
                                  {item.answers.map(
                                    (answer: string, index: string) => {
                                      return (
                                        <Checkbox key={index}>
                                          {answer}
                                        </Checkbox>
                                      );
                                    }
                                  )}
                                </Form.Item>
                              </Col>
                            );
                          } else if (item.type === "text") {
                            return (
                              <Col key={item._id} span={spn}>
                                <Form.Item
                                  style={{ marginBottom: 30 }}
                                  name={`otherChecklist${item._id}`}
                                  initialValue={
                                    savedAnswers?.otherChecklistAnswers?.find(
                                      (answer: any) =>
                                        answer?.question === item._id ||
                                        answer?.question?.question ===
                                        item.question
                                    )?.answer
                                  }
                                  label={
                                    <h3 style={{ margin: 0 }}>
                                      {item.question}
                                    </h3>
                                  }
                                  rules={[
                                    {
                                      required: savedAnswers
                                        ?.otherChecklistAnswers?.length
                                        ? false
                                        : true,
                                      message: "Please enter!",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="location-input"
                                    placeholder="Enter"
                                  />
                                </Form.Item>
                              </Col>
                            );
                          }
                        })}
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="gutter-row" lg={9} sm={24}>
                <Notes />
              </Col>
            </Row>
          )}
        </>
      )}

      <div className="onsite-btn-container">
        {isTabletOrMobile ? (
          <Row gutter={12} style={{ marginBottom: "20px" }}>
            <Col lg={10} md={10}></Col>
            <Col lg={14} md={14}>
              <Button type="primary" className="save-button" htmlType="submit">
                Next
              </Button>
            </Col>
          </Row>
        ) : (
          <Row gutter={12} style={{ marginBottom: "20px" }}>
            <Col lg={6} md={10}></Col>
            <Col lg={14} md={14}>
              <Button type="primary" className="save-button" htmlType="submit">
                Next
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </Form>
  );
};

export default OnsiteChecklist;
