import React, { useEffect } from "react";
import { Modal, Button, Row, Col, Input, Form } from "antd";
import "./CommercialPopUps.less";
const { TextArea } = Input;
type Props = {
  row?: any;
  title: string;
  showSurfacePrepIntModal: boolean;
  toggleSurfacePrepIntModal: (item: any) => void;
  handleSurfaceAdd?: any;
};

const SurfacePrepInt = (props: Props) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (Object.keys(props?.row)?.length > 0) {
      form.setFieldsValue(props.row);
    } else {
      form.resetFields();
    }
  }, [props.row, props.showSurfacePrepIntModal]);

  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };

  const onFinish = (values: any) => {
    props.handleSurfaceAdd(values);
  };
  return (
    <Modal
      className="job-type-comm-modal"
      centered={true}
      visible={props.showSurfacePrepIntModal}
      onOk={props.toggleSurfacePrepIntModal}
      onCancel={props.toggleSurfacePrepIntModal}
      footer={false}
      title={
        <h1 style={{ marginTop: 10 }}>Surface preparation ({props.title})</h1>
      }
    >
      <Form
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          hasFeedback
          name="title"
          label={<h2>Add Title</h2>}
          rules={[
            {
              required: true,
              message: "Please enter the title!",
            },
            checkEmpty,
          ]}
        >
          <Input
            placeholder="Type here"
            className="default-material-form-input"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          name="detail"
          label={<h2>Add Detail</h2>}
          rules={[
            {
              required: true,
              message: "Please enter the Details!",
            },
            checkEmpty,
          ]}
        >
          <TextArea
            rows={4}
            placeholder="Type here"
            className="default-material-form-text-area"
          />
        </Form.Item>

        <Row style={{ marginTop: 70 }} gutter={10} justify="end">
          <Col>
            <Button
              className="job-type-add-comm-cancel"
              onClick={props.toggleSurfacePrepIntModal}
            >
              Cancel
            </Button>
          </Col>

          <Col>
            <Button
              className="job-type-add-comm-btn"
              htmlType="submit"
              type="primary"
            >
              {Object.keys(props?.row)?.length > 0 ? "Update" : "Add"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SurfacePrepInt;
