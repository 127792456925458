import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Layout, Steps, Button, message, Row, Col } from "antd";
import OnsiteChecklist from "../../containers/on-site-checklist/OnsiteChecklist";
import Notes from "../../containers/notes/Notes";
import PricingC from "../../containers/pricing/PricingC";
import useActiveProject from "../../utils/useActiveProject";

const { Content } = Layout;
const { Step } = Steps;
const ManagerPricing: React.FC = () => {
  useActiveProject();
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);
  const history = useHistory();

  const next = () => {
    history.push("/preview-profile");
  };

  const prev = () => {};

  const steps = [
    {
      title: "Profile",
    },
    {
      title: "On-site checklist",
    },
    {
      title: "Options setup",
    },
    {
      title: "Labor rates",
    },
    {
      title: "Options details",
    },
    {
      title: "Pricing",
    },
    {
      title: "Estimate checklist",
    },
    {
      title: "Proposal setup",
    },
    {
      title: "Proposal",
    },
  ];

  return (
    <Content>
      <div className="main--steps--holder">
        <Steps current={2}>
          <Step
            status="finish"
            className="ant-step-first-item"
            title="Sales Associate"
          />
          <Step status="finish" title="Estimator" />
          <Step status="process" title="Manager" />
          <Step status="wait" title="Production Associate" />
          <Step status="wait" title="Job Jacket" />
        </Steps>
      </div>
      <div className="main--tabs--holder">
        <Steps type="navigation" current={5} className="site-navigation-steps">
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </div>
      <div className="steps-content">
        <Row gutter={24}>
          <Col className="gutter-row" span={15}>
            <PricingC />
          </Col>
          <Col className="gutter-row" span={9}>
            <Notes />
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default ManagerPricing;
