import React, { useEffect, useState } from "react";
import { Row, Col, Input, Select } from "antd";
import "./DeliveryContact.less";
import { BulletIcon, TrashIcon } from "../../utils/icons";
import TextArea from "antd/lib/input/TextArea";
import { Rule } from "antd/lib/form";
import api from "../../utils/api";
import { MaskedInput } from "antd-mask-input";
import { useMediaQuery } from "react-responsive";

const { Option } = Select;

type Props = {
  title: string;
  addContact: (data: any) => void;
  addPhone: (data: any) => void;
  addEmail: (data: any) => void;
  users: any;
};

const DeliveryContact = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:1200px)" });

  const [emailError, setEmailError] = useState<string>("");

  const [deliveryContact, setDeliveryContact] = useState<any>({});

  const handleSelect = (value: any) => {
    const currentUser = props.users.find((user: any) => user._id === value);
    props.addContact(currentUser?.fullName);
    props.addPhone(currentUser?.phone);
    props.addEmail(currentUser?.email);
    setDeliveryContact(currentUser);
  };

  const handlePhoneChange = (e: any) => {
    props.addPhone(e.target.value);
  };

  const handleEmailChange = (e: any) => {
    validateEmail(e.target.value);
    props.addEmail(e.target.value);
  };

  const validateEmail = (value: string) => {
    if (value && !/\S+@\S+\.\S+/.test(value)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const title = props.title.split(" ");

  return (
    <>
      {isTabletOrMobile ? (
        <div className="delivery-notes-root" style={{ height: "310px" }}>
          <h1>{props.title}</h1>

          <div style={{ marginTop: "28px" }}>
            <Row justify={"space-between"}>
              <Col>
                <p>{title[0]} Contact:</p>
              </Col>
              <Col>
                <Select style={{ width: 270 }} onSelect={handleSelect}>
                  {props.users?.map((item: any) => {
                    return (
                      <Option key={item?._id} value={item._id}>
                        {item.fullName}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginTop: 5 }}>
              <Col>
                <p>Phone Number:</p>
              </Col>
              <Col>
                <MaskedInput
                  mask={"(000) 000-0000"}
                  className="form-input"
                  onChange={handlePhoneChange}
                  style={{ width: 270, borderRadius: 5, height: 30 }}
                  defaultValue={deliveryContact.phone}
                  // value={deliveryContact.phone}
                />
              </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginTop: 5 }}>
              <Col>
                <p>Email Address:</p>
              </Col>
              <Col>
                <Input
                  onChange={handleEmailChange}
                  style={{ width: 270, borderRadius: 5 }}
                  value={deliveryContact.email}
                />
                {emailError && (
                  <div
                    style={{ color: "red", marginBottom: 0, paddingBottom: 0 }}
                  >
                    {emailError}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="delivery-notes-root">
          <h1>{props.title}</h1>

          <div style={{ marginTop: "28px" }}>
            <Row justify={"space-between"}>
              <Col>
                <p>{title[0]} Contact:</p>
              </Col>
              <Col>
                <Select style={{ width: 270 }} onSelect={handleSelect}>
                  {props.users?.map((item: any) => {
                    return (
                      <Option key={item?._id} value={item._id}>
                        {item.fullName}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginTop: 5 }}>
              <Col>
                <p>Phone Number:</p>
              </Col>
              <Col>
                <MaskedInput
                  mask={"(000) 000-0000"}
                  className="form-input"
                  onChange={handlePhoneChange}
                  style={{ width: 270, borderRadius: 5, height: 30 }}
                  defaultValue={deliveryContact.phone}
                  // value={deliveryContact.phone}
                />
              </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginTop: 5 }}>
              <Col>
                <p>Email Address:</p>
              </Col>
              <Col>
                <Input
                  onChange={handleEmailChange}
                  style={{ width: 270, borderRadius: 5 }}
                  value={deliveryContact.email}
                />
                {emailError && (
                  <div
                    style={{ color: "red", marginBottom: 0, paddingBottom: 0 }}
                  >
                    {emailError}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

function propsAreEqual(prevProps: any, props: any) {
  return prevProps.title === props.title && prevProps.users === props.users;
}

export default React.memo(DeliveryContact, propsAreEqual);
