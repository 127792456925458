import { Form, Input, InputNumber, Select } from "antd";
import _ from "lodash";
import { useState, useEffect } from "react";
const { Option } = Select;
interface EquipmentEditableProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: any;
  index: number;
  form: any;
  children: React.ReactNode;
  data: any;
  setDataRates: any;
}

const EquipmentEditable: React.FC<EquipmentEditableProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  form,
  record,
  index,
  children,
  data,
  setDataRates,
  ...restProps
}) => {
  const [select, setSelect] = useState(false);
  let inputNode =
    inputType === "number" ? <InputNumber type="number" /> : <Input />;

  useEffect(() => {
    dataIndex === "type" && setSelect(true);
  }, []);

  const handleSelect = (value: string) => {
    if (record.defaultRate?.hasIntervals) {
      const index = data.findIndex((item: any) => item._id === record._id);
      switch (value) {
        case "daily":
          form.setFieldsValue({
            cost:
              record.defaultRate.dailyCost +
              record.surcharges +
              record.puAndDelivery,
          });
          break;
        case "weekly":
          form.setFieldsValue({
            cost:
              record.defaultRate.weeklyCost +
              record.surcharges +
              record.puAndDelivery,
          });
          break;
        case "monthly":
          form.setFieldsValue({
            cost:
              record.defaultRate.monthlyCost +
              record.surcharges +
              record.puAndDelivery,
          });
          break;
        case "other":
          break;
        default:
          break;
      }

      let newData = _.cloneDeep(data);
      newData[index].type = value;
      setDataRates(newData);
    }
  };

  return (
    <>
      <td {...restProps}>
        {editing ? (
          <>
            {!select ? (
              <Form.Item
                name={dataIndex}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: `Please enter ${title}!`,
                  },
                ]}
              >
                {inputNode}
              </Form.Item>
            ) : (
              <Select
                style={{ width: 150 }}
                dropdownMatchSelectWidth={false}
                showArrow={false}
                value={record.defaultRate?.hasIntervals ? record.type : "daily"}
                onChange={handleSelect}
              >
                <Option value="daily">Daily</Option>
                <Option value="weekly">Weekly</Option>
                <Option value="monthly">Monthly</Option>
                <Option value="other">Other</Option>
              </Select>
            )}
          </>
        ) : (
          children
        )}
      </td>
    </>
  );
};

export default EquipmentEditable;
