import React from 'react';
import { Row, Col, Button, Table } from 'antd';
import { columns } from './config';
import '../PaintSurfacesDefaults.less';

type Props = {};

const EquipmentDefaults = (props: Props) => {
  const data = new Array(6).fill({
    item: 'WR: Deck - 1x2 AC2',
    quantity: '100',
    type: 'EA',
    periods: 'null',
    cost: '$1.41',
    tax: '$0.17',
    action: '',
  });
  return (
    <div style={{ marginTop: 75 }}>
      <Row className="paint-surface-defaults-container" justify="space-between">
        <Col>
          <h1>Equipment</h1>
        </Col>
        <Col>
          <Button className="labor-rates-defaults-buttons">
            Add Equipment
          </Button>
        </Col>
      </Row>
      <Table dataSource={data} columns={columns} pagination={false} />
    </div>
  );
};

export default EquipmentDefaults;
