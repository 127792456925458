import React, { useEffect } from "react";
import { Layout, Steps, Row, Col } from "antd";
import OptionDetailsC from "../../containers/OptionDetails/OptionDetails";
import ManagerSteps from "./ManagerSteps";
const { Content } = Layout;
const { Step } = Steps;
const ManagerOptionDetails: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);
  return (
    <Content>
      <ManagerSteps current={3} />
      <div className="steps-content">
        <Row>
          <Col className="gutter-row" span={24}>
            <OptionDetailsC path="manager-pjcc" />
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default ManagerOptionDetails;
