import { useState } from "react";
import { Row, Col, Input, Image } from "antd";
import { BulletIcon, CorrectionIcon } from "../../utils/icons";
import "./ContractSetup.less";

type Props = {
  data: {
    _id: string;
    svgString: string;
    title: string;
    description: string;
    position: number;
    addToProjects: boolean;
    isDefault: boolean;
  };
  correction: string;
};

const CorrectionPeriod = ({ data, correction }: Props) => {
  return (
    <div className="equipment-root">
      <Row gutter={20} className="cd-margin-btm">
        <Col>
          <Image
            src={data.svgString}
            style={{
              paddingTop: "0px",
            }}
            preview={false}
          />
        </Col>
        <Col>
          <h1>Correction Period</h1>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <pre
            className="contract-defaults-description"
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          />
        </Col>
      </Row>

      {correction && (
        <Row gutter={10}>
          <Col span={0.1}>
            <BulletIcon />
          </Col>
          <Col span={23}>
            <p>{correction}</p>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default CorrectionPeriod;
