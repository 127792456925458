import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spin, message } from "antd";
import "./ContractDefaultContainer.less";
import ContractDefaultCard from "../../components/contract-default-card/ContractDefaultCard";
import AddEditContractDefaultCard from "../../components/contract-default-card/AddEditContractDefaultCard";
import api from "../../utils/api";
import EditStartCompletionDateCard from "../../components/contract-default-card/EditStartCompletionDateCard";

interface ContractDefaultDTO {
  _id: string;
  svgString: string;
  title: string;
  description: string;
  startAndCompletionDate?: {
    startDateFirmString: string;
    startDateFlexibleString: string;
    completionDateFirmString: string;
    completionDateFlexibleString: string;
  };
  position: number;
  addToProjects: boolean;
  isDefault: boolean;
  isEditable: boolean;
  edit?: boolean;
}

function ContractDefaultContainer() {
  const [loading, setLoading] = useState(true);
  const [openAddContractDefaultComponent, setOpenAddContractDefaultComponent] =
    useState(false);
  const [contractDefaults, setContractDefaults] = useState<
    ContractDefaultDTO[]
  >([]);

  console.log("Container", contractDefaults);

  useEffect(() => {
    api
      .get("contract-defaults")
      .then((response: any) => {
        setContractDefaults(
          response.map((item: ContractDefaultDTO) => {
            return {
              ...item,
              edit: false,
            };
          })
        );
        setLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const setShowEditComponent = (dataId: string) => {
    setContractDefaults(
      contractDefaults.map((item: ContractDefaultDTO) =>
        item._id === dataId ? { ...item, edit: false } : item
      )
    );
  };

  const handleOpenEditComponent = (dataId: string) => {
    setContractDefaults(
      contractDefaults.map((item: ContractDefaultDTO) =>
        item._id === dataId ? { ...item, edit: true } : item
      )
    );
  };

  const handleContractDefaultAddToProject = (
    dataId: string,
    checked: boolean
  ) => {
    api
      .patch(`contract-defaults/add-to-projects/${dataId}`, {
        addToProjects: checked,
      })
      .then((response: any) => {
        setContractDefaults(
          contractDefaults.map((item: ContractDefaultDTO) =>
            item._id === dataId
              ? { ...item, addToProjects: response.addToProjects }
              : item
          )
        );
      })
      .catch((error: any) => {
        console.log(error);
        message.error("Something Went Wrong");
      });
  };

  const handleContractDefaultPosition = (
    dataId: string,
    step: "up" | "down"
  ) => {
    const sortedContractDefaults = [...contractDefaults].sort(
      (a, b) => a.position - b.position
    );
    const index = sortedContractDefaults.findIndex(
      (item) => item._id === dataId
    );

    if (
      index === -1 ||
      (step === "up" && index === 0) ||
      (step === "down" && index === sortedContractDefaults.length - 1)
    ) {
      return; // No operation if out of bounds or not found.
    }

    const newIndex = step === "up" ? index - 1 : index + 1;

    const itemToMove = sortedContractDefaults[index];
    const adjacentItem = sortedContractDefaults[newIndex];
    const tempPosition = itemToMove.position;
    itemToMove.position = adjacentItem.position;
    adjacentItem.position = tempPosition;

    [sortedContractDefaults[index], sortedContractDefaults[newIndex]] = [
      adjacentItem,
      itemToMove,
    ];

    const patchReqBody = [
      {
        _id: itemToMove._id,
        position: itemToMove.position,
      },
      {
        _id: adjacentItem._id,
        position: adjacentItem.position,
      },
    ];

    console.log(sortedContractDefaults, patchReqBody);
    api
      .patch(
        `contract-defaults/update-contract-defaults-positions`,
        patchReqBody
      )
      .then((response: any) => {
        console.log(response);
        setContractDefaults(sortedContractDefaults);
      })
      .catch((error: any) => {
        console.log(error);
        message.error("Something Went Wrong");
      });
  };

  return (
    <div>
      <Row
        className="contract-default-header"
        align="middle"
        justify="space-between"
      >
        <Col>
          <h1 style={{ paddingTop: "8px" }}>Contract Defaults</h1>
        </Col>
        <Col>
          <Button
            className="contract-add"
            type="primary"
            onClick={() => setOpenAddContractDefaultComponent(true)}
          >
            + Add
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          {[...contractDefaults]
            .sort((a, b) => a.position - b.position)
            .map((item: ContractDefaultDTO, index: number) => (
              <div className="contract-default-root" key={index}>
                {item.edit === false ? (
                  <ContractDefaultCard
                    data={item}
                    handleOpenEditComponent={handleOpenEditComponent}
                    handleAddToProjects={handleContractDefaultAddToProject}
                    handleContractDefaultPosition={
                      handleContractDefaultPosition
                    }
                  />
                ) : item.title === "Start/Completion Date" ? (
                  <EditStartCompletionDateCard
                    dataToBeEdited={item}
                    setShowStartCompletionDateCard={setShowEditComponent}
                    setContractDefaults={setContractDefaults}
                  />
                ) : (
                  <AddEditContractDefaultCard
                    dataToBeEdited={item}
                    setShowEditComponent={setShowEditComponent}
                    setContractDefaults={setContractDefaults}
                    currentNumberOfContractDefaults={contractDefaults.length}
                  />
                )}
              </div>
            ))}
          {openAddContractDefaultComponent && (
            <div className="contract-default-add-edit-modal">
              <AddEditContractDefaultCard
                setShowComponent={setOpenAddContractDefaultComponent}
                setContractDefaults={setContractDefaults}
                currentNumberOfContractDefaults={contractDefaults.length}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ContractDefaultContainer;
