import React from 'react';
import { Card, Button, Row, Col, Typography } from 'antd';
import { CertaproIcon } from '../utils/icons';
import { Link } from 'react-router-dom';
const { Text } = Typography;

type Props = {};

const PageNotFound = (props: Props) => {
  return (
    <Row justify="center">
      <Col>
        <Row justify="center">
          <Col style={{ marginTop: '40%', marginBottom: '20px' }}>
            <CertaproIcon />
          </Col>
        </Row>

        <Card style={{ padding: '50px' }}>
          <Row justify="center" align="middle">
            <Col>
              <h1>404</h1>
            </Col>
          </Row>
          <Row justify="center" align="middle">
            <Col>
              <h1>PAGE NOT FOUND</h1>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <Link to="/">Go to Home </Link>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default PageNotFound;
