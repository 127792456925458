import axios, { Method } from "axios";

import { getAuthUser, getSelectedTenant } from "./authToken";
import { isNull } from "lodash";

function createHeaders(contentType: string | null = null) {
  const user = getAuthUser();
  const tenantId = getSelectedTenant();
  const headers: Record<string, string> = {
    Authorization: user.accessToken ? `Bearer ${user?.accessToken}` : "",
    "user-role": user?.roles[0],
  };

  if (contentType) {
    headers["Content-Type"] = contentType;
  }

  if (tenantId !== null && tenantId !== undefined) {
    headers["tenant-id"] = tenantId;
  }

  return headers;
}

export function getHeaders() {
  return createHeaders("application/json");
}

export function getCsvUploadHeaders() {
  return createHeaders();
}

const defaults = {
  baseURL: process.env.REACT_APP_BACKEND_URL || "http://localhost:3000/",
  headers: () => {
    const user = getAuthUser();
    const tenantId = getSelectedTenant();
    return {
      "Content-Type": "application/json",
      Authorization: user.accessToken ? "Bearer " + user.accessToken : "",
      "tenant-id": !isNull(tenantId) ? tenantId : "",
      "user-role": user.roles[0],
    };
  },
  error: {
    code: "INTERNAL_ERROR",
    message:
      "Something went wrong. Please check your internet connection or contact our support.",
    status: 503,
    data: {},
  },
};

const api = (url: string, variables: any, method: Method, tenantId?: any) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${defaults.baseURL}${url}`,
      method,
      headers: getHeaders(),
      params: method === "get" ? variables : undefined,
      data: method !== "get" ? variables : undefined,
    }).then(
      (response: any) => {
        resolve(response.data);
      },
      (error: any) => {
        reject(defaults.error);
      }
    );
  });

export default {
  get: (url: string, variables?: any, method?: any) =>
    api(url, variables, "get"),
  post: (url: string, variables: any, method?: any) =>
    api(url, variables, "post"),
  put: (url: string, variables: any, method?: any) =>
    api(url, variables, "put"),
  patch: (url: string, variables: any, method?: any) =>
    api(url, variables, "patch"),
  delete: (url: string, variables?: any, method?: any) =>
    api(url, variables, "delete"),
};
