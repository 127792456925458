import { Input, Select } from "antd";
import { MoreVerticalIcon } from "../../../utils/icons";

export const columns = [
  {
    title: "Carpentry Item",
    dataIndex: "carpentryItem",
    key: "carpentryItem",
    width: "200px",
    render: (data: any) => {
      return <Input defaultValue={data} />;
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: "100px",
    render: (data: any) => {
      return <Input defaultValue={data} />;
    },
  },
  {
    title: "Labor/Unit",
    dataIndex: "laborUnit",
    key: "laborUnit",
    width: "120px",
    render: (data: any) => {
      return <Input defaultValue={data} />;
    },
  },
  {
    title: "Material/Unit",
    dataIndex: "materialUnit",
    key: "materialUnit",

    render: (data: any) => {
      return <Input style={{ width: 90 }} defaultValue={data} />;
    },
  },
  {
    title: "Action",
    key: "action",
    width: "80px",
    render: (data: any) => {
      return <MoreVerticalIcon />;
    },
  },
];
