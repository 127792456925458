import React, { useEffect } from "react";
import { Layout, Button, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import CheckRequestC from "../../containers/check-request/CheckRequestC";
import ProductionSteps from "./ProductionSteps";
import useSyncProjectData from "../../utils/useSyncProjectData";
import { useParams } from "react-router-dom";
import { Spin } from "antd";

const { Content } = Layout;

const CheckRequest: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);
  const history = useHistory();
  const { id } = useParams<any>();
  const [isLoading] = useSyncProjectData(id);
  const next = () => {
    // history.push('/proposal');
  };

  const prev = () => {};
  return (
    <>
      {isLoading === true ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <Content>
          <ProductionSteps current={2} />
          <div className="steps-content">
            <Row gutter={24}>
              <Col className="gutter-row" lg={24}>
                <CheckRequestC />
              </Col>
            </Row>
          </div>
        </Content>
      )}
    </>
  );
};

export default CheckRequest;
