import React, { useEffect } from "react";
import { Modal, Button, Row, Col, Select, Input, Form } from "antd";
import "./SourceLocation.less";
type Props = {
  handlePost?: any;
  edit?: boolean;
  value?: string;
  showSourceLocationModal: boolean;
  toggleSourceLocationModal: (item: any) => void;
};

const SourceLocationModal = (props: Props) => {
  const [form] = Form.useForm();
  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };

  useEffect(() => {
    if (props.edit) {
      form.setFieldValue("question", props?.value);
    }
  }, [props]);

  const onFinish = (values: any) => {
    props.handlePost(values);
    form.resetFields();
  };
  return (
    <Modal
      className="source-location-modal"
      centered={true}
      visible={props.showSourceLocationModal}
      onOk={props.toggleSourceLocationModal}
      onCancel={props.toggleSourceLocationModal}
      footer={false}
      title={
        <h1 style={{ marginTop: 10 }}>
          {props?.edit ? "Edit" : "Add"} On-Site Checklist
        </h1>
      }
    >
      <Form
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          hasFeedback
          name="question"
          label={<h2>Enter {props?.edit ? "updated" : "new"} default</h2>}
          rules={[
            {
              required: true,
              message: "Please enter!",
            },
            checkEmpty,
          ]}
        >
          <Input
            placeholder="Type here"
            className="source-location-form-input"
          />
        </Form.Item>

        <Row style={{ marginTop: 60 }} gutter={10} justify="end">
          <Col>
            <Button
              className="source-location-cancel "
              onClick={props.toggleSourceLocationModal}
            >
              Cancel
            </Button>
          </Col>

          <Col>
            <Button
              className="source-location-add"
              htmlType="submit"
              type="primary"
            >
              {props?.edit ? "Update" : "Add"}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SourceLocationModal;
