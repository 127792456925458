import { Card, Col, Dropdown, Menu, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { DashOutlined, HddOutlined } from "@ant-design/icons";
import moment from "moment";
import { data } from "../available-jobs/data";
import { MoreActionIcon } from "../../utils/icons";
import { setActiveProject } from "../../redux/project/action";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

interface JobPostingCardProps {
  item: any;
}

const JobPostingCard: React.FC<JobPostingCardProps> = ({ item }) => {
  console.log(item, "here");

  const isTabletOrMobile = useMediaQuery({ query: "(min-width:440px)" });

  const history = useHistory();
  const dispatch = useDispatch();

  const [projects, setProjects] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [projectIndex, setProjectIndex] = useState(-1);
  const { items } = useSelector((state: RootStateOrAny) => state.offlineData);

  const handleOk = () => {
    setShowModal(false);
  };
  const handleModal = (data: any) => {
    let index = projects.findIndex(
      (item: any) => item.project._id === data.project._id
    );
    setProjectIndex(index);
    setShowModal(true);
  };

  const handleProjectSelect = (project: any) => {
    const activeProjectIndex = items.findIndex(
      (data: any) => data.projectId === project._id
    );

    dispatch(setActiveProject(activeProjectIndex));
    history.push(`/contractor-media/${project._id}`);
  };

  return (
    <>
      <Modal
        title={
          <span style={{ fontWeight: "bold", fontSize: "20px" }}>
            Apply for job
          </span>
        }
        centered
        visible={showModal}
        okText="Yes"
        cancelText="No"
        onOk={handleOk}
        onCancel={() => setShowModal(false)}
        width={"80%"}
      >
        <div>
          <h3 style={{ fontSize: "20px" }}>
            Please confirm that you'll be able to complete the project during
            the stated timeframe before submitting the application:
          </h3>
          {projects[projectIndex]?.startDateFirmOrFlexible === "firm" ? (
            <h3 style={{ fontSize: "20px" }}>
              Subcontractor shall start the Project on:{" "}
              {projects[projectIndex]?.projectStartDate && (
                <>
                  {moment(projects[projectIndex]?.projectStartDate).format(
                    "MM-DD-YYYY"
                  )}{" "}
                  ?
                </>
              )}
            </h3>
          ) : (
            <h3 style={{ fontSize: "20px" }}>
              Subcontractor shall start the Project within 3 days of:{" "}
              {projects[projectIndex]?.projectStartDate && (
                <>
                  {moment(projects[projectIndex]?.projectStartDate).format(
                    "MM-DD-YYYY"
                  )}{" "}
                  ?
                </>
              )}
            </h3>
          )}
        </div>
        <div style={{ border: "5px solid black" }}>
          {projects[projectIndex]?.completionDateFirmOrFlexible === "firm" ? (
            <h3>
              Subcontractor shall complete the entire Project by no later than:{" "}
              {projects[projectIndex]?.jobCompleteDate && (
                <>
                  {moment(projects[projectIndex]?.jobCompleteDate).format(
                    "MM-DD-YYYY"
                  )}{" "}
                  ?
                </>
              )}
            </h3>
          ) : (
            <h3 style={{ fontSize: "20px" }}>
              Subcontractor shall complete the entire Project within 7 days of:{" "}
              {projects[projectIndex]?.jobCompleteDate && (
                <>
                  {moment(projects[projectIndex]?.jobCompleteDate).format(
                    "MM-DD-YYYY"
                  )}{" "}
                  ?
                </>
              )}
            </h3>
          )}
        </div>
      </Modal>
      {isTabletOrMobile ? (
        <Card
          style={{
            border: "1px solid #FDB913",
            padding: "10px 10px 10px 10px",
            width: "45%",
            marginLeft: "20px",
          }}
        >
          <Row style={{ marginTop: "20px", display: "contents" }}>
            <div style={{ display: "flex", cursor: "pointer" }}>
              <Col offset={1} span={5}>
                <img
                  onClick={() => handleProjectSelect(item?.project)}
                  src={`data:image/svg+xml;base64,${Buffer.from(
                    item?.project?.segment?.segmentIcon
                  ).toString("base64")}`}
                  alt="project"
                  width="49"
                  height="49"
                />
              </Col>
              <Col span={12} style={{ marginRight: "4px" }}>
                <div onClick={() => handleProjectSelect(item?.project)}>
                  {item?.project?.jobName}
                </div>
                <div style={{ color: "gray" }}>
                  {`${item?.project?.city}, ${item?.project?.zip}`}
                </div>
              </Col>
              <Col offset={2} span={2} style={{ marginRight: "0px" }}>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => handleModal(data)}
                        style={{
                          fontWeight: 700,
                          fontSize: "15px",
                          lineHeight: "18px",
                          color: "#333333",
                        }}
                      >
                        <span className="nav-text">Apply For Job</span>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <button className="action-button">
                    <MoreActionIcon />
                  </button>
                </Dropdown>
              </Col>
            </div>
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <Col offset={1} xs={6} style={{ marginLeft: "30px" }}>
              <p style={{ color: "gray" }}>Labor Price</p>
            </Col>
            <Col xs={6} style={{ marginLeft: "50px" }}>
              <p style={{ color: "gray" }}>Segment</p>
              <p style={{ fontWeight: "bold" }}>
                {item?.project?.segment?.segmentName}
              </p>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col offset={1} xs={6} style={{ marginLeft: "30px" }}>
              <p style={{ color: "gray" }}>Start Date</p>
              <p style={{ fontWeight: "bold" }}>
                {moment(item?.projectStartDate).format("MMM DD, YYYY")}
              </p>
            </Col>
            <Col xs={6} style={{ marginLeft: "50px" }}>
              <p style={{ color: "gray" }}>Completion Date</p>
              <p style={{ fontWeight: "bold" }}>
                {moment(item?.jobCompleteDate).format("MMM DD, YYYY")}
              </p>
            </Col>
          </Row>
        </Card>
      ) : (
        <Card
          style={{
            border: "1px solid #FDB913",
            padding: "10px 10px 10px 10px",
          }}
        >
          <Row style={{ marginTop: "20px", display: "contents" }}>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
              }}
            >
              <Col offset={1} span={5}>
                <img
                  onClick={() => handleProjectSelect(item?.project)}
                  src={`data:image/svg+xml;base64,${Buffer.from(
                    item?.project?.segment?.segmentIcon
                  ).toString("base64")}`}
                  alt="project"
                  width="49"
                  height="49"
                />
              </Col>
              <Col span={12} style={{ marginRight: "4px" }}>
                <div onClick={() => handleProjectSelect(item?.project)}>
                  {item?.project?.jobName}
                </div>
                <div style={{ color: "gray" }}>
                  {`${item?.project?.city}, ${item?.project?.zip}`}
                </div>
              </Col>
              <Col offset={2} span={2} style={{ marginRight: "0px" }}>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => handleModal(data)}
                        style={{
                          fontWeight: 700,
                          fontSize: "15px",
                          lineHeight: "18px",
                          color: "#333333",
                        }}
                      >
                        <span className="nav-text">Apply For Job</span>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <button className="action-button">
                    <MoreActionIcon />
                  </button>
                </Dropdown>
              </Col>
            </div>
          </Row>

          <Row style={{ marginTop: "10px" }}>
            <Col offset={1} span={12} sm={12}>
              <p style={{ color: "gray" }}>Labor Price</p>
            </Col>
            <Col sm={12}>
              <p style={{ color: "gray" }}>Segment</p>
              <p style={{ fontWeight: "bold" }}>
                {item?.project?.segment?.segmentName}
              </p>
            </Col>
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <Col offset={1} span={12} sm={12}>
              <p style={{ color: "gray" }}>Start Date</p>
              <p style={{ fontWeight: "bold" }}>
                {moment(item?.projectStartDate).format("MMM DD, YYYY")}
              </p>
            </Col>
            <Col sm={12}>
              <p style={{ color: "gray" }}>Completion Date</p>
              <p style={{ fontWeight: "bold" }}>
                {moment(item?.jobCompleteDate).format("MMM DD, YYYY")}
              </p>
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default JobPostingCard;
