import {
  Avatar,
  Col,
  Row,
  Form,
  Input,
  Button,
  Upload,
  message,
  Progress,
  Spin,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { updateProject } from "../../redux/project/action";
import { updateUser } from "../../redux/user/action";
import { API_BASE } from "../../constant";
import { EditUploadIcon } from "../../utils/icons";
import { MaskedInput } from "antd-mask-input";
import "./SettingC.less";
import { useMediaQuery } from "react-responsive";
import { getSelectedTenant } from "../../utils/authToken";
import { isNull } from "lodash";
const { TextArea } = Input;

type Props = {};

const SettingC = (props: Props) => {
  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  const tenantId = getSelectedTenant();
  const [loading, setLoading] = useState(false);
  const [updateBtnDisable, setUpdateBtnDisable] = useState(false);
  const [saveBtnDisable, setSaveBtnDisable] = useState(false);
  const [imageUrl, setImageUrl] = useState(user.picture);
  const [inCorrectPass, setInCorrectPassword] = useState("");
  const userLocalStorage = JSON.parse(localStorage.getItem("user") || "{}");
  const [form] = Form.useForm();
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:800px)" });

  const [passwordForm] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = (values: any) => {
    setSaveBtnDisable(true);
    axios
      .put(
        `${API_BASE}user`,
        {
          fullName: values.fullName,
          email: values.email,
          phone: values.phone,
          location: values.location,
          jobTitle: values.jobTitle,
          jobDescription: values.jobDescription,
        },
        {
          headers: {
            Authorization: "Bearer " + userLocalStorage.accessToken,
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
        }
      )
      .then((resp) => {
        dispatch(updateUser({ ...resp.data, accessToken: user.accessToken }));
        setSaveBtnDisable(false);
        message.success("Profile successfully updated");
      })
      .catch((err) => {
        console.log(err);
        setSaveBtnDisable(false);
      });
  };

  function getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      const updatedObject = {
        ...info.file.response,
        picture: `${info.file.response.picture}?timestamp=${Date.now()}`,
        accessToken: user.accessToken,
      };
      dispatch(updateUser(updatedObject));
      getBase64(info.file.originFileObj, (imageUrl: any) => {
        return setImageUrl(imageUrl), setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  const handleUpdatePassword = (values: any) => {
    setUpdateBtnDisable(true);

    axios
      .patch(
        `${API_BASE}user/update-password`,
        {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
        },
        {
          headers: {
            Authorization: "Bearer " + userLocalStorage.accessToken,
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
        }
      )
      .then((resp) => {
        if (resp.status === 200) {
          passwordForm.resetFields();
          setUpdateBtnDisable(false);
          setInCorrectPassword("");
          message.success("Password successfully updated");
        }
      })
      .catch((err) => {
        setUpdateBtnDisable(false);
        setInCorrectPassword("Incorrect Password");
      });
  };

  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }
      return Promise.resolve();
    },
  };

  const getInitials = (name: string) => {
    try {
      const nameArray = name.split(" ");
      const firstName = nameArray[0];
      const lastName = nameArray[nameArray.length - 1];
      if (nameArray.length === 1) {
        const initials = firstName.charAt(0);
        return initials.toUpperCase();
      } else {
        const initials = firstName.charAt(0) + lastName.charAt(0);
        return initials.toUpperCase();
      }
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  const userInitials = getInitials(user?.fullName);

  return (
    <div className="setting-root">
      <h1>Settings</h1>
      <hr style={{ border: "1px solid #E8E8E8", padding: 0, margin: 0 }} />
      {!isTabletOrMobile ? (
        <Row>
          <Col span={16}>
            <Row style={{ marginTop: 30 }} justify="center">
              <Row>
                <Upload
                  disabled={loading}
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  action={`${API_BASE}user/upload-pic/${user._id}`}
                  headers={{
                    Authorization: "Bearer " + user.accessToken,
                  }}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {imageUrl && online ? (
                    <Avatar src={imageUrl} alt="avatar" size={100} />
                  ) : (
                    <>
                      <Avatar
                        size={100}
                        style={{ fontSize: 35, fontWeight: "bold" }}
                      >
                        {userInitials}
                      </Avatar>
                      {/* <EditUploadIcon
                    style={{ position: "relative", bottom: -25, left: 45 }}
                  /> */}
                    </>
                  )}
                </Upload>
              </Row>
            </Row>
            {loading && (
              <Row style={{ marginLeft: 100 }} gutter={20} justify="center">
                <Col>
                  <p style={{ color: "#FDB913" }}>Uploading</p>
                </Col>
                <Col>
                  <Spin />
                </Col>
              </Row>
            )}

            <div className="setting-profile">
              <h2>Profile</h2>
              <Form
                onFinish={onFinish}
                form={form}
                scrollToFirstError
                layout="vertical"
              >
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      name="fullName"
                      initialValue={user.fullName}
                      label={<h3>Full Name</h3>}
                      rules={[
                        { required: true, message: "Please enter full name" },
                        checkEmpty,
                      ]}
                    >
                      <Input className="setting-input" placeholder="Type" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="email"
                      initialValue={user.email}
                      label={<h3>Email Address</h3>}
                      rules={[{ type: "email", message: "Enter valid email" }]}
                    >
                      <Input
                        disabled
                        className="setting-input"
                        placeholder="Type"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      initialValue={user.phone}
                      name="phone"
                      label={<h3>Phone</h3>}
                    >
                      <MaskedInput
                        mask={"(000) 000-0000"}
                        className="setting-input"
                        placeholder="Type"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      initialValue={user.jobTitle}
                      name="jobTitle"
                      label={<h3>Job Title</h3>}
                    >
                      <Input className="setting-input" placeholder="Type" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      initialValue={user.location}
                      name="location"
                      label={<h3>Location</h3>}
                    >
                      <Input className="setting-input" placeholder="Type" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      name="jobDescription"
                      initialValue={user.jobDescription}
                      label={<h3>Job Description</h3>}
                    >
                      <TextArea
                        autoSize={{ minRows: 5, maxRows: 5 }}
                        placeholder="Type"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row style={{ marginTop: 30 }} justify="center">
                  <Col>
                    <Form.Item>
                      <Button
                        disabled={saveBtnDisable}
                        className="setting-save-btn"
                        type="primary"
                        htmlType="submit"
                      >
                        Save Profile
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <h2 style={{ marginTop: 40 }}>Password Setting</h2>
              <Form
                onFinish={handleUpdatePassword}
                form={passwordForm}
                scrollToFirstError
                layout="vertical"
              >
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      name="currentPassword"
                      label={<h3>Current Password</h3>}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your current password",
                        },
                      ]}
                    >
                      <Input.Password
                        className="setting-input"
                        placeholder="Type"
                      />
                    </Form.Item>
                    {inCorrectPass.length > 0 && (
                      <p style={{ color: "red" }}>{inCorrectPass}</p>
                    )}
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="newPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please enter new password",
                        },
                        {
                          min: 5,
                          message: "Password must be minimum 5 characters",
                        },
                      ]}
                      label={<h3>New Password</h3>}
                    >
                      <Input.Password
                        className="setting-input"
                        placeholder="Type"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ marginTop: 30 }} justify="center">
                  <Col>
                    <Form.Item>
                      <Button
                        disabled={updateBtnDisable}
                        className="setting-update-btn"
                        type="primary"
                        htmlType="submit"
                      >
                        Update Password
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <Row style={{ marginTop: 30, marginRight: 80 }} justify="center">
              <Row>
                <Upload
                  disabled={loading}
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  action={`${API_BASE}user/upload-pic/${user._id}`}
                  headers={{
                    Authorization: "Bearer " + user.accessToken,
                  }}
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {imageUrl && online ? (
                    <Avatar src={imageUrl} alt="avatar" size={100} />
                  ) : (
                    <>
                      <Avatar
                        size={100}
                        style={{ fontSize: 35, fontWeight: "bold" }}
                      >
                        {userInitials}
                      </Avatar>
                      {/* <EditUploadIcon
                    style={{ position: "relative", bottom: -25, left: 45 }}
                  /> */}
                    </>
                  )}
                </Upload>
              </Row>
            </Row>
            {loading && (
              <Row style={{ marginLeft: 100 }} gutter={20} justify="center">
                <Col>
                  <p style={{ color: "#FDB913" }}>Uploading</p>
                </Col>
                <Col>
                  <Spin />
                </Col>
              </Row>
            )}

            <div className="setting-profile">
              <h2>Profile</h2>
              <Form
                onFinish={onFinish}
                form={form}
                scrollToFirstError
                layout="vertical"
              >
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      name="fullName"
                      initialValue={user.fullName}
                      label={<h3>Full Name</h3>}
                      rules={[
                        { required: true, message: "Please enter full name" },
                        checkEmpty,
                      ]}
                    >
                      <Input className="setting-input" placeholder="Type" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="email"
                      initialValue={user.email}
                      label={<h3>Email Address</h3>}
                      rules={[{ type: "email", message: "Enter valid email" }]}
                    >
                      <Input
                        disabled
                        className="setting-input"
                        placeholder="Type"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      initialValue={user.phone}
                      name="phone"
                      label={<h3>Phone</h3>}
                    >
                      <MaskedInput
                        mask={"(000) 000-0000"}
                        className="setting-input"
                        placeholder="Type"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      initialValue={user.jobTitle}
                      name="jobTitle"
                      label={<h3>Job Title</h3>}
                    >
                      <Input className="setting-input" placeholder="Type" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      initialValue={user.location}
                      name="location"
                      label={<h3>Location</h3>}
                    >
                      <Input className="setting-input" placeholder="Type" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      name="jobDescription"
                      initialValue={user.jobDescription}
                      label={<h3>Job Description</h3>}
                    >
                      <TextArea
                        autoSize={{ minRows: 5, maxRows: 5 }}
                        placeholder="Type"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row style={{ marginTop: 30 }} justify="center">
                  <Col>
                    <Form.Item>
                      <Button
                        disabled={saveBtnDisable}
                        className="setting-save-btn"
                        type="primary"
                        htmlType="submit"
                      >
                        Save Profile
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <h2 style={{ marginTop: 40 }}>Password Setting</h2>
              <Form
                onFinish={handleUpdatePassword}
                form={passwordForm}
                scrollToFirstError
                layout="vertical"
              >
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      name="currentPassword"
                      label={<h3>Current Password</h3>}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your current password",
                        },
                      ]}
                    >
                      <Input.Password
                        className="setting-input"
                        placeholder="Type"
                      />
                    </Form.Item>
                    {inCorrectPass.length > 0 && (
                      <p style={{ color: "red" }}>{inCorrectPass}</p>
                    )}
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="newPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please enter new password",
                        },
                        {
                          min: 5,
                          message: "Password must be minimum 5 characters",
                        },
                      ]}
                      label={<h3>New Password</h3>}
                    >
                      <Input.Password
                        className="setting-input"
                        placeholder="Type"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ marginTop: 30 }} justify="center">
                  <Col>
                    <Form.Item>
                      <Button
                        disabled={updateBtnDisable}
                        className="setting-update-btn"
                        type="primary"
                        htmlType="submit"
                      >
                        Update Password
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SettingC;
