import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, Select, message, Modal } from "antd";

import "./CreateStoreLocation.less";

import { MaskedInput } from "antd-mask-input";

const { Option } = Select;

type Props = {
  showAddStoreModal: boolean;
  toggleShowAddStoreModal: (item: any) => void;
  handleAddStore: (item: any) => void;
  row?: any;
  invalidEmail?: string;
};

const CreateStoreLocation = (props: Props) => {
  const [form] = Form.useForm();
  const [isEditable, setIsEditable] = useState(false);
  useEffect(() => {
    if (Object.keys(props?.row)?.length > 0) {
      setIsEditable(true);

      form.setFieldsValue(props.row);
    } else {
      setIsEditable(false);
      form.resetFields();
    }
  }, [props.row, props.showAddStoreModal]);
  return (
    <div className="background-container">
      <Modal
        centered={true}
        visible={props.showAddStoreModal}
        onOk={props.toggleShowAddStoreModal}
        onCancel={props.toggleShowAddStoreModal}
        footer={false}
        title={
          <h1 style={{ marginTop: 10 }} className="default-modal-add-item">
            {isEditable ? "Update Paint Store" : "Add Paint Store"}
          </h1>
        }
        width={600}
      >
        <Form
          onFinish={(values: any) => {
            form.resetFields();
            props.handleAddStore(values);
          }}
          form={form}
          scrollToFirstError
          layout="vertical"
        >
          <div>
            <div>
              <Row>
                <Col style={{ width: 260 }}>
                  <Form.Item
                    label={<h1 className="input-title">Store Name</h1>}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your store name!",
                      },
                    ]}
                  >
                    <Input className="input-field" />
                  </Form.Item>
                </Col>
                <Col style={{ width: 260, marginLeft: 30 }}>
                  <Form.Item
                    label={<h1 className="input-title">Store Number</h1>}
                    name="number"
                  >
                    <Input className="input-field" type="number" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col style={{ width: 260 }}>
                  <Form.Item
                    label={<h1 className="input-title">Store Phone</h1>}
                    name="phone"
                  >
                    <MaskedInput
                      required
                      mask={"(000) 000-0000"}
                      className="input-field"
                    />
                  </Form.Item>
                </Col>
                <Col
                  className="so-options-container"
                  style={{ width: 260, marginLeft: 30 }}
                >
                  <Form.Item
                    name="store"
                    label={<h1 className="input-title">Store</h1>}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your store",
                      },
                    ]}
                  >
                    <Input className="input-field" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col style={{ width: 260 }}>
                  <Form.Item
                    label={<h1 className="input-title">Email</h1>}
                    name="email"
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: "Enter valid email",
                      },
                    ]}
                  >
                    <Input className="input-field" />
                  </Form.Item>
                </Col>
                <Col style={{ width: 260, marginLeft: 30 }}>
                  <Form.Item
                    name="address"
                    label={<h1 className="input-title">Store Address</h1>}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your store address",
                      },
                    ]}
                  >
                    <Input className="input-field" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col style={{ width: 260 }}>
                  <Form.Item
                    label={<h1 className="input-title">Latitude</h1>}
                    name="latitude"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your store's valid latitude!",
                      },
                    ]}
                  >
                    <Input className="input-field" type="number" />
                  </Form.Item>
                </Col>
                <Col style={{ width: 260, marginLeft: 30 }}>
                  <Form.Item
                    label={<h1 className="input-title">Longitude</h1>}
                    name="longitude"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your store's valid longitude!",
                      },
                    ]}
                  >
                    <Input className="input-field" type="number" />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div className="login-btn-container">
              {props.invalidEmail && (
                <p style={{ color: "red" }}>{props.invalidEmail}</p>
              )}
              <div>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="login-btn "
                  >
                    {isEditable ? "Update" : "Create"}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateStoreLocation;
