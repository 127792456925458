import React, { useEffect, useState } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Row, Col, Button, Input } from "antd";
import "./TermsAndConditionsC.less";
import { BulletIcon } from "../../../../utils/icons";
import TermsAndConditionsModal from "../pop-ups/TermsAndConditionsModal";
import { useDispatch } from "react-redux";
import {
  postTermsAndConditionsDefaults,
  updateTermsAndConditions,
  deleteTermsAndConditions,
} from "../../../../redux/proposal-defaults-com-res/action";

type Props = {
  type: string;
  terms?: any;
};

const TermsAndConditionsDefault = (props: Props) => {
  const dispatch = useDispatch();
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] =
    useState(false);
  const [data, setData] = useState<any>([]);

  const [edit, setEdit] = useState<any>({});

  useEffect(() => {
    setData(props.terms);
  }, [props.terms]);

  const handleSurfaceAdd = (data: any) => {
    if (Object.keys(edit)?.length > 0) {
      const body = {
        ...edit,
        ...data,
      };
      dispatch(updateTermsAndConditions(body));
    } else {
      const body = {
        ...data,
        type: props.type,
      };
      dispatch(postTermsAndConditionsDefaults(body));
    }
    setShowTermsAndConditionsModal(false);
  };

  const toggleSurfacePrepIntModal = (collapse: boolean) => {
    !showTermsAndConditionsModal
      ? setShowTermsAndConditionsModal(true)
      : setShowTermsAndConditionsModal(false);
  };

  const handleAdd = () => {
    setEdit({});
    !showTermsAndConditionsModal
      ? setShowTermsAndConditionsModal(true)
      : setShowTermsAndConditionsModal(false);
  };

  const handleEdit = (index: number, row: any) => {
    setEdit(row);
    setShowTermsAndConditionsModal(true);
  };

  const handleDelete = (id: string) => {
    dispatch(deleteTermsAndConditions(id));
  };

  const handleTitleChange = (e: any, id: string) => {
    const index = data?.findIndex((item: any) => item._id === id);
    const newData = [...data];
    newData[index].title = e.target.value;
    setData(newData);
  };

  const handleDetailChange = (e: any, id: string) => {
    const index = data?.findIndex((item: any) => item._id === id);
    const newData = [...data];
    newData[index].detail = e.target.value;
    setData(newData);
  };

  return (
    <div className="surface-preparation-interior">
      <TermsAndConditionsModal
        row={edit}
        // title={props.jobType}
        toggleSurfacePrepIntModal={toggleSurfacePrepIntModal}
        showTermsAndConditionsModal={showTermsAndConditionsModal}
        handleSurfaceAdd={handleSurfaceAdd}
      />
      <Row justify="space-between">
        <Col>
          <h1>Terms and Conditions </h1>
        </Col>
        <Col>
          <Button
            onClick={handleAdd}
            className="surface-preparation-add-btn"
            type="link"
          >
            + Add
          </Button>
        </Col>
      </Row>

      <Row>
        {data?.map((item: any, index: any) => {
          return (
            <Col span={24} key={item._id}>
              <Row gutter={15}>
                <Col span={20}>
                  <Row>
                    <Col span={1}>
                      <BulletIcon />
                    </Col>
                    <Col span={23}>
                      {edit === index ? (
                        <Row>
                          <Col span={11}>
                            <Input
                              onChange={(e: any) =>
                                handleTitleChange(e, item._id)
                              }
                              value={item.title}
                            />
                          </Col>
                          <strong>:</strong>
                          <Col span={12}>
                            <Input
                              onChange={(e: any) =>
                                handleDetailChange(e, item._id)
                              }
                              value={item.detail}
                            />
                          </Col>
                        </Row>
                      ) : (
                        <>
                          <strong>{item.title}:</strong>
                          {item.detail}
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <EditOutlined
                    onClick={() => handleEdit(index, item)}
                    style={{ color: "#FDB913", cursor: "pointer" }}
                  />
                </Col>
                <Col>
                  <DeleteOutlined
                    onClick={() => handleDelete(item._id)}
                    style={{ color: "#D2232A", cursor: "pointer" }}
                  />
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default TermsAndConditionsDefault;
