import { Table, Input, Button } from "antd";
import "./ViewPjcc.less";
import { HideArrowIcon, ShowArrowIcon } from "../../utils/icons";
import { useEffect, useState } from "react";
import { customToFixed } from "../../utils/helpers";

type Props = {
  showSider: boolean;
  pjccData?: any;
  applyGrossMargin?: any;
  togglePjccBtn: () => void;
};

const ViewPjcc = (props: Props) => {
  const [tableData, setTableData] = useState<any>([]);
  useEffect(() => {
    const cost = props.pjccData[15]?.cost;
    const amount =
      typeof cost === "number" && props.pjccData[0]?.amount
        ? parseFloat(((1 - cost / props.pjccData[0].amount) * 100).toFixed(2))
        : 0;

    let tempTableData = [
      ...props.pjccData,
      {
        name: "Gross Margin %",
        amount: amount,
        totalAmount: amount,
        option: props.pjccData[16].option,
        project: props.pjccData[16].project,
      },
    ];
    setTableData(
      tempTableData?.map((item: any) => {
        return {
          ...item,
          grossMargin: customToFixed(
            item?.grossMargin ? item?.grossMargin : 0,
            2
          ),
        };
      })
    );
  }, [props]);

  const pjccColumns = [
    {
      title: "PJCC",
      dataIndex: "name",
      key: "name",
      width: "150px",
      render: (data: any) => {
        return <h1 className="view-pjcc-text">{data}</h1>;
      },
    },
    {
      title: "GM",

      key: "gm",
      width: "150px",
      render: (data: any, _: any, index: number) => {
        return (
          <>
            {index === 0 ||
            index === 4 ||
            index === 8 ||
            index === 9 ||
            index === 10 ||
            index === 13 ||
            index === 14 ||
            index === 15 ||
            index === 16 ||
            index === 17 ? null : (
              <Input
                value={data.grossMargin}
                onChange={(e: any) =>
                  props.applyGrossMargin(e, data.name, index)
                }
                addonAfter="%"
              />
            )}
          </>
        );
      },
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (data: any, _: any, index: number) => {
        return (
          <>
            {index !== 0 && index !== 16 ? (
              <h1 className="view-pjcc-text">{data}</h1>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Per Unit",
      dataIndex: "amount",
      key: "amount",
      render: (data: any, _: any, index: number) => {
        return (
          <>
            {index === 17 ? (
              <div>
                <h1>{data}%</h1>
              </div>
            ) : (
              <div>
                {index !== 15 && <h1 className="view-pjcc-text">{data}</h1>}
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (data: any, _: any, index: number) => {
        return (
          <>
            {index === 17 ? (
              <div>
                <h1>{data}%</h1>
              </div>
            ) : (
              <div>
                {index !== 15 && <h1 className="view-pjcc-text">{data}</h1>}
              </div>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div className="view-pjcc-container">
      {props.showSider && (
        <>
          <div
            style={{ position: "absolute", top: "70%" }}
            className="invert-pjcc-btns"
          >
            <Button
              type="primary"
              className="view-pjcc-btn"
              onClick={props.togglePjccBtn}
            >
              {!props.showSider ? (
                <HideArrowIcon className="pjcc-show-icon" />
              ) : (
                <ShowArrowIcon className="pjcc-show-icon" />
              )}
              View PJCC
            </Button>
          </div>

          <div className="pjcc-table-hr">
            <Table
              rowKey="_id"
              className="pjcc-table"
              rowClassName={(record, index) =>
                index % 2 === 0
                  ? "table_row table-row-dark"
                  : "table_row table-row-light"
              }
              pagination={false}
              columns={pjccColumns}
              dataSource={tableData}
              scroll={{ y: 450 }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ViewPjcc;
