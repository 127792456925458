import { Button, Col, Form, Image, Input, Row, message } from "antd";
import React, { useEffect } from "react";
import api from "../../utils/api";
import "./ContractDefaultCard.less";

type ContractDefaultDTO = {
  _id: string;
  svgString: string;
  title: string;
  description: string;
  startAndCompletionDate?: {
    startDateFirmString: string;
    startDateFlexibleString: string;
    completionDateFirmString: string;
    completionDateFlexibleString: string;
  };
  position: number;
  addToProjects: boolean;
  isDefault: boolean;
  isEditable: boolean;
  edit?: boolean;
};

type FormResultDto = {
  startDateFirmString: string;
  startDateFlexibleString: string;
  completionDateFirmString: string;
  completionDateFlexibleString: string;
};

type Props = {
  dataToBeEdited: ContractDefaultDTO;
  setShowStartCompletionDateCard: (dataId: string) => void;
  setContractDefaults: (
    value:
      | ContractDefaultDTO[]
      | ((currentDefaults: ContractDefaultDTO[]) => ContractDefaultDTO[])
  ) => void;
};

function EditStartCompletionDateCard({
  dataToBeEdited,
  setShowStartCompletionDateCard,
  setContractDefaults,
}: Props) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(dataToBeEdited.startAndCompletionDate);
  }, []);

  const isDataUnchanged = (values: FormResultDto) => {
    return (
      dataToBeEdited.startAndCompletionDate?.startDateFirmString ===
        values.startDateFirmString &&
      dataToBeEdited.startAndCompletionDate?.startDateFlexibleString ===
        values.startDateFlexibleString &&
      dataToBeEdited.startAndCompletionDate?.completionDateFirmString ===
        values.completionDateFirmString &&
      dataToBeEdited.startAndCompletionDate?.completionDateFlexibleString ===
        values.completionDateFlexibleString
    );
  };

  const handleUpdate = (values: FormResultDto) => {
    console.log(values);
    if (isDataUnchanged(values)) {
      setShowStartCompletionDateCard(dataToBeEdited._id);
      return;
    }
    const putReqBody = {
      ...dataToBeEdited,
      startAndCompletionDate: values,
    };
    console.log("Put Req Body", putReqBody);
    api
      .put(`contract-defaults/${dataToBeEdited._id}`, putReqBody)
      .then((response: any) => {
        const responseWithType: ContractDefaultDTO = {
          _id: response._id,
          svgString: response.svgString,
          title: response.title,
          description: response.description,
          startAndCompletionDate: response.startAndCompletionDate,
          position: response.position,
          addToProjects: response.addToProjects,
          isDefault: response.isDefault,
          isEditable: response.isEditable,
          edit: false,
        };
        console.log(responseWithType);
        setContractDefaults((currentDefaults: ContractDefaultDTO[]) =>
          currentDefaults.map((item: ContractDefaultDTO) =>
            item._id === responseWithType._id ? responseWithType : item
          )
        );
      })
      .catch((error: any) => {
        console.log(error);
        message.error("Something Went Wrong");
        setShowStartCompletionDateCard(dataToBeEdited._id);
      });
  };

  const handleCancelButton = () => {
    setShowStartCompletionDateCard(dataToBeEdited._id);
  };

  return (
    <div style={{ padding: "30px 25px 0px 25px" }}>
      <h1>Update Default</h1>
      <Row>
        <Col style={{ marginRight: 10, marginTop: 5 }}>
          <Image
            src={dataToBeEdited.svgString}
            style={{ fontSize: "30px" }}
            preview={false}
          />
        </Col>
        <Col style={{ padding: "2px 15px 0px 0px" }}>
          <h1 style={{ fontWeight: "bold" }}>Title: </h1>
        </Col>
        <Col>
          <Input
            className="default-title-input"
            type="string"
            value={dataToBeEdited.title}
            disabled={true}
          />
        </Col>
      </Row>
      <div>
        <Form
          form={form}
          onFinish={handleUpdate}
          layout="horizontal"
          labelCol={{ flex: "200px" }}
          labelAlign="left"
          labelWrap
          style={{ paddingTop: "20px" }}
        >
          <Row>
            <Form.Item
              label={
                <p
                  className="contract-start-completion-p-tag"
                  style={{ paddingTop: 24 }}
                >
                  Start Date Firm:
                </p>
              }
              name="startDateFirmString"
              rules={[
                { required: true, message: "Please enter Start Date Firm!" },
              ]}
            >
              <Input
                className="default-Form-input"
                style={{ marginRight: 68 }}
              />
            </Form.Item>
            <hr style={{ width: "220px", marginTop: "24px" }} />
          </Row>

          <Row>
            <Form.Item
              label={
                <p
                  className="contract-start-completion-p-tag"
                  style={{ paddingTop: 24 }}
                >
                  Start Date Flexible:
                </p>
              }
              name="startDateFlexibleString"
              rules={[
                {
                  required: true,
                  message: "Please enter Start Date Flexible!",
                },
              ]}
            >
              <Input
                className="default-Form-input"
                style={{ marginRight: 46 }}
              />
            </Form.Item>
            <hr style={{ width: "220px", marginTop: "24px" }} />
          </Row>

          <Row>
            <Form.Item
              label={
                <p
                  className="contract-start-completion-p-tag"
                  style={{ paddingTop: 24 }}
                >
                  Completion Date Firm:
                </p>
              }
              name="completionDateFirmString"
              rules={[
                {
                  required: true,
                  message: "Please enter Completion Date Firm!",
                },
              ]}
            >
              <Input
                className="default-Form-input"
                style={{ marginRight: 18 }}
              />
            </Form.Item>
            <hr style={{ width: "220px", marginTop: "24px" }} />
          </Row>

          <Row>
            <Form.Item
              label={
                <p
                  className="contract-start-completion-p-tag"
                  style={{ paddingTop: 24 }}
                >
                  Completion Date Flexible:
                </p>
              }
              name="completionDateFlexibleString"
              rules={[
                {
                  required: true,
                  message: "Please enter Completion Date Flexible!",
                },
              ]}
            >
              <Input
                className="default-Form-input"
                style={{ marginRight: -8 }}
              />
            </Form.Item>
            <hr style={{ width: "220px", marginTop: "24px" }} />
          </Row>

          <Row justify={"end"} style={{ paddingBottom: "20px" }}>
            <Col style={{ paddingRight: "15px" }}>
              <Button
                htmlType="submit"
                type="primary"
                className="contract-save-cancel-button"
              >
                Update
              </Button>
              {/* <Button
                className="contract-save-cancel-button"
                type="primary"
                onClick={handleUpdate}
              >
                Update
              </Button> */}
            </Col>
            <Col>
              <Button
                className="contract-save-cancel-button"
                type="primary"
                onClick={handleCancelButton}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default EditStartCompletionDateCard;
