import { Col, Input, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { SearchIcon } from "../../utils/icons";
import { Image } from "antd";
import "./Media.less";
import loadingGif from '../../assets/images/loading.gif'
import api from "../../utils/api";


type Props = {
  loading: boolean,
  images: any[],
  SetImages: any,
  onSiteMedia: any[],
  setOnSiteMedia: any,
}

const MediaCard = (props: Props) => {


  const [inputSearch, setInputSearch] = useState("");

  const [previewSrc, setPreviewSrc] = useState("")


  const found = props.images?.filter((item: any) => {
    if (!inputSearch) {
      return true;
    }
    return item.name.toLowerCase().includes(inputSearch.toLowerCase());
  });


  const handlePreview = async (file: any, fileListVerb: string) => {
    if (file.key && !file.OrgImageURl) {
      const res: any = await api.get(`upload-files/preview-image/${file.key}`)
      const OrgImageURl = `data:${file.mimeType};base64,${Buffer.from(res.Body).toString('base64')}`;
      if (fileListVerb === "onSiteMedia") {
        const index = props.onSiteMedia.findIndex((item: any) => item.uid === file.uid);
        const updatedList = [...props.onSiteMedia]
        updatedList[index] = { ...file, OrgImageURl }
        props.setOnSiteMedia(updatedList)
      } else {
        if ((fileListVerb === "found")) {
          const index = props.images.findIndex((item: any) => item.uid === file.uid);
          const updatedList = [...props.images]
          updatedList[index] = { ...file, OrgImageURl }
          props.SetImages(updatedList)
        }
      }
      setPreviewSrc(OrgImageURl)
    }
    else {
      if (file.OrgImageURl) {
        setPreviewSrc(file.OrgImageURl)
      }
    }
  }

  const handleClosePreview = () => {
    setPreviewSrc(""); // Set previewSrc to an empty string when the preview is closed
  };

  return (
    <div className="media-background">
      <Row justify="space-between">
        <Col>
          <h1>Media</h1>
        </Col>
        <Col>
          <Input
            className="media-search"
            placeholder="Search Media"
            prefix={<SearchIcon />}
            value={inputSearch}
            onChange={(e: any) => setInputSearch(e.target.value)}
          />
        </Col>
      </Row>
      {props.loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <Row style={{ marginTop: 20 }} gutter={30}>
            {found?.map((item: any) => (
              <>
                {item?.addToProject && (
                  <Col
                    key={item?.thumbUrl}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div style={{ width: 200 }}>
                      <Image
                        style={{ borderRadius: 5, objectFit: "cover" }}
                        width={200}
                        src={item?.thumbUrl}
                        preview={{
                          src: previewSrc !== "" ? previewSrc : loadingGif,
                          onVisibleChange: (visible) => {
                            if (!visible) {
                              handleClosePreview(); // Call handleClosePreview when the preview is closed
                            }
                          }
                        }}
                        onClick={() => { handlePreview(item, "found") }}
                      />
                    </div>
                    <div>
                      <p style={{ width: 200 }}>{item?.name}</p>
                    </div>
                  </Col>
                )}
              </>
            ))}
          </Row>
          <h2>On-SIte Media:</h2>
          <Row gutter={30}>
            {props.onSiteMedia?.map((item: any) => (
              <>
                {item?.addToProject && (
                  <Col
                    key={item?.thumbUrl}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div style={{ width: 200 }}>
                      <Image
                        style={{ borderRadius: 5, objectFit: "cover" }}
                        width={200}
                        src={item?.thumbUrl}
                        preview={{
                          src: previewSrc !== "" ? previewSrc : loadingGif,
                          onVisibleChange: (visible) => {
                            if (!visible) {
                              handleClosePreview(); // Call handleClosePreview when the preview is closed
                            }
                          }
                        }}
                        onClick={() => { handlePreview(item, "onSiteMedia") }}
                      />
                    </div>

                    <p style={{ width: 200 }}>{item?.name}</p>
                  </Col>
                )}
              </>
            ))}
          </Row>
        </>
      )}
    </div>
  );
};

export default MediaCard;
