export const columns = [
  {
    title: "Line item",
    dataIndex: "product",
    render: (data: string) => {
      return <p>{data}</p>;
    },
  },
  {
    title: "Total LF/ SF",
    dataIndex: "totalLfOrSf",
    render: (data: string) => {
      return <p>{data}</p>;
    },
  },
];
