import { Input, message, Table } from "antd";
import axios from "axios";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { API_BASE } from "../../constant";
import "../../containers/OptionDetails/OptionDetails.less";
import api from "../../utils/api";
import { getAuthUser } from "../../utils/authToken";
import ActionMenu from "./ActionMenu";
import {
  removeRatesFromOptions,
  addServiceToProject,
  addRatesToOptions,
} from "../../redux/project/action";
import _ from "lodash";
import { customToFixed } from "../../utils/helpers";
var crypto = require("crypto");

type Props = {
  travelRates: any;
  setTravelState: (data: any) => void;
  setRatesValue: any;
  handleCheckModifiedOnEdit?: any;
};

const Travel = (props: Props) => {
  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const dispatch = useDispatch<any>();
  const user = getAuthUser();
  const calculateTravelTotal = (index: number, newData: any) => {
    // const newData = [...props.travelRates];
    newData[index].totalCost =
      newData[index].rfmCount * newData[index].dnCount * newData[index].cost;
    newData[index].totalCost = customToFixed(newData[index].totalCost, 2);
    props.setTravelState(newData);
  };

  const onRFMCountChange = (e: any, index: number, rowId: string) => {
    if (e.target.value >= 0) {
      const newData = [...props.travelRates];
      const rowIndex = newData.findIndex((item: any) => item._id === rowId);
      newData[rowIndex].rfmCount = e.target.value;
      // props.setTravelState(newData);
      calculateTravelTotal(rowIndex, newData);
    }
  };

  const handleCostChange = (e: any, index: number, rowId: string) => {
    const newData = [...props.travelRates];
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    newData[rowIndex].cost = e.target.value;
    handleModifiedRate(rowIndex);
    newData[rowIndex].modified = true;
    // props.setTravelState(newData);
    calculateTravelTotal(rowIndex, newData);
  };

  const onDNCountChange = (e: any, index: number, rowId: string) => {
    if (e.target.value >= 0) {
      const newData = [...props.travelRates];
      const rowIndex = newData.findIndex((item: any) => item._id === rowId);
      newData[rowIndex].dnCount = e.target.value;
      // props.setTravelState(newData);
      calculateTravelTotal(rowIndex, newData);
    }
  };

  const handleDelete = (id: string) => {
    let project = _.cloneDeep(items[currentProject]);
    const newData = [...props.travelRates];
    const optionRate = newData.find((item: any) => item._id === id);
    let optionIndex = project.options.findIndex((option: any) => optionRate.option === option.optionInfo._id)
    project.options[optionIndex].rates.travelRates = _.cloneDeep(props.travelRates);
    for (const singleOption of project.options) {
      if (singleOption.optionInfo._id === optionRate.option) {
        const optionRateIndex = singleOption.rates.travelRates.findIndex(
          (item: any) => item._id === id
        );
        singleOption.rates.travelRates[optionRateIndex].isDeleted = true;
        // singleOption.rates.travelRates.splice(optionRateIndex, 1);
      }
    }
    let body = {
      category: "travel",
      id: id,
    };
    dispatch(removeRatesFromOptions(body, project, adminDefaults));
    const index = newData.findIndex((item: any) => item._id === id);
    newData[index].isDeleted = true;
    // newData.splice(index, 1);
    props.setRatesValue({ travelRates: newData });
    props.setTravelState(newData);
  };

  const deepEqualForClone = (obj1: any, obj2: any): boolean => {
    // If both are direct values
    console.log("OBJECT 1", obj1, "OBJECT 2", obj2);
    if (obj1 === obj2) {
      console.log("equal");
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects excluding the "_id"
    const keys1 = Object.keys(obj1).filter(
      (key) => !["_id", "projectLaborRate"].includes(key)
    );
    const keys2 = Object.keys(obj2).filter(
      (key) => !["_id", "projectLaborRate"].includes(key)
    );

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      console.log("keys length Not equal");
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqualForClone(obj1[key], obj2[key])) {
        console.log(
          "Check if every key-value pair in obj1 matches that in obj2"
        );
        return false;
      }
    }

    return true;
  };

  const deepEqualCheckForClone = (editedObject: any, oldObjectArray: any) => {
    for (let oldObject of oldObjectArray) {
      const updatedOldObject = {
        ...oldObject,
      };
      if (deepEqualForClone(editedObject, updatedOldObject)) {
        return true;
      }
    }
    return false;
  };

  const deepEqualForCloneInProjectRates = (obj1: any, obj2: any): boolean => {
    // If both are direct values
    console.log("OBJECT 1", obj1, "OBJECT 2", obj2);
    if (obj1 === obj2) {
      console.log("equal");
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects excluding the "_id"
    const keys1 = Object.keys(obj1).filter((key) => !["_id"].includes(key));
    const keys2 = Object.keys(obj2).filter((key) => !["_id"].includes(key));

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      console.log("keys length Not equal");
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqualForClone(obj1[key], obj2[key])) {
        console.log(
          "Check if every key-value pair in obj1 matches that in obj2"
        );
        return false;
      }
    }

    return true;
  };

  const deepEqualCheckForCloneInProjectRates = (
    editedObject: any,
    oldObjectArray: any
  ) => {
    for (let oldObject of oldObjectArray) {
      const updatedOldObject = {
        ...oldObject,
      };
      if (deepEqualForCloneInProjectRates(editedObject, updatedOldObject)) {
        return {
          rate: updatedOldObject,
          result: true,
        };
      }
    }
    return {
      rate: editedObject,
      result: false,
    };
  };

  const handleTravelClone = (data: any, index: number, surfaceName: string) => {
    const result = props.travelRates.some((obj: any) => {
      let name: string = obj.item;
      if (name.replaceAll(" ", "") === surfaceName[index].replaceAll(" ", "")) {
        return true;
      }
    });
    if (result === true) {
      message.error("Name already taken");
    } else {
      let projectLaborRateId = crypto.randomBytes(12).toString("hex");
      const newRates = {
        ...data,
        item: surfaceName[index],
        project: items[currentProject].projectId,
        projectLaborRate: projectLaborRateId,
        _id: crypto.randomBytes(12).toString("hex"),
        isCloned: true,
      };

      const notDeletedRates = props.travelRates.filter(
        (item: any) => item.isDeleted === false
      );

      const isClonedArray = notDeletedRates.filter(
        (item: any) => item.isCloned === true
      );

      const rateAlreadyExists = deepEqualCheckForClone(newRates, isClonedArray);
      if (rateAlreadyExists) {
        message.error("Rate Already Exists");
      } else {
        let project = _.cloneDeep(items[currentProject]);
        let selectedOptionIndex = project.options.findIndex(
          (item: any) => item.optionInfo._id === newRates.option
        );
        project.options[selectedOptionIndex].rates.travelRates = props.travelRates;
        let newProjectTravelRates = [];
        let projectTravelRate = {
          cost: newRates.cost,
          item: newRates.item,
          project: newRates.project,
          _id: projectLaborRateId,
          isCloned: true,
          isDeleted: newRates.isDeleted,
          modified: newRates.modified,
          defaultRate: newRates.defaultRate
        };

        const notDeletedProjectRates = project.projectRates.travelRates.filter(
          (item: any) => item.isDeleted === false
        );

        const isClonedProjectRatesArray = notDeletedProjectRates.filter(
          (item: any) => item.isCloned === true
        );

        const rateAlreadyExistsInProject = deepEqualCheckForCloneInProjectRates(
          newRates,
          isClonedProjectRatesArray
        );
        if (rateAlreadyExistsInProject.result) {
          const foundedRate = rateAlreadyExistsInProject.rate;
          newRates.projectLaborRate = foundedRate._id;
        } else {
          project.projectRates.travelRates.push(projectTravelRate);
          newProjectTravelRates.push(projectTravelRate);
        }

        let optionIndex = project.options.findIndex(
          (item: any) => item.optionInfo._id === newRates.option
        );
        project.options[optionIndex].rates.travelRates.push(newRates);

        const body = [
          {
            category: "travel",
            rate: [newRates],
          },
        ];

        const newData = [...props.travelRates];
        newData.push({ ...newRates });
        props.setTravelState(newData);

        if (newProjectTravelRates.length !== 0) {
          let newBodyForProjectRates = [
            {
              category: "travel",
              rate: newProjectTravelRates,
            },
          ];
          dispatch(
            addServiceToProject(
              items[currentProject].projectId,
              newBodyForProjectRates
            )
          );
        }
        dispatch(addRatesToOptions(body, project, adminDefaults));
      }
    }
  };

  const handleModifiedRate = (index: number) => {
    const newData = [...props.travelRates];
    props.handleCheckModifiedOnEdit(newData[index]);
  };

  const optionTravelColumns = [
    {
      title: "Item",
      key: "item",
      dataIndex: "item",
      width: "150px",
    },
    {
      title: "Count",
      key: "item",
      width: "150px",
      render: (data: any, _: any, index: number) => {
        return (
          <Input
            type="number"
            style={{ width: 100 }}
            onChange={(e: any) => onRFMCountChange(e, index, _._id)}
            value={data.rfmCount}
          />
        );
      },
    },
    {
      title: "Frequency",
      key: "dnCount",
      width: "150px",
      render: (data: any, _: any, index: number) => {
        return (
          <Input
            style={{ width: 100 }}
            type="number"
            onChange={(e: any) => onDNCountChange(e, index, _._id)}
            value={data.dnCount}
          />
        );
      },
    },
    {
      title: "Cost",
      dataIndex: "cost",
      width: "150px",
      render: (cost: any, _: any, index: number) => {
        return (
          <div>
            <Input
              type="number"
              style={{ width: 90 }}
              onChange={(e: any) => handleCostChange(e, index, _._id)}
              value={cost}
              defaultValue={cost}
            />
          </div>
        );
      },
    },

    {
      title: "Total",
      dataIndex: "totalCost",
      key: "totalCost",
      width: "150px",
      render: (totalCost: any) => {
        return <div style={{ marginLeft: 10 }}>{totalCost}</div>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: "80px",

      render: (data: any, _: any, index: number) => {
        return (
          <div
            className="table--editor--holder"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <ActionMenu
              handleClone={handleTravelClone}
              handleDelete={handleDelete}
              data={data}
              index={index}
              category="travel"
            />
          </div>
        );
      },
    },
  ];
  return (
    <>
      {props.travelRates?.length > 0 && (
        <Table
          scroll={{ x: 900 }}
          rowKey="_id"
          rowClassName={(_, index) =>
            index % 2 === 0
              ? "table_row table-row-light"
              : "table_row table-row-dark"
          }
          pagination={false}
          columns={optionTravelColumns}
          dataSource={props.travelRates
            ?.filter((item: any) => !item.isDeleted)
            .sort((a: any, b: any) => a.item.localeCompare(b.item))}
        />
      )}
    </>

  );
};

export default Travel;
