import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, Select, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { BulletIcon } from "../../utils/icons";
import "./CreateVendor.less";
import _ from "lodash";
const { Option } = Select;

type Props = {
  showAddVendorModal: boolean;
  toggleShowAddVendorModal: (item: any) => void;
  handleAddVendor: (item: any) => void;
  row?: any;
};

const CreateVendor = (props: Props) => {
  const [form] = Form.useForm();
  const [isEditable, setIsEditable] = useState(false);
  const [prefixList, setPrefixList] = useState<string[]>([]);
  const [newPrefix, setNewPrefix] = useState("");

  useEffect(() => {
    if (Object.keys(props?.row)?.length > 0) {
      setIsEditable(true);

      form.setFieldsValue(props.row);
      setPrefixList(props.row.prefix);
    } else {
      setIsEditable(false);
      form.resetFields();
    }
  }, [props.row, props.showAddVendorModal]);

  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setPrefixList([...prefixList, newPrefix]);
      setNewPrefix("");
    }
  };

  const handleDelete = (prefix: string) => {
    setPrefixList(prefixList.filter((item: any) => item !== prefix));
  };

  return (
    <div className="background-container">
      <Modal
        centered={true}
        visible={props.showAddVendorModal}
        onOk={props.toggleShowAddVendorModal}
        onCancel={props.toggleShowAddVendorModal}
        footer={false}
        title={
          <h1 style={{ marginTop: 10 }} className="default-modal-add-item">
            {isEditable ? "Update Paint Store" : "Add Paint Store"}
          </h1>
        }
        width={600}
      >
        <Form
          onFinish={(values: any) => {
            let prefix = _.cloneDeep(prefixList);
            form.resetFields();
            props.handleAddVendor({ ...values, prefix });
            setPrefixList([]);
          }}
          form={form}
          scrollToFirstError
          layout="vertical"
        >
          <div>
            <div>
              <Row>
                <Col style={{ width: 260 }}>
                  <Form.Item
                    label={<h1 className="input-title">Vendor Name</h1>}
                    name="vendorName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Vendor Name!",
                      },
                    ]}
                  >
                    <Input className="input-field" />
                  </Form.Item>
                </Col>
                <Col style={{ width: 260, marginLeft: 30 }}>
                  <Form.Item
                    label={<h1 className="input-title">Category</h1>}
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Category",
                      },
                    ]}
                    className="select-container"
                  >
                    <Select
                      placeholder="Select"
                      size="large"
                      className="select-field"
                    >
                      <Option value="Residential">Residential</Option>
                      <Option value="Commercial">Commercial</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col style={{ width: 260 }}>
                  <Form.Item
                    label={<h1 className="input-title">Rep Name</h1>}
                    name="repName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Rep Name!",
                      },
                    ]}
                  >
                    <Input className="input-field" />
                  </Form.Item>
                </Col>
                <Col style={{ width: 260, marginLeft: 30 }}>
                  <Form.Item
                    name="repContact"
                    label={<h1 className="input-title">Rep Contact</h1>}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Valid Email Address!",
                        type: "email",
                      },
                    ]}
                  >
                    <Input className="input-field" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="accountNumber"
                label={<h1 className="input-title">Account Number</h1>}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Valid Account Number!",
                  },
                ]}
              >
                <Input className="input-field" />
              </Form.Item>
            </div>
            <div style={{ marginBottom: 20 }}>
              <h1 className="input-title">Prefixes</h1>
              <Input
                className="input-field"
                value={newPrefix}
                onChange={(e) => setNewPrefix(e.target.value)}
                onKeyDown={handleEnter}
                style={{ marginBottom: 15 }}
              />
              {prefixList.map((item: string) => {
                return (
                  <div key={item}>
                    <Row gutter={15} style={{ marginLeft: 10, padding: 0 }}>
                      <Col span={22}>
                        <Row>
                          <Col span={1}>
                            <BulletIcon />
                          </Col>
                          <Col span={23}>
                            <p>{item}</p>
                          </Col>
                        </Row>
                      </Col>

                      <Col>
                        <DeleteOutlined
                          onClick={() => handleDelete(item)}
                          style={{ color: "#D2232A", cursor: "pointer" }}
                        />
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>

            <div className="login-btn-container">
              <div>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="login-btn "
                  >
                    {isEditable ? "Update" : "Create"}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateVendor;
