import { Input, message, Select, Table } from "antd";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import "../../containers/OptionDetails/OptionDetails.less";
import api from "../../utils/api";
import { getAuthUser } from "../../utils/authToken";
import ActionMenu from "./ActionMenu";
import {
  removeRatesFromOptions,
  addServiceToProject,
  addRatesToOptions,
} from "../../redux/project/action";
import _ from "lodash";
import { useState } from "react";
import ExpandedEquipmentRow from "./ExpandedEquipmentRow";
import CustomExpandIcon from "./CustomExpandIcon";
import { customToFixed } from "../../utils/helpers";
var crypto = require("crypto");

const { Option } = Select;
type Props = {
  equipmentRates: any;
  setEquipmentState: any;
  setRatesValue: any;
  handleCheckModifiedOnEdit?: any;
};

const Equipment = (props: Props) => {
  const user = getAuthUser();
  const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);
  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const dispatch = useDispatch<any>();

  const handleInterval = (value: string, index: number, rowId: string) => {
    const newData = [...props.equipmentRates];
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    if (typeof newData[rowIndex].defaultRate === "string") {
      const defaultRateItem = adminDefaults.defaultRates.equipmentRates.find(
        (item: any) => item._id === newData[rowIndex].defaultRate
      );
      if (defaultRateItem.hasIntervals) {
        switch (value) {
          case "daily":
            newData[rowIndex].cost = parseFloat(defaultRateItem.dailyCost);
            // parseFloat(newData[rowIndex].surcharges) +
            // parseFloat(newData[rowIndex].puAndDelivery);
            break;
          case "weekly":
            newData[rowIndex].cost = parseFloat(defaultRateItem.weeklyCost);
            // parseFloat(newData[rowIndex].surcharges) +
            // parseFloat(newData[rowIndex].puAndDelivery);
            break;
          case "monthly":
            newData[rowIndex].cost = parseFloat(defaultRateItem.monthlyCost);
            // parseFloat(newData[rowIndex].surcharges) +
            // parseFloat(newData[rowIndex].puAndDelivery);
            break;
          case "other":
            break;
          default:
            break;
        }
        newData[rowIndex].type = value;
        // props.setEquipmentState(newData);
        calculateEquipmentTotal(rowIndex, newData);
      }
    } else {
      if (newData[rowIndex].defaultRate?.hasIntervals) {
        switch (value) {
          case "daily":
            newData[rowIndex].cost = parseFloat(
              newData[rowIndex].defaultRate.dailyCost
            );
            // parseFloat(newData[rowIndex].surcharges) +
            // parseFloat(newData[rowIndex].puAndDelivery);
            break;
          case "weekly":
            newData[rowIndex].cost = parseFloat(
              newData[rowIndex].defaultRate.weeklyCost
            );
            // parseFloat(newData[rowIndex].surcharges) +
            // parseFloat(newData[rowIndex].puAndDelivery);
            break;
          case "monthly":
            newData[rowIndex].cost = parseFloat(
              newData[rowIndex].defaultRate.monthlyCost
            );
            // parseFloat(newData[rowIndex].surcharges) +
            // parseFloat(newData[rowIndex].puAndDelivery);
            break;
          case "other":
            break;
          default:
            break;
        }
        newData[rowIndex].type = value;
        // props.setEquipmentState(newData);
        calculateEquipmentTotal(rowIndex, newData);
      }
    }
  };

  const handleExpand = (key: any) => {
    if (expandedRowKeys.includes(key)) {
      setExpandedRowKeys(expandedRowKeys.filter((k: any) => k !== key));
    } else {
      setExpandedRowKeys([...expandedRowKeys, key]);
    }
  };

  const handlePeriodsChange = (e: any, index: number, rowId: string) => {
    if (e.target.value >= 0) {
      const newData = [...props.equipmentRates];
      const rowIndex = newData.findIndex((item: any) => item._id === rowId);
      newData[rowIndex].periods = e.target.value;
      // handleModifiedRate(index);
      // newData[index].modified = true;
      // props.setEquipmentState(newData);
      calculateEquipmentTotal(rowIndex, newData);
    }
  };

  const handleDeliveryChange = (e: any, index: number, rowId: string) => {
    if (e.target.value >= 0) {
      const newData = _.cloneDeep(props.equipmentRates);
      const rowIndex = newData.findIndex((item: any) => item._id === rowId);
      newData[rowIndex].puAndDelivery = +e.target.value;
      handleModifiedRate(rowIndex);
      newData[rowIndex].modified = true;
      calculateCost(rowIndex, newData);
    }
  };
  const calculateCost = (index: any, newData: any) => {
    calculateEquipmentTotals(index, newData);
  };
  const handleSurchargesChange = (e: any, index: number, rowId: string) => {
    if (e.target.value >= 0) {
      const newData = _.cloneDeep(props.equipmentRates);
      const rowIndex = newData.findIndex((item: any) => item._id === rowId);
      newData[rowIndex].surcharges = +e.target.value;
      handleModifiedRate(rowIndex);
      newData[rowIndex].modified = true;
      calculateCost(rowIndex, newData);
    }
  };

  const calculateEquipmentTotal = (index: number, newData: any) => {
    // const newData = [...props.equipmentRates];

    newData[index].totalCost =
      (newData[index].cost +
        newData[index].puAndDelivery +
        newData[index].surcharges) *
      newData[index].count *
      newData[index].periods *
      (1 + newData[index].tax / 100);

    newData[index].totalCost = customToFixed(newData[index].totalCost, 2);

    props.setEquipmentState(newData);
  };

  const handleCost = (e: any, index: number, rowId: string) => {
    if (e.target.value >= 0) {
      const newData = [...props.equipmentRates];
      const rowIndex = newData.findIndex((item: any) => item._id === rowId);

      newData[rowIndex].cost = +e.target.value;
      handleModifiedRate(rowIndex);
      newData[rowIndex].modified = true;
      // props.setEquipmentState(newData);
      calculateEquipmentTotal(rowIndex, newData);
    }
  };

  const handleTax = (e: any, index: number, rowId: string) => {
    const newData = [...props.equipmentRates];
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    newData[rowIndex].tax = e.target.value;
    handleModifiedRate(rowIndex);
    newData[rowIndex].modified = true;
    calculateEquipmentTotal(rowIndex, newData);
  };

  const onCountChange = (e: any, index: number, rowId: string) => {
    if (e.target.value >= 0) {
      const newData = [...props.equipmentRates];
      const rowIndex = newData.findIndex((item: any) => item._id === rowId);
      newData[rowIndex].count = e.target.value;
      // props.setEquipmentState(newData);
      // props.setEquipmentState(newData);
      calculateEquipmentTotal(rowIndex, newData);
      // calculateEquipmentTotals(index, newData);
    }
  };
  const calculateEquipmentTotals = (index: number, newData: any) => {
    newData[index].totalCost = parseFloat(
      (
        (newData[index].cost +
          newData[index].puAndDelivery +
          newData[index].surcharges) *
        newData[index].count *
        newData[index].periods *
        (1 + newData[index].tax / 100)
      ).toFixed(2)
    );

    props.setEquipmentState(newData);
  };
  const handleDelete = (id: string) => {
    let project = _.cloneDeep(items[currentProject]);
    const newData = [...props.equipmentRates];
    const optionRate = newData.find((item: any) => item._id === id);
    let optionIndex = project.options.findIndex((option: any) => optionRate.option === option.optionInfo._id)
    project.options[optionIndex].rates.equipmentRates = _.cloneDeep(props.equipmentRates);
    for (const singleOption of project.options) {
      if (singleOption.optionInfo._id === optionRate.option) {
        const optionRateIndex = singleOption.rates.equipmentRates.findIndex(
          (item: any) => item._id === id
        );
        singleOption.rates.equipmentRates[optionRateIndex].isDeleted = true;
        // singleOption.rates.equipmentRates.splice(optionRateIndex, 1);
      }
    }
    let body = {
      category: "equipment",
      id: id,
    };
    dispatch(removeRatesFromOptions(body, project, adminDefaults));
    const index = newData.findIndex((item: any) => item._id === id);
    newData[index].isDeleted = true;
    // newData.splice(index, 1);
    props.setRatesValue({ equipmentRates: newData });
    props.setEquipmentState(newData);
  };

  const deepEqualForClone = (obj1: any, obj2: any): boolean => {
    // If both are direct values
    console.log("OBJECT 1", obj1, "OBJECT 2", obj2);
    if (obj1 === obj2) {
      console.log("equal");
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects excluding the "_id"
    const keys1 = Object.keys(obj1).filter(
      (key) => !["_id", "projectLaborRate"].includes(key)
    );
    const keys2 = Object.keys(obj2).filter(
      (key) => !["_id", "projectLaborRate"].includes(key)
    );

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      console.log("keys length Not equal");
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqualForClone(obj1[key], obj2[key])) {
        console.log(
          "Check if every key-value pair in obj1 matches that in obj2"
        );
        return false;
      }
    }

    return true;
  };

  const deepEqualCheckForClone = (editedObject: any, oldObjectArray: any) => {
    for (let oldObject of oldObjectArray) {
      const updatedOldObject = {
        ...oldObject,
      };
      if (deepEqualForClone(editedObject, updatedOldObject)) {
        return true;
      }
    }
    return false;
  };

  const deepEqualForCloneInProjectRates = (obj1: any, obj2: any): boolean => {
    // If both are direct values
    console.log("OBJECT 1", obj1, "OBJECT 2", obj2);
    if (obj1 === obj2) {
      console.log("equal");
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects excluding the "_id"
    const keys1 = Object.keys(obj1).filter((key) => !["_id"].includes(key));
    const keys2 = Object.keys(obj2).filter((key) => !["_id"].includes(key));

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      console.log("keys length Not equal");
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqualForClone(obj1[key], obj2[key])) {
        console.log(
          "Check if every key-value pair in obj1 matches that in obj2"
        );
        return false;
      }
    }

    return true;
  };

  const deepEqualCheckForCloneInProjectRates = (
    editedObject: any,
    oldObjectArray: any
  ) => {
    for (let oldObject of oldObjectArray) {
      const updatedOldObject = {
        ...oldObject,
      };
      if (deepEqualForCloneInProjectRates(editedObject, updatedOldObject)) {
        return {
          rate: updatedOldObject,
          result: true,
        };
      }
    }
    return {
      rate: editedObject,
      result: false,
    };
  };

  const handleEquipmentClone = (
    data: any,
    index: number,
    surfaceName: string
  ) => {
    const result = props.equipmentRates.some((obj: any) => {
      let name: string = obj.item;
      if (name.replaceAll(" ", "") === surfaceName[index].replaceAll(" ", "")) {
        return true;
      }
    });
    if (result === true) {
      message.error("Name already taken");
    } else {
      let projectLaborRateId = crypto.randomBytes(12).toString("hex");

      const newRates = {
        ...data,
        item: surfaceName[index],
        project: items[currentProject].projectId,
        projectLaborRate: projectLaborRateId,
        _id: crypto.randomBytes(12).toString("hex"),
        isCloned: true,
      };

      const notDeletedRates = props.equipmentRates.filter(
        (item: any) => item.isDeleted === false
      );

      const isClonedArray = notDeletedRates.filter(
        (item: any) => item.isCloned === true
      );

      const rateAlreadyExists = deepEqualCheckForClone(newRates, isClonedArray);
      if (rateAlreadyExists) {
        message.error("Rate Already Exists");
      } else {
        let project = _.cloneDeep(items[currentProject]);
        let selectedOptionIndex = project.options.findIndex(
          (item: any) => item.optionInfo._id === newRates.option
        );
        project.options[selectedOptionIndex].rates.equipmentRates = props.equipmentRates;
        let newProjectEquipmentRates = [];

        let projectEquipmentRate = {
          _id: projectLaborRateId,
          project: newRates.project,
          defaultRate: newRates.defaultRate,
          item: newRates.item,
          surcharges: newRates.surcharges,
          puAndDelivery: newRates.puAndDelivery,
          tax: newRates.tax,
          type: newRates.type,
          cost: newRates.cost,
          isDeleted: false,
          isCloned: true,
          modified: newRates.modified
        };

        const notDeletedProjectRates =
          project.projectRates.equipmentRates.filter(
            (item: any) => item.isDeleted === false
          );

        const isClonedProjectRatesArray = notDeletedProjectRates.filter(
          (item: any) => item.isCloned === true
        );

        const rateAlreadyExistsInProject = deepEqualCheckForCloneInProjectRates(
          newRates,
          isClonedProjectRatesArray
        );

        if (rateAlreadyExistsInProject.result) {
          const foundedRate = rateAlreadyExistsInProject.rate;
          newRates.projectLaborRate = foundedRate._id;
        } else {
          project.projectRates.equipmentRates.push(projectEquipmentRate);
          newProjectEquipmentRates.push(projectEquipmentRate);
        }

        let optionIndex = project.options.findIndex(
          (item: any) => item.optionInfo._id === newRates.option
        );
        project.options[optionIndex].rates.equipmentRates.push(newRates);

        const body = [
          {
            category: "equipment",
            rate: [newRates],
          },
        ];

        const newData = [...props.equipmentRates];
        newData.push({ ...newRates });
        props.setEquipmentState(newData);

        if (newProjectEquipmentRates.length !== 0) {
          let newBodyForProjectRates = [
            {
              category: "equipment",
              rate: newProjectEquipmentRates,
            },
          ];
          dispatch(
            addServiceToProject(
              items[currentProject].projectId,
              newBodyForProjectRates
            )
          );
        }
        dispatch(addRatesToOptions(body, project, adminDefaults));
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "+" || e.key === "-" || e.key === "." || e.key === "e") {
      e.preventDefault();
    }
  };

  const handleModifiedRate = (index: number) => {
    const newData = [...props.equipmentRates];
    props.handleCheckModifiedOnEdit(newData[index]);
  };

  const optionEquipmentColumns = [
    {
      title: "Item",
      key: "item",
      dataIndex: "item",
      width: "200px",
    },
    {
      title: "Count",
      key: "count",
      width: "200px",
      render: (data: any, _: any, index: number) => {
        return (
          <div className="qty--text--field">
            <Input
              type="number"
              style={{ width: 100 }}
              onChange={(e: any) => onCountChange(e, index, _._id)}
              value={data?.count}
            />
          </div>
        );
      },
    },

    {
      title: "Interval",
      width: "200px",
      render: (data: any, _: any, index: number) => {
        return (
          <div
            className="table--editor--holder option-detail-table-exp"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Select
              onSelect={(value: string) => handleInterval(value, index, _._id)}
              style={{ width: "100px" }}
              value={data.type}
            >
              <Option value="daily">Daily</Option>
              <Option value="weekly">Weekly</Option>
              <Option value="monthly">Monthly</Option>
              <Option value="other">Other</Option>
            </Select>
          </div>
        );
      },
    },
    {
      title: "Periods",
      dataIndex: "periods",
      width: "200px",
      render: (periods: any, _: any, index: number) => {
        return (
          <div>
            <Input
              type="number"
              style={{ width: 100 }}
              onChange={(e: any) => handlePeriodsChange(e, index, _._id)}
              value={periods}
              defaultValue={periods}
              onKeyDown={handleKeyDown}
            />
          </div>
        );
      },
    },

    {
      title: "Total",
      dataIndex: "totalCost",

      render: (totalCost: any) => {
        return (
          <div style={{ marginLeft: 10 }}>{totalCost ? totalCost : 0}</div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "60px",

      render: (data: any, _: any, index: number) => {
        return (
          <div
            className="table--editor--holder"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <ActionMenu
              handleRowExpand={handleExpand}
              handleClone={handleEquipmentClone}
              handleDelete={handleDelete}
              data={data}
              index={index}
              category="Equipment"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      {props.equipmentRates?.length > 0 && (
        <Table
          scroll={{ x: 900 }}
          rowKey="_id"
          rowClassName={(_, index) =>
            index % 2 === 0
              ? "table_row table-row-light"
              : "table_row table-row-dark"
          }
          pagination={false}
          columns={optionEquipmentColumns}
          dataSource={props.equipmentRates
            ?.filter((item: any) => !item.isDeleted)
            .sort((a: any, b: any) => a.item.localeCompare(b.item))}
          expandedRowKeys={expandedRowKeys}
          expandIcon={(props) => CustomExpandIcon(props)}
          onExpandedRowsChange={setExpandedRowKeys}
          expandable={{
            expandedRowRender: (record, index) => (
              <ExpandedEquipmentRow
                handleCost={handleCost}
                handleTax={handleTax}
                handleDelivery={handleDeliveryChange}
                handleSurcharges={handleSurchargesChange}
                record={record}
                index={index}
              />
            ),
          }}
        />
      )}
    </>

  );
};

export default Equipment;
