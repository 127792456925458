import React, { useEffect, useState } from "react";
import { Card, Form, Input, Button, Row, Col, Checkbox } from "antd";
import "./EditJobsite.less";
import PreviewClient from "./PreviewClient";
import { MaskedInput } from "antd-mask-input";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  updateProjectById,
} from "../../redux/project/action";

const EditClient: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const [form] = Form.useForm();

  const [isEdit, isEditSet] = useState(false);

  const [isOnsite, setIsOnsite] = useState(
    items[currentProject]?.projectInfo?.hasOnSiteContact ? true : false
  );

  const [isMaint, setIsMaint] = useState(
    items[currentProject]?.projectInfo?.hasMaintenanceContact ? true : false
  );

  const onCheckSiteChange = (e: any) => {
    if (e.target.checked) {
      setIsOnsite(true);
    } else {
      setIsOnsite(false);
    }
  };
  const onCheckMainChange = (e: any) => {
    if (e.target.checked) {
      setIsMaint(true);
    } else {
      setIsMaint(false);
    }
  };

  const onFinish = (values: any) => {
    const data = {
      ...items[currentProject].projectInfo,
      primaryContactName: values.PrimaryContactName,
      primaryContactTelephone: values.PrimaryContactTelephone,
      primaryContactCell: values.PrimaryContactCell,
      primaryContactEmail: values.PrimaryContactEmail,
      primaryContactAddress: values.PrimaryContactAddress,
      hasMaintenanceContact: isMaint,
      maintenanceContactName: values.MaintenanceContactName,
      maintenanceContactTelephone: values.MaintenanceContactTelephone,
      maintenanceContactAddress: values.MaintenanceContactAddress,
      maintenanceContactCell: values.MaintenanceContactCell,
      maintenanceContactEmail: values.MaintenanceContactEmail,
      onSiteContactName: values["On-siteContactName"],
      onSiteContactTelephone: values["On-siteContactTelephone"],
      onSiteContactAddress: values["On-siteContactAddress"],
      onSiteContactCell: values["On-siteContactCell"],
      onSiteContactEmail: values["On-siteContactEmail"],
      hasOnSiteContact: isOnsite,
    };
    dispatch(updateProjectById(data));
    isEditSet(false);
  };
  const showEditForm = () => {
    isEditSet(true);
  };

  const onCancel = () => {
    isEditSet(false);
    form.resetFields();
  };

  return (
    <div>
      <Card>
        {!isEdit ? (
          <>
            <div className="profile-content job-container form-container ">
              <div>
                <Row justify="space-between">
                  <Col>
                    <h1 className="form-subtitle">
                      Client's contact information
                    </h1>
                    <h1 className="project-type">Primary</h1>
                  </Col>
                  <Col style={{ textAlign: "right" }}>
                    <p className="edit--text" onClick={showEditForm}>
                      Edit
                    </p>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col span={6}>
                  <h4>Name</h4>
                </Col>
                <Col span={6}>
                  <p>
                    {items[currentProject]?.projectInfo?.primaryContactName}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <h4>Telephone</h4>
                </Col>
                <Col span={6}>
                  <p>
                    {
                      items[currentProject]?.projectInfo
                        ?.primaryContactTelephone
                    }
                  </p>
                </Col>
              </Row>

              <Row>
                <Col span={6}>
                  <h4>Cell</h4>
                </Col>
                <Col span={6}>
                  <p>
                    {items[currentProject]?.projectInfo?.primaryContactCell}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col span={6}>
                  <h4>Email</h4>
                </Col>
                <Col span={6}>
                  <p>
                    {items[currentProject]?.projectInfo?.primaryContactEmail}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col span={6}>
                  <h4>Address</h4>
                </Col>
                <Col span={6}>
                  <p>
                    {items[currentProject]?.projectInfo?.primaryContactAddress}
                  </p>
                </Col>
              </Row>
            </div>

            {isOnsite && (
              <PreviewClient
                name={items[currentProject]?.projectInfo?.onSiteContactName}
                telephone={
                  items[currentProject]?.projectInfo?.onSiteContactTelephone
                }
                cell={items[currentProject]?.projectInfo?.onSiteContactCell}
                email={items[currentProject]?.projectInfo?.onSiteContactEmail}
                address={
                  items[currentProject]?.projectInfo?.onSiteContactAddress
                }
                title="On-site"
              />
            )}

            {isMaint && (
              <PreviewClient
                name={
                  items[currentProject]?.projectInfo?.maintenanceContactName
                }
                telephone={
                  items[currentProject]?.projectInfo
                    ?.maintenanceContactTelephone
                }
                cell={
                  items[currentProject]?.projectInfo?.maintenanceContactCell
                }
                email={
                  items[currentProject]?.projectInfo?.maintenanceContactEmail
                }
                address={
                  items[currentProject]?.projectInfo?.maintenanceContactAddress
                }
                title="Maintenance"
              />
            )}
          </>
        ) : (
          <div className="job-container form-container ">
            <Form
              layout="vertical"
              onFinish={onFinish}
              form={form}
              scrollToFirstError
              autoComplete="off"
            >
              <h1 className="project-type">Client's contact information</h1>
              <div className="checkbox-container">
                <Checkbox checked disabled>
                  Primary
                </Checkbox>
                <Checkbox checked={isOnsite} onChange={onCheckSiteChange}>
                  On-site
                </Checkbox>
                <Checkbox checked={isMaint} onChange={onCheckMainChange}>
                  Invoice
                </Checkbox>
              </div>
              <EditPart
                name={items[currentProject]?.projectInfo?.primaryContactName}
                telephone={
                  items[currentProject]?.projectInfo?.primaryContactTelephone
                }
                cell={items[currentProject]?.projectInfo?.primaryContactCell}
                email={items[currentProject]?.projectInfo?.primaryContactEmail}
                address={
                  items[currentProject]?.projectInfo?.primaryContactAddress
                }
                title={"Primary"}
                form={form}
              />
              {isOnsite && (
                <EditPart
                  name={items[currentProject]?.projectInfo?.onSiteContactName}
                  telephone={
                    items[currentProject]?.projectInfo?.onSiteContactTelephone
                  }
                  cell={items[currentProject]?.projectInfo?.onSiteContactCell}
                  email={items[currentProject]?.projectInfo?.onSiteContactEmail}
                  address={
                    items[currentProject]?.projectInfo?.onSiteContactAddress
                  }
                  title={"On-site"}
                  form={form}
                />
              )}
              {isMaint && (
                <EditPart
                  name={
                    items[currentProject]?.projectInfo?.maintenanceContactName
                  }
                  telephone={
                    items[currentProject]?.projectInfo
                      ?.maintenanceContactTelephone
                  }
                  cell={
                    items[currentProject]?.projectInfo?.maintenanceContactCell
                  }
                  email={
                    items[currentProject]?.projectInfo?.maintenanceContactEmail
                  }
                  address={
                    items[currentProject]?.projectInfo
                      ?.maintenanceContactAddress
                  }
                  title={"Maintenance"}
                  form={form}
                />
              )}
              <div className="btn-container">
                <div>
                  <Button
                    onClick={onCancel}
                    style={{
                      border: "none",
                      color: "#FDB913",
                      width: "94px",
                      height: "40px",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button
                    style={{
                      border: "none",
                      color: "#1F1F1F",
                      width: "94px",
                      height: "40px",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Card>
    </div>
  );
};

const EditPart: React.FC<{
  name: string;
  telephone: string;
  title: string;
  cell: string;
  email: string;
  address: string;
  form: any;
}> = ({ name, telephone, cell, email, address, title, form }) => {
  const [cellRequired, setCellRequired] = useState(true);
  const [telephoneRequired, setTelephoneRequired] = useState(true);
  useEffect(() => {
    let telephone = form.getFieldValue(`${title}ContactTelephone`);
    let cell = form.getFieldValue(`${title}ContactCell`);

    if (telephone) {
      setCellRequired(false);
    }
    if (cell) {
      setTelephoneRequired(false);
    }
  }, []);

  const handleRequired = () => {
    let telephone = form.getFieldValue(`${title}ContactTelephone`);
    let cell = form.getFieldValue(`${title}ContactCell`);

    if (
      (telephone === "(___) ___-____" || telephone === "") &&
      (cell === "(___) ___-____" || cell === "")
    ) {
      setCellRequired(true);
      setTelephoneRequired(true);
      console.log("inside both validations failed IF-statement");
      return;
    }
    if (telephone && !telephone.endsWith("_")) {
      console.log("inside telephone field input IF-statment");
      setCellRequired(false);
      if (cell === "(___) ___-____" || cell === "") {
        form.setFieldValue(`${title}ContactCell`, "");
      }
    }
    if (cell && !cell.endsWith("_")) {
      console.log("inside cell field input IF-statment");
      setTelephoneRequired(false);
      if (telephone === "(___) ___-____" || telephone === "") {
        form.setFieldValue(`${title}ContactTelephone`, "");
      }
    }
  };

  const checkJobNumber = {
    validator(_: any, value: string) {
      if (value) {
        if (
          (value.endsWith("_") && cellRequired) ||
          (value.endsWith("_") && telephoneRequired)
        ) {
          return Promise.reject(new Error("Please enter 10 digit number"));
        }
      }

      return Promise.resolve();
    },
  };

  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };
  return (
    <div>
      <div style={{ paddingTop: "20px" }}>
        <h1 className="project-type">
          {title === "Maintenance" ? "Invoice" : title}
        </h1>
      </div>
      <div className="job-form" style={{ marginTop: "23px" }}>
        <Row gutter={17}>
          <Col span={12}>
            <div>
              <Form.Item
                initialValue={name}
                hasFeedback
                name={`${title}ContactName`}
                label={<h1 className="form-text">Name</h1>}
                rules={[
                  { required: true, message: "Please enter the Name!" },
                  checkEmpty,
                ]}
              >
                <Input className="form-input" />
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <div>
              <Form.Item
                initialValue={telephone}
                hasFeedback
                name={`${title}ContactTelephone`}
                label={<h1 className="form-text">Telephone</h1>}
                rules={[
                  {
                    required: telephoneRequired,
                    message: "Please enter the telephone!",
                  },
                  { whitespace: true },
                  checkJobNumber,
                ]}
                validateTrigger={["onBlur", "onChange"]}
              >
                <MaskedInput
                  onBlur={handleRequired}
                  mask={"(000) 000-0000"}
                  className="form-input"
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={17}>
          <Col span={12}>
            <div>
              <Form.Item
                initialValue={cell}
                hasFeedback
                name={`${title}ContactCell`}
                label={<h1 className="form-text">Cell</h1>}
                rules={[
                  {
                    required: cellRequired,
                    message: "Please enter the cell!",
                  },
                  { whitespace: true },
                  checkJobNumber,
                ]}
                validateTrigger={["onBlur", "onChange"]}
              >
                <MaskedInput
                  onBlur={handleRequired}
                  mask={"(000) 000-0000"}
                  className="form-input"
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <div>
              <Form.Item
                initialValue={email}
                hasFeedback
                name={`${title}ContactEmail`}
                label={<h1 className="form-text">Email</h1>}
                rules={[
                  { type: "email", message: "Enter valid email" },
                  { required: true, message: "Enter the email" },
                ]}
              >
                <Input className="form-input" />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row gutter={17}>
          <Col span={12}>
            <div>
              <Form.Item
                initialValue={address}
                hasFeedback
                name={`${title}ContactAddress`}
                label={<h1 className="form-text">Address</h1>}
                rules={[
                  { required: true, message: "Enter the address" },
                  checkEmpty,
                ]}
              >
                <Input className="form-input" />
              </Form.Item>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditClient;
