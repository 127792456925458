export const colorsColumns = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
  },
  {
    title: "Hex-Code",
    dataIndex: "hexCode",
    render: (data: any) => {
      return <div>{data}</div>;
    },
  },
];

export const colorsHeader = [
  {
    label: "name",
    key: "name",
  },
  {
    label: "hexCode",
    key: "hexCode",
  },
  {
    label: "_id",
    key: "_id",
  },
];
