import { Row, Col } from "antd";
import "./ProposalSetup.less";
import { useSelector, RootStateOrAny } from "react-redux";

type Props = {
  name?: string;
  address?: string;
  telephone?: string;
  email?: string;
};

const JobsiteInfo = (props: Props) => {
  return (
    <div style={{ paddingTop: 55 }} className="psc-container">
      <h1>Job site Information</h1>
      <Row gutter={[2, 14]}>
        <Col span={4}>
          <h2>Property Name</h2>
        </Col>
        <Col span={8} style={{ wordWrap: "break-word" }}>
          <h3>{props.name}</h3>
        </Col>
        <Col span={3}>
          <h2>Telephone</h2>
        </Col>
        <Col span={9}>
          <h3>{props.telephone}</h3>
        </Col>
        <Col span={4}>
          <h2>Property Address</h2>
        </Col>
        <Col span={8}>
          <h3>{props.address}</h3>
        </Col>
        <Col span={3}>
          <h2>Email</h2>
        </Col>
        <Col span={9}>
          <h3>{props.email}</h3>
        </Col>
      </Row>
    </div>
  );
};

export default JobsiteInfo;
