import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import "./DescriptionCard.less";

type Props = {
  dummy: any;
  notes?: string;
};

const DescriptionCard = (props: Props) => {
  const [notesValue, setNotesValue] = useState("");
  useEffect(() => {
    if (props?.notes) {
      let notesWIthLink = addLinkToText(props.notes);
      setNotesValue(notesWIthLink);
    }
  }, []);

  const addLinkToText = (text: string): string => {
    const regex = /\[([^\]]+)\]/g; // Matches anything between square brackets.

    return text?.replace(regex, (_: string, group1: string) => {
      let url = group1;

      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "http://" + url;
      }

      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${group1}</a>`;
    });
  };

  return (
    <div className="desc-card-root">
      <div className="desc-card-sub">
        <Row>
          {props.dummy.map((data: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <Col lg={4} md={8} xs={12}>
                  <h1>{data.title}</h1>
                </Col>
                <Col lg={8} md={16} xs={12}>
                  <p>{data.description}</p>
                </Col>
              </React.Fragment>
            );
          })}
        </Row>
        <div>
          {props.notes && (
            <>
              <Row>
                <h1>Notes:</h1>
              </Row>
              <Row>
                <pre
                  style={{
                    fontSize: "16px",
                  }}
                  dangerouslySetInnerHTML={{ __html: notesValue }}
                />
              </Row>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DescriptionCard;
