import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Checkbox, Form, Input, message } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { nextTick } from "process";
import { RootStateOrAny, useSelector } from "react-redux";

type Props = {
  showModalForEstimate: boolean;
  toggleModal: (item: any) => void;
  handleOptionClone: (values: any) => void;
  form: any;
};

const CloneOption = (props: Props) => {
  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const [address, setAddress] = useState("");

  useEffect(() => {
    const jobSiteAddress = `${
      items[currentProject].projectInfo?.clientName
        ? items[currentProject].projectInfo?.clientName + ","
        : ""
    } ${items[currentProject].projectInfo?.streetAddressOne}, ${
      items[currentProject].projectInfo?.streetAddressTwo
        ? items[currentProject].projectInfo?.streetAddressTwo + ", "
        : ""
    } ${items[currentProject].projectInfo?.city}, ${
      items[currentProject].projectInfo?.state
    }, ${items[currentProject].projectInfo?.zip}`;
    setAddress(jobSiteAddress);
  }, []);

  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };

  return (
    <div>
      <Modal
        className="surface-modal"
        centered={true}
        visible={props.showModalForEstimate}
        // onOk={props.toggleModal}
        onCancel={props.toggleModal}
        title={
          <h1 style={{ marginTop: 10 }} className="send-title-text">
            Clone Option
          </h1>
        }
        footer={false}
      >
        <Form
          form={props.form}
          onFinish={props.handleOptionClone}
          layout="vertical"
          scrollToFirstError
          autoComplete="off"
        >
          <div>
            <Row justify="center">
              <Col span={24}>
                <div className="surface-container">
                  <h3 className="send-req-input-title">Option Title</h3>

                  <Form.Item
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please enter!",
                      },
                      checkEmpty,
                    ]}
                  >
                    <Input className="form-input" />
                  </Form.Item>
                </div>

                <div className="surface-container">
                  <h3 className="send-req-input-title">Address</h3>

                  <Form.Item
                    name="address"
                    initialValue={address}
                    rules={[
                      {
                        required: true,
                        message: "Please enter!",
                      },
                      checkEmpty,
                    ]}
                  >
                    <Input className="form-input" />
                  </Form.Item>
                </div>
                <div className="surface-container">
                  <Form.Item
                    name="addCount"
                    valuePropName="checked"
                    getValueFromEvent={(e) => e.target.checked}
                  >
                    <Checkbox>Add Existing Count to the Clone</Checkbox>
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Row justify="end" style={{ marginTop: 20 }}>
              <Col>
                <Button
                  key="back"
                  onClick={props.toggleModal}
                  className="cancel-btn"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  key="submit"
                  name="add"
                  type="primary"
                  className="send-btn"
                  htmlType="submit"
                >
                  Add Clone
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default CloneOption;
