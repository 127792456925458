import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Row, Col, Select, Input, Form } from "antd";
import "./AddNewModal.less";
type Props = {
  showSourceLocationModal: boolean;
  toggleSourceLocationModal: (item: any) => void;
  handleAddAmount: (item: any) => void;
  checkRequestRetainerPercent: number;
};

const AddNewModal = (props: Props) => {
  const [newModalVisible, setNewModalVisible] = useState(false);
  const [formRef] = Form.useForm();

  useEffect(() => {
    formRef.setFieldsValue({
      amount: 0,
      retainerPercentage: props.checkRequestRetainerPercent.toString(),
      description: "",
    });
  }, [props.checkRequestRetainerPercent, formRef]);

  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };

  const onFinish = (values: any) => {
    props.handleAddAmount(values);
    formRef.setFieldsValue({
      amount: 0,
    });
  };

  const showNewModal = () => {
    setNewModalVisible(true);
  };

  const hideNewModal = () => {
    setNewModalVisible(false);
  };
  const handleYesButtonClick = () => {
    formRef.submit();
    hideNewModal();
  };
  return (
    <div>
      <Modal
        className="add-new-modal"
        centered={true}
        visible={props.showSourceLocationModal}
        onOk={props.toggleSourceLocationModal}
        onCancel={props.toggleSourceLocationModal}
        footer={false}
        title={<h1 style={{ marginTop: 10 }}>Add New Payment</h1>}
      >
        <Form
          form={formRef}
          onFinish={onFinish}
          scrollToFirstError
          layout="vertical"
          autoComplete="off"
          initialValues={{
            amount: 0,
            retainerPercentage: props.checkRequestRetainerPercent.toString(),
            description: "",
          }}
        >
          <Form.Item
            hasFeedback
            name="amount"
            label={<h2>Enter the amount</h2>}
            rules={[
              {
                required: true,
                message: "Please enter the amount!",
              },
              checkEmpty,
            ]}
          >
            <Input
              placeholder="$0"
              type="number"
              min="0"
              className="add-modal-form-input"
            />
          </Form.Item>

          <Form.Item
            hasFeedback
            name="retainerPercentage"
            label={<h2>Retainer Percentage</h2>}
            rules={[
              {
                required: true,
                message: "Retainer Percentage!",
              },
              checkEmpty,
            ]}
          >
            <Input
              placeholder="%"
              type="number"
              min="0"
              className="add-modal-form-input"
            />
          </Form.Item>
          <Form.Item
            hasFeedback
            name="description"
            label={<h2>Description</h2>}
            // rules={[
            //   {
            //     required: true,
            //     message: "Retainer Percentage!",
            //   },
            //   checkEmpty,
            // ]}
          >
            <Input
              placeholder="Add a Description"
              type="string"
              className="add-modal-form-input"
            />
          </Form.Item>
          <Row style={{ marginTop: 60 }} gutter={10} justify="end">
            <Col>
              <Button
                style={{
                  color: "#FDB913",
                  border: "1px solid #fdb913",
                }}
                className="add-modal-cancel "
                onClick={props.toggleSourceLocationModal}
              >
                Cancel
              </Button>
            </Col>

            <Col>
              <Button
                className="add-modal-submit"
                type="primary"
                onClick={showNewModal}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        className="new-modal"
        centered={true}
        title={
          <div style={{ color: "black", fontWeight: "bold" }}>Confirmation</div>
        }
        visible={newModalVisible}
        onCancel={hideNewModal}
        footer={[
          <Button key="no" onClick={hideNewModal}>
            No
          </Button>,
          <Button key="yes" type="primary" onClick={handleYesButtonClick}>
            Yes
          </Button>,
        ]}
      >
        <p>Are you sure you want to perform this action?</p>
      </Modal>
    </div>
  );
};

export default AddNewModal;
