import React, { useEffect } from "react";
import { Layout, Row, Col } from "antd";
import WoodOrderC from "../../containers/work-order/wood-order/WoodOrderC";
import ContractorSteps from "./ContractorSteps";

const { Content } = Layout;

const ContractorWoodOrder: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <Content>
      <div>
        <ContractorSteps current={4} />
      </div>

      <div className="steps-content">
        <Row gutter={24}>
          <Col className="gutter-row" span={24}>
            <WoodOrderC />
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default ContractorWoodOrder;
