import { Input } from 'antd';
export const pricingColumns = [
  {
    title: 'Summary of Options',
    dataIndex: 'summary',
    key: 'summary',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'GM%',
    dataIndex: 'gm',
    key: 'gm',

    render: (data: any) => {
      return (
        <div
          style={{
            width: '60px',
            border: '1px solid #E8E8E8',
            borderRadius: '5px',
          }}
        >
          <Input defaultValue="50%" />
        </div>
      );
    },
  },
];
