import React, { PropsWithChildren, useState, useEffect } from 'react';

import { Layout } from 'antd';
import PaymentRecordC from '../../containers/payment-contractor/payment-record/PaymentRecordC';

const { Content } = Layout;

const PaymentRecord: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div>
      <PaymentRecordC />
    </div>
  );
};

export default PaymentRecord;
