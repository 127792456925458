import React, { useEffect } from "react";
import { Layout, Row, Col } from "antd";
import JobJacketContract from "../../containers/jobjacket-contract/JobJacketContract";
import ContractorSteps from "./ContractorSteps";
const { Content } = Layout;

const ContractorPreView: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <Content>
      <div>
        <ContractorSteps current={5} />
      </div>

      <div className="steps-content">
        <Row gutter={24}>
          <Col className="gutter-row" span={24}>
            <JobJacketContract />
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default ContractorPreView;
