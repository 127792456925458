import { Col, Input, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { BulletIcon, SearchIcon } from "../../utils/icons";
import { Image } from "antd";
import "./Media.less";
import { useParams } from "react-router-dom";
import api from "../../utils/api";
import { useSelector, RootStateOrAny } from "react-redux";
import moment from "moment";

type Props = {};

const DocumentCard = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const [documents, setDocuments] = useState<any>([]);
  const [activeDocs, setActiveDOcs] = useState<any>([]);
  const [archivedDocs, setArchivedDOcs] = useState<any>([]);
  const [paintOrdersArr, setPaintOrdersArr] = useState<any>([]);
  const [checkRequestArr, setCheckRequestArr] = useState<any>([]);
  const [uploadedDocsArr, setUploadedDocsArr] = useState<any>([]);
  const [inputSearch, setInputSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const { online } = useSelector((state: RootStateOrAny) => state.offline);

  useEffect(() => {
    setLoading(true);
    if (online) {
      api
        .get(`upload-files/document/${id}`)
        .then((resp: any) => {
          const dateArr: any[] = [];
          const jobNameArr: any[] = [];
          const uploadedDocs: any[] = [];
          console.log(resp);

          resp.forEach((item: any) => {
            const nameParts = item.name.split(" : ");
            if (
              nameParts.length === 2 &&
              nameParts[1].trim().match(/^\d{4}-\d{2}-\d{2}/)
            ) {
              dateArr.push(item);
            } else {
              jobNameArr.push(item);
            }
          });

          const checkRequests = resp.filter((item: any) =>
            item.name.startsWith("Check Request")
          );
          const paintOrders = resp.filter((item: any) =>
            item.name.startsWith("Paint Order")
          );
          const uploadFiles = resp.filter(
            (item: any) =>
              !item.name.startsWith("Paint Order") &&
              !item.name.startsWith("Check Request") &&
              item.active === true &&
              item.addToProject === true
          );
          setPaintOrdersArr(paintOrders);
          setCheckRequestArr(checkRequests);
          setUploadedDocsArr(uploadFiles);

          let activeDocuments: any = [];
          let archivedDocuments: any = [];

          dateArr.forEach((item: any) => {
            if (item?.active === true) {
              activeDocuments.push(item);
            }
            if (item?.active === false) {
              archivedDocuments.push(item);
            }
          });

          setActiveDOcs(
            activeDocuments.filter((item: any) => item.addToProject === true)
          );
          setArchivedDOcs(archivedDocuments);

          setDocuments(resp);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [online]);

  const foundActive = activeDocs?.filter((item: any) => {
    if (!inputSearch) {
      return true;
    }
    return item.name.toLowerCase().includes(inputSearch.toLowerCase());
  });

  const foundArchived = archivedDocs?.filter((item: any) => {
    if (!inputSearch) {
      return true;
    }
    return item.name.toLowerCase().includes(inputSearch.toLowerCase());
  });

  const openPdfInNewTab = (pdfUrl: string, itemName: string) => {
    if (/iPhone|iPad|iPod|Macintosh|Mac OS X/i.test(navigator.userAgent)) {
      const a = document.createElement("a");
      a.href = pdfUrl;
      a.target = "_blank";
      a.click();
    } else {
      fetch(pdfUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const objectUrl = URL.createObjectURL(blob);
          const win = window.open(objectUrl, "_blank");
          if (!win) {
            console.error(
              "Unable to open a new tab. Please check your browser settings."
            );
          } else {
            win.document.title = itemName;
          }
        })
        .catch((error) => {
          console.error("Error loading PDF:", error);
        });
    }
  };

  return (
    <div className="media-background">
      <Row justify="space-between">
        <Col>
          <h1>Document</h1>
        </Col>
        <Col>
          <Input
            className="media-search"
            placeholder="Search Documents"
            prefix={<SearchIcon />}
            value={inputSearch}
            onChange={(e: any) => setInputSearch(e.target.value)}
          />
        </Col>
      </Row>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <h2>Active:</h2>
          {foundActive?.length !== 0 && (
            <>
              <Row gutter={20} style={{ fontWeight: "bold" }}>
                <Col span={1}></Col>
                <Col span={11}>File Name</Col>
                <Col span={12}>Generated Time</Col>
              </Row>
            </>
          )}

          <Row gutter={20}>
            {foundActive?.map((item: any, index: number) => {
              return (
                <React.Fragment key={item?.thumbUrl}>
                  <Col>
                    <BulletIcon style={{ marginRight: "10px" }} />
                  </Col>
                  <Col span={11}>
                    <a
                      download={`${item?.name}`}
                      href={item?.thumbUrl}
                      onClick={(e) => {
                        e.preventDefault();
                        openPdfInNewTab(item?.thumbUrl, item?.name);
                      }}
                    >
                      {item?.name.split(":")[0]}
                    </a>
                  </Col>
                  <Col span={12}>
                    <p style={{ padding: 0, margin: 0, color: "#FDB913" }}>
                      {item?.name.split(": ")[1]}
                    </p>
                  </Col>
                </React.Fragment>
              );
            })}
          </Row>
          <h2>Uploaded Docs:</h2>
          <Row gutter={20}>
            {uploadedDocsArr?.map((item: any, index: number) => {
              return (
                <React.Fragment key={item?.thumbUrl}>
                  <Col>
                    <BulletIcon style={{ marginRight: "10px" }} />
                  </Col>
                  <Col span={23}>
                    <a
                      download={item?.name}
                      href={item?.thumbUrl}
                      onClick={(e) => {
                        e.preventDefault();
                        openPdfInNewTab(item?.thumbUrl, item?.name);
                      }}
                    >
                      {item?.name}
                    </a>
                  </Col>
                </React.Fragment>
              );
            })}
          </Row>
          <h2>Paint Order:</h2>
          <Row gutter={20}>
            {paintOrdersArr?.map((item: any, index: number) => {
              return (
                <React.Fragment key={item?.thumbUrl}>
                  <Col span={1}>
                    <BulletIcon style={{ marginRight: "10px" }} />
                  </Col>
                  <Col span={23}>
                    <a
                      download={item?.name}
                      href={item?.thumbUrl}
                      onClick={(e) => {
                        e.preventDefault();
                        openPdfInNewTab(item?.thumbUrl, item?.name);
                      }}
                    >
                      {item?.name}
                    </a>
                  </Col>
                </React.Fragment>
              );
            })}
          </Row>
          <h2>Check Request</h2>
          <Row gutter={20}>
            {checkRequestArr?.map((item: any, index: number) => {
              return (
                <React.Fragment key={item?.thumbUrl}>
                  <Col>
                    <BulletIcon style={{ marginRight: "10px" }} />
                  </Col>
                  <Col span={23}>
                    <a
                      download={item?.name}
                      href={item?.thumbUrl}
                      onClick={(e) => {
                        e.preventDefault();
                        openPdfInNewTab(item?.thumbUrl, item?.name);
                      }}
                    >
                      {item?.name}
                    </a>
                  </Col>
                </React.Fragment>
              );
            })}
          </Row>
          <h2>Archived:</h2>
          {foundArchived?.length !== 0 && (
            <>
              <Row gutter={20} style={{ fontWeight: "bold" }}>
                <Col span={1}></Col>
                <Col span={11}>File Name</Col>
                <Col span={12}>Generated Time</Col>
              </Row>
            </>
          )}

          <Row gutter={20}>
            {foundArchived?.map((item: any, index: number) => {
              return (
                <React.Fragment key={item?.thumbUrl}>
                  <Col>
                    <BulletIcon style={{ marginRight: "10px" }} />
                  </Col>
                  <Col span={11}>
                    <a
                      download={`${item?.name}`}
                      href={item?.thumbUrl}
                      onClick={(e) => {
                        e.preventDefault();
                        openPdfInNewTab(item?.thumbUrl, item?.name);
                      }}
                    >
                      {item?.name.split(":")[0]}
                    </a>
                  </Col>
                  <Col span={12}>
                    <p style={{ padding: 0, margin: 0, color: "#FDB913" }}>
                      {item?.name.split(": ")[1]}
                    </p>
                  </Col>
                </React.Fragment>
              );
            })}
          </Row>
        </>
      )}
    </div>
  );
};

export default DocumentCard;
