import React from 'react';
import { Button } from 'antd';
import { BlankPageIcon } from '../../utils/icons';
import { useHistory } from 'react-router-dom';
import JobsBulkEntry from '../../components/pop-ups/default-modal/JobsBulkEntry';

interface EmptyScreenProps {
  fetchAllJobs: any;
  showButton: boolean
}
const EmptyScreen: React.FC<EmptyScreenProps> = ({ fetchAllJobs, showButton }) => {
  const history = useHistory();

  const handleCreateProfile = () => {
    history.push('/new-profile');
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <div style={{ marginTop: '30px' }}>
        <BlankPageIcon />
      </div>

      <h1
        style={{
          marginTop: '20px',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '22px',
          lineHeight: '26px',
        }}
      >
        No jobs available
      </h1>

      <div
        style={{
          marginTop: '30px',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '10px',
        }}
      >
        <Button
          onClick={handleCreateProfile}
          style={{
            width: '229px',
            height: '58px',
            borderRadius: '5px',
            color: '#1F1F1F',
            fontWeight: 'bold',
            fontSize: '15px',
            lineHeight: '18px',
          }}
          type="primary"
        >
          ADD NEW PROFILE
        </Button>
        {showButton && (
          <JobsBulkEntry style="empty-screen-btn" pageReload={fetchAllJobs} />
        )}
      </div>
    </div>
  );

};

export default EmptyScreen;
