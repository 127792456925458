import React, { useEffect, useRef, useState } from "react";
import { Layout, Row, Col, Button, theme } from "antd";
import JobJacketContract from "../../containers/jobjacket-contract/JobJacketContract";

import JobJacketSteps from "./JobJacketSteps";
import { useHistory, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import api from "../../utils/api";
import { createJobJacket } from "../../redux/project/action";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import logo from "../../assets/images/Pro-Driven-Edit.jpg";
import lineGradient from "../../assets/images/lineGradient.jpg";
import contractProject from "../../assets/images/contract-project.jpg";
import scope from "../../assets/images/pdfscope.jpg";
import money from "../../assets/images/pdfmoney.jpg";
import dot from "../../assets/images/pdfdot.jpg";
import equipment from "../../assets/images/pdfequipment.jpg";
import correction from "../../assets/images/pdfcorrection.jpg";
import calendar from "../../assets/images/pdfstart-date.jpg";
import moment from "moment";
import { LatoRegular } from "../../assets/fonts/LatoRegular";
import { LatoBold } from "../../assets/fonts/LatoBold";
import { getAuthUser, getSelectedTenant } from "../../utils/authToken";
import { generateProposalCPdf } from "../../utils/proposalCPdf";
import _, { isNull } from "lodash";
import HTMLParser, { JSONToHTML } from "html-to-json-parser";
import useSyncProjectData from "../../utils/useSyncProjectData";
import { Spin } from "antd";
import { contractPdf } from "../../utils/contractPdf";
import axios from "axios";
import { API_BASE } from "../../constant";

var FileSaver = require("file-saver");
// const { convertFile } = require('convert-svg-to-png');

const { Content } = Layout;

const Contract: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const user = getAuthUser();
  const tenantId = getSelectedTenant();
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const { closestStoreData } = useSelector(
    (state: RootStateOrAny) => state.closestStoreData
  );
  const [equipmentNames, setEquipmentNames] = useState<any>([]);
  const [compensation, setCompensation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalSum, setTotalSum] = useState(0);
  const dispatch = useDispatch();
  const [profilePhoto, setProfilePhoto] = useState();
  const [optionsMediaList, setOptionsMediaList] = useState([]);
  const [isLoading] = useSyncProjectData(id);

  const values = {
    startDate: moment(
      items[currentProject].prePresentationChecklist?.projectStartDate
    ).format("DD MMM,YYYY"),
    completionDate: moment(
      items[currentProject].prePresentationChecklist?.jobCompleteDate
    ).format("DD MMM,YYYY"),

    contractor:
      items[currentProject].prePresentationChecklist?.crew?.fullName ??
      "_______________",
    companyName: adminDefaults?.companyInfo.companyName,
    jobName: items[currentProject].projectInfo?.jobName,
  };

  useEffect(() => {
    if (online) {
      setLoading(true);
      fetchData();
    }
  }, [online]);

  async function fetchData() {
    try {
      const photoResponse: any = await api.get(
        `user/photo/${items[currentProject].projectInfo?.salesAssociate._id}`
      );
      setProfilePhoto(photoResponse);

      const mediaResponse = await axios.get(
        `${API_BASE}upload-files/option-media/${items[currentProject].projectId}`,
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
        }
      );
      if (mediaResponse?.data?.length > 0) {
        setOptionsMediaList(mediaResponse.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const productionItems = items[
      currentProject
    ].projectInfo?.productionPjcc?.filter((item: any) => {
      return item.appearOnWorkOrder;
    });
    setCompensation(productionItems);
    setTotalSum(
      productionItems?.reduce((initial: any, curValue: any) => {
        return (
          initial +
          parseFloat(
            curValue.productionTargets ? curValue?.productionTargets : 0
          )
        );
      }, 0)
    );

    const equipmentRates = items[currentProject]?.options
      ?.map((item: any) => item.rates?.equipmentRates)
      ?.reduce((acc: any, val: any) => acc.concat(val), []); // reducing array of array of objects to array of objects
    const uniqueEquipmentRates = [
      ...new Map(equipmentRates.map((rate: any) => [rate.item, rate])).values(),
    ];

    setEquipmentNames(uniqueEquipmentRates);

    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);
  const componentRef = useRef<any>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleDownload = async () => {
    if (componentRef.current) {
      const canvas = await html2canvas(componentRef.current);
      const img = canvas.toDataURL("image/png");
      const doc = new jsPDF({
        orientation: "p", // landscape
        unit: "pt", // points, pixels won't work properly
        format: [canvas.width, canvas.height], // set needed dimensions for any element
      });
      doc.addImage(
        img,
        "PNG",
        0,
        0,
        doc.internal.pageSize.getWidth(),
        doc.internal.pageSize.getHeight()
      );
      doc.save("contract.pdf");
    }
  };

  const next = () => {
    history.push(`/production-associate-check-request/${id}`);
  };

  const generateWorkOrderPaintPdf = async () => {
    const horizontalMargin = 30;
    const columns = [
      { header: "Surface", dataKey: "item" },
      { header: "Product", dataKey: "material" },
      { header: "Color", dataKey: "color" },
      { header: "Coats", dataKey: "coats" },
      { header: "Gallons", dataKey: "gallons" },
      // { header: "Primer", dataKey: "primerMaterial" },
      // { header: "Primer Gallons", dataKey: "primerGallons" },
    ];

    var doc = new jsPDF("p", "pt");
    doc.setFontSize(30);

    doc.text("WORK ORDER", horizontalMargin, 60);
    doc.setFontSize(16);
    doc.setTextColor("#585858");
    doc.text("PAINTING", horizontalMargin, 80);
    doc.setTextColor("black");
    let image = new Image();
    image.src = adminDefaults?.companyInfo.companyLogo;
    const imageAspectRatio = image.naturalWidth / image.naturalHeight;
    const opWidth = 200;
    doc.addImage(
      image,
      "png",
      365,
      25,
      opWidth,
      opWidth / imageAspectRatio,
      "logo",
      "FAST"
    );
    let tableEndYPos = 150;

    doc.addFileToVFS("Lato-Regular.ttf", LatoRegular);
    doc.addFont("Lato-Regular.ttf", "LatoRegular", "normal");
    doc.addFileToVFS("Lato-Bold.ttf", LatoBold);
    doc.addFont("Lato-Bold.ttf", "LatoRegular", "bold");

    for (const option of items[currentProject].paintWorkOrder) {
      if (option.paintLabor > 0) {
        doc.setFont("LatoRegular", "bold");
        doc.setFontSize(16);
        doc.text(option.item, horizontalMargin, tableEndYPos);
        tableEndYPos += 4;
        image.src = lineGradient;
        doc.addImage(image, "png", horizontalMargin, tableEndYPos, 500, 10);
        tableEndYPos += 12;

        doc.setFont("LatoRegular", "normal");
        doc.setFontSize(8);

        // Render Address
        doc.text("Address:", horizontalMargin, tableEndYPos);
        const splitAddress = doc.splitTextToSize(option.address, 150);
        const addressHeight = 10 * splitAddress.length; // Estimate height based on lines
        doc.text(splitAddress, 60 + horizontalMargin, tableEndYPos); // Adjust y position for address

        // Render Wash Hours
        doc.text("Wash Hours:", 230 + horizontalMargin, tableEndYPos);
        doc.text(
          option.washHours.toFixed(2),
          320 + horizontalMargin,
          tableEndYPos
        );

        // Render Paint Hours
        doc.text("Paint Hours:", 390 + horizontalMargin, tableEndYPos);
        doc.text(
          option.paintHours.toFixed(2),
          480 + horizontalMargin,
          tableEndYPos
        );
        tableEndYPos += 12;

        // Render Prep Hours
        doc.text("Prep Hours:", 230 + horizontalMargin, tableEndYPos);
        doc.text(
          option.prepHours.toFixed(2),
          320 + horizontalMargin,
          tableEndYPos
        );

        // Render Paint Labor
        doc.text("Paint Labor:", 390 + horizontalMargin, tableEndYPos);
        doc.text(
          "$" +
          option.paintLabor.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          480 + horizontalMargin,
          tableEndYPos
        );
        tableEndYPos += addressHeight + 5; // Add space after address


        const rows = option.paintRateId;
        const tableDataMapped = rows
          .filter((row: any) => {
            return row.primerGallons > 0 || row.paintGallons > 0;
          })
          .map((row: any) => {
            let paintMaterial = row.paintMaterial;
            let primerMaterial = row.primerMaterial;
            let gallons = "";
            let material = "";
            let color = "      ";
            let coats = "";
            let lines = 0;
            if (
              !primerMaterial ||
              primerMaterial === "undefined:undefined" ||
              row.primerGallons === 0
            ) {
              // primerMaterial = "";
            } else {
              color += "N/A\n      ";
              material += `${primerMaterial}\n`;
              gallons += `${row.primerGallons.toFixed(2)}\n`;
              coats = "Full Prime\n";
              lines++;
            }

            if (
              !paintMaterial ||
              paintMaterial === "undefined:undefined" ||
              row.paintGallons === 0
            ) {
              // paintMaterial = "";
            } else {
              color += row.color;
              material += paintMaterial;
              gallons += row.paintGallons.toFixed(2);
              coats += row.coats;
              lines++;
            }

            if (paintMaterial === "" && row.paintGallons >= 0) {
              color += row.color;
              material += paintMaterial;
              gallons += row.paintGallons.toFixed(2);
              coats += row.coats;
              lines++;
            }

            let colorObj = adminDefaults.colors.find(
              (item: any) => item.name === row.color
            );
            return {
              ...row,
              gallons,
              material,
              color,
              colorCode: colorObj?.hexCode,
              coats,
              lines,
            };
          });
        console.log(tableDataMapped);

        autoTable(doc, {
          body: tableDataMapped,
          columns: columns,
          startY: tableEndYPos,
          margin: { horizontal: 30 },
          styles: {
            lineColor: "#585858",
            lineWidth: 1,
            minCellHeight: 5,
          },
          headStyles: {
            fillColor: "#FDB913",
            halign: "center",
            textColor: "black",
            fontSize: 8,
          },
          bodyStyles: { fontSize: 8, textColor: "black" },
          columnStyles: {
            0: {
              cellWidth: 80,
            },
            1: {
              cellWidth: 185,
            },
            2: {
              cellWidth: 185,
            },
            3: {
              halign: "center",
              cellWidth: 45,
            },
            4: {
              halign: "center",
              cellWidth: 45,
            },
          },
          // eslint-disable-next-line no-loop-func
          didDrawPage: (data) => {
            tableEndYPos = data.cursor?.y || tableEndYPos;
            // tableEndYPos = data.table.finalY || 0;
          },
          didDrawCell: (data) => {
            // console.log(data);
            if (data.row.section !== "head") {
              if (data.row.index % 2 === 0) {
                data.cell.styles.fillColor = "#E2E2E2";
              } else {
                data.cell.styles.fillColor = "#FFFFFF";
              }
              let xPos = data.cursor ? data.cursor.x : 0;
              let yPos = data.cursor ? data.cursor.y : 0;
              let startYColor = 5;
              if (
                data.row.index >= 0 &&
                tableDataMapped[data.row.index].lines >= 2
              ) {
                startYColor = 14;
              }

              if (
                data.column.dataKey === "color" &&
                data.row.index >= 0 &&
                tableDataMapped[data.row.index].colorCode
              ) {
                let fillColor = doc.getFillColor();
                let drawColor = doc.getDrawColor();
                doc.setDrawColor(0, 0, 0);
                doc.setFillColor(tableDataMapped[data.row.index].colorCode);
                doc.rect(xPos + 5, yPos + startYColor, 9, 9, "F");
                doc.setFillColor(fillColor);
                doc.setDrawColor(drawColor);
              }
            }
          },
        });
        tableEndYPos += 10;
        if (option.workOrderNotes) {
          doc.setFont("LatoRegular", "bold");
          doc.text("Notes:", horizontalMargin + 25, tableEndYPos);
          doc.setFont("LatoRegular", "normal");
          const lines = option.workOrderNotes.split("\n");
          for (const line of lines) {
            const words = line.split(" ");
            let currentX = horizontalMargin + 81;
            let currentY = tableEndYPos;
            const lineHeight = 8; // You may need to adjust this

            words.forEach((word: string) => {
              if (word.startsWith("[") && word.endsWith("]")) {
                doc.setTextColor("#fdb913");
                // It's a link
                let url = word.slice(1, -1);
                if (!url.startsWith("http://") && !url.startsWith("https://")) {
                  url = "http://" + url;
                }
                doc.textWithLink(url, currentX, currentY, { url: url });
                currentX += doc.getStringUnitWidth(url) * 9; // Advance the x coordinate
              } else {
                // It's just text
                doc.setTextColor("black");
                doc.text(word, currentX, currentY);
                currentX += doc.getStringUnitWidth(word) * 9; // Advance the x coordinate
              }

              // If the text would go off the right side of the page, wrap to the next line
              if (currentX > doc.internal.pageSize.width - 20) {
                // 20 is the right margin
                currentX = horizontalMargin + 81;
                currentY += lineHeight;
              }
            });
            tableEndYPos = currentY + lineHeight;
          }
        }
        tableEndYPos += 50;
      }
    }
    doc.save("Work Order - Paint.pdf");
    let blob = doc.output();
    return blob;
  };

  const generateWorkOrderCarpentryPdf = async () => {
    const horizontalMargin = 30;
    const columns = [
      { header: "Carpentry Item", dataKey: "item" },
      { header: "Quantity", dataKey: "count" },
    ];
    const carpentryWorkOrder = items[currentProject]?.carpentryWorkOrder;
    const filteredWorkOrder = carpentryWorkOrder.filter(
      (option: any) => option.carpentryLabor > 0
    );
    if (filteredWorkOrder.length > 0) {
      var doc = new jsPDF("p", "pt");

      doc.addFileToVFS("Lato-Regular.ttf", LatoRegular);
      doc.addFont("Lato-Regular.ttf", "LatoRegular", "normal");
      doc.addFileToVFS("Lato-Bold.ttf", LatoBold);
      doc.addFont("Lato-Bold.ttf", "LatoRegular", "bold");

      let image = new Image();
      image.src = adminDefaults?.companyInfo.companyLogo;
      const imageAspectRatio = image.naturalWidth / image.naturalHeight;
      const opWidth = 200;
      doc.addImage(
        image,
        "png",
        365,
        25,
        opWidth,
        opWidth / imageAspectRatio,
        "logo",
        "FAST"
      );

      doc.setFontSize(30);
      doc.text("WORK ORDER", horizontalMargin, 60);
      doc.setFontSize(16);
      doc.setTextColor("#585858");
      doc.text("CARPENTRY", horizontalMargin, 80);

      doc.setTextColor("black");

      let tableEndYPos = 150;
      for (const option of filteredWorkOrder) {
        doc.setFont("LatoRegular", "bold");
        doc.setFontSize(16);
        doc.text(option?.buildingName, horizontalMargin, tableEndYPos);
        tableEndYPos += 4;
        image.src = lineGradient;
        doc.addImage(image, "png", horizontalMargin, tableEndYPos, 500, 10);
        tableEndYPos += 12;
        doc.setFont("LatoRegular", "normal");
        doc.setFontSize(8);

        // doc.text("Building Name", horizontalMargin, tableEndYPos);
        // doc.text(option?.buildingName, 150 + horizontalMargin, tableEndYPos);
        doc.text("Carpentry Hours:", horizontalMargin, tableEndYPos);
        doc.text(
          option?.carpentryHours.toFixed(2),
          150 + horizontalMargin,
          tableEndYPos
        );
        doc.text("Carpentry Labor:", 300 + horizontalMargin, tableEndYPos);
        doc.text(
          "$" +
          option?.carpentryLabor.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          450 + horizontalMargin,
          tableEndYPos
        );

        tableEndYPos += 10;

        const rows = option?.carpentryRateId;
        const filteredZeroQty = rows.filter((item: any) => item.count > 0);

        autoTable(doc, {
          body: filteredZeroQty,
          columns: columns,
          startY: tableEndYPos,
          margin: { horizontal: horizontalMargin },
          styles: {
            lineColor: "#585858",
            lineWidth: 1,
          },
          headStyles: {
            fillColor: "#FDB913",
            fontSize: 8,
            halign: "center",
            textColor: "black",
          },
          bodyStyles: { fillColor: "#FAF9F9", fontSize: 8, textColor: "black" },
          columnStyles: {
            0: {
              cellWidth: 400,
            },
            1: {
              halign: "center",
              cellWidth: 50,
            },
          },
          // eslint-disable-next-line no-loop-func
          didDrawPage: (data) => {
            tableEndYPos = data.cursor?.y || tableEndYPos;
            // tableEndYPos = data.table.finalY || 0;
          },
          didDrawCell: (data) => {
            if (data.row.section !== "head") {
              if (data.row.index % 2 === 0) {
                data.cell.styles.fillColor = "#E2E2E2";
              } else {
                data.cell.styles.fillColor = "#FFFFFF";
              }
            }
          },
        });
        tableEndYPos += 10;
        if (option.workOrderNotes) {
          doc.setFont("LatoRegular", "bold");
          doc.text("Notes:", horizontalMargin + 25, tableEndYPos);
          doc.setFont("LatoRegular", "normal");
          const lines = option.workOrderNotes.split("\n");
          for (const line of lines) {
            const words = line.split(" ");
            let currentX = horizontalMargin + 81;
            let currentY = tableEndYPos;
            const lineHeight = 8; // You may need to adjust this

            words.forEach((word: string) => {
              if (word.startsWith("[") && word.endsWith("]")) {
                doc.setTextColor("#fdb913");
                // It's a link
                let url = word.slice(1, -1);
                if (!url.startsWith("http://") && !url.startsWith("https://")) {
                  url = "http://" + url;
                }
                doc.textWithLink(url, currentX, currentY, { url: url });
                currentX += doc.getStringUnitWidth(url) * 9; // Advance the x coordinate
              } else {
                // It's just text
                doc.setTextColor("black");
                doc.text(word, currentX, currentY);
                currentX += doc.getStringUnitWidth(word) * 9; // Advance the x coordinate
              }

              // If the text would go off the right side of the page, wrap to the next line
              if (currentX > doc.internal.pageSize.width - 20) {
                // 20 is the right margin
                currentX = horizontalMargin + 81;
                currentY += lineHeight;
              }
            });
            tableEndYPos = currentY + lineHeight;
          }
        }
        tableEndYPos += 50;
      }
      doc.save("Work Order - Carpentry.pdf");
      let blob = doc.output();
      return blob;
    }
  };

  const generateCarpentryMaterialsPdf = async () => {
    const horizontalMargin = 30;
    const columns = [
      { header: "Line Item", dataKey: "product" },
      { header: "Total LF/ SF", dataKey: "totalLfOrSf" },
    ];

    const rows = items[currentProject]?.woodOrder?.productInfo;
    const filteredZeroQty = rows?.filter((item: any) => item.totalLfOrSf > 0);
    if (filteredZeroQty?.length > 0) {
      var doc = new jsPDF("p", "pt");
      doc.setFontSize(30);

      doc.text("Carpentry Materials", horizontalMargin, 60);

      let image = new Image();
      image.src = adminDefaults?.companyInfo.companyLogo;
      const imageAspectRatio = image.naturalWidth / image.naturalHeight;
      const opWidth = 200;
      doc.addImage(
        image,
        "png",
        365,
        25,
        opWidth,
        opWidth / imageAspectRatio,
        "logo",
        "FAST"
      );
      let tableEndYPos = 150;
      autoTable(doc, {
        body: filteredZeroQty,
        columns: columns,
        startY: tableEndYPos,
        margin: { horizontal: horizontalMargin },
        styles: {
          lineColor: "#585858",
          lineWidth: 1,
        },
        headStyles: {
          fillColor: "#FDB913",
          fontSize: 8,
          halign: "center",
          textColor: "black",
        },
        bodyStyles: { fillColor: "#FAF9F9", fontSize: 8, textColor: "black" },
        columnStyles: {
          1: {
            halign: "center",
            cellWidth: 60,
          },
        },
        // eslint-disable-next-line no-loop-func
        didDrawPage: (data) => {
          tableEndYPos = data.cursor?.y || tableEndYPos;
          // tableEndYPos = data.table.finalY || 0;
        },
      });
      tableEndYPos += 30;
      doc.save("Carpentry Materials.pdf");
      let blob = doc.output();
      return blob;
    }
  };

  const generatePaintMaterialsPdf = async () => {
    const horizontalMargin = 30;
    const columnsForTableOne = [
      { header: "Product", dataKey: "product" },
      { header: "Color", dataKey: "color" },
      { header: "Gallons", dataKey: "paintGallons" },
    ];
    const columnsForTableTwo = [
      { header: "Primer", dataKey: "primer" },
      { header: "Gallons", dataKey: "primeGallons" },
    ];

    var doc = new jsPDF("p", "pt");
    doc.setFontSize(30);

    doc.text("Paint Materials", horizontalMargin, 60);

    let image = new Image();
    image.src = adminDefaults?.companyInfo.companyLogo;
    const imageAspectRatio = image.naturalWidth / image.naturalHeight;
    const opWidth = 200;
    doc.addImage(
      image,
      "png",
      365,
      25,
      opWidth,
      opWidth / imageAspectRatio,
      "logo",
      "FAST"
    );
    let tableEndYPos = 150;

    doc.setFontSize(8);

    doc.text("Account Number:", horizontalMargin, tableEndYPos);
    doc.text(
      `${items[currentProject]?.paintOrder?.accountNumber}`,
      150 + horizontalMargin,
      tableEndYPos
    );
    doc.text("Store Phone:", 300 + horizontalMargin, tableEndYPos);
    doc.text(
      `${closestStoreData?.phone ? closestStoreData.phone : ""}`,
      450 + horizontalMargin,
      tableEndYPos
    );
    tableEndYPos += 12;

    doc.text("PO:", horizontalMargin, tableEndYPos);
    doc.text(
      `${items[currentProject]?.projectInfo?.jobNumber}`,
      150 + horizontalMargin,
      tableEndYPos
    );
    doc.text("PRC:", 300 + horizontalMargin, tableEndYPos);
    if (items[currentProject]?.prePresentationChecklist?.activePrcDescription) {
    }
    doc.text(
      items[currentProject]?.prePresentationChecklist?.activePrcDescription
        ? items[
          currentProject
        ]?.prePresentationChecklist?.activePrcDescription.toString()
        : "",
      450 + horizontalMargin,
      tableEndYPos
    );
    tableEndYPos += 15;

    const splitJobSiteAddress = doc.splitTextToSize(
      items[currentProject]?.paintOrder?.jobSiteAddress,
      350
    );

    doc.text("Delivery Address:", horizontalMargin, tableEndYPos);
    doc.text(splitJobSiteAddress, 150 + horizontalMargin, tableEndYPos);
    tableEndYPos += 12 * splitJobSiteAddress.length;

    doc.text("Closest Store Address:", horizontalMargin, tableEndYPos);
    doc.text(
      `${closestStoreData?.address ? closestStoreData?.address : ""}`,
      150 + horizontalMargin,
      tableEndYPos
    );
    tableEndYPos += 12;

    doc.text("Store Number", horizontalMargin, tableEndYPos);
    doc.text(
      `${closestStoreData?.number !== null ? closestStoreData.number : "N/A"}`,
      150 + horizontalMargin,
      tableEndYPos
    );
    tableEndYPos += 12;

    const rowsForTableOne = items[currentProject]?.paintOrder?.paintInfo;
    const paintTableMapped = rowsForTableOne?.map((row: any) => {
      let colorObj = adminDefaults.colors.find(
        (item: any) => item.name === row.color
      );
      return {
        ...row,
        color: "      " + row.color,
        colorCode: colorObj?.hexCode,
      };
    });
    const rowsForTableTwo = items[
      currentProject
    ]?.paintOrder?.primerInfo?.filter((item: any) => item.primeGallons > 0);
    if (rowsForTableTwo?.length > 0) {
      autoTable(doc, {
        body: rowsForTableTwo,
        columns: columnsForTableTwo,
        startY: tableEndYPos,
        margin: { horizontal: horizontalMargin },
        // margin: { horizontal: 10 },
        styles: {
          lineColor: "#585858",
          lineWidth: 1,
        },
        headStyles: {
          fillColor: "#FDB913",
          halign: "center",
          textColor: "black",
          fontSize: 8,
        },
        bodyStyles: { fillColor: "#FAF9F9", fontSize: 8 },
        columnStyles: {
          1: {
            halign: "center",
            cellWidth: 50,
          },
        },
        // eslint-disable-next-line no-loop-func
        didDrawPage: (data) => {
          tableEndYPos = data.cursor?.y || tableEndYPos;
          // tableEndYPos = data.table.finalY || 0;
        },
      });

      tableEndYPos += 20;
    }

    autoTable(doc, {
      body: paintTableMapped,
      columns: columnsForTableOne,
      startY: tableEndYPos,
      margin: { horizontal: horizontalMargin },
      // margin: { horizontal: 10 },
      styles: {
        lineColor: "#585858",
        lineWidth: 1,
        textColor: "black",
      },
      headStyles: {
        fillColor: "#FDB913",
        halign: "center",
        textColor: "black",
        fontSize: 8,
      },
      bodyStyles: { fillColor: "#FAF9F9", fontSize: 8 },
      columnStyles: {
        2: {
          halign: "center",
          cellWidth: 50,
        },
      },
      didDrawPage: (data) => {
        tableEndYPos = data.cursor?.y || tableEndYPos;
        // tableEndYPos = data.table.finalY || 0;
      },
      didDrawCell: (data) => {
        if (data.row.section !== "head") {
          if (data.row.index % 2 === 0) {
            data.cell.styles.fillColor = "#E2E2E2";
          } else {
            data.cell.styles.fillColor = "#FFFFFF";
          }
          let xPos = data.cursor ? data.cursor.x : 0;
          let yPos = data.cursor ? data.cursor.y : 0;
          let startYColor = 5;
          if (paintTableMapped[data.row.index].lines >= 2) {
            startYColor = 14;
          }

          if (
            data.column.dataKey === "color" &&
            paintTableMapped[data.row.index].colorCode
          ) {
            let fillColor = doc.getFillColor();
            let drawColor = doc.getDrawColor();
            doc.setDrawColor(0, 0, 0);
            doc.setFillColor(paintTableMapped[data.row.index].colorCode);
            doc.rect(xPos + 5, yPos + startYColor, 9, 9, "F");
            doc.setFillColor(fillColor);
            doc.setDrawColor(drawColor);
          }
        }
      },
    });

    tableEndYPos += 30;
    doc.save("Paint Materials List.pdf");
    let blob = doc.output();
    return blob;
  };

  const generateJobJacketPDFs = async () => {
    const horizontalMargin = 30;
    const regTextFontSize = 8;

    let image = new Image(200, 40);
    image.src = adminDefaults?.companyInfo.companyLogo;
    const imageAspectRatio = image.naturalWidth / image.naturalHeight;

    let contractEndPosition = 100;
    var contractDoc = new jsPDF("p", "pt");

    contractDoc.addFileToVFS("Lato-Regular.ttf", LatoRegular);
    contractDoc.addFont("Lato-Regular.ttf", "LatoRegular", "normal");
    contractDoc.addFileToVFS("Lato-Bold.ttf", LatoBold);
    contractDoc.addFont("Lato-Bold.ttf", "LatoRegular", "bold");

    contractDoc.setFont("LatoRegular", "normal");
    contractDoc.setFontSize(20);

    const opWidth = 160;
    contractDoc.addImage(
      image,
      "png",
      horizontalMargin - 8,
      10,
      opWidth,
      opWidth / imageAspectRatio,
      "logo",
      "FAST"
    );
    contractDoc.setFont("LatoRegular", "bold");
    contractDoc.text("Contract", horizontalMargin, contractEndPosition);
    contractDoc.setFont("LatoRegular", "normal");
    contractDoc.setFontSize(regTextFontSize);
    contractDoc.text(
      `${adminDefaults?.companyInfo?.companyName}`,
      horizontalMargin + 390,
      40
    );
    contractDoc.text(
      "Address: 8105 Center Run Drive.",
      horizontalMargin + 390,
      50
    );
    contractDoc.text("Telephone: (317) 968-9565", horizontalMargin + 390, 60);
    contractDoc.text(
      `Website: ${adminDefaults?.companyInfo?.companyWebsite}`,
      horizontalMargin + 390,
      70
    );
    let currentDrawColor = contractDoc.getDrawColor();
    contractDoc.setDrawColor("#909090");
    contractDoc.line(0, 120, 600, 120);
    contractDoc.setDrawColor(currentDrawColor);
    contractDoc.setFontSize(14);

    contractEndPosition += 80;
    contractDoc.setFont("LatoRegular", "bold");
    contractDoc.text("Description", horizontalMargin, contractEndPosition);
    contractDoc.setFontSize(regTextFontSize);
    contractDoc.setFont("LatoRegular", "normal");
    const description = `This Contract including the attached Attachment 1 (collectively, the "Work Order") is executed as of the ${moment(
      items[currentProject]?.prePresentationChecklist?.projectStartDate
    ).format("DD MMM,YYYY")} by and between '${adminDefaults?.companyInfo?.companyName
      }' LLC d/b/a CertaPro Painters("${adminDefaults?.companyInfo?.companyName
      }") and ${items[currentProject]?.prePresentationChecklist?.crew?.fullName
        ? items[currentProject]?.prePresentationChecklist?.crew?.fullName
        : "______________"
      } ("Subcontractor"). ${adminDefaults?.companyInfo?.companyName
      }' and Subcontractor agree that all of the work authorized by this Work Order shall be subject to the terms and conditions set forth within the Master Subcontract Agreement between ${adminDefaults?.companyInfo?.companyName
      }' and Subcontractor dated ${moment(
        items[currentProject]?.prePresentationChecklist?.jobCompleteDate
      ).format(
        "DD MMM,YYYY"
      )} (the "Master Subcontract Agreement"). Upon execution of this Work Order, the Master Subcontract Agreement shall be incorporated into and be considered a part of this Work Order as if set forth herein in its entirety. Any capitalized terms which are not defined herein shall have the meanings defined in the Master Subcontract Agreement.)`;

    const splitDescription = contractDoc.splitTextToSize(description, 530);

    contractEndPosition += 20;
    contractDoc.text(splitDescription, horizontalMargin, contractEndPosition);

    const projectImage = new Image();
    projectImage.src = contractProject;
    contractEndPosition += 90;
    contractDoc.addImage(
      projectImage,
      "jpg",
      horizontalMargin,
      contractEndPosition,
      14,
      14
    );
    contractDoc.setFontSize(14);

    contractEndPosition += 11;
    contractDoc.setFont("LatoRegular", "bold");
    contractDoc.text("Project", horizontalMargin + 20, contractEndPosition);
    contractDoc.setFont("LatoRegular", "normal");
    contractDoc.setFontSize(regTextFontSize);
    contractEndPosition += 20;
    contractDoc.text(
      `The Project which is covered by this Work Order is described as:   ${items[currentProject].projectInfo?.jobName}`,
      horizontalMargin,
      contractEndPosition
    );

    const scopeImage = new Image();
    scopeImage.src = scope;
    contractEndPosition += 30;
    contractDoc.addImage(
      scopeImage,
      "jpg",
      horizontalMargin,
      contractEndPosition,
      14,
      14
    );
    contractDoc.setFontSize(14);

    contractEndPosition += 10;
    contractDoc.setFont("LatoRegular", "bold");
    contractDoc.text(
      "Subcontractor's Scope of Work",
      horizontalMargin + 20,
      contractEndPosition
    );
    contractDoc.setFont("LatoRegular", "normal");
    contractDoc.setFontSize(regTextFontSize);
    contractEndPosition += 20;
    contractDoc.text(
      "The Work to be performed by Subcontractor under this Work Order includes all of the work indicated in the attached Attachment 1.",
      horizontalMargin,
      contractEndPosition
    );

    const compensationImage = new Image();
    compensationImage.src = money;
    contractEndPosition += 30;
    contractDoc.addImage(
      compensationImage,
      "jpg",
      horizontalMargin,
      contractEndPosition,
      14,
      14
    );
    contractDoc.setFontSize(14);
    contractDoc.setFont("LatoRegular", "bold");
    contractEndPosition += 10;
    contractDoc.text(
      "Compensation",
      horizontalMargin + 20,
      contractEndPosition
    );
    contractDoc.setFont("LatoRegular", "normal");
    contractDoc.setFontSize(regTextFontSize);
    contractEndPosition += 20;
    contractDoc.text(
      "Subcontractor shall be paid for satisfactory performance of the Work the total lump sum amount shown below.",
      horizontalMargin,
      contractEndPosition
    );
    let compensationTableData = [];
    if (compensation) {
      compensationTableData = compensation?.map((row: any) => {
        if (!row.productionTargets) {
          return {
            ...row,
            productionTargets: 0,
          };
        }
        return row;
      });
    }
    let tableBody = [
      ...compensationTableData,
      {
        name: "Total Contract Price",
        productionTargets: totalSum ? totalSum : 0,
      },
    ];
    const compensationColumns = [
      { header: "Description", dataKey: "name" },
      { header: "Amount", dataKey: "productionTargets" },
    ];
    contractEndPosition += 20;
    autoTable(contractDoc, {
      body: tableBody,
      columns: compensationColumns,
      startY: contractEndPosition,
      margin: { horizontal: horizontalMargin },
      styles: {
        lineColor: "#585858",
        lineWidth: 1,
      },
      headStyles: {
        fillColor: "#FDB913",
        halign: "center",
        textColor: "black",
      },

      bodyStyles: { fillColor: "#FAF9F9", fontSize: 8, textColor: "black" },
      columnStyles: {
        1: {
          halign: "right",
        },
      },

      didDrawPage: (data) => {
        contractEndPosition = data.cursor?.y || contractEndPosition;
      },
      didParseCell: (data) => {
        var rows = data.table.body;
        if (data.row.index === rows.length - 1) {
          data.cell.styles.fontStyle = "bold";
        }
      },
    });
    contractEndPosition += 20;
    const compensationDescription =
      "Retainage shall be fifteen percent (15%), invoice frequency shall be on a weekly basis and payment terms shall be seven (7) days from receipt of invoice with the following exception(s) (if any)";
    const splitCompensation = contractDoc.splitTextToSize(
      compensationDescription,
      520
    );

    contractDoc.text(splitCompensation, horizontalMargin, contractEndPosition);
    const dotImage = new Image();
    dotImage.src = dot;
    if (
      items[currentProject]?.prePresentationChecklist?.paymentTermsException
        ?.length
    ) {
      contractEndPosition += 20;

      contractDoc.addImage(
        dotImage,
        "jpg",
        horizontalMargin,
        contractEndPosition,
        4,
        4
      );
      contractEndPosition += 5;
      contractDoc.text(
        `${items[currentProject]?.prePresentationChecklist?.paymentTermsException}`,
        horizontalMargin + 10,
        contractEndPosition
      );
    }

    if (items[currentProject]?.prePresentationChecklist?.showEquipment) {
      contractEndPosition += 40;
      const equipmentImage = new Image();
      equipmentImage.src = equipment;

      contractDoc.addImage(
        equipmentImage,
        "jpg",
        horizontalMargin,
        contractEndPosition,
        14,
        14
      );
      contractDoc.setFontSize(14);

      contractEndPosition += 12;
      contractDoc.setFont("LatoRegular", "bold");
      contractDoc.text("Equipment", horizontalMargin + 20, contractEndPosition);
      contractDoc.setFontSize(regTextFontSize);
      contractDoc.setFont("LatoRegular", "normal");
      contractEndPosition += 20;
      contractDoc.text(
        "Subcontractor will be provided with the equipment listed below for the time periods shown below",
        horizontalMargin,
        contractEndPosition
      );
      contractEndPosition += 20;
      const equipmentDescription = `${adminDefaults?.companyInfo?.companyName} LLC will pay for the associated rental fees for said equipment up to the time limits indicated. Should the equipment rental need to be extended due to the Subcontractor's negligence, excessive rental pricing may be deducted from the Subcontractor's payment.`;
      const splitEquipment = contractDoc.splitTextToSize(
        equipmentDescription,
        520
      );
      contractDoc.text(splitEquipment, horizontalMargin, contractEndPosition);

      contractEndPosition += 20;
      equipmentNames.map((item: any) => {
        contractDoc.addImage(
          dotImage,
          "jpg",
          horizontalMargin + 10,
          contractEndPosition + 15,
          4,
          4
        );
        contractEndPosition += 20;
        contractDoc.setFont("LatoRegular", "normal");
        contractDoc.text(
          `${item.item}`,
          horizontalMargin + 20,
          contractEndPosition
        );
      });
    }

    const correctionImage = new Image();
    correctionImage.src = correction;
    contractEndPosition += 30;
    contractDoc.addImage(
      correctionImage,
      "jpg",
      horizontalMargin,
      contractEndPosition,
      14,
      14
    );
    contractDoc.setFontSize(14);

    contractEndPosition += 10;
    contractDoc.setFont("LatoRegular", "bold");
    contractDoc.text(
      "Correction Period",
      horizontalMargin + 20,
      contractEndPosition
    );
    contractDoc.setFont("LatoRegular", "normal");
    contractDoc.setFontSize(regTextFontSize);
    contractEndPosition += 20;
    contractDoc.text(
      "The Correction Period shall be one (1) year from completion of the Work unless such longer period is provided in the following field.",
      horizontalMargin,
      contractEndPosition
    );

    if (
      items[currentProject]?.prePresentationChecklist?.correctionPeriod?.length
    ) {
      contractEndPosition += 10;

      contractDoc.addImage(
        dotImage,
        "jpg",
        horizontalMargin,
        contractEndPosition,
        4,
        4
      );
      contractEndPosition += 5;
      contractDoc.text(
        `${items[currentProject]?.prePresentationChecklist?.correctionPeriod}`,
        horizontalMargin + 10,
        contractEndPosition
      );
    }
    if (contractEndPosition >= 800) {
      contractDoc.addPage();
    }

    const calendarImage = new Image();
    calendarImage.src = calendar;
    if (contractEndPosition > 800) {
      contractEndPosition = 50;
    } else {
      contractEndPosition += 20;
    }

    if (contractEndPosition >= 800) {
      contractDoc.addPage();
      contractEndPosition = 50;
    } else {
      contractEndPosition += 12;
    }

    contractDoc.addImage(
      calendarImage,
      "jpg",
      horizontalMargin,
      contractEndPosition - 12,
      14,
      14
    );
    contractDoc.setFontSize(14);

    contractDoc.setFont("LatoRegular", "bold");
    contractDoc.text(
      "Start/Completion Date",
      horizontalMargin + 20,
      contractEndPosition
    );
    contractDoc.setFont("LatoRegular", "normal");
    contractDoc.setFontSize(regTextFontSize);
    contractEndPosition += 20;
    contractDoc.text(
      "Start Date:    Subcontractor shall start the Project on:",
      horizontalMargin,
      contractEndPosition
    );
    contractDoc.text(
      items[currentProject].prePresentationChecklist?.projectStartDate
        ? `${moment(
          items[currentProject].prePresentationChecklist?.projectStartDate
        ).format("MMM DD,YYYY")}`
        : "",
      horizontalMargin + 350,
      contractEndPosition
    );
    contractDoc.line(
      380,
      contractEndPosition + 2,
      425,
      contractEndPosition + 2
    );
    contractEndPosition += 20;
    contractDoc.text(
      "Completion Date:   Subcontractor shall complete the entire Project by no later than:",
      horizontalMargin,
      contractEndPosition
    );
    contractDoc.text(
      items[currentProject].prePresentationChecklist?.jobCompleteDate
        ? `${moment(
          items[currentProject].prePresentationChecklist?.jobCompleteDate
        ).format("MMM DD,YYYY")}`
        : "",
      horizontalMargin + 350,
      contractEndPosition
    );
    if (contractEndPosition >= 800) {
      contractDoc.addPage();
      contractEndPosition = 50;
    }
    contractDoc.line(
      380,
      contractEndPosition + 2,
      425,
      contractEndPosition + 2
    );

    contractEndPosition += 30;
    if (contractEndPosition >= 800) {
      contractDoc.addPage();
      contractEndPosition = 50;
    }
    contractDoc.line(0, contractEndPosition, 600, contractEndPosition);
    contractDoc.setFont("LatoRegular", "bold");
    contractDoc.setFontSize(12);
    contractEndPosition += 50;
    contractDoc.text(
      "IN WITNESS WHEREOF, the Parties hereto have executed this Work Order",
      horizontalMargin + 70,
      contractEndPosition
    );
    contractEndPosition += 15;
    contractDoc.text(
      "to be effective upon its full execution.",
      horizontalMargin + 160,
      contractEndPosition
    );

    contractEndPosition += 30;
    contractDoc.text(
      `${adminDefaults?.companyInfo?.companyName} LLC:`,
      horizontalMargin,
      contractEndPosition
    );

    contractDoc.text(
      "Subcontractor:",
      horizontalMargin + 400,
      contractEndPosition
    );

    contractEndPosition += 30;
    contractDoc.setFont("LatoRegular", "normal");
    contractDoc.setFontSize(regTextFontSize);
    contractDoc.line(
      horizontalMargin,
      contractEndPosition,
      130,
      contractEndPosition
    );
    contractDoc.line(
      horizontalMargin + 400,
      contractEndPosition,
      530,
      contractEndPosition
    );

    contractEndPosition += 10;

    contractDoc.text("Signature", horizontalMargin, contractEndPosition);
    contractDoc.text("Signature", horizontalMargin + 400, contractEndPosition);
    contractEndPosition += 30;
    contractDoc.line(
      horizontalMargin,
      contractEndPosition,
      130,
      contractEndPosition
    );
    contractDoc.line(
      horizontalMargin + 400,
      contractEndPosition,
      530,
      contractEndPosition
    );

    contractEndPosition += 10;

    contractDoc.text("Printed", horizontalMargin, contractEndPosition);
    contractDoc.text("Printed", horizontalMargin + 400, contractEndPosition);

    contractEndPosition += 30;
    contractDoc.line(
      horizontalMargin,
      contractEndPosition,
      130,
      contractEndPosition
    );
    contractDoc.line(
      horizontalMargin + 400,
      contractEndPosition,
      530,
      contractEndPosition
    );

    contractEndPosition += 10;

    contractDoc.text("Title", horizontalMargin, contractEndPosition);
    contractDoc.text("Title", horizontalMargin + 400, contractEndPosition);
    contractDoc.save("Contract");
    let blob = contractDoc.output();
    return blob;
  };

  const interpolateDescription = (description: string, values: any) => {
    return description.replace(
      /\{(.*?)\}/g,
      (match, key) => values[key.trim()] || match
    );
  };

  const handleGenerateJJ = async () => {
    setLoading(true);
    const updatedDescription = adminDefaults.contractDefaults
      .filter(
        (singleContractDefault: any) => singleContractDefault.addToProjects
      ) // First, filter the array
      .sort((a: any, b: any) => a.position - b.position) // Then sort it
      .map((singleContractDefault: any) => {
        // Now, map over the filtered and sorted array to update descriptions
        const interpolatedDescription = interpolateDescription(
          singleContractDefault.description,
          values
        );
        return {
          ...singleContractDefault,
          description: interpolatedDescription,
        };
      });
    let contractBlob = await contractPdf(
      adminDefaults.companyInfo,
      updatedDescription,
      items[currentProject],
      compensation,
      totalSum,
      equipmentNames
    );
    // let contractBlob = await generateJobJacketPDFs();
    let workOrderPaintBlob = await generateWorkOrderPaintPdf();
    let workOrderCarpentryBlob = await generateWorkOrderCarpentryPdf();
    let woodOrderBlob = await generateCarpentryMaterialsPdf();
    let paintOrderBlob = await generatePaintMaterialsPdf();

    let project = _.cloneDeep(items[currentProject]);
    let defaults = _.cloneDeep(adminDefaults);
    let companyInfo = _.cloneDeep(adminDefaults.companyInfo);
    const result = (
      await HTMLParser(
        "<div>" +
        items[currentProject]?.projectInfo?.proposal?.projectSummary +
        "</div>",
        true
      )
    ).toString();

    const parsedHtml = JSON.parse(result);
    let proposalCBlob = await generateProposalCPdf(
      project,
      defaults,
      companyInfo,
      "save",
      profilePhoto,
      parsedHtml,
      optionsMediaList
    );

    let body = {
      contractBlob,
      workOrderPaintBlob,
      workOrderCarpentryBlob,
      woodOrderBlob,
      paintOrderBlob,
      proposalCBlob,
      projectName: items[currentProject].projectInfo.jobName,
    };

    api.post(`upload-files/pdf/${id}`, body);

    setLoading(false);
  };

  return (
    <>
      {isLoading === true ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <Content>
          <JobJacketSteps current={5} />
          <Row style={{ marginBottom: 30 }} gutter={8} justify="end">
            <Col>
              <Button
                onClick={handleGenerateJJ}
                loading={loading}
                disabled={!online}
                className="notify-btn"
                style={{
                  color: "#FDB913",
                  border: "1px solid #fdb913",
                }}
              >
                Export
              </Button>
            </Col>

            {/* <Col>
          <Button
            style={{ color: "#1f1f1f" }}
            type="primary"
            className="notify-btn"
          >
            Send for Signing
          </Button>
        </Col> */}
          </Row>
          <div ref={componentRef} className="steps-content">
            <Row gutter={24}>
              <Col className="gutter-row" span={24}>
                <JobJacketContract />
              </Col>
            </Row>
          </div>
          <Row justify="center" style={{ marginBottom: "20px" }}>
            <div>
              <Button
                type="primary"
                className="save-button"
                onClick={() => next()}
              >
                Next
              </Button>
            </div>
          </Row>
        </Content>
      )}
    </>
  );
};

export default Contract;
