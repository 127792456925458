import React from "react";
import { Table, Button, Spin } from "antd";
import { columns } from "./columns";
import "../WorkOrderPaintC.less";
import { RootStateOrAny, useSelector } from "react-redux";

type Props = {};

const WoodOrderC = (props: Props) => {
  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  return (
    <div>
      <div className="wo-paint-root">
        <h1 className="wop-title wop-header">Carpentry Material</h1>

        <hr style={{ border: "1px solid #E8E8E8", marginBottom: "40px" }} />
        <div className="wo-paint-table">
          <Table
            rowKey={(_: any, index: any) => index.toString()}
            pagination={false}
            rowClassName={(record, index) =>
              index % 2 === 0
                ? "table_row table-row-light"
                : "table_row table-row-dark"
            }
            columns={columns}
            dataSource={items[currentProject].woodOrder.productInfo}
          />
        </div>
      </div>
    </div>
  );
};

export default WoodOrderC;
