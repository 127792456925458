import React from "react";
import InspectionOfFailure from "./checklists/InspectionOfFailure";
import OtherChecklists from "./checklists/OtherChecklists";
import SourceLocation from "./checklists/SourceLocation";
import "./OnSiteDefault.less";

type Props = {};

const OnSiteDefault = (props: Props) => {
  return (
    <div>
      <h1 className="onsite-default-title">On- site Checklists Defaults</h1>

      <OtherChecklists />
    </div>
  );
};

export default OnSiteDefault;
