import React from "react";
import StoreLocationsC from "../../containers/admin-store-loactions/StoreLocationsC";

type Props = {};

const StoreLocations = (props: Props) => {
  return (
    <div>
      <StoreLocationsC />
    </div>
  );
};

export default StoreLocations;
