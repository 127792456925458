import { Col, Image, Row, Switch, Table, Tooltip } from "antd";
import {
  UpSquareFilled,
  DownSquareFilled,
  EditFilled,
} from "@ant-design/icons";
import "./ContractDefaultCard.less";
import { BulletIcon } from "../../utils/icons";

type Props = {
  data: {
    _id: string;
    svgString: string;
    title: string;
    description: string;
    startAndCompletionDate?: {
      startDateFirmString: string;
      startDateFlexibleString: string;
      completionDateFirmString: string;
      completionDateFlexibleString: string;
    };
    position: number;
    addToProjects: boolean;
    isDefault: boolean;
    isEditable: boolean;
  };
  handleOpenEditComponent: (dataId: string) => void;
  handleAddToProjects: (dataId: string, checked: boolean) => void;
  handleContractDefaultPosition: (dataId: string, step: "up" | "down") => void;
};

function ContractDefaultCard({
  data,
  handleOpenEditComponent,
  handleAddToProjects,
  handleContractDefaultPosition,
}: Props) {
  const compensationColumns = [
    {
      title: "Description",
      dataIndex: "name",
      width: "100px",
    },
    {
      title: "Amount",
      dataIndex: "productionTargets",
      render: (data: any) => {
        return (
          <>
            {data && (
              <div>
                $
                {data?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
            )}
          </>
        );
      },
      width: "100px",
    },
  ];

  const defaultDescription = () => {
    switch (data.title) {
      case "Project":
        return (
          <Row gutter={50} align="middle">
            <Col>
              <pre
                className="contract-description"
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{
                  __html: data.description,
                }}
              />
            </Col>
            <Col style={{ marginTop: -20 }}>
              <Row className="contract-default-project-card">
                <h2>{`{Job Name}`}</h2>
              </Row>
            </Col>
          </Row>
        );
      case "Compensation":
        return (
          <div>
            <p className="contract-description">
              Subcontractor shall be paid for satisfactory performance of the
              Work the total lump sum amount shown below.
            </p>
            <div
              style={{ padding: "0px 25px 20px 30px" }}
              className="compensation-table"
            >
              <Table
                rowKey={(_, index: any) => index.toString()}
                rowClassName={(_, index) =>
                  index % 2 === 0
                    ? "table_row table-row-light"
                    : "table_row table-row-dark"
                }
                pagination={false}
                columns={compensationColumns}
                dataSource={[]}
              />
            </div>
            <pre
              className="contract-description"
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
            <Row gutter={10} style={{ paddingLeft: 25 }}>
              <Col span={0.1}>
                <BulletIcon />
              </Col>
              <Col span={23}>
                <p>{`{compensation}`}</p>
              </Col>
            </Row>
          </div>
        );
      case "Equipment":
        return (
          <div>
            <pre
              className="contract-description"
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
            <Row gutter={10} style={{ paddingLeft: 25 }}>
              <Col span={0.1}>
                <BulletIcon />
              </Col>
              <Col span={23}>
                <p>{`{equipments}`}</p>
              </Col>
            </Row>
          </div>
        );

      case "Correction Period":
        return (
          <div>
            <pre
              className="contract-description"
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
            <Row gutter={10} style={{ paddingLeft: 25 }}>
              <Col span={0.1}>
                <BulletIcon />
              </Col>
              <Col span={23}>
                <p>{`{correction Period}`}</p>
              </Col>
            </Row>
          </div>
        );

      case "Start/Completion Date":
        return (
          <div>
            <Row
              gutter={[10, 16]}
              align="middle"
              className="contract-description"
            >
              <Col lg={4} xs={8} className="contract-start-completion-p-tag">
                <p>Start Date Firm:</p>
                <p>Start Date Flexible:</p>
              </Col>
              <Col lg={9} xs={12}>
                <p>{data.startAndCompletionDate?.startDateFirmString}</p>
                <p>{data.startAndCompletionDate?.startDateFlexibleString}</p>
              </Col>
              <Col span={2.5} xs={4}>
                {/* <>__________________</>
                <>__________________</> */}
              </Col>
            </Row>
            <Row
              gutter={[10, 16]}
              align="middle"
              className="contract-description"
            >
              <Col lg={4} xs={8} className="contract-start-completion-p-tag">
                <p>Completion Date Firm:</p>
                <p>Completion Date Flexible:</p>
              </Col>
              <Col lg={9} xs={12}>
                <p>{data.startAndCompletionDate?.completionDateFirmString}</p>
                <p>
                  {data.startAndCompletionDate?.completionDateFlexibleString}
                </p>
              </Col>
              <Col span={2.5} xs={4}>
                {/* <>__________________</>
              <>__________________</> */}
              </Col>
            </Row>
          </div>
        );

      default:
        return (
          <pre
            className="contract-description"
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          />
        );
    }
  };

  return (
    <div style={{ padding: "30px 0px 20px 0px" }}>
      <Row justify={"space-between"} style={{ padding: "0px 28px" }}>
        <Col span={9}>
          <Row align="middle">
            {data.svgString && (
              <Col style={{ marginRight: 15 }}>
                <Image
                  src={data.svgString}
                  style={{ paddingTop: "0px", marginTop: "-15px" }}
                />
              </Col>
            )}
            <Col>
              <h1>{data.title}</h1>
            </Col>
          </Row>
        </Col>
        <Col span={5} offset={8}>
          <Row justify="end" align="middle">
            {data.isEditable && (
              <Col>
                <EditFilled
                  onClick={() => handleOpenEditComponent(data._id)}
                  style={{
                    color: "#FDB913",
                    cursor: "pointer",
                    fontSize: "24px",
                  }}
                />
              </Col>
            )}
            <Col style={{ marginLeft: 15 }}>
              <Tooltip title="Add to Projects">
                <Switch
                  defaultChecked={data.addToProjects}
                  onChange={(checked: boolean) =>
                    handleAddToProjects(data._id, checked)
                  }
                />
              </Tooltip>
            </Col>
            <Col style={{ marginLeft: 15 }}>
              <UpSquareFilled
                onClick={() => handleContractDefaultPosition(data._id, "up")}
                style={{
                  color: "#FDB913",
                  cursor: "pointer",
                  fontSize: "24px",
                }}
              />{" "}
              <DownSquareFilled
                onClick={() => handleContractDefaultPosition(data._id, "down")}
                style={{
                  color: "#FDB913",
                  cursor: "pointer",
                  fontSize: "24px",
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {data.isDefault ? (
        defaultDescription()
      ) : (
        <div>
          <pre
            className="contract-description"
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default ContractDefaultCard;
