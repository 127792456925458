import React, { ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Layout, Steps, Spin, Row } from "antd";
import Notes from "../../containers/notes/Notes";
import EstimateChecklistsC from "../../containers/EstimateChecklists/EstimateChecklistsC";
import ManagerSteps from "./ManagerSteps";
import { useDispatch } from "react-redux";
import useActiveProject from "../../utils/useActiveProject";
import useSyncProjectData from "../../utils/useSyncProjectData";
import { useParams } from "react-router-dom";

const { Content } = Layout;
const { Step } = Steps;

const ManagerEstimateChecklist: React.FC = () => {
  useActiveProject();
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);
  const history = useHistory();
  const { id } = useParams<any>();
  const [isLoading] = useSyncProjectData(id);

  return (
    <>
      {isLoading === true ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <Content>
          <ManagerSteps current={5} />
          <div className="steps-content">
            <EstimateChecklistsC path="manager-proposal-setup" />
          </div>
        </Content>
      )}
    </>
  );
};

export default ManagerEstimateChecklist;
