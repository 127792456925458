import React, { useState } from "react";
import { Row, Col, Input, Space, Button } from "antd";
import "./DeliveryNotes.less";
import { BulletIcon, TrashIcon } from "../../utils/icons";
import Search from "antd/lib/transfer/search";
import TextArea from "antd/lib/input/TextArea";
import { useMediaQuery } from "react-responsive";

type Props = {
  title: string;
  notes: any;
  addNotes: (data: any) => void;
  handleDelete?: (index: number) => void;
};

const DeliveryNotes = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:1200px)" });

  const handleNoteChange = (e: any) => {
    props.addNotes(e.target.value);
  };

  return (
    <div className="delivery-notes-root">
      <h1>{props.title}</h1>

      <div style={{ marginTop: "28px" }}>
        {isTabletOrMobile ? (
          <TextArea
            style={{
              height: "190px",
              marginBottom: 5,
              border: "1px solid #E8E8E8",
              borderRadius: "5px",
            }}
            onChange={handleNoteChange}
            rows={4}
            placeholder="Add Notes"
          />
        ) : (
          <TextArea
            style={{
              marginBottom: 5,
              border: "1px solid #E8E8E8",
              borderRadius: "5px",
            }}
            onChange={handleNoteChange}
            rows={4}
            placeholder="Add Notes"
          />
        )}
      </div>
    </div>
  );
};

export default DeliveryNotes;
