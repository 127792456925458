import { Col, Image, Row } from "antd";
import "./ContractSetup.less";

type Props = {
  data: {
    _id: string;
    svgString: string;
    title: string;
    description: string;
    position: number;
    addToProjects: boolean;
    isDefault: boolean;
  };
  jobName: string;
};

function ContractProject({ data, jobName }: Props) {
  return (
    <div>
      <Row>
        <Col>
          <Image
            src={data.svgString}
            style={{
              paddingTop: "0px",
              paddingRight: "18px",
            }}
            preview={false}
          />
        </Col>
        <Col>
          <h1>Project</h1>
        </Col>
      </Row>
      <Row gutter={10} align="middle">
        <Col span={20}>
          <pre
            className="contract-defaults-description"
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          />
        </Col>
        <Col span={4} style={{ marginTop: -25 }}>
          <Row className="contract-default-project-card" >
            <h2>{jobName ?? "JobName"}</h2>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ContractProject;
