import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  LOGIN_LOADING,
  UPDATE_USER,
  TENANT_SELECTED,
} from "./types";

import authService from "../../services/auth.service";

export const updateUser = (payload: any) => ({
  type: UPDATE_USER,
  payload,
});

export const register = (payload: any) => (dispatch: any) => {
  return authService.register(payload).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return Promise.reject(message);
    }
  );
};

export const tenantSelected = (payload: string) => ({
  type: TENANT_SELECTED,
  payload,
});

export const login =
  (fullName: string, password: string) => (dispatch: any) => {
    dispatch({
      type: LOGIN_LOADING,
    });
    return authService.login(fullName, password).then(
      (data) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: { user: data },
        });

        return Promise.resolve();
      },
      (error) => {
        dispatch({
          type: LOGIN_FAIL,
        });
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  };
export const logout = () => (dispatch: any) => {
  authService.logout();
  dispatch({
    type: LOGOUT,
  });
};
