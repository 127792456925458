import React, { useEffect } from "react";
import { Layout, Row, Col, Button } from "antd";
import WorkOrderCarpentryC from "../../containers/work-order/work-order-carpentry/WorkOrderCarpentryC";
import { useHistory, useParams } from "react-router-dom";
import ContractorSteps from "./ContractorSteps";

const { Content } = Layout;

const ContractorWorkOrderCarpentry: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <Content>
      <div>
        <ContractorSteps current={2} />
      </div>

      <div className="steps-content">
        <Row gutter={24}>
          <Col className="gutter-row" span={24}>
            <WorkOrderCarpentryC />
          </Col>
        </Row>
      </div>
    </Content>
  );
};

export default ContractorWorkOrderCarpentry;
