import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import moment from "moment";
import "./NotesCard.less";

const NotesCard: React.FC<{ data: any }> = ({ data }) => {
  const [notesValue, setNotesValue] = useState("");
  useEffect(() => {
    let notesWIthLink = addLinkToText(data.text);
    setNotesValue(notesWIthLink);
  }, []);

  const addLinkToText = (text: string): string => {
    const regex = /\[([^\]]+)\]/g; // Matches anything between square brackets.

    return text.replace(regex, (_: string, group1: string) => {
      let url = group1;

      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "http://" + url;
      }

      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${group1}</a>`;
    });
  };

  let role = "";
  // switch (data.user.roles[0]) {
  //   case 'admin':
  //     role = 'Admin';
  //     break;
  //   case 'sales_associate':
  //     role = 'Sales Associate';
  //     break;
  //   case 'production_associate':
  //     role = 'Production Associate';
  //     break;
  //   case 'estimator':
  //     role = 'Estimator';
  //     break;
  //   case 'contractor':
  //     role = 'Contractor';
  //     break;
  //   case 'manager':
  //     role = 'Manager';
  //     break;
  //   default:
  //     role = '';
  // }

  return (
    <div className="notes-card-root">
      <div className="notes-card-container">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ marginRight: "25px" }}>
              <Avatar size={50} src={data.user.picture} />
            </div>

            <div style={{ marginTop: "12px" }}>
              <h1
                className="notes-name-text"
                style={{ marginBottom: 5, marginRight: 8 }}
              >
                {data.user.fullName}
              </h1>
              <p className="sub-title">{role}</p>
            </div>
          </div>

          <div>
            <h1 style={{ marginTop: "15px" }} className="sub-title">
              {moment(data.createdAt).format("MMMM DD YYYY")}
            </h1>
          </div>
        </div>

        <div>
          <p
            className="card-text"
            style={{
              marginTop: "5px",
              whiteSpace: "pre-line",
              wordWrap: "break-word",
            }}
            dangerouslySetInnerHTML={{ __html: notesValue }}
          />
        </div>
      </div>
    </div>
  );
};

export default NotesCard;
