import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Menu,
  message,
  Modal,
  Row,
  Space,
  Steps,
} from "antd";
import { useHistory, useParams } from "react-router-dom";
import api from "../../utils/api";
import { RootStateOrAny, useSelector } from "react-redux";
import moment from "moment";
import { getAuthUser } from "../../utils/authToken";
import { useMediaQuery } from "react-responsive";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { DownOutlined } from "@ant-design/icons";

const { Step } = Steps;

interface ContractorSteps {
  current: number;
}
const ContractorSteps: React.FC<ContractorSteps> = (props: ContractorSteps) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:1400px)" });
  const isMobile = useMediaQuery({ query: "(max-width:600px)" });

  const [showModal, setShowModal] = useState(false);
  const [projectIndex, setProjectIndex] = useState(-1);
  const user = getAuthUser();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { currentProject, items } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  useEffect(() => {}, []);

  const handleSendMail = () => {
    const project = items.find((item: any) => item.projectId === id);
    const body = {
      userId: user._id,
      userName: user.fullName,
      email: user.email,
      projectId: id,
      projectName: project.projectInfo.jobName,
      productionAssociate: project.projectInfo.productionAssociate,
    };
    api
      .post("pre-presentation-checklist/send-message", body)
      .then((response: any) => {
        console.log(response);
        message.success("Email Sent");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleOk = () => {
    handleSendMail();
    setShowModal(false);
  };

  const handleModal = () => {
    // console.log(items[projectIndex].prePresentationChecklist, id);
    let index = items.findIndex((item: any) => item.projectId === id);
    setProjectIndex(index);
    setShowModal(true);
  };

  const handleStep = (select: number) => {
    switch (select) {
      case 0:
        history.push(`/contractor-proposal/${id}`);
        break;
      case 1:
        history.push(`/contractor-work-order-paint/${id}`);
        break;
      case 2:
        history.push(`/contractor-work-order-carpentry/${id}`);
        break;
      case 3:
        history.push(`/contractor-paint-order/${id}`);
        break;
      case 4:
        history.push(`/contractor-wood-order/${id}`);
        break;
      case 5:
        history.push(`/contractor-pre-view/${id}`);
        break;
      case 6:
        history.push(`/contractor-media/${id}`);
        break;
    }
  };

  const elements = [
    {
      title: "Proposal C",
    },
    {
      title: "Work Order- Paint",
    },
    {
      title: "Work Order- Carpentry",
    },
    {
      title: "Paint Materials",
    },
    {
      title: "Carpentry Materials",
    },
    {
      title: "Contract",
    },
    {
      title: "Media/documents",
    },
  ];

  const menuItems: any = (
    <Menu>
      {elements.map((item: any, index: number) => {
        return (
          <Menu.Item onClick={() => handleStep(index)}>{item.title}</Menu.Item>
        );
      })}
    </Menu>
  );
  const handlePrev = () => {
    const prev = props.current - 1;
    if (prev >= 0) {
      handleStep(prev);
    }
  };
  const handleNext = () => {
    const next = props.current + 1;
    if (next < elements.length) {
      handleStep(next);
    }
  };

  return (
    <>
      <Modal
        title="Apply for job"
        centered
        visible={showModal}
        okText="Yes"
        cancelText="No"
        onOk={handleOk}
        onCancel={() => setShowModal(false)}
        width={"40%"}
      >
        <div>
          <h3>
            Please confirm that you'll be able to complete the project during
            the stated timeframe before submitting the application:
          </h3>
          {items[projectIndex]?.prePresentationChecklist
            ?.startDateFirmOrFlexible === "firm" ? (
            <h3>
              Subcontractor shall start the Project on:{" "}
              {items[projectIndex]?.prePresentationChecklist
                ?.projectStartDate && (
                <>
                  {moment(
                    items[projectIndex]?.prePresentationChecklist
                      ?.projectStartDate
                  ).format("MM-DD-YYYY")}{" "}
                  ?
                </>
              )}
            </h3>
          ) : (
            <h3>
              Subcontractor shall start the Project within 3 days of:{" "}
              {items[projectIndex]?.prePresentationChecklist
                ?.projectStartDate && (
                <>
                  {moment(
                    items[projectIndex]?.prePresentationChecklist
                      ?.projectStartDate
                  ).format("MM-DD-YYYY")}{" "}
                  ?
                </>
              )}
            </h3>
          )}
        </div>
        <div>
          {items[projectIndex]?.prePresentationChecklist
            ?.completionDateFirmOrFlexible === "firm" ? (
            <h3>
              Subcontractor shall complete the entire Project by no later than:{" "}
              {items[projectIndex]?.prePresentationChecklist
                ?.jobCompleteDate && (
                <>
                  {moment(
                    items[projectIndex]?.prePresentationChecklist
                      ?.jobCompleteDate
                  ).format("MM-DD-YYYY")}{" "}
                  ?
                </>
              )}
            </h3>
          ) : (
            <h3>
              Subcontractor shall complete the entire Project within 7 days of:{" "}
              {items[projectIndex]?.prePresentationChecklist
                ?.jobCompleteDate && (
                <>
                  {moment(
                    items[projectIndex]?.prePresentationChecklist
                      ?.jobCompleteDate
                  ).format("MM-DD-YYYY")}{" "}
                  ?
                </>
              )}
            </h3>
          )}
        </div>
      </Modal>
      {isMobile ? (
        <p
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            marginTop: "10px",
            textAlign: "center",
          }}
        >
          {currentProject >= 0
            ? `${items[currentProject].projectInfo.jobName}`
            : ""}
        </p>
      ) : null}

      {isTabletOrMobile ? (
        <>
          {items[currentProject]?.prePresentationChecklist?.crew?._id !==
            user?._id && (
            <Row justify={"end"}>
              <Button
                style={{
                  color: "#1f1f1f",
                  marginBottom: "15px",
                  marginTop: "15px",
                }}
                size="large"
                type="primary"
                onClick={handleModal}
              >
                Apply for Job
              </Button>
            </Row>
          )}
        </>
      ) : (
        <>
          {items[currentProject]?.prePresentationChecklist?.crew?._id !==
            user?._id && (
            <Row justify={"end"}>
              <Button
                style={{ color: "#1f1f1f" }}
                size="large"
                type="primary"
                onClick={handleModal}
              >
                Apply for Job
              </Button>
            </Row>
          )}
        </>
      )}

      {!isTabletOrMobile ? (
        <div className="main--tabs--holder" style={{ overflowX: "scroll" }}>
          <Steps
            type="navigation"
            current={props.current}
            className="site-navigation-steps"
          >
            {elements.map((item, index: number) => (
              <Step
                className="step"
                key={item.title}
                title={item.title}
                onClick={() => handleStep(index)}
              />
            ))}
          </Steps>
        </div>
      ) : (
        <div className="navigation-text-container">
          <Row align={"middle"}>
            <Col span={6} offset={4}>
              <p onClick={handlePrev}>
                <LeftOutlined /> Prev
              </p>
            </Col>
            <Col span={6} offset={0}>
              <Dropdown
                overlay={menuItems}
                trigger={["click"]}
                placement="bottom"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    {elements[props.current]?.title} <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </Col>
            <Col span={6} offset={2}>
              <p onClick={handleNext}>
                Next <RightOutlined />
              </p>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ContractorSteps;
