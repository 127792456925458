import { Modal, Button, Row, Col, Select, Input, Form } from "antd";
import "./AddItemDefaultModal.less";
import {
  addMaterialItem,
  updateMaterialItem,
} from "../../../redux/material-defaults/action";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const { Option } = Select;
type Props = {
  showSingleItemModal: boolean;
  toggleSingleItemModal: () => void;
  material?: string;
  item?: any;
  edit?: boolean;
};

const AddItemDefaultModal = (props: Props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { user } = useSelector((state: RootStateOrAny) => state.auth);

  useEffect(() => {
    if (Object.keys(props.item).length > 0) {
      form.setFieldsValue(props.item);
    } else {
      form.resetFields();
    }
  }, [props.item, props.showSingleItemModal]);

  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };

  const onFinish = (values: any) => {
    if (!props.edit) {
      dispatch(addMaterialItem(values));
    } else {
      dispatch(updateMaterialItem({ ...values, _id: props.item._id }));
    }

    props.toggleSingleItemModal();
  };
  const handlePrice = (e: any) => {
    let priceAfterTax = parseInt(e.target.value);
    priceAfterTax += e.target.value * 0.08;
    form.setFieldsValue({ priceAfterTax: priceAfterTax });
  };
  return (
    <Modal
      className="default-modal-rt"
      centered={true}
      visible={props.showSingleItemModal}
      onOk={props.toggleSingleItemModal}
      onCancel={props.toggleSingleItemModal}
      footer={false}
      title={
        <h1 style={{ marginTop: 10 }} className="default-modal-add-item">
          {!props.edit ? "Add item" : "Edit item"}
        </h1>
      }
    >
      <div className="default-material-form">
        <Form
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="manufacturer"
                label={<h2>Add Manufacturer name</h2>}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  checkEmpty,
                ]}
              >
                <Input className="default-material-form-input" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="product"
                label={<h2>Enter Product</h2>}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  checkEmpty,
                ]}
              >
                <Input className="default-material-form-input" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="itemType"
                label={<h2>Type</h2>}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  className="default-material-form-select"
                  placeholder="Select"
                >
                  <Option value="INT">Interior</Option>
                  <Option value="EXT">Exterior</Option>
                  <Option value="INT/EXT">Interior/Exterior</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={props.material}
                hasFeedback
                name="material"
                label={<h2>Material</h2>}
              >
                <Select
                  className="default-material-form-select"
                  placeholder="Select"
                  disabled={props.edit}
                >
                  <Option value="paint">Paint</Option>
                  <Option value="primer">Primer</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="sheen"
                label={<h2>Sheen</h2>}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  checkEmpty,
                ]}
              >
                <Input className="default-material-form-input" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="priceAfterTax"
                label={
                  <Row gutter={60} align="middle">
                    <Col>
                      <h2>Price</h2>
                    </Col>
                    <Col>{/* <h3>(Tax rate = 8 %)</h3> */}</Col>
                  </Row>
                }
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input type="number" className="default-material-form-input" />
              </Form.Item>
              {/* <Form.Item
                hasFeedback
                name="priceBeforeTax"
                label={
                  <Row gutter={50} align="middle">
                    <Col>
                      <h2>Price before taxes</h2>
                    </Col>
                    <Col>
                      <h3>(Tax rate = 8 %)</h3>
                    </Col>
                  </Row>
                }
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input
                  type="number"
                  onChange={handlePrice}
                  className="default-material-form-input"
                />
              </Form.Item> */}
            </Col>
          </Row>
          {/* <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="priceAfterTax"
                label={
                  <Row gutter={60} align="middle">
                    <Col>
                      <h2>Price after taxes</h2>
                    </Col>
                    <Col>
                      <h3>(Tax rate = 8 %)</h3>
                    </Col>
                  </Row>
                }
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input type="number" className="default-material-form-input" />
              </Form.Item>
            </Col>
          </Row> */}

          <Row gutter={10} align="middle" justify="end">
            <Col>
              <Button
                onClick={props.toggleSingleItemModal}
                className="default-material-cancel-btn"
              >
                Cancel
              </Button>
            </Col>

            <Col>
              <Button
                htmlType="submit"
                type="primary"
                className="default-material-add-btn"
              >
                {!props.edit ? "Add Entry" : "Update Entry"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default AddItemDefaultModal;
