import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Input,
  List,
  Modal,
  Row,
  Select,
  Tag,
  Tooltip,
} from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

interface FilterItem {
  text: string;
  value: string;
  checked: boolean;
}

interface ProjectFiltersModalProps {
  visible: boolean;
  onClose: () => void;
  filterList: { [key: string]: FilterItem[] };
  handleApplyFilters: (selectedFilters: any) => void;
}

const filterKeysList = [
  "jobName",
  "jobNumber",
  "projectType",
  "projectSubtype",
  "segment",
  "subSegment",
  "streetAddressOne",
  "city",
  "state",
  "zip",
  "country",
  "primaryContactName",
  "primaryContactEmail",
  "primaryContactAddress",
  "projectStatus",
];

const filterLabels: { [key: string]: string } = {
  jobName: "Job Name",
  jobNumber: "Job Number",
  projectType: "Project Type",
  projectSubtype: "Project Subtype",
  segment: "Segment",
  subSegment: "Sub Segment",
  streetAddressOne: "Street Address",
  city: "City",
  state: "State",
  zip: "ZIP Code",
  country: "Country",
  primaryContactName: "Primary Contact Name",
  primaryContactEmail: "Primary Contact Email",
  primaryContactAddress: "Primary Contact Address",
  projectStatus: "Status",
};

const ProjectFiltersModal = ({
  visible,
  onClose,
  filterList,
  handleApplyFilters,
}: ProjectFiltersModalProps) => {
  const [currentFilter, setCurrentFilter] = useState(filterKeysList[0]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: FilterItem[];
  }>({});
  const [expandedFilters, setExpandedFilters] = useState<{
    [key: string]: boolean;
  }>({});

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleCheckboxChange = (item: FilterItem, checked: boolean) => {
    setSelectedFilters((prev) => {
      const updatedList = checked
        ? [...(prev[currentFilter] || []), item]
        : (prev[currentFilter] || []).filter((i) => i.value !== item.value);
      return { ...prev, [currentFilter]: updatedList };
    });
  };

  // Sorting the list to have checked items first
  const filteredList = filterList[currentFilter]
    ?.filter((item) =>
      item.text.toLowerCase().includes(searchInput.toLowerCase())
    )
    .sort((a, b) => {
      const aChecked = selectedFilters[currentFilter]?.some(
        (i) => i.value === a.value
      );
      const bChecked = selectedFilters[currentFilter]?.some(
        (i) => i.value === b.value
      );
      return aChecked === bChecked ? 0 : aChecked ? -1 : 1;
    });

  const resetFilter = () => {
    setSearchInput("");
    const newFilters = {}; // Create new filters object
    setSelectedFilters(newFilters);
    setExpandedFilters({});
    handleApplyFilters(newFilters);
  };

  const applyFilters = () => {
    setSearchInput("");
    handleApplyFilters(selectedFilters);
    // onClose();
  };

  const handleRemoveItem = (item: FilterItem) => {
    setSelectedFilters((prev) => {
      const updatedList = (prev[currentFilter] || []).filter(
        (i) => i.value !== item.value
      );
      return { ...prev, [currentFilter]: updatedList };
    });
  };

  const renderSelectedFilters = () => {
    return Object.keys(selectedFilters).map((key) => {
      const items = selectedFilters[key];
      if (items.length === 0) return null;

      const displayedItems = expandedFilters[key] ? items : items.slice(0, 2);
      const hiddenCount = items.length - displayedItems.length;

      return (
        <div key={key} style={{ marginBottom: 8 }}>
          <strong>{filterLabels[key]}: </strong>
          {displayedItems.map((item, index) => (
            <Tag key={index}>
              {item.text}
              <CloseCircleOutlined
                style={{ marginLeft: 8, cursor: "pointer", color: "red" }}
                onClick={() => handleRemoveItem(item)}
              />
            </Tag>
          ))}
          {hiddenCount > 0 && (
            <Tag
              onClick={() =>
                setExpandedFilters((prev) => ({
                  ...prev,
                  [key]: !prev[key],
                }))
              }
              style={{ cursor: "pointer" }}
            >
              {expandedFilters[key] ? "show less" : `see ${hiddenCount} more`}
            </Tag>
          )}
        </div>
      );
    });
  };

  console.log(filterList, selectedFilters);
  return (
    <Modal
      title={"Project Filters"}
      visible={visible}
      onCancel={onClose}
      footer={[
        <>
          <Button
            key="reset"
            onClick={resetFilter}
            style={{
              color: "black",
              paddingInline: "30px",
              borderRadius: "8px",
              height: "40px",
            }}
          >
            Reset
          </Button>
          <Button
            key="close"
            onClick={onClose}
            style={{
              color: "black",
              paddingInline: "30px",
              borderRadius: "8px",
              height: "40px",
            }}
          >
            Close
          </Button>
          <Button
            key="apply"
            type="primary"
            style={{
              color: "black",
              paddingInline: "30px",
              borderRadius: "8px",
              height: "40px",
            }}
            onClick={applyFilters}
          >
            Apply
          </Button>
        </>,
      ]}
    >
      <div>
        <div>
          <Select
            value={currentFilter}
            onChange={(value) => {
              setCurrentFilter(value);
              setSearchInput("");
            }}
            style={{ width: "100%", marginBottom: 16 }}
          >
            {filterKeysList.map((key) => (
              <Option key={key} value={key}>
                {filterLabels[key]}
              </Option>
            ))}
          </Select>
          <Input
            placeholder={`Search ${filterLabels[currentFilter]}`}
            value={searchInput}
            onChange={handleSearchChange}
            // suffix={
            //   searchInput && (
            //     <CloseCircleOutlined onClick={() => setSearchInput("")} />
            //   )
            // }
            style={{ marginBottom: 8, display: "block" }}
          />
          <div style={{ height: 300, overflowY: "scroll", paddingRight: 16 }}>
            <List
              itemLayout="horizontal"
              dataSource={filteredList}
              renderItem={(item) => (
                <List.Item>
                  <Checkbox
                    checked={selectedFilters[currentFilter]?.some(
                      (i) => i.value === item.value
                    )}
                    onChange={(e) =>
                      handleCheckboxChange(item, e.target.checked)
                    }
                  >
                    {item.text}
                  </Checkbox>
                </List.Item>
              )}
            />
          </div>

          <div style={{ marginTop: 16 }}>{renderSelectedFilters()}</div>
        </div>
      </div>
    </Modal>
  );
};

export default ProjectFiltersModal;
