import { Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import { useMediaQuery } from "react-responsive";
type Props = {
  options?: any;
};

const PriceSummaryC = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:800px)" });

  const [subTotal, setSubTotal] = useState(0);
  

  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  useEffect(() => {
    let total = 0;
    props?.options?.map((item: any) => {
      let temp = totalLaborPrice(item);
      total = total + temp;
      
    });

    setSubTotal(total);
  }, [props]);




  const totalLaborPrice = (data: any) => {
    let projectPaintLabor = items[currentProject]?.pjcc.find(
      (item: any) => item.name === "Paint Labor"
    );
    let projectCarpentryLabor = items[currentProject]?.pjcc.find(
      (item: any) => item.name === "Carpentry Labor"
    );

    let singleOptionIndex = items[currentProject]?.options.findIndex(
      (item: any) => item.optionInfo._id === data._id
    );
    let paintLabor = items[currentProject]?.options[
      singleOptionIndex
    ]?.pjcc.find((item: any) => item.name === "Paint Labor");
    let carpentryLabor = items[currentProject]?.options[
      singleOptionIndex
    ]?.pjcc.find((item: any) => item.name === "Carpentry Labor");

    let paintLaborPrice =
      (paintLabor.cost / projectPaintLabor.cost) *
      projectPaintLabor.productionTargets;
    let carpentryLaborPrice =
      (carpentryLabor.cost / projectCarpentryLabor.cost) *
      projectCarpentryLabor.productionTargets;
    let totalPrice =
      (isNaN(paintLaborPrice) ? 0 : paintLaborPrice) +
      (isNaN(carpentryLaborPrice) ? 0 : carpentryLaborPrice);
    return isNaN(totalPrice) ? 0 : totalPrice;
  };

  const columns = [
    {
      title: "Options",
      dataIndex: "title",
      width: "90%",
    },
    {
      title: "Price",
      width: "10%",
      render: (data: any) => {
        return (
          <div style={{ color: "#333333" }}>
            $
            {totalLaborPrice(data)?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        );
      },
    },
  ];
  

  return (
    <div>
      <h2 className="content-title" style={{ padding: "50px 25px 20px 30px" }}>
        Price Summary
      </h2>
      <>
        {isTabletOrMobile ? (
          <div
            className="pjcc--listing"
            style={{
              padding: "0px 25px 20px 30px",
              wordWrap: "normal",
              width: "850px",
            }}
          >
            {props?.options?.length > 0 && (
              <div className="table-title">
                <Table
                  rowClassName={(record, index) => {
                    return index % 2 === 0
                      ? "table_row table-row-dark"
                      : "table_row table-row-light ";
                  }}
                  rowKey="_id"
                  columns={columns}
                  dataSource={props?.options}
                  pagination={false}
                  summary={(pageData) => {
                    return (
                      <>
                        <Table.Summary.Row style={{ fontWeight: "bold" }}>
                          <Table.Summary.Cell index={0}>
                            Total
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1} className="pricing">
                            <span style={{ wordWrap: "normal" }}>
                              $
                              {subTotal?.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{" "}
                            </span>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <div
            className="pjcc--listing"
            style={{ padding: "0px 25px 20px 30px", wordWrap: "normal" }}
          >
          
            
            {props?.options?.length > 0 && (
              <div className="table-title">
                <Table
                  rowClassName={(record, index) => {
                    return index % 2 === 0
                      ? "table_row table-row-dark"
                      : "table_row table-row-light ";
                  }}
                  rowKey="_id"
                  columns={columns}
                  dataSource={props?.options}
                  pagination={false}
                  summary={(pageData) => {
                    return (
                      <>
                        <Table.Summary.Row style={{ fontWeight: "bold" }}>
                          <Table.Summary.Cell index={0}>
                            Total
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1} className="pricing">
                            <span style={{ wordWrap: "normal" }}>
                              $
                              {subTotal?.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{" "}
                            </span>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default PriceSummaryC;
