import { Content } from "antd/lib/layout/layout";
import DocumentCard from "../../containers/media/DocumentCard";
import MediaCard from "../../containers/media/MediaCard";
import JobJacketSteps from "./JobJacketSteps";
import { useSelector, RootStateOrAny } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../../utils/api";

type Props = {};

const Media = (props: Props) => {
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);
  const [images, SetImages] = useState<any>([]);
  const [onSiteMedia, setOnSiteMedia] = useState<any>([]);
  useEffect(() => {
    setLoading(true);
    if (online) {
      api
        .get(`upload-files/media/${id}`)
        .then((resp: any) => {
          let onSiteMediaPics: any[] = [];
          let allMedia: any[] = [];
          resp.forEach((item: any) => {
            if (item.waterSourcePic || item.electricSourcePic) {
              onSiteMediaPics.push(item);
            } else {
              allMedia.push(item);
            }
          });
          setOnSiteMedia(onSiteMediaPics);

          SetImages(allMedia);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [online]);

  return (
    <>
      <Content>
        <JobJacketSteps current={6} />
        <MediaCard
          loading={loading}
          images={images}
          SetImages={SetImages}
          onSiteMedia={onSiteMedia}
          setOnSiteMedia={setOnSiteMedia}
        />
        <div style={{ marginTop: 40 }}>
          <DocumentCard />
        </div>
      </Content>
    </>
  );
};

export default Media;
