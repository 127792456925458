import { useEffect } from "react";
import { useDispatch } from "react-redux";
import PayProcessing from "../../containers/pay-processing-defaults/PayProcessing";
import {
  fetchAssociates,
  fetchPayProcessing,
} from "../../redux/pay-proccesing/action";

const PayProcessingDefaults = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAssociates());
    dispatch(fetchPayProcessing());
  }, []);
  return (
    <div>
      <PayProcessing />
    </div>
  );
};

export default PayProcessingDefaults;
