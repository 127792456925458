import React from 'react';
import { Row, Col, Button, Table } from 'antd';
import { columns } from './config';
import '../PaintSurfacesDefaults.less';

type Props = {};

const PaintSurfacesDefaults = (props: Props) => {
  const data = new Array(6).fill({
    surface: 'A/C Junctions',
    type: 'EA',
    paintLabor: '12 (Medium)',
    prep: '48 (Medium)',
    primeLabor: '12 (Medium)',
    paint: '100',
    prime: '100',
    defaultProduct: 'SW: Cashmere Flat',
    action: '',
  });
  return (
    <div>
      <Row className="paint-surface-defaults-container" justify="space-between">
        <Col>
          <h1>Paint surfaces</h1>
        </Col>
        <Col>
          <Button className="labor-rates-defaults-buttons">
            Add Paint Surface
          </Button>
        </Col>
      </Row>
      <Table dataSource={data} columns={columns} pagination={false} />
    </div>
  );
};

export default PaintSurfacesDefaults;
