import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, Select, message, Modal } from "antd";

import "./Login.less";

import { MaskedInput } from "antd-mask-input";

const { Option } = Select;

type Props = {
  showAddUserModal: boolean;
  toggleShowAddUserModal: (item: any) => void;
  handleAddUser: (item: any) => void;
  row?: any;
  invalidEmail?: string;
};

const CreateAccountC = (props: Props) => {
  const [form] = Form.useForm();
  const [isEditable, setIsEditable] = useState(false);
  useEffect(() => {
    if (props?.row && Object.keys(props.row).length > 0) {
      setIsEditable(true);

      form.setFieldsValue(props.row);
    } else {
      setIsEditable(false);
      form.resetFields();
    }
  }, [props.row, props.showAddUserModal]);
  return (
    <div className="background-container">
      <Modal
        centered={true}
        visible={props.showAddUserModal}
        onOk={props.toggleShowAddUserModal}
        onCancel={props.toggleShowAddUserModal}
        footer={false}
        title={
          <h1 className="login-title">
            {isEditable ? "Update User" : "Add User"}
          </h1>
        }
      >
        <Form
          onFinish={(values: any) => {
            form.resetFields();
            props.handleAddUser(values);
          }}
          form={form}
          scrollToFirstError
          layout="vertical"
        >
          <div>
            <div>
              <Row>
                <Col style={{ width: 220 }}>
                  <Form.Item
                    label={<h1 className="input-title">Full name</h1>}
                    name="fullName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your full name!",
                      },
                    ]}
                  >
                    <Input className="input-field" />
                  </Form.Item>
                </Col>
                <Col style={{ width: 220, marginLeft: 30 }}>
                  <Form.Item
                    label={<h1 className="input-title">Job Title</h1>}
                    name="jobTitle"
                  >
                    <Input className="input-field" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col style={{ width: 220 }}>
                  <Form.Item
                    label={<h1 className="input-title">Add Phone Number</h1>}
                    name="phone"
                  >
                    <MaskedInput
                      required
                      mask={"(000) 000-0000"}
                      className="input-field"
                    />
                  </Form.Item>
                </Col>

                <Col
                  className="so-options-container"
                  style={{ width: 220, marginLeft: 30 }}
                >
                  <Form.Item
                    name="roles"
                    label={<h1 className="input-title">Role</h1>}
                    rules={[
                      {
                        required: true,
                        message: "Please select the role",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      size="large"
                      style={{ marginTop: 4 }}
                    >
                      <Option value="sales_associate">Sales Associate</Option>
                      <Option value="estimator">Estimator</Option>
                      <Option value="production_associate">
                        Production Associate
                      </Option>
                      <Option value="admin">Admin</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label={<h1 className="input-title">Email</h1>}
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your valid email address!",
                    type: "email",
                  },
                ]}
              >
                <Input disabled={isEditable} className="input-field" />
              </Form.Item>

              {!isEditable && (
                <>
                  <Form.Item
                    label={<h1 className="input-title">Password</h1>}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your password!",
                      },
                    ]}
                  >
                    <Input.Password className="input-field" />
                  </Form.Item>

                  <Form.Item
                    label={<h1 className="input-title">Confirm Password</h1>}
                    name="confirm password"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password className="input-field" />
                  </Form.Item>
                </>
              )}
            </div>

            <div className="login-btn-container">
              {props.invalidEmail && (
                <p style={{ color: "red" }}>{props.invalidEmail}</p>
              )}
              <div>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="login-btn "
                  >
                    {isEditable ? "Update" : "Create"}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateAccountC;
