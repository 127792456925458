import { Col, Row, Switch } from "antd";
import "./ProposalSetup.less";

type Props = {
  title: string;
  name: string;
  telephone: string;
  address: string;
  email: string;
  cell?: string;
  addToProposal?: boolean;
  onSwitchChange?: any;
  showSwitch?: boolean;
};

const ClientContact = (props: Props) => {
  return (
    <div className="cc-container">
      <Row justify="space-between">
        <h4>{props.title}</h4>
        <Row gutter={15}>
          <Col>
            {props.showSwitch && (
              <Switch
                disabled={props.title === "Primary"}
                checked={props.addToProposal || props.title === "Primary"}
                onChange={(checked) =>
                  props.onSwitchChange(checked, props.title)
                }
              />
            )}
          </Col>
          <Col>{props.showSwitch && <p>Add to proposal</p>}</Col>
        </Row>
      </Row>
      <div className="psc-container subtitle-margin ">
        <Row gutter={[2, 14]}>
          <Col lg={3} sm={3}>
            <h2>Name</h2>
          </Col>
          <Col lg={5} sm={6}>
            <h3>{props.name}</h3>
          </Col>
          <Col lg={3} sm={3}>
            <h2>Telephone</h2>
          </Col>
          <Col lg={5} sm={5}>
            <h3>{props.telephone}</h3>
          </Col>
          <Col lg={3} sm={3}>
            <h2>Cell</h2>
          </Col>
          <Col lg={5} sm={4}>
            <h3>{props.cell}</h3>
          </Col>
          <Col lg={3} sm={3}>
            <h2>Address</h2>
          </Col>
          <Col lg={5} sm={6}>
            <h3>{props.address}</h3>
          </Col>
          <Col lg={3} sm={3}>
            <h2>Email</h2>
          </Col>
          <Col lg={5} sm={6}>
            <h3>{props.email}</h3>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ClientContact;
