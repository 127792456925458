import React from "react";
import { Row, Col } from "antd";
import CompanyInfo from "../../pages/administrator/CompanyInfo";

type Props = {
  companyInfo: any;
};

const ContractFooter = (props: Props) => {
  return (
    <div className="contract-footer-root">
      <Row justify="center">
        <Col span={17}>
          <h1 style={{ textAlign: "center" }}>
            IN WITNESS WHEREOF, the Parties hereto have executed this Work Order
            to be effective upon its full execution.
          </h1>
        </Col>
      </Row>
      <div className="cf-margin-top">
        <Row gutter={40} justify="space-between">
          <Col>
            <h1>{props?.companyInfo?.companyName} </h1>
            <div className="cf-sign-container">
              <hr style={{ marginRight: 200 }} />
              <p>Signature</p>
              <div className="cf-sign-margin">
                <hr style={{ marginRight: 200 }} />
                <p>Printed</p>
              </div>
              <div className="cf-sign-margin">
                <hr style={{ marginRight: 200 }} />
                <p>Title</p>
              </div>
            </div>
          </Col>
          <Col style={{ marginRight: 100 }}>
            <h1>Subcontractor:</h1>
            <div className="cf-sign-container">
              <hr />
              <p>Signature</p>
              <div className="cf-sign-margin">
                <hr />
                <p>Printed</p>
              </div>
              <div className="cf-sign-margin">
                <hr />
                <p>Title</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ContractFooter;
