import { getAuthUser, getSelectedTenant } from "./../../utils/authToken";
import axios from "axios";
import { API_BASE } from "../../constant";
import api from "../../utils/api";
import { isNull } from "lodash";

export const setCurrentOption = (payload: any) => ({
  type: "SET_CURRENT_OPTION",
  payload,
});

export const addOption = (payload: any) => ({
  type: "ADD_OPTION",
  payload,
});

export const removeOption = (payload: any) => ({
  type: "REMOVE_OPTION",
  payload,
});

export const addOptions = (payload: any) => ({
  type: "ADD_OPTIONS",
  payload,
});

export const updateOption = (payload: any) => ({
  type: "UPDATE_OPTIONS",
  payload,
});

export const updateOptionTraversed = (payload: any) => ({
  type: "UPDATE_OPTION_TRAVERSED",
  payload,
});

export const setOptionsPjcc = (payload: any) => ({
  type: "SET_OPTIONS_PJCC",
  payload,
});

export const fetchOptions = (id: string) => (dispatch: any) => {
  api
    .get(`options/project/${id}`)
    .then((response: any) => {
      dispatch(addOptions(response));
    })
    .catch((err) => {
      console.error(err);
    });
};

export const deleteOptionById = (id: string) => (dispatch: any) => {
  api
    .delete(`options/${id}`)
    .then(() => {
      dispatch(removeOption(id));
    })
    .catch((err) => console.error(err));
};

export const createOption = (body: any) => (dispatch: any) => {
  const user = getAuthUser();
  const tenantId = getSelectedTenant();
  return axios
    .post(`${API_BASE}options`, body, {
      headers: {
        Authorization: user.accessToken ? "Bearer " + user.accessToken : "",
        contentType: "application/json",
        "tenant-id": !isNull(tenantId) ? tenantId : "",
      },
    })
    .then((response: any) => {
      dispatch(addOption(response.data));
      return Promise.resolve();
    })
    .catch((err) => Promise.reject(err));
};

export const fetchOptionsPjcc = (id: string) => (dispatch: any) => {
  api
    .get(`pjcc/options/${id}`)
    .then((response: any) => {
      dispatch(setOptionsPjcc(response));
    })
    .catch((err) => {
      console.error(err);
    });
};
