import { Row, Col, Avatar, Space, Divider } from "antd";
import { MailIcon, PhoneIcon, AddressIcon } from "../../utils/icons";
import { RootStateOrAny, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

type Props = {
  jobName?: string;
  address?: string;
  telephone?: string;
  email?: string;
  associateName?: string;
  associatePicture?: string;
  associateCell?: string;
  associateEmail?: string;
  companyInfo?: any;
  associateJobTitle?: any;
  preparedBy?: any;
  city?:any;
  zip?:any;
  state?:any;
};

const JobSiteHeader = (props: Props) => {
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:800px)" });

  const getInitials = (name: any) => {
    try {
      const nameArray = name.split(" ");
      const firstName = nameArray[0];
      const lastName = nameArray[nameArray.length - 1];
      if (nameArray.length === 1) {
        const initials = firstName.charAt(0);
        return initials.toUpperCase();
      } else {
        const initials = firstName.charAt(0) + lastName.charAt(0);
        return initials.toUpperCase();
      }
    } catch (err) {
      console.log(err);
      return "";
    }
  };
  const associateInitials = getInitials(
    props?.preparedBy?.fullName || props?.associateName
  );
  return (
    <>
      {isTabletOrMobile ? (
        <div className="proposal-header" style={{ width: "850px" }}>
          <div className="proposal-jobsite-container">
            <Row justify="space-between">
              <Col lg={10} md={10} xs={10} style={{ marginBottom: "15px" }}>
                <h2 className="content-title" style={{ marginBottom: 0 }}>
                  JOBSITE
                </h2>
                <Divider
                  type="horizontal"
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    marginRight: 50,
                    borderColor: "#FDB913",
                    borderWidth: 5,
                  }}
                ></Divider>
                <div className="jobsite-content-title">
                  <Row gutter={12}>
                    <Col style={{ wordWrap: "break-word" }}>
                      {props.jobName}
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col>{props.address}</Col>
                  </Row>
                  <Row gutter={8}>
                    <Col>{props.city}, {props.state}, {props.zip}</Col>
                  </Row>
               
                  <Row gutter={12}>
                    <Col>{props.telephone}</Col>
                  </Row>
                  <Row gutter={12}>
                    <Col>{props.email}</Col>
                  </Row>
                </div>
              </Col>
              <Col lg={10} md={10} xs={10}>
                <h1 className="content-title" style={{ marginBottom: 0 }}>
                  Prepared By
                </h1>
                <Divider
                  type="horizontal"
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    marginRight: 60,
                    borderColor: "#FDB913",
                    borderWidth: 5,
                  }}
                ></Divider>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col flex="auto">
                    <Row gutter={12} align="middle">
                      <Col>
                        {props?.preparedBy?.fullName || props?.associateName}
                      </Col>
                    </Row>
                    <Row gutter={12} align="middle">
                      <Col>
                        {props?.preparedBy?.jobTitle ||
                          props?.associateJobTitle}
                      </Col>
                    </Row>
                    <Row gutter={12} align="middle">
                      <Col>
                        {props?.preparedBy?.phone || props?.associateCell}
                      </Col>
                    </Row>

                    <Row gutter={12} align="middle">
                      <Col>
                        {props?.preparedBy?.email || props?.associateEmail}
                      </Col>
                    </Row>
                  </Col>
                  <Col flex="auto">
                    {(props?.preparedBy?.picture || props?.associatePicture) &&
                    online ? (
                      <Avatar
                        src={
                          props?.preparedBy?.picture || props?.associatePicture
                        }
                        shape={"square"}
                        style={{ width: 70, height: 70 }}
                        // size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                      />
                    ) : (
                      <Avatar
                        shape="square"
                        style={{
                          fontWeight: "bold",
                          fontSize: 30,
                        }}
                        size={70}
                      >
                        {associateInitials}
                      </Avatar>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="proposal-header">
          <div className="proposal-jobsite-container">
            <Row justify="space-between">
              <Col lg={10} sm={24} style={{ marginBottom: "15px" }}>
                <h2 className="content-title" style={{ marginBottom: 0 }}>
                  JOBSITE
                </h2>
                <Divider
                  type="horizontal"
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    marginRight: 50,
                    borderColor: "#FDB913",
                    borderWidth: 5,
                  }}
                ></Divider>
                <div className="jobsite-content-title">
                  <Row gutter={12}>
                    <Col style={{ wordWrap: "break-word" }}>
                      {props.jobName}
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col>{props.address}</Col>
                  </Row>
                  <Row gutter={8}>
                  <Col>{props.city}, {props.state}, {props.zip}</Col>
                  </Row>
              
                  
                  <Row gutter={12}>
                    <Col>{props.telephone}</Col>
                  </Row>
                  <Row gutter={12}>
                    <Col>{props.email}</Col>
                  </Row>
                </div>
              </Col>
              <Col lg={10} sm={24}>
                <h1 className="content-title" style={{ marginBottom: 0 }}>
                  Prepared By
                </h1>
                <Divider
                  type="horizontal"
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    marginRight: 60,
                    borderColor: "#FDB913",
                    borderWidth: 5,
                  }}
                ></Divider>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col flex="auto">
                    <Row gutter={12} align="middle">
                      <Col>
                        {props?.preparedBy?.fullName || props?.associateName}
                      </Col>
                    </Row>
                    <Row gutter={12} align="middle">
                      <Col>
                        {props?.preparedBy?.jobTitle ||
                          props?.associateJobTitle}
                      </Col>
                    </Row>
                    <Row gutter={12} align="middle">
                      <Col>
                        {props?.preparedBy?.phone || props?.associateCell}
                      </Col>
                    </Row>

                    <Row gutter={12} align="middle">
                      <Col>
                        {props?.preparedBy?.email || props?.associateEmail}
                      </Col>
                    </Row>
                  </Col>
                  <Col flex="auto">
                    {(props?.preparedBy?.picture || props?.associatePicture) &&
                    online ? (
                      <Avatar
                        src={
                          props?.preparedBy?.picture || props?.associatePicture
                        }
                        shape={"square"}
                        style={{ width: 70, height: 70 }}
                        // size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                      />
                    ) : (
                      <Avatar
                        shape="square"
                        style={{
                          fontWeight: "bold",
                          fontSize: 30,
                        }}
                        size={70}
                      >
                        {associateInitials}
                      </Avatar>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default JobSiteHeader;
