import React from 'react';
import IntegrationsC from '../../containers/integration/IntegrationsC';

type Props = {};

const Integrations = (props: Props) => {
  return (
    <div>
      <IntegrationsC />
    </div>
  );
};

export default Integrations;
