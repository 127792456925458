import React from "react";
import Company from "../../containers/admin-company-info/Company";

type Props = {};

const CompanyInfo = (props: Props) => {
  return (
    <div>
      <Company />
    </div>
  );
};

export default CompanyInfo;
