export const columns = [
  {
    title: 'Date',
    key: 'date',
    dataIndex: 'date',
  },
  {
    title: 'Project Name',
    key: 'projectName',
    dataIndex: 'projectName',
  },
  {
    title: 'Amount',
    key: 'amount',
    dataIndex: 'amount',
  },
];
