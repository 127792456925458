import { Divider } from "antd";
import "react-quill/dist/quill.snow.css";
import "./Proposal.less";
import { useMediaQuery } from "react-responsive";

type Props = {
  summary?: any;
  optionsList?: any;
};


const ProjectSummary = (props: Props) => {


  const isTabletOrMobile = useMediaQuery({ query: "(max-width:800px)" });
  if ((props?.summary == undefined) && (props?.optionsList?.length === 0)) {

    return null;

  }
  return (
    <>
      {isTabletOrMobile ? (
        <div className="proposal-header" style={{ width: "850px" }}>
          <h2 className="content-title" style={{ marginBottom: 0 }}>
            {" "}
            PROJECT SUMMARY
          </h2>
          <Divider
            type="horizontal"
            style={{
              marginTop: 0,
              marginRight: 50,
              borderColor: "#FDB913",
              borderWidth: 5,
            }}
          ></Divider>
          {props?.optionsList?.length && (
            <>
              <p>
                Project Includes: {props?.optionsList?.join(", ")}. For specific
                details please refer to the Included Details section below.
              </p>
            </>
          )}
          <div className="remove-padding">
            <pre
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: props?.summary
                  ? '<div class="ql-font-Lato" class="ql-size-medium">' +
                  props?.summary +
                  "</div>"
                  : "",
              }}
            />
            {/* <p
          dangerouslySetInnerHTML={{
            __html: props.summary?.replace(/\n/g, "<br/>"),
          }}
        /> */}
          </div>
        </div>
      ) : (
        <div className="proposal-header">
          <h2 className="content-title" style={{ marginBottom: 0 }}>
            {" "}
            PROJECT SUMMARY
          </h2>
          <Divider
            type="horizontal"
            style={{
              marginTop: 0,
              marginRight: 50,
              borderColor: "#FDB913",
              borderWidth: 5,
            }}
          ></Divider>
          {props?.optionsList?.length && (
            <>
              <p>
                Project Includes: {props?.optionsList?.join(", ")}. For specific
                details please refer to the Included Details section below.
              </p>
            </>
          )}
          <div className="remove-padding">
            <pre
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{
                __html: props?.summary
                  ? '<div class="ql-font-Lato" class="ql-size-medium">' +
                  props?.summary +
                  "</div>"
                  : "",
              }}
            />
            {/* <p
          dangerouslySetInnerHTML={{
            __html: props.summary?.replace(/\n/g, "<br/>"),
          }}
        /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectSummary;
