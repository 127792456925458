import { Divider, Row, Col, Image, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import api from "../../utils/api";
import loadingGif from '../../assets/images/loading.gif'

interface optionImages {
  optionsMediaList: any[]
  setOptionsMediaList: any
  loading: any
}

const OptionImages = (props: optionImages) => {
  const { currentProject, items } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const [imagesPerRow, setImagesPerRow] = useState(0);
  const [previewSrc, setPreviewSrc] = useState("")
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const optionImagesPerRow =
      items[currentProject]?.projectInfo?.proposal?.optionImagesPerRow;
    if (optionImagesPerRow) {
      setImagesPerRow(optionImagesPerRow);
    }
  }, []);
  const handleResize = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 576) {
      setWidth(100); // For extra small screens
    } else if (screenWidth < 768) {
      setWidth(120); // For small screens
    } else if (screenWidth < 900) {
      setWidth(130); // For medium small screens
    } else if (screenWidth < 1024) {
      setWidth(150); // For medium screens
    } else if (screenWidth < 1250) {
      setWidth(170); // For large screens
    } else if (screenWidth < 1440) {
      setWidth(200); // For extra large screens
    } else {
      setWidth(270); // For very large screens
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePreview = async (file: any) => {
    if (file.key && !file.OrgImageURl) {
      const res: any = await api.get(`upload-files/preview-image/${file.key}`)
      const OrgImageURl = `data:${file.mimeType};base64,${Buffer.from(res.Body).toString('base64')}`;
      for (const option of props.optionsMediaList) {
        const innerIndex = option.data.findIndex((item: any) => item.uid === file.uid);
        if (innerIndex !== -1) {
          const updatedList = [...option.data];
          updatedList[innerIndex] = { ...file, OrgImageURl };
          const updatedOptionsMediaList = props.optionsMediaList.map(optionItem => {
            if (optionItem === option) {
              return { ...option, data: updatedList };
            }
            return optionItem;
          });
          props.setOptionsMediaList(updatedOptionsMediaList);
        }
      }
      setPreviewSrc(OrgImageURl)
    }
    else {
      if (file.OrgImageURl) {
        setPreviewSrc(file.OrgImageURl)
      }
    }
  }
  const handleClosePreview = () => {
    setPreviewSrc("");
  };

  return (
    <>
      {(items[currentProject]?.projectInfo?.proposal?.addOptionMedia === true &&
        props.optionsMediaList.length > 0) &&
        props.optionsMediaList.map((item: any) => (
          <div key={item._id}>
            <h3 style={{ marginLeft: "0px", marginBottom: "10px" }}>
              {item.title}
            </h3>
            <div style={{ marginLeft: "20px" }}>
              {imagesPerRow === 2 ? (
                <Row gutter={5}>
                  {item.data.map((file: any) => (
                    <Col key={file.uid} span={24 / imagesPerRow}>
                      <Image
                        width={width * 1.9}
                        // height={width * 2.25 * 0.5625}
                        src={file?.thumbUrl ? file?.thumbUrl : "error"}
                        preview={{
                          src: previewSrc !== "" ? previewSrc : loadingGif,
                          onVisibleChange: (visible) => {
                            if (!visible) {
                              handleClosePreview();
                            }
                          }
                        }}
                        style={{ display: "inline-block" }}
                        onClick={() => { handlePreview(file) }}
                      />
                      <h1 className="file-text" style={{ fontSize: 14, textAlign: "center" }}>
                        {file.caption}
                      </h1>
                    </Col>
                  ))}
                </Row>
              ) : (
                <>
                  {imagesPerRow === 3 ? (
                    <Row gutter={5}>
                      {item.data.map((file: any) => (
                        <Col key={file.uid} span={24 / imagesPerRow}>
                          <Image
                            width={width * 1.3}
                            // height={width * 1.3 * 0.5625}
                            src={file?.thumbUrl ? file?.thumbUrl : "error"}
                            preview={{
                              src: previewSrc !== "" ? previewSrc : loadingGif,
                              onVisibleChange: (visible) => {
                                if (!visible) {
                                  handleClosePreview();
                                }
                              }
                            }}
                            style={{ display: "inline-block" }}
                            onClick={() => { handlePreview(file) }}
                          />
                          <h1
                            className="file-text"
                            style={{ fontSize: 14, textAlign: "center" }}
                          >
                            {file.caption}
                          </h1>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <Row gutter={5}>
                      {item.data.map((file: any) => (
                        <Col key={file.uid} span={24 / imagesPerRow}>
                          <Image
                            width={width * 1.1}
                            // height={width * 1.15 * 0.5625}
                            src={file?.thumbUrl ? file?.thumbUrl : "error"}
                            preview={{
                              src: previewSrc !== "" ? previewSrc : loadingGif,
                              onVisibleChange: (visible) => {
                                if (!visible) {
                                  handleClosePreview();
                                }
                              }
                            }}
                            style={{ display: "inline-block" }}
                            onClick={() => { handlePreview(file) }}
                          />
                          <h1
                            className="file-text"
                            style={{ fontSize: 14, textAlign: "center" }}
                          >
                            {file.caption}
                          </h1>
                        </Col>
                      ))}
                    </Row>
                  )}
                </>
              )}
            </div>
          </div>
        ))
      }
    </>
  );
};
export default OptionImages;
