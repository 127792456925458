import { Col, Row, Layout, Button } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ResourceNotCreated from "../../components/other/ResourceNotCreated";
import Notes from "../../containers/notes/Notes";
import Documents from "../../containers/pjcc/Document";
import PjccListing from "../../containers/pjcc/PjccListing";
import { setAppliedDiscount, setPjcc } from "../../redux/project-pjcc/action";
import { updateProjectDiscountAndGm } from "../../redux/project/action";
import { getAuthUser } from "../../utils/authToken";
import useActiveProject from "../../utils/useActiveProject";
import ManagerSteps from "./ManagerSteps";
import DiscountWarning from "../../containers/discount-warning/DiscountWarning";
import { customToFixed } from "../../utils/helpers";
import useSyncProjectData from "../../utils/useSyncProjectData";
import { Spin } from "antd";
import { useMediaQuery } from "react-responsive";
const { Content } = Layout;

type Props = {};

const ManagerPJCC = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:1150px)" });

  const { id } = useParams<{ id: string }>();
  const [isLoading] = useSyncProjectData(id);
  const history = useHistory();
  useActiveProject();
  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const [selectedProjectPjcc, setSelectedProjectPjcc] = useState(
    items[currentProject]?.pjcc ? items[currentProject]?.pjcc : []
  );
  const [projectAppliedDiscount, setProjectAppliedDiscount] = useState(
    items[currentProject].projectInfo.discounts
      ? items[currentProject].projectInfo.discounts
      : []
  );
  const [grossMarginAmount, setGrossMarginAmount] = useState(
    items[currentProject]?.pjcc[20]?.totalAmount -
      items[currentProject]?.pjcc[15]?.cost
  );
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
    setSelectedProjectPjcc(
      items[currentProject]?.pjcc
        ? items[currentProject]?.pjcc?.map((item: any) => {
            return {
              ...item,
              grossMargin: customToFixed(
                item?.grossMargin ? item?.grossMargin : 0,
                2
              ),
            };
          })
        : []
    );
  }, [items[currentProject].pjcc]);

  console.log("selectedProjectPjcc", selectedProjectPjcc);

  const next = () => {
    const updatedGm = selectedProjectPjcc
      .filter((item: any) => {
        if (
          item.index === 1 ||
          item.index === 2 ||
          item.index === 3 ||
          item.index === 5 ||
          item.index === 6 ||
          item.index === 7 ||
          item.index === 11 ||
          item.index === 12
        ) {
          return item;
        }
      })
      .map((item: any) => {
        return {
          name: item.name,
          margin: +item.grossMargin,
        };
      });
    // let body = projectAppliedDiscount?.map((item: any) => {
    //   return item._id;
    // });

    let project = _.cloneDeep(items[currentProject]);
    project.projectInfo.discounts = projectAppliedDiscount;
    let newBody = {
      discounts: projectAppliedDiscount,
    };
    dispatch(updateProjectDiscountAndGm(newBody, id, project, adminDefaults));
    history.push(`/manager-estimate-checklist/${id}`);
  };
  return (
    <>
      {isLoading === true ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <Content>
          <ManagerSteps current={4} />
          {grossMarginAmount < 0 && <DiscountWarning />}
          {items[currentProject]?.pjcc?.length === 0 ? (
            <ResourceNotCreated message="PJCC not created" url="/" />
          ) : (
            <>
              <div className="steps-content">
                {isTabletOrMobile ? (
                  <Row gutter={24}>
                    <Col className="gutter-row" lg={15} sm={24}>
                      <PjccListing
                        pjccData={selectedProjectPjcc}
                        setPjccData={setSelectedProjectPjcc}
                      />
                    </Col>
                    <Col className="gutter-row" lg={9} sm={24}>
                      <Documents
                        pjccData={selectedProjectPjcc}
                        setPjccData={setSelectedProjectPjcc}
                        projectAppliedDiscount={projectAppliedDiscount}
                        setProjectAppliedDiscount={setProjectAppliedDiscount}
                      />
                      <Notes />
                    </Col>
                  </Row>
                ) : (
                  <Row gutter={24}>
                    <Col className="gutter-row" lg={24} sm={24}>
                      <PjccListing
                        pjccData={selectedProjectPjcc}
                        setPjccData={setSelectedProjectPjcc}
                      />
                    </Col>
                    <Col className="gutter-row" lg={24} sm={24}>
                      <Documents
                        pjccData={selectedProjectPjcc}
                        setPjccData={setSelectedProjectPjcc}
                        projectAppliedDiscount={projectAppliedDiscount}
                        setProjectAppliedDiscount={setProjectAppliedDiscount}
                      />
                      <Notes />
                    </Col>
                  </Row>
                )}
                {isTabletOrMobile ? (
                  <Row>
                    <Col lg={6} md={10}></Col>
                    <Col style={{ marginBottom: "20px" }}>
                      <Button
                        type="primary"
                        className="save-button"
                        onClick={() => next()}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col lg={10} md={10}></Col>
                    <Col style={{ marginBottom: "20px" }}>
                      <Button
                        type="primary"
                        className="save-button"
                        onClick={() => next()}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </>
          )}
        </Content>
      )}
    </>
  );
};

export default ManagerPJCC;
