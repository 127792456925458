import { getAuthUser } from "./../utils/authToken";
import axios from "axios";
import { API_BASE } from "../constant";
import { storeUser } from "../utils/authToken";

const login = (email: string, password: string) => {
  return axios
    .post(`${API_BASE}user/login`, {
      email,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        storeUser(response.data);
      }
      return response.data;
    });
};

const register = (payload: any) => {
  const user = getAuthUser();
  return axios
    .post(`${API_BASE}user`, payload, {
      headers: { Authorization: "Bearer " + user.accessToken },
    })
    .then((response) => {
      return response.data;
    });
};
const logout = () => {
  localStorage.clear();
};
export default {
  register,
  login,
  logout,
};
