import React from 'react';
import Profiles from '../../containers/admin-user-profiles/Profiles';

type Props = {};

const UserProfiles = (props: Props) => {
  return (
    <div>
      <Profiles />
    </div>
  );
};

export default UserProfiles;
