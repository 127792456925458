import OtherDefaultsC from "../../containers/other-defaults/OtherDefaultsC";

type Props = {};

const OtherDefaults = (props: Props) => {
  return (
    <div>
      <OtherDefaultsC />
    </div>
  );
};

export default OtherDefaults;
