import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Input, Button, Alert, message } from 'antd';
import './Login.less';
import { useParams, useHistory } from 'react-router-dom';
import logo from "../../assets/images/Pro-Driven.jpg";
import axios from 'axios';
import { API_BASE } from '../../constant';

const ResetPassword = () => {
  const history = useHistory()
  const [enableForm, setEnableForm] = useState(false)
  const [email, setEmail] = useState(null)
  let verificationKeySource = useParams<{ verificationKey: any }>();
  const key = JSON.parse(JSON.stringify(verificationKeySource))
  console.log(key)
  useEffect(() => {
    axios.post(`${API_BASE}user/forgot-password-verify`, { verification: key.verficationkey })
      .then((res: any) => {
        setEnableForm(true);
        setEmail(res.data.email);
        message.info('Please Set Your New Password!')
      })
      .catch((err) => {
        console.log(err)
        message.warning('Verfication Failed!')
        history.push('/login')
      })
  }, [])
  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
    axios.post(`${API_BASE}user/reset-password`, { email: email, password: values.password })
      .then((res) => {
        message.success("Password is sucessfull set!")
        history.push('/login')
      })
      .catch((err) => {
        message.warning('Some Error had accoured!')
        console.log(err)
      })
  };
  return (
    <div className="background-container">
      {(enableForm !== false) &&
        <Row justify="center">
          <Col>
            <div className="certapro-logo">
              <img src={logo} alt="ProDriven Logo" style={{ width: 400, height: 85 }} />
            </div>

            <Card className="login-card">
              <Form
                onFinish={onFinish}
                scrollToFirstError
                layout="vertical"
                autoComplete="off"
              >
                <h1 className="login-title">Set New Password</h1>
                <div className="input-container" style={{ marginTop: '35px' }}>
                  <div>
                    <Form.Item
                      label={<h1 className="input-title">New Password</h1>}
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your password!',
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password className="input-field" />
                    </Form.Item>
                    <Form.Item
                      label={
                        <h1 className="input-title">Confirm New Password</h1>
                      }
                      name="confirm"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                'The two passwords that you entered do not match!'
                              )
                            );
                          },
                        }),
                      ]}
                      hasFeedback
                    >
                      <Input.Password className="input-field" />
                    </Form.Item>
                  </div>

                  <div className="login-btn-container">
                    <div>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login-btn "
                          style={{ marginTop: '12px' }}
                        >
                          Set New Password
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      }
    </div>
  );
};

export default ResetPassword;
