export const pricingData = [
  {
    key: '1',
    summary: 'Building 1',
    price: '$0',
    gm: '50%',
  },
  {
    key: '2',
    summary: 'Building 2',
    price: '$0',
    gm: '50%',
  },
  {
    key: '3',
    summary: 'Building 3',
    price: '$0',
    gm: '50%',
  },
  {
    key: '4',
    summary: 'Building 4',
    price: '$0',
    gm: '50%',
  },
  {
    key: '5',
    summary: 'Building 5',
    price: '$0',
    gm: '50%',
  },
  {
    key: '6',
    summary: 'Building 6',
    price: '$0',
    gm: '50%',
  },
];
