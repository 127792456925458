import React from 'react';
import { Row, Col } from 'antd';

const PreviewClient: React.FC<{
  name: string;
  telephone: string;
  title: string;
  cell: string;
  email: string;
  address: string;
}> = ({ name, telephone, cell, email, address, title }) => {
  return (
    <div>
      <div className="profile-content job-container form-container ">
        <div>
          <Row>
            <Col span={18}>
              <h1 className="project-type">{title}</h1>
            </Col>
          </Row>
        </div>
        <Row>
          <Col span={6}>
            <h4>Name</h4>
          </Col>
          <Col span={6}>
            <p>{name}</p>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <h4>Telephone</h4>
          </Col>
          <Col span={6}>
            <p>{telephone}</p>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <h4>Cell</h4>
          </Col>
          <Col span={6}>
            <p>{cell}</p>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <h4>Email</h4>
          </Col>
          <Col span={6}>
            <p>{email}</p>
          </Col>
        </Row>

        <Row>
          <Col span={6}>
            <h4>Address</h4>
          </Col>
          <Col span={6}>
            <p>{address}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PreviewClient;
