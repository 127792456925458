import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Form,
  Input,
  Upload,
  Tag,
  message,
  Avatar,
  Select,
} from "antd";
import AssociatesCard from "../../components/associates-card/AssociatesCard";
import "./Company.less";
import { useHistory } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";
import { EditUploadIcon, InvalidMediaIcon } from "../../utils/icons";
import { API_BASE } from "../../constant";
import ImgCrop from "antd-img-crop";
import { MaskedInput } from "antd-mask-input";
import axios from "axios";
import JobJacketContract from "../jobjacket-contract/JobJacketContract";
import { usStates, canadaStates } from "../../constant/index";
import { isNull } from "lodash";
import { getSelectedTenant } from "../../utils/authToken";

type Props = {};

const Company = (props: Props) => {
  const { adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const history = useHistory();

  const { Dragger } = Upload;

  const tenantId = getSelectedTenant();
  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  const { online } = useSelector((state: RootStateOrAny) => state.offline);

  const [form] = Form.useForm();
  const [isEdit, isEditSet] = useState(false);
  const [loading, setLoading] = useState(false);

  const [uploadAction, setUploadAction] = useState("");

  const [companyInfo, setCompanyInfo] = useState<any>({});
  const [companyLogo, setCompanyLogo] = useState("");

  const [states, setStates] = useState<any>([...usStates, ...canadaStates]);

  useEffect(() => {
    axios
      .get(`${API_BASE}defaults/company-info`, {
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "tenant-id": !isNull(tenantId) ? tenantId : "",
        },
      })
      .then((response: any) => {
        setCompanyInfo(response.data[0]);
        // setImageUrl(response.data[0].companyLogo);
        if (response.data.length !== 0) {
          setUploadAction(
            `${API_BASE}defaults/company-info/upload-pic/${response?.data[0]?._id}`
          );
          setCompanyLogo(response.data[0].companyLogo);
        } else {
          setUploadAction(`${API_BASE}defaults/company-info/upload-pic`);
        }
        form.setFieldsValue(response.data[0]);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const showEditForm = () => {
    isEditSet(true);
  };

  const onFinish = (values: any) => {
    if (!companyInfo) {
      axios
        .post(`${API_BASE}defaults/company-info`, values, {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
        })
        .then((response: any) => {
          setCompanyInfo(response.data);
          setUploadAction(
            `${API_BASE}defaults/company-info/upload-pic/${response?.data?._id}`
          );
          message.success("Company Profile Created");
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      axios
        .put(`${API_BASE}defaults/company-info/${companyInfo._id}`, values, {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
        })
        .then((response: any) => {
          setCompanyInfo(response.data);
          message.success("Company Profile Updated");
        })
        .catch((err: any) => {
          console.log(err);
        });
    }

    // isEditSet(false);
  };

  const onCancel = () => {
    isEditSet(false);
    form.resetFields();
  };

  function getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file: any) {
    const isPng = file.type === "image/png";
    if (!isPng) {
      message.error("You can only upload PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isPng && isLt2M;
  }

  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setCompanyInfo(info.file.response);
      getBase64(info.file.originFileObj, (imageUrl: any) => {
        return setCompanyLogo(imageUrl), setLoading(false);
      });
    }
  };

  const handleOnChange = (info: any) => {
    console.log(info);
  };

  const handleBeforeUpload = (file: File) => {
    const allowedTypes = ["image/png"];
    const isTypeAllowed = allowedTypes.includes(file.type);
    if (!isTypeAllowed) {
      message.error("Only PNG files are allowed");
    }

    return isTypeAllowed;
  };

  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "+" || e.key === "-" || e.key === "." || e.key === "e") {
      e.preventDefault();
    }
  };

  const handleCountry = (value: any) => {
    if (usStates.includes(value)) {
      form.setFieldsValue({
        companyCountry: "United States",
      });
    }
    if (canadaStates.includes(value)) {
      form.setFieldsValue({
        companyCountry: "Canada",
      });
    }
  };

  const cropSize = {
    width: 500,
    height: 500,
  };
  return (
    <div className="company-root">
      <h1>Company Info</h1>
      <hr style={{ border: "1px solid #E8E8E8", padding: 0, margin: 0 }} />

      <Row>
        <Col span={16}>
          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <h2
                style={{ marginTop: 30, marginLeft: 30 }}
                className="form-subtitle"
              >
                Company Logo
              </h2>
              {/* <div style={{ paddingTop: "4px", marginTop: 30 }}>
                <InvalidMediaIcon />
              </div> */}
            </div>
          </Row>
          <Row style={{ marginLeft: 30 }} justify="start">
            {/* <ImgCrop cropShape="rect" fillColor='#fdb913' aspectSlider={true}> */}
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-upload"
              action={uploadAction}
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              headers={{
                Authorization: "Bearer " + user.accessToken,
                "tenant-id": !isNull(tenantId) ? tenantId : "",
              }}
            >
              {companyLogo ? (
                <div className="company--logo">
                  <Avatar
                    src={companyLogo}
                    shape={"square"}
                    alt="avatar"
                    style={{ width: 200, height: 80 }}
                  />
                </div>
              ) : (
                <EditUploadIcon
                  style={{ position: "relative", bottom: -25, left: 98 }}
                />
              )}
            </Upload>
            {/* </ImgCrop> */}
          </Row>
          <div className="setting-profile">
            <Form
              onFinish={onFinish}
              form={form}
              scrollToFirstError
              layout="vertical"
            >
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item
                    name="companyName"
                    label={<h3>Company Name</h3>}
                    rules={[
                      { required: true, message: "Please enter full name" },
                      checkEmpty,
                    ]}
                  >
                    <Input className="setting-input" placeholder="Type" />
                  </Form.Item>
                </Col>
                {/* <Col span={12}>
                  <Form.Item
                    name="companyAddress"
                    label={<h3>Address</h3>}
                    // rules={[{ type: "email", message: "Enter valid email" }]}
                  >
                    <Input
                      //   disabled
                      className="setting-input"
                      placeholder="Type"
                    />
                  </Form.Item>
                </Col> */}
              </Row>
              <Row gutter={20}>
                <Col span={12}>
                  <div>
                    <Form.Item
                      //   initialValue={
                      //     items[currentProject]?.projectInfo?.streetAddressOne
                      //   }
                      hasFeedback
                      name="companyStreetAddressOne"
                      label={<h3 className="form-text">Street Address 1</h3>}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Street Address!",
                        },
                        checkEmpty,
                      ]}
                    >
                      <Input className="form-input" />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Form.Item
                      //   initialValue={
                      //     items[currentProject]?.projectInfo?.streetAddressTwo
                      //   }
                      name="companyStreetAddressTwo"
                      label={
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <h3 className="form-text">Street Address 2</h3>
                          <p
                            style={{
                              color: "#CACACA",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            (optional)
                          </p>
                        </div>
                      }
                      rules={[checkEmpty]}
                    >
                      <Input className="form-input" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={12}>
                  <div>
                    <Form.Item
                      //   initialValue={items[currentProject]?.projectInfo?.city}
                      hasFeedback
                      name="companyCity"
                      label={<h3 className="form-text">City</h3>}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the City!",
                        },
                        checkEmpty,
                      ]}
                    >
                      <Input className="form-input" />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="EditJobsite--state--dropdown">
                    <Form.Item
                      //   initialValue={items[currentProject]?.projectInfo?.state}
                      hasFeedback
                      name="companyStateProvince"
                      label={<h3 className="form-text">State</h3>}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the State!",
                        },
                        checkEmpty,
                      ]}
                    >
                      <Select
                        showSearch
                        onChange={handleCountry}
                        className="select-field"
                        placeholder="State"
                        size="large"
                        filterOption={(input: any, option: any) =>
                          (option?.children?.toString() ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {states?.sort().map((item: any, index: number) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </Select>
                      {/* <Input className="form-input" /> */}
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={12}>
                  <div>
                    <Form.Item
                      hasFeedback
                      name="companyPostalCode"
                      label={<h3 className="form-text">Zip</h3>}
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Zip code!",
                        },
                        {
                          max: 5,
                          message: "Code cannot exceed 5 digits",
                        },
                        checkEmpty,
                      ]}
                    >
                      <Input
                        type="number"
                        min={0}
                        className="form-input"
                        onKeyDown={handleKeyDown}
                        onPaste={(e: any) => {
                          const pastedText = e.clipboardData.getData("text");
                          const parsedText = parseFloat(pastedText);
                          if (pastedText < 0) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <Form.Item
                      hasFeedback
                      name="companyCountry"
                      label={<h3 className="form-text">Country</h3>}
                    >
                      {/* <Select
                          onChange={handleCountry}
                          className="select-field"
                          placeholder="Country"
                          size="large"
                        >
                          <Option value={"Canada"}>Canada</Option>
                          <Option value={"United States"}>United States</Option>
                        </Select> */}
                      <Input className="form-input" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item name="officePhone" label={<h3>Telephone</h3>}>
                    <MaskedInput
                      mask={"(000) 000-0000"}
                      className="setting-input"
                      placeholder="Type"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="companyWebsiteUrl" label={<h3>Website</h3>}>
                    <Input className="setting-input" placeholder="Type" />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ marginTop: 30 }} justify="center">
                <Col>
                  <Form.Item>
                    <Button
                      //   disabled={saveBtnDisable}
                      className="setting-save-btn"
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Company;
