import { Col, Input, Rate, Row, Table } from "antd";
import { defaultTo } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { RectangleIcon } from "../../utils/icons";

type Props = {
  washDetails: any;
  handleWashDetails: any;
  washRates: any;
  setOverRideWashSqft: any;
  washIncluded: any;
};

const Wash = (props: Props) => {
  const [washColor, setWashColor] = useState("");
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      calculateWashHours();
    } else {
      isMounted.current = true;
    }
  }, [props.washDetails?.rate, props.washDetails?.squareFeet]);

  useEffect(() => {
    if (
      props.washIncluded &&
      props?.washDetails?.diffLevel !== undefined
    )
      setColor(props?.washDetails?.diffLevel);
  }, [props?.washDetails?.diffLevel]);

  const setColor = (value: number) => {
    switch (value) {
      case 0:
        setWashColor("#d1cec4");
        break;
      case 1:
        setWashColor("#00B44F");
        break;
      case 2:
        setWashColor("#FDB913");
        break;
      case 3:
        setWashColor("#FF0000");
        break;
      default:
        if (
        props?.washDetails?.diffLevel !== undefined
      )
        setColor(props?.washDetails?.diffLevel);
    }
  };

  const handleHover = (level: number) => {
    setColor(level);
  };

  const onHandleDiffChange = (value: any) => {
    const newData = { ...props.washDetails };
    newData.diffLevel = value;

    switch (value) {
      case 1:
        newData.rate = props.washRates.easy;
        break;
      case 2:
        newData.rate = props.washRates.medium;
        break;
      case 3:
        newData.rate = props.washRates.hard;
        break;
      default:
        break;
    }

    props.handleWashDetails(newData);
  };

  const calculateWashHours = () => {
    const newData = { ...props.washDetails };
    newData.totalHours = parseFloat(
      (newData.squareFeet / newData.rate).toFixed(2)
    );
    // newData.totalHoursOverride = newData.totalHours;
    props.handleWashDetails(newData);
  };

  const handleWashSquareFeet = (e: any) => {
    if (e.target.value >= 0) {
      const newData = { ...props.washDetails };
      newData.squareFeet = e.target.value;
      props.setOverRideWashSqft();
      props.handleWashDetails(newData);
      // calculateWashHours()
    }
  };

  const handleRateChange = (e: any) => {
    if (e.target.value >= 0) {
      const newData = { ...props.washDetails };
      newData.rate = e.target.value;
      newData.diffLevel = 0;
      props.handleWashDetails(newData);
    }
  };

  const handleTotalHours = (e: any) => {
    if (e.target.value >= 0) {
      const newData = { ...props.washDetails };
      newData.totalHours = e.target.value;
      // newData.totalHoursOverride = e.target.value;
      props.handleWashDetails(newData);
    }
  };

  const data = [
    {
      wash: "Wash",
    },
  ];

  const columns = [
    {
      title: "Wash",
      dataIndex: "wash",
      key: "wash",
    },
    {
      title: "SQFT",
      dataIndex: "sqft",
      key: "sqft",

      render: (data: any) => {
        return (
          <div className="table--editor--holder">
            <Input
              onChange={handleWashSquareFeet}
              value={props.washDetails?.squareFeet}
            />
          </div>
        );
      },
    },
    {
      title: "Diff Level",
      dataIndex: "diffLevel",
      key: "diffLevel",

      render: (item: any) => {
        return (
          <Row>
            <Col className="gutter-row">
              <div className="set-opacity">
                <Rate
                  allowClear={false}
                  count={3}
                  onHoverChange={handleHover}
                  style={{ color: washColor }}
                  value={props.washDetails?.diffLevel}
                  onChange={onHandleDiffChange}
                  character={<RectangleIcon />}
                />
              </div>
            </Col>
          </Row>
        );
      },
    },

    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",

      render: (data: any) => {
        return (
          <div className="table--editor--holder">
            <Input
              onChange={handleRateChange}
              value={props.washDetails?.rate}
            />
          </div>
        );
      },
    },
    {
      title: "Wash Hours",
      dataIndex: "washHours",

      render: (data: any) => {
        return (
          <div className="table--editor--holder">
            <Input
              // onChange={handleTotalHours}
              value={props.washDetails?.totalHours}
            />
          </div>
        );
      },
    },
  ];

  return <Table dataSource={data} columns={columns} pagination={false} />;
};

export default Wash;
