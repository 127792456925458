import {
  Modal,
  Button,
  Select,
  Input,
  List,
  message,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../utils/api";

interface ProjectMigrationModalProps {
  selectedProjectList: any[];
  tenants: any[];
  fetchTenantProjects: () => void;
  isMigrateModalVisible: boolean;
  setIsMigrateModalVisible: (value: boolean) => void;
}

const { Title } = Typography;
const { Option } = Select;

export default function MigrateProjectModal({
  selectedProjectList,
  tenants,
  fetchTenantProjects,
  isMigrateModalVisible,
  setIsMigrateModalVisible
}: ProjectMigrationModalProps) {
  const { id } = useParams<{ id: string }>();
  const [currentTenant, setCurrentTenant] = useState<any>({});
  const [otherTenants, setOtherTenants] = useState<any[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<string | null>(null);

  useEffect(() => {
    const currentTenant = tenants?.find((tenant: any) => tenant._id === id);
    const otherTenants = tenants?.filter((tenant: any) => tenant._id !== id);
    setCurrentTenant(currentTenant);
    setOtherTenants(otherTenants);
  }, [tenants, id]);


  const MigrateProject = async () => {
    if (!selectedTenant) {
      message.error("Please select a tenant to migrate to.");
      return;
    }

    const projectIds = selectedProjectList.map((project) => project._id);
    const body = {
      currentTenantId: currentTenant._id,
      targetTenantId: selectedTenant,
      projectIds,
    };

    try {
      await api.post("tenants/migration", body)
      message.success("Projects migrated successfully!");
      setIsMigrateModalVisible(false);
      onModalClose();
      fetchTenantProjects();
    } catch (error) {
      message.error("Failed to migrate projects. Please try again.");
      console.error(error);
    }

  };

  const disableApplyButton = () => {
    if (selectedProjectList.length > 0 && selectedTenant) {
      return false;
    } else {
      return true;
    }
  }

  const onModalClose = () => {
    setIsMigrateModalVisible(false);
    setSelectedTenant(null);
  }

  return (
    <Modal
      title={"Project Migration"}
      visible={isMigrateModalVisible}
      onCancel={onModalClose}
      destroyOnClose={true}
      footer={[
        <>
          <Button
            key="close"
            onClick={onModalClose}
            style={{
              color: "black",
              paddingInline: "30px",
              borderRadius: "8px",
              height: "40px",
            }}
          >
            Cancel
          </Button>
          <Button
            key="apply"
            type="primary"
            style={{
              color: "black",
              paddingInline: "30px",
              borderRadius: "8px",
              height: "40px",
            }}
            onClick={MigrateProject}
            disabled={disableApplyButton()}
          >
            Apply
          </Button>
        </>,
      ]}
    >
      <div>
        <div style={{ marginBottom: 8, display: "flex", alignItems: "center" }}>
          <Title level={5} style={{ margin: 0 }}>
            Current Tenant
          </Title>
          <Input
            value={currentTenant?.companyName}
            readOnly
            style={{ marginLeft: 10, flex: 1 }}
          />
        </div>

        <div style={{ marginBottom: 8, display: "flex", alignItems: "center" }}>
          <Title level={5} style={{ margin: 0 }}>
            Migrate To
          </Title>
          <Select
            style={{ width: 355, marginLeft: 40 }}
            onSelect={(value) => setSelectedTenant(value)}
            placeholder="Select a tenant"
          >
            {otherTenants?.map((item) => (
              <Option key={item?._id} value={item._id}>
                {item.companyName}
              </Option>
            ))}
          </Select>
        </div>
        <label style={{ fontWeight: "bold" }}>
          Following Project are selected for Migration:
        </label>
        <div
          style={{
            height: 300,
            overflowY: "scroll",
            paddingRight: 16,
            border: "1px solid #D3D3D3",
            padding: 5,
            borderRadius: 5,
          }}
        >
          <List
            itemLayout="horizontal"
            dataSource={selectedProjectList}
            renderItem={(item) => (
              <List.Item key={item._id}>{item.jobName}</List.Item>
            )}
          />
        </div>
      </div>
    </Modal >
  );
}
