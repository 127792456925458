import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "antd";
import { useDispatch } from "react-redux";
import { WarningFilled } from "@ant-design/icons";
import "./ErrorBannerModal.less";
import { clearError } from "../../redux/project/action";

type Props = {
  error: any;
};

const ErrorBannerModal = (props: Props) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.error) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [props.error]);
  const onClose = () => {
    setShowModal(false);
    dispatch(clearError());
  };
  return (
    <Modal
      title={
        <Row style={{ color: "red" }} gutter={5}>
          <Col>
            <WarningFilled style={{ fontSize: 20, color: "#e54b53" }} />
          </Col>
          <Col>
            <span
              style={{ color: "#1f1f1f", fontSize: "20px", fontWeight: "bold" }}
            >
              Error
            </span>
          </Col>
        </Row>
      }
      open={showModal}
      onCancel={onClose}
      onOk={onClose}
      okText="OK"
      closable={false}
      className="error-modal"
      footer={[
        <Button
          key="cancel"
          onClick={onClose}
          style={{
            marginRight: 8,
            color: "#FDB913",
            border: "1px solid #fdb913",
          }}
        >
          Cancel
        </Button>,
        <Button
          style={{ color: "#1F1F1F" }}
          key="submit"
          type="primary"
          onClick={onClose}
        >
          Ok
        </Button>,
      ]}
    >
      {props.error?.map((item: any, index: number) => {
        return (
          <div key={index} style={{ padding: 20 }}>
            <p style={{ fontWeight: "bold" }}>Error No:{++index}</p>
            <p>Error Type: {item?.errorType?.message}</p>
            <p className="error-message">{item?.message}</p>
          </div>
        );
      })}
    </Modal>
  );
};

export default ErrorBannerModal;
