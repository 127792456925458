import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Button,
  TimePicker,
  DatePicker,
  Row,
  Col,
  Select,
} from "antd";
import { MaskedInput } from "antd-mask-input";
import "../forms/CreateForm.less";
import "./EditJobsite.less";
import moment from "moment";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { updateProjectById } from "../../redux/project/action";
const { Option } = Select;

type Props = {};

const EditTracking: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch<any>();
  const [subSegments, setSubSegments] = useState([]);
  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const [form] = Form.useForm();
  const [isEdit, isEditSet] = useState(false);

  useEffect(() => {
    if (items[currentProject]?.projectInfo?.segment) {
      setSubSegments(items[currentProject]?.projectInfo?.segment.subSegments);
    }
  }, []);

  const checkJobNumber = {
    validator(_: any, value: string) {
      if (value) {
        if (value.endsWith("_")) {
          return Promise.reject(new Error("Please enter 8 digit number"));
        }
      }

      return Promise.resolve();
    },
  };

  const onFinish = (values: any) => {
    let estimateDate = moment();

    if (values.estimateDate && values.estimateTime) {
      estimateDate = estimateDate
        .startOf("day")
        .add(values.estimateTime.hours(), "hours")
        .add(values.estimateTime.minutes(), "minutes");
    }
    const findSegment = adminDefaults.marketSegments.find(
      (item: any) => item._id === values.segment
    );
    const data = {
      ...items[currentProject]?.projectInfo,
      segment: findSegment || values.segment,
      subSegment: values.subSegment,
      leadDate: values.leadDate.valueOf(),
      estimateScheduledDate: estimateDate.valueOf(),
      jobNumber: values.jobNumber,
      projectType: values.projectType,
      projectSubtype: values.projectSubtype,
    };

    dispatch(updateProjectById(data));
    isEditSet(false);
  };

  const showEditForm = () => {
    isEditSet(true);
  };

  const onCancel = () => {
    isEditSet(false);
    form.resetFields();
  };

  const handleSegment = (value: any) => {
    const segment = adminDefaults?.marketSegments?.find(
      (item: any) => item._id === value
    );

    if (segment) {
      setSubSegments(segment.subSegments);
      form.setFieldsValue({
        subSegment: "",
      });
    }
  };

  return (
    <div>
      <Card className="root-container">
        {!isEdit ? (
          <div className="profile-content job-container form-container ">
            <div>
              <Row justify="space-between">
                <Col>
                  <h1 className="form-subtitle">Tracking</h1>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <p className="edit--text" onClick={showEditForm}>
                    Edit
                  </p>
                </Col>
              </Row>
            </div>
            <Row>
              <Col span={6}>
                <h4>Job type</h4>
              </Col>
              <Col span={6}>
                <p>{items[currentProject]?.projectInfo?.projectType}</p>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <h4>Job Sub-type</h4>
              </Col>
              <Col span={6}>
                <p>{items[currentProject]?.projectInfo?.projectSubtype}</p>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <h4>Segment</h4>
              </Col>
              <Col span={6}>
                <p>
                  {items[currentProject]?.projectInfo?.segment?.segmentName}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <h4>Sub Segment</h4>
              </Col>
              <Col span={6}>
                <p>{items[currentProject]?.projectInfo?.subSegment}</p>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <h4>RPF recieved</h4>
              </Col>
              <Col span={6}>
                <p>
                  {moment(items[currentProject]?.projectInfo?.leadDate).format(
                    "MM-DD-YYYY"
                  )}
                </p>
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <h4 style={{ overflowWrap: "anywhere", width: "80%" }}>
                  Estimate schedule date
                </h4>
              </Col>
              <Col span={6}>
                <p>
                  {moment(
                    items[currentProject]?.projectInfo?.estimateScheduledDate
                  ).format("MM-DD-YYYY")}
                </p>
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <h4>C1 Job Number</h4>
              </Col>
              <Col span={6}>
                <p>{items[currentProject]?.projectInfo?.jobNumber}</p>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="job-container form-container ">
            <Form
              layout="vertical"
              onFinish={onFinish}
              form={form}
              scrollToFirstError
              autoComplete="off"
            >
              <h1 className="project-type">Tracking</h1>

              <div className="job-form">
                <Row gutter={17}>
                  <Col span={12}>
                    <div>
                      <Form.Item
                        initialValue={
                          items[currentProject]?.projectInfo?.projectType
                        }
                        name="projectType"
                        label={<h1 className="form-text">Job Type</h1>}
                      >
                        <Select
                          size="large"
                          className="select-field"
                          placeholder="Job Type"
                        >
                          <Option value="Commercial">Commercial</Option>
                          <Option value="Residential">Residential</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      <Form.Item
                        initialValue={
                          items[currentProject]?.projectInfo?.projectSubtype
                        }
                        name="projectSubtype"
                        label={<h1 className="form-text">Job Sub Type</h1>}
                      >
                        <Select
                          size="large"
                          className="select-field"
                          placeholder="Sub Segment"
                        >
                          <Option value="Interior">Interior</Option>
                          <Option value="Exterior">Exterior</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row gutter={17}>
                  <Col span={12}>
                    <div>
                      <Form.Item
                        initialValue={
                          items[currentProject]?.projectInfo?.segment?._id
                        }
                        name="segment"
                        label={<h1 className="form-text">Segment</h1>}
                      >
                        <Select
                          onChange={handleSegment}
                          size="large"
                          className="select-field"
                          placeholder="Segment"
                        >
                          {adminDefaults?.marketSegments?.map((item: any) => {
                            return (
                              <Option key={item?._id} value={item?._id}>
                                {item?.segmentName}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      <Form.Item
                        initialValue={
                          items[currentProject]?.projectInfo?.subSegment
                        }
                        name="subSegment"
                        label={<h1 className="form-text">Sub Segment</h1>}
                      >
                        <Select
                          size="large"
                          className="select-field"
                          placeholder="Sub Segment"
                        >
                          {subSegments?.map((item: any, index: number) => {
                            return (
                              <Option key={index} value={item}>
                                {item}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <div className="form-item">
                  <Row gutter={17}>
                    <Col className="estimate-container" span={12}>
                      <Row>
                        <Col>
                          <h1 className="form-text">Estimate scheduled</h1>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 8 }} gutter={20}>
                        <Col span={12}>
                          <Form.Item
                            initialValue={moment(
                              items[currentProject]?.projectInfo
                                ?.estimateScheduledDate
                            )}
                            name="estimateDate"
                          >
                            <DatePicker
                              disabledDate={(current) => {
                                return moment().add(-1, "days") >= current;
                              }}
                              className="estimate-date"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            initialValue={moment(
                              items[currentProject]?.projectInfo
                                ?.estimateScheduledDate
                            )}
                            name="estimateTime"
                          >
                            <TimePicker
                              className="estimate-date"
                              use12Hours
                              format="h:mm:ss A"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <div className="form-item">
                        <Form.Item
                          name="leadDate"
                          initialValue={moment(
                            items[currentProject]?.projectInfo?.leadDate
                          )}
                          label={<h1 className="form-text">Lead Date</h1>}
                          rules={[
                            { required: true, message: "Please select!" },
                          ]}
                        >
                          <DatePicker className="pp-lead-date" />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <div>
                        <Form.Item
                          initialValue={
                            items[currentProject]?.projectInfo?.jobNumber
                          }
                          name="jobNumber"
                          label={<h1 className="form-text">C1 Job Number</h1>}
                          rules={[
                            {
                              required: true,
                              message: "Please enter the number!",
                            },
                            checkJobNumber,
                          ]}
                          hasFeedback
                        >
                          <MaskedInput
                            mask={"JOB-0000-0000"}
                            className="form-input"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="btn-container">
                <div>
                  <Button
                    onClick={onCancel}
                    style={{
                      border: "none",
                      color: "#FDB913",
                      width: "94px",
                      height: "40px",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button
                    style={{
                      border: "none",
                      color: "#1F1F1F",
                      width: "94px",
                      height: "40px",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Card>
    </div>
  );
};

export default EditTracking;
