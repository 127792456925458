import { getAuthUser } from "./../../utils/authToken";
import axios from "axios";
import { API_BASE } from "../../constant";
import api from "../../utils/api";

export const setSetupDefaults = (payload: any) => ({
  type: "SET_SETUP_DEFAULTS",
  payload,
});

export const setSurfaceDefaults = (payload: any) => ({
  type: "SET_SURFACE_DEFAULTS",
  payload,
});

export const fetchSetupDefaults = (jobType: string) => (dispatch: any) => {
  api
    .get(`proposal/setup/${jobType}`)
    .then((response: any) => {
      dispatch(setSetupDefaults(response));
    })
    .catch((err) => {
      console.error(err);
    });
};

export const fetchSurfaceDefaults = (jobType: string) => (dispatch: any) => {
  const user = getAuthUser();
  api
    .get(`proposal/surface/${jobType}`)
    .then((response: any) => {
      dispatch(setSurfaceDefaults(response));
    })
    .catch((err) => {
      console.error(err);
    });
};
