import { Button, Col, Input, Modal, Row, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { SaveOutlined, UndoOutlined } from "@ant-design/icons";
import _ from "lodash";
import {
  removeProjectMaterialFromProject,
  updateProjectPaintMaterials,
} from "../../redux/project/action";
import { useParams } from "react-router-dom";
var crypto = require("crypto");
type Props = {
  showModal: boolean;
  toggleModal: () => void;
};

const ProjectMaterialsC = (props: Props) => {
  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [paintMaterials, setPaintMaterials] = useState<any>([]);
  const [primerMaterials, setPrimerMaterials] = useState<any>([]);

  useEffect(() => {
    setPaintMaterials(filterUniqueMaterials("paintMaterial"));
    setPrimerMaterials(filterUniqueMaterials("primerMaterial"));
  }, [items[currentProject]?.projectMaterials, props.showModal]);

  const filterUniqueMaterials = (material: string) => {
    const allPaintMaterials = items[currentProject].options
      .map((option: any) =>
        option.rates.paintRates.map((item: any) => {
          if (material === "primerMaterial") {
          }
          return item[material];
        })
      )
      .reduce((acc: any, val: any) => acc.concat(val), [])
      .filter((item: any) => item);
    const uniquePaintMaterials = [
      ...new Map(
        allPaintMaterials.map((item: any) => [item["_id"], item])
      ).values(),
    ];
    const findMaterials = uniquePaintMaterials.map((item: any) => {
      const element = items[currentProject]?.projectMaterials?.find(
        (material: any) => material.defaultMaterialId === item._id
      );

      return {
        ...item,
        priceAfterTax: element ? element.price : item?.priceAfterTax,
      };
    });
    return findMaterials;
  };

  const onPaintMaterialChange = (e: any, data: any, index: number) => {
    const newData = _.cloneDeep(paintMaterials);
    newData[index].priceAfterTax = e.target.value;
    setPaintMaterials(newData);
  };

  const onPrimerMaterialChange = (e: any, data: any, index: number) => {
    const newData = _.cloneDeep(primerMaterials);
    newData[index].priceAfterTax = e.target.value;
    setPrimerMaterials(newData);
  };

  const handlePaintSave = (data: any, index: number) => {
    let body: any = {
      defaultMaterialId: data?._id,
      projectId: id,
      price: parseFloat(data?.priceAfterTax),
      material: data?.material,
    };
    const projectClone = _.cloneDeep(items[currentProject]);
    const findMaterial = projectClone.projectMaterials?.find(
      (item: any) => item.defaultMaterialId === data._id
    );

    let updatedPaintMaterials;
    if (findMaterial) {
      body._id = findMaterial._id;
      updatedPaintMaterials = projectClone.projectMaterials?.map(
        (material: any) => {
          if (material._id === body._id) {
            return body;
          }
          return material;
        }
      );
    } else {
      body._id = crypto.randomBytes(12).toString("hex");
      updatedPaintMaterials = [...projectClone.projectMaterials, body];
    }

    projectClone.projectMaterials = updatedPaintMaterials;
    dispatch(
      updateProjectPaintMaterials(id, body, projectClone, adminDefaults)
    );
  };

  const handleMaterialDelete = (data: any) => {
    const projectClone = _.cloneDeep(items[currentProject]);

    const findMaterial = projectClone.projectMaterials?.find(
      (item: any) => item.defaultMaterialId === data._id
    );
    const updatedPaintMaterials = projectClone.projectMaterials?.filter(
      (item: any) => item.defaultMaterialId !== data._id
    );

    projectClone.projectMaterials = updatedPaintMaterials;

    if (findMaterial) {
      dispatch(
        removeProjectMaterialFromProject(
          findMaterial._id,
          projectClone,
          adminDefaults
        )
      );
    }
  };

  const paintColumns = [
    {
      title: "Product",
      dataIndex: "product",
      width: "50%",
      render: (data: string) => {
        return <p>{data}</p>;
      },
    },
    {
      title: "Price",
      render: (data: any, _: any, index: number) => {
        return (
          <Row align="middle" gutter={10}>
            <Col>
              <Input
                type="number"
                style={{ width: 120 }}
                value={data.priceAfterTax}
                onChange={(e: any) => onPaintMaterialChange(e, data, index)}
              />
            </Col>
            <Col>
              <Tooltip arrowPointAtCenter placement="topLeft" title="Update">
                <SaveOutlined
                  onClick={() => handlePaintSave(data, index)}
                  style={{ color: "#FDB913", cursor: "pointer", fontSize: 17 }}
                />
              </Tooltip>
            </Col>
            <Col>
              <Tooltip placement="right" title="Reset">
                <UndoOutlined
                  style={{ color: "#FDB913", cursor: "pointer", fontSize: 17 }}
                  onClick={() => handleMaterialDelete(data)}
                />
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  const primerColumns = [
    {
      title: "Product",
      dataIndex: "product",
      width: "50%",
      render: (data: string) => {
        return <p>{data}</p>;
      },
    },
    {
      title: "Price",
      render: (data: any, _: any, index: number) => {
        return (
          <Row align="middle" gutter={10}>
            <Col>
              <Input
                type="number"
                style={{ width: 120 }}
                value={data.priceAfterTax}
                onChange={(e: any) => onPrimerMaterialChange(e, data, index)}
              />
            </Col>
            <Col>
              <Tooltip arrowPointAtCenter placement="topLeft" title="Update">
                <SaveOutlined
                  onClick={() => handlePaintSave(data, index)}
                  style={{ color: "#FDB913", cursor: "pointer", fontSize: 17 }}
                />
              </Tooltip>
            </Col>
            <Col>
              <Tooltip placement="right" title="Reset">
                <UndoOutlined
                  style={{ color: "#FDB913", cursor: "pointer", fontSize: 17 }}
                  onClick={() => handleMaterialDelete(data)}
                />
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Modal
      width="600px"
      title={<h1 className="send-title-text">Update Materials</h1>}
      onOk={props.toggleModal}
      onCancel={props.toggleModal}
      visible={props.showModal}
      footer={[
        <Button
          key="cancel"
          onClick={props.toggleModal}
          style={{
            marginRight: 8,
            color: "#FDB913",
            border: "1px solid #fdb913",
          }}
        >
          Cancel
        </Button>,
        <Button
          style={{ color: "#1F1F1F" }}
          key="submit"
          type="primary"
          onClick={props.toggleModal}
        >
          Ok
        </Button>,
      ]}
    >
      <div>
        <h1 style={{ fontWeight: 700, paddingLeft: 20, fontSize: 18 }}>
          Paint Materials
        </h1>

        <Table
          scroll={{ y: 200 }}
          rowKey={(_: any, index: any) => index.toString()}
          pagination={false}
          rowClassName={(record, index) =>
            index % 2 === 0
              ? "table_row table-row-light"
              : "table_row table-row-dark"
          }
          columns={paintColumns}
          dataSource={paintMaterials}
        />

        <h1 style={{ fontWeight: 700, paddingLeft: 20, fontSize: 18 }}>
          Primer Materials
        </h1>

        <Table
          scroll={{ y: 200 }}
          rowKey={(_: any, index: any) => index.toString()}
          pagination={false}
          rowClassName={(record, index) =>
            index % 2 === 0
              ? "table_row table-row-light"
              : "table_row table-row-dark"
          }
          columns={primerColumns}
          dataSource={primerMaterials}
        />
      </div>
    </Modal>
  );
};

export default ProjectMaterialsC;
