import React from 'react';
import LaborRatesDefaultsC from '../../containers/labor-rates-default/LaborRatesDefaultsC';

type Props = {};

const LaborRatesDefaults = (props: Props) => {
  return (
    <div>
      <LaborRatesDefaultsC />
    </div>
  );
};

export default LaborRatesDefaults;
