import moment from "moment";
import { Tag, Menu, Avatar, Button, Dropdown, message } from "antd";
import { MoreActionIcon } from "../../utils/icons";
import { Link } from "react-router-dom";
import axios from "axios";
import api from "../../utils/api";

export const columns = [
  {
    title: "Project Name",
    sorter: (a: any, b: any) => a.jobName.localeCompare(b.jobName),
    render: (data: any) => {
      return (
        <>
          <Link to={`/manager-preview-profile/${data._id}`}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ paddingTop: "7px" }}>
                <img
                  src={`data:image/svg+xml;base64,${Buffer.from(
                    data?.segment?.segmentIcon
                  ).toString("base64")}`}
                  alt="project"
                  width="39"
                  height="39"
                />
              </div>

              <div style={{ paddingLeft: "15px" }}>
                <p
                  style={{
                    margin: "0",
                    color: " #1F1F1F",
                    fontSize: "16px",
                    fontStyle: "normal",
                  }}
                >
                  {data.jobName}
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    color: "rgba(51, 51, 51, 0.7)",
                  }}
                >
                  {data.jobNumber}
                </p>
              </div>
            </div>
          </Link>
        </>
      );
    },
  },
  {
    title: "Status",
    // dataIndex: 'projectType',
    sorter: (a: any, b: any) => a.projectType.localeCompare(b.projectType),
    render: (data: any) => {
      return (
        <div>
          <p
            style={{
              fontSize: "16px",
              color: " #1F1F1F",
              // margin: "0",
            }}
          >
            {data.projectStatus}
          </p>
          {/* <p
            style={{
              fontSize: "12px",
              color: "rgba(51, 51, 51, 0.7)",
            }}
          >
            {data.projectSubtype}
          </p> */}
        </div>
      );
    },
    filters: [
      {
        text: "New Lead",
        value: "New Lead",
      },
      {
        text: "Proposal Pending",
        value: "Proposal Pending",
      },
      {
        text: "Proposal Completed",
        value: "Proposal Completed",
      },
    ],
    onFilter: (value: any, record: any) => {
      return record.projectStatus?.indexOf(value) === 0;
    },
  },

  {
    title: "Status",
    dataIndex: "status",
    sorter: (a: any, b: any) => a.status.localeCompare(b.status),

    filters: [
      {
        text: "New Lead",
        value: "New Lead",
      },
      {
        text: "In Progress",
        value: "In Progress",
      },
    ],
    onFilter: (value: any, record: any) => {
      return record.status.indexOf(value) === 0;
    },
    render: (tag: any) => {
      const color = tag?.includes("New Lead")
        ? "rgba(0, 180, 79, 0.19)"
        : "blue";
      return (
        <Tag
          style={{
            color: "black",
            borderRadius: "25px",
            width: "71px",
            height: "24px",
            textAlign: "center",
            fontSize: "12px",
            fontWeight: "500",
          }}
          color={color}
          key={tag}
        >
          {tag}
        </Tag>
      );
    },
  },
  {
    title: "Last Modified At",
    dataIndex: "updatedAt",
    sorter: (a: any, b: any) => a.updatedAt - b.updatedAt,
    render: (data: any) => {
      let value = data;
      if (value.toString().length === 13) {
        value = Math.floor(value / 1000);
      }
      return (
        <p style={{ fontSize: "16px" }}>
          {moment(value * 1000).format("MM/DD/YYYY")}
        </p>
      );
    },
  },
  {
    title: "Action",
    render: (data: any) => {
      return (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item className="project-menu-items" key={1}>
                <Link
                  to={
                    data.projectState === "Draft"
                      ? `/update-profile/${data._id}`
                      : `/preview-profile/${data._id}`
                  }
                >
                  <span className="nav-text">
                    <h1>Edit Profile</h1>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item
                className="project-menu-items"
                // onClick={() => {
                //   const body = {
                //     url: `https://cppindy.com/preview-profile/${data._id}`,
                //   };
                //   api
                //     .post(`projects/send-email/${data._id}`, body)
                //     .then((response: any) => {
                //       console.log(response);
                //       message.success("Email Sent");
                //     })
                //     .catch((err) => {
                //       console.error(err);
                //     });
                //   message.success("Email Sent");
                // }}
                key={2}
              >
                <span className="nav-text">
                  <h1>Send to Estimator</h1>
                </span>
              </Menu.Item>
              <Menu.Item className="project-menu-items" key={3}>
                <Link to={`/pjcc/${data._id}`}>
                  <span className="nav-text">
                    <h1>Review PJCC</h1>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item className="project-menu-items" key={4}>
                <Link to={`/proposal/${data._id}`}>
                  <span className="nav-text">
                    <h1>Preview Proposal</h1>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item className="project-menu-items" key={5}>
                <Link to={`/on-site-checklist/${data._id}`}>
                  <span className="nav-text">
                    <h1>Start Estimate</h1>
                  </span>
                </Link>
              </Menu.Item>
              <Menu.Item className="project-menu-items" key={6}>
                <Link to={`/manager-preview-profile/${data._id}`}>
                  <span className="nav-text">
                    <h1>Review Estimate</h1>
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item className="project-menu-items" key={9}>
                <span className="nav-text">
                  <h1>Post Job</h1>
                </span>
              </Menu.Item>
              <Menu.Item className="project-menu-items" key={10}>
                <span className="nav-text">
                  <h1>Set Inactive</h1>
                </span>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button className="action-button">
            <MoreActionIcon />
          </Button>
        </Dropdown>
      );
    },
  },
];
