import { Input } from "antd";
import React from "react";
import "./ProposalSetup.less";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { LatoRegular } from "../../assets/fonts/LatoRegular";
import { LatoBold } from "../../assets/fonts/LatoBold";

const Quill = ReactQuill.Quill;
// var Size = Quill.import("attributors/style/size");
// Size.whitelist = ["12px", "24px", "48px"];
// Quill.register(Size, true);
// var Font = Quill.import("formats/font");
// // Font.whitelist = ["Roboto", "Raleway", "Montserrat", "Lato", "Rubik"];
// Font.whitelist = ["Lato"];
// Quill.register(Font, true);

const { TextArea } = Input;

type Props = {
  summary?: string;
  handleSummary?: any;
  optionsList?: any;
};

const ProposalSummary = (props: Props) => {
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };
  const handleOnChange = (html: any) => {
    console.log(html);
  };
  return (
    <div className="ps-summary-container">
      <h1>Project Summary</h1>
      {props?.optionsList?.length > 0 && (
        <>
          <p>
            Project Includes: {props?.optionsList?.join(", ")}. For specific
            details please refer to the Included Details section below.
          </p>
        </>
      )}
      {/* <pre
        dangerouslySetInnerHTML={{
          __html: props?.summary ? props?.summary : "",
        }}
      /> */}
      {/* <TextArea
        style={{
          border: "1px solid #E8E8E8",
          borderRadius: "5px",
        }}
        onChange={props.handleSummary}
        value={props.summary}
        // onKeyDown={handleKeyDown}
      /> */}
      <ReactQuill
        theme="snow"
        modules={{
          toolbar: [
            // [
            //   // { header: "1" }, { header: "2" },
            //   { font: Font.whitelist },
            // ],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [
              { list: "ordered" },
              { list: "bullet" },
              // { indent: "-1" },
              // { indent: "+1" },
            ],
            ["link"],
            // ["clean"],
          ],
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
          },
        }}
        formats={[
          // "header",
          "font",
          "size",
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          "color",
          "background",
          "list",
          "bullet",
          "indent",
          "link",
          // "image",
          // "video",
        ]}
        placeholder="Write Something..."
        onChange={props.handleSummary}
        value={props.summary}
      />
    </div>
  );
};

export default ProposalSummary;
