import moment from "moment";

export const columns = [
  {
    title: "Draw",
    dataIndex: "drawNumber",
    width: 150,
  },
  {
    title: "Date",
    dataIndex: "date",
    width: 150,

    render: (data: any) => {
      return <div>{moment(data).format("MM-DD-YYYY")}</div>;
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    width: 150,

    render: (data: any) => {
      return (
        <div>
          $
          {data?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    title: "Retainer",
    dataIndex: "retainer",
    width: 150,

    render: (data: any) => {
      return (
        <div>
          $
          {data?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    title: "Deposit",
    dataIndex: "depositAmount",
    width: 150,

    render: (data: any) => {
      return (
        <div>
          $
          {data?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    title: "Balance",
    dataIndex: "balanceDue",
    width: 150,

    render: (data: any) => {
      return (
        <div>
          $
          {data?.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      );
    },
  },
  {
    title: "Type",
    dataIndex: "paymentType",
    width: 150,

    render: (data: any) => {
      return <div>{data}</div>;
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    render: (data: any) => {
      return <div>{data}</div>;
    },
  },
];
