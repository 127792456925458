import React, { useState, useEffect } from "react";
import { Image, Row, message, Checkbox, Spin, Input, Modal } from "antd";
import axios from "axios";
import { API_BASE } from "../../constant";
import { RootStateOrAny, useSelector } from "react-redux";
import { getAuthUser, getSelectedTenant } from "../../utils/authToken";
import { DeleteOutlined } from "@ant-design/icons";
import api from "../../utils/api";
import loadingGif from "../../assets/images/loading.gif";
import { isNull } from "lodash";

interface FileType {
  uid: string;
  thumbUrl?: string;
  key?: string;
  OrgImageURl?: string;
  mimeType?: string;
  addToOption?: boolean;
  caption?: string;
}

interface CaptionType {
  id: string;
  caption: string;
}

interface EditMediaProps {
  fileList: FileType[];
  setFileList: React.Dispatch<React.SetStateAction<FileType[]>>;
  captionText: CaptionType[];
  setCaptionText: React.Dispatch<React.SetStateAction<CaptionType[]>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditMedia = (props: EditMediaProps) => {
  const user = getAuthUser();
  const tenantId = getSelectedTenant();
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const [previewSrc, setPreviewSrc] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<FileType | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const showDeleteConfirm = (file: FileType) => {
    setFileToDelete(file);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFileToDelete(null);
  };

  const handleDelete = async () => {
    if (fileToDelete && online) {
      setIsDeleting(true);
      await axios
        .delete(`${API_BASE}upload-files/option-media/${fileToDelete.uid}`, {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
        })
        .then(() => {
          props.setFileList(
            props.fileList.filter(
              (item: FileType) => item.uid !== fileToDelete.uid
            )
          );
          setIsModalVisible(false);
          setFileToDelete(null);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIsDeleting(false);
        });
    } else {
      message.info("First get online");
    }
  };

  const handleAddToOption = async (isChecked: boolean, file: FileType) => {
    try {
      const values = {
        addToOption: isChecked,
      };
      const resp = await axios.patch(
        `${API_BASE}upload-files/option-media/${file.uid}`,
        values,
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
        }
      );
      if (resp.data) {
        if (resp.data.addToOption === true) {
          message.success("Image is successfully added to option for Proposal");
        } else {
          message.success(
            "Image is successfully removed from option for Proposal"
          );
        }

        const index = props.fileList.findIndex((item: FileType) => {
          return item.uid === resp.data._id;
        });
        if (index !== -1) {
          const updatedFileList = [...props.fileList];
          updatedFileList[index].addToOption = resp.data.addToOption;
          props.setFileList(updatedFileList);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCaptionInput = (val: string, fileId: string) => {
    const updatedCaptionText = props.captionText.map((item: CaptionType) => {
      if (item.id === fileId) {
        const index = props.fileList.findIndex((file: FileType) => {
          return file.uid === item.id;
        });
        if (index !== -1) {
          const updatedFileList = [...props.fileList];
          updatedFileList[index].caption = val;
          props.setFileList(updatedFileList);
        }
        return { ...item, caption: val };
      }
      return item;
    });

    if (!updatedCaptionText.find((item: CaptionType) => item.id === fileId)) {
      updatedCaptionText.push({ id: fileId, caption: val });
    }
    props.setCaptionText(updatedCaptionText);
  };

  const handlePreview = async (file: any) => {
    if (file.key && !file.OrgImageURl) {
      const res: any = await api.get(`upload-files/preview-image/${file.key}`);
      const OrgImageURl = `data:${file.mimeType};base64,${Buffer.from(res.Body).toString("base64")}`;
      const index = props.fileList.findIndex((item) => item.uid === file.uid);
      const updatedList = [...props.fileList];
      updatedList[index] = { ...file, OrgImageURl };
      props.setFileList(updatedList);
      setPreviewSrc(OrgImageURl);
    } else {
      if (file.OrgImageURl) {
        setPreviewSrc(file.OrgImageURl);
      }
      if (!file.key) {
        setPreviewSrc(file.thumbUrl);
      }
    }
  };

  const handleClosePreview = () => {
    setPreviewSrc(""); // Set previewSrc to an empty string when the preview is closed
  };

  return (
    <div className="profile-content">
      {online && (
        <>
          {props.loading ? (
            <Row justify="center">
              <Spin tip="Loading..." />
            </Row>
          ) : (
            <Row
              justify="start"
              align="middle"
              className="upload-photo-container"
            >
              {props.fileList.map((file: FileType) => (
                <div key={file.uid}>
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      marginRight: "15px",
                    }}
                  >
                    <Image
                      width={300}
                      src={file?.thumbUrl ? file?.thumbUrl : "error"}
                      preview={{
                        src: previewSrc !== "" ? previewSrc : loadingGif,
                        onVisibleChange: (visible) => {
                          if (!visible) {
                            handleClosePreview(); // Call handleClosePreview when the preview is closed
                          }
                        },
                      }}
                      style={{ display: "inline-block" }}
                      onClick={() => {
                        handlePreview(file);
                      }}
                    />
                    <button
                      type="submit"
                      onClick={() => showDeleteConfirm(file)}
                      style={{
                        position: "absolute",
                        bottom: 5,
                        right: 5,
                        padding: 0,
                        border: "none",
                        background: "white",
                        borderRadius: "20%",
                        cursor: "pointer",
                      }}
                    >
                      <DeleteOutlined
                        style={{
                          fontSize: "16px",
                          color: "red",
                        }}
                      />
                    </button>
                    <Checkbox
                      checked={file.addToOption === true}
                      onChange={(e) => {
                        handleAddToOption(e.target.checked, file);
                      }}
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "15px" }}>
                    <Input
                      type="text"
                      placeholder="add caption"
                      style={{
                        height: "30px",
                        marginTop: "5px",
                      }}
                      value={
                        props.captionText.find(
                          (item: CaptionType) => item.id === file.uid
                        )?.caption ||
                        file?.caption ||
                        ""
                      }
                      onChange={(e) => {
                        handleCaptionInput(e.target.value, file.uid);
                      }}
                    />
                  </div>
                </div>
              ))}
            </Row>
          )}
        </>
      )}
      <Modal
        title={<span style={{ fontWeight: "bold" }}>Warning</span>}
        visible={isModalVisible}
        onOk={handleDelete}
        onCancel={handleCancel}
        okText={isDeleting ? <Spin /> : "Delete"}
        cancelText="Cancel"
        okButtonProps={{ disabled: isDeleting }}
      >
        <p>Are you sure you want to delete this file?</p>
      </Modal>
    </div>
  );
};
export default EditMedia;
