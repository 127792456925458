export const paintTitles = [
  {
    title: "Paint",
    label: "Paint Labor Red",
    name: "paintLaborHard",
  },
  {
    label: "Paint Labor Yellow",
    name: "paintLaborMedium",
  },
  {
    label: "Paint Labor Green",
    name: "paintLaborEasy",
  },
  {
    title: "Prep",
    label: "Prep Labor Red",
    name: "prepLaborHard",
  },
  {
    label: "Prep Labor Yellow",
    name: "prepLaborMedium",
  },
  {
    label: "Prep Labor Green",
    name: "prepLaborEasy",
  },
  {
    label: "Prime Labor Red",
    name: "primeLaborHard",
  },
  {
    label: "Prime Labor Yellow",
    name: "primeLaborMedium",
  },
  {
    label: "Prime Labor Green",
    name: "primeLaborEasy",
  },

];
