import React, { PropsWithChildren, useEffect, useState } from "react";
import { Layout } from "antd";
import SideBarComponent from "../../components/layout/SidebarComponent";
import HeaderComponent from "../../components/layout/HeaderComponent";
import { logout } from "../../redux/user/action";
import { getAuthUser } from "../../utils/authToken";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
const { Content } = Layout;
const DashboardLayout: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [collapse, setCollapse] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:850px)" });

  const user = getAuthUser();
  const history = useHistory();
  const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
  const adminState = JSON.parse(localStorage.getItem("adminState") || "{}");

  const [title, setTitle] = useState("");
  useEffect(() => {
    if (adminState === "dashboard") {
      setTitle("Admin");
    } else {
      setTitle("Dashboard");
    }
  }, []);

  const handleMenuClick = () => {
    if (adminState === "admin") {
      setTitle("Admin");
      localStorage.setItem("adminState", JSON.stringify("dashboard"));
      history.push("/");
    } else if (adminState === "dashboard") {
      setTitle("Dashboard");
      localStorage.setItem("adminState", JSON.stringify("admin"));
      history.push("/admin-material-default");
    }
  };
  useEffect(() => {
    const resizeW = () => changeDeviceSize(window.innerWidth);
    window.addEventListener("resize", resizeW); // Update the width on resize
    deviceSize < 1200 ? setCollapse(true) : setCollapse(false);
    return () => window.removeEventListener("resize", resizeW);
  }, [deviceSize]);

  const onCollapse = (collapsed: boolean) => setCollapse(collapsed);
  const onToggleSidebar = (collapsed: boolean) => {
    collapse ? setCollapse(false) : setCollapse(true);
  };

  return (
    <Layout
      className="site-layout-background"
      hasSider
      style={{ minHeight: "100vh" }}
    >
      <SideBarComponent
        collapse={collapse}
        onCollapse={onCollapse}
        onToggleSidebar={onToggleSidebar}
      />
      <Layout
        className="site-layout"
        style={{
          marginLeft: collapse
            ? isTabletOrMobile
              ? 0
              : 75
            : isTabletOrMobile
            ? 0
            : 210,
          background: "#FAF9F9",
        }}
      >
        <HeaderComponent
          title={title}
          handleMenuClick={handleMenuClick}
          collapse={collapse}
          onCollapse={onCollapse}
          onToggleSidebar={onToggleSidebar}
        />

        {isTabletOrMobile ? (
          <Content style={{ margin: "0 16px", position: "relative" }}>
            {children}
          </Content>
        ) : (
          <Content
            style={{ margin: "0 16px", padding: 20, position: "relative" }}
          >
            {children}
          </Content>
        )}
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
