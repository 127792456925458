export const paintHeader = [
  {
    label: "item",
    key: "item",
  },
  {
    label: "type",
    key: "type",
  },
  {
    label: "paintLaborEasy",
    key: "paintLaborEasy",
  },
  {
    label: "paintLaborMedium",
    key: "paintLaborMedium",
  },
  {
    label: "paintLaborHard",
    key: "paintLaborHard",
  },
  {
    label: "prepLaborEasy",
    key: "prepLaborEasy",
  },
  {
    label: "prepLaborMedium",
    key: "prepLaborMedium",
  },
  {
    label: "prepLaborHard",
    key: "prepLaborHard",
  },
  {
    label: "primeLaborEasy",
    key: "primeLaborEasy",
  },
  {
    label: "primeLaborMedium",
    key: "primeLaborMedium",
  },
  {
    label: "primeLaborHard",
    key: "primeLaborHard",
  },
  {
    label: "paintSpreadRate",
    key: "paintSpreadRate",
  },
  {
    label: "primerSpreadRate",
    key: "primerSpreadRate",
  },
  {
    label: "_id",
    key: "_id",
  },
];

export const paintCostColumns: any = [
  {
    title: "Surface",
    dataIndex: "item",
    width: "200px",
    key: "item",
    fixed: "left",
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Paint Labor Red",
    dataIndex: "paintLaborHard",
  },
  {
    title: "Paint Labor Yellow",
    dataIndex: "paintLaborMedium",
  },
  {
    title: "Paint Labor Green",
    dataIndex: "paintLaborEasy",
  },
  {
    title: "Prep Labor Red",
    dataIndex: "prepLaborHard",
  },
  {
    title: "Prep Labor Yellow",
    dataIndex: "prepLaborMedium",
  },
  {
    title: "Prep Labor Green",
    dataIndex: "prepLaborEasy",
  },
  {
    title: "Prime Labor Red",
    dataIndex: "primeLaborHard",
  },
  {
    title: "Prime Labor Yellow",
    dataIndex: "primeLaborMedium",
  },
  {
    title: "Prime Labor Green",
    dataIndex: "primeLaborEasy",
  },
  {
    title: "Paint Spread Rate",
    dataIndex: "paintSpreadRate",
  },
  {
    title: "Primer Spread Rate",
    dataIndex: "primerSpreadRate",
  },
];

export const carpentryCostColumns = [
  {
    title: "Carpentry Item",
    dataIndex: "item",
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
  },
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "Labor/Unit",
    dataIndex: "laborRate",
    render: (data: any) => {
      return <div>${data}</div>;
    },
  },
  {
    title: "Material/Unit",
    dataIndex: "materialRate",
    render: (data: any) => {
      return <div>${data}</div>;
    },
  },
];

export const carpentryHeader = [
  {
    label: "item",
    key: "item",
  },
  {
    label: "type",
    key: "type",
  },
  {
    label: "laborRate",
    key: "laborRate",
  },
  {
    label: "materialRate",
    key: "materialRate",
  },
  {
    label: "_id",
    key: "_id",
  },
];

export const miscCostColumns = [
  {
    title: "Item",
    dataIndex: "item",
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
  },
  {
    title: "Cost/Unit",
    dataIndex: "cost",
    render: (data: any) => {
      return <div>${data}</div>;
    },
  },
];

export const travelCostColumns = [
  {
    title: "Item",
    dataIndex: "item",
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
  },
  {
    title: "Cost/Unit",
    dataIndex: "cost",
    render: (data: any) => {
      return <div>${data}</div>;
    },
  },
];

export const miscHeader = [
  {
    label: "item",
    key: "item",
  },
  {
    label: "cost",
    key: "cost",
  },
  {
    label: "_id",
    key: "_id",
  },
];

export const equipmentHeader = [
  {
    label: "item",
    key: "item",
  },
  {
    label: "hasIntervals",
    key: "hasIntervals",
  },
  {
    label: "dailyCost",
    key: "dailyCost",
  },
  {
    label: "weeklyCost",
    key: "weeklyCost",
  },
  {
    label: "monthlyCost",
    key: "monthlyCost",
  },
  {
    label: "tax",
    key: "tax",
  },
  {
    label: "puAndDelivery",
    key: "puAndDelivery",
  },
  {
    label: "surcharges",
    key: "surcharges",
  },
  {
    label: "_id",
    key: "_id",
  },
];
