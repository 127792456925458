const initialState = {
  selectedSalesAssociate: {},
  selectedEstimators: {},
  selectedProductionAssociate: {},
  associates: [],
};

const associatePeople = (state = initialState, action: any) => {
  switch (action.type) {
    case "FETCHING_ASSOCIATE_PEOPLE_SUCCESS":
      return {
        ...state,
        associates: action.payload,
      };
    case "SET_SELECTED_SALES_ASSOCIATE":
      return {
        ...state,
        selectedSalesAssociate: action.payload,
      };
    case "SET_SELECTED_ESTIMATOR":
      return {
        ...state,
        selectedEstimators: action.payload,
      };
    case "SET_SELECTED_PRODUCTION_ASSOCIATE":
      return {
        ...state,
        selectedProductionAssociate: action.payload,
      };
    case "SET_SELECTED_ASSOCIATES":
      return {
        ...state,
        selectedSalesAssociate: action.payload.salesAssociate,
        selectedEstimators: action.payload.estimator,
        selectedProductionAssociate: action.payload.productionAssociate,
      };
    default:
      return state;
  }
};

export default associatePeople;
