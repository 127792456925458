const initialState = {
  currentOption: {},
  options: [],
  optionsPjcc: [],
};

const options = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_CURRENT_OPTION":
      return {
        ...state,
        currentOption: action.payload,
      };
    case "ADD_OPTION":
      return {
        ...state,
        options: [...state.options, action.payload],
      };
    case "ADD_OPTIONS":
      return {
        ...state,
        options: [...action.payload],
      };
    case "UPDATE_OPTIONS": {
      const newData = state.options.map(
        (option: any) =>
          action.payload.find((o: any) => o._id === option._id) || option
      );

      return {
        ...state,
        options: newData,
      };
    }

    case "UPDATE_OPTION_TRAVERSED":
      const update: any = [...state.options];
      const updateIndex = state.options.findIndex(
        (item: any) => item._id === action.payload._id
      );
      update[updateIndex].traversed = action.payload.traversed;
      return {
        ...state,
        options: update,
      };
      break;
    case "REMOVE_OPTION":
      const temp = [...state.options];
      const index = state.options.findIndex(
        (item: any) => item._id === action.payload
      );
      temp.splice(index, 1);

      return {
        ...state,
        options: temp,
      };
    case "SET_OPTIONS_PJCC":
      return {
        ...state,
        optionsPjcc: action.payload,
      };
    default:
      return state;
  }
};

export default options;
