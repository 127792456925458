import React from "react";
import { Upload, Button, message, Tag } from "antd";
import "./AddMedia.less";
import { InvalidMediaIcon } from "../../utils/icons";
import axios from "axios";
import { API_BASE } from "../../constant";
import { RootStateOrAny, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getSelectedTenant } from "../../utils/authToken";
import { isNull } from "lodash";

const { Dragger } = Upload;

interface ImageProps {
  images?: any;
  updateFileList?: any;
  setFileList?: any;
}

const AddMedia: React.FC<ImageProps> = ({
  images,
  updateFileList,
  setFileList,
}: ImageProps) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:800px)" });

  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const tenantId = getSelectedTenant();

  const handleOnChange = (info: any) => {
    console.log(info);
    const { status, originFileObj } = info.file;
    if (status === "done") {
      const reader = new FileReader();
      reader.readAsDataURL(originFileObj);
      reader.onloadend = () => {
        const thumbUrl = reader.result;
        // do something with the thumbnail URL
        setFileList([
          ...images,
          {
            name: info.file.name,
            thumbUrl,
            uid: info.file.response._id,
            addToProject: info.file.response.addToProject,
            _doc: info.file.response,
          },
        ]);
      };
    }
  };

  const handleBeforeUpload = (file: File) => {
    if (items[currentProject].projectId && file.size <= 50000000) {
      return true;
    } else {
      message.error("Max File Size Allowed is 50MB. Try Another file!");
    }
  };

  const handleDelete = (file: any) => {
    if (file.response) {
      axios
        .delete(`${API_BASE}upload-files/${file.response._id}`, {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
        })
        .then(() => {
          setFileList(
            images.filter((item: any) => item.uid !== file.response._id)
          );
          // updateFileList(file.uid);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .delete(`${API_BASE}upload-files/${file.uid}`, {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
        })
        .then(() => {
          setFileList(images.filter((item: any) => item.uid !== file.uid));
          // updateFileList(file.uid);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="job-container">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "2px",
        }}
      >
        <h1 style={{ marginRight: 15 }} className="form-subtitle">
          Media
        </h1>
        <div style={{ paddingTop: "4px" }}>
          <InvalidMediaIcon />
        </div>
      </div>
      {online ? (
        <>
          <div>
            {!items[currentProject]?.projectId ? (
              <div
                style={{
                  height: 201,
                  backgroundColor: "#fafafa",
                  border: "1px dashed #d9d9d9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isTabletOrMobile ? (
                  <Tag
                    color="error"
                    style={{
                      fontSize: 24,
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}
                  >
                    First Save the Project and then Try to upload.
                  </Tag>
                ) : (
                  <Tag
                    color="error"
                    style={{
                      fontSize: 18,
                      paddingTop: 5,
                      paddingBottom: 5,
                      marginLeft: "7px",
                    }}
                  >
                    First Save the Project and then Try to upload.
                  </Tag>
                )}
              </div>
            ) : (
              <Dragger
                multiple={true}
                defaultFileList={images}
                listType="picture"
                beforeUpload={handleBeforeUpload}
                action={`${API_BASE}upload-files/${items[currentProject]?.projectId}`}
                headers={{
                  Authorization: "Bearer " + user.accessToken,
                  "tenant-id": !isNull(tenantId) ? tenantId : "",
                }}
                onRemove={handleDelete}
                onChange={handleOnChange}
                disabled={items[currentProject]?.projectId ? false : true}
              >
                <p className="drag-text">Drag your files here</p>
                <p className="or-text">OR</p>
                <div style={{ paddingTop: "20px", paddingBottom: "30px" }}>
                  <Button className="browse-button">Browse File</Button>
                </div>
              </Dragger>
            )}
          </div>
        </>
      ) : (
        <div
          style={{
            height: 201,
            backgroundColor: "#fafafa",
            border: "1px dashed #d9d9d9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tag
            color="error"
            style={{ fontSize: 24, paddingTop: 5, paddingBottom: 5 }}
          >
            Internet Not Available.
          </Tag>
        </div>
      )}
    </div>
  );
};

export default AddMedia;
