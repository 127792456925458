import React, { PropsWithChildren, useState, useEffect } from 'react';

import { Layout } from 'antd';
import PaymentLogsC from '../../containers/payment-contractor/payment-logs/PaymentLogsC';

const { Content } = Layout;

const PaymentLogs: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div>
      <PaymentLogsC />
    </div>
  );
};

export default PaymentLogs;
