import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Menu,
  Dropdown,
  Select,
  Input,
  message,
  Spin,
} from "antd";
import "./EquipmentCostsC.less";
import { SearchOutlined } from "@ant-design/icons";
import api from "../../utils/api";
import { MoreActionIcon } from "../../utils/icons";
import AddEquipmentCost from "../../components/pop-ups/default-modal/AddEquipmentCost";
import { CSVLink } from "react-csv";
import { equipmentHeader } from "./config";
import DefaultBulkEntry from "../../components/pop-ups/default-modal/MaterialDefaultBulkEntry";
import { getArrayClone } from "../../utils/helpers";
import { findIndex } from "lodash";

const { Option } = Select;

const EquipmentCostsC = () => {
  const [equipmentCost, setEquipmentCost] = useState<any>([]);
  const [showSingleItemModal, setShowSingleItemModal] = useState(false);
  const [item, setItem] = useState<any>({});
  const [buttonDisable, setButtonDisable] = useState(false);

  const [showBulkModal, setShowBulkModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [equipmentSearched, setEquipmentSearched] = useState("");
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    api
      .get("rates/cost/equipment")
      .then((resp: any) => {
        setEquipmentCost(
          resp.map((item: any) => {
            return {
              ...item,
              interval: item.hasIntervals && "daily",
              cost: item.dailyCost,
              totalCost: item.dailyCost + item.puAndDelivery + item.surcharges,
            };
          })
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const found = equipmentCost?.filter((item: any) => {
    if (!equipmentSearched) {
      return true;
    }
    return item.item.toLowerCase().includes(equipmentSearched.toLowerCase());
  });

  const handleAdd = (values: any) => {
    if (edit) {
      const body = {
        ...values,
        hasIntervals: values.weeklyCost || values.monthlyCost ? true : false,
      };

      api.put(`rates/equipment/${item._id}`, body).then(() => {
        setEquipmentCost(
          equipmentCost.map((data: any) =>
            data._id === item._id
              ? {
                  ...body,
                  _id: item._id,
                  interval: (body.weeklyCost || body.monthlyCost) && "daily",
                  cost: body.dailyCost,
                  totalCost:
                    parseInt(body.dailyCost) +
                    parseInt(body.puAndDelivery) +
                    parseInt(body.surcharges),
                }
              : data
          )
        );
      });
    } else {
      let body = {
        ...values,
        hasIntervals: false,
      };
      if (values.weeklyCost || values.monthlyCost) {
        body.hasIntervals = true;
      }

      api
        .post("rates", {
          category: "equipment",
          rate: body,
        })
        .then((resp: any) => {
          setEquipmentCost([
            ...equipmentCost,
            {
              ...resp,
              interval: resp.hasIntervals && "daily",
              totalCost: resp.dailyCost + resp.puAndDelivery + resp.surcharges,
              cost: resp.dailyCost,
            },
          ]);
        });
    }
    toggleSingleItemModal();
  };

  const toggleSingleItemModal = () => {
    !showSingleItemModal
      ? setShowSingleItemModal(true)
      : setShowSingleItemModal(false);
  };

  const handleSingleItem = () => {
    setEdit(false);
    setItem({});
    toggleSingleItemModal();
  };

  const handleEdit = (data: any) => {
    setItem(data);
    setEdit(true);
    toggleSingleItemModal();
  };

  const handleInterval = (value: string, id: number) => {
    let newData = getArrayClone(equipmentCost);
    const index = equipmentCost.findIndex((item: any) => item._id === id);

    if (newData[index]?.hasIntervals) {
      switch (value) {
        case "daily":
          newData[index].cost = newData[index].dailyCost;
          break;
        case "weekly":
          newData[index].cost = newData[index].weeklyCost;
          break;
        case "monthly":
          newData[index].cost = newData[index].monthlyCost;
          break;
        default:
          break;
      }
      newData[index].totalCost =
        newData[index].cost +
        newData[index].puAndDelivery +
        newData[index].surcharges;

      newData[index].interval = value;
      setEquipmentCost(newData);
    }
  };

  const columns = [
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
      sorter: (a: any, b: any) => a.item.localeCompare(b.item),
    },
    {
      title: "Interval",
      render: (data: any) => {
        return (
          <div
            className="table--editor--holder option-detail-table-exp"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Select
              onSelect={(value: string) => handleInterval(value, data._id)}
              style={{ width: "100px" }}
              value={data.interval}
              disabled={!data.hasIntervals}
            >
              <Option disabled={!data.dailyCost} value="daily">
                Daily
              </Option>
              <Option disabled={!data.weeklyCost} value="weekly">
                Weekly
              </Option>
              <Option disabled={!data.monthlyCost} value="monthly">
                Monthly
              </Option>
            </Select>
          </div>
        );
      },
    },
    {
      title: "Cost/Unit",
      dataIndex: "cost",
      render: (data: any) => {
        return <div>${data}</div>;
      },
    },
    {
      title: "P/U & Delivery",
      dataIndex: "puAndDelivery",
      render: (data: any) => {
        return <div>${data}</div>;
      },
    },
    {
      title: "Surcharges",
      dataIndex: "surcharges",
      render: (data: any) => {
        return <div>${data}</div>;
      },
    },
    {
      title: "Total Cost",
      dataIndex: "totalCost",
      render: (data: any) => {
        return <div>${data}</div>;
      },
    },
    {
      title: "Action",
      render: (data: any) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="edit"
                  style={{ fontWeight: "bold" }}
                  onClick={() => handleEdit(data)}
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  key="delete"
                  style={{ fontWeight: "bold" }}
                  onClick={() => handleDelete(data._id)}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <div style={{ cursor: "pointer" }}>
              <MoreActionIcon />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const handleDelete = (id: string) => {
    api.delete(`rates/equipment/${id}`).then(() => {
      setEquipmentCost(equipmentCost.filter((item: any) => item._id !== id));
    });
  };

  const handleBulkBtn = () => {
    !showBulkModal ? setShowBulkModal(true) : setShowBulkModal(false);
  };
  const toggleBulkModal = () => {
    !showBulkModal ? setShowBulkModal(true) : setShowBulkModal(false);
  };

  const handleAddCsv = (info: any) => {
    setButtonDisable(true);
    if (info.file.status === "done") {
      setButtonDisable(false);
      message.success("Successfully Uploaded");
      const temp = info.file.response.data.map((resp: any) => {
        return {
          ...resp,
          interval: resp.hasIntervals ? "daily" : "",
          totalCost: resp.dailyCost + resp.puAndDelivery + resp.surcharges,
          cost: resp.dailyCost,
        };
      });

      setEquipmentCost(temp);
      toggleBulkModal();
    } else if (info.file.status === "error") {
      setButtonDisable(false);
      message.error("Uploading Failed!");
    }
  };

  return (
    <div className="admin-ec-root">
      <DefaultBulkEntry
        buttonDisable={buttonDisable}
        showBulkModal={showBulkModal}
        toggleBulkModal={toggleBulkModal}
        url="rates/upload"
        handleAddCsv={handleAddCsv}
      />
      <AddEquipmentCost
        toggleSingleItemModal={toggleSingleItemModal}
        handleAdd={handleAdd}
        showSingleItemModal={showSingleItemModal}
        item={item}
        edit={edit}
      />
      <Row className="admin-ec-header" justify="space-between">
        <Col>
          <h1>Equipment Labor Rates</h1>
        </Col>
        <Row gutter={12}>
          <Col>
            <Input
              className="search-manufacture-input"
              prefix={
                <SearchOutlined className="contractor-data-search-icon" />
              }
              placeholder="Search Equipment"
              onChange={(e) => {
                setEquipmentSearched(e.target.value);
              }}
            />
          </Col>
          <div className="contractor-data-vertical-line " />
          <Col>
            <Button
              className="admin-ec-add-single-btn"
              onClick={handleSingleItem}
            >
              Add Single item
            </Button>
          </Col>
          <Col>
            <Button
              className="admin-ec-add-btn"
              type="primary"
              onClick={handleBulkBtn}
            >
              Bulk Entry
            </Button>
          </Col>
        </Row>
      </Row>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <hr style={{ border: "1px solid #E8E8E8", marginBottom: 30 }} />
          <Button
            className="default-bulk-btn"
            style={{ margin: "0px 20px 10px" }}
            type="primary"
          >
            <CSVLink
              data={equipmentCost}
              filename={"equipment-rates"}
              headers={equipmentHeader}
              enclosingCharacter={""}
            >
              Download CSV
            </CSVLink>
          </Button>
          {equipmentCost.length > 0 && (
            <Table
              scroll={{ x: "900px" }}
              rowKey="_id"
              columns={columns}
              dataSource={found}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EquipmentCostsC;
