import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
const { Option } = Select;

type Props = {
  showModal: boolean;
  setShowModal: any;
  addDiscount: (body: any) => void;
  updateDiscount: (body: any) => void;
  discountValue?: any;
};

const DiscountModal = (props: Props) => {
  const [selectedValue, setSelectedValue] = useState(undefined);
  const [inputValue, setInputValue] = useState(0);
  const [showExpression, setShowExpression] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      description: props?.discountValue?.description,
      amountType: props?.discountValue?.amountType,
      amount: props?.discountValue?.amount,
      expression: props?.discountValue?.expression,
    });
    setSelectedValue(props?.discountValue?.amountType);
  }, [props?.discountValue, props.showModal]);

  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };

  const checkValue = {
    validator(_: any, value: string) {
      if (value) {
        if (selectedValue === "percentage" && Number(value) > 100) {
          return Promise.reject(
            new Error("Value should not be greater than 100")
          );
        }
      }

      return Promise.resolve();
    },
  };

  const handleOk = async (values: any) => {
    const updatedValues = {
      ...values,
      amount: Number(values.amount),
    };
    if (Object.keys(props?.discountValue).length === 0) {
      props.addDiscount(updatedValues);
    } else {
      props.updateDiscount({
        ...updatedValues,
        _id: props?.discountValue._id,
        projectId: props?.discountValue.projectId,
      });
    }
  };

  const handleCancel = () => {
    props.setShowModal(false);
  };

  const handleAmountType = (e: any) => {
    setSelectedValue(e);
    if (e === "expression") {
      setShowExpression(true);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "+" || e.key === "-" || e.key === "." || e.key === "e") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Modal
        title={
          <h2 style={{ marginTop: 10, fontWeight: "bold" }}>
            {Object.keys(props?.discountValue).length === 0
              ? "Add Discount"
              : "Edit Discount"}
          </h2>
        }
        visible={props.showModal}
        footer={false}
        onCancel={handleCancel}
        // width={"30%"}
      >
        <div className="default-material-form">
          <Form
            form={form}
            onFinish={handleOk}
            scrollToFirstError
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              hasFeedback
              name="description"
              label={<h2>Description</h2>}
              rules={[
                {
                  required: true,
                  message: "",
                },
                checkEmpty,
              ]}
            >
              <Input className="default-material-form-input" />
            </Form.Item>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  hasFeedback
                  name="amountType"
                  label={<h2>Amount Type</h2>}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Select
                    className="default-material-form-select"
                    placeholder="Select"
                    onSelect={(e) => handleAmountType(e)}
                  >
                    <Option value="percentage">Percentage</Option>
                    <Option value="dollar">Dollar</Option>
                    {/* <Option value="expression">Expression</Option> */}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  // initialValue={props.material}
                  hasFeedback
                  name="amount"
                  label={<h2>Amount</h2>}
                  rules={[
                    {
                      required: selectedValue === "expression" ? false : true,
                      message: "",
                    },
                    checkValue,
                  ]}
                >
                  <Input
                    className="default-material-form-input"
                    disabled={
                      selectedValue === undefined ||
                      selectedValue === "expression"
                    }
                    type="number"
                    min={0}
                    max={selectedValue === "percentage" ? 100 : undefined}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Item>
              </Col>
            </Row>

            {showExpression && (
              <Form.Item
                hasFeedback
                name="expression"
                label={<h2>Expression</h2>}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  checkEmpty,
                ]}
              >
                <Input className="default-material-form-input" />
              </Form.Item>
            )}

            <Row gutter={10} align="middle" justify="end">
              <Col>
                <Button
                  onClick={handleCancel}
                  className="default-material-cancel-btn"
                >
                  Cancel
                </Button>
              </Col>

              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="default-material-add-btn"
                >
                  {Object.keys(props?.discountValue).length === 0
                    ? "Add Entry"
                    : "Update Entry"}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default DiscountModal;
