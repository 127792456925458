import { Row, Col, Image } from "antd";
import moment from "moment";
import { CalendarIcon } from "../../utils/icons";
import "./ContractSetup.less";

type SaveCompleteDateStringsDto = {
  startDateFirmString: string;
  startDateFlexibleString: string;
  completionDateFirmString: string;
  completionDateFlexibleString: string;
};

type Props = {
  data: SaveCompleteDateStringsDto;
  svgString: string;
  startDate?: any;
  completionDate?: any;
  startDateFirmOrFlexible?: any;
  completionDateFirmOrFlexible?: any;
};

const ContractDate = ({ data, svgString, ...props }: Props) => {
  return (
    <div className="contract-date-root">
      <Row gutter={20} className="cd-margin-btm">
        <Col>
          <Image
            src={svgString}
            style={{
              paddingTop: "0px",
            }}
            preview={false}
          />
        </Col>
        <Col>
          <h1>Start/Completion Date</h1>
        </Col>
      </Row>
      <div className="estimate-date-cont">
        <Row gutter={[10, 16]} align="middle">
          <Col lg={4} xs={8}>
            <h2>Start Date:</h2>
          </Col>
          <Col lg={14} xs={12}>
            {props.startDateFirmOrFlexible === "firm" ? (
              <h3>{data.startDateFirmString}</h3>
            ) : (
              <h3>{data.startDateFlexibleString}</h3>
            )}
          </Col>
          <Col span={2.5} xs={4}>
            {props.startDate ? (
              <p>{moment(props.startDate).format("MMM DD,YYYY")}</p>
            ) : (
              <>__________________</>
            )}
          </Col>
        </Row>
        <Row gutter={[10, 16]} align="middle">
          <Col lg={4} xs={8}>
            <h2>Completion Date:</h2>
          </Col>
          <Col lg={14} xs={12}>
            {props.completionDateFirmOrFlexible === "firm" ? (
              <h3>{data.completionDateFirmString}</h3>
            ) : (
              <h3>{data.completionDateFlexibleString}</h3>
            )}
          </Col>
          <Col span={2.5} xs={4}>
            {props.completionDate ? (
              <p>{moment(props.completionDate).format("MMM DD,YYYY")}</p>
            ) : (
              <>__________________</>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ContractDate;
