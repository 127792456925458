import { Descriptions, Row, Col, Divider } from "antd";
import { BulletIconBlack } from "../../utils/icons";
import "../../components/proposal/Proposal.less";

type Props = {
  setup?: any[];
  surface?: any[];
};

const CleanUpSection = () => (
  <div className="tasks-wrapper" style={{ marginTop: "50px" }}>
    <h2 className="content-title">CLEAN UP</h2>
    <Divider
      type="horizontal"
      style={{
        marginTop: 0,
        marginRight: 50,
        borderColor: "#FDB913",
        borderWidth: 5,
      }}
    />
    <Descriptions style={{ marginTop: "20px" }}>
      <Descriptions.Item>
        <p className="terms-text" style={{ fontWeight: "normal" }}>
          <strong>UPON COMPLETION:</strong> Includes thorough clean up of paint, tape, plastic, dust and debris in areas where work was completed. All tools, supplies & equipment will be removed from the property.
        </p>
      </Descriptions.Item>
    </Descriptions>
  </div>
);

const BuildingTasks = ({ setup = [], surface = [] }: Props) => {
  const renderSurfacePreparation = () => (
    <div style={{ marginTop: "50px" }}>
      <h2 className="content-title">SURFACE PREPARATION</h2>
      <Divider
        type="horizontal"
        style={{
          marginTop: 0,
          marginRight: 50,
          borderColor: "#FDB913",
          borderWidth: 5,
        }}
      />
      <Descriptions column={1} style={{ marginTop: "25px" }}>
        {surface.map((item: any) => (
          <Descriptions.Item
            key={item._id}
            style={{
              margin: 0,
              padding: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="tasks-wrapper"
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div>
                <BulletIconBlack />
              </div>

              <p
                className="terms-text"
                style={{
                  fontWeight: "normal",
                  marginLeft: "10px",
                }}
              >
                <strong>{item.title}:</strong> {item.detail}
              </p>
            </div>
          </Descriptions.Item>
        ))}
      </Descriptions>

    </div>
  );

  const renderSetup = () => (
    <div style={{ marginTop: 50 }}>
      <h2 className="content-title" style={{ fontSize: 24, marginBottom: 0 }}>
        SETUP
      </h2>
      <Divider
        type="horizontal"
        style={{
          marginTop: 0,
          marginRight: 50,
          borderColor: "#FDB913",
          borderWidth: 5,
        }}
      ></Divider>

      <div>
        <Row>
          <Col span={7}>
            <p style={{ fontWeight: "bold" }}>Customer To:</p>
            {setup &&
              setup
                .filter((item: any) => item.title === "customer tasks")
                .map((row: any) => {
                  return (
                    <div
                      key={row._id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ marginRight: 10 }}>
                        <BulletIconBlack />
                      </div>
                      <p className="profile-content">
                        <span>{row.task}</span>
                      </p>
                    </div>
                  );
                })}
          </Col>
          <Col span={9}>
            <p style={{ fontWeight: "bold" }}>
              CertaPro will cover and protect:
            </p>
            {setup &&
              setup
                .filter(
                  (obj: any) =>
                    Object.keys(obj).includes("category") &&
                    obj.category === "Cover and protect"
                )
                .map((row: any) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                      key={row._id}
                    >
                      <div style={{ marginRight: 10 }}>
                        <BulletIconBlack />
                      </div>
                      <p className="profile-content">
                        <span>{row.task}</span>
                      </p>
                    </div>
                  );
                })}
          </Col>
          <Col span={8}>
            <p style={{ fontWeight: "bold" }}>CertaPro will:</p>
            {setup &&
              setup
                .filter(
                  (obj: any) =>
                    Object.keys(obj).includes("category") && obj.category === "will do"
                )
                .map((row: any) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                      key={row._id}
                    >
                      <div style={{ marginRight: 10 }}>
                        <BulletIconBlack />
                      </div>
                      <p className="customer-text">
                        <span>{row.task}</span>
                      </p>
                    </div>
                  );
                })}
          </Col>
        </Row>
      </div>
    </div>
  );

  return (
    <div style={{ marginTop: 50 }}>
      {surface.length > 0 && renderSurfacePreparation()}
      {setup.length > 0 && renderSetup()}
      <CleanUpSection />
    </div>
  );
};

export default BuildingTasks;
