import React, { Fragment, useState } from "react";
import { Menu, Layout } from "antd";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/images/Pro-Driven-Edit.jpg";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { LogoutIcon } from "../../utils/icons";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { routes } from "../../config/config";
import { verifyRole } from "../../utils/helpers";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import bugIcon from "../../assets/icons/bug.png";
import { setCurrentProject } from "../../redux/project/action";
import { useMediaQuery } from "react-responsive";

const { Sider } = Layout;

type SideBarProps = {
  collapse: boolean;
  onCollapse: (collapsed: boolean) => void;
  onToggleSidebar: (item: any) => void;
};

const SideBarComponent: React.FC<SideBarProps> = ({
  collapse,
  onCollapse,
  onToggleSidebar,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:850px)" });

  const history = useHistory();
  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  const dispatch = useDispatch();
  const adminState = JSON.parse(
    localStorage.getItem("adminState") || "{}"
  ).toString();

  return (
    <>
      {isTabletOrMobile && collapse ? null : (
        <div className="sider-wrapper">
          <Sider
            width={210}
            trigger={null}
            className="site-layout-background"
            style={{
              overflow: "scroll",
              height: "100vh",
              position: "fixed",
              zIndex: 999,
            }}
            collapsed={collapse}
            onCollapse={onCollapse}
          >
            <div>
              <div
                className="logo"
                style={{
                  background: "white",
                  height: 60,
                  borderRadius: 10,
                  border: "3px solid #FDB913",
                  marginRight: 5,
                  marginLeft: 5,
                  marginTop: 2,
                }}
              >
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    width: collapse ? 60 : 125,
                    borderRadius: 10,
                  }}
                />

                <span className="toggle-menu-holder">
                  {React.createElement(
                    collapse ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                      className: "trigger",
                      onClick: (event: React.MouseEvent<HTMLElement>) => {
                        // toggle()
                        onToggleSidebar(false);
                      },
                    }
                  )}
                </span>
              </div>
              <div className="sider-menu-style">
                <Menu mode="inline" onSelect={() => { }}>
                  <>
                    {routes.map(
                      (
                        { visible, title, roles, path, icon: Icon, children },
                        index
                      ) => {
                        if (visible && roles && verifyRole(roles, user?.roles)) {
                          if (roles?.includes("admin") && children) {
                            return (
                              <React.Fragment key={index}>
                                {!(
                                  adminState === "dashboard" &&
                                  title === "Defaults"
                                ) && (
                                    <Menu.SubMenu
                                      key={index}
                                      icon={Icon && <Icon />}
                                      title={title}
                                    >
                                      {children.map((item: any) => {
                                        return (
                                          <Menu.Item
                                            style={{
                                              background: "##FDB913",
                                              margin: 0,
                                              width: "100%",
                                            }}
                                            key={item.key}
                                            onClick={() => {
                                              dispatch(setCurrentProject(-1));
                                              history.push(item.path);
                                            }}
                                          >
                                            {item.label}
                                          </Menu.Item>
                                        );
                                      })}
                                    </Menu.SubMenu>
                                  )}
                              </React.Fragment>
                            );
                          }
                          if (roles?.includes("super_admin") && children) {
                            return (
                              <React.Fragment key={index}>
                                {!(
                                  adminState === "dashboard" &&
                                  title === "Defaults"
                                ) && (
                                    <Menu.SubMenu
                                      key={index}
                                      icon={Icon && <Icon />}
                                      title={title}
                                    >
                                      {children.map((item: any) => {
                                        return (
                                          <Menu.Item
                                            style={{
                                              background: "##FDB913",
                                              margin: 0,
                                              width: "100%",
                                            }}
                                            key={item.key}
                                            onClick={() => {
                                              dispatch(setCurrentProject(-1));
                                              history.push(item.path);
                                            }}
                                          >
                                            {item.label}
                                          </Menu.Item>
                                        );
                                      })}
                                    </Menu.SubMenu>
                                  )}
                              </React.Fragment>
                            );
                          } else {
                            return (
                              <React.Fragment key={index}>
                                {!(
                                  adminState === "admin" && title === "My Jobs"
                                ) && (
                                    <Menu.Item
                                      style={{
                                        // background: "##FDB913",
                                        background: "#FDB913",
                                        width: "100%",
                                      }}
                                      onClick={() => {
                                        dispatch(setCurrentProject(-1));
                                        history.push(path);
                                      }}
                                      key={index}
                                      icon={Icon && <Icon />}
                                    >
                                      {title}
                                    </Menu.Item>
                                  )}
                              </React.Fragment>
                            );
                          }
                        }
                        return <Fragment key={index} />;
                      }
                    )}
                    <Menu.Item
                      onClick={() =>
                        window.open(
                          "https://docs.google.com/forms/d/1I-HIuHblMIffVcHlgkFQtGASzmnrsKJx9Mh_YPmjoK8/edit"
                        )
                      }
                    >
                      <img
                        src={bugIcon}
                        width={25}
                        height={23}
                        style={{
                          marginLeft: -3,
                          marginRight: 6,
                          marginTop: -5,
                        }}
                      />
                      {!collapse && <>Report a Bug</>}
                    </Menu.Item>
                  </>
                </Menu>
              </div>
            </div>
          </Sider>
        </div>
      )}
    </>
  );
};

export default SideBarComponent;
