import React from 'react';
import { Row, Col, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './IntegrationsC.less';
import IntegrationCard from '../../components/integration-card/IntegrationCard';
import RecommendationCard from '../../components/integration-card/RecommendedCard';

type Props = {};

const IntegrationsC = (props: Props) => {
  return (
    <div className="admin-integrations-root">
      <Row className="integration-header" justify="space-between">
        <Col>
          <h1>Integrations</h1>
        </Col>
        <Col>
          <Input
            prefix={<SearchOutlined />}
            className="search-integrations"
            placeholder="Search Integrations"
          />
        </Col>
      </Row>
      <hr style={{ border: '1px solid #E8E8E8' }} />
      <div className="integrations-container">
        <h1>Added Integrations</h1>
        <Row style={{ marginTop: 20 }} gutter={[20, 20]}>
          <Col span={8}>
            <IntegrationCard />
          </Col>
          <Col span={8}>
            <IntegrationCard />
          </Col>
          <Col span={8}>
            <IntegrationCard />
          </Col>
        </Row>

        <div style={{ marginTop: 40 }}>
          <h1>Recommended</h1>
          <Row style={{ marginTop: 20 }} gutter={[20, 20]}>
            <Col span={8}>
              <RecommendationCard />
            </Col>
            <Col span={8}>
              <RecommendationCard />
            </Col>
            <Col span={8}>
              <RecommendationCard />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default IntegrationsC;
