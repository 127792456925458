import React, { PropsWithChildren, useState, useEffect } from "react";

import { Col, Layout, Row } from "antd";
import JobCards from "../../containers/job-cards/JobCards";

const { Content } = Layout;

const MyJobs: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [userSearched, setUserSearched] = useState("");
  const [showEmpty, setShowEmpty] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <div>
      <JobCards />
    </div>
  );
};

export default MyJobs;
