import React from "react";
import { Row, Col, Button } from "antd";
import AssociatesCard from "../../components/associates-card/AssociatesCard";
import "./Profiles.less";
import { useHistory } from "react-router-dom";
type Props = {};

const Profiles = (props: Props) => {
  const history = useHistory();
  return (
    <div>
      <h1 className="user-profiles-text">User Profiles</h1>
      {/* <Row style={{ marginTop: 20 }} gutter={[40, 40]}>
        <Col span={8}>
          <AssociatesCard />
        </Col>
        <Col span={8}>
          <AssociatesCard />
        </Col>
        <Col span={8}>
          <AssociatesCard />
        </Col>
        <Col span={8}>
          <AssociatesCard />
        </Col>
        <Col span={8}>
          <AssociatesCard />
        </Col>
        <Col span={8}>
          <AssociatesCard />
        </Col>
      </Row> */}
      <Row style={{ marginTop: 60 }} justify="center">
        <Button
          onClick={() => history.push("/create-account")}
          className="profiles-add-user"
          type="primary"
        >
          Add New User
        </Button>
      </Row>
    </div>
  );
};

export default Profiles;
