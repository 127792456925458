import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, Select, message, Modal } from "antd";

import "./DiscountWarning.less";

type Props = {};

const DiscountWarning = (props: Props) => {
  return (
    <div style={{ backgroundColor: "#FFF8E7", padding: 2, marginBottom: 10 }}>
      <h1 style={{ fontWeight: "bold", padding: 10 }}>
        Warning: It is a long established fact that a reader will be distracted
        by the readable content of a page when looking at its layout.{" "}
      </h1>
    </div>
  );
};

export default DiscountWarning;
