import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "antd";
import { RootStateOrAny, useSelector } from "react-redux";

const Building: React.FC<{ option: any }> = ({ option }) => {
  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const [notesValue, setNotesValue] = useState("");
  useEffect(() => {
    let notesWIthLink = addLinkToText(
      option?.optionInfo?.proposalGeneralDescription
    );
    setNotesValue(notesWIthLink);
  }, []);

  const addLinkToText = (text: string): string => {
    const regex = /\[([^\]]+)\]/g; // Matches anything between square brackets.

    return text?.replace(regex, (_: string, group1: string) => {
      let url = group1;

      if (!url.startsWith("http://") && !url.startsWith("https://")) {
        url = "http://" + url;
      }

      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${group1}</a>`;
    });
  };

  const columns = [
    {
      title: "Surface",
      dataIndex: "item",
      width: "15%",
    },
    {
      title: "Product",
      width: "38%",
      render: (data: any) => {
        return (
          <>
            {data?.primeSelect === "spot" ? (
              // <p>{`${data?.paintMaterial?.manufacturer || ""}:${
              //   data?.paintMaterial?.product || ""
              // }:${data?.paintMaterial?.sheen || ""}`}</p>
              <p>
                {data.customerSuppliedPaint
                  ? "Customer Supplied"
                  : [
                      data?.paintMaterial?.manufacturer,
                      data?.paintMaterial?.product,
                      data?.paintMaterial?.sheen,
                    ]
                      .filter(Boolean)
                      .join(":")}
              </p>
            ) : (
              <>
                {/* <p>{`${data?.primerMaterial?.manufacturer || ""}:${
                  data?.primerMaterial?.product || ""
                }:${data?.primerMaterial?.sheen || ""}`}</p> */}
                <p>
                  {data.customerSuppliedPrimer
                    ? "Customer Supplied"
                    : [
                        data?.primerMaterial?.manufacturer,
                        data?.primerMaterial?.product,
                        data?.primerMaterial?.sheen,
                      ]
                        .filter(Boolean)
                        .join(":")}
                </p>
                <p>
                  {data.customerSuppliedPaint
                    ? "Customer Supplied"
                    : [
                        data?.paintMaterial?.manufacturer,
                        data?.paintMaterial?.product,
                        data?.paintMaterial?.sheen,
                      ]
                        .filter(Boolean)
                        .join(":")}
                </p>
                {/* <p>{`${data?.paintMaterial?.manufacturer || ""}:${
                  data?.paintMaterial?.product || ""
                }:${data?.paintMaterial?.sheen || ""}`}</p> */}
              </>
            )}
            {/* {data && <>{`${data?.paintMaterial.manufacturer}: ${data?.paintMaterial.product}`}</>} */}
          </>
        );
      },
    },
    {
      title: (
        <>
          <Row>
            <Col>
              <div
                style={{
                  height: 20,
                  width: 20,
                  // marginTop:8,
                  marginRight: 20,
                }}
              />
            </Col>
            <Col>Color</Col>
          </Row>
        </>
      ),
      width: "38%",
      // dataIndex: "color",
      render: (data: any) => {
        let response = adminDefaults.colors.find(
          (item: any) => item.name === data.color
        );
        return (
          <>
            {data.primeSelect === "full" && (
              <Row>
                <Col>
                  <div
                    style={{
                      // background: response?.hexCode,
                      height: 20,
                      width: 20,
                      marginRight: 20,
                    }}
                  />
                </Col>
                <Col>
                  <p>N/A</p>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <div
                  style={{
                    background: response?.hexCode,
                    height: 20,
                    width: 20,
                    marginRight: 20,
                  }}
                />
              </Col>
              <Col>
                <p>{data.color}</p>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Coats",
      width: "9%",
      render: (data: any) => {
        return (
          <>
            {data?.primeSelect === "spot" ? (
              <p>{data?.coats}</p>
            ) : (
              <>
                <p>{data?.primeSelect === "full" ? "Full Prime" : "Spot"}</p>
                <p>{data?.coats}</p>
              </>
            )}
            {/* <p>{data?.primerCoats === "full" ? "Full Prime" : "Spot"}</p>
            <p>{data?.coats}</p> */}
          </>
        );
      },
    },
  ];

  return (
    <>
      <h3
        style={{
          fontSize: 19,
          color: "#1F1F1F",
          fontWeight: 600,
        }}
      >
        {option?.optionInfo?.title}
      </h3>
      <pre
        className="building-description"
        style={{ padding: "10px 16px 12px 0px", margin: 0 }}
        dangerouslySetInnerHTML={{ __html: notesValue }}
      />
      <div className="pjcc--listing table-title">
        <div>
          {(() => {
            const filteredPaintRates = option?.rates?.paintRates.filter(
              (item: any) =>
                item.count > 0 &&
                (item.isDeleted === false || item.isDeleted === undefined) &&
                item.showLaborRates
            );
            console.log(filteredPaintRates);

            return filteredPaintRates.length > 0 ? (
              <Table
                rowKey="_id"
                columns={columns}
                dataSource={filteredPaintRates}
                pagination={false}
              />
            ) : null;
          })()}
        </div>
      </div>
    </>
  );
};

export default Building;
