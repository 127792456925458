import api from "../../utils/api";

const pushSetupDefaults = (payload: any) => ({
  type: "PUSH_SETUP_DEFAULTS",
  payload,
});

const pushSurfaceDefaults = (payload: any) => ({
  type: "PUSH_SURFACE_DEFAULTS",
  payload,
});

const pushTermsAndConditionsDefaults = (payload: any) => ({
  type: "PUSH_TERMS_AND_CONDITIONS_DEFAULTS",
  payload,
});

const addSetupDefaults = (payload: any) => ({
  type: "ADD_SETUP_DEFAULTS",
  payload,
});

const updateSetupDefaults = (payload: any) => ({
  type: "UPDATE_SETUP_DEFAULTS",
  payload,
});

const updateSurfaceDefaults = (payload: any) => ({
  type: "UPDATE_SURFACE_DEFAULTS",
  payload,
});

const updateTermsAndConditionsDefaults = (payload: any) => ({
  type: "UPDATE_TERMS_AND_CONDITIONS_DEFAULTS",
  payload,
});

const addSurfaceDefaults = (payload: any) => ({
  type: "ADD_SURFACE_DEFAULTS",
  payload,
});

const addTermsAndConditionsDefaults = (payload: any) => ({
  type: "ADD_TERMS_AND_CONDITIONS_DEFAULTS",
  payload,
});

const removeSurfaceDefaults = (payload: any) => ({
  type: "REMOVE_SURFACE_DEFAULTS",
  payload,
});

const removeTermsAndConditionsDefaults = (payload: any) => ({
  type: "REMOVE_TERMS_AND_CONDITIONS_DEFAULTS",
  payload,
});

const removeSetupDefaults = (payload: any) => ({
  type: "REMOVE_SETUP_DEFAULTS",
  payload,
});

export const updateSetup = (data: any) => (dispatch: any) => {
  api.patch(`proposal/setup/${data._id}`, data).then(() => {
    dispatch(updateSetupDefaults(data));
  });
};

export const updateSurface = (data: any) => (dispatch: any) => {
  api.patch(`proposal/surface/${data._id}`, data).then((resp: any) => {
    dispatch(updateSurfaceDefaults(data));
  });
};

export const deleteSetupDefaults = (id: string) => (dispatch: any) => {
  api.delete(`proposal/setup/${id}`).then(() => {
    dispatch(removeSetupDefaults(id));
  });
};

export const deleteSurfaceDefaults = (id: string) => (dispatch: any) => {
  api.delete(`proposal/surface/${id}`).then(() => {
    dispatch(removeSurfaceDefaults(id));
  });
};

export const postSetupDefaults = (data: any) => (dispatch: any) => {
  api.post("proposal/setup", data).then((response: any) => {
    dispatch(pushSetupDefaults(response));
  });
};

export const fetchSetupDefaults = (type: string) => (dispatch: any) => {
  api.get(`proposal/setup/${type}`).then((response: any) => {
    dispatch(addSetupDefaults(response));
  });
};

export const postSurfaceDefaults = (data: any) => (dispatch: any) => {
  api.post("proposal/surface", data).then((response: any) => {
    dispatch(pushSurfaceDefaults(response));
  });
};

export const fetchSurfaceDefaults = (type: string) => (dispatch: any) => {
  api.get(`proposal/surface/${type}`).then((response: any) => {
    dispatch(addSurfaceDefaults(response));
  });
};

export const postTermsAndConditionsDefaults =
  (data: any) => (dispatch: any) => {
    api.post("proposal/terms", data).then((response: any) => {
      dispatch(pushTermsAndConditionsDefaults(response));
    });
  };

export const updateTermsAndConditions = (data: any) => (dispatch: any) => {
  api.patch(`proposal/terms/${data._id}`, data).then((resp: any) => {
    dispatch(updateTermsAndConditionsDefaults(data));
  });
};

export const deleteTermsAndConditions = (id: string) => (dispatch: any) => {
  api.delete(`proposal/terms/${id}`).then(() => {
    dispatch(removeTermsAndConditionsDefaults(id));
  });
};

export const fetchTermsAndConditionsDefaults =
  (type: string) => (dispatch: any) => {
    api.get(`proposal/terms/${type}`).then((response: any) => {
      dispatch(addTermsAndConditionsDefaults(response));
    });
  };
