import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useEffect } from "react";
import "./AddItemDefaultModal.less";
const { Option } = Select;
type Props = {
  showSingleItemModal: boolean;
  toggleSingleItemModal: () => void;
  handleAdd: (values: any) => void;
  item?: any;
  edit?: boolean;
};

const AddCarpentryCost = (props: Props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(props.item).length > 0) {
      form.setFieldsValue(props.item);
    } else {
      form.resetFields();
    }
  }, [props.item, props.showSingleItemModal]);

  const onFinish = (values: any) => {
    props.handleAdd(values);
  };

  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };
  return (
    <Modal
      className="default-modal-rt"
      centered={true}
      visible={props.showSingleItemModal}
      onOk={props.toggleSingleItemModal}
      onCancel={props.toggleSingleItemModal}
      footer={false}
      title={
        <h1 style={{ marginTop: 10 }} className="default-modal-add-item">
          {!props.edit ? "Add item" : "Edit item"}
        </h1>
      }
    >
      <div className="default-material-form">
        <Form
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="item"
                label={<h2>Item Name</h2>}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  checkEmpty,
                ]}
              >
                <Input className="default-material-form-input" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="type"
                label={<h2>Type</h2>}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select dropdownMatchSelectWidth={false}>
                  <Option value="EA">EA</Option>
                  <Option value="LF">LF</Option>
                  <Option value="SQFT">SQFT</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="laborRate"
                label={<h2>Labor/Unit cost</h2>}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input type="number" className="default-material-form-input" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="materialRate"
                label={<h2>Material/Unit cost</h2>}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input type="number" className="default-material-form-input" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10} align="middle" justify="end">
            <Col>
              <Button
                onClick={props.toggleSingleItemModal}
                className="default-material-cancel-btn"
              >
                Cancel
              </Button>
            </Col>

            <Col>
              <Button
                htmlType="submit"
                type="primary"
                className="default-material-add-btn"
              >
                {!props.edit ? "Add Entry" : "Update Entry"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default AddCarpentryCost;
