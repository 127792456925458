export const data = [
  {
    key: '1',
    name: {
      title: 'Spirit Lake Exteriors',
      contact: '00-00000-0',
      img: 'icons/project.svg',
    },
    type: { main: 'Commercial', sub: 'interior' },
    segment: 'Condo/HOA',
    users: ['icons/user1.svg', 'icons/user2.svg', 'icons/user3.svg'],
    status: 'New Lead',
    date: 'March 07, 2021',
    action: '...',
  },
  {
    key: '2',
    name: {
      title: 'pirit Lake Exteriors',
      contact: '00-00000-0',
      img: 'icons/project.svg',
    },
    type: { main: 'Residential', sub: 'interior' },
    segment: 'Condo/HOA',
    users: ['icons/user1.svg', 'icons/user2.svg'],
    status: 'New Lead',
    date: 'March 07, 2021',
    action: '...',
  },
  {
    key: '3',
    name: {
      title: 'Apirit Lake Exteriors',
      contact: '00-00000-0',
      img: 'icons/project.svg',
    },
    type: { main: 'Commercial', sub: 'interior' },
    segment: 'Light Industrial',
    users: ['icons/user1.svg', 'icons/user2.svg', 'icons/user3.svg'],
    status: 'In Progress',
    date: 'March 07, 2021',
    action: '...',
  },
  {
    key: '4',
    name: {
      title: 'dpirit Lake Exteriors',
      contact: '00-00000-0',
      img: 'icons/project.svg',
    },
    type: { main: 'Commercial', sub: 'interior' },
    segment: 'Condo/HOA',
    users: ['icons/user1.svg', 'icons/user2.svg'],
    status: 'New Lead',
    date: 'March 08, 2021',
    action: '...',
  },
  {
    key: '5',
    name: {
      title: 'Spirit Lake Exteriors',
      contact: '00-00000-0',
      img: 'icons/project.svg',
    },
    type: { main: 'Commercial', sub: 'interior' },
    segment: 'Light Industrial',
    users: ['icons/user1.svg', 'icons/user2.svg', 'icons/user3.svg'],
    status: 'New Lead',
    date: 'March 07, 2021',
    action: '...',
  },
  {
    key: '6',
    name: {
      title: 'Spirit Lake Exteriors',
      contact: '00-00000-0',
      img: 'icons/project.svg',
    },
    type: { main: 'Residential', sub: 'interior' },
    segment: 'Light Industrial',
    users: ['icons/user1.svg', 'icons/user2.svg', 'icons/user3.svg'],
    status: 'New Lead',
    date: 'March 07, 2021',
    action: '...',
  },
  {
    key: '7',
    name: {
      title: 'Spirit Lake Exteriors',
      contact: '00-00000-0',
      img: 'icons/project.svg',
    },
    type: { main: 'Commercial', sub: 'interior' },
    segment: 'Light Industrial',
    users: ['icons/user1.svg', 'icons/user2.svg', 'icons/user3.svg'],
    status: 'New Lead',
    date: 'March 07, 2021',
    action: '...',
  },

  {
    key: '8',
    name: {
      title: 'Spirit Lake Exteriors',
      contact: '00-00000-0',
      img: 'icons/project.svg',
    },
    type: { main: 'Commercial', sub: 'interior' },
    segment: 'Condo/HOA',
    users: ['icons/user1.svg', 'icons/user2.svg', 'icons/user3.svg'],
    status: 'New Lead',
    date: 'March 07, 2021',
    action: '...',
  },
  {
    key: '9',
    name: {
      title: 'pirit Lake Exteriors',
      contact: '00-00000-0',
      img: 'icons/project.svg',
    },
    type: { main: 'Residential', sub: 'interior' },
    segment: 'Condo/HOA',
    users: ['icons/user1.svg', 'icons/user2.svg'],
    status: 'New Lead',
    date: 'March 07, 2021',
    action: '...',
  },
  {
    key: '10',
    name: {
      title: 'Apirit Lake Exteriors',
      contact: '00-00000-0',
      img: 'icons/project.svg',
    },
    type: { main: 'Commercial', sub: 'interior' },
    segment: 'Light Industrial',
    users: ['icons/user1.svg', 'icons/user2.svg', 'icons/user3.svg'],
    status: 'In Progress',
    date: 'March 07, 2021',
    action: '...',
  },
  {
    key: '11',
    name: {
      title: 'dpirit Lake Exteriors',
      contact: '00-00000-0',
      img: 'icons/project.svg',
    },
    type: { main: 'Commercial', sub: 'interior' },
    segment: 'Condo/HOA',
    users: ['icons/user1.svg', 'icons/user2.svg'],
    status: 'New Lead',
    date: 'March 08, 2021',
    action: '...',
  },
];
