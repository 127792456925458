import { Input, Row, Col, Rate, Select } from "antd";
import { RectangleIcon } from "../../utils/icons";
import "../../containers/OptionDetails/OptionDetails.less";
import { RootStateOrAny, useSelector } from "react-redux";
import { useEffect, useState } from "react";

type Props = {
  record?: any;
  index?: number;
  handleCost?: any;
  handleTax?: any;
  handleDelivery?: any;
  handleSurcharges?: any;
};

const ExpandedEquipmentRow = (props: Props) => {
  return (
    <div style={{ padding: "0px 78px 0px 260px" }}>
      <Row gutter={[100, 30]}>
        <Col>
          <div className="table-form-group">
            <span>Cost</span>
            <Input
              style={{ width: "100px" }}
              type="number"
              onChange={(e: any) =>
                props.handleCost(e, props.index, props?.record?._id)
              }
              value={props?.record?.cost}
            />
          </div>
        </Col>
        <Col className="gutter-row">
          <div className="table-form-group">
            <span>Delivery</span>
            <Input
              style={{ width: "100px" }}
              onChange={(e: any) =>
                props.handleDelivery(e, props.index, props?.record?._id)
              }
              type="number"
              value={props.record?.puAndDelivery}
            />
          </div>
        </Col>
        <Col className="gutter-row">
          <div className="table-form-group">
            <span>Surcharges</span>
            <Input
              style={{ width: "100px" }}
              onChange={(e: any) =>
                props.handleSurcharges(e, props.index, props?.record?._id)
              }
              type="number"
              value={props.record?.surcharges}
            />
          </div>
        </Col>

        <Col className="gutter-row">
          <div className="table-form-group">
            <span>Tax %</span>
            <Input
              style={{ width: "100px" }}
              type="number"
              onChange={(e: any) =>
                props.handleTax(e, props.index, props?.record?._id)
              }
              value={props.record?.tax}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ExpandedEquipmentRow;
