import React from 'react';
import { Row, Col, Button, Table } from 'antd';
import { columns } from './config';
import '../PaintSurfacesDefaults.less';

type Props = {};

const MiscDefaults = (props: Props) => {
  const data = new Array(6).fill({
    item: 'AC Junction',
    type: 'EA',
    cost_unit: '$30',
    action: '',
  });
  return (
    <div style={{ marginTop: 75 }}>
      <Row className="paint-surface-defaults-container" justify="space-between">
        <Col>
          <h1>Misc</h1>
        </Col>
        <Col>
          <Button className="labor-rates-defaults-buttons">Add Misc</Button>
        </Col>
      </Row>
      <Table dataSource={data} columns={columns} pagination={false} />
    </div>
  );
};

export default MiscDefaults;
