import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Space,
  Tooltip,
} from "antd";
import { DownOutlined, MenuOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";

import { getAuthUser } from "../../utils/authToken";
import { logout } from "../../redux/user/action";
import { clearOfflineData } from "../../redux/project/action";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/images/Pro-Driven-Edit.jpg";
import collapse from "antd/lib/collapse";

const { Header } = Layout;

type Props = {
  title: string;
  handleMenuClick: any;
  collapse: boolean;
  onCollapse: (collapsed: boolean) => void;
  onToggleSidebar: (item: any) => void;
};

const HeaderComponent = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:850px)" });
  const isMobile = useMediaQuery({ query: "(max-width:600px)" });
  function truncateText(text: any, maxLength: any) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    } else {
      return text;
    }
  }

  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  // const user = getAuthUser();

  const history = useHistory();
  const dispatch = useDispatch();
  const { online, outbox } = useSelector(
    (state: RootStateOrAny) => state.offline
  );
  const [initials, setInitials] = useState("");
  const { currentProject, items } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  useEffect(() => {
    const initials = getInitials(user?.fullName);
    setInitials(initials);
  }, []);

  const getInitials = (name: string) => {
    try {
      const nameArray = name.split(" ");
      const firstName = nameArray[0];
      const lastName = nameArray[nameArray.length - 1];
      if (nameArray.length === 1) {
        const initials = firstName.charAt(0);
        return initials.toUpperCase();
      } else {
        const initials = firstName.charAt(0) + lastName.charAt(0);
        return initials.toUpperCase();
      }
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  const adminItem = [
    {
      key: "1",
      label: (
        <div onClick={props.handleMenuClick} style={{ fontWeight: "bold" }}>
          Switch to {props.title}
        </div>
      ),
    },
  ];
  let menuList = [
    {
      key: "2",
      label: (
        <div
          onClick={() => history.push("/Setting")}
          style={{ fontWeight: "bold" }}
        >
          Settings
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          onClick={() => {
            localStorage.clear();
            dispatch(logout());
            dispatch(clearOfflineData());
            history.push("/login");
          }}
          style={{ fontWeight: "bold" }}
        >
          Logout
        </div>
      ),
    },
  ];
  if (user?.roles[0] === "admin") {
    menuList = [...adminItem, ...menuList];
  }

  const menu = <Menu items={menuList} />;
  return (
    <Header
      className="site-layout-sub-header-background"
      style={{
        padding: 0,
        backgroundColor: "white",
      }}
    >
      {isTabletOrMobile && (props.collapse || !props.collapse) ? (
        <Row style={{ justifyContent: "space-between" }}>
          <Col>
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "125px",
                  borderRadius: 10,
                  marginLeft: "25px",
                }}
              />
            </Link>

            <MenuOutlined
              onClick={() => {
                props.onToggleSidebar(false);
              }}
            />
          </Col>

          {isMobile ? null : (
            <Col>
              <p
                style={{ fontWeight: "bold", fontSize: "20px", marginLeft: 50 }}
              >
                {currentProject >= 0
                  ? truncateText(items[currentProject].projectInfo.jobName, 30)
                  : ""}
              </p>
            </Col>
          )}

          <Col>
            <Row style={{ marginRight: 20 }} gutter={10}>
              <Col onClick={() => history.push("/user-profile")}>
                {user?.picture && online ? (
                  <>
                    <Avatar
                      style={{ cursor: "pointer" }}
                      size={42}
                      src={user?.picture}
                    />
                  </>
                ) : (
                  <Avatar
                    style={{
                      // backgroundColor: "#f56a00",
                      // color: "#fff",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                    size={42}
                  >
                    {initials}
                  </Avatar>
                )}
              </Col>
              <Col>
                {online ? (
                  <>
                    {outbox.length === 0 ? (
                      <Tooltip
                        title="Online"
                        placement="topLeft"
                        color="#00c20d"
                      >
                        <Badge status="success" />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Not-Sync"
                        placement="topLeft"
                        color="#ebc334"
                      >
                        <Badge status="warning" />
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <Tooltip title="Offline" placement="topLeft" color="#d61818">
                    <Badge status="error" />
                  </Tooltip>
                )}
              </Col>
              <Col>
                <Dropdown trigger={["click"]} overlay={menu}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row style={{ justifyContent: "space-between" }}>
          <col span={5}></col>
          {isMobile ? null : (
            <Col>
              <p
                style={{ fontWeight: "bold", fontSize: "20px", marginLeft: 50 }}
              >
                {currentProject >= 0
                  ? truncateText(items[currentProject].projectInfo.jobName, 50)
                  : ""}
              </p>
            </Col>
          )}

          <Col>
            <Row style={{ marginRight: 20 }} gutter={10}>
              <Col onClick={() => history.push("/user-profile")}>
                {user?.picture && online ? (
                  <>
                    <Avatar
                      style={{ cursor: "pointer" }}
                      size={42}
                      src={user?.picture}
                    />
                  </>
                ) : (
                  <Avatar
                    style={{
                      // backgroundColor: "#f56a00",
                      // color: "#fff",
                      fontWeight: "bold",
                      fontSize: 20,
                    }}
                    size={42}
                  >
                    {initials}
                  </Avatar>
                )}
              </Col>
              <Col>
                {online ? (
                  <>
                    {outbox.length === 0 ? (
                      <Tooltip
                        title="Online"
                        placement="topLeft"
                        color="#00c20d"
                      >
                        <Badge status="success" />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Not-Sync"
                        placement="topLeft"
                        color="#ebc334"
                      >
                        <Badge status="warning" />
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <Tooltip title="Offline" placement="topLeft" color="#d61818">
                    <Badge status="error" />
                  </Tooltip>
                )}
              </Col>
              <Col>
                <Dropdown trigger={["click"]} overlay={menu}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Header>
  );
};

export default HeaderComponent;
