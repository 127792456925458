import { useEffect } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setActiveProject } from "../redux/project/action";

const useActiveProject = () => {
  const { id } = useParams<{ id: string }>();
  const { items } = useSelector((state: RootStateOrAny) => state.offlineData);
  const dispatch = useDispatch();

  useEffect(() => {
    const activeProjectIndex = items.findIndex(
      (project: any) => project.projectId === id
    );

    dispatch(setActiveProject(activeProjectIndex));
  }, [id, items, dispatch]);
};
export default useActiveProject;
