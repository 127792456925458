import React, { PropsWithChildren, useState, useEffect } from "react";
import { data } from "../../containers/available-jobs/data";
import { Layout, Table, Input, Tag, Menu, Avatar, Dropdown } from "antd";
import moment from "moment";
import { MoreActionIcon } from "../../utils/icons";
import { SearchOutlined } from "@ant-design/icons";
import EmptyScreen from "../../containers/available-jobs/EmptyScreen";

const { Content } = Layout;

const LandedJobs: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [userSearched, setUserSearched] = useState("");
  const [showEmpty, setShowEmpty] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
    if (!data) {
      setShowEmpty(true);
    }
  }, []);

  const HandleMenuClick = (e: any) => { };

  const actionData = [
    {
      title: "Print Job Jacket",
      handleClick: () => { },
    },
    {
      title: "Create Work Order",
      handleClick: () => { },
    },
    {
      title: "Post Job",
      handleClick: () => { },
    },
  ];

  const menu = (
    <Menu>
      {actionData.map((item, index) => (
        <Menu.Item
          style={{
            fontWeight: 700,
            fontSize: "15px",
            lineHeight: "18px",
            color: "#333333",
          }}
          key={index}
        >
          <span className="nav-text" onClick={item.handleClick}>
            {item.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const columns = [
    {
      title: "Project Name",
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.title.localeCompare(b.name.title),
      render: (data: any) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ paddingTop: "7px" }}>
              <img src={data.img} alt="project" width="39" height="39"></img>
            </div>
            <div style={{ paddingLeft: "15px" }}>
              <p
                style={{
                  margin: "0",
                  color: " #1F1F1F",
                  fontSize: "16px",
                  fontStyle: "normal",
                }}
              >
                {data.title}
              </p>
              <p
                style={{
                  fontSize: "12px",
                  color: "rgba(51, 51, 51, 0.7)",
                }}
              >
                {data.contact}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: (a: any, b: any) => a.type.main.localeCompare(b.type.main),
      render: (data: any) => {
        return (
          <div>
            <p
              style={{
                fontSize: "16px",
                color: " #1F1F1F",
                margin: "0",
              }}
            >
              {data.main}
            </p>
            <p
              style={{
                fontSize: "12px",
                color: "rgba(51, 51, 51, 0.7)",
              }}
            >
              {data.sub}
            </p>
          </div>
        );
      },
      filters: [
        {
          text: "Commercial",
          value: "Commercial",
        },
        {
          text: "Residential",
          value: "Residential",
        },
      ],
      onFilter: (value: any, record: any) => {
        return record.type.main.indexOf(value) === 0;
      },
    },
    {
      title: "Segment",
      dataIndex: "segment",
      sorter: (a: any, b: any) => a.segment.localeCompare(b.segment),
      render: (data: any) => {
        return <p style={{ fontSize: "16px" }}>{data}</p>;
      },

      filters: [
        {
          text: "Condo/HOA",
          value: "Condo/HOA",
        },
        {
          text: "Light Industrial",
          value: "Light Industrial",
        },
      ],
      onFilter: (value: any, record: any) => {
        return record.segment.indexOf(value) === 0;
      },
    },
    {
      title: "Users",
      dataIndex: "users",
      filters: [
        {
          text: "New Lead",
          value: "New Lead",
        },
        {
          text: "In Progress",
          value: "In Progress",
        },
      ],
      onFilter: (value: any, record: any) => {
        return record.status.indexOf(value) === 0;
      },
      render: (data: any) => {
        return (
          <div>
            <Avatar.Group>
              {data.map((img: any, index: any) => (
                <Avatar key={index} src={img} />
              ))}
            </Avatar.Group>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      filters: [
        {
          text: "user1",
          value: "user1",
        },
        {
          text: "user2",
          value: "user2",
        },
      ],
      onFilter: (value: any, record: any) => {
        return record.status.indexOf(value) === 0;
      },
      render: (tag: any) => {
        const color = tag.includes("New Lead")
          ? "rgba(0, 180, 79, 0.19)"
          : "blue";
        return (
          <Tag
            style={{
              color: "black",
              borderRadius: "25px",
              width: "71px",
              height: "24px",
              textAlign: "center",
              fontSize: "12px",
              fontWeight: "500",
            }}
            color={color}
            key={tag}
          >
            {tag}
          </Tag>
        );
      },
    },
    {
      title: "Last Modified At",
      dataIndex: "date",
      sorter: (a: any, b: any) => moment(a.date).unix() - moment(b.date).unix(),
      render: (data: any) => {
        return <p style={{ fontSize: "16px" }}>{data}</p>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (value: any) => {
        return (
          <>
            <Dropdown overlay={menu} trigger={["click"]}>
              <button className="action-button">
                <MoreActionIcon />{" "}
              </button>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const found = data.filter((user) => {
    if (!userSearched) {
      return true;
    }
    return user.name.title.toLowerCase().includes(userSearched.toLowerCase());
  });

  return (
    <div>
      {showEmpty ? (
        <EmptyScreen fetchAllJobs="" showButton={false} />
      ) : (
        <div className="content--container">
          <Content style={{ margin: "24px 0px 0" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h1
                style={{
                  color: "#1F1F1F",
                  fontSize: "24px",
                  fontWeight: "bold",
                  paddingLeft: "20px",
                }}
              >
                Landed Jobs
              </h1>
            </div>
            <hr style={{ border: "1px solid #E8E8E8", width: "100%" }} />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "15px 0px 0px 20px",
              }}
            >
              <Input
                prefix={<SearchOutlined />}
                style={{ width: "332px", height: "40px" }}
                placeholder="Search jobs"
                onChange={(e) => {
                  setUserSearched(e.target.value);
                }}
              />
            </div>

            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 360 }}
            >
              <Table
                rowClassName="table_row"
                columns={columns}
                dataSource={found}
                pagination={{
                  pageSize: 10,
                }}
              />
            </div>
          </Content>
        </div>
      )}
    </div>
  );
};

export default LandedJobs;
