import React, { useState } from "react";
import { Form, Input, Checkbox, Row, Col } from "antd";
import "./CreateForm.less";
import { MaskedInput } from "antd-mask-input";

const ClientContact: React.FC<{
  form: any;
  title: string;
}> = ({ title, form }) => {
  const [sameJobSiteAddress, setSameJobSiteAddress] = useState(false);
  const [cellRequired, setCellRequired] = useState(true);
  const [telephoneRequired, setTelephoneRequired] = useState(true);

  const handleRequired = () => {
    let telephone = form.getFieldValue(`${title}ContactTelephone`);
    let cell = form.getFieldValue(`${title}ContactCell`);

    if (!cell && !telephone) {
      setCellRequired(true);
      setTelephoneRequired(true);
      return;
    }
    if (telephone && !telephone.endsWith("_")) {
      setCellRequired(false);
      if (cell === "(___) ___-____") {
        form.setFieldValue(`${title}ContactCell`, "");
      }
    }
    if (cell && !cell.endsWith("_")) {
      setTelephoneRequired(false);
      setCellRequired(false);
      if (telephone === "(___) ___-____") {
        form.setFieldValue(`${title}ContactTelephone`, "");
      }
    }
  };

  const checkJobNumber = {
    validator(_: any, value: string) {
      if (value) {
        if (value.endsWith("_")) {
          return Promise.reject(new Error("Please enter 10 digit number"));
        }
      }

      return Promise.resolve();
    },
  };

  const handleChange = (e: any) => {
    setSameJobSiteAddress(e.target.checked ? true : false);
    if (e.target.checked) {
      setSameJobSiteAddress(true);
      form.setFieldsValue({
        [`${title}ContactAddress`]: form.getFieldValue("streetAddressOne"),
      });
    } else {
      setSameJobSiteAddress(false);
      form.setFieldsValue({
        [`${title}ContactAddress`]: "",
      });
    }
  };

  return (
    <div className="client-container">
      <h1 className="client-title">
        {title === "Maintenance" ? "Invoice" : title}
      </h1>
      <div className="job-form" style={{ marginTop: 23 }}>
        <Row gutter={17}>
          <Col span={12}>
            <div className="form-item">
              <Form.Item
                hasFeedback
                name={`${title}ContactName`}
                label={<h1 className="form-text">Name</h1>}
                rules={[
                  { required: true, message: "Please enter the Name!" },
                  { whitespace: true },
                ]}
              >
                <Input className="form-input" />
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <div className="form-item">
              <Form.Item
                hasFeedback
                name={`${title}ContactTelephone`}
                label={<h1 className="form-text">Telephone</h1>}
                rules={[
                  {
                    required: telephoneRequired,
                    message: "Please enter the telephone!",
                  },
                  { whitespace: true },
                  checkJobNumber,
                ]}
                validateTrigger={["onBlur", "onChange"]}
              >
                <MaskedInput
                  onBlur={handleRequired}
                  mask={"(000) 000-0000"}
                  className="form-input"
                />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row gutter={17}>
          <Col span={12}>
            <div className="form-item">
              <Form.Item
                hasFeedback
                name={`${title}ContactCell`}
                label={<h1 className="form-text">Cell</h1>}
                rules={[
                  { required: cellRequired, message: "Please enter the cell!" },
                  { whitespace: true },
                  checkJobNumber,
                ]}
                validateTrigger={["onBlur", "onChange"]}
              >
                <MaskedInput
                  onBlur={handleRequired}
                  mask={"(000) 000-0000"}
                  className="form-input"
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <div className="form-item">
              <Form.Item
                hasFeedback
                name={`${title}ContactEmail`}
                label={<h1 className="form-text">Email</h1>}
                rules={[
                  { type: "email", message: "Enter valid email" },
                  { required: true, message: "Enter the email" },
                ]}
              >
                <Input className="form-input" />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <div className="form-item">
              <Form.Item
                hasFeedback
                name={`${title}ContactAddress`}
                label={<h1 className="form-text">Address</h1>}
                rules={[
                  { required: true, message: "Enter the address" },
                  { whitespace: true },
                ]}
              >
                <Input className="form-input" />
              </Form.Item>
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <Checkbox onChange={handleChange}>
          <p className="checkbox-text">Same as jobsite address</p>
        </Checkbox>
      </div>
    </div>
  );
};

export default ClientContact;
