import { Input, Select } from 'antd';
import { MoreVerticalIcon } from '../../../utils/icons';

export const columns = [
  {
    title: 'Item',
    dataIndex: 'item',
    key: 'item',
    width: '200px',
    render: (data: any) => {
      return <Input defaultValue={data} />;
    },
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    width: '100px',
    render: (data: any) => {
      return <Input defaultValue={data} />;
    },
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: '120px',
    render: (data: any) => {
      return <Input defaultValue={data} />;
    },
  },
  {
    title: 'Periods',
    dataIndex: 'periods',
    key: 'periods',
    width: '100px',
    render: (data: any) => {
      return <Input defaultValue={data} />;
    },
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
    key: 'cost',
    width: '120px',
    render: (data: any) => {
      return <Input defaultValue={data} />;
    },
  },
  {
    title: 'Tax',
    dataIndex: 'tax',
    key: 'tax',
    render: (data: any) => {
      return <Input style={{ width: '100px' }} defaultValue={data} />;
    },
  },
  {
    title: 'Action',
    key: 'action',
    width: '80px',
    render: (data: any) => {
      return <MoreVerticalIcon />;
    },
  },
];
