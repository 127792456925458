import {
  Row,
  Col,
  Table,
  Spin,
  Button,
  message,
  Form,
  Modal,
  Input,
  Select,
  Card,
  List,
  Checkbox,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import api from "../../utils/api";
import { columns } from "./config";
import { useParams } from "react-router-dom";
import { EyeIcon, SearchIcon } from "../../utils/icons";
import React from "react";
import TenantSteps from "./TenantSteps";

const TenantUsers = () => {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<any>([]);
  const [tenantUsers, setTenantUsers] = useState<any>([]);
  const [checkedTenantUsers, setCheckedTenantUsers] = useState<any>([]);
  const [checkboxSearched, setCheckboxSearched] = useState("");

  const [tenant, setTenant] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editUser, setEditUser] = useState<any>(null);
  const [uniqueRoles, setUniqueRoles] = useState<string[]>([]);
  const [checkedItems, setCheckedItems] = useState<any[]>([]);

  const [isAddUserMode, setIsAddUserMode] = useState(true);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    setLoading(true);
    api
      .get(`tenants/${id}`)
      .then((response: any) => {
        setTenant(response);
      })
      .catch((err) => {
        setLoading(false);
      });

    api
      .get(`user`)
      .then((response: any) => {
        setUsers(
          response.filter(
            (item: any) =>
              !item.roles.some((role: string) => role === "super_admin")
          )
        );
      })
      .catch((err) => {
        setLoading(false);
      });
    api
      .get(`user/tenant/${id}`)
      .then((response: any) => {
        setLoading(false);
        setTenantUsers(
          response.filter(
            (item: any) =>
              !item.roles.some((role: string) => role === "super_admin")
          )
        );
        const allRoles: string[] = response
          .flatMap((user: any) => user.roles)
          .filter((role: string) => role);
        const uniqueRolesArray: string[] = Array.from(new Set(allRoles));
        setUniqueRoles(uniqueRolesArray);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const handleAddUser = () => {
    const checkedUsersForThisTenant = users.map((user: any) => {
      const tenantUserIndex = tenantUsers.findIndex(
        (tenantUser: any) => tenantUser._id === user._id
      );
      if (tenantUserIndex === -1) {
        return {
          ...user,
          checked: false,
        };
      } else {
        return {
          ...user,
          checked: true,
        };
      }
    });
    setCheckedTenantUsers(checkedUsersForThisTenant);
    setCheckedItems(
      checkedUsersForThisTenant.filter((user: any) => user.checked)
    );
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setCheckboxSearched(""); // Reset the search input field
    setCheckedItems([]); // Reset the checked items
    setIsModalVisible(false);
  };

  const handleOk = () => {
    const deletedItems = tenantUsers.filter((oldUser: any) => {
      if (
        checkedItems.findIndex((item: any) => item._id === oldUser._id) === -1
      ) {
        return oldUser._id;
      }
    });
    const addedItems = checkedItems.filter((checkedItem: any) => {
      if (
        tenantUsers.findIndex(
          (oldUser: any) => oldUser._id === checkedItem._id
        ) === -1
      ) {
        return checkedItem._id;
      }
    });

    const body = {
      deletedItems,
      addedItems,
    };
    api
      .patch(`user/add-delete-tenant/${id}`, body)
      .then((response: any) => {
        setTenantUsers(
          response.filter(
            (item: any) =>
              !item.roles.some((role: string) => role === "super_admin")
          )
        );
      })
      .catch((err) => {
        console.log(err);
      });
    setCheckboxSearched(""); // Reset the search input field
    setCheckedItems([]); // Reset the checked items
    setIsModalVisible(false);
  };

  const updatedColumns = [
    ...columns,
    {
      title: "Tenant",
      dataIndex: "tenants",
      render: (tenants: any) => {
        if (tenants.length > 0) {
          const tenantsNames = tenants
            .map((tenant: any) => {
              return tenant.tenantName;
            })
            .join(", ");
          return <>{tenantsNames}</>;
        } else {
          return <></>;
        }
      },
    },

    // {
    //   title: "Edit",
    //   render: (data: any) => {
    //     return (
    //       <EditOutlined
    //         onClick={() => handleEditUser(data)}
    //         style={{ color: "#FDB913", cursor: "pointer", fontSize: "20px" }}
    //       />
    //     );
    //   },
    // },
  ];
  const handleCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    itemId: string
  ) => {
    const updatedUsers = checkedTenantUsers.map((user: any) => {
      if (user._id === itemId) {
        return {
          ...user,
          checked: e.target.checked,
        };
      }
      return user;
    });
    setCheckedTenantUsers(updatedUsers);

    const checkedUser = checkedTenantUsers.find(
      (user: any) => user._id === itemId
    );
    if (checkedUser && e.target.checked) {
      setCheckedItems((prevCheckedItems) => [...prevCheckedItems, checkedUser]);
    } else {
      setCheckedItems((prevCheckedItems) =>
        prevCheckedItems.filter((item) => item._id !== itemId)
      );
    }
  };

  const handleToolTipString = (user: any) => {
    return (
      <>
        <table>
          <tr>
            <td style={{ padding: 10 }}>Job Title</td>
            <td style={{ padding: 10 }}>Email</td>
            <td style={{ padding: 10 }}>Roles</td>
            <td style={{ padding: 10 }}>Tenants</td>
          </tr>
          <tr>
            <td style={{ padding: 10 }}>{user.jobTitle}</td>
            <td style={{ padding: 10 }}>{user.email}</td>
            <td style={{ padding: 10 }}>{user.roles.join(", ")}</td>
            <td style={{ padding: 10 }}>
              {user.tenants.map((tenant: any) => tenant.tenantName).join(", ")}
            </td>
          </tr>
        </table>
      </>
    );
  };

  return (
    <>
      <TenantSteps current={0} />
      <Row className="pp-default-header" align="middle" justify="space-between">
        <Col>
          <h1 style={{ fontWeight: "bold", fontSize: "30px" }}>
            {tenant.tenantName} Users
          </h1>
        </Col>
        <Col>
          <Button
            className="pp-add-associates"
            type="primary"
            onClick={handleAddUser}
          >
            Add User
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <hr style={{ border: "1px solid #E8E8E8" }} />
          <div className="pp-default-table">
            <Table
              columns={updatedColumns}
              dataSource={tenantUsers}
              rowKey="email"
            />
            <Modal
              title="Tenant Users"
              style={{ fontWeight: "bold" }}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <Card className="checkbox-card">
                <Input
                  className="search-surface-input"
                  prefix={<SearchIcon />}
                  placeholder="Search surfaces"
                  onChange={(e) => {
                    setCheckboxSearched(e.target.value);
                  }}
                />

                <div className="checkbox-scroll-bar">
                  <List
                    bordered
                    style={{ height: 120 }}
                    dataSource={checkedTenantUsers
                      .filter((item: any) =>
                        item.fullName
                          .toLowerCase()
                          .includes(checkboxSearched.toLowerCase())
                      )
                      .sort((a: any, b: any) => {
                        if (a.checked !== b.checked) {
                          return a.checked ? -1 : 1;
                        }
                        const itemA = a.fullName.toLowerCase();
                        const itemB = b.fullName.toLowerCase();
                        if (itemA < itemB) {
                          return -1;
                        }
                        if (itemA > itemB) {
                          return 1;
                        }
                        return 0;
                      })}
                    rowKey="_id"
                    renderItem={(item: any, index: number) => (
                      <React.Fragment key={item._id}>
                        <List.Item className="surface-list-container">
                          <Checkbox
                            checked={item.checked}
                            onChange={(e: any) => {
                              handleCheckbox(e, item._id);
                            }}
                          >
                            <h1 className="surface-checkbox-text ">
                              {item.fullName}
                            </h1>
                          </Checkbox>
                          <Tooltip
                            placement="top"
                            title={() => handleToolTipString(item)}
                            overlayStyle={{
                              maxWidth: "80%",
                            }}
                            showArrow={false}
                          >
                            <EyeIcon
                              style={{
                                height: 20,
                                width: 25,
                                cursor: "pointer",
                                marginTop: -5,
                                marginRight: 10,
                              }}
                            />
                          </Tooltip>
                        </List.Item>
                      </React.Fragment>
                    )}
                  />
                </div>
              </Card>
              {checkedItems.length > 0 && (
                <div>
                  <h2>Checked Items:</h2>
                  <ul>
                    {checkedItems.map((item) => (
                      <li key={item._id}>
                        {item.fullName},
                        {item.tenants.map((tenant: any) => (
                          <span key={tenant.tenantId}>{tenant.tenantName}</span>
                        ))}
                        ,{item.roles.join(", ")}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Modal>
          </div>
        </>
      )}
    </>
  );
};

export default TenantUsers;
