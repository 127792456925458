import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { paintTitles } from "./config";
const { Option } = Select;
type Props = {
  showSingleItemModal: boolean;
  toggleSingleItemModal: () => void;
  handleAdd: (values: any) => void;
  item?: any;
  edit?: boolean;
  paintMaterials?: any;
  primerMaterials?: any;
};

const AddPaintCost = (props: Props) => {
  const [ratesState, setRatesState] = useState<any>({});
  const [form] = Form.useForm();
  useEffect(() => {
    setRatesState({
      prepLaborEasy: false,
      primeLaborEasy: false,
      prepLaborMedium: false,
      primeLaborMedium: false,
      prepLaborHard: false,
      primeLaborHard: false,
    });
    if (Object.keys(props.item).length > 0) {
      form.setFieldsValue({
        ...props.item,
        paintMaterial: props.item?.paintMaterial,
        primerMaterial: props.item?.primerMaterial,
      });
    } else {
      form.resetFields();
    }
  }, [props.item, props.showSingleItemModal]);
  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };
  const onFinish = (values: any) => {
    props.handleAdd(values);
  };

  const handleRatesChange = (e: any, name: string) => {
    if (!props.edit) {
      switch (name) {
        case "paintLaborEasy":
          if (!ratesState.prepLaborEasy) {
            form.setFieldsValue({
              prepLaborEasy: 4 * e.target.value,
            });
          }
          if (!ratesState.primeLaborEasy)
            form.setFieldsValue({
              primeLaborEasy: e.target.value,
            });
          break;
        case "paintLaborMedium":
          if (!ratesState.prepLaborMedium) {
            form.setFieldsValue({
              prepLaborMedium: 4 * e.target.value,
            });
          }
          if (!ratesState.primeLaborMedium) {
            form.setFieldsValue({
              primeLaborMedium: e.target.value,
            });
          }

          break;
        case "paintLaborHard":
          if (!ratesState.prepLaborHard) {
            form.setFieldsValue({
              prepLaborHard: 4 * e.target.value,
            });
          }
          if (!ratesState.primeLaborHard) {
            form.setFieldsValue({
              primeLaborHard: e.target.value,
            });
          }
          break;
        case "prepLaborEasy":
          setRatesState({
            ...ratesState,
            prepLaborEasy: true,
          });
          break;
        case "primeLaborEasy":
          setRatesState({
            ...ratesState,
            primeLaborEasy: true,
          });
          break;
        case "prepLaborMedium":
          setRatesState({
            ...ratesState,
            prepLaborMedium: true,
          });
          break;
        case "primeLaborMedium":
          setRatesState({
            ...ratesState,
            primeLaborMedium: true,
          });
          break;
        case "prepLaborHard":
          setRatesState({
            ...ratesState,
            prepLaborHard: true,
          });
          break;
        case "primeLaborHard":
          setRatesState({
            ...ratesState,
            primeLaborHard: true,
          });
          break;
        default:
          break;
      }
    }
  };
  return (
    <Modal
      className="default-modal-rt"
      centered={true}
      visible={props.showSingleItemModal}
      onOk={props.toggleSingleItemModal}
      onCancel={props.toggleSingleItemModal}
      footer={false}
      title={
        <h1 style={{ marginTop: 10 }} className="default-modal-add-item">
          {!props.edit ? "Add item" : "Edit item"}
        </h1>
      }
    >
      <div style={{ padding: "0px 25px" }} className="default-material-form">
        <Form
          form={form}
          onFinish={onFinish}
          scrollToFirstError
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            hasFeedback
            name="item"
            label={<h2>Item Name</h2>}
            rules={[
              {
                required: true,
                message: "",
              },
              checkEmpty,
            ]}
          >
            <Input className="default-material-form-input" />
          </Form.Item>

          <Row gutter={20}>
            {paintTitles.map((item: any, index: number) => {
              return (
                <Col key={index} span={8}>
                  <Form.Item
                    hasFeedback
                    name={item.name}
                    label={<h2>{item.label}</h2>}
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e: any) => handleRatesChange(e, item?.name)}
                      type="number"
                      className="default-material-form-input"
                    />
                  </Form.Item>
                </Col>
              );
            })}
          </Row>

          <Form.Item
            hasFeedback
            name="type"
            label={<h2>Type</h2>}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Select dropdownMatchSelectWidth={false}>
              <Option value="EA">EA</Option>
              <Option value="LF">LF</Option>
              <Option value="SQFT">SQFT</Option>
              <Option value="SQFT*1.25">SQFT*1.25</Option>
              <Option value="EA/SIDE">EA/SIDE</Option>
              <Option value="SQFT/SIDE">SQFT/SIDE</Option>
            </Select>
          </Form.Item>
          <Row gutter={25}>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="paintSpreadRate"
                label={<h2>Paint</h2>}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input type="number" className="default-material-form-input" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                hasFeedback
                name="primerSpreadRate"
                label={<h2>Prime</h2>}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input type="number" className="default-material-form-input" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={25}>
            <Col span={12}>
              {" "}
              <Form.Item
                hasFeedback
                name="paintMaterial"
                label={<h2>Paint Material</h2>}
              >
                <Select
                  showSearch
                  dropdownMatchSelectWidth={false}
                  style={{ caretColor: "transparent" }}
                  filterOption={(input, option) =>
                    (option?.children?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  // onChange={(value: string) => handlePrimeChange(value)}
                >
                  {props.paintMaterials.map((material: any, index: any) => (
                    <option key={index} value={material["_id"]}>
                      {`${material.manufacturer}: ${material.product}: ${material.sheen}`}
                    </option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              {" "}
              <Form.Item
                hasFeedback
                name="primerMaterial"
                label={<h2>Primer Material</h2>}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (option?.children?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  dropdownMatchSelectWidth={false}
                  style={{ caretColor: "transparent" }}
                  // onChange={(value: string) => handlePrimeChange(value)}
                >
                  {props.primerMaterials.map((material: any, index: any) => (
                    <option key={index} value={material["_id"]}>
                      {`${material.manufacturer}: ${material.product}: ${material.sheen}`}
                    </option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={10} align="middle" justify="end">
            <Col>
              <Button
                onClick={props.toggleSingleItemModal}
                className="default-material-cancel-btn"
              >
                Cancel
              </Button>
            </Col>

            <Col>
              <Button
                htmlType="submit"
                type="primary"
                className="default-material-add-btn"
              >
                {!props.edit ? "Add Entry" : "Update Entry"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default AddPaintCost;
