import React, { useState } from "react";
import { Row, Col, DatePicker, TimePicker, Checkbox, Radio } from "antd";
import moment from "moment";
import "./DeliveryDate.less";
import { useMediaQuery } from "react-responsive";

type Props = {
  onTimeChange: (date: any, dateString: string) => void;
  onDateChange: (date: any, dateString: string) => void;
  pickUp?: string;
  onPickUpChange?: (e: any) => void;
};

const SelectDeliveryDate = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:850px)" });

  return (
    <div className="deliver-date-root">
      <Row justify="center" style={{ padding: 20, marginTop: 30 }}>
        <Col>
          {props.pickUp === "pickUp" ? (
            <h1>Pick-Up Date & Time </h1>
          ) : (
            <h1>Delivery Date & Time </h1>
          )}
        </Col>
      </Row>

      <Row gutter={[15, 5]} justify="center">
        <Col>
          <DatePicker
            disabledDate={(current) => {
              return moment().add(-1, "days") >= current;
            }}
            className="delivery-date-selector"
            placeholder="Date"
            onChange={props.onDateChange}
          />
        </Col>
        <Col>
          <TimePicker
            className="delivery-date-selector"
            use12Hours
            format="h:mm a"
            placeholder="Time"
            minuteStep={15}
            onChange={props.onTimeChange}
          />
        </Col>
      </Row>
      {isTabletOrMobile ? (
        <Row style={{ marginTop: 30 }} justify="center">
          <Col>
            <Radio.Group
              onChange={props.onPickUpChange}
              value={props.pickUp}
              optionType="button"
              buttonStyle="solid"
            >
              <Row gutter={15} style={{ marginLeft: "50px" }}>
                <Col md={24} style={{ paddingBottom: "5px" }}>
                  <Radio
                    style={{ borderRadius: 5 }}
                    value="pickUp"
                    className="pick--up--button"
                  >
                    Pick Up
                  </Radio>
                </Col>
                <Col md={24}>
                  <Radio
                    style={{ borderRadius: 5 }}
                    value="delivery"
                    className="delivery--button"
                  >
                    Delivery
                  </Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Col>
        </Row>
      ) : (
        <Row style={{ marginTop: 30 }} justify="center">
          <Col>
            <Radio.Group
              onChange={props.onPickUpChange}
              value={props.pickUp}
              optionType="button"
              buttonStyle="solid"
            >
              <Row gutter={15}>
                <Col md={24} lg={12}>
                  <Radio
                    style={{ borderRadius: 5 }}
                    value="pickUp"
                    className="pick--up--button"
                  >
                    Pick Up
                  </Radio>
                </Col>
                <Col md={24} lg={12}>
                  <Radio
                    style={{ borderRadius: 5 }}
                    value="delivery"
                    className="delivery--button"
                  >
                    Delivery
                  </Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SelectDeliveryDate;
