import React from "react";
import { Modal } from "antd";
import "./SendRequest.less";

import Notes from "../../containers/notes/Notes";

type Props = {
  showNotesModal: boolean;
  toggleNotesModal: (item: any) => void;
};

const AddNotesModal = (props: Props) => {
  return (
    <Modal
      className="notes-modal-container"
      visible={props.showNotesModal}
      onOk={props.toggleNotesModal}
      onCancel={props.toggleNotesModal}
      footer={false}
    >
      <Notes />
    </Modal>
  );
};

export default AddNotesModal;
