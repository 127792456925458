import produce from "immer";
import _, { forEach, isNull } from "lodash";
import { API_BASE } from "../../constant";
import moment from "moment";
import axios from "axios";
import { pricingCalculation } from "../../utils/priceCalculation";
import { getAuthUser, getSelectedTenant } from "../../utils/authToken";
import { getErrorPage } from "../../utils/helpers";
const initialState = {
  currentProject: -1,
  items: [],
  adminDefaults: {},
  error: [],
};

const offlineData = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "UPDATE_ESTIMATE_CHECKLIST":
        const update = _.cloneDeep(state.items);
        update[state.currentProject].projectInfo.estimateChecklist =
          action.payload.data.estimateChecklist;
        return {
          ...state,
          items: update,
        };
      case "ADD_DISCOUNT":
        // let updatedDiscount = [...state.currentProject.discounts];
        // updatedDiscount.push(action.payload);
        // action.payload.data["_id"] = action.payload.data["id"];

        // Delete old key
        // delete action.payload.data["id"];
        let updatedDiscount = state.adminDefaults.discounts.map((item) => {
          return { ...item };
        });
        updatedDiscount.push(action.payload.data);
        return {
          ...state,
          adminDefaults: { ...state.adminDefaults, discounts: updatedDiscount },
        };
      case "EDIT_DISCOUNT":
        let editedDiscount = state.adminDefaults.discounts.map((item) => {
          return { ...item };
        });
        let updatedDiscountIndex = editedDiscount.findIndex(
          (item) => item._id === action.payload.body._id
        );
        editedDiscount[updatedDiscountIndex] = action.payload.body;
        return {
          ...state,
          adminDefaults: { ...state.adminDefaults, discounts: editedDiscount },
        };
      case "UPDATE_DISCOUNT":
        let discount = [];
        if (state.currentProject.discounts) {
          discount = state.currentProject.discounts.map((item) => {
            return { ...item };
          });
        }

        if (action.payload.checked) {
          discount.push(action.payload);
        } else {
          const discountIndex = discount.findIndex(
            (item) => item._id === action.payload._id
          );
          discount.splice(discountIndex, 1);
        }
        return {
          ...state,
          currentProject: { ...state.currentProject, discounts: discount },
        };
      case "SET_PROPOSAL":
        const newData = state.items.map((item) => {
          return { ...item };
        });

        const projectIndex = newData.findIndex(
          (item) => item._id === action.payload.projectId
        );
        newData[projectIndex].proposal = action.payload;

        return {
          ...state,
          currentProject: { ...state.currentProject, proposal: action.payload },
          items: newData,
        };
      case "SET_PROJECTS":
        return {
          ...state,
          items: action.payload,
          isFetchingProjects: false,
        };

      case "Update_PROPOSAL_COUNT":
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.projectId) {
              return {
                ...item,
                projectInfo: {
                  ...item.projectInfo,
                  proposalCount: action.payload.proposalCount,
                },
              };
            }
            return item;
          }),
        };

      case "UPDATE_PROJECT_DEFAULTS":
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.projectId) {
              return {
                ...item,
                projectDefaults: action.payload.updates
              };
            }
            return item;
          }),
        };

      case "SET_PROJECT_AND_INDEX":
        return {
          ...state,
          items: action.payload,
          currentProject: 0,
          isFetchingProjects: false,
        };
      case "CLEAR_OFFLINE_DATA":
        return initialState;
      case "SET_PROJECT":
        return {
          ...state,
          currentProject: action.payload,
        };
      case "SET_ALL_PROJECTS":
        return {
          ...state,
          allProjects: action.payload,
        };
      case "SET_ADMIN_DEFAULTS":
        return {
          ...state,
          adminDefaults: action.payload,
        };
      case "SET_ALL_DEFAULTS":
        return {
          ...state,
          allDefaults: action.payload,
        };
      case "SET_SINGLE_PROJECT":
        return {
          ...state,
          currentProject: action.payload,
        };
      case "UPDATE_CURRENT_PROJECT":
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.projectId) {
              return {
                ...action.payload.data,
              };
            }
            return item;
          }),
        };
      case "UPDATE_PROJECT_AND_INDEX":
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.projectId) {
              return {
                ...action.payload,
              };
            }
            return item;
          }),
        };
      case "UPDATE_PROJECT_REQUEST": {
        let projects = state.items.map((item) => {
          if (item.projectId === action.payload.data._id) {
            return {
              ...item,
              projectInfo: {
                ...action.payload.data,
                updatedAt: moment().unix(),
              },
            };
          }
          return item;
        });
        const updatedObject = projects.splice(state.currentProject, 1);
        projects.unshift(updatedObject[0]);
        return {
          ...state,
          items: projects,
          currentProject: 0,
        };
      }
      case "UPDATE_PROJECT_STATE_REQUEST": {
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.data._id) {
              return {
                ...item,
                projectInfo: {
                  ...item.projectInfo,
                  projectState: action.payload.data.projectState,
                },
              };
            }
            return item;
          }),
        };
      }
      case "UPDATE_PROJECT_CHECK_REQUEST_RETAINER_PERCENT_REQUEST": {
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.data.projectId) {
              return {
                ...item,
                projectInfo: {
                  ...item.projectInfo,
                  checkRequestRetainerPercent:
                    action.payload.data.checkRequestRetainerPercent,
                },
              };
            }
            return item;
          }),
        };
      }
      case "UPDATE_PROJECT_AND_PJCC_REQUEST":
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.newProject.projectId) {
              return action.payload.newProject;
            }
            return item;
          }),
        };

      case "SYNC_PROJECT_DATA": {
        let updatedProject = {};
        let adminDefaults;

        if (action.payload.adminDefaults) {
          updatedProject = pricingCalculation(
            action.payload.pricingService,
            action.payload.adminDefaults
          );
          adminDefaults = action.payload.adminDefaults;
        } else {
          updatedProject = pricingCalculation(
            action.payload.pricingService,
            state.adminDefaults
          );
          adminDefaults = state.adminDefaults;
        }

        return {
          ...state,
          currentProject: 0,
          items: [updatedProject],
          adminDefaults: adminDefaults,
        };
      }

      case "SET_PROJECT_DATA_CONTRACTOR": {
        const projectData = pricingCalculation(
          action.payload,
          state.adminDefaults
        );
        return {
          ...state,
          items: [projectData],
          currentProject: 0,
        };
      }

      case "UPDATE_PROJECT_OVERRIDE_GROSSMARGIN_REQUEST":
        return {
          ...state,
        };
      case "DELETE_PROJECT_REQUEST":
        const deleteIdx = state.items.findIndex(
          (item) => item.todoId === action.payload
        );
        if (deleteIdx < 0) return state;
        return {
          ...state,
          items: state.items
            .slice(0, deleteIdx)
            .concat(state.items.slice(deleteIdx + 1)),
        };

      case "POST_PROJECT_REQUEST":
        const currentIndex = 0;
        const newProjectBody = {
          projectId: action.payload.data._id,
          projectInfo: action.payload.data,
          carpentryWorkOrder: [],
          notes: [],
          checkRequests: [],
          options: [],
          paintOrder: [],
          paintWorkOrder: [],
          pjcc: [],
          projectMaterials: [],
          projectRates: {
            paintRates: [],
            carpentryRates: [],
            equipmentRates: [],
            travelRates: [],
            miscellaneousRates: [],
          },
          woodOrder: [],
        };
        // const projects = _.cloneDeep(state.items);
        // projects.unshift(newProjectBody);
        return {
          ...state,
          items: [newProjectBody],
          currentProject: currentIndex,
        };

      case "ADD_ONSITE_CHECKLIST_ANSWERS_OTHER":
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.data.project) {
              return {
                ...item,
                onsiteChecklistAnswers: {
                  ...item.onsiteChecklistAnswers,
                  otherChecklistAnswers: [
                    ...item.onsiteChecklistAnswers.otherChecklistAnswers,
                    ...action.payload.data.otherChecklistAnswers,
                  ],
                },
              };
            }
            return item;
          }),
        };
      case "POST_UPDATE_ONSITE_CHECKLIST_ANSWERS":
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.data.project) {
              return {
                ...item,
                onsiteChecklistAnswers: action.payload.data,
              };
            }
            return item;
          }),
        };
      case "POST_SERVICE_TO_PROJECT_REQUEST":
        return {
          ...state,
        };
      case "PATCH_UNDO_DELETE_SERVICE_TO_PROJECT_REQUEST":
        return {
          ...state,
        };
      case "PATCH_SERVICE_TO_PROJECT_REQUEST":
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.newProject.projectId) {
              return action.payload.newProject;
            }
            return item;
          }),
        };
      case "DELETE_RATES_FROM_PROJECT_AND_OPTION_REQUEST":
        return {
          ...state,
        };
      case "DELETE_RATES_FROM_OPTION_WITH_IDS_REQUEST":
        return {
          ...state,
        };
      case "DELETE_SERVICE_FROM_PROJECT_REQUEST":
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.newProject.projectId) {
              return action.payload.newProject;
            }
            return item;
          }),
        };
      case "DELETE_SERVICE_FROM_PROJECT_ONLY_REQUEST":
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.newProject.projectId) {
              return action.payload.newProject;
            }
            return item;
          }),
        };
      case "POST_NEW_SERVICE_TO_PROJECT_OPTIONS_RATE_REQUEST":
        console.log(action.payload.newProject);
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.newProject.projectId) {
              return action.payload.newProject;
            }
            return item;
          }),
        };

      case "POST_NEW_SERVICE_TO_PROJECT_REQUEST":
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.newProject.projectId) {
              return action.payload.newProject;
            }
            return item;
          }),
        };
      case "POST_RATES_TO_OPTION_REQUEST":
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.newProject.projectId) {
              return action.payload.newProject;
            }
            return item;
          }),
        };
      case "POST_UPDATE_AND_ADD_MODIFIED_PROJECT_RATES_REQUEST":
        return {
          ...state,
        };
      case "POST_UPDATE_OR_ADD_MODIFIED_PROJECT_RATES_AND_ADD_OPTION_RATES_REQUEST":
        return {
          ...state,
        };
      case "POST_OPTION_TO_PROJECT_REQUEST": {
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.newProject.projectId) {
              return action.payload.newProject;
            }
            return item;
          }),
        };
      }
      case "POST_OPTION_CLONE_TO_PROJECT_REQUEST": {
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.newProject.projectId) {
              return action.payload.newProject;
            }
            return item;
          }),
        };
      }
      case "DELETE_RATES_FROM_OPTION_REQUEST":
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.newProject.projectId) {
              return action.payload.newProject;
            }
            return item;
          }),
        };
      case "DELETE_OPTION_FROM_PROJECT_REQUEST":
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.newProject.projectId) {
              return action.payload.newProject;
            }
            return item;
          }),
        };

      case "UPDATE_OPTION_DETAILS": {
        const updatedProject = pricingCalculation(
          action.payload.payloadForRedux,
          state.adminDefaults
        );
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.payloadForRedux.projectId) {
              return updatedProject;
            }
            return item;
          }),
        };
      }
      case "PUT_OPTION_DETAILS_INFO": {
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.data.project) {
              return {
                ...item,
                options: item.options.map((option) => {
                  if (option.optionInfo._id === action.payload.data._id) {
                    return {
                      ...option,
                      optionInfo: action.payload.data,
                    };
                  } else return option;
                }),
              };
            }
            return item;
          }),
        };
      }
      case "PUT_OPTION_TO_PROJECT_REQUEST":
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.newProject.projectId) {
              return action.payload.newProject;
            }
            return item;
          }),
        };

      case "SET_PROJECT_PROPOSAL": {
        let proposal = action.payload.body;
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.projectId) {
              return {
                ...item,
                projectInfo: {
                  ...state.items[state.currentProject].projectInfo,
                  proposal,
                },
              };
            }
            return item;
          }),
        };
      }

      case "UPDATE_PROJECT_DISCOUNT_REQUEST": {
        console.log(action.payload);
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.reduxBody.projectId) {
              console.log(item);
              return { ...action.payload.reduxBody.newProject };
            }
            return item;
          }),
        };
      }
      case "UPDATE_SINGLE_ENTITY_OF_PROJECT_GROSS_MARGIN": {
        console.log(action.payload);
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.reduxBody.projectId) {
              console.log(item);
              return { ...action.payload.reduxBody.newProject };
            }
            return item;
          }),
        };
      }
      case "REMOVE_SINGLE_ENTITY_OF_PROJECT_GROSS_MARGIN": {
        console.log(action.payload);
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.reduxBody.projectId) {
              console.log(item);
              return { ...action.payload.reduxBody.newProject };
            }
            return item;
          }),
        };
      }
      case "UPDATE_PROJECT_GROSS_MARGIN": {
        console.log(action.payload);
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.reduxBody.projectId) {
              console.log(item);
              return { ...action.payload.reduxBody.newProject };
            }
            return item;
          }),
        };
      }
      case "UPDATE_PROJECT_PROPOSAL": {
        let proposal = action.payload.body;
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.projectId) {
              return {
                ...item,
                projectInfo: {
                  ...state.items[state.currentProject].projectInfo,
                  proposal,
                },
              };
            }
            return item;
          }),
        };
      }
      case "POST_CHECK_REQUEST": {
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.data.project) {
              return {
                ...item,
                checkRequests: [...item.checkRequests, action.payload.data],
              };
            }
            return item;
          }),
        };
      }
      case "PATCH_CHECK_REQUEST": {
        return {
          ...state,
          items: state.items.map((item) => {
            if (
              item.projectId === state.items[state.currentProject].projectId
            ) {
              return {
                ...item,
                checkRequests: item.checkRequests.map((item) => {
                  return {
                    ...item,
                    bonusPayment: action.payload.data.bonusPayment,
                  };
                }),
              };
            }
            return item;
          }),
        };
      }
      case "POST_PRE_PRESENTATION_CHECKLIST": {
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.data.project) {
              return {
                ...item,
                prePresentationChecklist: action.payload.data,
              };
            }
            return item;
          }),
        };
      }
      case "SET_PROJECT_JOB_JACKET": {
        let workOrders = _.cloneDeep(action.payload.resp);
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.id) {
              return {
                ...item,
                jobJacket: action.payload.resp,
                contractDoc: { workOrders },
              };
            }
            return item;
          }),
        };
      }
      case "ADD_NOTES_SUCCESS":
        return {
          ...state,
          items: state.items.map((item) => {
            if (item.projectId === action.payload.data.project) {
              return {
                ...item,
                notes: [
                  ...state.items[state.currentProject].notes,
                  action.payload.data,
                ],
              };
            }
            return item;
          }),
        };
      case "PUT_PROJECT": {
        return {
          ...state,
          items: state.items.map((project) => {
            if (project.projectId === action.payload.newProject.projectId) {
              return action.payload.newProject;
            }
            return project;
          }),
        };
      }
      case "SET_ERROR": {
        const path = action.meta.data.path;

        let pageError = "Error Occurred";
        if (path) {
          pageError = getErrorPage(path);
        }
        const errorMessage = {
          errorType: action.payload.response,
          message: pageError,
        };
        const user = getAuthUser();
        const tenantId = getSelectedTenant();
        axios
          .post(`${API_BASE}dump-collection`, action.meta.data, {
            headers: {
              Authorization: "Bearer " + user.accessToken,
              "tenant-id": !isNull(tenantId) ? tenantId : "",
            },
          })
          .then((response) => { })
          .catch((err) => {
            console.log(err);
          });

        return {
          ...state,
          error: [...state.error, errorMessage],
        };
      }
      case "REQUEST_UPDATE_OPTION_IMG_CAPTIONS": {
        return {
          ...state,
        };
      }
      case "CLEAR_ERROR":
        return {
          ...state,
          error: [],
        };
      default:
        return state;
    }
  });

export default offlineData;
