import React, { useEffect, useState } from "react";
import { Row, Col, Button, Spin } from "antd";
import "./SourceLocation.less";
import { BulletIcon } from "../../../utils/icons";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import SourceLocationModal from "../modal/SourceLocationModal";
import api from "../../../utils/api";

type Props = {};

const OtherChecklists = (props: Props) => {
  const [showSourceLocationModal, setShowSourceLocationModalModal] =
    useState(false);
  const [showEditSourceLocationModal, setShowEditSourceLocationModalModal] =
    useState(false);

  const [selectedDefault, setSelectedDefault] = useState<any>({});
  const [defaults, setDefaults] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    api
      .get("estimator-checklist-questions")
      .then((resp: any) => {
        setDefaults(resp);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const toggleSourceLocationModal = (collapse: boolean) => {
    !showSourceLocationModal
      ? setShowSourceLocationModalModal(true)
      : setShowSourceLocationModalModal(false);
  };

  const toggleEditSourceLocationModal = (collapse: boolean) => {
    !showEditSourceLocationModal
      ? setShowEditSourceLocationModalModal(true)
      : setShowEditSourceLocationModalModal(false);
  };

  const handleAdd = () => {
    !showSourceLocationModal
      ? setShowSourceLocationModalModal(true)
      : setShowSourceLocationModalModal(false);
  };

  const handlePost = (values: any) => {
    // const number = defaults.length + 1;
    api
      .post("estimator-checklist-questions", {
        ...values,
        type: "text",
        // number: number,
      })
      .then((resp: any) => {
        setDefaults([...defaults, resp]);
      });
    toggleSourceLocationModal(false);
  };

  const handleEdit = (object: any) => {
    setSelectedDefault(object);
    !showEditSourceLocationModal
      ? setShowEditSourceLocationModalModal(true)
      : setShowEditSourceLocationModalModal(false);
  };

  const handleUpdate = (value: any) => {
    api
      .patch(`estimator-checklist-questions/${selectedDefault?._id}`, {
        question: value?.question,
      })
      .then((resp: any) => {
        let objIndex = defaults.findIndex((item: any) => item._id === resp._id);
        let newDefaults = [...defaults];
        newDefaults[objIndex] = resp;
        setDefaults(newDefaults);
      });
    !showEditSourceLocationModal
      ? setShowEditSourceLocationModalModal(true)
      : setShowEditSourceLocationModalModal(false);
  };

  const handleDelete = (id: string) => {
    api
      .patch(`estimator-checklist-questions/soft-delete/${id}`, {})
      .then((resp: any) => {
        setDefaults(defaults.filter((item: any) => item._id !== id));
      });
  };

  return (
    <div className="source-location-root">
      <SourceLocationModal
        handlePost={handlePost}
        toggleSourceLocationModal={toggleSourceLocationModal}
        showSourceLocationModal={showSourceLocationModal}
      />
      <SourceLocationModal
        handlePost={handleUpdate}
        edit={true}
        value={selectedDefault.question}
        toggleSourceLocationModal={toggleEditSourceLocationModal}
        showSourceLocationModal={showEditSourceLocationModal}
      />
      <Row justify="space-between">
        <Col>
          <h1>Other Checklists</h1>
        </Col>
        <Col>
          <Button
            onClick={handleAdd}
            className="source-location-add-btn"
            type="link"
          >
            + Add
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <div>
          {defaults?.map((item: any) => {
            return (
              <Row key={item._id}>
                <Col lg={1} md={1}>
                  <BulletIcon style={{ marginRight: 10 }} />
                </Col>
                <Col lg={20} md={20}>
                  <p>{item.question}</p>
                </Col>
                <Col lg={1} md={1}>
                  <EditOutlined
                    onClick={() => handleEdit(item)}
                    style={{ color: "#FDB913", cursor: "pointer" }}
                  />
                </Col>
                <Col lg={1} md={1}>
                  <DeleteOutlined
                    onClick={() => handleDelete(item._id)}
                    style={{ color: "#D2232A", cursor: "pointer" }}
                  />
                </Col>
              </Row>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default OtherChecklists;
