import React from 'react';
import ProposalDefaultRes from '../../containers/proposal-defaults/residential/ProposalDefaultRes';

type Props = {};

const ProposalDefaultsRes = (props: Props) => {
  return (
    <div>
      <ProposalDefaultRes />
    </div>
  );
};

export default ProposalDefaultsRes;
