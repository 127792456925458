import React, { useEffect, useState } from "react";
import { Row, Col, Avatar } from "antd";
import "./UserInfo.less";
import { RootStateOrAny, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

type Props = {
  userName: string;
  jobTitle: string;
  jobDescription: string;
  picture: string;
};

const UserInfo = (props: Props) => {
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:800px)" });

  const [initials, setInitials] = useState("");
  useEffect(() => {
    const initials = getInitials(props?.userName);
    setInitials(initials);
  }, []);

  const getInitials = (name: string) => {
    try {
      const nameArray = name.split(" ");
      const firstName = nameArray[0];
      const lastName = nameArray[nameArray.length - 1];
      if (nameArray.length === 1) {
        const initials = firstName.charAt(0);
        return initials.toUpperCase();
      } else {
        const initials = firstName.charAt(0) + lastName.charAt(0);
        return initials.toUpperCase();
      }
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  return (
    <>
      {!isTabletOrMobile ? (
        <div className="user-info-root">
          <Row align="middle" gutter={20}>
            <Col>
              {props?.picture && online ? (
                <>
                  <Avatar size={64} src={props.picture} />
                </>
              ) : (
                <>
                  <Avatar
                    style={{
                      // backgroundColor: "#f56a00",
                      // color: "#fff",
                      fontWeight: "bold",
                      fontSize: 24,
                    }}
                    size={64}
                  >
                    {initials}
                  </Avatar>
                </>
              )}
            </Col>
            <Col>
              <h1>{props.userName}</h1>
            </Col>
          </Row>

          <div className="user-details-margin">
            <h1>Job Title</h1>
            <p>{props.jobTitle}</p>
          </div>
          <div className="user-details-margin">
            <h1>Job Description</h1>
            <p>{props.jobDescription}</p>
          </div>
        </div>
      ) : (
        <div className="user-info-root" style={{ width: "350px" }}>
          <Row align="middle" gutter={20}>
            <Col>
              {props?.picture && online ? (
                <>
                  <Avatar size={64} src={props.picture} />
                </>
              ) : (
                <>
                  <Avatar
                    style={{
                      // backgroundColor: "#f56a00",
                      // color: "#fff",
                      fontWeight: "bold",
                      fontSize: 24,
                    }}
                    size={64}
                  >
                    {initials}
                  </Avatar>
                </>
              )}
            </Col>
            <Col>
              <h1>{props.userName}</h1>
            </Col>
          </Row>

          <div className="user-details-margin">
            <h1>Job Title</h1>
            <p>{props.jobTitle}</p>
          </div>
          <div className="user-details-margin">
            <h1>Job Description</h1>
            <p>{props.jobDescription}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default UserInfo;
