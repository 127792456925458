import { Row } from "antd";
import { useHistory } from "react-router-dom";
import "./ResourceNotCreated.less";

type Props = {
  message: string;
  url: string;
};

const ResourceNotCreated = (props: Props) => {
  const history = useHistory();
  return (
    <div className="rnt-card">
      <h1>{props.message}</h1>
      <h2
        onClick={() => {
          history.push(`${props.url}`);
        }}
      >
        Go Back
      </h2>
    </div>
  );
};

export default ResourceNotCreated;
