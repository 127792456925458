import React, { useEffect } from "react";
import { Layout, Row, Col } from "antd";
import AssociatePeopleForm from "../../containers/forms/AssociatePeopleForm";
import CreateForm from "../../containers/forms/CreateForm";
import {
  setSelectedEstimator,
  setSelectedProductionAssociate,
  setSelectedSalesAssociate,
} from "../../redux/associatePeople/action";
import { useDispatch } from "react-redux";
import SalesAssociateSteps from "./SalesAssociateSteps";
import { getAuthUser } from "../../utils/authToken";

const { Content } = Layout;

const NewProfile: React.FC = () => {
  const dispatch = useDispatch();

  const user = getAuthUser();

  useEffect(() => {
    dispatch(setSelectedSalesAssociate({}));
    dispatch(setSelectedEstimator({}));
    dispatch(setSelectedProductionAssociate({}));
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);

  return (
    <Content>
      <div className="steps-content">
        <CreateForm />
      </div>
    </Content>
  );
};

export default NewProfile;
