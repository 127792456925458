import produce from "immer";

const initialState = {
  closestStoreData: {},
};

const closestStoreData = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "CLOSEST_STORE_DATA":
        return {
          ...state,
          closestStoreData: action.payload,
        };
    }
  });

export default closestStoreData;
