import EquipmentCostsC from "../../containers/admin-rates/EquipmentCostsC";

type Props = {};

const EquipmentCosts = (props: Props) => {
  return (
    <div>
      <EquipmentCostsC />
    </div>
  );
};

export default EquipmentCosts;
