import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRouter = ({component:Component , ...rest}) => {
  const user = useSelector(({auth})=>auth.user);
    return (
        <Route {...rest} render={(props) => user ? (<Component {...props} user={user} />) : (<Redirect to="/login" />)} />
    )
}
PrivateRouter.propTypes = {
  component : PropTypes.any.isRequired,
}
export default PrivateRouter;