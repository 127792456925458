import "../../components/proposal/Proposal.less";

type Props = {
  setup?: any;
};

const warrantyDoesNotCoverList = [
  'Any work where the Contractor did not supply the paint or other materials.',
  'Any work which was not performed by the Contractor.',
  'Any work which was not performed by the Contractor.',
  'Any work where the Contractor did not supply the paint or other materials.',
  'Any work which was not performed by the Contractor.',
  'Varnished surfaces.',
  'Surfaces made of, or containing, galvanized metal.',
  'The cost of paint required to perform the repairs.',
  'Repairs to horizontal surfaces or any surface that, by virtue of its design permits moisture to collect. Surfaces include, but are not limited to, decks, railings, stairs, porches, roofs and wood gutters.',
  'Exact paint match as environmental conditions will affect the color and finish of all paints over time.',
  'Any repairs which are necessitated as a result of a defect in the paint regardless of whether the paint was supplied by the Contractor or the customer.',
  'Bleeding caused by knots, rust or cedar.',
  'Cracks in drywall, plaster or wood.',
  'Peeling, blistering or chipping where they are caused by:',
];

const causedByList = [
  'mill-glazing from smooth cedar.',
  'ordinary wear and tear.',
  'abnormal use or misuse.',
  'peeling of layers of paint existing prior to the work performed by the Contractor.',
  'structural defects.',
  'settling or movement.',
  'moisture content of the substrate.',
  'abrasion, mechanical damage, abrasive cleaning, abuse or damage resulting from use of chemicals or cleaning agents or exposure to harmful solids, liquids or gases.',
  'damage or defects caused in whole or in part by reason of fire, explosion, flood, acts of God, extreme weather conditions, misuse, alteration, abuse, vandalism, negligence, or any other similar causes beyond    the control of the Contractor.',
]

const warrantyValidList = [
  'Pay the full contract price.',
  'Retain a copy of the original contract.',
  'Retain a copy of your cancelled check or other evidence of payment in full.',
  'Pay for all materials used to perform the repairs.',
  'Make the property accessible to the Contractor, or his employees, to perform the repairs.',
]
const Warranty = (props: Props) => {
  return (
    <div
      className="terms-container"
      style={{ marginTop: 30, marginBottom: 50, marginLeft: 30 }}
    >
      <div className="tasks-wrapper" style={{ marginTop: "50px" }}>
        <h3 style={{textAlign:'center', fontWeight:'bold'}}>LIMITED TWO YEAR WARRANTY</h3>
            <div className="terms-text" style={{ fontWeight: "normal", marginTop: "25px" }}>
              Subject to the limitation set forth below, for a period of 24 months 
              from the date of completion of the work described on the front of this 
              contract, the Independent Franchise Owner named on the front of this 
              contract (the "Contractor") will repair peeling, blistering or chipping 
              paint resulting from defective workmanship. <br/><br/> THIS LIMITED WARRANTY DOES NOT COVER:
            </div>
            <div style={{ marginTop: "25px" }}>
            {warrantyDoesNotCoverList.map((item: any, index: any) => {
              return (
                <div
                  className="tasks-wrapper"
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >

                  <p
                    className="terms-text"
                    style={{
                      fontWeight: "normal",
                      marginLeft: "10px",
                      marginRight: "5px",
                      marginTop: 0,
                      marginBottom: 0,
                      padding: 0
                    }}
                  >
                    •
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {item}
                  </p>
                </div>
              );
            })}

            {causedByList.map((item: any, index: any) => {
              return (
                <div
                  className="tasks-wrapper"
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p
                    className="terms-text"
                    style={{
                      fontWeight: "normal",
                      marginLeft: "60px",
                      marginRight: "5px",
                      marginTop: 0,
                      marginBottom: 0,
                      padding: 0
                    }}
                  >
                    o
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {item}
                  </p>
                </div>
              );
            })}

            <div className="terms-text" style={{ fontWeight: "normal" }}>
              <br></br>Repairs under this limited warranty will be performed only on the specific areas where peeling, blistering or chipping has occurred and only to the level of surface preparation described in the preparation section of the Contract.
              <br/><br/>
              FOR THIS WARRANTY TO BE VALID, YOU MUST: <br/> <br/>
            </div>
            {warrantyValidList.map((item: any, index: any) => {
              return (
                <div
                  className="tasks-wrapper"
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p
                    className="terms-text"
                    style={{
                      fontWeight: "normal",
                      marginLeft: "10px",
                      marginRight: "5px",
                      marginTop: 0,
                      marginBottom: 0,
                      padding: 0
                    }}
                  >
                    •
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {item}
                  </p>
                </div>
              );
            })}
            <div className="terms-text" style={{ fontWeight: "normal" }}>
              <br></br>THIS LIMITED WARRANTY IS THE ONLY EXPRESS WARRANTY MADE BY THE CONTRACTOR AND IS IN LIEU OF ALL OTHER WARRANTIES, EXPRESS OR IMPLIED. THIS WARRANTY COVERS ONLY THOSE SERVICES PROVIDED BY THE CONTRACTOR TO THE ORIGINAL PURCHASER NAMED ON THE FRONT OF THIS CONTRACT. IN NO EVENT SHALL THE CONTRACTOR BE LIABLE FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES IN EXCESS OF THE ORIGINAL CONTRACT PRICE. THIS WARRANTY MAY NOT BE ALTERED OR EXTENDED FOR ANY PURPOSE UNLESS DONE SO IN WRITING IN A DOCUMENT EXECUTED BY ALL PARTIES TO THIS CONTRACT.
              <br/><br/>
              This warranty gives you specific legal rights. Some jurisdictions do not allow limitations on how long an implied warranty lasts, so the above limitation may not apply to you. Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitations or exclusions may not apply to you.
              <br/><br/>
              For warranty service, you should contact your Contractor to schedule an inspection of your property by calling CertaPro Painters® at 800.462.3782.
            </div>
          </div>
      </div>
    </div>
  );
};

export default Warranty;
