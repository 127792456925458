import React, { useEffect, useState } from "react";
import { Card, Row, Col, message, Avatar } from "antd";
import "../forms/AssociatePeopleForm.less";
import AssociatePeopleForm from "../forms/AssociatePeopleForm";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { updateProjectById } from "../../redux/project/action";
import { setSelectedAssociates } from "../../redux/associatePeople/action";

const EditAssociatePeopleForm: React.FC = () => {
  const [isEdit, isEditSet] = useState(false);
  const dispatch = useDispatch<any>();
  const { currentProject, items } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const { online } = useSelector((state: RootStateOrAny) => state.offline);

  const associatePeople = useSelector(
    (state: RootStateOrAny) => state.associatePeople
  );

  const [associateInitials, setAssociateInitials] = useState("");
  const [estimatorInitials, setEstimatorInitials] = useState("");
  const [productionInitials, setProductionInitials] = useState("");

  const {
    selectedSalesAssociate,
    selectedEstimators,
    selectedProductionAssociate,
  } = associatePeople;

  useEffect(() => {
    const associateInitials = getInitials(selectedSalesAssociate?.fullName);
    setAssociateInitials(associateInitials);
    const estimatorInitials = getInitials(selectedEstimators?.fullName);
    setEstimatorInitials(estimatorInitials);
    // const productionInitials = getInitials(
    //   selectedProductionAssociate?.fullName
    // );
    if (
      selectedProductionAssociate &&
      Object.keys(selectedProductionAssociate).length
    ) {
      const productionInitials = getInitials(
        selectedProductionAssociate.fullName
      );
      setProductionInitials(productionInitials);
    }
    // setProductionInitials(productionInitials);
  }, []);

  const getInitials = (name: string) => {
    try {
      const nameArray = name.split(" ");
      const firstName = nameArray[0];
      const lastName = nameArray[nameArray.length - 1];
      if (nameArray.length === 1) {
        const initials = firstName.charAt(0);
        return initials.toUpperCase();
      } else {
        const initials = firstName.charAt(0) + lastName.charAt(0);
        return initials.toUpperCase();
      }
    } catch (err) {
      console.log(err);
      return "";
    }
  };

  const handleSave = () => {
    const data = {
      ...items[currentProject].projectInfo,
      salesAssociate: selectedSalesAssociate
        ? selectedSalesAssociate
        : undefined,
      estimator: selectedEstimators ? selectedEstimators : undefined,
      productionAssociate: selectedProductionAssociate
        ? selectedProductionAssociate
        : undefined,
    };
    dispatch(updateProjectById(data));
    isEditSet(false);
    message.success("Associates updated");
  };

  const showEditForm = () => {
    isEditSet(true);
  };

  const handleCancel = () => {
    isEditSet(false);

    dispatch(
      setSelectedAssociates({
        salesAssociate: items[currentProject].projectInfo?.salesAssociate,
        estimator: items[currentProject].projectInfo?.estimator,
        productionAssociate:
          items[currentProject].projectInfo?.productionAssociate,
      })
    );
  };

  return (
    <>
      {!isEdit ? (
        <Card className="root-container">
          <div className="profile-content job-container form-container ">
            <Row justify="space-between">
              <Col>
                <h1 className="form-subtitle">Associate/People</h1>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <p className="edit--text" onClick={showEditForm}>
                  Edit
                </p>
              </Col>
            </Row>

            <div className="assosicate--list">
              {selectedSalesAssociate?.fullName && (
                <Row gutter={60} className="list--holder">
                  <Col lg={2} sm={3}>
                    {selectedSalesAssociate?.picture && online ? (
                      <>
                        <Avatar
                          src={selectedSalesAssociate?.picture}
                          size={48}
                          shape="square"
                        />
                      </>
                    ) : (
                      <>
                        <Avatar shape="square" size={48}>
                          {associateInitials}
                        </Avatar>
                      </>
                    )}
                  </Col>
                  <Col span={16}>
                    <h5>{selectedSalesAssociate?.fullName}</h5>
                    <p>Sales Associate</p>
                  </Col>
                </Row>
              )}

              {selectedEstimators?.fullName && (
                <Row gutter={60} className="list--holder">
                  <Col lg={2} sm={3}>
                    {selectedEstimators?.picture && online ? (
                      <>
                        <Avatar
                          src={selectedEstimators?.picture}
                          size={48}
                          shape="square"
                        />
                      </>
                    ) : (
                      <>
                        <Avatar shape="square" size={48}>
                          {estimatorInitials}
                        </Avatar>
                      </>
                    )}
                  </Col>
                  <Col span={16}>
                    <h5>{selectedEstimators?.fullName}</h5>
                    <p>Estimator</p>
                  </Col>
                </Row>
              )}

              {selectedProductionAssociate?.fullName && (
                <Row gutter={60}>
                  <Col lg={2} sm={3}>
                    {selectedProductionAssociate?.picture && online ? (
                      <>
                        <Avatar
                          src={selectedProductionAssociate?.picture}
                          size={48}
                          shape="square"
                        />
                      </>
                    ) : (
                      <>
                        <Avatar shape="square" size={48}>
                          {productionInitials}
                        </Avatar>
                      </>
                    )}
                  </Col>
                  <Col span={16}>
                    <h5>{selectedProductionAssociate?.fullName}</h5>
                    <p>Production Associate</p>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </Card>
      ) : (
        <AssociatePeopleForm
          handleUpdate={handleSave}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default EditAssociatePeopleForm;
