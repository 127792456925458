import React, { useEffect, useState } from "react";
import DescriptionCard from "../../../components/work-order-description/DescriptionCard";
import { Table, Button, Row, Col, Dropdown, Input, Menu, Spin } from "antd";
import { columns } from "./columns";
import "../WorkOrderPaintC.less";
import api from "../../../utils/api";
import { Link, useParams } from "react-router-dom";
import { SearchIcon } from "../../../utils/icons";
import { RootStateOrAny, useSelector } from "react-redux";

type Props = {};

const WorkOrderPaintC = (props: Props) => {
  const [count, setCount] = useState(0);
  const [optionSearched, setOptionSearched] = useState("");
  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const [workOrderPaint, setWorkOrderPaint] = useState(
    items[currentProject]?.paintWorkOrder?.filter(
      (item: any) => item.paintLabor
    )
  );
  // const { id } = useParams<{ id: string }>();
  const { adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const newColumn = [
    columns[0],
    columns[1],
    {
      title: (
        <>
          <Row>
            <Col>
              <div
                style={{
                  height: 20,
                  width: 20,
                  // marginTop:8,
                  marginRight: 20,
                }}
              />
            </Col>
            <Col>Color</Col>
          </Row>
        </>
      ),
      // dataIndex: "color",
      render: (data: any) => {
        let response = adminDefaults.colors.find(
          (item: any) => item.name === data.color
        );
        return (
          <>
            {data.primerMaterial && data?.primerCoats === "full" && (
              <Row>
                <Col>
                  <div
                    style={{
                      // background: response?.hexCode,
                      height: 20,
                      width: 20,
                      marginRight: 20,
                    }}
                  />
                </Col>
                <Col>
                  <p>N/A</p>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <div
                  style={{
                    background: response?.hexCode,
                    height: 20,
                    width: 20,
                    marginRight: 20,
                  }}
                />
              </Col>
              <Col>
                <p>{data.color}</p>
              </Col>
            </Row>
          </>
        );
      },
    },
    columns[3],
    columns[4],
    // columns[5],
  ];

  const findOption = workOrderPaint?.filter((option: any) => {
    if (!optionSearched) {
      return true;
    }
    return option.item.toLowerCase().includes(optionSearched.toLowerCase());
  });

  const handleOptionSelect = (id: string) => {
    const index = workOrderPaint?.findIndex((item: any) => item._id === id);
    if (index >= 0) {
      setCount(index);
    }
  };

  const menu = (
    <>
      <div className="option-input-container">
        <Input
          className="buildings-search"
          placeholder="Search"
          onChange={(e) => {
            setOptionSearched(e.target.value);
          }}
          prefix={<SearchIcon />}
        />
      </div>
      <Menu>
        {findOption?.map((item: any) => (
          <Menu.Item
            onClick={() => handleOptionSelect(item._id)}
            style={{ height: 38 }}
            key={item._id}
          >
            <p className="jmp-search-text">{item.item}</p>
          </Menu.Item>
        ))}
      </Menu>
    </>
  );

  const dummyData = [
    {
      title: "Item",
      description: workOrderPaint[count]?.item,
    },
    {
      title: "Prep hours",
      description: workOrderPaint[count]?.prepHours.toFixed(2),
    },
    {
      title: "Address",
      description: workOrderPaint[count]?.address,
    },
    {
      title: "Paint hours",
      description: workOrderPaint[count]?.paintHours.toFixed(2),
    },
    {
      title: "Wash hours",
      description: workOrderPaint[count]?.washHours.toFixed(2),
    },

    {
      title: "Paint Labor",
      description: workOrderPaint[count]?.paintLabor.toFixed(2),
    },
  ];
  return (
    <div className="wo-paint-root">
      <Row className="wop-header" justify="space-between">
        <Col>
          <h1 className="wop-title">Work Order- Paint</h1>
        </Col>
        <Col>
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button className="wo-paint-jump">Jump to Option</Button>
          </Dropdown>
        </Col>
      </Row>

      <hr style={{ border: "1px solid #E8E8E8" }} />
      <DescriptionCard
        dummy={workOrderPaint[count]?.paintLabor ? dummyData : []}
        notes={findOption[count]?.workOrderNotes}
      />

      <div className="wo-paint-table">
        <Table
          rowKey={(_: any, index: any) => index.toString()}
          pagination={false}
          rowClassName={(record, index) =>
            index % 2 === 0
              ? "table_row table-row-light"
              : "table_row table-row-dark"
          }
          columns={newColumn}
          dataSource={workOrderPaint[count]?.paintRateId?.filter(
            (item: any) => item.paintGallons && item.showLaborRates
          )}
        />
      </div>

      <Row gutter={10} justify="end" style={{ padding: "20px" }}>
        <Col>
          <Button
            className="wo-paint-prev"
            onClick={() => {
              count > 0 && setCount(count - 1);
            }}
          >
            Prev Item
          </Button>
        </Col>
        <Col>
          <Button
            className="wo-paint-next"
            onClick={() => {
              count < workOrderPaint.length - 1 && setCount(count + 1);
            }}
            type="primary"
          >
            Next Item
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default WorkOrderPaintC;
