import { Input, Select } from 'antd';
import { MoreVerticalIcon } from '../../../utils/icons';
const { Option } = Select;
export const columns = [
  {
    title: 'Surface',
    dataIndex: 'surface',
    key: 'surface',
    render: (data: any) => {
      return <Input defaultValue={data} />;
    },
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (data: any) => {
      return (
        <Select defaultValue="EA">
          <Option value="EA">EA</Option>
          <Option value="LF">LF</Option>
        </Select>
      );
    },
  },
  {
    title: 'Paint Labor',
    dataIndex: 'paintLabor',
    key: 'paintLabor',
    render: (data: any) => {
      return <Input defaultValue={data} />;
    },
  },
  {
    title: 'Prep',
    dataIndex: 'prep',
    key: 'prep',
    render: (data: any) => {
      return <Input defaultValue={data} />;
    },
  },
  {
    title: 'Prime Labor',
    dataIndex: 'primeLabor',
    key: 'primeLabor',
    render: (data: any) => {
      return <Input defaultValue={data} />;
    },
  },
  {
    title: 'Paint',
    dataIndex: 'paint',
    key: 'paint',
    render: (data: any) => {
      return <Input defaultValue={data} />;
    },
  },
  {
    title: 'Prime',
    dataIndex: 'prime',
    key: 'prime',
    render: (data: any) => {
      return <Input defaultValue={data} />;
    },
  },
  {
    title: 'Default Product',
    dataIndex: 'defaultProduct',
    key: 'defaultProduct',
    render: (data: any) => {
      return (
        <Select defaultValue="SB">
          <Option value="EA">SW: Cashmere Flat</Option>
          <Option value="SB">SB: DTM Gloss S.........</Option>
          <Option value="BM">BM: Regal Eggshell</Option>
          <Option value="PPG">PPG: Silken Touch Satin</Option>
        </Select>
      );
    },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (data: any) => {
      return <MoreVerticalIcon />;
    },
  },
];
