import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  Tabs,
  Dropdown,
  Menu,
  message,
  Spin,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./MaterialDefaultC.less";
import AddItemDefaultModal from "../../components/pop-ups/default-modal/AddItemDefaultModal";
import DefaultBulkEntry from "../../components/pop-ups/default-modal/MaterialDefaultBulkEntry";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { columns, paintPrimerHeaders } from "./config";
import { MoreActionIcon } from "../../utils/icons";
import {
  bulkAddDefaultMaterial,
  deleteMaterialDefaults,
} from "../../redux/material-defaults/action";
import { useMediaQuery } from "react-responsive";

const { TabPane } = Tabs;

const MaterialDefaultC = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:800px)" });

  const dispatch = useDispatch();

  const [ManufacturerSearched, setManufacturerSearched] = useState("");
  const [showSingleItemModal, setShowSingleItemModal] = useState(false);
  const [material, setMaterial] = useState("paint");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [item, setItem] = useState({});
  const [edit, setEdit] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const { paints, primers, loading } = useSelector(
    (state: RootStateOrAny) => state.materialDefaults
  );

  const handleTab = (key: string) => {
    setMaterial(key);
  };

  let found = [];

  if (material === "paint") {
    found = paints.filter((item: any) => {
      if (!ManufacturerSearched) {
        return true;
      }
      return item.product
        .toLowerCase()
        .includes(ManufacturerSearched.toLowerCase());
    });
  } else {
    found = primers.filter((item: any) => {
      if (!ManufacturerSearched) {
        return true;
      }
      return item.product
        .toLowerCase()
        .includes(ManufacturerSearched.toLowerCase());
    });
  }

  const handleAddCsv = (info: any) => {
    setButtonDisable(true);
    if (info.file.status === "done") {
      setButtonDisable(false);
      dispatch(bulkAddDefaultMaterial(info.file.response));
      message.success("Successfully Uploaded");
      toggleBulkModal();
    } else if (info.file.status === "error") {
      setButtonDisable(false);
      message.error("Uploading Failed!");
    }
  };

  const toggleSingleItemModal = () => {
    !showSingleItemModal
      ? setShowSingleItemModal(true)
      : setShowSingleItemModal(false);
  };

  const toggleBulkModal = () => {
    !showBulkModal ? setShowBulkModal(true) : setShowBulkModal(false);
  };

  const handleSingleItem = () => {
    setEdit(false);
    setItem({});
    toggleSingleItemModal();
  };

  const handleBulkBtn = () => {
    !showBulkModal ? setShowBulkModal(true) : setShowBulkModal(false);
  };

  const handleDelete = (id: string) => {
    dispatch(deleteMaterialDefaults(id, material));
  };

  const handleEdit = (data: any) => {
    setItem(data);
    setEdit(true);
    toggleSingleItemModal();
  };

  const tableColumns = [
    ...columns,
    {
      title: "Action",
      render: (data: any) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="edit"
                  style={{ fontWeight: "bold" }}
                  onClick={() => handleEdit(data)}
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  key="delete"
                  style={{ fontWeight: "bold" }}
                  onClick={() => handleDelete(data._id)}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <div style={{ cursor: "pointer" }}>
              <MoreActionIcon />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div className="material-default-root">
      <DefaultBulkEntry
        buttonDisable={buttonDisable}
        showBulkModal={showBulkModal}
        toggleBulkModal={toggleBulkModal}
        url="material/upload"
        handleAddCsv={handleAddCsv}
      />
      <AddItemDefaultModal
        toggleSingleItemModal={toggleSingleItemModal}
        showSingleItemModal={showSingleItemModal}
        material={material}
        item={item}
        edit={edit}
      />
      <Row
        justify="space-between"
        className="material-default-header"
        align="middle"
      >
        <Col>
          {isTabletOrMobile ? (
            <h1 style={{ marginBottom: "15px" }}>Material Defaults</h1>
          ) : (
            <h1>Material Defaults</h1>
          )}
        </Col>

        <Col>
          <Row gutter={12}>
            <Col>
              <Input
                className="search-manufacture-input"
                prefix={<SearchOutlined className="default-search-icon" />}
                placeholder="Search Manufacturers"
                onChange={(e) => {
                  setManufacturerSearched(e.target.value);
                }}
              />
            </Col>
            <div className="vertical-line" />

            <Col>
              <Button className="default-add-btn" onClick={handleSingleItem}>
                Add Single item
              </Button>
            </Col>
            <Col>
              <Button
                onClick={handleBulkBtn}
                className="default-bulk-btn"
                type="primary"
              >
                Bulk Entry
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <div className="material-default-tab">
          <Button className="default-bulk-btn" type="primary">
            <CSVLink
              data={material === "paint" ? paints : primers}
              filename={
                material === "paint" ? "paint-materials" : "primer-materials"
              }
              headers={paintPrimerHeaders}
              enclosingCharacter={""}
            >
              Download CSV
            </CSVLink>
          </Button>

          <Tabs activeKey={material} onChange={handleTab}>
            <TabPane tab="Interior & Exterior Paints" key="paint">
              {paints.length > 0 && (
                <Table
                  rowClassName={(_, index) =>
                    index % 2 === 0
                      ? "table_row table-row-light"
                      : "table_row table-row-dark"
                  }
                  scroll={{ x: 900 }}
                  rowKey="_id"
                  dataSource={found}
                  columns={tableColumns}
                />
              )}
            </TabPane>
            <TabPane tab="Interior & Exterior Primers" key="primer">
              {primers.length > 0 && (
                <Table
                  rowClassName={(_, index) =>
                    index % 2 === 0
                      ? "table_row table-row-light"
                      : "table_row table-row-dark"
                  }
                  scroll={{ x: 900 }}
                  rowKey="_id"
                  dataSource={found}
                  columns={tableColumns}
                />
              )}
            </TabPane>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default MaterialDefaultC;
