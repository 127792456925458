import { Input, Row, Col, Rate, Select } from "antd";
import { RectangleIcon } from "../../utils/icons";
import "../../containers/OptionDetails/OptionDetails.less";
import { RootStateOrAny, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Wash from "./Wash";
type Props = {
  record?: any;
  index?: number;
  handleInputsChange?: any;
  handleDifficultyChange?: any;
  handlePaintMaterialChange: any;
  handlePrimerMaterialChange: any;
};

const ExpandedRow = (props: Props) => {
  const [prepRateColor, setPrepRateColor] = useState("");
  const [diffLevelColor, setDiffLevelColor] = useState("");
  const [primeLevelColor, setPrimeLevelColor] = useState("");
  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  useEffect(() => {
    setColor(setPrepRateColor, props?.record?.prepLevel);
  }, [props?.record?.prepLevel]);

  useEffect(() => {
    setColor(setDiffLevelColor, props?.record?.paintLevel);
  }, [props?.record?.paintLevel]);

  useEffect(() => {
    setColor(setPrimeLevelColor, props?.record?.primeLevel);
  }, [props?.record?.primeLevel]);

  const setColor = (setColorFn: Function, value: number, defaultValue = 0) => {
    switch (value) {
      case 0:
        setColorFn("#d1cec4");
        break;
      case 1:
        setColorFn("#FF0000");
        break;
      case 2:
        setColorFn("#FDB913");
        break;
      case 3:
        setColorFn("#00B44F");
        break;
      default:
        setColor(setColorFn, defaultValue, defaultValue);
    }
  };

  const handleHover = (
    setColorFn: Function,
    level: number,
    defaultValue: number
  ) => {
    setColor(setColorFn, level, defaultValue);
  };

  const onPrepChange = (value: number) => {
    props.handleDifficultyChange(value, props.index, "prep", props.record._id);
  };
  const onDiffChange = (value: number) => {
    props.handleDifficultyChange(value, props.index, "paint", props.record._id);
  };

  const onPrimeChange = (value: number) => {
    props.handleDifficultyChange(value, props.index, "prime", props.record._id);
  };

  const findMaterial = (material: any) => {
    const findFromProject = items[currentProject]?.projectMaterials?.find(
      (item: any) => item?.defaultMaterialId === material?._id
    );
    if (findFromProject) {
      return findFromProject.price;
    }
    return material?.priceAfterTax;
  };

  return (
    <div className="table-expanded--holder">
      <Row
        style={{ marginBottom: 20 }}
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      >
        <Col className="gutter-row" span={12}>
          <div className="table-row-material">
            <h1>Material</h1>
            <Select
              showSearch
              dropdownMatchSelectWidth={false}
              style={{ caretColor: "transparent", width: "100%" }}
              filterOption={(input: any, option: any) =>
                (option?.children?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              defaultValue={
                props.record.customerSuppliedPaint
                  ? "customerSupplied"
                  : props.record.paintMaterial &&
                    `${props.record.paintMaterial?.manufacturer}:${props.record.paintMaterial?.product}:${props.record.paintMaterial?.sheen}`
              }
              onChange={(value: string) =>
                props.handlePaintMaterialChange(value, props.record)
              }
            >
              {adminDefaults.materialDefaults.paints.map(
                (material: any, index: any) => (
                  <option key={index} value={material["_id"]}>
                    {`${material.manufacturer}: ${material.product}: ${material.sheen}`}
                  </option>
                )
              )}
              <option key="customerSupplied" value="customerSupplied">
                Customer Supplied
              </option>
            </Select>
          </div>
        </Col>
        <Col className="gutter-row" span={12}>
          <div className="table-row-material">
            <h1>Primer</h1>
            <Select
              showSearch
              dropdownMatchSelectWidth={false}
              style={{
                caretColor: "transparent",
                width: "100%",
              }}
              filterOption={(input, option) =>
                (option?.children?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              defaultValue={
                props.record.customerSuppliedPrimer
                  ? "customerSupplied"
                  : props.record.primerMaterial &&
                    `${props.record.primerMaterial.manufacturer}:${props.record.primerMaterial.product}:${props.record.primerMaterial.sheen}`
              }
              onChange={(value: string) =>
                props.handlePrimerMaterialChange(value, props.record)
              }
            >
              {adminDefaults.materialDefaults.primers.map(
                (material: any, index: any) => (
                  <option key={index} value={material["_id"]}>
                    {`${material.manufacturer}: ${material.product}: ${material.sheen}`}
                  </option>
                )
              )}
              <option key="customerSupplied" value="customerSupplied">
                Customer Supplied
              </option>
            </Select>
          </div>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Prep Hours</span>
            <div style={{ marginLeft: 10 }}>{props.record.prepHours}</div>
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Paint Hours</span>
            <div style={{ marginLeft: 10 }}>{props.record.paintHours}</div>
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Prime Hours</span>
            <div style={{ marginLeft: 10 }}>{props.record.primeHours}</div>
          </div>
        </Col>

        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Total Paint Hours</span>
            <div style={{ marginLeft: 10 }}>{props.record.totalPaintHours}</div>
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Paint Gallons</span>
            <div style={{ marginLeft: 10 }}>{props.record.paintGallons}</div>
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Primer Gallons</span>
            <div style={{ marginLeft: 10 }}>{props.record.primerGallons}</div>
          </div>
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Prep Labor Rate</span>
            <Input
              type="number"
              name="prepLaborRates"
              onChange={(e: any) =>
                props.handleInputsChange(e, props.index, props.record._id)
              }
              value={props.record.prepLaborRate}
              defaultValue={props.record.prepLaborRate}
            />
          </div>
        </Col>

        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Paint Labor Rate</span>
            <Input
              type="number"
              name="paintLaborRates"
              onChange={(e: any) =>
                props.handleInputsChange(e, props.index, props.record._id)
              }
              value={props.record.paintLaborRate}
              defaultValue={props.record.paintLaborRate}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Prime Labor Rate</span>
            <Input
              type="number"
              name="primeLaborRates"
              onChange={(e: any) =>
                props.handleInputsChange(e, props.index, props.record._id)
              }
              value={props.record.primeLaborRate}
              defaultValue={props.record.primeLaborRate}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Paint Material Cost</span>
            <div style={{ marginLeft: 10, marginTop: 15 }}>
              {props.record.paintMaterialCost}
            </div>
          </div>
        </Col>

        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Paint Spread Rate</span>
            <Input
              type="number"
              name="paintSpreadRates"
              onChange={(e: any) =>
                props.handleInputsChange(e, props.index, props.record._id)
              }
              value={props.record.paintSpreadRate}
              defaultValue={props.record.paintSpreadRate}
            />
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Primer Spread Rate</span>
            <Input
              type="number"
              name="primeSpreadRates"
              onChange={(e: any) =>
                props.handleInputsChange(e, props.index, props.record._id)
              }
              value={props.record.primerSpreadRate}
              defaultValue={props.record.primerSpreadRate}
            />
          </div>
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Prep Level</span>
            <div className="set-opacity">
              <Rate
                onHoverChange={(level: number) =>
                  handleHover(setPrepRateColor, level, props?.record.prepLevel)
                }
                style={{ color: prepRateColor }}
                allowClear={false}
                count={3}
                value={props.record.prepLevel}
                onChange={onPrepChange}
                character={<RectangleIcon />}
              />
            </div>
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Diff Level</span>
            <div className="set-opacity">
              <Rate
                onHoverChange={(level: number) =>
                  handleHover(
                    setDiffLevelColor,
                    level,
                    props?.record.paintLevel
                  )
                }
                style={{ color: diffLevelColor }}
                count={3}
                value={props.record.paintLevel}
                onChange={onDiffChange}
                character={<RectangleIcon />}
              />
            </div>
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Prime Level</span>
            <div className="set-opacity">
              <Rate
                count={3}
                onHoverChange={(level: number) =>
                  handleHover(
                    setPrimeLevelColor,
                    level,
                    props?.record.primeLevel
                  )
                }
                style={{ color: primeLevelColor }}
                value={props.record.primeLevel}
                onChange={onPrimeChange}
                character={<RectangleIcon />}
              />
            </div>
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Paint Price/Gallon</span>
            <div style={{ marginLeft: 10 }}>
              {props.record.customerSuppliedPaint
                ? 0
                : findMaterial(props.record.paintMaterial)}
            </div>
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <div className="table-form-group">
            <span>Primer Price/Gallon</span>
            <div style={{ marginLeft: 10 }}>
              {props.record.customerSuppliedPrimer
                ? 0
                : findMaterial(props.record.primerMaterial)}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ExpandedRow;
