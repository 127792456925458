import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export const paintOrderPDF = async (
  items: any,
  currentProject: any,
  adminDefaults: any,
  closestStoreData?: any
) => {
  const horizontalMargin = 30;
  const columnsForTableOne = [
    { header: "Product", dataKey: "product" },
    { header: "Color", dataKey: "color" },
    { header: "Gallons", dataKey: "paintGallons" },
  ];
  const columnsForTableTwo = [
    { header: "Primer", dataKey: "primer" },
    { header: "Gallons", dataKey: "primeGallons" },
  ];

  var doc = new jsPDF("p", "pt");
  doc.setFontSize(30);

  doc.text("Paint Materials", horizontalMargin, 60);
  let tableEndYPos = 150;

  doc.setFontSize(8);

  doc.text("Account Number:", horizontalMargin, tableEndYPos);
  doc.text(
    `${items[currentProject]?.paintOrder?.accountNumber}`,
    150 + horizontalMargin,
    tableEndYPos
  );
  doc.text("Store Phone:", 300 + horizontalMargin, tableEndYPos);
  doc.text(
    `${closestStoreData?.phone ? closestStoreData.phone : ""}`,
    450 + horizontalMargin,
    tableEndYPos
  );
  tableEndYPos += 12;
  doc.text("PO:", horizontalMargin, tableEndYPos);
  doc.text(
    `${items[currentProject]?.projectInfo?.jobNumber}`,
    150 + horizontalMargin,
    tableEndYPos
  );
  doc.text("PRC:", 300 + horizontalMargin, tableEndYPos);
  if (items[currentProject]?.prePresentationChecklist?.activePrcDescription) {
  }
  doc.text(
    items[currentProject]?.prePresentationChecklist?.activePrcDescription
      ? items[
          currentProject
        ]?.prePresentationChecklist?.activePrcDescription.toString()
      : "",
    450 + horizontalMargin,
    tableEndYPos
  );
  tableEndYPos += 15;

  const splitJobSiteAddress = doc.splitTextToSize(
    items[currentProject]?.paintOrder?.jobSiteAddress,
    350
  );

  doc.text("Delivery Address:", horizontalMargin, tableEndYPos);
  doc.text(splitJobSiteAddress, 150 + horizontalMargin, tableEndYPos);
  tableEndYPos += 12 * splitJobSiteAddress.length;

  doc.text("Closest Store Address:", horizontalMargin, tableEndYPos);
  doc.text(
    `${closestStoreData?.address ? closestStoreData?.address : ""}`,
    150 + horizontalMargin,
    tableEndYPos
  );
  tableEndYPos += 12;

  doc.text("Store Number", horizontalMargin, tableEndYPos);
  doc.text(
    `${closestStoreData?.number !== null ? closestStoreData.number : "N/A"}`,
    150 + horizontalMargin,
    tableEndYPos
  );
  tableEndYPos += 12;

  const rowsForTableOne = items[currentProject]?.paintOrder?.paintInfo;
  const paintTableMapped = rowsForTableOne.map((row: any) => {
    let colorObj = adminDefaults.colors.find(
      (item: any) => item.name === row.color
    );
    return {
      ...row,
      color: "      " + row.color,
      colorCode: colorObj?.hexCode,
    };
  });
  const rowsForTableTwo = items[currentProject]?.paintOrder?.primerInfo.filter(
    (item: any) => item.primeGallons > 0
  );
  if (rowsForTableTwo.length > 0) {
    autoTable(doc, {
      body: rowsForTableTwo,
      columns: columnsForTableTwo,
      startY: tableEndYPos,
      margin: { horizontal: horizontalMargin },
      // margin: { horizontal: 10 },
      styles: {
        lineColor: "#585858",
        lineWidth: 1,
      },
      headStyles: {
        fillColor: "#FDB913",
        halign: "center",
        textColor: "black",
        fontSize: 8,
      },
      bodyStyles: { fillColor: "#FAF9F9", fontSize: 8 },
      columnStyles: {
        1: {
          halign: "center",
          cellWidth: 50,
        },
      },
      // eslint-disable-next-line no-loop-func
      didDrawPage: (data) => {
        tableEndYPos = data.cursor?.y || tableEndYPos;
      },
    });

    tableEndYPos += 20;
  }

  autoTable(doc, {
    body: paintTableMapped,
    columns: columnsForTableOne,
    startY: tableEndYPos,
    margin: { horizontal: horizontalMargin },
    styles: {
      lineColor: "#585858",
      lineWidth: 1,
      textColor: "black",
    },
    headStyles: {
      fillColor: "#FDB913",
      halign: "center",
      textColor: "black",
      fontSize: 8,
    },
    bodyStyles: { fillColor: "#FAF9F9", fontSize: 8 },
    columnStyles: {
      2: {
        halign: "center",
        cellWidth: 50,
      },
    },
    didDrawPage: (data) => {
      tableEndYPos = data.cursor?.y || tableEndYPos;
      // tableEndYPos = data.table.finalY || 0;
    },
    didDrawCell: (data) => {
      if (data.row.section !== "head") {
        if (data.row.index % 2 === 0) {
          data.cell.styles.fillColor = "#E2E2E2";
        } else {
          data.cell.styles.fillColor = "#FFFFFF";
        }
        let xPos = data.cursor ? data.cursor.x : 0;
        let yPos = data.cursor ? data.cursor.y : 0;
        let startYColor = 5;
        if (paintTableMapped[data.row.index].lines >= 2) {
          startYColor = 14;
        }

        if (
          data.column.dataKey === "color" &&
          paintTableMapped[data.row.index].colorCode
        ) {
          let fillColor = doc.getFillColor();
          let drawColor = doc.getDrawColor();
          doc.setDrawColor(0, 0, 0);
          doc.setFillColor(paintTableMapped[data.row.index].colorCode);
          doc.rect(xPos + 5, yPos + startYColor, 9, 9, "F");
          doc.setFillColor(fillColor);
          doc.setDrawColor(drawColor);
        }
      }
    },
  });

  tableEndYPos += 30;
  let blob = doc.output();
  return blob;
};
