import api from "../../utils/api";

const setMaterialDefaults = (payload: any) => ({
  type: "SET_DEFAULTS",
  payload,
});

const addPaintDefaults = (payload: any) => ({
  type: "ADD_PAINT",
  payload,
});

const addPrimerDefaults = (payload: any) => ({
  type: "ADD_PRIMER",
  payload,
});

const removePaintMaterial = (payload: any) => ({
  type: "REMOVE_PAINT",
  payload,
});
const removePrimerMaterial = (payload: any) => ({
  type: "REMOVE_PRIMER",
  payload,
});
const updatePaintMaterial = (payload: any) => ({
  type: "UPDATE_PAINT",
  payload,
});

export const bulkAddDefaultMaterial = (payload: any) => ({
  type: "BULK_ADD_DEFAULT_MATERIAL",
  payload,
});

const updatePrimerMaterial = (payload: any) => ({
  type: "UPDATE_PRIMER",
  payload,
});

const hideLoader = () => ({
  type: "HIDE_LOADER",
});

export const deleteMaterialDefaults =
  (id: string, material: string) => (dispatch: any) => {
    api
      .delete(`material/${id}`)
      .then(() => {
        if (material === "paint") {
          dispatch(removePaintMaterial(id));
        } else {
          dispatch(removePrimerMaterial(id));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

export const fetchMaterialDefaults = () => (dispatch: any) => {
  api
    .get("material")
    .then((response: any) => {
      dispatch(setMaterialDefaults(response));
      dispatch(hideLoader());
    })
    .catch((err) => {
      console.error(err);
      dispatch(hideLoader());
    });
};

export const addMaterialItem = (values: any) => (dispatch: any) => {
  api
    .post("material", values)
    .then((response: any) => {
      if (values.material === "paint") {
        dispatch(addPaintDefaults(response));
      } else {
        dispatch(addPrimerDefaults(response));
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

export const updateMaterialItem = (values: any) => (dispatch: any) => {
  api
    .put(`material/${values._id}`, values)
    .then(() => {
      if (values.material === "paint") {
        dispatch(updatePaintMaterial(values));
      } else {
        dispatch(updatePrimerMaterial(values));
      }
    })
    .catch((err) => {
      console.error(err);
    });
};
