import React from "react";
import { version } from "../../../package.json";

type Props = {};

const About = (props: Props) => {
  return <div>v{version}</div>;
};

export default About;
