import { AES } from 'crypto-js';
import Utf8 from 'crypto-js/enc-utf8';

const secretKey: string = 'secret';

export const getAuthUser = () =>
  JSON.parse(localStorage.getItem('user') || '{}');

export const getSelectedTenant = () => {
  const authString = localStorage.getItem('reduxPersist:auth') || '{}';
  const authObject = JSON.parse(authString);
  let tenantId = authObject.tenant;
  if (tenantId) {
    return tenantId;
  } else {
    const bytes = AES.decrypt(authObject, secretKey);
    const decryptedAuthObj = JSON.parse(bytes.toString(Utf8));
    return decryptedAuthObj.tenant;
  }
};

export const storeUser = (data: any) =>
  localStorage.setItem('user', JSON.stringify(data));
