import { userInfo } from 'os';
import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import UserContact from '../../components/user-contact/UserContact';
import UserInfo from '../../components/user-info/UserInfo';

type Props = {};

const UserProfileC = (props: Props) => {
  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  return (
    <div>
      <h1 style={{ fontWeight: 'bold', fontSize: '36px', lineHeight: '43px' }}>
        User Profile
      </h1>
      <UserInfo
        userName={user.fullName}
        picture={user.picture}
        jobTitle={user.jobTitle ? user.jobTitle : 'N/A'}
        jobDescription={user.jobDescription ? user.jobDescription : 'N/A'}
      />
      <div style={{ marginTop: '25px' }}>
        <UserContact
          contact={user.phone ? user.phone : 'N/A'}
          email={user.email}
          address={user.location ? user.location : 'N/A'}
        />
      </div>
    </div>
  );
};

export default UserProfileC;
