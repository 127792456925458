import jsPDF from "jspdf";
import logo from "../assets/images/Pro-Driven-Edit.jpg";
import proposalIcon from "../assets/images/business-proposal.jpg";
import phoneIcon from "../assets/images/phone.jpg";
import mailIcon from "../assets/images/mail.jpg";
import addressIcon from "../assets/images/address-icon.png";
import autoTable from "jspdf-autotable";

import { LatoRegular } from "../assets/fonts/LatoRegular";
import { LatoBold } from "../assets/fonts/LatoBold";
import moment from "moment";
import { log } from "util";

function drawDivider(
  document: jsPDF,
  position: number,
  x1: number,
  x2: number
) {
  document.setLineWidth(3);
  document.setDrawColor("#fdb913");
  document.line(x1, position, x2, position);
}

const getColorFromHtmlStyleString = (styleText: string) => {
  let startIndex = styleText.indexOf("(");
  let endIndex = styleText.indexOf(")");
  let rgbString = styleText.substring(startIndex + 1, endIndex);
  let rgbValuesArray = rgbString.split(", ");
  return rgbValuesArray.map((rgbValueString) => parseInt(rgbValueString));
};

const printWordsWithWrapping = (
  doc: jsPDF,
  text: string,
  xPos: number,
  yPos: number
) => {
  let words = text.split(" ");
  for (let word of words) {
    word = word.trim();
    if (xPos + doc.getTextWidth(word) > 565) {
      yPos += 10;
      xPos = 30;
    }
    // let wordWithSpace = word;
    let wordWithSpace = `${word} `;
    if (word.length < 1) {
      continue;
    }
    doc.text(wordWithSpace, xPos, yPos);
    xPos += doc.getTextWidth(wordWithSpace);
  }
  return { xPos, yPos };
};

const hasLargerFontSize = async (
  proposalDoc: jsPDF,
  pTagWithMultipleElements: any
) => {
  let fontSize = 0;
  for (const element of pTagWithMultipleElements) {
    if (
      typeof element === "object" &&
      element.type === "span" &&
      element.attributes.class
    ) {
      if (element.attributes.class === "ql-size-large") {
        if (fontSize !== 2) fontSize = 1;
      } else if (element.attributes.class === "ql-size-huge") {
        fontSize = 2;
      }
    }
  }
  return fontSize;
};

const delay = (delayInms: number) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};

export const generateProposalPdfWithHtml = async (
  proposalDoc: jsPDF,
  htmlObject: any,
  htmlStartPosition: number
) => {
  const horizontalMargin = 30;
  const pageLimit = 790; // Define the page limit
  const pageTopMargin = 60;
  proposalDoc.setLineWidth(1);
  proposalDoc.setDrawColor("black");

  let docEndPosition = htmlStartPosition;

  let cursorPosition = {
    xPos: horizontalMargin,
    yPos: docEndPosition,
  };

  const defaultFillColor = proposalDoc.getFillColor();
  const defaultTextColor = proposalDoc.getTextColor();
  const defaultFontSize = proposalDoc.getFontSize();

  proposalDoc.addFileToVFS("Lato-Regular.ttf", LatoRegular);
  proposalDoc.addFont("Lato-Regular.ttf", "LatoRegular", "normal");
  proposalDoc.addFileToVFS("Lato-Bold.ttf", LatoBold);
  proposalDoc.addFont("Lato-Bold.ttf", "LatoRegular", "bold");

  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.setFontSize(8);
  docEndPosition += 10;
  let hasHugeFont = false;
  for (const element of htmlObject.content) {
    // p tag with multiple HTML elements
    switch (element.type) {
      case "p":
        if (element.content.length > 1) {
          const containsLargerFontSize = await hasLargerFontSize(
            proposalDoc,
            element.content
          );
          if (containsLargerFontSize === 1) {
            docEndPosition += 10;
          } else if (containsLargerFontSize === 2) {
            docEndPosition += 5;
          }
          let xPos = horizontalMargin;
          element.content.forEach((currentElement: any) => {
            if (typeof currentElement === "string") {
              cursorPosition = printWordsWithWrapping(
                proposalDoc,
                currentElement,
                xPos,
                docEndPosition
              );
              xPos = cursorPosition.xPos;
              docEndPosition = cursorPosition.yPos;
            }
            if (typeof currentElement === "object") {
              switch (currentElement.type) {
                case "strong":
                  proposalDoc.setFont("LatoRegular", "bold");
                  cursorPosition = printWordsWithWrapping(
                    proposalDoc,
                    currentElement.content[0],
                    xPos,
                    docEndPosition
                  );
                  docEndPosition = cursorPosition.yPos;
                  xPos = cursorPosition.xPos;
                  proposalDoc.setFont("LatoRegular", "normal");
                  break;
                case "em":
                  proposalDoc.setFont("LatoRegular", "italic");
                  cursorPosition = printWordsWithWrapping(
                    proposalDoc,
                    currentElement.content[0],
                    xPos,
                    docEndPosition
                  );
                  docEndPosition = cursorPosition.yPos;
                  xPos = cursorPosition.xPos;
                  proposalDoc.setFont("LatoRegular", "normal");
                  break;
                case "u": {
                  const textWidth = proposalDoc.getTextWidth(
                    currentElement.content[0]
                  );
                  proposalDoc.line(
                    xPos,
                    docEndPosition + 1,
                    textWidth + xPos,
                    docEndPosition + 1
                  );
                  cursorPosition = printWordsWithWrapping(
                    proposalDoc,
                    currentElement.content[0],
                    xPos,
                    docEndPosition
                  );
                  docEndPosition = cursorPosition.yPos;
                  xPos = cursorPosition.xPos;
                  // docEndPosition += 10;
                  break;
                }
                case "s": {
                  const textWidth = proposalDoc.getTextWidth(
                    currentElement.content[0]
                  );
                  proposalDoc.line(
                    xPos,
                    docEndPosition - 3,
                    textWidth + xPos,
                    docEndPosition - 3,
                    "FD"
                  );
                  cursorPosition = printWordsWithWrapping(
                    proposalDoc,
                    currentElement.content[0],
                    xPos,
                    docEndPosition
                  );
                  docEndPosition = cursorPosition.yPos;
                  xPos = cursorPosition.xPos;
                  // docEndPosition += 10;
                  break;
                }
                case "a":
                  proposalDoc.setTextColor("blue");
                  if (
                    xPos + proposalDoc.getTextWidth(currentElement.content[0]) >
                    565
                  ) {
                    xPos = horizontalMargin;
                    docEndPosition += 10;
                  }
                  proposalDoc.textWithLink(
                    currentElement.content[0],
                    xPos,
                    docEndPosition,
                    { url: currentElement.attributes.href }
                  );
                  xPos += proposalDoc.getTextWidth(currentElement.content[0]);
                  proposalDoc.setTextColor(defaultTextColor);
                  // docEndPosition += 10;
                  break;
                case "span":
                  let contentStyle = currentElement.attributes.style;
                  if (contentStyle) {
                    if (contentStyle.startsWith("background-color")) {
                      //draw bg rect
                      let colorArry = getColorFromHtmlStyleString(contentStyle);
                      const textWidth = proposalDoc.getTextWidth(
                        currentElement.content[0]
                      );
                      proposalDoc.setFillColor(
                        colorArry[0],
                        colorArry[1],
                        colorArry[2]
                      );
                      proposalDoc.rect(
                        xPos,
                        docEndPosition - 8,
                        textWidth,
                        10,
                        "F"
                      );
                      cursorPosition = printWordsWithWrapping(
                        proposalDoc,
                        currentElement.content[0],
                        xPos,
                        docEndPosition
                      );
                      docEndPosition = cursorPosition.yPos;
                      xPos = cursorPosition.xPos;
                      proposalDoc.setFillColor(defaultFillColor);
                    } else if (contentStyle.startsWith("color")) {
                      //set font color
                      let colorArry = getColorFromHtmlStyleString(contentStyle);
                      proposalDoc.setTextColor(
                        colorArry[0],
                        colorArry[1],
                        colorArry[2]
                      );
                      cursorPosition = printWordsWithWrapping(
                        proposalDoc,
                        currentElement.content[0],
                        xPos,
                        docEndPosition
                      );
                      docEndPosition = cursorPosition.yPos;
                      xPos = cursorPosition.xPos;
                      proposalDoc.setTextColor(defaultTextColor);
                      // docEndPosition += 10;
                    }
                  } else {
                    contentStyle = currentElement.attributes.class;
                    switch (contentStyle) {
                      case "ql-size-huge":
                        proposalDoc.setFontSize(18);
                        cursorPosition = printWordsWithWrapping(
                          proposalDoc,
                          currentElement.content[0],
                          xPos,
                          docEndPosition
                        );
                        docEndPosition = cursorPosition.yPos;
                        xPos = cursorPosition.xPos;
                        proposalDoc.setFontSize(defaultFontSize);
                        break;
                      case "ql-size-large":
                        proposalDoc.setFontSize(12);
                        cursorPosition = printWordsWithWrapping(
                          proposalDoc,
                          currentElement.content[0],
                          xPos,
                          docEndPosition
                        );
                        docEndPosition = cursorPosition.yPos;
                        xPos = cursorPosition.xPos;
                        proposalDoc.setFontSize(defaultFontSize);
                        break;
                      case "ql-size-small":
                        proposalDoc.setFontSize(6);
                        cursorPosition = printWordsWithWrapping(
                          proposalDoc,
                          currentElement.content[0],
                          xPos,
                          docEndPosition
                        );
                        docEndPosition = cursorPosition.yPos;
                        xPos = cursorPosition.xPos;
                        proposalDoc.setFontSize(defaultFontSize);
                        break;
                      default:
                        break;
                    }
                  }
                  break;
                default:
                  console.log("default case: ", currentElement);
              }
              // xPos += (proposalDoc.getTextWidth(currentElement.content[0]));
              if (xPos >= 565) {
                docEndPosition += 10;
                xPos = horizontalMargin;
              }
            }
          });
          docEndPosition += 10;
        } else {
          //Single line in a <p> tag
          let isLink = false;
          const content = element.content[0];
          if (typeof content === "string") {
            // Simple text without any styling
            let splitText = proposalDoc.splitTextToSize(
              content,
              565 - horizontalMargin
            );
            proposalDoc.text(splitText, horizontalMargin, docEndPosition);
            docEndPosition += 10 * splitText.length;
          } else {
            switch (content.type) {
              case "strong":
                proposalDoc.setFont("LatoRegular", "bold");
                break;
              case "em":
                proposalDoc.setFont("LatoRegular", "italic");
                break;
              case "u": {
                const textWidth = proposalDoc.getTextWidth(content.content[0]);
                proposalDoc.line(
                  horizontalMargin,
                  docEndPosition + 1,
                  textWidth + horizontalMargin,
                  docEndPosition + 1
                );
                break;
              }
              case "s": {
                const textWidth = proposalDoc.getTextWidth(content.content[0]);
                proposalDoc.line(
                  horizontalMargin,
                  docEndPosition - 3,
                  textWidth + horizontalMargin,
                  docEndPosition - 3,
                  "FD"
                );
                break;
              }
              case "a":
                proposalDoc.setTextColor("blue");
                proposalDoc.textWithLink(
                  content.content[0],
                  horizontalMargin,
                  docEndPosition,
                  { url: content.attributes.href }
                );
                docEndPosition += 10;
                isLink = true;
                break;
              case "span":
                let contentStyle = content.attributes.style;
                if (contentStyle) {
                  if (contentStyle.startsWith("background-color")) {
                    //draw bg rect
                    let colorArry = getColorFromHtmlStyleString(contentStyle);
                    const textWidth = proposalDoc.getTextWidth(
                      content.content[0]
                    );
                    proposalDoc.setFillColor(
                      colorArry[0],
                      colorArry[1],
                      colorArry[2]
                    );
                    proposalDoc.rect(
                      horizontalMargin,
                      docEndPosition - 8,
                      textWidth,
                      10,
                      "F"
                    );
                  } else if (contentStyle.startsWith("color")) {
                    //set font color
                    let colorArry = getColorFromHtmlStyleString(contentStyle);
                    proposalDoc.setTextColor(
                      colorArry[0],
                      colorArry[1],
                      colorArry[2]
                    );
                  }
                } else {
                  contentStyle = content.attributes.class;
                  switch (contentStyle) {
                    case "ql-size-huge":
                      proposalDoc.setFontSize(18);
                      docEndPosition += 10;
                      break;
                    case "ql-size-large":
                      proposalDoc.setFontSize(12);
                      docEndPosition += 5;
                      break;
                    case "ql-size-small":
                      proposalDoc.setFontSize(6);
                      break;
                    default:
                      break;
                  }
                }
                break;
              case "br":
                break;
              default:
                debugger;
                console.log("default case: ", content);
            }
            if (!isLink) {
              let text = content.content ? content.content[0] : " ";
              let splitText = proposalDoc.splitTextToSize(
                text,
                565 - horizontalMargin
              );
              for (let i = 0; i < splitText.length; i++) {
                proposalDoc.text(
                  splitText[i],
                  horizontalMargin,
                  docEndPosition
                );
                docEndPosition += 10;
                if (docEndPosition >= pageLimit) {
                  proposalDoc.addPage();
                  docEndPosition = pageTopMargin;
                }
              }
            }

            proposalDoc.setTextColor(defaultTextColor);
            proposalDoc.setFillColor(defaultFillColor);
            proposalDoc.setFontSize(defaultFontSize);
            proposalDoc.setFont("LatoRegular", "normal");
          }
        }
        break;
      case "ol": {
        const itemsList: any[] = element.content;

        const processContent = (content: any): string => {
          if (typeof content === "string") {
            return content;
          } else if (Array.isArray(content)) {
            return content.map(processContent).join("");
          } else {
            return processContent(content.content);
          }
        };

        const addTextToDoc = (
          text: string,
          margin: number,
          position: number
        ) => {
          const splitText = proposalDoc.splitTextToSize(text, 515 - margin);
          splitText.forEach((line: any) => {
            if (position > proposalDoc.internal.pageSize.height - 20) {
              proposalDoc.addPage();
              position = 20;
            }
            proposalDoc.setFont("LatoRegular", "normal");
            proposalDoc.text(line, margin + 20, position);
            position += 10;
          });
          return position;
        };

        itemsList.forEach((item: any, index: number) => {
          if (docEndPosition > proposalDoc.internal.pageSize.height - 20) {
            proposalDoc.addPage();
            docEndPosition = 20;
          }

          const numberPrefix = index < 9 ? "        " : "     ";
          const ovalue: string = processContent(item);

          proposalDoc.text(
            `${numberPrefix}${index + 1}.`,
            horizontalMargin,
            docEndPosition
          );
          docEndPosition = addTextToDoc(
            ovalue,
            horizontalMargin,
            docEndPosition
          );
        });

        break;
      }

      case "ul": {
        const itemsList: any[] = element.content;

        const processContent = (content: any): string => {
          if (typeof content === "string") {
            return content;
          } else if (Array.isArray(content)) {
            return content.map(processContent).join("");
          } else {
            return processContent(content.content);
          }
        };

        const addTextToDoc = (
          text: string,
          margin: number,
          position: number
        ) => {
          const splitText = proposalDoc.splitTextToSize(text, 515 - margin);
          splitText.forEach((line: any) => {
            if (position > proposalDoc.internal.pageSize.height - 20) {
              proposalDoc.addPage();
              position = 20;
            }
            proposalDoc.setFont("LatoRegular", "normal");
            proposalDoc.text(line, margin + 20, position);
            position += 10;
          });
          return position;
        };

        itemsList.forEach((item) => {
          if (docEndPosition > proposalDoc.internal.pageSize.height - 20) {
            proposalDoc.addPage();
            docEndPosition = 20; // reset position to top margin
          }

          proposalDoc.text(`        \u2022 `, horizontalMargin, docEndPosition);

          const itemText: string = processContent(item);

          docEndPosition = addTextToDoc(
            itemText,
            horizontalMargin,
            docEndPosition
          );
        });

        break;
      }
    }
  }
  return docEndPosition;
};

export const generateProposalCPdf = async (
  project: any,
  adminDefaults: any,
  companyInfo: any,
  type: any,
  userPhoto: any,
  parsedHTML?: any,
  optionsMediaList?: any
) => {
  //   const { currentProject, items, adminDefaults } = useSelector(
  //     (state: RootStateOrAny) => state.offlineData
  //   );

  let optionPjccForProposal: any[] = [];
  project.options.map((option: any) => {
    let optionPjcc = option.pjcc.filter(
      (item: any) => item.name === "Estimated Price"
    );
    optionPjccForProposal.push(...optionPjcc);
  });

  let subTotal = optionPjccForProposal?.reduce((initial: any, value: any) => {
    return value.amount + initial;
  }, 0);

  const horizontalMargin = 30;
  const pageLimit = 800;
  const pageTopMargin = 60;

  let image = new Image();
  image.src = `${companyInfo?.companyLogo}`;
  const imageAspectRatio = image.naturalWidth / image.naturalHeight;
  let contractEndPosition = 100;
  var proposalDoc = new jsPDF("p", "pt");

  const defaultFillCol = proposalDoc.getFillColor();
  const defaultDrawCol = proposalDoc.getDrawColor();
  const defaultLineWidth = proposalDoc.getLineWidth();
  proposalDoc.setFillColor("#fdb913");
  proposalDoc.rect(30, 30, 535, 80, "F");
  proposalDoc.setFillColor(defaultFillCol);

  proposalDoc.addFileToVFS("Lato-Regular.ttf", LatoRegular);
  proposalDoc.addFont("Lato-Regular.ttf", "LatoRegular", "normal");
  proposalDoc.addFileToVFS("Lato-Bold.ttf", LatoBold);
  proposalDoc.addFont("Lato-Bold.ttf", "LatoRegular", "bold");

  proposalDoc.setFont("LatoRegular");
  proposalDoc.setFontSize(20);

  let formattedDate = moment(project?.projectInfo?.proposal?.createdAt).format(
    "MMMM DD,YYYY"
  );

  proposalDoc.addImage(
    image,
    "png",
    35,
    40,
    140,
    140 / imageAspectRatio,
    "logo",
    "FAST"
  );

  proposalDoc.setLineWidth(5);
  proposalDoc.setDrawColor("#fdb913");
  proposalDoc.rect(42, 48, 122, 37, "D");
  proposalDoc.setDrawColor(defaultDrawCol);
  proposalDoc.setLineWidth(defaultLineWidth);

  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.setFontSize(8);

  const headerTextCm = 297;
  proposalDoc.text(
    `${companyInfo?.companyName}`,
    headerTextCm,
    contractEndPosition - 40,
    {
      align: "center",
    }
  );

  proposalDoc.text(
    `Address: ${companyInfo?.companyStreetAddressOne}, ${companyInfo?.companyStreetAddressTwo
      ? companyInfo?.companyStreetAddressTwo + ", "
      : ""
    }`,
    headerTextCm,
    contractEndPosition - 30,
    {
      align: "center",
    }
  );

  proposalDoc.text(
    `${companyInfo?.companyCity}, ${companyInfo?.companyState}, ${companyInfo?.companyZip}`,
    headerTextCm,
    contractEndPosition - 20,
    {
      align: "center",
    }
  );

  proposalDoc.text(
    `Telephone: ${companyInfo?.companyTelephone}`,
    headerTextCm,
    contractEndPosition - 10,
    {
      align: "center",
    }
  );

  proposalDoc.text(
    `Website: ${companyInfo?.companyWebsite}`,
    headerTextCm,
    contractEndPosition - 0,
    {
      align: "center",
    }
  );

  const headerTextRm = 550;
  proposalDoc.text(
    `Type: ${project?.projectInfo?.projectType} ${project?.projectInfo?.projectSubtype}`,
    headerTextRm,
    contractEndPosition - 40,
    {
      align: "right",
    }
  );

  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.setFontSize(8);
  proposalDoc.text(
    `Proposal #: ${project?.projectInfo?.jobNumber}`,
    headerTextRm,
    contractEndPosition - 30,
    {
      align: "right",
    }
  );
  proposalDoc.text(
    `Date: ${formattedDate}`,
    headerTextRm,
    contractEndPosition - 20,
    {
      align: "right",
    }
  );

  // const ProposalDocIconImage = new Image();
  // ProposalDocIconImage.src = proposalIcon;
  // proposalDoc.addImage(ProposalDocIconImage, "png", 200, 70, 40, 40);
  // proposalDoc.setFont("LatoRegular", "bold");
  // proposalDoc.setFontSize(24);
  // proposalDoc.text("PROPOSAL", horizontalMargin + 210, contractEndPosition);
  // proposalDoc.line(0, 120, 600, 120);

  contractEndPosition += 30;
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.setFontSize(12);
  proposalDoc.text("JOBSITE", horizontalMargin, contractEndPosition);

  proposalDoc.text("PREPARED BY", horizontalMargin + 270, contractEndPosition);

  contractEndPosition += 5;
  drawDivider(proposalDoc, contractEndPosition, 30, 250);

  drawDivider(proposalDoc, contractEndPosition, horizontalMargin + 270, 565);
  contractEndPosition = contractEndPosition + 6;

  if (userPhoto) {
    const profileImage = new Image();
    profileImage.src = userPhoto;
    proposalDoc.addImage(
      profileImage,
      "png",
      horizontalMargin + 450,
      contractEndPosition + 5,
      45,
      45,
      "profile",
      "FAST"
    );
  }

  // proposalDoc.setFillColor(255, 255, 255);
  // proposalDoc.setDrawColor(255, 255, 255);
  // proposalDoc.setLineWidth(12);
  // proposalDoc.circle(horizontalMargin + 226, contractEndPosition + 36, 32, "D");

  proposalDoc.setFontSize(8);
  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.text(
    project?.projectInfo?.salesAssociate?.fullName,
    horizontalMargin + 270,
    contractEndPosition + 10
  );
  proposalDoc.text(
    project?.projectInfo?.salesAssociate?.jobTitle
      ? project?.projectInfo?.salesAssociate?.jobTitle
      : "",
    horizontalMargin + 270,
    contractEndPosition + 20
  );
  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.text(
    `${project?.projectInfo?.salesAssociate?.phone
      ? project?.projectInfo?.salesAssociate?.phone
      : ""
    }`,
    horizontalMargin + 270,
    contractEndPosition + 30
  );

  proposalDoc.text(
    project?.projectInfo?.salesAssociate?.email,
    horizontalMargin + 270,
    contractEndPosition + 40
  );

  proposalDoc.setFontSize(8);

  proposalDoc.text(
    project?.projectInfo?.jobName,
    horizontalMargin,
    contractEndPosition + 10
  );

  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.text(
    project?.projectInfo?.streetAddressOne,
    horizontalMargin,
    contractEndPosition + 20
  );
  contractEndPosition = contractEndPosition + 10;

  proposalDoc.setFont("LatoRegular", "normal");

  const locationText = `${project?.projectInfo?.city}, ${project?.projectInfo?.state}, ${project?.projectInfo?.zip}`;

  const maxCharsPerLine = 45;
  const maxLines = 2;
  let lines = [];
  let remainingText = locationText;

  for (let i = 0; i < maxLines; i++) {
    if (remainingText.length > maxCharsPerLine) {
      const line = remainingText.substring(0, maxCharsPerLine).trim();
      lines.push(line);
      remainingText = remainingText.substring(maxCharsPerLine).trim();
    } else {
      lines.push(remainingText.trim());
      break;
    }
  }

  for (let i = 0; i < lines.length; i++) {
    proposalDoc.text(
      lines[i],
      horizontalMargin,
      contractEndPosition + 20 + i * 10
    );
  }

  contractEndPosition += lines.length * 1;

  proposalDoc.text(
    project?.projectInfo?.primaryContactTelephone || "",
    horizontalMargin,
    contractEndPosition + 30
  );
  proposalDoc.text(
    project?.projectInfo?.primaryContactEmail,
    horizontalMargin,
    contractEndPosition + 40
  );

  contractEndPosition += 60;

  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.setFontSize(12);
  proposalDoc.text("CLIENT", horizontalMargin, contractEndPosition);

  contractEndPosition += 5;
  drawDivider(proposalDoc, contractEndPosition, 30, 565);

  contractEndPosition += 20;
  const clientContactInfoStartPos = contractEndPosition;
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.setFontSize(8);
  proposalDoc.text("Type of Contact", horizontalMargin, contractEndPosition);
  proposalDoc.text("Primary", horizontalMargin + 80, contractEndPosition);
  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.setFontSize(8);
  contractEndPosition += 10;
  proposalDoc.text("Name", horizontalMargin, contractEndPosition);
  proposalDoc.text(
    project?.projectInfo.primaryContactName || "",
    horizontalMargin + 80,
    contractEndPosition
  );
  contractEndPosition += 10;
  proposalDoc.text("Telephone", horizontalMargin, contractEndPosition);
  proposalDoc.text(
    project?.projectInfo.primaryContactTelephone || "",
    horizontalMargin + 80,
    contractEndPosition
  );
  contractEndPosition += 10;
  proposalDoc.text("Cell", horizontalMargin, contractEndPosition);
  proposalDoc.text(
    project?.projectInfo.primaryContactCell || "",
    horizontalMargin + 80,
    contractEndPosition
  );
  contractEndPosition += 10;
  proposalDoc.text("Email", horizontalMargin, contractEndPosition);
  proposalDoc.text(
    project?.projectInfo.primaryContactEmail || "",
    horizontalMargin + 80,
    contractEndPosition
  );
  contractEndPosition += 10;
  proposalDoc.text("Address", horizontalMargin, contractEndPosition);
  proposalDoc.text(
    project?.projectInfo.primaryContactAddress || "",
    horizontalMargin + 80,
    contractEndPosition
  );

  if (project?.projectInfo?.proposal?.onSite) {
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      "On-site",
      horizontalMargin + 230,
      clientContactInfoStartPos
    );
    proposalDoc.setFont("LatoRegular", "normal");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      project?.projectInfo.onSiteContactName || "",
      horizontalMargin + 230,
      clientContactInfoStartPos + 10
    );
    proposalDoc.text(
      project?.projectInfo.onSiteContactTelephone || "",
      horizontalMargin + 230,
      clientContactInfoStartPos + 20
    );
    proposalDoc.text(
      project?.projectInfo.onSiteContactCell || "",
      horizontalMargin + 230,
      clientContactInfoStartPos + 30
    );
    proposalDoc.text(
      project?.projectInfo.onSiteContactEmail || "",
      horizontalMargin + 230,
      clientContactInfoStartPos + 40
    );
    proposalDoc.text(
      project?.projectInfo.onSiteContactAddress || "",
      horizontalMargin + 230,
      clientContactInfoStartPos + 50
    );
  }

  if (project?.projectInfo?.proposal?.maintenance) {
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      "Maintenance",
      horizontalMargin + 380,
      clientContactInfoStartPos
    );
    proposalDoc.setFont("LatoRegular", "normal");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      project?.projectInfo.maintenanceContactName || "",
      horizontalMargin + 380,
      clientContactInfoStartPos + 10
    );
    proposalDoc.text(
      project?.projectInfo.maintenanceContactTelephone || "",
      horizontalMargin + 380,
      clientContactInfoStartPos + 20
    );
    proposalDoc.text(
      project?.projectInfo.maintenanceContactCell || "",
      horizontalMargin + 380,
      clientContactInfoStartPos + 30
    );
    proposalDoc.text(
      project?.projectInfo.maintenanceContactEmail || "",
      horizontalMargin + 380,
      clientContactInfoStartPos + 40
    );
    proposalDoc.text(
      project?.projectInfo.maintenanceContactAddress || "",
      horizontalMargin + 380,
      clientContactInfoStartPos + 50
    );
  }

  contractEndPosition += 30;
  proposalDoc.setFontSize(12);
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.text("PRICE SUMMARY", horizontalMargin, contractEndPosition);
  contractEndPosition += 5;
  drawDivider(proposalDoc, contractEndPosition, 30, 565);

  const columns = [
    { header: "Options", dataKey: "title" },
    { header: "Price", dataKey: "estimatedPrice" },
  ];

  let tableEndYPos = contractEndPosition + 10;

  let projectPaintLabor = project?.pjcc.find(
    (item: any) => item.name === "Paint Labor"
  );
  let projectCarpentryLabor = project?.pjcc.find(
    (item: any) => item.name === "Carpentry Labor"
  );

  let totalLastPrice = 0;

  let total = 0;
  const includeOptions = project?.options.filter(
    (item: any) => item.optionInfo.included === "included"
  );

  const rows = includeOptions.map((option: any) => {
    let title = option.optionInfo.title;
    let paintLabor = option?.pjcc.find(
      (item: any) => item.name === "Paint Labor"
    );
    let carpentryLabor = option?.pjcc.find(
      (item: any) => item.name === "Carpentry Labor"
    );

    let paintLaborPrice =
      (paintLabor.cost / projectPaintLabor.cost) *
      projectPaintLabor.productionTargets;

    let carpentryLaborPrice =
      (carpentryLabor.cost / projectCarpentryLabor.cost) *
      projectCarpentryLabor.productionTargets;

    let proposalCTotalLabor =
      (isNaN(paintLaborPrice) ? 0 : paintLaborPrice) +
      (isNaN(carpentryLaborPrice) ? 0 : carpentryLaborPrice);

    total += Math.round(proposalCTotalLabor);
    return {
      title,
      estimatedPrice: `$${proposalCTotalLabor.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`,
    };
  });

  let discountsArray: { title: string; estimatedPrice: string }[] = [];
  project?.projectInfo?.discounts.map((item: any) => {
    let discountedValue = 0;
    let description = "";
    if (item.amountType === "percentage") {
      discountedValue = total * (item.amount / 100);
      total -= Math.round(discountedValue);
      description = item.description;
    } else if (item.amountType === "dollar") {
      discountedValue = item.amount;
      total -= Math.round(discountedValue);
      description = item.description;
    }
    discountsArray.push({
      title: description,
      estimatedPrice: `-$${discountedValue.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`,
    });
  });

  rows.push(...discountsArray);

  rows.push({
    title: "Total",
    estimatedPrice: `$${total.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}`,
  });

  let newRows = project?.options.reduce((acc: any[], option: any) => {
    if (option.pjcc[15].cost > 0) {
      let title = option.optionInfo.title;
      let paintLabor = option?.pjcc.find(
        (item: any) => item.name === "Paint Labor"
      );
      let carpentryLabor = option?.pjcc.find(
        (item: any) => item.name === "Carpentry Labor"
      );

      let paintLaborPrice =
        (paintLabor.cost / projectPaintLabor.cost) *
        projectPaintLabor.productionTargets;
      let carpentryLaborPrice =
        (carpentryLabor.cost / projectCarpentryLabor.cost) *
        projectCarpentryLabor.productionTargets;
      let totalPrice =
        (isNaN(paintLaborPrice) ? 0 : paintLaborPrice) +
        (isNaN(carpentryLaborPrice) ? 0 : carpentryLaborPrice);

      totalLastPrice += isNaN(totalPrice) ? 0 : totalPrice;
      acc.push({
        title,
        estimatedPrice: isNaN(totalPrice)
          ? 0
          : `$${totalPrice.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
      });
    }
    return acc;
  }, []);

  newRows.push({
    title: "Total",
    estimatedPrice: `$${totalLastPrice.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`,
  });

  let rowIndex = 0;
  const summaryDrawLineYPositions: number[] = [];
  autoTable(proposalDoc, {
    body: rows,
    showHead: false,
    theme: "plain",
    columns: columns,
    startY: tableEndYPos,
    margin: { horizontal: 30 },
    styles: {
      lineColor: "#585858",
      lineWidth: 0,
      minCellHeight: 5,
    },
    headStyles: {
      fillColor: "#FDB913",
      halign: "center",
      textColor: "black",
    },
    bodyStyles: { fillColor: "#FFFFFF", textColor: "black", fontSize: 8 },
    columnStyles: {
      1: {
        halign: "right",
      },
    },
    didParseCell: (data) => {
      if (data.row.index === rows.length - 1) {
        data.cell.styles.fontStyle = "bold";
      }
    },
    didDrawCell: (data) => {
      const { cell, row, column, table } = data;

      if (column.dataKey === "title" || column.dataKey === "estimatedPrice") {
        proposalDoc.setLineWidth(2);
        proposalDoc.setDrawColor("#585858");
        proposalDoc.line(
          cell.x,
          cell.y + cell.height,
          cell.x + cell.width,
          cell.y + cell.height
        );
      }

      tableEndYPos = cell.y + cell.height;
    },
    didDrawPage: (data) => {
      tableEndYPos = data.cursor?.y || tableEndYPos;
    },
  });
  tableEndYPos += 15;
  proposalDoc.setDrawColor("#000000");
  proposalDoc.setFillColor("#D3D3D3");
  proposalDoc.setLineWidth(1);
  summaryDrawLineYPositions.forEach((val, index) => {
    if (index === summaryDrawLineYPositions.length - 1) {
      proposalDoc.line(horizontalMargin, val, 565, val, "D");
    } else {
      proposalDoc.line(horizontalMargin, val, 565, val, "F");
    }
  });
  proposalDoc.setDrawColor(defaultDrawCol);
  proposalDoc.setFillColor(defaultFillCol);

  contractEndPosition = tableEndYPos + 15;

  if (contractEndPosition >= pageLimit) {
    proposalDoc.addPage();
    contractEndPosition = pageTopMargin;
  }
  proposalDoc.setFontSize(12);
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.text("PROJECT SUMMARY", horizontalMargin, contractEndPosition);
  contractEndPosition += 5;
  drawDivider(proposalDoc, contractEndPosition, 30, 565);
  contractEndPosition += 20;
  if (contractEndPosition >= pageLimit) {
    proposalDoc.addPage();
    contractEndPosition = pageTopMargin;
  }

  proposalDoc.setFontSize(8);
  proposalDoc.setFont("LatoRegular", "normal");

  let optionTitles = project.options
    .filter((item: any) => item.optionInfo.included === "included")
    .map((item: any) => item.optionInfo.title);

  if (contractEndPosition >= pageLimit) {
    proposalDoc.addPage();
    contractEndPosition = pageTopMargin;
  }
  contractEndPosition += 10;
  let generatedSummary = `Project Includes: ${optionTitles.join(", ")}. For specific details please refer to the Included Details section below.`;
  let splitGeneratedSummary = proposalDoc.splitTextToSize(
    generatedSummary,
    540
  );

  let summaryHeight = splitGeneratedSummary.length * 10;

  if (contractEndPosition + summaryHeight >= pageLimit) {
    proposalDoc.addPage();
    contractEndPosition = pageTopMargin;
  }

  proposalDoc.text(
    splitGeneratedSummary,
    horizontalMargin,
    contractEndPosition
  );

  contractEndPosition += summaryHeight + 10;

  if (contractEndPosition >= pageLimit) {
    proposalDoc.addPage();
    contractEndPosition = pageTopMargin;
  }

  contractEndPosition += 20;

  try {
    contractEndPosition = await generateProposalPdfWithHtml(
      proposalDoc,
      parsedHTML,
      contractEndPosition
    );

    if (contractEndPosition >= pageLimit) {
      proposalDoc.addPage();
      contractEndPosition = pageTopMargin;
    } else {
      contractEndPosition += 20;
    }
  } catch (error) {
    console.error("Error adding HTML: ", error);
  }
  if (contractEndPosition >= pageLimit) {
    proposalDoc.addPage();
    contractEndPosition = pageTopMargin;
  }

  // let projectSummary = project?.projectInfo?.proposal?.projectSummary;
  // let splitSummary = proposalDoc.splitTextToSize(projectSummary, 540);
  // proposalDoc.text(splitSummary, horizontalMargin, contractEndPosition);
  // contractEndPosition += 30;

  // if (contractEndPosition >= pageLimit) {
  //   proposalDoc.addPage();
  //   contractEndPosition = pageTopMargin;
  // }

  proposalDoc.setFontSize(12);
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.text("INCLUDED DETAILS", horizontalMargin, contractEndPosition);
  contractEndPosition += 5;
  drawDivider(proposalDoc, contractEndPosition, 30, 565);

  //   let optionDetails = project?.options.map((option: any) => {
  //     let tableData = option.rates.paintRates.filter(
  //       (item: any) => item.count > 0
  //     );
  //     if (tableData.length !== 0) {
  //       let data = tableData.map((entity: any) => {
  //         return {
  //           ...entity,
  //           paintMaterial: `${entity?.paintMaterial?.manufacturer}: ${entity?.paintMaterial?.product}`,
  //           primerMaterial: `${entity?.primerMaterial?.manufacturer}: ${entity?.primerMaterial?.product}`,
  //         };
  //       });
  //       return {
  //         optionName: option.optionInfo.title,
  //         data,
  //       };
  //     }
  //   });

  contractEndPosition += 25;

  //   let tableHeader = [
  //     { header: "Surface", dataKey: "item" },
  //     { header: "Prime", dataKey: "primeSelect" },
  //     { header: "Coats", dataKey: "coats" },
  //     { header: "Paint Material", dataKey: "paintMaterial" },
  //     { header: "Color", dataKey: "color" },
  //     { header: "Primer", dataKey: "primerMaterial" },
  //   ];

  const projectDetailsHeader = [
    { header: "Surface", dataKey: "item" },
    { header: "Product", dataKey: "material" },
    { header: "Color", dataKey: "color" },
    { header: "Coats", dataKey: "coats" },
    // { header: "Primer", dataKey: "primerMaterial" },
    // { header: "Primer Gallons", dataKey: "primerGallons" },
  ];

  for (const [index, option] of project?.paintWorkOrder.entries()) {
    if (option.paintLabor > 0) {
      proposalDoc.setFont("LatoRegular", "bold");
      proposalDoc.setFontSize(10);

      // Check if adding this text will overflow the page
      if (contractEndPosition + 15 > pageLimit) {
        proposalDoc.addPage();
        contractEndPosition = pageTopMargin;
      }

      proposalDoc.text(option.item, horizontalMargin, contractEndPosition);
      contractEndPosition += 15;

      // Additional checks after the text is added
      if (contractEndPosition + 15 > pageLimit) {
        proposalDoc.addPage();
        contractEndPosition = pageTopMargin;
      }

      proposalDoc.setFont("LatoRegular", "normal");
      proposalDoc.setFontSize(8);
      const optionGDIndex = project?.options.findIndex(
        (item: any) =>
          item.optionInfo.title === option.item &&
          item.optionInfo.included === "included"
      );

      const optionGD =
        project?.options[optionGDIndex]?.optionInfo.proposalGeneralDescription;

      if (optionGD) {
        const lines = optionGD.split("\n");
        lines.forEach((line: any) => {
          line = line.replaceAll("\t", " ");
          let currentX = horizontalMargin;
          let start = 0;
          while (start < line.length) {
            let linkStart = line.indexOf("[", start);
            if (linkStart === -1) {
              let splitText = proposalDoc.splitTextToSize(line, 540);
              splitText.forEach((part: any) => {
                // Check if adding this text will overflow the page
                if (
                  contractEndPosition + proposalDoc.getTextDimensions(part).h >
                  pageLimit
                ) {
                  proposalDoc.addPage();
                  contractEndPosition = pageTopMargin;
                }

                proposalDoc.text(part, horizontalMargin, contractEndPosition);
                contractEndPosition += 8;
              });
              start = line.length;
            } else {
              if (contractEndPosition >= pageLimit) {
                proposalDoc.addPage();
                contractEndPosition = pageTopMargin;
              }
              if (linkStart > start) {
                let txt = line.substring(start, linkStart);
                let splitText = proposalDoc.splitTextToSize(txt, 540);
                splitText.forEach((part: any) => {
                  // Check if adding this text will overflow the page
                  if (
                    contractEndPosition +
                    proposalDoc.getTextDimensions(part).h >
                    pageLimit
                  ) {
                    proposalDoc.addPage();
                    contractEndPosition = pageTopMargin;
                  }

                  proposalDoc.text(part, currentX, contractEndPosition);
                  contractEndPosition += 8;
                });
              }
              let linkEnd = line.indexOf("]", linkStart);
              if (linkEnd === -1) {
                start = linkStart + 1;
                if (contractEndPosition >= pageLimit) {
                  proposalDoc.addPage();
                  contractEndPosition = pageTopMargin;
                }
              } else {
                let linkText = line.substring(linkStart + 1, linkEnd);
                proposalDoc.setTextColor("#fdb913");
                if (
                  !linkText.startsWith("http://") &&
                  !linkText.startsWith("https://")
                ) {
                  linkText = "http://" + linkText;
                }
                proposalDoc.textWithLink(
                  linkText,
                  currentX,
                  contractEndPosition,
                  { linkText: linkText }
                );
                // currentX += proposalDoc.getStringUnitWidth(linkText);
                // contractEndPosition += linkText.length * 8 + 5;
                contractEndPosition += proposalDoc.getLineHeight() + 5;
                start = linkEnd + 1;
              }
            }
          }
        });
      }

      contractEndPosition += 10;
      proposalDoc.setTextColor("black");

      const newRows = option.paintRateId;
      const tableDataMapped = newRows
        .filter((row: any) => {
          return (
            row.primerGallons > 0 ||
            (row.paintGallons > 0 && row.showLaborRates)
          );
        })
        .map((row: any) => {
          let paintMaterial = row.paintMaterial;
          let primerMaterial = row.primerMaterial;
          let gallons = "";
          let material = "";
          let color = "      ";
          let coats = "";
          let lines = 0;
          if (
            !primerMaterial ||
            primerMaterial === "undefined:undefined" ||
            row.primerGallons === 0
          ) {
            // primerMaterial = "";
          } else {
            color += "N/A\n      ";
            material += `${primerMaterial}\n`;
            gallons += `${row.primerGallons.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}\n`;
            coats = "Full Prime\n";
            lines++;
          }

          if (
            !paintMaterial ||
            paintMaterial === "undefined:undefined" ||
            row.paintGallons === 0
          ) {
            // paintMaterial = "";
          } else {
            color += row.color;
            material += paintMaterial;
            gallons += row.paintGallons.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            coats += row.coats;
            lines++;
          }
          if (paintMaterial === "" && row.paintGallons >= 0) {
            color += row.color;
            material += paintMaterial;
            gallons += row.paintGallons.toFixed(2);
            coats += row.coats;
            lines++;
          }
          let colorObj = adminDefaults.colors.find(
            (item: any) => item.name === row.color
          );
          return {
            ...row,
            gallons,
            material,
            color,
            colorCode: colorObj?.hexCode,
            coats,
            lines,
          };
        });

      const detailsDrawLineYPositions: number[] = [];
      let tableRowIndex = -1;
      autoTable(proposalDoc, {
        body: tableDataMapped,
        columns: projectDetailsHeader,
        startY: contractEndPosition,
        margin: { horizontal: 30 },
        theme: "plain",
        styles: {
          lineColor: "#585858",
          lineWidth: 0,
          minCellHeight: 5,
        },
        headStyles: {
          fillColor: "#FDB913",
          halign: "left",
          textColor: "black",
          fontSize: 8,
        },
        bodyStyles: { fontSize: 8, textColor: "black" },
        columnStyles: {
          0: {
            cellWidth: 75,
          },
          1: {
            cellWidth: 230,
          },
          2: {
            cellWidth: 185,
          },
          3: {
            halign: "center",
            cellWidth: 45,
          },
        },
        // eslint-disable-next-line no-loop-func
        didDrawPage: (data) => {
          contractEndPosition = data.cursor?.y || contractEndPosition;
          // contractEndPosition = data.table.finalY || 0;
        },
        didDrawCell: (data) => {
          const rowEndYPos = data.cursor ? data.cursor.y : 0;
          if (tableRowIndex !== data.row.index) {
            tableRowIndex = data.row.index;
            detailsDrawLineYPositions.push(Math.round(rowEndYPos));
          }
          if (data.row.section !== "head") {
            if (data.row.index >= 0) {
              let xPos = data.cursor ? data.cursor.x : 0;
              let yPos = data.cursor ? data.cursor.y : 0;
              let startYColor = 5;
              if (tableDataMapped[data.row.index].lines >= 2) {
                startYColor = 14;
              }
              if (
                data.column.dataKey === "color" &&
                tableDataMapped[data.row.index]?.colorCode
              ) {
                let fillColor = proposalDoc.getFillColor();
                let drawColor = proposalDoc.getDrawColor();
                proposalDoc.setDrawColor(0, 0, 0);
                proposalDoc.setFillColor(
                  tableDataMapped[data.row.index].colorCode
                );
                proposalDoc.rect(xPos + 5, yPos + startYColor, 9, 9, "F");
                proposalDoc.setFillColor(fillColor);
                proposalDoc.setDrawColor(drawColor);
              }
            }
          }
        },
        didParseCell: (data) => {
          if (data.section === "head") {
            if (
              data.column.dataKey === "coats" ||
              data.column.dataKey === "gallons"
            ) {
              data.cell.styles.halign = "center";
            }
          }
        },
      });

      proposalDoc.setDrawColor("#000000");
      proposalDoc.setFillColor("#D3D3D3");
      proposalDoc.setLineWidth(1);
      detailsDrawLineYPositions.forEach((val, index) => {
        proposalDoc.line(horizontalMargin, val, 565, val, "F");
      });
      proposalDoc.line(
        horizontalMargin,
        contractEndPosition,
        565,
        contractEndPosition,
        "F"
      );
      proposalDoc.setDrawColor(defaultDrawCol);
      proposalDoc.setFillColor(defaultFillCol);
      contractEndPosition += 30;
      if (contractEndPosition >= pageLimit) {
        proposalDoc.addPage();
        contractEndPosition = pageTopMargin;
      }
    }
  }

  if (contractEndPosition >= pageLimit - 100) {
    proposalDoc.addPage();
    contractEndPosition = pageTopMargin;
  }

  const projectDefaultsSetup = project?.projectDefaults?.setup?.filter((item: any) => item.isDeleted == false && item.checked === true) || []
  let combinedSetup = [...project?.projectInfo?.proposal?.setup, ...projectDefaultsSetup]

  const bodyStyles = {
    fontSize: 8,
    font: "LatoRegular",
    textColor: "black"
  };

  let customerTasks = combinedSetup.filter(
    (item: any) => item.title === "customer tasks"
  );
  let certaproCoverAndProtectTasks =
    combinedSetup?.filter(
      (item: any) => item.category === "Cover and protect"
    );
  let certaproWillDoTasks = combinedSetup.filter(
    (item: any) => item.category === "will do"
  );

  proposalDoc.setFontSize(12);
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.text("SETUP", horizontalMargin, contractEndPosition);
  contractEndPosition += 5;
  drawDivider(proposalDoc, contractEndPosition, 30, 565);
  proposalDoc.setFontSize(8);
  contractEndPosition += 20;
  proposalDoc.setFont("LatoRegular", "bold");
  if (customerTasks.length > 0) {
    proposalDoc.text("Customer to:", horizontalMargin, contractEndPosition);
  }
  if (certaproCoverAndProtectTasks.length > 0) {
    proposalDoc.text(
      "CertaPro will cover and protect:",
      horizontalMargin + 180,
      contractEndPosition
    );
  }
  if (certaproWillDoTasks.length > 0) {
    proposalDoc.text(
      "CertaPro will:",
      horizontalMargin + 355,
      contractEndPosition
    );
  }
  let start = contractEndPosition + 20;

  contractEndPosition += 5;
  let customerTableEndYPos = contractEndPosition;
  let coverTableEndYPos = contractEndPosition;
  let willDoTableEndYPos = contractEndPosition;

  let maxTableHeight = Math.max(
    customerTableEndYPos,
    coverTableEndYPos,
    willDoTableEndYPos
  );

  // proposalDoc.addPage();

  contractEndPosition = maxTableHeight + 20;
  // contractEndPosition = 20;

  function checkAndAddPage(proposalDoc: any, currentY: any, pageLimit: any) {
    if (currentY >= pageLimit) {
      proposalDoc.addPage();
      return 60;
    }
    return currentY;
  }

  let maxRows = Math.max(
    customerTasks?.length || 0,
    certaproCoverAndProtectTasks?.length || 0,
    certaproWillDoTasks?.length || 0
  );

  for (let i = 0; i < maxRows; i++) {
    start = checkAndAddPage(proposalDoc, start, 790);

    proposalDoc.setFontSize(bodyStyles.fontSize);
    proposalDoc.setFont(bodyStyles.font);
    proposalDoc.setTextColor(bodyStyles.textColor);

    if (customerTasks[i]) {
      proposalDoc.setFont("helvetica", "normal");
      let customerTaskLines = proposalDoc.splitTextToSize(
        '\u2022 ' + customerTasks[i].task,
        200
      );
      customerTaskLines.forEach((line: string, index: number) => {
        proposalDoc.text(line, horizontalMargin, start + (index * 8));
      });
      start += (customerTaskLines.length - 1) * 8;
    }


    if (certaproCoverAndProtectTasks[i]) {
      proposalDoc.setFont("helvetica", "normal");
      let coverTaskLines = proposalDoc.splitTextToSize(
        '\u2022 ' + certaproCoverAndProtectTasks[i].task,
        200
      );
      coverTaskLines.forEach((line: string, index: number) => {
        proposalDoc.text(line, horizontalMargin + 180, start + (index * 8));
      });
      start += (coverTaskLines.length - 1) * 8;
    }

    if (certaproWillDoTasks[i]) {
      proposalDoc.setFont("helvetica", "normal");
      let willDoTaskLines = proposalDoc.splitTextToSize(
        '\u2022 ' + certaproWillDoTasks[i].task,
        200
      );
      willDoTaskLines.forEach((line: string, index: number) => {
        proposalDoc.text(line, horizontalMargin + 355, start + (index * 8));
      });
      start += (willDoTaskLines.length - 1) * 8;
    }

    start += 10;
  }
  if (contractEndPosition >= 790) {
    proposalDoc.addPage();
    contractEndPosition = 60;
  }
  contractEndPosition = start + 20;
  if (contractEndPosition >= 790) {
    proposalDoc.addPage();
    contractEndPosition = 60;
  }


  proposalDoc.setFontSize(12);
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.text(
    "SURFACE PREPARATIONS",
    horizontalMargin,
    contractEndPosition
  );

  contractEndPosition += 5;
  drawDivider(proposalDoc, contractEndPosition, 30, 565);

  contractEndPosition += 20;
  proposalDoc.setFontSize(8);

  let startX = horizontalMargin;
  let startY = contractEndPosition;

  const handlePageBreak = () => {
    if (startY + 10 > proposalDoc.internal.pageSize.height - 20) {
      proposalDoc.addPage();
      startX = horizontalMargin;
      startY = 20;
      contractEndPosition = startY;
    }
  };

  const projectDefaultsSurface = project?.projectDefaults?.surface?.filter((item: any) => item.isDeleted == false && item.checked === true) || []
  const combinedSurfaces = [...project?.projectInfo?.proposal?.surface, ...projectDefaultsSurface]


  combinedSurfaces?.map((item: any) => {
    let surfaceDetail = `**${item.title}:** ${item.detail}`;
    let splitString = proposalDoc.splitTextToSize(surfaceDetail, 540);
    const startXCached = startX;
    splitString.map((text: any, i: any) => {
      if (text) {
        const arrayOfNormalAndBoldText = text.split("**");
        arrayOfNormalAndBoldText.map((textItems: any, j: any) => {
          if (j === 1) {
            proposalDoc.setFont("LatoRegular", "bold");
          } else {
            proposalDoc.setFont("LatoRegular", "normal");
          }
          handlePageBreak();
          proposalDoc.text(textItems, startX, startY);
          startX = startX + proposalDoc.getStringUnitWidth(textItems) * 8;
        });
        startX = startXCached;
        startY += 10;
        handlePageBreak();
      }
    });
    startY += 10;
  });

  contractEndPosition = startY + 20;

  if (contractEndPosition >= pageLimit - 100) {
    proposalDoc.addPage();
    contractEndPosition = pageTopMargin;
  }

  proposalDoc.setFontSize(14);
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.text("CLEAN UP", horizontalMargin, contractEndPosition);
  contractEndPosition += 5;
  drawDivider(proposalDoc, contractEndPosition, 30, 565);

  contractEndPosition += 20;
  proposalDoc.setFontSize(8);

  let startXCleanUp = horizontalMargin;

  let cleanUp =
    "**UPON COMPLETION:** Includes thorough clean up of paint, tape, plastic, dust and debris in areas where work was completed. All tools, supplies & equipment will be removed from the property.";

  let splitString = proposalDoc.splitTextToSize(cleanUp, 560);
  const startXCached = startXCleanUp;
  splitString.map((text: any, i: any) => {
    if (text) {
      const arrayOfNormalAndBoldText = text.split("**");
      arrayOfNormalAndBoldText.map((textItems: any, j: any) => {
        if (j === 1) {
          proposalDoc.setFont("LatoRegular", "bold");
        } else {
          proposalDoc.setFont("LatoRegular", "normal");
        }
        proposalDoc.text(textItems, startXCleanUp, contractEndPosition);
        startXCleanUp =
          startXCleanUp + proposalDoc.getStringUnitWidth(textItems) * 8;
      });
      startXCleanUp = startXCached;
      contractEndPosition += 10;
    }
  });

  contractEndPosition += 20;

  if (contractEndPosition >= pageLimit) {
    proposalDoc.addPage();
    contractEndPosition = pageTopMargin;
  }
  // contractEndPosition += 20;

  if (contractEndPosition >= pageLimit - 150) {
    proposalDoc.addPage();
    contractEndPosition = pageTopMargin;
  }

  proposalDoc.text(
    "For And On Behalf Of",
    horizontalMargin,
    contractEndPosition
  );

  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.setTextColor("#FDB913");

  proposalDoc.text(
    "CERTAPRO PAINTERS",
    horizontalMargin,
    contractEndPosition + 10
  );

  proposalDoc.setTextColor("black");

  proposalDoc.setFont("LatoRegular", "normal");

  proposalDoc.setLineWidth(1);
  proposalDoc.setDrawColor(defaultDrawCol);

  proposalDoc.line(
    horizontalMargin + 360,
    contractEndPosition,
    550,
    contractEndPosition
  );

  contractEndPosition += 10;

  proposalDoc.setFontSize(8);
  proposalDoc.text(
    "Authorized Client Signature(S)",
    horizontalMargin + 360,
    contractEndPosition
  );
  contractEndPosition += 30;

  proposalDoc.line(
    horizontalMargin,
    contractEndPosition,
    150,
    contractEndPosition
  );
  proposalDoc.line(
    horizontalMargin + 360,
    contractEndPosition,
    550,
    contractEndPosition
  );

  contractEndPosition += 10;

  proposalDoc.setFontSize(8);

  proposalDoc.text(
    "Authorized Signature(S)",
    horizontalMargin,
    contractEndPosition
  );
  proposalDoc.text(
    "Authorized Client Representative Name & Title",
    horizontalMargin + 360,
    contractEndPosition
  );

  contractEndPosition += 30;

  proposalDoc.line(
    horizontalMargin + 360,
    contractEndPosition,
    550,
    contractEndPosition
  );

  contractEndPosition += 10;
  proposalDoc.setFontSize(8);

  proposalDoc.text("Client", horizontalMargin + 360, contractEndPosition);

  if (
    project?.projectInfo?.proposal?.addOptionMedia &&
    optionsMediaList.length > 0
  ) {
    proposalDoc.addPage();
    contractEndPosition = pageTopMargin;
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.setFontSize(12);
    proposalDoc.text(
      "ADDENDUM - ALL PICTURES",
      horizontalMargin,
      contractEndPosition
    );
    contractEndPosition += 5;
    drawDivider(proposalDoc, contractEndPosition, horizontalMargin, 565);

    for (const item of optionsMediaList) {
      let imageMarginX = horizontalMargin;
      let imageWidth = 0;
      let imageHeight = 0;
      let imagesAddedPerRow = 0;
      let maxRowHeight = 0;
      let maxlinesAddedByCaptionInsertion = 0;

      contractEndPosition += 30;

      if (contractEndPosition + 300 > pageLimit) {
        proposalDoc.addPage();
        contractEndPosition = pageTopMargin;
      }

      proposalDoc.setFont("LatoRegular", "bold");
      proposalDoc.setFontSize(12);
      proposalDoc.text(item.title, horizontalMargin, contractEndPosition);
      contractEndPosition += 5;

      await Promise.all(
        item.data.map(async (file: any) => {
          let optionImage = new Image();
          optionImage.src = file?.thumbUrl;
          await new Promise((resolve, reject) => {
            optionImage.onload = resolve;
            optionImage.onerror = reject;
          });
          let orgImgHeight = optionImage.height;
          let orgWidth = optionImage.width;
          if (project.projectInfo.proposal.optionImagesPerRow === 2) {
            imageWidth = 250;

            imageHeight = (imageWidth / optionImage.width) * orgImgHeight;
            if (imagesAddedPerRow === 2) {
              if (contractEndPosition + maxRowHeight > pageLimit) {
                proposalDoc.addPage();
                contractEndPosition = pageTopMargin;
              } else {
                contractEndPosition +=
                  maxRowHeight + maxlinesAddedByCaptionInsertion * 10 + 15;
              }
              imageMarginX = horizontalMargin;
              maxRowHeight = 0;
              imagesAddedPerRow = 0;
            }
            if (contractEndPosition + imageHeight > pageLimit) {
              proposalDoc.addPage();
              contractEndPosition = pageTopMargin;
            }
            let imageWidthWithOrientation = imageWidth;
            let imageHeightWithOrientation = imageHeight;
            let rotation = 0;
            let imageYPos = contractEndPosition + 5;
            if (file.orientation === 6) {
              rotation = 270;
              imageWidthWithOrientation = imageHeight;
              imageHeightWithOrientation = imageWidth;
              imageYPos -= imageHeightWithOrientation;
            }
            if (file.name.match(/\.(png|jpg|jpeg)$/i)) {
              let imageType = file.name.split(".").pop();
              proposalDoc.addImage(
                optionImage,
                imageType,
                imageMarginX,
                imageYPos,
                imageWidthWithOrientation,
                imageHeightWithOrientation,
                undefined,
                undefined,
                rotation
              );
              if (
                maxRowHeight <
                (imageWidth / optionImage.width) * orgImgHeight
              ) {
                maxRowHeight = (imageWidth / optionImage.width) * orgImgHeight;
              }
            }
            contractEndPosition += imageHeight + 12;

            let captionTextArray = proposalDoc.splitTextToSize(
              file.caption ? file.caption : "",
              imageWidth
            );
            let newMarginYByCaption = 0;
            if (maxlinesAddedByCaptionInsertion < captionTextArray.length) {
              maxlinesAddedByCaptionInsertion = captionTextArray.length;
            }
            captionTextArray.map((captionText: string) => {
              let textWidth = proposalDoc.getStringUnitWidth(captionText) * 10;
              let textX = imageMarginX + (imageWidth - textWidth) / 2;
              proposalDoc.setFont("LatoRegular", "normal");
              proposalDoc.setFontSize(10);
              proposalDoc.text(captionText, textX, contractEndPosition + 5);
              contractEndPosition += 12;
              newMarginYByCaption += 12;
            });
            contractEndPosition -= imageHeight + newMarginYByCaption + 12;
            imageMarginX += imageWidth + 10;
          } else if (project.projectInfo.proposal.optionImagesPerRow === 3) {
            imageWidth = 175;
            imageHeight = (imageWidth / optionImage.width) * orgImgHeight;
            if (imagesAddedPerRow === 3) {
              if (contractEndPosition + maxRowHeight > pageLimit) {
                proposalDoc.addPage();
                contractEndPosition = pageTopMargin;
              } else {
                contractEndPosition +=
                  maxRowHeight + maxlinesAddedByCaptionInsertion * 10 + 15;
              }
              imageMarginX = horizontalMargin;
              maxRowHeight = 0;
              imagesAddedPerRow = 0;
            }
            if (contractEndPosition + imageHeight > pageLimit) {
              proposalDoc.addPage();
              contractEndPosition = pageTopMargin;
            }
            let imageWidthWithOrientation = imageWidth;
            let imageHeightWithOrientation = imageHeight;
            let rotation = 0;
            let imageYPos = contractEndPosition + 5;
            if (file.orientation === 6) {
              rotation = 270;
              imageWidthWithOrientation = imageHeight;
              imageHeightWithOrientation = imageWidth;
              imageYPos -= imageHeightWithOrientation;
            }
            if (file.name.match(/\.(png|jpg|jpeg)$/i)) {
              let imageType = file.name.split(".").pop();
              proposalDoc.addImage(
                optionImage,
                imageType,
                imageMarginX,
                imageYPos,
                imageWidthWithOrientation,
                imageHeightWithOrientation,
                undefined,
                undefined,
                rotation
              );
              if (
                maxRowHeight <
                (imageWidth / optionImage.width) * orgImgHeight
              ) {
                maxRowHeight = (imageWidth / optionImage.width) * orgImgHeight;
              }
            }
            contractEndPosition += imageHeight + 12;
            let captionTextArray = proposalDoc.splitTextToSize(
              file.caption ? file.caption : "",
              imageWidth
            );
            let newMarginYByCaption = 0;
            if (maxlinesAddedByCaptionInsertion < captionTextArray.length) {
              maxlinesAddedByCaptionInsertion = captionTextArray.length;
            }
            captionTextArray.map((captionText: string) => {
              let textWidth = proposalDoc.getStringUnitWidth(captionText) * 10;
              let textX = imageMarginX + (imageWidth - textWidth) / 2;
              proposalDoc.setFont("LatoRegular", "normal");
              proposalDoc.setFontSize(10);
              proposalDoc.text(captionText, textX, contractEndPosition + 5);
              contractEndPosition += 12;
              newMarginYByCaption += 12;
            });
            contractEndPosition -= imageHeight + newMarginYByCaption + 12;
            imageMarginX += imageWidth + 10;
          } else {
            if (project.projectInfo.proposal.optionImagesPerRow === 4) {
              imageWidth = 125;
              imageHeight = (imageWidth / optionImage.width) * orgImgHeight;
              if (imagesAddedPerRow === 4) {
                if (contractEndPosition + maxRowHeight > pageLimit) {
                  proposalDoc.addPage();
                  contractEndPosition = pageTopMargin;
                } else {
                  contractEndPosition +=
                    maxRowHeight + maxlinesAddedByCaptionInsertion * 10 + 15;
                }
                imageMarginX = horizontalMargin;
                maxRowHeight = 0;
                imagesAddedPerRow = 0;
              }
              if (contractEndPosition + imageHeight > pageLimit) {
                proposalDoc.addPage();
                contractEndPosition = pageTopMargin;
              }
              let imageWidthWithOrientation = imageWidth;
              let imageHeightWithOrientation = imageHeight;
              let rotation = 0;
              let imageYPos = contractEndPosition + 5;
              if (file.orientation === 6) {
                rotation = 270;
                imageWidthWithOrientation = imageHeight;
                imageHeightWithOrientation = imageWidth;
                imageYPos -= imageHeightWithOrientation;
              }
              if (file.name.match(/\.(png|jpg|jpeg)$/i)) {
                let imageType = file.name.split(".").pop();
                proposalDoc.addImage(
                  optionImage,
                  imageType,
                  imageMarginX,
                  imageYPos,
                  imageWidthWithOrientation,
                  imageHeightWithOrientation,
                  undefined,
                  undefined,
                  rotation
                );
                if (
                  maxRowHeight <
                  (imageWidth / optionImage.width) * orgImgHeight
                ) {
                  maxRowHeight =
                    (imageWidth / optionImage.width) * orgImgHeight;
                }
              }
              contractEndPosition += imageHeight + 12;
              let captionTextArray = proposalDoc.splitTextToSize(
                file.caption ? file.caption : "",
                imageWidth
              );
              let newMarginYByCaption = 0;
              if (maxlinesAddedByCaptionInsertion < captionTextArray.length) {
                maxlinesAddedByCaptionInsertion = captionTextArray.length;
              }
              captionTextArray.map((captionText: string) => {
                let textWidth =
                  proposalDoc.getStringUnitWidth(captionText) * 10;
                let textX = imageMarginX + (imageWidth - textWidth) / 2;
                proposalDoc.setFont("LatoRegular", "normal");
                proposalDoc.setFontSize(10);
                proposalDoc.text(captionText, textX, contractEndPosition + 5);
                contractEndPosition += 12;
                newMarginYByCaption += 12;
              });
              contractEndPosition -= imageHeight + newMarginYByCaption + 12;
              imageMarginX += imageWidth + 10;
            }
          }
          imagesAddedPerRow++;
        })
      );

      if (project.projectInfo.proposal.optionImagesPerRow === 3) {
        if (
          item.data.length > 0 &&
          contractEndPosition + maxRowHeight > pageLimit
        ) {
          proposalDoc.addPage();
          contractEndPosition = pageTopMargin;
        } else contractEndPosition += maxRowHeight;
      } else if (project.projectInfo.proposal.optionImagesPerRow === 4) {
        if (
          item.data.length > 0 &&
          contractEndPosition + maxRowHeight > pageLimit
        ) {
          proposalDoc.addPage();
          contractEndPosition = pageTopMargin;
        } else contractEndPosition += maxRowHeight;
      } else {
        if (
          project.projectInfo.proposal.optionImagesPerRow === 2 &&
          item.data.length > 0 &&
          contractEndPosition + maxRowHeight > pageLimit
        ) {
          proposalDoc.addPage();
          contractEndPosition = pageTopMargin;
        } else {
          contractEndPosition +=
            maxRowHeight + maxlinesAddedByCaptionInsertion * 10 + 25;
        }
      }
    }
  }

  contractEndPosition += 10;

  if (type === "save") {
    proposalDoc.save("Proposal-C");
    let blob = proposalDoc.output();
    return blob;
  }
  if (type === "email") {
    let blob = proposalDoc.output();
    return blob;
  }
};
