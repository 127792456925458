import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContractorDataC from "../../containers/contractor-data/ContractorDataC";
import { fetchContractorData } from "../../redux/contractor-data/action";

const ContractorData = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchContractorData());
  }, []);
  return <ContractorDataC />;
};

export default ContractorData;
