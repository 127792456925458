import {
  Row,
  Col,
  Table,
  Spin,
  Dropdown,
  Button,
  message,
  Menu,
  Input,
} from "antd";
import { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import api from "../../utils/api";
import CreateStoreLocation from "../create-store/CreateStoreLocation";
import "../pay-processing-defaults/PayProcessing.less";
import { useDispatch } from "react-redux";
import { MoreActionIcon } from "../../utils/icons";
import { CSVLink } from "react-csv";
import DefaultBulkEntry from "../../components/pop-ups/default-modal/MaterialDefaultBulkEntry";

const StoreLocationsC = () => {
  const dispatch = useDispatch<any>();
  const [stores, setStores] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState<any>({});
  const [showAddStoreModal, setShowAddStoreModal] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  const [storeSearched, setStoreSearched] = useState("");

  useEffect(() => {
    setLoading(true);
    api
      .get("store-locations")
      .then((response: any) => {
        setLoading(false);
        setStores(response);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const handleAddStore = () => {
    setEdit({});
    setShowAddStoreModal(true);
  };

  const toggleShowAddStoreModal = (collapse: boolean) => {
    !showAddStoreModal
      ? setShowAddStoreModal(true)
      : setShowAddStoreModal(false);
  };

  const onFinish = (values: any) => {
    if (Object.keys(edit).length > 0) {
      api.put(`store-locations/${edit._id}`, values).then((resp: any) => {
        let newData = [...stores];
        let rowIndex = newData.findIndex((item: any) => item._id === edit._id);
        newData[rowIndex] = resp;
        setStores(newData);
      });
    } else {
      api
        .post("store-locations", values)
        .then((resp: any) => {
          let newData = [...stores];
          newData.push(resp);
          setStores(newData);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    setShowAddStoreModal(false);
  };

  const handleDelete = (id: string) => {
    api
      .delete(`store-locations/${id}`)
      .then((resp: any) => {
        let newData = [...stores];
        let updatedBody = newData.filter((item: any) => item._id !== resp._id);
        setStores(updatedBody);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const found = stores?.filter((item: any) => {
    if (!storeSearched) {
      return true;
    }
    return item.name.toLowerCase().includes(storeSearched.toLowerCase());
  });

  const handleEdit = (row: any) => {
    setEdit(row);
    setShowAddStoreModal(true);
  };

  const handleBulkBtn = () => {
    !showBulkModal ? setShowBulkModal(true) : setShowBulkModal(false);
  };

  const toggleBulkModal = () => {
    !showBulkModal ? setShowBulkModal(true) : setShowBulkModal(false);
  };

  const handleAddCsv = (info: any) => {
    setButtonDisable(true);
    if (info.file.status === "done") {
      setButtonDisable(false);
      message.success("Successfully Uploaded");
      const resp = info.file.response.data;
      setStores(resp);
      toggleBulkModal();
    } else if (info.file.status === "error") {
      setButtonDisable(false);
      message.error("Uploading Failed!");
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Store Phone",
      dataIndex: "phone",
    },
    {
      title: "Store",
      dataIndex: "store",
    },
    {
      title: "Number",
      dataIndex: "number",
    },
    {
      title: "Action",
      render: (data: any) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  className="project-menu-items"
                  key={1}
                  onClick={() => {
                    handleDelete(data._id);
                  }}
                >
                  <span className="nav-text">
                    <h1>Delete</h1>
                  </span>
                </Menu.Item>
                <Menu.Item
                  className="project-menu-items"
                  key={2}
                  onClick={() => {
                    handleEdit(data);
                  }}
                >
                  <span className="nav-text">
                    <h1>Edit</h1>
                  </span>
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <Button className="action-button">
              <MoreActionIcon />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  // Added email column to csv data to be downloaded
  const paintStoreHeader = [
    {
      label: "name",
      key: "name",
    },
    {
      label: "latitude",
      key: "latitude",
    },
    {
      label: "longitude",
      key: "longitude",
    },
    {
      label: "address",
      key: "address",
    },
    {
      label: "phone",
      key: "phone",
    },
    {
      label: "store",
      key: "store",
    },
    {
      label: "number",
      key: "number",
    },
    {
      label: "email",
      key: "email",
    },
    {
      label: "_id",
      key: "_id",
    },
  ];

  return (
    <div className="pay-processing-root">
      <DefaultBulkEntry
        buttonDisable={buttonDisable}
        showBulkModal={showBulkModal}
        toggleBulkModal={toggleBulkModal}
        url="store-locations/upload"
        handleAddCsv={handleAddCsv}
      />
      <CreateStoreLocation
        row={edit}
        showAddStoreModal={showAddStoreModal}
        toggleShowAddStoreModal={toggleShowAddStoreModal}
        handleAddStore={onFinish}
      />
      <Row className="admin-ec-header" justify="space-between">
        <Col>
          <h1>Paint Store Location</h1>
        </Col>
        <Row gutter={12}>
          <Col>
            <Input
              className="search-manufacture-input"
              prefix={
                <SearchOutlined className="contractor-data-search-icon" />
              }
              placeholder="Search Paint Store"
              onChange={(e) => {
                setStoreSearched(e.target.value);
              }}
            />
          </Col>
          <div className="contractor-data-vertical-line " />
          <Col>
            <Button
              className="pp-add-associates"
              type="primary"
              onClick={handleAddStore}
            >
              Add Store Location
            </Button>
          </Col>
          <Col>
            <Button
              className="admin-ec-add-btn"
              type="primary"
              onClick={handleBulkBtn}
            >
              Bulk Entry
            </Button>
          </Col>
        </Row>
      </Row>

      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <hr style={{ border: "1px solid #E8E8E8", marginBottom: 30 }} />
          <Button
            className="default-bulk-btn"
            style={{ margin: "0px 20px 10px" }}
            type="primary"
          >
            <CSVLink
              data={stores}
              filename={"paint-stores"}
              headers={paintStoreHeader}
              enclosingCharacter={'"'}
              separator={","}
            >
              Download CSV
            </CSVLink>
          </Button>
          <div className="pp-default-table">
            {stores?.length > 0 && (
              <Table
                scroll={{ x: "900px" }}
                columns={columns}
                dataSource={found}
                rowKey="_id"
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default StoreLocationsC;
