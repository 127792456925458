import React from 'react';
import { Table } from 'antd';
import './PaymentRequests.less';
import { columns } from './config';

type Props = {};

const PaymentRequests = (props: Props) => {
  const data = new Array(7).fill({
    date: 'March 07, 2021',
    projectName: 'Spirit Lake Exterior  ',
    amount: '$1,500.00',
  });
  return (
    <div className="payment-requests-main-container">
      <div className="payment-requests-sub-container">
        <h1>Payment Requests</h1>
      </div>
      <hr style={{ border: '1px solid #E8E8E8', marginBottom: 40 }} />
      <Table columns={columns} dataSource={data} pagination={false} />
    </div>
  );
};

export default PaymentRequests;
