import React from 'react';
import DescriptionCard from '../../components/work-order-description/DescriptionCard';
import { Table, Button, Row, Col } from 'antd';
import { columns } from './columns';
import './PaymentTabC.less';

type Props = {};
const filled = new Array(6).fill({
  key: '1',
  draw_number: 1,
  date: 'March 07, 2021',

  retainer: '$0.00',
  deposit_amount: '$0.00',
  balance_due: '$0.00',
});
const dummyData = [
  {
    title: 'Project',
    description: 'Building 1',
  },
  {
    title: 'Contracted Price',
    description: '10 hrs',
  },
  {
    title: 'Crew',
    description: 'Bid Doc - 2.5.2010.10a',
  },
];

const PaymentTabC = (props: Props) => {
  return (
    <div className="payment-tab-root">
      <h1 className="payment-tab-title">Payment</h1>
      <hr style={{ border: '1px solid #E8E8E8' }} />
      <DescriptionCard dummy={dummyData} />
      <div className="payment-tab-table">
        <Table
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'table_row table-row-light'
              : 'table_row table-row-dark'
          }
          columns={columns}
          dataSource={filled}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default PaymentTabC;
