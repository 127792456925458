import { Steps } from "antd";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const { Step } = Steps;
type Props = {};

const RoleNavigationSteps: React.FC<{ data: any }> = ({ data }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:1030px)" });
  const isMobile = useMediaQuery({ query: "(max-width:400px)" });

  const history = useHistory();

  return (
    <>
      {isMobile ? (
        <div className="main--steps--holder">
          <Steps current={data.current}>
            <Step
              title="Sales"
              className="step"
              onClick={() => {
                if (data.projectId) {
                  history.push(`/manager-preview-profile/${data.projectId}`);
                }
              }}
            />
            <Step
              style={{ marginLeft: 0 }}
              className="step"
              title="Production"
              onClick={() => {
                if (data.projectId) {
                  history.push(`/production-pjcc/${data.projectId}`);
                }
              }}
            />
            <Step
              style={{ marginLeft: 0 }}
              className="step"
              title="Job Jacket"
              onClick={() => {
                if (data.projectId) {
                  history.push(`/proposal-c/${data.projectId}`);
                }
              }}
            />
          </Steps>
        </div>
      ) : (
        <>
          {isTabletOrMobile ? (
            <div className="main--steps--holder">
              <Steps current={data.current}>
                <Step
                  title="Sales"
                  className="step"
                  onClick={() => {
                    if (data.projectId) {
                      history.push(
                        `/manager-preview-profile/${data.projectId}`
                      );
                    }
                  }}
                />
                <Step
                  style={{ marginLeft: 20 }}
                  className="step"
                  title="Production"
                  onClick={() => {
                    if (data.projectId) {
                      history.push(`/production-pjcc/${data.projectId}`);
                    }
                  }}
                />
                <Step
                  style={{ marginLeft: 20 }}
                  className="step"
                  title="Job Jacket"
                  onClick={() => {
                    if (data.projectId) {
                      history.push(`/proposal-c/${data.projectId}`);
                    }
                  }}
                />
              </Steps>
            </div>
          ) : (
            <div className="main--steps--holder">
              <Steps current={data.current}>
                <Step
                  title="Sales"
                  className="step"
                  onClick={() => {
                    if (data.projectId) {
                      history.push(
                        `/manager-preview-profile/${data.projectId}`
                      );
                    }
                  }}
                />
                <Step
                  style={{ marginLeft: 0 }}
                  className="step"
                  title="Production"
                  onClick={() => {
                    if (data.projectId) {
                      history.push(`/production-pjcc/${data.projectId}`);
                    }
                  }}
                />
                <Step
                  style={{ marginLeft: 10 }}
                  className="step"
                  title="Job Jacket"
                  onClick={() => {
                    if (data.projectId) {
                      history.push(`/proposal-c/${data.projectId}`);
                    }
                  }}
                />
              </Steps>
            </div>
          )}{" "}
        </>
      )}
    </>
  );
};

export default RoleNavigationSteps;
