import React, { useEffect } from "react";
import { Layout, Row, Col } from "antd";
import PaintOrderCom from "../../containers/work-order/paint-order-com/PaintOrderCom";
import JobJacketSteps from "./JobJacketSteps";
import useSyncProjectData from "../../utils/useSyncProjectData";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
const { Content } = Layout;
const PaintOrderCommercial: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);
  const { id } = useParams<any>();
  const [isLoading] = useSyncProjectData(id);
  return (
    <>
      {isLoading === true ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <Content>
          <JobJacketSteps current={3} />
          <div className="steps-content">
            <Row gutter={24}>
              <Col className="gutter-row" span={24}>
                <PaintOrderCom />
              </Col>
            </Row>
          </div>
        </Content>
      )}
    </>
  );
};

export default PaintOrderCommercial;
