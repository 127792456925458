import { createTransform, Transform } from "redux-persist";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

const secretKey: string = "secret";

export const encryptTransform: Transform<any, any> = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState: any): string => {
    return AES.encrypt(JSON.stringify(inboundState), secretKey).toString();
  },
  // transform state being rehydrated
  (outboundState: any): any => {
    try {
      const bytes = AES.decrypt(outboundState, secretKey);
      return JSON.parse(bytes.toString(Utf8));
    } catch (err: any){
      localStorage.clear();
      sessionStorage.clear();
    }
  }
);
