import { EditOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Row, Col, Button, Tag, Input, Spin } from "antd";
import "./CommercialSetup.less";
import SetupInteriorModal from "../pop-ups/SetupInteriorModal";
import { BulletIcon } from "../../../../utils/icons";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  deleteSetupDefaults,
  postSetupDefaults,
  updateSetup,
} from "../../../../redux/proposal-defaults-com-res/action";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

type Props = {
  type: string;
  tasks: any;
  jobType: string;
};

const SetupDefaults = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:1025px)" });

  const [data, setData] = useState<any>([]);
  const dispatch = useDispatch();
  const [showSetupInteriorModal, setShowSetupInteriorModal] = useState(false);

  const { loading } = useSelector(
    (state: RootStateOrAny) => state.proposalDefaultsComRes
  );
  const [edit, setEdit] = useState({
    customer: -1,
    cover: -1,
    will: -1,
  });

  useEffect(() => {
    setData(props.tasks);
  }, [props.tasks]);
  const handleAdd = () => {
    !showSetupInteriorModal
      ? setShowSetupInteriorModal(true)
      : setShowSetupInteriorModal(false);
  };

  const handleSetupAdd = (data: any) => {
    const body = {
      ...data,
      type: props.type,
      jobType: props.jobType,
    };
    dispatch(postSetupDefaults(body));

    toggleSetupInteriorModal(false);
  };

  const toggleSetupInteriorModal = (collapse: boolean) => {
    !showSetupInteriorModal
      ? setShowSetupInteriorModal(true)
      : setShowSetupInteriorModal(false);
  };

  const handleEdit = (index: number, row: any, type: string) => {
    switch (type) {
      case "customer": {
        if (edit.customer !== -1) {
          dispatch(updateSetup(row));
          setEdit({ ...edit, customer: -1 });
        } else {
          setEdit({ ...edit, customer: index });
        }

        break;
      }
      case "cover": {
        if (edit.cover !== -1) {
          dispatch(updateSetup(row));
          setEdit({ ...edit, cover: -1 });
        } else {
          setEdit({ ...edit, cover: index });
        }
        break;
      }
      case "will": {
        if (edit.will !== -1) {
          dispatch(updateSetup(row));
          setEdit({ ...edit, will: -1 });
        } else {
          setEdit({ ...edit, will: index });
        }
        break;
      }
    }
  };

  const handleTaskChange = (e: any, id: string) => {
    const index = data?.findIndex((item: any) => item._id === id);
    const newData = [...data];
    newData[index].task = e.target.value;
    setData(newData);
  };

  const handleDelete = (id: string) => {
    dispatch(deleteSetupDefaults(id));
  };

  return (
    <div className="setup-interior-commercial">
      <SetupInteriorModal
        title={props.jobType}
        toggleSetupInteriorModal={toggleSetupInteriorModal}
        showSetupInteriorModal={showSetupInteriorModal}
        handleSetupAdd={handleSetupAdd}
      />
      <Row justify="space-between">
        <Col>
          <h1>Setup ({props.jobType})</h1>
        </Col>
        <Col>
          <Button
            onClick={handleAdd}
            className="setup-comm-add-btn"
            type="link"
          >
            + Add
          </Button>
        </Col>
      </Row>

      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <div style={{ marginTop: 30 }}>
          {isTabletOrMobile ? (
            <Row gutter={10}>
              <Col lg={24} sm={24} style={{ marginBottom: "20px" }}>
                <h2 style={{ marginBottom: "20px" }}>Customer Tasks</h2>
                {data
                  ?.filter((item: any) => item.title === "customer tasks")
                  .map((row: any, index: any) => {
                    return (
                      <Row gutter={10} key={row._id}>
                        <Col span={18}>
                          <Row>
                            <Col span={2}>
                              <BulletIcon />
                            </Col>
                            {edit.customer === index ? (
                              <Col span={22}>
                                <Input
                                  onChange={(e: any) =>
                                    handleTaskChange(e, row._id)
                                  }
                                  value={row.task}
                                />
                              </Col>
                            ) : (
                              <Col span={22}>
                                <p>{row.task}</p>
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Col span={2}>
                          {edit.customer === index ? (
                            <SaveOutlined
                              onClick={() => handleEdit(index, row, "customer")}
                              style={{ color: "#FDB913", cursor: "pointer" }}
                            />
                          ) : (
                            <EditOutlined
                              onClick={() => handleEdit(index, row, "customer")}
                              style={{ color: "#FDB913", cursor: "pointer" }}
                            />
                          )}
                        </Col>
                        <Col span={2}>
                          <DeleteOutlined
                            onClick={() => handleDelete(row._id)}
                            style={{ color: "#D2232A", cursor: "pointer" }}
                          />
                        </Col>
                      </Row>
                    );
                  })}
              </Col>
              <Col lg={12} sm={12}>
                <h2 style={{ marginBottom: "20px" }}>Certapro Tasks</h2>
                <Tag
                  style={{
                    borderRadius: "5px",
                    width: "70%",
                    marginBottom: "15px",
                    height: "40px",
                    minWidth: "150px",
                  }}
                  color={"#c9daef"}
                >
                  <h3 style={{ paddingTop: 9 }}>cover and protect</h3>
                </Tag>
                {data
                  ?.filter((item: any) => item.category === "Cover and protect")
                  .map((row: any, index: any) => {
                    return (
                      <Row key={row._id} gutter={10}>
                        <Col span={18}>
                          <Row>
                            <Col span={2}>
                              <BulletIcon />
                            </Col>
                            {edit.cover === index ? (
                              <Col span={22}>
                                <Input
                                  onChange={(e: any) =>
                                    handleTaskChange(e, row._id)
                                  }
                                  value={row.task}
                                />
                              </Col>
                            ) : (
                              <Col span={22}>
                                <p>{row.task}</p>
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Col span={2}>
                          {edit.cover === index ? (
                            <SaveOutlined
                              onClick={() => handleEdit(index, row, "cover")}
                              style={{ color: "#FDB913", cursor: "pointer" }}
                            />
                          ) : (
                            <EditOutlined
                              onClick={() => handleEdit(index, row, "cover")}
                              style={{ color: "#FDB913", cursor: "pointer" }}
                            />
                          )}
                        </Col>
                        <Col span={2}>
                          <DeleteOutlined
                            onClick={() => handleDelete(row._id)}
                            style={{ color: "#D2232A", cursor: "pointer" }}
                          />
                        </Col>
                      </Row>
                    );
                  })}
              </Col>
              <Col lg={12} sm={12}>
                <Tag
                  style={{
                    borderRadius: "5px",
                    marginTop: "46px",
                    width: "70%",
                    height: "40px",
                    marginBottom: "15px",
                    minWidth: "100px",
                  }}
                  color={"#c9daef"}
                >
                  <h3 style={{ paddingTop: 9 }}>Will do</h3>
                </Tag>
                {data
                  ?.filter((item: any) => item.category === "will do")
                  .map((row: any, index: any) => {
                    return (
                      <Row key={row._id} gutter={10}>
                        <Col span={18}>
                          <Row>
                            <Col span={2}>
                              <BulletIcon />
                            </Col>
                            {edit.will === index ? (
                              <Col span={22}>
                                <Input
                                  onChange={(e: any) =>
                                    handleTaskChange(e, row._id)
                                  }
                                  value={row.task}
                                />
                              </Col>
                            ) : (
                              <Col span={22}>
                                <p>{row.task}</p>
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Col span={2}>
                          {edit.will === index ? (
                            <SaveOutlined
                              onClick={() => handleEdit(index, row, "will")}
                              style={{ color: "#FDB913", cursor: "pointer" }}
                            />
                          ) : (
                            <EditOutlined
                              onClick={() => handleEdit(index, row, "will")}
                              style={{ color: "#FDB913", cursor: "pointer" }}
                            />
                          )}
                        </Col>
                        <Col span={2}>
                          <DeleteOutlined
                            onClick={() => handleDelete(row._id)}
                            style={{ color: "#D2232A", cursor: "pointer" }}
                          />
                        </Col>
                      </Row>
                    );
                  })}
              </Col>
            </Row>
          ) : (
            <Row gutter={10}>
              <Col lg={8} sm={24} style={{ marginBottom: "20px" }}>
                <h2 style={{ marginBottom: "20px" }}>Customer Tasks</h2>
                {data
                  ?.filter((item: any) => item.title === "customer tasks")
                  .map((row: any, index: any) => {
                    return (
                      <Row gutter={10} key={row._id}>
                        <Col span={18}>
                          <Row>
                            <Col span={2}>
                              <BulletIcon />
                            </Col>
                            {edit.customer === index ? (
                              <Col span={22}>
                                <Input
                                  onChange={(e: any) =>
                                    handleTaskChange(e, row._id)
                                  }
                                  value={row.task}
                                />
                              </Col>
                            ) : (
                              <Col span={22}>
                                <p>{row.task}</p>
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Col span={2}>
                          {edit.customer === index ? (
                            <SaveOutlined
                              onClick={() => handleEdit(index, row, "customer")}
                              style={{ color: "#FDB913", cursor: "pointer" }}
                            />
                          ) : (
                            <EditOutlined
                              onClick={() => handleEdit(index, row, "customer")}
                              style={{ color: "#FDB913", cursor: "pointer" }}
                            />
                          )}
                        </Col>
                        <Col span={2}>
                          <DeleteOutlined
                            onClick={() => handleDelete(row._id)}
                            style={{ color: "#D2232A", cursor: "pointer" }}
                          />
                        </Col>
                      </Row>
                    );
                  })}
              </Col>
              <Col lg={8} sm={12}>
                <h2 style={{ marginBottom: "20px" }}>Certapro Tasks</h2>
                <Tag
                  style={{
                    borderRadius: "5px",
                    width: "70%",
                    marginBottom: "15px",
                    height: "40px",
                    minWidth: "150px",
                  }}
                  color={"#c9daef"}
                >
                  <h3 style={{ paddingTop: 9 }}>cover and protect</h3>
                </Tag>
                {data
                  ?.filter((item: any) => item.category === "Cover and protect")
                  .map((row: any, index: any) => {
                    return (
                      <Row key={row._id} gutter={10}>
                        <Col span={18}>
                          <Row>
                            <Col span={2}>
                              <BulletIcon />
                            </Col>
                            {edit.cover === index ? (
                              <Col span={22}>
                                <Input
                                  onChange={(e: any) =>
                                    handleTaskChange(e, row._id)
                                  }
                                  value={row.task}
                                />
                              </Col>
                            ) : (
                              <Col span={22}>
                                <p>{row.task}</p>
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Col span={2}>
                          {edit.cover === index ? (
                            <SaveOutlined
                              onClick={() => handleEdit(index, row, "cover")}
                              style={{ color: "#FDB913", cursor: "pointer" }}
                            />
                          ) : (
                            <EditOutlined
                              onClick={() => handleEdit(index, row, "cover")}
                              style={{ color: "#FDB913", cursor: "pointer" }}
                            />
                          )}
                        </Col>
                        <Col span={2}>
                          <DeleteOutlined
                            onClick={() => handleDelete(row._id)}
                            style={{ color: "#D2232A", cursor: "pointer" }}
                          />
                        </Col>
                      </Row>
                    );
                  })}
              </Col>
              <Col lg={8} sm={12}>
                <Tag
                  style={{
                    borderRadius: "5px",
                    marginTop: "46px",
                    width: "70%",
                    height: "40px",
                    marginBottom: "15px",
                    minWidth: "100px",
                  }}
                  color={"#c9daef"}
                >
                  <h3 style={{ paddingTop: 9 }}>Will do</h3>
                </Tag>
                {data
                  ?.filter((item: any) => item.category === "will do")
                  .map((row: any, index: any) => {
                    return (
                      <Row key={row._id} gutter={10}>
                        <Col span={18}>
                          <Row>
                            <Col span={2}>
                              <BulletIcon />
                            </Col>
                            {edit.will === index ? (
                              <Col span={22}>
                                <Input
                                  onChange={(e: any) =>
                                    handleTaskChange(e, row._id)
                                  }
                                  value={row.task}
                                />
                              </Col>
                            ) : (
                              <Col span={22}>
                                <p>{row.task}</p>
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Col span={2}>
                          {edit.will === index ? (
                            <SaveOutlined
                              onClick={() => handleEdit(index, row, "will")}
                              style={{ color: "#FDB913", cursor: "pointer" }}
                            />
                          ) : (
                            <EditOutlined
                              onClick={() => handleEdit(index, row, "will")}
                              style={{ color: "#FDB913", cursor: "pointer" }}
                            />
                          )}
                        </Col>
                        <Col span={2}>
                          <DeleteOutlined
                            onClick={() => handleDelete(row._id)}
                            style={{ color: "#D2232A", cursor: "pointer" }}
                          />
                        </Col>
                      </Row>
                    );
                  })}
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  );
};

export default SetupDefaults;
