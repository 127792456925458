import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Layout, Button, Row, Col } from "antd";
import LaborRates from "../../containers/labor-rates/LaborRates";
import ManagerSteps from "./ManagerSteps";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import useActiveProject from "../../utils/useActiveProject";
import { Spin } from "antd";
import useSyncProjectData from "../../utils/useSyncProjectData";
import { useParams } from "react-router-dom";
const { Content } = Layout;
const ManagerLaborRates: React.FC = () => {
  const { currentProject, items } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const dispatch = useDispatch<any>();
  useActiveProject();
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);
  const history = useHistory();
  const { id } = useParams<any>();
  const [isLoading] = useSyncProjectData(id, true);
  const handleNext = () => {
    history.push(`/manager-options-setup/${items[currentProject]?.projectId}`);
  };
  return (
    <>
      {isLoading === true ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <Content>
          <ManagerSteps current={2} />
          <div className="steps-content">
            <Row>
              <Col className="gutter-row" span={24}>
                <LaborRates />
              </Col>
            </Row>
          </div>
          <div className="onsite-btn-container">
            <Row gutter={12} justify="center" style={{ marginBottom: "20px" }}>
              <Col>
                <Button
                  onClick={handleNext}
                  type="primary"
                  className="save-button"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </div>
        </Content>
      )}
    </>
  );
};

export default ManagerLaborRates;
