import { Row, Col, Avatar, Button } from "antd";
import "./IntegrationCard.less";
import { PandaIcon } from "../../utils/icons";
type Props = {};

const RecommendationCard = (props: Props) => {
  return (
    <div className="integration-card-root">
      <Row align="middle" gutter={12}>
        <Col>
          <Avatar src={<PandaIcon />} size={54} />
        </Col>
        <Col>
          <h2>PandaDocs</h2>
        </Col>
      </Row>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
        tellus, at pellentesque amet, auctor mattis lectus ipsum quis. tesque
        amet, auctor mattis lectus ipsum quis.
      </p>
      <Button className="recommended-add-btn">Add</Button>
    </div>
  );
};

export default RecommendationCard;
