const initialState = {
  setup: [],
  surface: [],
};

const proposalDefaults = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_SETUP_DEFAULTS":
      return {
        ...state,
        setup: action.payload,
      };
    case "SET_SURFACE_DEFAULTS":
      return {
        ...state,
        surface: action.payload,
      };
    default:
      return state;
  }
};

export default proposalDefaults;
