import { message } from "antd";
import api from "../../utils/api";

const setContractorData = (payload: any) => ({
  type: "SET_CONTRACTOR_DATA",
  payload,
});

const addContractorData = (payload: any) => ({
  type: "ADD_CONTRACTOR",
  payload,
});

export const updateContractorData = (payload: any) => ({
  type: "UPDATE_CONTRACTOR_DATA",
  payload,
});

const updateContractorStatus = (payload: any) => ({
  type: "UPDATE_CONTRACTOR_STATUS",
  payload,
});

export const bulkAddContractorData = (payload: any) => ({
  type: "BULK_ADD_CONTRACTOR_DATA",
  payload,
});

export const AddContractAgreement = (payload: any) => ({
  type: "ADD_CONTRACT_AGREEMENT",
  payload,
});

const hideLoader = () => ({
  type: "HIDE_LOADER",
});

export const fetchContractorData = () => (dispatch: any) => {
  api
    .get("user/contractors")
    .then((response: any) => {
      dispatch(setContractorData(response));
      dispatch(hideLoader());
    })
    .catch((err) => {
      console.error(err);
      dispatch(hideLoader());
    });
};

export const addContractorDefaults = (values: any) => (dispatch: any) => {
  api
    .post("user/contractor", values)
    .then((response: any) => {
      dispatch(addContractorData(response));
      return response;
    })
    .catch((err) => {
      message.error(`User already exists try with another email`);
      return err;
    });
};

export const updateContractorDefaults = (values: any) => (dispatch: any) => {
  api
    .put(`user/contractor/${values._id}`, values)
    .then((resp) => {
      dispatch(updateContractorData(resp));
    })
    .catch((err) => {
      console.error(err);
    });
};

export const updateContractorActive = (values: any) => (dispatch: any) => {
  api
    .patch(`user/update-status/${values._id}`, values)
    .then(() => {
      dispatch(updateContractorStatus(values));
    })
    .catch((err) => {
      console.error(err);
    });
};
