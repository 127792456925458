import React, { useEffect } from "react";
import { Layout, Row, Col, Button } from "antd";
import WorkOrderPaintC from "../../containers/work-order/work-order-paint/WorkOrderPaintC";
import { useHistory, useParams } from "react-router-dom";
import JobJacketSteps from "./JobJacketSteps";
import useSyncProjectData from "../../utils/useSyncProjectData";
import { Spin } from "antd";

const { Content } = Layout;

const WorkOrderPaint: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);

  const handleNext = () => {
    history.push(`/work-order-carpentry/${id}`);
  };
  const [isLoading] = useSyncProjectData(id);

  return (
    <>
      {isLoading === true ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <Content>
          <JobJacketSteps current={1} />
          <div className="steps-content">
            <Row gutter={24}>
              <Col className="gutter-row" span={24}>
                <WorkOrderPaintC />
              </Col>
            </Row>
          </div>
          <div className="onsite-btn-container">
            <Row gutter={12} justify="center" style={{ marginBottom: "20px" }}>
              <Col>
                <Button
                  onClick={handleNext}
                  type="primary"
                  className="save-button"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </div>
        </Content>
      )}
    </>
  );
};

export default WorkOrderPaint;
