import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Menu,
  Dropdown,
  message,
  Input,
  Spin,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./EquipmentCostsC.less";
import { carpentryCostColumns, carpentryHeader } from "./config";
import api from "../../utils/api";
import { MoreActionIcon } from "../../utils/icons";
import AddCarpentryCost from "../../components/pop-ups/default-modal/AddCarpentryCost";
import DefaultBulkEntry from "../../components/pop-ups/default-modal/MaterialDefaultBulkEntry";
import { CSVLink } from "react-csv";

type carpentryType = {
  _id: string;
  item: string;
  laborRate: string;
  materialRate: string;
  type: string;
};

const CarpentryCostC = () => {
  const [carpentryCost, setCarpentryCost] = useState<carpentryType[]>([]);
  const [showSingleItemModal, setShowSingleItemModal] = useState(false);
  const [item, setItem] = useState<any>({});
  const [buttonDisable, setButtonDisable] = useState(false);
  const [carpentrySearched, setCarpentrySearched] = useState("");
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    api
      .get("rates/cost/carpentry")
      .then((resp: any) => {
        setCarpentryCost(resp);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  const handleDelete = (id: string) => {
    api.delete(`rates/carpentry/${id}`).then(() => {
      setCarpentryCost(carpentryCost.filter((item: any) => item._id !== id));
    });
  };

  const handleAddCsv = (info: any) => {
    setButtonDisable(true);
    if (info.file.status === "done") {
      setButtonDisable(false);
      message.success("Successfully Uploaded");
      const resp = info.file.response.data;
      setCarpentryCost(resp);
      toggleBulkModal();
    } else if (info.file.status === "error") {
      setButtonDisable(false);
      message.error("Uploading Failed!");
    }
  };

  const toggleBulkModal = () => {
    !showBulkModal ? setShowBulkModal(true) : setShowBulkModal(false);
  };

  const found = carpentryCost?.filter((item: any) => {
    if (!carpentrySearched) {
      return true;
    }
    return item.item.toLowerCase().includes(carpentrySearched.toLowerCase());
  });

  const handleAdd = (values: any) => {
    if (edit) {
      api.put(`rates/carpentry/${item._id}`, values).then((resp: any) => {
        setCarpentryCost(
          carpentryCost.map((data: any) =>
            data._id === item._id ? { ...values, _id: item._id } : data
          )
        );
      });
    } else {
      api
        .post("rates", {
          category: "carpentry",
          rate: values,
        })
        .then((resp: any) => {
          setCarpentryCost([...carpentryCost, resp]);
        });
    }
    toggleSingleItemModal();
  };

  const toggleSingleItemModal = () => {
    !showSingleItemModal
      ? setShowSingleItemModal(true)
      : setShowSingleItemModal(false);
  };

  const handleSingleItem = () => {
    setEdit(false);
    setItem({});
    toggleSingleItemModal();
  };

  const handleEdit = (data: any) => {
    setItem(data);
    setEdit(true);
    toggleSingleItemModal();
  };
  const handleBulkBtn = () => {
    !showBulkModal ? setShowBulkModal(true) : setShowBulkModal(false);
  };

  const tableColumns = [
    ...carpentryCostColumns,
    {
      title: "Action",
      width: 100,
      render: (data: any) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="edit"
                  style={{ fontWeight: "bold" }}
                  onClick={() => handleEdit(data)}
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  key="delete"
                  style={{ fontWeight: "bold" }}
                  onClick={() => handleDelete(data._id)}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <div style={{ cursor: "pointer" }}>
              <MoreActionIcon />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div className="admin-ec-root">
      <DefaultBulkEntry
        buttonDisable={buttonDisable}
        showBulkModal={showBulkModal}
        toggleBulkModal={toggleBulkModal}
        url="rates/upload"
        handleAddCsv={handleAddCsv}
      />
      <AddCarpentryCost
        toggleSingleItemModal={toggleSingleItemModal}
        handleAdd={handleAdd}
        showSingleItemModal={showSingleItemModal}
        item={item}
        edit={edit}
      />
      <Row className="admin-ec-header" justify="space-between">
        <Col>
          <h1>Carpentry Labor Rates</h1>
        </Col>
        <Row gutter={12}>
          <Col>
            <Input
              className="search-manufacture-input"
              prefix={
                <SearchOutlined className="contractor-data-search-icon" />
              }
              placeholder="Search Carpentry"
              onChange={(e) => {
                setCarpentrySearched(e.target.value);
              }}
            />
          </Col>
          <div className="contractor-data-vertical-line " />
          <Col>
            <Button
              className="admin-ec-add-single-btn"
              onClick={handleSingleItem}
            >
              Add Single item
            </Button>
          </Col>
          <Col>
            <Button
              className="admin-ec-add-btn"
              type="primary"
              onClick={handleBulkBtn}
            >
              Bulk Entry
            </Button>
          </Col>
        </Row>
      </Row>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <hr style={{ border: "1px solid #E8E8E8", marginBottom: 30 }} />
          <Button
            className="default-bulk-btn"
            style={{ margin: "0px 20px 10px" }}
            type="primary"
          >
            <CSVLink
              data={found}
              filename={"carpentry-rates"}
              headers={carpentryHeader}
              enclosingCharacter={""}
            >
              Download CSV
            </CSVLink>
          </Button>
          {carpentryCost.length > 0 && (
            <Table
              scroll={{ x: "900px" }}
              rowKey="_id"
              columns={tableColumns}
              dataSource={found}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CarpentryCostC;
