import React from "react";
import TravelCostC from "../../containers/admin-rates/TravelCostC";

type Props = {};

const TravelCost = (props: Props) => {
  return <TravelCostC />;
};

export default TravelCost;
