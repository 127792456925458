import React from "react";
import { Row, Col } from "antd";
import "./Proposal.less";
import { useMediaQuery } from "react-responsive";

const Signature = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:1200px)" });

  return (
    <>
      {" "}
      {isTabletOrMobile ? (
        <div className="proposal-header" style={{ paddingBottom: 50 }}>
          <Row justify="space-between">
            <Col>
              <div className="signature-container">
                <div style={{ paddingBottom: 30 }}>
                  <h1 className="signature-italic">For and on behalf of</h1>
                  <h1 className="signature-title">CERTAPRO PAINTERS</h1>
                </div>

                <div>
                  <hr />
                  <h1
                    className="signature-italic"
                    style={{ marginTop: 5, marginBottom: "30px" }}
                  >
                    Authorized Signature(s)
                  </h1>
                </div>
              </div>
            </Col>
            <Col>
              <div
                className="signature-container"
                style={{ paddingRight: "100px" }}
              >
                <div style={{ paddingBottom: 30 }}>
                  <hr />
                  <h1
                    className="signature-italic"
                    style={{ marginTop: 5, marginBottom: "20px" }}
                  >
                    Authorized Client Signature(s)
                  </h1>
                </div>
                <div style={{ paddingBottom: 30 }}>
                  <hr />
                  <h1 className="signature-italic" style={{ marginTop: 5 }}>
                    Authorized Client Representative Name & Title
                  </h1>
                </div>
                <div>
                  <hr />
                  <h1 className="signature-italic" style={{ marginTop: 5 }}>
                    Client
                  </h1>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="proposal-header" style={{ paddingBottom: 50 }}>
          <Row justify="space-between">
            <Col>
              <div className="signature-container">
                <div style={{ paddingBottom: 30 }}>
                  <h1 className="signature-italic">For and on behalf of</h1>
                  <h1 className="signature-title">CERTAPRO PAINTERS</h1>
                </div>

                <div>
                  <hr />
                  <h1 className="signature-italic" style={{ marginTop: 5 }}>
                    Authorized Signature(s)
                  </h1>
                </div>
              </div>
            </Col>
            <Col>
              <div
                className="signature-container"
                style={{ paddingRight: "100px" }}
              >
                <div style={{ paddingBottom: 30 }}>
                  <hr />
                  <h1 className="signature-italic" style={{ marginTop: 5 }}>
                    Authorized Client Signature(s)
                  </h1>
                </div>
                <div style={{ paddingBottom: 30 }}>
                  <hr />
                  <h1 className="signature-italic" style={{ marginTop: 5 }}>
                    Authorized Client Representative Name & Title
                  </h1>
                </div>
                <div>
                  <hr />
                  <h1 className="signature-italic" style={{ marginTop: 5 }}>
                    Client
                  </h1>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Signature;
